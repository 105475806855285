import { REST } from 'utils/rest';

class ColumnAPI {
  static getHplcproductionData(serial, onSuccess, onError) {
    const url = `/api/proxy/hplcproduction/columns/?serial=${serial}`;
    REST.get(url, onSuccess, onError);
  }

  static put(id, column, onSuccess, onError) {
    const url = `/api/columns/${id}/`;
    const data = {};
    const dataFields = [
      'name',
      'serial',
      'type',
      'lot',
      'max_pressure',
      'length',
      'internal_diameter',
      'particle_size',
      'pore_size',
    ];
    dataFields.forEach((field) => {
      if (field in column) {
        data[field] = column[field];
      }
    });

    REST.put(url, data, onSuccess, onError);
  }

  static archive(columnId, isArchived, onSuccess, onError) {
    const url = `/api/columns/${columnId}/`;
    const data = { archived: !!isArchived };
    REST.patch(url, data, onSuccess, onError);
  }

  static addRunTime(columnId, ageSeconds, onSuccess, onError) {
    const url = `/api/columns/${columnId}/:add_run_time/`;
    const data = { age: +ageSeconds };
    REST.post(url, data, onSuccess, onError);
  }

  static post(column, onSuccess, onError) {
    const url = '/api/columns/';
    const data = {};
    const dataFields = [
      'name',
      'serial',
      'type',
      'lot',
      'max_pressure',
      'length',
      'internal_diameter',
      'particle_size',
      'pore_size',
    ];

    dataFields.forEach((field) => {
      if (field in column) {
        data[field] = column[field];
      }
    });

    REST.post(url, data, onSuccess, onError);
  }
}

export default ColumnAPI;
