<template>
  <InputTypeNumber
    v-bind="$attrs"
    :value="valueInPercent"
    :isDisabled="isDisabled"
    min="0"
    max="100"
    step="1"
    class="input-percent"
    @change="changeValue"
    @blur="validateValue"
  />
</template>

<script>
  import InputTypeNumber from '@/uikitProject/tables/shared/vueTableCell/private/inputs/private/InputTypeNumber';

  export default {
    name: 'InputPercent',

    components: {
      InputTypeNumber,
    },

    inheritAttrs: false,

    props: {
      // 1 = 100%, so we need to make transformation to real percent
      value: {
        type: Number,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      valueInPercent() {
        return parseInt(this.value * 100);
      },
    },

    methods: {
      changeValue(value) {
        this.$emit('change', String(value / 100));
      },
      validateValue() {
        if (this.value < 0) {
          this.$emit('change', '0');
        }
        if (this.value > 1) {
          this.$emit('change', '1');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .input-percent {
    text-align: right;
  }
</style>
