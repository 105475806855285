import { REST } from 'utils/rest';

const url = '/api/organizations/';

export class OrganizationsApi {
  static join(token, onSuccess, onError) {
    return REST.get(`${url}join/${token}/`, onSuccess, onError);
  }
  static removeMember({ organizationId, memberId }, onSuccess, onError) {
    return REST.delete(`${url}${organizationId}/members/${memberId}/`, onSuccess, onError);
  }
}
