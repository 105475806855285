<template>
  <div v-if="isConnecting" class="wifi-connecting">
    <LoaderSpinner />
  </div>
  <div v-else class="wifi">
    <Tippy
      :isInteractive="true"
      trigger="click"
      placement="bottom-start"
      :duration="[200, 67]"
      :popperOptions="popperOptions"
      :isHideOnClickInside="false"
      :isDisabled="!hasAdapter || !isDeviceOnline"
    >
      <template #trigger>
        <PopupHelper
          v-if="!hasAdapter || !isDeviceOnline"
          text="You must purchase a Wi-Fi adapter to use the wireless connection. Write to our support team to find out about supported adapters"
        >
          <div class="wifi__btn wifi__btn--disabled">
            <IconMaterial title="wifi" :size="18" class="wifi__icon wifi__icon--disabled" />
            <DisconnectedStatus class="wifi__disconnected-status" />
          </div>
        </PopupHelper>
        <BtnIcon v-else class="wifi__btn">
          <IconMaterial
            title="wifi"
            :size="18"
            class="wifi__icon"
            :class="{
              'wifi__icon--no-connection': !hasConnection,
            }"
          />
          <InUseStatus v-if="isInUse" class="wifi__in-use-status" />
        </BtnIcon>
      </template>

      <template #default="{ hide, isShow }">
        <Details
          v-if="isShow"
          :deviceSocket="deviceSocket"
          @hide="hide"
          @showDialogToConnect="showWifiAuthModal"
          @connect="showConnectionStatus"
        />
      </template>
    </Tippy>

    <WifiAuthModal
      :deviceSocket="deviceSocket"
      :network="networkToConnect"
      @connect="showConnectionStatus"
    />
  </div>
</template>

<script>
  import BtnIcon from '@/uikitBase/btns/BtnIcon.vue';
  import Tippy from '@/uikitBase/popups/vueTippy/Tippy.vue';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper.vue';
  import IconMaterial from '@/uikitBase/icons/IconMaterial.vue';
  import Details from '@/uikitProject/devices/vueDeviceConnection/private/vueWifi/private/Details.vue';
  import WifiAuthModal from '@/components/block/modal/WifiAuthModal.vue';
  import InUseStatus from '@/uikitProject/devices/vueDeviceConnection/private/common/InUseStatus.vue';
  import DisconnectedStatus from '@/uikitProject/devices/vueDeviceConnection/private/common/DisconnectedStatus.vue';
  import LoaderSpinner from '@/uikitProject/loaders/LoaderSpinner.vue';
  import { DeviceSocketEvents } from '@/api/sockets/DeviceSocket';

  export default {
    name: 'Wifi',

    components: {
      LoaderSpinner,
      DisconnectedStatus,
      InUseStatus,
      WifiAuthModal,
      Details,
      IconMaterial,
      PopupHelper,
      Tippy,
      BtnIcon,
    },

    props: {
      connectionDetails: {
        type: Object,
      },
      deviceSocket: {
        type: Object,
        required: true,
      },
      isDeviceOnline: {
        type: Boolean,
        required: true,
      },
    },

    data: () => ({
      networkToConnect: null,
      isConnecting: false,
    }),

    computed: {
      popperOptions() {
        const pageContentContainer = document.querySelector('.main-wrapper');

        return {
          modifiers: {
            preventOverflow: {
              boundariesElement: pageContentContainer,
              enabled: true,
            },
          },
        };
      },

      hasAdapter() {
        return this.connectionDetails != null;
      },
      hasConnection() {
        return this.connectionDetails?.connection?.connection ?? false;
      },
      isInUse() {
        return this.connectionDetails?.connection?.in_use ?? false;
      },
    },

    created() {
      this.initSocketListeners();
    },

    beforeDestroy() {
      this.removeSocketListeners();
    },

    methods: {
      showWifiAuthModal(network) {
        this.networkToConnect = network;
        this.$modal.show('wifiAuth');
      },
      /*
       * We decided to show a loader to hide the disconnect state when connecting to another network
       */
      showConnectionStatus() {
        this.isConnecting = true;
      },

      initSocketListeners() {
        if (this.deviceSocket) {
          const listenersGroup = this.deviceSocket?.createEventListenersGroup();
          this.listenersGroupId = listenersGroup.id;

          listenersGroup.addEventListener(DeviceSocketEvents.NETWORK_DETAILS, (networkDetails) => {
            if (this.isConnecting) {
              this.isConnecting = false;

              if (!networkDetails.private_ip) {
                this.notifyError(`Can't connect to the network!`);
              }
            }
          });
        }
      },
      removeSocketListeners() {
        this.deviceSocket?.removeEventListenersByGroupId(this.listenersGroupId);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .wifi {
    display: flex;
    align-items: center;
    width: 18px;
    height: 20px;
    color: #000;
    white-space: normal;

    &__icon {
      &--disabled,
      &--no-connection {
        opacity: 0.4;
      }
    }

    &__btn {
      position: relative;
      cursor: pointer;

      &--disabled {
        cursor: not-allowed;
      }
    }

    &__disconnected-status {
      position: absolute;
      bottom: 2px;
      right: 0;
    }

    &__in-use-status {
      position: absolute;
      top: 0;
      right: -1px;
    }
  }

  .wifi-connecting {
    width: 18px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
