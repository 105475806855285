<template>
  <div class="device-list">
    <PrDevice
      v-for="device of devices"
      :key="device.id"
      :device="device"
      is-selectable
      @select="select"
    />
  </div>
</template>

<script>
  import PrDevice from '@/uikitProject/devices/selector/components/PrDevice';

  const EVENT_SELECT = 'select';

  export default {
    name: 'PrList',

    components: {
      PrDevice,
    },

    props: {
      devices: {
        type: Array,
        required: true,
      },
    },

    methods: {
      select(device) {
        this.$emit(EVENT_SELECT, device);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .device-list {
    min-height: 33px;
    border-radius: $border-radius__sm;
    overflow: hidden;
    background-color: $color-bg-input;
  }
</style>
