<template>
  <i class="material-icons" :class="classSize" :title="hint">{{ title }}</i>
</template>

<script>
  export default {
    name: 'IconMaterial',

    props: {
      size: {
        type: [Number, String],
      },
      title: {
        type: String,
        required: true,
      },
      hint: {
        default: null,
        type: String,
        required: false,
      },
    },

    computed: {
      classSize() {
        return this.size ? `material-icon--${this.size}` : 'material-icons--inherit';
      },
    },
  };
</script>

<style lang="scss">
  $material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/';
  $material-design-icons-class-icons-generate-codepoints: false;
  @import '../../../node_modules/material-design-icons-iconfont/src/material-design-icons';

  //TODO временно убрал scoped, нужно навести порядок в стилях
</style>

<style lang="scss" scoped>
  .material-icons {
    &--inherit {
      font-size: inherit;
    }
  }
</style>
