<template>
  <DecoratorState
    :hasTitleOnAdaptive="hasTitleOnAdaptive"
    :title="title"
    :hasTitle="hasTitle"
    class="state-failed"
  >
    <template #icon>
      <IconMaterial title="highlight_off" size="16" />
    </template>
  </DecoratorState>
</template>

<script>
  import DecoratorState from '@/uikitProject/decorators/DecoratorState';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  export default {
    name: 'StateConnectionLost',

    components: {
      DecoratorState,
      IconMaterial,
    },

    props: {
      hasTitle: {
        type: Boolean,
        default: true,
      },
      hasTitleOnAdaptive: {
        type: Boolean,
        default: true,
      },
      isShortTitle: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      title() {
        return this.isShortTitle ? 'Connection lost' : 'Connection with the device was lost';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .state-failed {
    color: $color-text-danger;
  }
</style>
