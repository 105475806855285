<template>
  <div class="scale">
    <div class="scale__balance" :style="styleBalance"></div>
  </div>
</template>

<script>
  export default {
    name: 'Scale',

    props: {
      volume: {
        type: Number,
        required: true,
      },
      amount: {
        type: Number,
        required: true,
      },
      label: {
        type: String,
      },
    },

    computed: {
      percent() {
        return (this.amount / this.volume) * 100;
      },
      styleBalance() {
        return {
          width: `${this.percent}%`,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .scale {
    border: 1px solid darken($color-bg-light-selection, 20);
    border-radius: $border-radius__sm;
    background: $color-bg-light-selection;
    overflow: hidden;
    height: 10px;

    &__balance {
      height: 100%;
      background: darken($color-bg-light-selection, 20);
    }
  }
</style>
