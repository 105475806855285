import { makeGraphqlRequestCloud } from 'api/graphql/graphQLClient';
import { Compounds } from '@/graphql/cloud/compounds/Compounds.graphql';
import { CreateCompound } from '@/graphql/cloud/compounds/mutations/CreateCompound.graphql';

export const apiCompounds = {
  async getCompounds(search = '', sequenceId) {
    const { compounds } = await makeGraphqlRequestCloud(Compounds, {
      search: `%${search ?? ''}%`,
      sequenceId,
    });

    return compounds;
  },
  async createCompound(compoundName) {
    const { compound } = await makeGraphqlRequestCloud(CreateCompound, {
      name: compoundName,
    });

    return compound;
  },
};
