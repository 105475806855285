<template>
  <div
    v-if="configuration.hasOwnProperty('detector') && configuration.detector != null"
    class="hardware-tile detector"
  >
    <div class="hardware-tile__wrapper-title">
      <div>
        <i class="hardware-tile__title-icon" style="font-weight: bold">flare</i>
        <h4>Detector</h4>
      </div>
      <Information :data="popupInfoData" class="hardware-tile__information" />
    </div>

    <hardware-state-component :state="currentState" />

    <HardwareErrorComponent :errors="errors" :error-list="errorList" />

    <!--eslint-disable-next-line-->
    <template v-if="state" v-show="currentState === 3 || currentState === 7">
      <div v-for="(v, i) in configuration.detector.measurements" :key="i">
        <ChromatogramLive
          :ref="'chromatogram' + i.toString()"
          :mps="configuration.detector.mps"
          style="margin: 0 0 0 -16px; height: 40px"
          :chartBackground="'#00000000'"
          :chartAxisColor="'#00000000'"
          :chartTimeLabelsColor="null"
          :chart-line-color="colorFromNm(v.nm)"
          :hasOnlyMinMaxMau="true"
          :isRoundYValues="true"
        />
        <div class="text-gray" style="width: 100%; text-align: right">
          {{ v.name }} {{ v.nm }}nm
          <span
            v-if="
              stateDetails &&
              stateDetails.are_leds_on &&
              stateDetails.adcValues[i] != null &&
              stateDetails.adcValues[i] < 100000
            "
            v-tippy
            content="Channel intensity is low"
            class="detector__low-intensity"
            >low!</span
          >
        </div>
      </div>
    </template>

    <div class="detector__buttons">
      <Checkbox
        v-if="canTurnOffLeds || canTurnOnLeds"
        v-model="stateDetails.are_leds_on"
        :isDisabled="stateDetails.are_leds_on ? !canTurnOffLeds : !canTurnOnLeds"
        data-test-id="checkbox-detector-leds"
        @change="toggleLed"
      >
        Turn ON
      </Checkbox>

      <!--  If the state is ready or working  -->
      <Btn v-if="canCalibrate" height="s" icon-left="adjust" @click="zero">Zero</Btn>
    </div>
  </div>
</template>

<script>
  import ChromatogramLive from '@/uikitProject/charts/vueChromatogramLive/ChromatogramLive.vue';
  import Base from './Base.vue';
  import colorFromNm from 'utils/chart/chromatogram-colors.ts';
  import Btn from '@/uikitBase/btns/Btn';
  import Checkbox from '@/uikitBase/switchers/Checkbox';
  import HardwareErrorComponent from 'components/element/HardwareErrorComponent';
  import { GENERAL_ERRORS } from '@/constants/hardware/errors';
  import Information from 'components/block/hardware/private/Information';
  import { detectorCommands } from '@/constants/hardware/commands';

  export default {
    components: { Information, HardwareErrorComponent, Checkbox, Btn, ChromatogramLive },

    extends: Base,

    computed: {
      currentState() {
        return this.state?.detector;
      },
      stateDetails() {
        return this.state?.detector_details;
      },
      errors() {
        return this.stateDetails?.errors;
      },
      errorList() {
        return { ...GENERAL_ERRORS };
      },

      popupInfoData() {
        const {
          firmware_version,
          id,
          serial,
          mps,
          period,
          measurements,
        } = this.configuration.detector;

        const data = [
          {
            name: 'Id',
            value: id,
          },
          {
            name: 'Serial',
            value: serial,
          },
          {
            name: 'Firmware Version',
            value: firmware_version
              ? `${firmware_version.major}.${firmware_version.minor}`
              : 'Not available',
          },
          {
            name: 'Measurements per second',
            value: mps,
          },
          {
            name: 'Period',
            value: period,
          },
        ];

        Object.entries(measurements).forEach(([title, measurement]) => {
          data.push(
            {
              type: 'title',
              value: `Measurement ${title}`,
            },
            {
              name: 'Name',
              value: measurement.name,
            },
            {
              name: 'ADC value',
              value: this.state.detector_details?.adcValues?.[title],
            },
            {
              name: 'NM',
              value: measurement.nm,
            },
          );
        });

        return data;
      },

      commands() {
        return this.stateDetails?.commandDetails ?? {};
      },

      canTurnOnLeds() {
        return this.commands[detectorCommands.TURN_ON_LEDS]?.status.available;
      },
      canTurnOffLeds() {
        return this.commands[detectorCommands.TURN_OFF_LEDS]?.status.available;
      },
      canCalibrate() {
        return this.commands[detectorCommands.CALIBRATE]?.status.available;
      },
    },

    watch: {
      state(state) {
        if (state?.detector_details?.values) {
          Object.entries(this.state.detector_details.values).forEach(([i, vs]) => {
            const v = this.$refs[`chromatogram${i}`];
            v?.[0]?.append(vs);
          });
        }
      },
    },

    methods: {
      zero() {
        this.ws.command('detector', detectorCommands.CALIBRATE);
      },
      colorFromNm(nm) {
        return colorFromNm(nm);
      },
      toggleLed(isChecked) {
        isChecked
          ? this.ws.command('detector', detectorCommands.TURN_ON_LEDS)
          : this.ws.command('detector', detectorCommands.TURN_OFF_LEDS);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .detector {
    &__low-intensity {
      color: $color-text-danger;
      font-weight: $weight-bold;
    }

    &__buttons {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;
      gap: 10px;
    }
  }
</style>
