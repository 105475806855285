<template>
  <div>
    <div class="top-line--flex">
      <h1 style="flex: none">Compare</h1>
      <PopupHelper v-if="ids && ids.length" text="Remove all chromatograms from compare">
        <Btn
          type="danger"
          height="s"
          iconLeft="delete"
          class="compare-page__btn-clear"
          @click="removeAll"
          >Clear</Btn
        >
      </PopupHelper>
    </div>
    <ChromatogramsCompare :ids="ids" />
  </div>
</template>

<script>
  import ChromatogramsCompare from 'components/block/ChromatogramsCompare.vue';
  import PageTitle from '@/mixins/page-title';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper.vue';
  import ChromatogramsCompareService from '@/services/ChromatogramsCompare';

  export default {
    name: 'ChromatogramsComparePage',

    components: {
      PopupHelper,
      Btn,
      ChromatogramsCompare,
    },

    mixins: [PageTitle],

    props: {
      ids: Array,
      strIDs: Array,
    },

    data() {
      return {
        pageTitle: 'HPLC Cloud: Compare',
      };
    },

    methods: {
      removeAll() {
        ChromatogramsCompareService.save([]);
      },
    },
  };
</script>
