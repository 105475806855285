import { AuthService } from '@/services/auth.service';
import { Centrifuge } from 'centrifuge';
import LocationHelper from '@/utils/LocationHelper';
import ChannelNotAccessibleError from '@/errors/ChannelNotAccessibleError';
import { HTTP_403_FORBIDDEN } from '@/constants/network/httpStatuses';

const SUBSCRIPTION_ENDPOINT = '/api/centrifuge/subscribe';
const TIMEOUT = 15000;
const MIN_SUBSCRIPTION_DELAY = 3000;

const protocol = LocationHelper.isSecuredConnection ? 'wss' : 'ws';
const url = `${protocol}://${window.location.host}/realtime/ws`;

export let centrifuge: Centrifuge;

type Options = {
  needAccessToSharedChannels?: boolean;
};
export const connectToCentrifuge = async ({ needAccessToSharedChannels = false }: Options = {}) => {
  centrifuge = new Centrifuge(url, {
    debug: true,
    minReconnectDelay: 1000,
    ...(!needAccessToSharedChannels &&
      AuthService.isAuthenticated() && {
        token: AuthService.token() ?? undefined,
        timeout: TIMEOUT,
        async getToken() {
          await AuthService.refreshAccessToken();

          const token = AuthService.token();
          if (token) {
            return token;
          }
          throw new Error('Authentication token was not found!');
        },
      }),
  });

  centrifuge.connect();

  await centrifuge.ready();

  return centrifuge;
};

const getSubscriptionToken = async (url: string, ctx: object) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(ctx),
  });

  if (!response.ok) {
    if (response.status === HTTP_403_FORBIDDEN) {
      throw new ChannelNotAccessibleError();
    }

    throw new Error(`Unexpected status code ${response.status}`);
  }
  const data = await response.json();

  return data.channels[0].token;
};

const subscriptions = {};
export const subscribeToCentrifugeChannel = (channel: string, shareToken?: string) => {
  const oldSubscription = subscriptions[channel];

  if (oldSubscription) {
    oldSubscription.removeAllListeners();
    centrifuge.removeSubscription(oldSubscription);
  }

  const subscription = centrifuge.newSubscription(channel, {
    token: undefined,
    minResubscribeDelay: MIN_SUBSCRIPTION_DELAY,
    getToken(ctx) {
      // ctx has a channel in the Subscription token case.

      const urlSearchParams = new URLSearchParams();

      if (shareToken) {
        urlSearchParams.append('share_token', shareToken);
      } else if (AuthService.isAuthenticated()) {
        const authToken = AuthService.token();
        authToken && urlSearchParams.append('token', authToken);
      }

      return getSubscriptionToken(SUBSCRIPTION_ENDPOINT + `?${urlSearchParams.toString()}`, {
        channels: [ctx.channel],
      });
    },
  });

  subscriptions[channel] = subscription;

  subscription.subscribe();

  return subscription;
};
