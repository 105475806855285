<template>
  <div class="fields">
    <div v-for="(field, index) of fields" :key="field.id" class="fields__field">
      <div class="fields__title">{{ index }}. {{ field.name }}</div>
      <Value
        :value="field.value"
        @update="updateValue($event, index, field.type)"
        @get="getCurrentValue(index)"
      />
    </div>
  </div>
</template>

<script>
  import Value from 'components/blocks/devices/vueDompBrowser/private/private/Value';

  const EVENT_UPDATE = 'update';
  const EVENT_GET = 'get';

  export default {
    name: 'Fields',

    components: { Value },

    props: {
      fields: {
        type: Array,
        required: true,
      },
    },

    methods: {
      updateValue(value, index, type) {
        this.$emit(EVENT_UPDATE, {
          value,
          index,
          type,
        });
      },
      getCurrentValue(index) {
        this.$emit(EVENT_GET, {
          index,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .fields {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 10px 20px;
    padding: 0 9px;

    &__field {
      display: contents;
    }
  }
</style>
