<template>
  <div class="decorator-input">
    <div v-if="label" class="decorator-input__wrapper-label">
      <div class="decorator-input__label">
        {{ label }}<span v-if="isOptional" class="decorator-input__optional">, optional</span>
      </div>
      <div v-if="!isOptional && messageRequired" class="decorator-input__required">
        required, {{ messageRequired }}
      </div>
    </div>

    <div class="decorator-input__icon">
      <IconMaterial :title="iconTitle" :size="iconSizePx" />
    </div>

    <div class="decorator-input__field">
      <slot />
    </div>
  </div>
</template>

<script>
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  export default {
    name: 'DecoratorInput',

    components: {
      IconMaterial,
    },

    props: {
      iconSize: {
        type: String,
        required: true,
        validator(value) {
          const availableValues = ['sm', 'md'];
          return availableValues.includes(value);
        },
      },
      iconTitle: {
        type: String,
        required: true,
      },
      label: {
        type: String,
      },
      isOptional: {
        type: Boolean,
        default: false,
      },
      messageRequired: {
        type: String,
      },
    },

    computed: {
      iconSizePx() {
        switch (this.iconSize) {
          case 'sm':
            return 18;
          case 'md':
            return 32;
          default:
            throw new Error('Wrong icon size value');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .decorator-input {
    display: grid;
    grid-template-columns: auto minmax(100px, 810px);
    grid-template-areas: '. label' 'icon field';
    gap: 0 12px;

    &__wrapper-label {
      display: flex;
      justify-content: space-between;
      grid-area: label;
      font-size: 13px;
      color: $color-text-third;
    }

    &__optional,
    &__required {
      font-style: italic;
    }

    &__icon {
      text-align: center;
      width: 32px;
      grid-area: icon;
      margin-top: 5px;
    }

    &__field {
      grid-area: field;
    }
  }
</style>
