export const EVENT_SELECT = 'select';

export const props = {
  // Config with number of rows and columns.
  // Format: 96_8x12
  trayConfig: {
    required: true,
    validator: (value) => typeof value === 'string' || value === null,
  },
  isDisabled: {
    type: Boolean,
  },
};
