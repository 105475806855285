<template>
  <div class="run-substate">
    <StateProcess v-if="substate != null" :title="this.getSubstateDescription(substate)" />
  </div>
</template>

<script>
  import { INJECTION_SUBSTATES } from '@/constants/injections/substates';
  import StateProcess from '@/uikitProject/states/StateProcess';

  export default {
    name: 'RunSubstate',
    components: { StateProcess },
    props: {
      substate: {
        type: String,
      },
    },
    methods: {
      getSubstateDescription(code) {
        const message = INJECTION_SUBSTATES[code];
        return message ? message : 'Unknown substate';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .run-substate {
    display: flex;
    justify-content: center;
    width: 100%;
  }
</style>
