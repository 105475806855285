<template>
  <IconMaterial title="star" size="16" class="icon-favorite" />
</template>

<script>
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  export default {
    name: 'IconFavorite',

    components: {
      IconMaterial,
    },
  };
</script>

<style lang="scss" scoped>
  .icon-favorite {
    color: $color-text-primary;
  }
</style>
