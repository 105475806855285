<template>
  <div class="decorator-paper-block" :class="classClickable" :style="styles">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'DecoratorPaperBlock',

    props: {
      isClickable: {
        type: Boolean,
        default: false,
      },
      styles: {
        type: Object,
      },
    },

    computed: {
      classClickable() {
        return this.isClickable && 'decorator-paper-block--clickable';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .decorator-paper-block {
    border-radius: 2px;
    background: #fff;
    padding: 16px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 20px 0 rgba(0, 0, 0, 0.09);

    &--clickable {
      &:hover {
        text-decoration: none;
        background: #f8f8f8;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 4px 24px 0 rgba(0, 0, 0, 0.09);
      }

      &:active {
        background: #f0f0f0;
        box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.12);
      }
    }
  }
</style>
