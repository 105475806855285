<template>
  <div class="method-details">
    <GlobalEvents @keypress.enter="save" />

    <template v-if="method && methodForEdit">
      <div class="method-details__header">
        <h1>
          Method
          <span v-if="method && method.id" class="method-details__id">#{{ method.id }}</span>
        </h1>

        <SearchFakedInput
          v-show="!methodPresetSearchVisible"
          style="max-width: 200px"
          class="method-search method-details__select-preset"
          text="Select method as preset"
          data-test-id="btn-search-preset"
          @click="openMethodPresetSearch"
        />
        <div v-show="methodPresetSearchVisible" class="method-search">
          <SelectMethodAsPreset
            ref="methodPresetSearch"
            @change="updateMethodDataFromPreset"
            @hidePopup="closeMethodPresetSearch"
          />
        </div>
      </div>

      <MethodForm
        ref="methodForm"
        v-model="methodForEdit"
        :configurationForMethod="preferredConfiguration"
        :canArchive="true"
        :errors="errors"
        :isArchived="isArchived"
        :isValid.sync="isValid"
        class="method-details__form"
        @update:isArchive="setArchivingStatus"
        @favorite="setFavoriteStatus"
        @updateField="clearError"
      />

      <div v-if="!isArchived" class="method-details__actions">
        <div class="method-details__btns">
          <Btn
            :disabled="!isDataChanged || isUpdatingMethod"
            class="method-details__btn"
            @click="revert"
          >
            Revert
          </Btn>

          <PopupHelper
            v-if="!canSave && !isEditable"
            text="You can't update the method because it's already used. It's allowed to update only the name."
          >
            <Btn
              :disabled="!canSave || !isValid"
              type="primary"
              class="method-details__btn"
              @click="save"
            >
              Save
            </Btn>
          </PopupHelper>
          <Btn
            v-else
            :disabled="!canSave || !isValid"
            type="primary"
            class="method-details__btn"
            @click="save"
          >
            Save
          </Btn>
        </div>
      </div>
    </template>
    <div v-else-if="isLoadingMethod">
      <LoadingComponent />
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { METHOD_EMPTY } from '@/constants/methods/presets';
  import { openInNewTab } from 'utils/browserHelpers.ts';
  import {
    EVENT_METHOD_ARCHIVE,
    EVENT_METHOD_CREATE,
    EVENT_METHOD_UPDATE,
  } from '@/constants/events/cabinet.ts';
  import LoadingComponent from 'components/element/LoadingComponent';
  import Btn from '@/uikitBase/btns/Btn';
  import MethodForm from '@/components/blocks/method/MethodForm.vue';
  import MethodAPI from '@/api/method';
  import { apiMethods } from '@/api/graphql/cloud/methods';
  import SelectMethodAsPreset from '@/components/element/SelectMethodAsPreset.vue';
  import SearchFakedInput from '@/components/element/SearchFakedInput.vue';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper.vue';
  import {
    getMethodWithDefaultValuesByConfiguration,
    isMethodDefaultConfiguration,
  } from '@/utils/methodHelpers';
  import { DEFAULT_DEVICE_CONFIGURATION } from '@/constants/methods/configuration';

  export default {
    name: 'MethodDetails',

    components: {
      PopupHelper,
      SearchFakedInput,
      SelectMethodAsPreset,
      MethodForm,
      Btn,
      LoadingComponent,
    },

    props: {
      methodId: {
        type: Number,
        required: true,
      },
    },

    data: () => ({
      method: null,
      methodForEdit: null,
      isLoadingMethod: false,
      isUpdatingMethod: false,
      isValid: true,
      errors: { ...METHOD_EMPTY },
      methodPresetSearchVisible: false,

      preferredConfiguration: DEFAULT_DEVICE_CONFIGURATION,
    }),

    computed: {
      isArchived() {
        return !(this.method && !this.method.archived);
      },
      isEditable() {
        return this.method?.editable;
      },
      isDataChanged() {
        if (!this.method || typeof this.method !== 'object') return false;

        return Object.keys(this.methodForEdit).some((key) => {
          if (key === 'defaultValues') {
            return false;
          }

          if (this.method[key] && typeof this.method[key] === 'object') {
            return !_.isEqual(this.method[key], this.methodForEdit[key]);
          }

          // eslint-disable-next-line eqeqeq
          return this.method[key] != this.methodForEdit[key];
        });
      },
      isSerialChanged() {
        return this.method.serial !== this.methodForEdit.serial;
      },

      isOnlyNameChanged() {
        if (this.isDataChanged) {
          return Object.keys(this.methodForEdit).every((key) => {
            if (key === 'defaultValues') {
              return true;
            }

            if (key === 'name') {
              // eslint-disable-next-line eqeqeq
              return this.method[key] != this.methodForEdit[key];
            }

            if (this.method[key] && typeof this.method[key] === 'object') {
              return _.isEqual(this.method[key], this.methodForEdit[key]);
            }

            // eslint-disable-next-line eqeqeq
            return this.method[key] == this.methodForEdit[key];
          });
        }
        return false;
      },
      canSave() {
        return this.isEditable ? this.isDataChanged : this.isDataChanged && this.isOnlyNameChanged;
      },
    },

    watch: {
      methodId: {
        handler() {
          this.setMethod(null);
          this.initMethod();
        },
        immediate: true,
      },
    },

    methods: {
      async setMethod(method) {
        if (method != null) {
          await this.initConfiguration(method);
        }

        this.method =
          method == null
            ? method
            : await getMethodWithDefaultValuesByConfiguration(method, this.preferredConfiguration);
        this.methodForEdit = _.cloneDeep(this.method);
      },
      clearErrors() {
        this.errors = { ...METHOD_EMPTY };
      },
      clearError(fieldName) {
        this.errors = { ...this.errors, [fieldName]: null };
      },

      handleSubmitError(data, status) {
        const { method, name } = data;

        if (status === 400) {
          const fieldNames = method ? Object.keys(method) : [];

          fieldNames.forEach((field) => {
            this.errors[field] = method[field][0];
          });

          if (name) {
            this.errors.name = name[0];
          }

          this.$refs.methodForm.scrollToField(name ? 'name' : fieldNames[0]);
        } else {
          this.notifyResponseError(data, status);
        }
      },

      async initMethod() {
        this.isLoadingMethod = true;

        try {
          const method = await apiMethods.getMethod(this.methodId);
          this.setMethod(method);
        } catch {
          this.notifyError('Unable to get a method!');
        } finally {
          this.isLoadingMethod = false;
        }
      },

      revert() {
        this.methodPresetSearchVisible = false;
        this.clearErrors();
        this.methodForEdit = _.cloneDeep(this.method);
      },

      onSubmitSuccess(method) {
        if (this.isEditable) {
          this.setMethod(method);
          this.$events.emit(EVENT_METHOD_UPDATE, method);
        } else {
          this.$events.emit(EVENT_METHOD_CREATE, method);
          openInNewTab(`/app/methods/${method.id}`);
        }

        this.isUpdatingMethod = false;
      },

      onSubmitError(data, status) {
        this.handleSubmitError(data, status);
        this.isUpdatingMethod = false;
      },

      async setArchivingStatus(isArchived) {
        const methodId = this.method.id;

        isArchived ? await apiMethods.archive(methodId) : await apiMethods.restore(methodId);

        this.setMethod({
          ...this.method,
          archived: isArchived,
        });
        this.$events.emit(EVENT_METHOD_ARCHIVE, {
          method: this.method,
          isArchived,
        });
      },

      setFavoriteStatus(isFavorite) {
        this.method = {
          ...this.method,
          favorite: isFavorite,
        };
      },

      async openMethodPresetSearch() {
        this.methodPresetSearchVisible = true;
        await this.$nextTick();
        this.$refs.methodPresetSearch.activate();
      },

      closeMethodPresetSearch() {
        this.methodPresetSearchVisible = false;
      },

      updateMethodDataFromPreset(method) {
        if (method) {
          this.clearErrors();
          // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
          const { id, favorite, ..._method } = method;
          this.methodForEdit = {
            ...this.methodForEdit,
            ..._.pick(_method, _.keys(this.method)),
          };
        }
      },

      save() {
        if (!this.isValid || this.isUpdatingMethod) {
          return;
        }

        document.activeElement.blur();

        this.clearErrors();

        this.isUpdatingMethod = true;

        MethodAPI.put(
          this.methodForEdit,
          (method) => {
            this.setMethod(method);
            this.$events.emit(EVENT_METHOD_UPDATE, method);
            this.isUpdatingMethod = false;
          },
          this.onSubmitError,
        );
      },

      initConfiguration(method) {
        const isDefaultConfiguration = isMethodDefaultConfiguration(method.hardware_configuration);
        this.preferredConfiguration = isDefaultConfiguration
          ? DEFAULT_DEVICE_CONFIGURATION
          : this.method?.hardware_configuration ?? DEFAULT_DEVICE_CONFIGURATION;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .method-details {
    @media (max-width: $screen-sm-max) {
      padding: 0 10px;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__id {
      color: $color-text-third;
      font-weight: $weight-normal;
      font-size: $size-md;
      margin-left: 5px;
    }

    &__select-preset {
      font-size: $size-xxs;
    }

    &__title {
      font-size: $size-xl;
      font-weight: $weight-semi-bold;
      margin-bottom: 30px;
    }

    &__form {
      margin-bottom: 20px;
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
    }

    &__btns {
      display: flex;
      align-items: center;
    }

    &__btn {
      margin-left: 10px;
    }
  }
</style>
