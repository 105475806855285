<template>
  <div
    tabIndex="-1"
    class="search-input-faked input--faked text-gray"
    data-test-id="btn-search"
    @click="$emit('click')"
    @focus="focus"
    @mousedown="$emit('mousedown')"
  >
    <div class="material-icons material-icon--14" style="margin: 1px 4px 2px 0">search</div>
    {{ text }}
  </div>
</template>

<script>
  export default {
    name: 'SearchFakedInput',
    props: {
      text: {
        type: String,
        default: 'Search',
      },
    },
    methods: {
      focus(event) {
        // Prevent focusing
        event.preventDefault();
        if (event.relatedTarget) {
          event.relatedTarget.focus(); // Revert focus back to previous blurring element
        } else {
          event.currentTarget.blur(); // No previous focus target, blur instead
        }
      },
    },
  };
</script>

<style lang="scss">
  .search-input-faked {
    user-select: none;
    cursor: text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    width: 80px;
    @screen sm {
      width: 160px;
    }

    &:hover {
      background: #f4f4f4;
    }
  }
</style>
