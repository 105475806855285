<template>
  <div class="settings">
    <div class="layout-config">
      <div v-if="!isCromite" class="column-grow-1">
        <h4>Pumps</h4>
        <div class="vertical-container">
          <label>
            Pump
            <select v-model="settings.pump">
              <option v-for="pump in defaults.concat(pumps)" :key="pump.id" :value="pump.id">
                {{ pump.serial }}
              </option>
            </select>
          </label>
          <label>
            Pump B (gradient)
            <select v-model="settings.pump_b">
              <option
                v-for="pump in defaults.concat(gradientCompatiblePumps)"
                :key="pump.id"
                :value="pump.id"
              >
                {{ pump.serial }}
              </option>
            </select>
          </label>
        </div>

        <h4>Injection</h4>
        <div class="vertical-container">
          <!--          <Checkbox v-model="settings.combine_injection_and_fraction_collector">-->
          <!--            Combine injection and fraction collector-->
          <!--          </Checkbox>-->

          <!--          <div-->
          <!--            v-if="settings.combine_injection_and_fraction_collector"-->
          <!--            class="horizontal-container"-->
          <!--          >-->
          <!--            <label class="main">-->
          <!--              Autosampler fraction collector-->
          <!--              <select-->
          <!--                v-model="settings.autosampler_fraction_collector"-->
          <!--                @change="updateAutosamplerFractionCollectorTraySettings"-->
          <!--              >-->
          <!--                <option-->
          <!--                  v-for="autosampler_fraction_collector in defaults.concat(-->
          <!--                    autosamplerFractionsCollectors,-->
          <!--                  )"-->
          <!--                  :key="autosampler_fraction_collector.id"-->
          <!--                  :value="autosampler_fraction_collector.id"-->
          <!--                >-->
          <!--                  {{ autosampler_fraction_collector.serial }}-->
          <!--                </option>-->
          <!--              </select>-->
          <!--            </label>-->

          <!--            <label>-->
          <!--              Tray-->
          <!--              <select v-model="settings.autosampler_fraction_collector_tray">-->
          <!--                supportedTrays-->
          <!--                <option-->
          <!--                  v-for="(id, name) in autosamplerFractionCollectorTrays"-->
          <!--                  :key="id"-->
          <!--                  :value="id"-->
          <!--                >-->
          <!--                  {{ name }}-->
          <!--                </option>-->
          <!--              </select>-->
          <!--            </label>-->
          <!--          </div>-->

          <div class="horizontal-container">
            <label class="main">
              Autosampler

              <select v-model="settings.autosampler">
                <option
                  v-for="autosampler in defaults.concat(autosamplers)"
                  :key="autosampler.id"
                  :value="autosampler.id"
                >
                  {{ autosampler.serial }}
                </option>
              </select>
            </label>
            <label>
              Tray
              <select v-model="autosamplerTray">
                <option v-for="(id, name) in autosamplerTrays" :key="id" :value="id">
                  {{ name }}
                </option>
              </select>
            </label>
          </div>
          <!--          <label v-if="settings.combine_injection_and_fraction_collector">-->
          <!--            Fraction collector valve-->
          <!--            <select v-model="settings.fraction_collector_valve">-->
          <!--              <option-->
          <!--                v-for="valve in defaults.concat(fractionCollectorValves)"-->
          <!--                :key="valve.id"-->
          <!--                :value="valve.id"-->
          <!--              >-->
          <!--                {{ valve.serial }}-->
          <!--              </option>-->
          <!--            </select>-->
          <!--          </label>-->
          <label>
            Valve loop
            <select v-model="settings.valve_loop">
              <option v-for="valve in defaults.concat(valves)" :key="valve.id" :value="valve.id">
                {{ valve.serial }}
              </option>
            </select>
          </label>

          <label>
            Valve
            <select v-model="settings.valve">
              <option v-for="valve in defaults.concat(valves)" :key="valve.id" :value="valve.id">
                {{ valve.serial }}
              </option>
            </select>
          </label>
        </div>
      </div>
      <div class="column-grow-1">
        <template v-if="!isCromite">
          <h4>Detection</h4>
          <div class="vertical-container">
            <label>
              Detector
              <select v-model="settings.detector">
                <option
                  v-for="detector in defaults.concat(detectors)"
                  :key="detector.id"
                  :value="detector.id"
                >
                  {{ detector.serial }}
                </option>
              </select>
            </label>
            <Checkbox v-model="settings.detector_led_saving_mode" :isDisabled="!settings.detector">
              LED saving mode
            </Checkbox>
            <Checkbox v-model="settings.analog_signal">Analog signal</Checkbox>
          </div>
        </template>

        <h4>Phase</h4>
        <div class="vertical-container">
          <AddSuggestion
            message="Add A container"
            class="settings__suggestion"
            :isShowAlways="hasPhaseContainerA"
            @show="addPhaseContainer('a')"
          >
            <InputForm
              v-if="hasPhaseContainerA"
              v-model.number="settings.phase.a"
              title="Volume of A container, mL"
              type="number"
              min="0"
              class="settings__input-form"
              @keypress="checkIfValueIsNotNegative"
              @paste.prevent
            />
          </AddSuggestion>
          <AddSuggestion
            message="Add B container"
            class="settings__suggestion"
            :isShowAlways="hasPhaseContainerB"
            @show="addPhaseContainer('b')"
          >
            <InputForm
              v-if="hasPhaseContainerB"
              v-model.number="settings.phase.b"
              title="Volume of B container, mL"
              type="number"
              min="0"
              class="settings__input-form"
              @keypress="checkIfValueIsNotNegative"
              @paste.prevent
            />
          </AddSuggestion>
        </div>

        <h4>Waste</h4>
        <div class="vertical-container settings__waste">
          <AddSuggestion
            message="Add waste container"
            :isShowAlways="hasWasteContainer"
            @show="addWasteContainer"
          >
            <InputForm
              v-model.number="settings.waste"
              title="Volume of waste container, mL"
              type="number"
              min="0"
              class="settings__input-form"
              @keypress="checkIfValueIsNotNegative"
              @paste.prevent
            />
          </AddSuggestion>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DeviceAlltestaAPI from 'api/device-alltesta';
  import Checkbox from '@/uikitBase/switchers/Checkbox';
  import _ from 'lodash';
  import InputForm from '@/uikitProject/inputs/InputForm';
  import AddSuggestion from 'components/blocks/devices/settings/private/AddSuggestion';

  const EVENT_UPDATE = 'update';
  const EVENT_READY = 'ready';
  const EVENT_ERROR = 'error';

  const defaultOptions = {
    OFF: null,
    AUTO: 'auto',
  };

  const TRAY_VALUES = {
    small48: 0,
    big96: 1,
  };

  const SETTINGS = {
    pump: 'auto',
    pump_b: 'auto',
    autosampler: 'auto',
    autosampler_tray: TRAY_VALUES.small48,
    autosampler_fraction_collector: 'auto',
    autosampler_fraction_collector_tray: null,
    combine_injection_and_fraction_collector: false,
    fraction_collector_valve: null,
    valve_loop: 'auto',
    valve: 'auto',
    detector: 'auto',
    detector_led_saving_mode: true,
    analog_signal: true,
    phase: null,
    waste: null,
  };

  export default {
    name: 'AlltestaTowerSettings',

    components: { AddSuggestion, InputForm, Checkbox },

    props: {
      id: {
        type: String,
        default: null,
      },
      isCromite: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        settings: { ...SETTINGS },
        availableDevices: [],
      };
    },

    computed: {
      pumps() {
        return this.availableDevices.filter(
          ({ id, name }) =>
            name.toLowerCase().includes('pump') &&
            !name.toLowerCase().includes('pump-g') &&
            this.settings.pump_b !== id,
        );
      },
      gradientCompatiblePumps() {
        return this.availableDevices.filter(
          ({ id, name }) => name.toLowerCase().includes('pump-g') && this.settings.pump !== id,
        );
      },
      autosamplers() {
        return this.availableDevices.filter(({ name }) =>
          name.toLowerCase().includes('autosampler'),
        );
      },
      autosamplerTrays() {
        // const selectedAutosampler = this.availableDevices.filter(
        //   (device) => device.id === this.settings.autosampler,
        // )[0];
        return {
          // auto: null,
          // ...selectedAutosampler?.additionalInfo['supported_trays'],
          '48_6x8': TRAY_VALUES.small48,
          '96_8x12': TRAY_VALUES.big96,
        };
      },
      autosamplerTray: {
        get() {
          return this.settings.autosampler_tray ?? TRAY_VALUES.small48;
        },
        set(value) {
          this.settings.autosampler_tray = value;
        },
      },
      // autosamplerFractionCollectorTrays() {
      //   /*const selectedAutosamplerFractionCollector = this.availableDevices.filter(
      //     (device) => device.id === this.settings.autosampler_fraction_collector,
      //   )[0];*/
      //   return {
      //     auto: null,
      //     //...selectedAutosamplerFractionCollector?.additionalInfo['supported_trays'],
      //   };
      // },
      fractionCollectorValves() {
        return this.availableDevices.filter(({ name }) => name.toLowerCase().includes('valve'));
      },
      autosamplerFractionsCollectors() {
        return this.availableDevices.filter(({ name }) =>
          name.toLowerCase().includes('autosampler'),
        );
      },
      valves() {
        return this.availableDevices.filter(({ name }) => name.toLowerCase().includes('valve'));
      },
      detectors() {
        return this.availableDevices.filter(({ name }) => name.toLowerCase().includes('detector'));
      },
      defaults() {
        return [
          { id: defaultOptions.OFF, serial: 'Off' },
          { id: defaultOptions.AUTO, serial: 'Auto' },
        ];
      },

      hasPhaseContainerA() {
        return this.settings.phase?.a != null;
      },
      hasPhaseContainerB() {
        return this.settings.phase?.b != null;
      },
      hasWasteContainer() {
        return this.settings.waste != null;
      },
    },

    watch: {
      'settings.valve_loop'() {
        this.selectOnlyOneValve('valve_loop');
      },
      'settings.valve'() {
        this.selectOnlyOneValve('valve');
      },
    },

    created() {
      this.updateBoxData();
    },

    methods: {
      addPhaseContainer(containerName) {
        this.settings = {
          ...this.settings,
          phase: {
            ...this.settings.phase,
            ...this.settings.phase?.containers,
            [containerName]: 0,
          },
        };
      },
      addWasteContainer() {
        this.settings = {
          ...this.settings,
          waste: 0,
        };
      },

      updateBoxData() {
        DeviceAlltestaAPI.get(
          this.id,
          (deviceInfo) => {
            // Alltesta version > 1.94 and accept settings as serials and send all connected devices
            deviceInfo.settings.analog_signal = deviceInfo.settings.analog_signal === 1;
            if (deviceInfo.configuration.alltesta) {
              this.settings = _.pick(deviceInfo.settings, Object.keys(SETTINGS));
              this.availableDevices = deviceInfo.configuration.alltesta.serial_devices;
            } else {
              this.settings = _.pick(deviceInfo.settings, Object.keys(SETTINGS));
            }
            this.$emit(EVENT_READY);
          },
          (...e) => {
            this.notifyResponseError(...e);

            const [data] = e;
            const message = data?.detail || data?.error || data?.msg || data?.message;
            this.$emit(EVENT_ERROR, message);
          },
        );
      },
      // updateAutosamplerTraySettings() {
      //   if (!this.settings.autosampler || this.settings.autosampler === 'auto') {
      //     this.settings.autosampler_tray = null;
      //   }
      // },
      updateAutosamplerFractionCollectorTraySettings() {
        if (
          !this.settings.autosampler_fraction_collector ||
          this.settings.autosampler_fraction_collector === 'auto'
        ) {
          this.settings.autosampler_fraction_collector_tray = null;
        }
      },
      save() {
        const data = { id: this.id, ...this.settings };
        data.pump_b = data.pump_b ? 'auto' : null;
        if (data.phase) {
          if (!data.phase.b) {
            delete data.phase.b;
          }
          if (!data.phase.a) {
            delete data.phase.a;
          }
          if (!data.phase.a && !data.phase.b) {
            data.phase = null;
          }
        }
        if (!data.waste) {
          data.waste = null;
        }
        data.analog_signal = data.analog_signal ? 1 : 0;
        if (data.combine_injection_and_fraction_collector) {
          data.autosampler = null;
          data.valve_loop = null;
        } else {
          data.autosampler_fraction_collector = null;
          data.fraction_collector_valve = null;
        }
        DeviceAlltestaAPI.put(
          this.id,
          data,
          () => this.$emit(EVENT_UPDATE, this.settings),
          this.notifyResponseError,
        );
      },

      checkIfValueIsNotNegative(e) {
        if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','].includes(e.key)) {
          e.preventDefault();
        }
      },

      selectOnlyOneValve(lastChangedDeviceName) {
        const notChangedDeviceName =
          lastChangedDeviceName === 'valve_loop' ? 'valve' : 'valve_loop';

        const lastChangedValue = this.settings[lastChangedDeviceName];
        const notChangedValue = this.settings[notChangedDeviceName];

        if (
          (lastChangedValue !== defaultOptions.OFF &&
            (lastChangedValue === notChangedValue || notChangedValue === defaultOptions.AUTO)) ||
          (lastChangedValue === defaultOptions.AUTO && notChangedValue !== defaultOptions.OFF)
        ) {
          this.settings[notChangedDeviceName] = defaultOptions.OFF;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/css/component/modal';

  .settings {
    @extend .modal-body;

    h4 {
      margin-top: 32px;
      margin-bottom: 8px;
    }

    ::v-deep label {
      margin-top: 16px;
    }

    .container {
      margin-bottom: 8px;
    }

    &__suggestion:not(:last-child) {
      margin-bottom: 10px;
    }

    &__suggestion,
    &__waste {
      ::v-deep label {
        margin: 0;
      }
    }

    &__input-form {
      width: 100%;
    }
  }

  .horizontal-container {
    display: flex;
    flex-direction: row;
  }

  .horizontal-container > .main {
    flex: 2;
  }

  .horizontal-container > :not(.main) {
    flex: 1;
  }

  .vertical-container {
    display: flex;
    flex-direction: column;
  }

  .layout-config {
    width: 100%;
    display: flex;
    justify-content: stretch;

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }

    div.column-grow-1 {
      width: 50%;
      padding: 0 15px;

      @media (max-width: $screen-sm-max) {
        width: 100%;
      }
    }
  }
</style>
