<template>
  <ErrorBlock v-if="error === 8" style="margin: 16px 32px 16px 32px">
    <span slot="brief">The pressure was outside the limits specified in the method.</span>
    <div>
      <span v-if="method.pressure_min">
        Pressure should not not drop lower than
        <span style="white-space: nowrap; font-weight: bold"
          >{{ method.pressure_min }}&nbsp;psi</span
        >
        during the run.
      </span>
      <span v-if="method.pressure_max"
        >Pressure should not exceed maximum pressure
        <span style="white-space: nowrap; font-weight: bold"
          >{{ method.pressure_max }}&nbsp;psi</span
        >
        during the run.</span
      >
    </div>
    <div>Possible cause</div>
    <ul class="simple">
      <li>The pump has air inside</li>
      <li>System's working pressure is lower than the requested minimum</li>
      <li>You have leak or tubes broken</li>
      <li>The service life of the column is over</li>
      <li>The flow rate is too high</li>
    </ul>
    <div>To fix this error</div>
    <ul class="simple">
      <li>Use lower minimum pressure in the method</li>
      <li>Make sure all tubes connected tightly</li>
      <li>Empty the pump</li>
    </ul>
  </ErrorBlock>
  <ErrorBlock v-else-if="error === 9" style="margin: 16px 32px 16px 32px">
    <span slot="brief">Unable to reach pressure in available time.</span>
    <div>
      <span v-if="method.pressure_min">
        Minimum pressure
        <span style="white-space: nowrap; font-weight: bold"
          >{{ method.pressure_min }}&nbsp;psi</span
        >
        was expected to be reached before injection.</span
      >
    </div>
    <div>Possible cause</div>
    <ul class="simple">
      <li>The pump has air inside</li>
      <li>System's working pressure is lower than the requested minimum</li>
      <li>You have leak or tubes broken</li>
    </ul>
    <div>To fix this error</div>
    <ul class="simple">
      <li>Use lower minimum pressure in the method</li>
      <li>Make sure all tubes connected tightly</li>
      <li>Empty the pump</li>
    </ul>
  </ErrorBlock>
</template>

<script>
  import ErrorBlock from '@/uikitProject/blocks/ErrorBlock';

  export default {
    name: 'InjectionErrors',
    components: { ErrorBlock },
    props: {
      error: Number,
      method: Object,
    },
  };
</script>
