import UnknownSwitchCaseError from '@/utils/errors/UnknownSwitchCaseError';
import { compareAsc } from 'date-fns';

enum ComparatorTypes {
  STRING = 'string',
  NUMBER = 'number',
  DATETIME = 'datetime',
}

enum Directions {
  ASC = 'asc',
  DESC = 'desc',
}

export default class SortHelper {
  public static getBaseComparator(type: ComparatorTypes, direction: Directions = Directions.ASC) {
    switch (type) {
      case ComparatorTypes.NUMBER:
        return (a, b) => {
          const result = a - b;
          if (direction === Directions.DESC) return result * -1;
          return result;
        };
      case ComparatorTypes.STRING:
        return (a, b) => {
          const result = a.localeCompare(b);
          if (direction === Directions.DESC) return result * -1;
          return result;
        };
      case ComparatorTypes.DATETIME:
        return (a, b) => {
          const result = compareAsc(new Date(a), new Date(b));
          if (direction === Directions.DESC) return result * -1;
          return result;
        };
      default:
        throw new UnknownSwitchCaseError();
    }
  }
}
