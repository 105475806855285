<template>
  <div class="methods-page">
    <NavbarSecondaryAccount />

    <MethodEditModal
      v-if="detailDialogMethod"
      :method="detailDialogMethod"
      :isCloseOnArchivingStatus="true"
      @update:method="detailMethodUpdated"
      @update:archived="detailArchivedUpdated"
      @created="addMethodToTable"
    />

    <div class="top-line--flex methods-page__header">
      <div class="methods-page__wrapper-title">
        <h1 class="text-2xl sm:text-4xl" style="display: inline-block">
          <template v-if="archived">Archived Methods</template>
          <template v-else>Methods</template>
        </h1>
        <Btn
          v-if="!archived"
          height="s"
          icon-left="delete"
          type="transparent"
          class="archived-btn methods-page__btn-switch"
          @click="goToArchived()"
          >View archived</Btn
        >
        <Btn
          v-else
          height="s"
          icon-left="keyboard_arrow_left"
          type="transparent"
          class="archived-btn methods-page__btn-switch"
          @click="goToNotArchived()"
          >Back</Btn
        >

        <Btn
          v-if="isFavorites"
          height="s"
          icon-left="star_border"
          type="transparent"
          class="archived-btn methods-page__btn-switch"
          @click="showAll"
          >Show all</Btn
        >
        <Btn
          v-else
          height="s"
          icon-left="star"
          type="transparent"
          class="archived-btn methods-page__btn-switch"
          @click="showFavorites"
          >Show favorites</Btn
        >
      </div>
      <div class="top-line__actions methods-page__actions">
        <InputSearch v-model="searchQuery" placeholder="Search by name or id" />
      </div>
    </div>

    <table v-if="methods.length" class="table-default">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th class="hidden sm:table-cell">Run time</th>
          <th class="hidden sm:table-cell">Injection</th>
          <th class="hidden md:table-cell">Flow rate</th>
          <th class="hidden sm:table-cell">A phase</th>
          <th v-if="hasBPhase" class="hidden sm:table-cell">B phase</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="tbody__td--nowrap">
        <tr
          v-for="method in methods"
          :key="method.id"
          class="row--clickable"
          @click="showDetail(method)"
        >
          <td class="methods-page__id">
            {{ method.id }}
          </td>
          <td class="td--overflow-hidden">
            {{ method.name }}
          </td>
          <td class="hidden sm:table-cell text-black">{{ method.run_time_min }} min</td>
          <td
            class="hidden sm:table-cell text-black"
            v-html="method.injection_ul + ' μl / ' + method.injection_sec + ' s'"
          />
          <td class="hidden md:table-cell text-gray">{{ method.flow_rate_ml_per_min }} mL/min</td>
          <td
            class="hidden sm:table-cell text-black td--overflow-hidden"
            style="max-width: 130px"
            :title="method.solvents && method.solvents.a && method.solvents.a.name"
          >
            {{ method.solvents && method.solvents.a && method.solvents.a.name }}
          </td>
          <td
            v-if="hasBPhase"
            class="hidden sm:table-cell text-black td--overflow-hidden"
            style="max-width: 130px"
            :title="method.solvents && method.solvents.b && method.solvents.b.name"
          >
            {{ method.solvents && method.solvents.b && method.solvents.b.name }}
          </td>
          <td
            :class="{
              'methods-page__cell--selected': Number(selectedMethodId) === method.id,
            }"
          >
            <IconFavorite v-if="method.favorite" />
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="showMoreBtnVisible" class="table-bottom-container">
      <button class="button--unostentatious" @click="getNextItems()">Show more</button>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';

  import PageTitle from '@/mixins/page-title';
  import Btn from '@/uikitBase/btns/Btn';
  import NavbarSecondaryAccount from 'components/blocks/layouts/NavbarSecondaryAccount';
  import InputSearch from '@/uikitBase/inputs/InputSearch';
  import RouterHelper from 'utils/RouterHelper.ts';
  import {
    EVENT_METHOD_ARCHIVE,
    EVENT_METHOD_CREATE,
    EVENT_METHOD_UPDATE,
  } from '@/constants/events/cabinet.ts';
  import { EVENT_BEFORE_DESTROY } from '@/constants/events/global.ts';
  import { apiMethods } from '@/api/graphql/cloud/methods';
  import MethodEditModal from '@/components/block/modal/MethodEditModal.vue';
  import IconFavorite from '@/uikitProject/icons/IconFavorite.vue';

  export default {
    name: 'MethodsPage',

    components: {
      IconFavorite,
      MethodEditModal,
      InputSearch,
      NavbarSecondaryAccount,
      Btn,
    },

    mixins: [PageTitle],

    data() {
      return {
        pageTitle: 'HPLC Cloud: Methods',

        archived: false,

        methods: [],
        methodsHash: {},

        methodsCount: -1,

        detailDialogMethod: undefined,

        searchQuery: undefined,
      };
    },

    computed: {
      showMoreBtnVisible() {
        return this.methodsCount > this.methods.length;
      },
      selectedMethodId() {
        if (RouterHelper.isDualMode) {
          return RouterHelper.getRouteSecondary(this.$route)?.params?.methodId;
        }
        return null;
      },
      hasBPhase() {
        return this.methods.some((method) => method.solvents?.b?.name);
      },

      isFavorites() {
        return this.$route.query.isFavorites != null;
      },
    },

    watch: {
      searchQuery() {
        this.getMethods();
      },
      $route(to, from) {
        if (to.query.archived !== from.query.archived) {
          this.useRouteQuery(this.$route.query);
        }
      },
      isFavorites() {
        this.getMethods();
      },
    },

    created() {
      this.initEventBus();
    },

    mounted() {
      this.useRouteQuery(this.$route.query);
    },

    methods: {
      initEventBus() {
        this.$events.on(EVENT_METHOD_ARCHIVE, ({ method, isArchived }) => {
          if (this.archived === isArchived) {
            this.methods.unshift(method);
            this.methodsCount++;
          } else {
            const methodsFiltered = this.methods.filter((_method) => _method.id !== method.id);

            if (methodsFiltered.length < this.methods.length) {
              this.methods = methodsFiltered;
              this.methodsCount--;
            }
          }
        });
        this.$events.on(EVENT_METHOD_UPDATE, (method) => {
          this.methods = this.methods.map((_method) =>
            _method.id === method.id ? method : _method,
          );
        });
        this.$events.on(EVENT_METHOD_CREATE, (method) => this.addMethodToTable(method));

        this.$once(EVENT_BEFORE_DESTROY, () => {
          this.$events.off([EVENT_METHOD_UPDATE, EVENT_METHOD_CREATE]);
        });
      },

      useRouteQuery(query) {
        if (!!query && !!query.archived) {
          this.showArchived();
        } else {
          this.showNotArchived();
        }
      },
      showArchived() {
        this.archived = true;
        this.methods = [];
        this.getMethods();
      },
      showNotArchived() {
        this.archived = false;
        this.methods = [];
        this.getMethods();
      },

      goToArchived() {
        this.$router.push({ query: { archived: true } });
      },

      goToNotArchived() {
        this.$router.push({ query: { archived: undefined } });
      },

      showFavorites() {
        this.$router.push({ query: { ...this.$route.query, isFavorites: true } });
      },
      showAll() {
        this.$router.push({ query: { ...this.$route.query, isFavorites: undefined } });
      },

      async showDetail(method) {
        if (RouterHelper.isDualMode) {
          this.$router.push({
            name: 'methodDetails',
            params: {
              methodId: method.id,
            },
          });
        } else {
          this.detailDialogMethod = method;
          await this.$nextTick();
          this.$modal.show('method-edit');
        }
      },

      detailMethodUpdated(newMethodData) {
        _.assign(this.detailDialogMethod, newMethodData);
      },
      detailArchivedUpdated(isArchived) {
        if (isArchived !== this.archived) {
          const index = this.methods.indexOf(this.detailDialogMethod);
          if (index >= 0) {
            this.methods.splice(index, 1);
            delete this.methodsHash[this.detailDialogMethod.id];
            this.methodsCount -= 1;
          }
        }
      },

      addMethodToTable(method) {
        this.methodsCount += 1;
        this.methods.unshift(method);
        this.methodsHash[method.id] = true;
      },
      async getNextItems() {
        const { methods, count } = await apiMethods.getMethods({
          search: this.searchQuery,
          isArchived: this.archived,
          hasOnlyFavorites: this.isFavorites,
          offset: this.methods.length,
        });
        this.methodsCount = count;

        if (methods.length > 0) {
          const newMethods = [];

          newMethods.push(...methods.filter((method) => !(method.id in this.methodsHash)));

          if (newMethods.length > 0) {
            // / NOTE: sort methods if cur last column has less last_modified than first new column
            let isSort = false;
            if (this.methods.length > 0) {
              const curLastMethod = this.methods[this.methods.length - 1];
              const firstNewMethod = newMethods[0];
              isSort = firstNewMethod.last_modified_time > curLastMethod.last_modified_time;
            }
            this.methods.push(...newMethods);
            if (isSort) {
              this.methods.sort((a, b) => b.last_modified_time - a.last_modified_time);
            }
          }
        }
      },

      async getMethods() {
        const { methods, count } = await apiMethods.getMethods({
          search: this.searchQuery,
          isArchived: this.archived,
          hasOnlyFavorites: this.isFavorites,
        });

        this.methods = methods;
        this.methodsHash = {};
        this.methods.forEach((method) => {
          this.methodsHash[method.id] = true;
        });
        this.methodsCount = count;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .methods-page {
    &__header {
      display: flex;
      align-items: baseline;

      @media (max-width: $screen-xs-max) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__wrapper-title {
      display: flex;
      align-items: baseline;

      @media (max-width: $screen-xs-max) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__btn-switch {
      margin-left: 30px;

      @media (max-width: $screen-sm-max) {
        margin-left: 15px;
      }

      @media (max-width: $screen-xs-max) {
        margin-left: 0;
      }
    }

    &__actions {
      @media (max-width: $screen-xs-max) {
        margin-top: 10px;
        align-self: flex-end;
      }
    }

    &__cell {
      &--selected {
        position: relative;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          content: '';
          height: 100%;
          width: 2px;
          background-color: $color-bg-primary;
        }
      }
    }

    &__id {
      width: max-content;
      color: $color-text-third;
    }
  }

  .table-bottom-container {
    display: flex;
    justify-content: center;
  }
</style>
