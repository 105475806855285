<template>
  <span v-if="url" class="shareable-link print-hidden">
    <a :href="url" @click="putShareableLinkToTheClipboard">
      <i class="material-icons material-icon--14">share</i> Link
    </a>
    <a
      class="material-icons material-icon--14 shareable-link__remove"
      href="#"
      @click="stopSharing"
    >
      clear
    </a>
  </span>
  <a v-else class="text-gray shareable-link print-hidden" href="#" @click="share">
    <i class="material-icons material-icon--14">share</i> Share
  </a>
</template>

<script>
  import 'assets/css/component/vue-dialog.scss';
  import { consoleHelpers } from 'utils/logHelpers';

  const EVENT_ACTION_SHARE = 'action:share';
  const EVENT_ACTION_STOP_SHARING = 'action:stopSharing';

  export default {
    name: 'ShareableLinkComponent',

    props: {
      url: {
        type: String,
        default: null,
      },
    },

    methods: {
      putShareableLinkToTheClipboard(e) {
        e.preventDefault();
        if (!navigator.clipboard) {
          consoleHelpers.warn('navigator.clipboard requires secure origin or localhost');
          return;
        }
        navigator.clipboard.writeText(this.url).then(
          () => {
            consoleHelpers.warn('Async: Copying to clipboard was successful!');
          },
          (err) => {
            consoleHelpers.error('Async: Could not copy text: ', err);
          },
        );

        this.notify('Copied to the clipboard', 1);
      },

      share(e) {
        e.preventDefault();
        this.$emit(EVENT_ACTION_SHARE);
      },

      stopSharing(e) {
        e.preventDefault();
        this.$modal.show('dialog', {
          title: 'Disable shareable link?',
          text: 'Current link will no longer be available. New shareable link will be different.',
          buttons: [
            {
              title: 'Disable sharing',
              handler: () => {
                this.$modal.hide('dialog');
                this.$emit(EVENT_ACTION_STOP_SHARING);
              },
              class: 'vue-dialog-button red-text',
            },
            {
              title: 'Keep sharing',
              default: true,
              class: 'vue-dialog-button blue-text',
            },
          ],
        });
      },
    },
  };
</script>

<style lang="scss">
  .shareable-link {
    white-space: nowrap;
  }

  .shareable-link .shareable-link__remove {
    display: none;
  }

  .shareable-link:hover .shareable-link__remove {
    display: inline-block;
  }
</style>
