<template>
  <div class="chromatogram-single chromatogram">
    <div v-show="!empty">
      <!-- should be keydown or keypress but doesn't work-->
      <GlobalEvents
        @keydown.enter="chartEnter"
        @keydown.esc="chartEsc"
        @keydown.delete="chartBackspace"
        @click="handleClickOutsideChart"
      />

      <div v-resize:throttle="onResize" style="position: relative; width: 100%">
        <!--        <loading-component-->
        <!--          v-show="updated !== true"-->
        <!--          :label="null"-->
        <!--          style="position: absolute; width: 16px; margin: 0; z-index: 100; right: 32px; top: 24px"-->
        <!--        />-->
        <div
          ref="divChart"
          class="chromatogram-chart"
          :class="classModePeakDelete"
          :style="styles"
          @click.stop="chartMouseClick"
          @mouseleave="chartMouseLeave"
          @mouseenter="chartMouseEnter"
          @mousedown="chartMouseDown"
          @mousemove="chartMouseMove"
          @mouseup="chartMouseUp"
          @touchstart="chartTouchStart"
          @touchmove="chartTouchMove"
          @touchend="chartTouchEnd"
          @dblclick="chartDblClick"
        />
        <Btn
          v-if="editable && toolbar && !isShowEditPanel"
          padding="xl"
          class="chromatogram__btn-show-edit-panel"
          @click="showEditPanel"
        >
          <IconMaterial title="build" class="chromatogram__icon-build" />
        </Btn>
        <Prompt
          v-if="isShowPrompt"
          :message="helpMessage"
          class="chromatogram-single__prompt"
          @hide="hidePrompt"
        />
      </div>

      <div
        v-if="editable && toolbar && isShowEditPanel"
        class="chromatogram-single__wrapper-edit-panel"
      >
        <ChromatogramEdit
          :id="id"
          v-model="mode"
          :isLoadingFindPeaks="peaksProcessing"
          :hasBaseline="Boolean(baseline)"
          :isFullChart="isShowFullChart"
          :hasDetectionTime="hasDetectionTime"
          class="chromatogram-single__edit-panel"
          @findPeak="peaksAuto"
          @findBaseline="baselineAuto"
          @resetBaseline="baselineReset"
          @showFullChart="$emit('showFullChart')"
          @showDetectionTime="$emit('showDetectionTime')"
          @close="hideEditPanel"
        />
      </div>

      <div
        v-if="modeBaselineManual"
        style="margin: 0 32px 24px; display: flex; align-items: center; flex-wrap: wrap"
      >
        <span style="margin-right: 5px">Draw baseline, then click finish to save it</span>
        <PopupHelper text="Restarts setpoint selection.">
          <button
            v-show="!baseline"
            class="chromatogram-single__btn-edit-baseline toolbar__button"
            @click.stop="chartEsc"
          >
            <i class="material-icons material-icon--14">clear</i>
            Start over
          </button>
        </PopupHelper>
        <PopupHelper text="Removes the most recent baseline setpoint.">
          <button
            v-show="!baseline"
            class="chromatogram-single__btn-edit-baseline toolbar__button ml-1"
            @click.stop="chartBackspace"
          >
            <i class="material-icons material-icon--14">undo</i>
            Undo
          </button>
        </PopupHelper>
        <PopupHelper text="Establishes a baseline correction based on the selected setpoints.">
          <button
            v-show="!baseline"
            class="chromatogram-single__btn-edit-baseline chromatogram-single__btn-edit-baseline--active toolbar__button toolbar__button--active ml-1"
            @click.stop="chartEnter"
          >
            <i class="material-icons material-icon--14">check</i>
            Finish
          </button>
        </PopupHelper>
      </div>

      <PrTableSamplePeaks
        v-if="showPeaksList"
        :editable="editable"
        :ppeaks="peaks"
        :measurement-socket="measurementSocket"
        :highlightedPeakId="highlightedPeakId"
        :focusedCompoundInputId.sync="focusedCompoundInputId"
        @selection="setPeakHighlighting"
      />
      <slot
        name="peaks"
        :peaks="peaks"
        :measurement-socket="measurementSocket"
        :setPeakHighlighting="setPeakHighlighting"
        :highlightedPeakId="highlightedPeakId"
        style="margin-top: 16px"
      />
    </div>
    <nothing-there-component v-show="empty">Measurement is empty</nothing-there-component>
  </div>
</template>

<script>
  import resize from 'vue-resize-directive';
  import MeasurementSocket, { MeasurementSocketEvents } from 'api/sockets/MeasurementSocket';

  import PrTableSamplePeaks from 'components/blocks/charts/chromatogram/private/PrTableSamplePeaks.vue';
  import NothingThereComponent from 'components/element/NothingThereComponent';
  import { debounce } from 'lodash';

  import {
    CHART_COLORS,
    MAGIC_VALUES_ARE_NOT_SUPPORTED_AFTER_UNIXTIME_MS,
    OUT_OF_DETECTION_VALUE,
    PADDING_HORIZONTAL,
    PADDING_VERTICAL,
  } from 'utils/chart/chart-constants.ts';

  import ChromatogramPainter, { DEFAULT_PADDING } from 'utils/chart/chromatogram.ts';
  import ChromatogramEdit from '@/uikitProject/chromatogram/panels/vueChromatogramEdit/ChromatogramEdit';
  import Btn from '@/uikitBase/btns/Btn';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import Prompt from '@/uikitProject/chromatogram/help/Prompt';
  import ChromatogramHelper from 'components/blocks/charts/chromatogram/private/ChromatogramHelper.ts';
  import NavigatorHelper from 'utils/NavigatorHelper.ts';
  import SharedMeasurementSocket, {
    SharedMeasurementSocketEvents,
  } from 'api/sockets/SharedMeasurementSocket';
  import { consoleHelpers } from 'utils/logHelpers';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper.vue';

  const EVENT_CHANGE_MEASUREMENT = 'updateMeasurement';
  const EVENT_ZOOM = 'zoom';
  const EVENT_SELECT_PEAK = 'selectPeak';
  const EVENT_UPDATE = 'update';
  const EVENT_CREATED = 'created';
  const EVENT_MOUNTED = 'mounted';
  const EVENT_UPDATE_APPLY_BASELINE = 'update:applyBaseline';
  const EVENT_DESTROY = 'destroy';

  const MODE_ZOOM = 'zoom';
  const MODE_PEAK_ADD = 'peakAdd';
  const MODE_PEAK_ADD_MAGNET = 'peakAddMagnet';
  const MODE_PEAK_DELETE = 'peakDelete';
  const MODE_BASELINE_EDIT = 'baselineEdit';
  const MODE_DETECTION_ZONE = 'selectDetectionZone';

  const RECT_DELETE_COLOR = 'rgba(252, 241, 245, 0.3)';
  const RECT_DETECTION_ZONE_COLOR = 'rgba(94,202,0, 0.1)';

  // const MIN_DETECTION_TIME_MINUTES = 0.01;

  const SHIFT_FOR_FINGER = -50;
  const PEAK_PROCESSING_TIMEOUT_MS = 5000;

  export default {
    name: 'PrChartChromatogramSingle',

    components: {
      PopupHelper,
      Prompt,
      IconMaterial,
      Btn,
      ChromatogramEdit,
      NothingThereComponent,
      PrTableSamplePeaks,
    },

    directives: { resize },

    model: {
      prop: 'measurement',
      event: EVENT_CHANGE_MEASUREMENT,
    },

    props: {
      measurement: Object,
      canSelectPeak: { type: Boolean, default: false },
      editable: { type: Boolean, default: true },
      sampleToken: { type: String, default: null },
      colorScheme: { type: Object, default: () => CHART_COLORS },
      toolbar: { type: Boolean, default: true },
      showPeaksList: { type: Boolean, default: false },
      applyBaseline: { type: Boolean, required: true },
      // Chart shouldn't be smaller than bounds
      bounds: { type: Object, default: null },
      heightPx: { type: Number, default: 300 },
      hasPadding: { type: Boolean, default: true },
      hasLiveUpdate: { type: Boolean, default: true },
      timeMin: {
        type: Number,
        default: 0,
      },
      timeMax: {
        type: Number,
      },
      hasOnlyHorizontalZoom: {
        type: Boolean,
      },
      doShowExactBounds: {
        type: Boolean,
      },

      hasSpacesIfOutOfDetectionTime: {
        type: Boolean,
      },
      isShowFullChart: {
        type: Boolean,
      },

      detectionTime: {
        type: Object,
      },

      creationDate: {
        type: Date,
      },
    },

    data() {
      return {
        mode: MODE_ZOOM, // 0 - zoom, 1 - add peak, 2 - draw baseline, 3 - delete peak
        draw: null,
        cPaint: null,
        chart: {
          mouseDownX: -1,
          mouseDownY: -1,
          touchAccepted: null,
          peakLine: null,
          zoomRect: null,
          deleteRect: null,
          baseline: null,
          baselinePoints: null,
          detectionZone: null,
        },
        internalMeasurement: this.measurement,
        measurementSocket: this.createSocket(this.measurement?.id),
        mouse: {
          startMinutes: null,
          startMau: null,
          eventStart: null,
        },
        updated: this.hasLiveUpdate ? null : true,
        mouseStartPosition: null,

        isZoomed: false,
        highlightedPeakId: null,
        focusedCompoundInputId: null,

        alreadyRendered: {
          data: null,
          peaks: null,
          baseline: null,
          applyBaseline: null,
        },

        isShowEditPanel: true,
        isShowPrompt: false,

        updateRectDebounced: null,

        listenersGroupId: null,

        peakProcessingTimeout: null,
      };
    },

    computed: {
      empty() {
        return this.internalMeasurement?.data_length <= 0;
      },
      peaks() {
        return this.internalMeasurement?.peaks;
      },
      minutesMax() {
        return this.privateTimeMax;
      },
      allPeaks() {
        if (this.peaks == null || (Array.isArray(this.peaks) && this.peaks.length === 0)) {
          return null;
        }

        if (this.applyBaseline && this.internalMeasurement?.baseline?.length) {
          return this.peaks.map((peak) => ({
            ...peak,
            start_mau:
              peak.start_mau !== undefined && peak.start_mau !== null
                ? peak.start_mau - this.getBaselineByMinute(peak.start)
                : undefined,
            end_mau:
              peak.end_mau !== undefined && peak.start_mau !== null
                ? peak.end_mau - this.getBaselineByMinute(peak.end)
                : undefined,
          }));
        }
        return this.peaks;
      },
      modeZoom: {
        get() {
          // TODO extract modes to constants
          return this.mode === MODE_ZOOM;
        },
        set(b) {
          if (b) this.mode = MODE_ZOOM;
        },
      },
      modePeakAdd: {
        get() {
          return this.mode === MODE_PEAK_ADD || this.mode === MODE_PEAK_ADD_MAGNET;
        },
        set(b) {
          this.mode = b ? MODE_PEAK_ADD : MODE_ZOOM;
        },
      },
      modePeakAddMagnet: {
        get() {
          return this.mode === MODE_PEAK_ADD_MAGNET;
        },
        set(b) {
          this.mode = b ? MODE_PEAK_ADD_MAGNET : MODE_ZOOM;
        },
      },
      modeBaselineManual: {
        get() {
          return this.mode === MODE_BASELINE_EDIT;
        },
        set(b) {
          this.mode = b ? MODE_PEAK_ADD_MAGNET : MODE_ZOOM;
        },
      },
      modePeakDelete: {
        get() {
          return this.mode === MODE_PEAK_DELETE;
        },
        set(b) {
          this.mode = b ? MODE_PEAK_DELETE : MODE_ZOOM;
        },
      },
      modeDetectionZone: {
        get() {
          return this.mode === MODE_DETECTION_ZONE;
        },
        set(b) {
          this.mode = b ? MODE_DETECTION_ZONE : MODE_ZOOM;
        },
      },
      id() {
        return this.internalMeasurement?.id;
      },
      baseline() {
        const b = this.internalMeasurement?.baseline;
        return b && b.length ? b : null;
      },
      mps() {
        return this.internalMeasurement?.mps;
      },
      data() {
        return this.internalMeasurement?.data;
      },
      peaksProcessing: {
        get() {
          return Boolean(this.internalMeasurement?.processing);
        },
        set(val) {
          if (this.internalMeasurement) {
            this.internalMeasurement.processing = val;
          }
        },
      },
      classModePeakDelete() {
        return this.modePeakDelete && 'chromatogram-chart--mode--peak-delete';
      },
      helpMessage() {
        switch (this.mode) {
          case MODE_PEAK_DELETE:
            return 'Click and hold to remove all peaks inside';
          case MODE_PEAK_ADD:
          case MODE_PEAK_ADD_MAGNET:
            return 'Click and hold to draw a new peak';
          case MODE_BASELINE_EDIT:
            return 'Click at least once to add a baseline';
          case MODE_DETECTION_ZONE:
            return `Click and hold to define a detection zone`;
          default:
            return null;
        }
      },

      dataModified() {
        return ChromatogramHelper.getModifiedData({
          data: this.data,
          baseline: this.baseline,
          isApplyBaseline: this.applyBaseline,
          isSupportMagicValues:
            this.creationDate?.getTime() < MAGIC_VALUES_ARE_NOT_SUPPORTED_AFTER_UNIXTIME_MS,
        });
      },
      styles() {
        return {
          height: `${this.heightPx}px`,
        };
      },

      hasDetectionTime() {
        return Boolean(this.detectionTime && (this.detectionTime.start || this.detectionTime.end));
      },
      privateTimeMin() {
        if (this.isShowFullChart) {
          return this.timeMin;
        }

        return this.detectionTime?.start ?? this.timeMin;
      },
      privateTimeMax() {
        if (this.isShowFullChart) {
          return this.timeMax;
        }

        if (this.detectionTime?.end) {
          return this.timeMax
            ? Math.min(this.detectionTime?.end, this.timeMax)
            : this.detectionTime?.end;
        }
        return this.timeMax;
      },
    },

    watch: {
      updated(value) {
        consoleHelpers.warn('UPDATED', value);
      },
      doShowExactBounds(value) {
        this.cPaint.updateDoShowExactBounds(value);
        this.refresh();
      },
      data: 'refresh',
      bounds: 'refresh',
      isShowFullChart: 'refresh',
      privateTimeMin(timeMin) {
        this.cPaint.scaleConfig.minutesMin = timeMin;
        this.refresh({ doResetZoom: true });
      },
      privateTimeMax(privateTimeMax) {
        this.cPaint.scaleConfig.minutesMax = privateTimeMax;
        this.refresh({ doResetZoom: true });
      },
      applyBaseline(value) {
        this.refresh({ doResetZoom: true });
        this.cPaint.toggleBaseline({ show: !value });
      },
      'chart.baselinePoints'(val) {
        const { draw } = this;

        const points = val?.map((o) => Object.values(o));
        if (!points) {
          this.chart.baseline.remove();
          this.chart.baseline = null;
          return;
        }
        if (!this.chart.baseline)
          this.chart.baseline = draw.polyline(points).fill('none').stroke({
            color: '#2491bb',
            width: 2,
            linecap: 'round',
            linejoin: 'round',
          });
        else this.chart.baseline.plot(points);
      },
      modeBaselineManual(val) {
        if (val) {
          this.$emit(EVENT_UPDATE_APPLY_BASELINE, false);
        } else {
          this.chart.baselinePoints = null;
        }
      },
      measurement(m, oldM) {
        if (m === this.internalMeasurement) return;

        const idChanged = oldM?.id !== m?.id;

        if (!idChanged && m.data == null) return;
        this.internalMeasurement = m;

        this.chart.baselinePoints = null;

        if (this.internalMeasurement?.baseline) {
          this.$emit(EVENT_UPDATE_APPLY_BASELINE, true);
        }

        if (idChanged) {
          this.updated = false;
          this.destroySocket();

          if (m?.id) {
            this.measurementSocket = this.createSocket(m.id);
          }
        }
      },
      mode() {
        this.isShowPrompt = Boolean(this.helpMessage);
      },
      baseline() {
        if (!this.applyBaseline) {
          this.cPaint.toggleBaseline({ show: true });
        }
      },
    },

    created() {
      this.$emit(EVENT_CREATED);
    },

    mounted() {
      const { colorScheme, canSelectPeak, bounds } = this;
      const { divChart } = this.$refs;

      const scaleConfig = {
        factor: 10,
        enableOverlay: true,
        minutesMin: this.privateTimeMin,
        minutesMax: this.privateTimeMax,
        mauMax: bounds?.mauMax,
        mauMin: bounds?.mauMin,
        padding: this.hasPadding ? DEFAULT_PADDING : { top: 0, bottom: 0, left: 0, right: 0 },
        hasAutoZoomMinBounds: true,
      };

      this.cPaint = new ChromatogramPainter(divChart, scaleConfig, colorScheme, {
        canSelectPeak,
        hasTimeScale: true,
        hasMauScale: true,
        doShowExactBounds: this.doShowExactBounds,
        hasSpacesIfOutOfDetectionTime: this.hasSpacesIfOutOfDetectionTime,
      });
      this.draw = this.cPaint.overlayDraw;
      this.refresh();

      this.initZoomRect();
      this.initDeleteRect();
      this.initPeakLine();
      this.initDetectionZone();
      this.cPaint.addPeakHoverListener(this.onPeakHover);
      this.cPaint.resetZoom();

      this.updateRectDebounced = debounce(() => this.cPaint.updateRect(), 1000, {
        leading: true,
      });

      this.$emit(EVENT_MOUNTED);
    },

    beforeDestroy() {
      this.destroySocket();
      this.$emit(EVENT_DESTROY);
    },

    methods: {
      showEditPanel() {
        this.isShowEditPanel = true;
      },
      hideEditPanel() {
        this.isShowEditPanel = false;
      },
      hidePrompt() {
        this.isShowPrompt = false;
      },

      setPeakHighlighting({ peakId, isSelected }) {
        this.cPaint?.setPeakHighlighting(peakId, isSelected);
      },

      confirmBaseline() {
        const points = this.chart.baselinePoints?.map((o) => {
          const point = this.cPaint.getMinutesMau(o);
          return { time: point.minutes, mau: point.mau };
        });

        if (points?.length) {
          this.baselineSet(points);
          this.$emit(EVENT_UPDATE_APPLY_BASELINE, true);
          this.chart.baselinePoints = null;
        } else {
          this.notifyError('You must add at least one baseline point!');
        }

        this.mode = MODE_ZOOM;
      },

      zoom({ pStart, pFinish }) {
        const coordsStart = this.cPaint.getMinutesMau(pStart);
        const coordsFinish = this.cPaint.getMinutesMau(pFinish);

        this.setZoomRange(
          coordsStart.minutes,
          coordsFinish.minutes,
          coordsStart.mau,
          coordsFinish.mau,
        );

        this.chart.zoomRect.hide();
      },

      refresh({ doResetZoom = false } = {}) {
        const hasUpdatedData = this.data && this.data !== this.alreadyRendered.data;
        const hasUpdatedPeaks = this.peaks !== this.alreadyRendered.peaks;
        const hasUpdatedBaseline = this.baseline !== this.alreadyRendered.baseline;
        const hasUpdatedApplyBaseline = this.applyBaseline !== this.alreadyRendered.applyBaseline;
        const hasUpdatedBounds =
          this.privateTimeMin !== this.alreadyRendered.minutesMin ||
          this.privateTimeMax !== this.alreadyRendered.minutesMax ||
          this.bounds?.mauMin !== this.alreadyRendered.mauMin ||
          this.bounds?.mauMax !== this.alreadyRendered.mauMax;

        if (
          hasUpdatedData ||
          hasUpdatedPeaks ||
          hasUpdatedBaseline ||
          hasUpdatedApplyBaseline ||
          hasUpdatedBounds
        ) {
          if (this.minutesMax) this.cPaint.updateMinutesMax(this.minutesMax);
          if (this.bounds) {
            this.cPaint.updateMauMax(this.bounds.mauMax);
            this.cPaint.updateMauMin(this.bounds.mauMin);
          }

          if (this.hasSpacesIfOutOfDetectionTime) {
            const startPosition = this.isShowFullChart
              ? null
              : this.detectionTime?.start * this.internalMeasurement.mps * 60;
            const endPosition = this.isShowFullChart
              ? null
              : this.detectionTime?.end * this.internalMeasurement.mps * 60;

            this.cPaint.addMeasurement(
              startPosition
                ? [
                    ...Array(Math.floor(startPosition)).fill(OUT_OF_DETECTION_VALUE),
                    ...this.dataModified.slice(startPosition, endPosition),
                    ...Array(
                      Math.floor(
                        this.dataModified.length - endPosition > 0
                          ? this.dataModified.length - endPosition
                          : 0,
                      ),
                    ).fill(OUT_OF_DETECTION_VALUE),
                  ]
                : this.dataModified,
              this.mps,
              'main',
              {
                drawOnly: { peaks: true, baseline: true, polyline: true },
                doResetZoom: !this.isZoomed || doResetZoom,
                doRedraw: true,
              },
            );
          } else {
            this.cPaint.addMeasurement(this.dataModified, this.mps, 'main', {
              drawOnly: { peaks: true, baseline: true, polyline: true },
              doResetZoom: !this.isZoomed || doResetZoom,
              doRedraw: true,
            });
          }

          this.cPaint.setBaselineToMeasurement(this.baseline);
          this.cPaint.setPeaksToMeasurement(this.allPeaks);

          if (hasUpdatedBaseline || !this.isZoomed || doResetZoom) {
            this.cPaint.resetZoom();
          }

          this.alreadyRendered = {
            data: this.data,
            peaks: this.peaks,
            baseline: this.baseline,
            applyBaseline: this.applyBaseline,
            minutesMin: this.privateTimeMin,
            minutesMax: this.privateTimeMax,
            mauMin: this.bounds?.mauMin ?? null,
            mauMax: this.bounds?.mauMax ?? null,
          };
        }
      },

      initZoomRect() {
        const { draw } = this;
        const zr = this.chart.zoomRect;

        if (zr == null) {
          this.chart.zoomRect = draw
            .rect(0, 0)
            .stroke({
              color: '#000',
              width: 2,
              dasharray: '10',
            })
            .fill('none')
            .hide();
        } else {
          this.chart.zoomRect = draw
            .rect(zr.width(), zr.height())
            .move(zr.x(), zr.y())
            .stroke({ color: '#000', width: 2, dasharray: '10' })
            .fill('none')
            .hide();
          if (zr.visible()) this.chart.zoomRect.show();
        }
      },
      initDeleteRect() {
        this.chart.deleteRect = this.draw.rect(0, 0, 0, 0).fill(RECT_DELETE_COLOR).hide();
      },
      initDetectionZone() {
        this.chart.detectionZone = this.draw
          .rect(0, 0, 0, 0)
          .fill(RECT_DETECTION_ZONE_COLOR)
          .hide();
      },
      initPeakLine() {
        const { draw } = this;

        this.chart.peakLine = draw
          .line(0, 0, 0, 0)
          .stroke({
            color: '#2491bb',
            width: 2,
            dasharray: '4',
          })
          .fill('none')
          .hide();
      },

      onResize() {
        this.cPaint.updateSize();
      },
      onPeakHover(peakId, isHighlighted) {
        this.highlightedPeakId = isHighlighted ? peakId : null;
      },

      getBaselineByMinute(time) {
        const index = Math.trunc(time * 60 * this.mps);
        return this.baseline?.[index] || 0;
      },
      getLastTimeCoord() {
        const { w } = this.cPaint.getHW();
        return w + PADDING_HORIZONTAL - 1;
      },

      createSocket(id) {
        if (!id) {
          throw new Error(`Can't establish a socket connection without id`);
        }

        if (!this.hasLiveUpdate) {
          return {};
        }

        const onMeasurement = (m) => {
          if (m.id === this.id && m.data == null) {
            return;
          }

          const wasFullscreen = this.cPaint.isFullscreen();

          this.internalMeasurement = { ...this.internalMeasurement, ...m };

          consoleHelpers.warn('ON MEASUREMENT', m);
          this.$emit(EVENT_CHANGE_MEASUREMENT, this.internalMeasurement);

          if ('baseline' in m) {
            this.$emit(EVENT_UPDATE_APPLY_BASELINE, Boolean(m.baseline?.length));
          }

          this.refresh({ doResetZoom: wasFullscreen });
          consoleHelpers.warn('UPDATED TRUE', m);
          this.updated = true;
        };

        const onConnection = async () => {
          // const measurement = await connection.get();
          // onMeasurement(measurement);
        };

        const socket = this.sampleToken
          ? SharedMeasurementSocket.start(id, onConnection, {
              shareToken: this.sampleToken,
            })
          : MeasurementSocket.start(id, onConnection);

        const socketEvents = this.sampleToken
          ? SharedMeasurementSocketEvents
          : MeasurementSocketEvents;

        const listenersGroup = socket.createEventListenersGroup();
        this.listenersGroupId = listenersGroup.id;

        listenersGroup.addEventListener(socketEvents.PEAKS, onMeasurement);

        return socket;
      },

      peakAddByMinutesMau(aMinutes, aMau, bMinutes, bMau) {
        this.peakAddByIndexMau(aMinutes * 60 * this.mps, aMau, bMinutes * 60 * this.mps, bMau);
      },

      peakAddByIndexMau(aIndex, aMau, bIndex, bMau) {
        const { mps, modePeakAddMagnet } = this;
        const start = { mau: null, time: aIndex / mps / 60 };
        const end = { mau: null, time: bIndex / mps / 60 };
        if (!modePeakAddMagnet) {
          start.mau = aMau;
          end.mau = bMau;
          if (this.applyBaseline) {
            start.mau += this.baseline?.[Math.round(aIndex)] || 0;
            end.mau += this.baseline?.[Math.round(bIndex)] || 0;
          }
        }
        if (start.time <= end.time) this.peakAdd(start, end);
        else this.peakAdd(end, start);
      },

      setZoomRange(minutesMin, minutesMax, min, max) {
        let [_minutesMin, _minutesMax, _min, _max] = [minutesMin, minutesMax, min, max];
        if (_minutesMin > _minutesMax) {
          [_minutesMin, _minutesMax] = [_minutesMax, _minutesMin];
        }

        if (_min > _max) {
          [_min, _max] = [_max, _min];
        }

        const minimumIntervalInMinutes = 0.00166; // 0.1 second

        const hasMovementX =
          _minutesMax - _minutesMin > minimumIntervalInMinutes > minimumIntervalInMinutes;
        const hasMovementY = this.hasOnlyHorizontalZoom || _max - _min !== 0;
        const hasMovement = hasMovementX && hasMovementY;

        if (!hasMovement && this.isZoomed) {
          this.cPaint.resetZoom();
          this.isZoomed = false;
        } else if (hasMovement) {
          const zoomBounds = {
            minutesMin: _minutesMin,
            minutesMax: _minutesMax,
          };

          if (!this.hasOnlyHorizontalZoom) {
            zoomBounds.mauMin = _min;
            zoomBounds.mauMax = _max;
          }

          this.cPaint.setZoom(zoomBounds);

          this.isZoomed = true;
        }
      },

      resetZoom() {
        this.cPaint.resetZoom();
      },

      chartDblClick() {
        if (window.getSelection) window.getSelection().removeAllRanges();
        else if (document.selection) document.selection.empty();
        this.cPaint.resetZoom();

        this.isZoomed = false;
      },

      chartMouseLeave() {
        this.chart.zoomRect?.hide();
        this.chart.deleteRect?.hide();
        this.chart.peakLine?.hide();
        this.cPaint?.resetHighlightForDelete();

        if (this.modeBaselineManual) {
          const points = this.chart.baselinePoints?.map((o) => Object.values(o));
          if (points?.length) {
            this.chart.baseline?.plot([
              ...points,
              [this.getLastTimeCoord(), points[points.length - 1][1]],
            ]);
          }
        }
      },
      chartMouseEnter(e) {
        if (e.buttons === 0) return;
        if (this.chart.mouseDownX === -1) return;
        if (this.modeZoom) {
          this.chart.zoomRect.show();
        } else if (this.modePeakAdd) {
          this.chart.peakLine.show();
        } else if (this.modePeakDelete) {
          this.chart.deleteRect.show();
        }
      },

      chartEnter() {
        if (this.modeBaselineManual) {
          this.confirmBaseline();
        }
      },

      chartEsc() {
        if (this.modeBaselineManual) {
          this.chart.baselinePoints = null;
        }
      },

      async chartBackspace() {
        if (this.modeBaselineManual && this.chart.baselinePoints) {
          this.chart.baselinePoints.pop();

          if (this.chart.baselinePoints.length === 0) {
            this.chart.baselinePoints = null;
          } else {
            await this.$nextTick();
            const points = this.chart.baselinePoints?.map((o) => Object.values(o));
            this.chart.baseline.plot([
              ...points,
              [this.getLastTimeCoord(), points[points.length - 1][1]],
            ]);
          }
        }
      },

      async chartMouseClick(e) {
        this.cPaint.updateRect();
        const p = this.cPaint.getXY(e);
        const lastTimeCoord = this.getLastTimeCoord();

        if (this.modeBaselineManual && !NavigatorHelper.isTouchDevice) {
          // If user clicked outside of the chart
          if (p.x <= PADDING_HORIZONTAL) p.x = PADDING_HORIZONTAL + 1;
          if (p.x > lastTimeCoord) p.x = lastTimeCoord;
          if (this.chart.baselinePoints?.length) {
            const lastPoint = this.chart.baselinePoints[this.chart.baselinePoints.length - 1];
            if (p.x > lastPoint.x) {
              this.chart.baselinePoints.push(p);
            } else {
              return;
            }
          } else {
            this.chart.baselinePoints = [{ x: PADDING_HORIZONTAL + 1, y: p.y }, p];
          }

          await this.$nextTick();

          const points = this.chart.baselinePoints?.map((o) => Object.values(o));
          this.chart.baseline.plot([...points, [lastTimeCoord, points[points.length - 1][1]]]);

          if (p.x === lastTimeCoord) {
            // Finish baseline if the last point was set
            this.confirmBaseline();
          }
        }

        if (p.x < PADDING_HORIZONTAL) {
          return;
        }

        if (this.canSelectPeak && !this.modePeakAdd && !this.modeBaselineManual) {
          const peakId = this.cPaint.getPeakIdByPoint({
            x: p.x - PADDING_HORIZONTAL,
            y: p.y - PADDING_VERTICAL,
          });
          if (peakId) {
            this.$emit(EVENT_SELECT_PEAK, peakId);
            this.$emit(EVENT_UPDATE);
            this.focusedCompoundInputId = peakId;
          }
        }

        if (this.modePeakAdd) {
          this.modePeakAdd = false;
        }
      },

      chartMouseDown(e) {
        this.cPaint.updateRect();

        const p = this.cPaint.getXY(e);
        this.chart.mouseDownX = p.x;
        this.chart.mouseDownY = p.y;

        const coords = this.cPaint.getMinutesMau(p);
        this.mouse.startMinutes = coords.minutes;
        this.mouse.startMau = coords.mau;
        this.mouse.eventStart = e;

        if (this.modeZoom) {
          this.chart.zoomRect.move(p.x, p.y).size(0, 0).show();
        } else if (this.modePeakAdd) {
          this.chart.peakLine.plot(p.x, p.y, p.x, p.y).show();
        } else if (this.modePeakDelete) {
          const timeMin = Math.min(p.x, this.chart.mouseDownX) - PADDING_HORIZONTAL;
          const privateTimeMax = timeMin + Math.abs(p.x - this.chart.mouseDownX);
          this.cPaint?.highlightForDeleteInside(timeMin, privateTimeMax);
        }
      },
      async chartMouseMove(e) {
        if (this.modeBaselineManual) {
          this.updateRectDebounced();

          const p = this.cPaint.getXY(e);

          const lastTimeCoord = this.getLastTimeCoord();
          const baselinePoints = this.chart.baselinePoints?.map((o) => Object.values(o));
          let baselinePointsToDraw;

          if (baselinePoints?.length) {
            const [lastPointX] = baselinePoints[baselinePoints.length - 1];
            if (p.x > lastTimeCoord) p.x = lastTimeCoord;
            baselinePointsToDraw =
              p.x > lastPointX
                ? [...baselinePoints, [p.x, p.y], [lastTimeCoord, p.y]]
                : [
                    ...baselinePoints,
                    [lastTimeCoord, baselinePoints[baselinePoints.length - 1][1]],
                  ];
          } else {
            if (!this.chart.baselinePoints) {
              this.chart.baselinePoints = [];
              await this.$nextTick();
            }
            baselinePointsToDraw = [
              [PADDING_HORIZONTAL + 1, p.y],
              [lastTimeCoord, p.y],
            ];
          }
          this.chart.baseline.plot(baselinePointsToDraw);
          return;
        }

        if (e.buttons === 0) return;

        const p = this.cPaint.getXY(e);

        if (this.modeZoom) {
          this.hasOnlyHorizontalZoom
            ? this.chart.zoomRect
                .move(Math.min(p.x, this.chart.mouseDownX), 24)
                .size(
                  Math.abs(p.x - this.chart.mouseDownX),
                  this.draw?.node.getBoundingClientRect().height - 48,
                )
            : this.chart.zoomRect
                .move(Math.min(p.x, this.chart.mouseDownX), Math.min(p.y, this.chart.mouseDownY))
                .size(Math.abs(p.x - this.chart.mouseDownX), Math.abs(p.y - this.chart.mouseDownY));
        } else if (this.modePeakAdd) {
          this.chart.peakLine.plot(this.chart.mouseDownX, this.chart.mouseDownY, p.x, p.y);
        } else if (this.modePeakDelete) {
          this.chart.deleteRect
            .move(Math.min(p.x, this.chart.mouseDownX), 24)
            .size(
              Math.abs(p.x - this.chart.mouseDownX),
              this.draw?.node.getBoundingClientRect().height - 48,
            )
            .show();

          const timeMin = Math.min(p.x, this.chart.mouseDownX) - PADDING_HORIZONTAL;
          const privateTimeMax = timeMin + Math.abs(p.x - this.chart.mouseDownX);
          this.cPaint?.highlightForDeleteInside(timeMin, privateTimeMax);
        } else if (this.modeDetectionZone) {
          const p = this.cPaint.getXY(e);

          this.chart.detectionZone
            .move(Math.min(p.x, this.chart.mouseDownX), 24)
            .size(
              Math.abs(p.x - this.chart.mouseDownX),
              this.draw?.node.getBoundingClientRect().height - 48,
            )
            .show();
        }
      },
      chartMouseUp(e) {
        const { eventStart } = this.mouse;
        if (this.chart.mouseDownX === -1) return;

        const pFinish = this.cPaint.getXY(e);

        if (this.modeZoom) {
          const pStart = this.cPaint.getXY(eventStart);

          this.$emit(EVENT_ZOOM, { pStart, pFinish });
          this.zoom({ pStart, pFinish });
        } else if (this.modePeakAdd) {
          const coords = this.cPaint.getMinutesMau(pFinish);
          this.peakAddByMinutesMau(
            this.mouse.startMinutes,
            this.mouse.startMau,
            coords.minutes,
            coords.mau,
          );
          this.chart.peakLine.hide();
        } else if (this.modePeakDelete) {
          this.chart.deleteRect.move(pFinish.x, 0).size(0, 100).show();
          this.mode = MODE_ZOOM;
          const { peaksForDelete } = this.cPaint;
          this.removePeaks(peaksForDelete.map(({ id }) => id));
          this.cPaint?.resetHighlightForDelete();
          this.chart.deleteRect.hide();
        } else if (this.modeDetectionZone) {
          const pStart = this.cPaint.getXY(eventStart);

          const coordsStart = this.cPaint.getMinutesMau(pStart);
          const coordsFinish = this.cPaint.getMinutesMau(pFinish);

          this.saveDetectionTime(coordsStart.minutes, coordsFinish.minutes);

          this.chart.detectionZone.move(pFinish.x, 0).size(0, 100).show();
          this.chart.detectionZone.hide();

          this.mode = MODE_ZOOM;
        }

        this.chart.mouseDownX = -1;
      },

      // todo: merge with @chartMouseDown
      chartTouchStart(e) {
        this.cPaint.updateRect();

        this.touchAccepted = null;

        const p = this.cPaint.getXY(e.changedTouches[0]);
        this.chart.mouseDownX = p.x;
        this.chart.mouseDownY = p.y;

        const coords = this.cPaint.getMinutesMau(p);
        this.mouse.startMinutes = coords.minutes;
        this.mouse.startMau = coords.mau;
        this.mouse.eventStart = e;

        if (this.modeBaselineManual) {
          if (!this.chart.baselinePoints) {
            this.chart.baselinePoints = [];
          }
        }

        if (this.modeZoom) {
          this.chart.zoomRect.move(p.x, p.y).size(0, 0).show();
        } else if (this.modePeakAdd) {
          this.chart.peakLine.plot(p.x, p.y, p.x, p.y).show();
        } else if (this.modePeakDelete) {
          this.chart.deleteRect.move(p.x, 0).size(0, 100).show();
        }
      },
      // todo: merge with @chartMouseMove
      chartTouchMove(e) {
        const p = this.cPaint.getXY(e.changedTouches[0]);
        const dx = Math.abs(p.x - this.chart.mouseDownX);
        const dy = Math.abs(p.y - this.chart.mouseDownY);

        if (this.touchAccepted == null && !this.modeBaselineManual) {
          if (dx * dx + dy * dy > 400) {
            if (dx < dy) {
              this.touchAccepted = false;
              this.chartMouseLeave();
              this.sendTouchEvent(
                e.changedTouches[0].pageX,
                e.changedTouches[0].pageY,
                document.body,
                'touchstart',
              );
              return;
            }
            this.touchAccepted = true;
          }
        } else if (this.touchAccepted === false) {
          return;
        }

        e.preventDefault();
        e.returnValue = false;

        if (this.modeBaselineManual) {
          const points = this.chart.baselinePoints?.map((o) => Object.values(o));

          if (points?.length) {
            const [lastPointX] = points[points.length - 1];
            if (p.x > lastPointX && p.x < this.getLastTimeCoord()) {
              this.chart.baseline.plot([
                ...points,
                [p.x, p.y + SHIFT_FOR_FINGER],
                [this.getLastTimeCoord(), p.y + SHIFT_FOR_FINGER],
              ]);
            } else {
              this.chart.baseline.plot([
                ...points,
                [this.getLastTimeCoord(), points[points.length - 1][1]],
              ]);
            }
          } else {
            if (p.x < PADDING_HORIZONTAL || p.x > this.getLastTimeCoord()) {
              this.chart.baseline.plot([]);
              return;
            }

            this.chart.baseline.plot([
              [PADDING_HORIZONTAL + 1, p.y + SHIFT_FOR_FINGER],
              [this.getLastTimeCoord(), p.y + SHIFT_FOR_FINGER],
            ]);
          }
        }

        if (this.modeZoom) {
          this.hasOnlyHorizontalZoom
            ? this.chart.zoomRect
                .move(Math.min(p.x, this.chart.mouseDownX), 24)
                .size(dx, this.draw?.node.getBoundingClientRect().height - 48)
            : this.chart.zoomRect
                .move(Math.min(p.x, this.chart.mouseDownX), Math.min(p.y, this.chart.mouseDownY))
                .size(dx, dy);
        } else if (this.modePeakAdd) {
          this.chart.peakLine.plot(this.chart.mouseDownX, this.chart.mouseDownY, p.x, p.y);
        } else if (this.modePeakDelete) {
          this.chart.deleteRect
            .move(Math.min(p.x, this.chart.mouseDownX), 24)
            .size(
              Math.abs(p.x - this.chart.mouseDownX),
              this.draw?.node.getBoundingClientRect().height - 48,
            )
            .show();

          const timeMin = Math.min(p.x, this.chart.mouseDownX) - PADDING_HORIZONTAL;
          const privateTimeMax = timeMin + Math.abs(p.x - this.chart.mouseDownX);
          this.cPaint?.highlightForDeleteInside(timeMin, privateTimeMax);
        } else if (this.modeDetectionZone) {
          this.chart.detectionZone
            .move(Math.min(p.x, this.chart.mouseDownX), 24)
            .size(
              Math.abs(p.x - this.chart.mouseDownX),
              this.draw?.node.getBoundingClientRect().height - 48,
            )
            .show();
        }
      },
      // todo: merge with @chartMouseUp
      async chartTouchEnd(e) {
        if (this.touchAccepted === false) {
          return;
        }

        e.returnValue = true;

        if (this.chart.mouseDownX === -1) return;

        const pStart = this.cPaint.getXY(this.mouse.eventStart.changedTouches[0]);
        const pFinish = this.cPaint.getXY(e.changedTouches[0]);

        const coords = this.cPaint.getMinutesMau(pFinish);

        if (this.modeBaselineManual) {
          const pointShifted = {
            x: pFinish.x,
            y: pFinish.y + SHIFT_FOR_FINGER,
          };
          if (pointShifted.x < PADDING_HORIZONTAL || pointShifted.x > this.getLastTimeCoord()) {
            return;
          }

          if (this.chart.baselinePoints?.length) {
            const lastPoint = this.chart.baselinePoints[this.chart.baselinePoints.length - 1];
            if (pointShifted.x > lastPoint.x) {
              this.chart.baselinePoints.push(pointShifted);
            } else {
              return;
            }
          } else {
            this.chart.baselinePoints.push(
              { x: PADDING_HORIZONTAL + 1, y: pointShifted.y },
              pointShifted,
            );
          }

          await this.$nextTick();

          const points = this.chart.baselinePoints?.map((o) => Object.values(o));
          if (points?.length) {
            this.chart.baseline.plot([
              ...points,
              [this.getLastTimeCoord(), points[points.length - 1][1]],
            ]);
          }
        }

        if (this.modeZoom) {
          this.$emit(EVENT_ZOOM, { pStart, pFinish });

          this.setZoomRange(
            this.mouse.startMinutes,
            coords.minutes,
            this.mouse.startMau,
            coords.mau,
          );
          this.chart.zoomRect.hide();
        } else if (this.modePeakAdd) {
          this.peakAddByMinutesMau(
            this.mouse.startMinutes,
            this.mouse.startMau,
            coords.minutes,
            coords.mau,
          );

          this.chart.peakLine.hide();
        } else if (this.modePeakDelete) {
          this.chart.deleteRect.move(pFinish.x, 0).size(0, 100).show();
          // this.setZoomRange(this.mouse.startMinutes, coords.minutes, this.mouse.startMau, coords.mau);
          this.mode = MODE_ZOOM;
          const { peaksForDelete } = this.cPaint;
          this.removePeaks(peaksForDelete.map(({ id }) => id));
          peaksForDelete.forEach(({ id }) =>
            this.showNotificationIfRpcError(() => this.measurementSocket.peakRemove(id)),
          );
          this.cPaint?.resetHighlightForDelete();
          this.chart.deleteRect.hide();
        } else if (this.modeDetectionZone) {
          const coordsStart = this.cPaint.getMinutesMau(pStart);
          const coordsFinish = this.cPaint.getMinutesMau(pFinish);

          this.saveDetectionTime(coordsStart.minutes, coordsFinish.minutes);

          this.chart.detectionZone.move(pFinish.x, 0).size(0, 100).show();
          this.chart.detectionZone.hide();

          this.mode = MODE_ZOOM;
        }

        this.chart.mouseDownX = -1;
      },

      sendTouchEvent(x, y, element, eventType) {
        const touchObj = new Touch({
          identifier: Date.now(),
          target: element,
          clientX: x,
          clientY: y,
          radiusX: 2.5,
          radiusY: 2.5,
          rotationAngle: 10,
          force: 0.5,
        });

        const touchEvent = new TouchEvent(eventType, {
          cancelable: true,
          bubbles: true,
          touches: [touchObj],
          targetTouches: [],
          changedTouches: [touchObj],
          shiftKey: true,
        });

        element.dispatchEvent(touchEvent);
      },

      // socket methods
      destroySocket() {
        if (!this.hasLiveUpdate) {
          return;
        }

        if (this.measurementSocket) {
          this.measurementSocket.close(this.listenersGroupId);
        }
      },

      peaksAuto() {
        const findPeaks = () => {
          if (this.peakProcessingTimeout) {
            clearTimeout(this.peakProcessingTimeout);
          }
          this.peaksProcessing = true;
          this.showNotificationIfRpcError(() => this.measurementSocket.peaksAuto());
          this.peakProcessingTimeout = setTimeout(() => {
            this.peaksProcessing = false;
          }, PEAK_PROCESSING_TIMEOUT_MS);
        };

        if (this.allPeaks?.length)
          this.$modal.show('dialog', {
            title: 'Find peaks',
            text: 'All existing peaks will be replaced with automatically found.',
            buttons: [
              {
                title: 'Find peaks',
                handler: () => {
                  findPeaks();
                  this.$modal.hide('dialog');
                },
                class: 'vue-dialog-button blue-text',
              },
              {
                title: 'Cancel',
                default: true,
                class: 'vue-dialog-button red-text',
              },
            ],
          });
        else findPeaks();
      },
      peakAdd(start, end) {
        this.showNotificationIfRpcError(() => this.measurementSocket.peakAdd(start, end));
        this.$emit(EVENT_UPDATE);
      },
      removePeaks(ids) {
        this.showNotificationIfRpcError(() => this.measurementSocket.peaksListRemove(ids));
        this.$emit(EVENT_UPDATE);
      },

      baselineAuto() {
        this.showNotificationIfRpcError(() => this.measurementSocket.baselineAuto());
        this.$emit(EVENT_UPDATE);
        this.$emit(EVENT_UPDATE_APPLY_BASELINE, true);
      },
      baselineSet(points) {
        this.showNotificationIfRpcError(() => this.measurementSocket.baselineSet(points));
        this.$emit(EVENT_UPDATE);
      },
      baselineReset() {
        this.showNotificationIfRpcError(() => this.measurementSocket.baselineReset());
        this.$emit(EVENT_UPDATE);
      },

      handleClickOutsideChart() {
        if (this.modeBaselineManual) {
          this.confirmBaseline();
        } else if (this.modeDetectionZone) {
          this.chart.detectionZone.hide();
          this.mode = MODE_ZOOM;
        }
      },

      saveDetectionTime() {
        // const isEnoughTime = Math.abs(minutesA - minutesB) > MIN_DETECTION_TIME_MINUTES;
        //
        // if (isEnoughTime) {
        //   this.$emit(EVENT_DETECTION_TIME, {
        //     timeMin: Math.min(minutesA, minutesB),
        //     timeMax: Math.max(minutesA, minutesB),
        //   });
        // } else {
        //   this.notifyError('Selected period is too small');
        // }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chromatogram-single {
    &__wrapper-edit-panel {
      display: flex;
      justify-content: center;
      margin-bottom: 8px;
      padding-top: 16px;

      @media print {
        display: none;
      }

      @media (max-width: $screen-xs-max) {
        overflow-x: auto;
        justify-content: flex-start;

        &::before,
        &::after {
          content: '';
          display: block;
          width: 16px;
          flex: none;
        }
      }
    }

    &__prompt {
      position: absolute;
      top: 30px;
      right: 40px;
      z-index: 5;
    }

    &__btn-edit-baseline {
      padding: 4px 12px;

      &--active {
        background: $color-bg-primary;
        color: $color-text-on-primary;

        &:enabled:hover,
        &:enabled:focus {
          background: $color-bg-primary--hover;
        }

        &:enabled:active {
          background: $color-bg-primary--active;
          color: $color-text-on-primary--active;
        }

        &:disabled,
        &[disabled] {
          cursor: not-allowed;
          color: $color-text-on-primary--disabled;
          background-color: $color-bg-primary--disabled;
        }
      }
    }
  }
</style>

<style lang="scss">
  .chromatogram {
    padding: 0;
    margin: 0 0 0 0;

    &__btn-show-edit-panel {
      position: absolute;
      top: 20px;
      right: 40px;
    }

    &__icon-build {
      color: $color-text-primary;
    }
  }

  .chromatogram-chart {
    width: 100%;
    margin: 0;

    &:not(&--mode--peak-delete) {
      svg {
        .peak-highlight {
          &:hover {
            fill: url(#backgroundHatching);
          }
        }
      }
    }
  }
</style>
