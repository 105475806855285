<template>
  <div
    v-if="
      configuration.hasOwnProperty('autosampler_fraction_collector') &&
      configuration.autosampler_fraction_collector != null
    "
    class="hardware-tile"
  >
    <div class="hardware-tile__wrapper-title">
      <div>
        <i class="hardware-tile__title-icon">group_work</i>
        <h4>Autosampler fraction collector</h4>
      </div>
      <Information :data="popupInfoData" class="hardware-tile__information" />
    </div>

    <hardware-state-component
      :state="currentState"
      :errors="errors"
      data-test-id="autosampler-status"
    />
    <HardwareErrorComponent :errors="errors" :error-list="errorList" />

    <!--  OPERATIONS  -->

    <template v-if="operation === 'INJECTION'">
      <div class="operation text-process">
        <i>colorize</i>
        <span>
          Injecting {{ stateDetails.amount }} µL
          <br />
          from {{ stateDetails.vial }}
        </span>
      </div>

      <template v-if="stateDetails.vial && stateDetails.vial > 0">
        <div class="inline-block font-black mt-2 text-2xl">{{ vial || stateDetails.vial }}</div>
        <div class="font-black text-gray">Vial</div>
      </template>
    </template>

    <div v-else-if="operation === 'WASHING'" class="operation text-process">
      <i>gradient</i>
      <span>Washing needle</span>
    </div>

    <div v-else-if="operation === 'SHAKING'" class="operation text-process">
      <i>sync_alt</i>
      <span>Shaking</span>
    </div>

    <div v-else-if="operation === 'GETTING_READY'" class="operation text-yellow">
      <i>rotate_left</i>
      <span>Getting ready</span>
    </div>

    <div v-else-if="operation === 'WASHING_LOOP'" class="operation text-yellow">
      <i>360</i>
      <span>Washing loop</span>
    </div>
    <div v-else-if="vialInUse != null" class="operation">
      <TrayVials
        v-model="vialInUse"
        :config="trayConfig"
        :isDisabled="true"
        :isProcessing="true"
        class="fraction-collector__tray"
        :class="{ 'fraction-collector__tray--small': trayConfig === '48_6x8' }"
        data-test-id="popup-tray-vials"
      />
    </div>

    <div v-else-if="!isInjectionInProgress" class="hardware-tile__wrapper-vial">
      <BtnSelectVial
        :isDisabled="state.ready !== true"
        :trayConfig="injectionTrayConfig"
        @select="setVial"
      />
    </div>

    <div v-if="canWashNeedle || canShake" class="mt-8">
      <Btn v-if="canWashNeedle" class="mr-2" icon-left="gradient" height="s" @click="washNeedle">
        Wash needle
      </Btn>
      <Btn v-if="canShake" class="mt-2" icon-left="sync_alt" height="s" @click="shake"> Shake </Btn>
    </div>

    <div v-if="canGetReady" class="mt-8">
      <Btn class="mt-2" icon-left="rotate_left" height="s" @click="get_ready">Cancel</Btn>
    </div>
  </div>
</template>

<script>
  import Base from './Base.vue';
  import 'assets/css/base/animation.scss';
  import BtnSelectVial from '../../../uikitProject/vials/vueBtnSelectVial/BtnSelectVial';
  import Btn from '@/uikitBase/btns/Btn';
  import HardwareErrorComponent from 'components/element/HardwareErrorComponent';
  import { AUTOSAMPLER_ERRORS, GENERAL_ERRORS } from '@/constants/hardware/errors';
  import Information from './private/Information';
  import TrayVials from '@/uikitProject/vials/vueTrayVials/TrayVials';
  import { autosamplerCommands } from '@/constants/hardware/commands';

  const EVENT_START = 'start';

  export default {
    components: {
      TrayVials,
      Information,
      HardwareErrorComponent,
      Btn,
      BtnSelectVial,
    },

    extends: Base,

    data: () => ({
      vial: null,
    }),

    computed: {
      vialInUse() {
        return this.state?.autosampler_fraction_collector_details?.vialInUse;
      },

      operations() {
        return this.configuration.autosampler_fraction_collector.operations ?? [];
      },
      currentState() {
        return this.state?.autosampler_fraction_collector;
      },
      stateDetails() {
        return this.state?.autosampler_fraction_collector_details;
      },
      operation() {
        return this.stateDetails?.operation;
      },
      errors() {
        return this.stateDetails?.errors;
      },
      errorList() {
        return { ...GENERAL_ERRORS, ...AUTOSAMPLER_ERRORS };
      },
      isInjectionInProgress() {
        return this.state.injection_id != null;
      },
      injectionTrayConfig() {
        const trayHardware = this.configuration.autosampler_fraction_collector;
        if (
          trayHardware &&
          trayHardware?.supported_injection_trays &&
          trayHardware?.injection_tray != null
        ) {
          return Object.entries(trayHardware.supported_injection_trays).find(
            ([, id]) => id === trayHardware?.injection_tray,
          )[0];
        }
        return null;
      },

      popupInfoData() {
        const { firmware_version, id, serial } = this.configuration.autosampler_fraction_collector;

        const data = [
          {
            name: 'Id',
            value: id,
          },
          {
            name: 'Serial',
            value: serial,
          },
          {
            name: 'Firmware Version',
            value: firmware_version
              ? `${firmware_version.major}.${firmware_version.minor}`
              : 'Not available',
          },
          {
            name: 'Tray',
            value: this.trayConfig,
          },
        ];

        return data;
      },

      commands() {
        return this.stateDetails?.commandDetails ?? {};
      },

      canWashNeedle() {
        return this.commands[autosamplerCommands.WASH]?.status.available;
      },
      canShake() {
        return this.commands[autosamplerCommands.SHAKE]?.status.available;
      },
      canGetReady() {
        return this.commands[autosamplerCommands.GET_READY]?.status.available;
      },
    },

    methods: {
      setVial(vial) {
        this.vial = vial;
        this.start();
      },

      start() {
        const { vial } = this;
        this.$emit(EVENT_START, vial);
      },

      washNeedle() {
        this.ws.command('autosamplerFractionCollector', autosamplerCommands.WASH);
      },
      shake() {
        this.ws.command('autosamplerFractionCollector', autosamplerCommands.SHAKE);
      },
      get_ready() {
        this.ws.command('autosamplerFractionCollector', autosamplerCommands.GET_READY);
      },
    },
  };
</script>

<style lang="scss">
  @import '~assets/css/base/icons';
  @import '~assets/css/base/text';
  @import '~assets/css/base/animation';

  .hardware-tile {
    .operation {
      text-align: center;
      align-content: center;
      margin: 32px auto auto;

      span {
        font-weight: bold;
        margin-bottom: 4px;
      }

      i {
        @extend .material-icons;
        @extend .material-icon--24;
        @extend .blink;
        display: block;
        margin: auto;
      }
    }
  }
</style>
