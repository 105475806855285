<template>
  <StyledInput
    ref="input"
    :value="value"
    :isValid="isValid"
    type="number"
    :isChangeOnBlur="isChangeOnBlur"
    :isDisabled="isDisabled"
    @change="change"
    @blur="$emit('blur')"
  />
</template>

<script>
  import StyledInput from '../../shared/StyledInput';

  const EVENT_CHANGE = 'change';

  export default {
    name: 'InputTypeNumber',

    components: {
      StyledInput,
    },

    props: {
      value: {
        type: [String, Number],
        required: true,
      },
      isValid: {
        type: Boolean,
        default: true,
      },
      isFloat: {
        type: Boolean,
        default: false,
      },
      isChangeOnBlur: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      change(value) {
        // We need to prevent empty input
        const valueNumber = Number(value);

        const isNegativeAllowed = this.$attrs.min < 0;

        if (value === '' || (!isNegativeAllowed && valueNumber < 0)) {
          this.$refs.input.$el.value = '0';
        }

        if (this.isFloat && Number.isInteger(valueNumber)) {
          this.$refs.input.$el.value = valueNumber.toFixed(1);
        }

        this.$emit(EVENT_CHANGE, this.$refs.input.$el.value);
      },
    },
  };
</script>
