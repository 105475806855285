<template>
  <div class="checkbox" :class="classes">
    <input
      :id="id"
      :checked="checked"
      :disabled="isDisabled"
      type="checkbox"
      class="checkbox__input-check"
      @change="changeValue"
    />

    <slot :id="id" name="view" :isChecked="checked" :isDisabled="isDisabled" :toggle="toggle">
      <label
        :for="id"
        class="checkbox__view disable-selection"
        data-test="checkboxView"
        @click.self="click"
      >
        <IconMaterial title="done" class="checkbox__icon-selected" />
      </label>

      <label
        v-if="$slots.default"
        :for="id"
        class="checkbox__label"
        data-test="checkboxLabel"
        @click="click"
      >
        <slot />
      </label>
    </slot>
  </div>
</template>

<script>
  import { generateId } from '@/utils/generateId.ts';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  const EVENT_MODEL = 'change';

  export default {
    name: 'Checkbox',

    components: { IconMaterial },

    model: {
      prop: 'checked',
      event: EVENT_MODEL,
    },

    props: {
      id: {
        type: String,
        default: () => generateId(),
      },
      checked: {
        type: Boolean,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      classCheckboxSelected() {
        return this.checked && 'checkbox--selected';
      },
      classDisabled() {
        return this.isDisabled && 'checkbox--disabled';
      },
      classes() {
        return [this.classCheckboxSelected, this.classDisabled];
      },
    },

    methods: {
      click(e) {
        if (this.isDisabled) e.preventDefault();
      },
      changeValue(e) {
        this.$emit(EVENT_MODEL, e.target.checked);
      },
      toggle() {
        this.$emit(EVENT_MODEL, !this.checked);
      },
    },
  };
</script>

<style lang="scss" scoped>
  $color-border-not-selected: $pure-color__black--alp-42;
  $color-border-not-selected--hover: $pure-color__black--alp-60;
  $color-border-not-selected--active: $pure-color__black--alp-12;
  $color-border-not-selected--disabled: $pure-color__black--alp-12;

  .checkbox {
    display: inline-flex;
    align-items: baseline;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
    }

    &__input-check {
      display: none;
    }

    &__view {
      position: relative;

      flex: none;
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: white;
      border: 1px solid $color-border-not-selected;
      border-radius: 2px;
      overflow: hidden;
      transition: border-color 0.1s, background-color 0.1s;

      color: white;
      font-size: 10px;
      cursor: inherit;

      @include isHoverDevice {
        &:hover {
          border: 1px solid $color-border-not-selected--hover;
        }

        &:active {
          border: 1px solid $color-border-not-selected--active;
        }
      }

      .checkbox--selected & {
        border-color: $color-bg-primary;
        background-color: $color-bg-primary;

        @include isHoverDevice {
          &:hover {
            border: 1px solid $color-bg-primary--hover;
            background-color: $color-bg-primary--hover;
          }

          &:active {
            border: 1px solid $color-bg-primary--active;
            background-color: $color-bg-primary--active;
          }
        }
      }

      .checkbox--disabled & {
        background-color: $color-bg-transparent--disabled;
        border-color: $color-border-not-selected--disabled;

        @include isHoverDevice {
          &:hover {
            border-color: $color-border-not-selected--disabled;
          }

          &:active {
            border-color: $color-border-not-selected--disabled;
          }
        }
      }

      .checkbox--disabled.checkbox--selected & {
        border-color: transparent;
        background-color: $color-bg-primary--disabled;

        @include isHoverDevice {
          &:hover {
            border-color: transparent;
            background-color: $color-bg-primary--disabled;
          }

          &:active {
            border-color: transparent;
            background-color: $color-bg-primary--disabled;
          }
        }
      }
    }

    &__icon-selected {
      opacity: 0;
      transition: opacity 0.1s;
      font-weight: bold;
      width: auto;
      height: auto;

      .checkbox--selected & {
        opacity: 1;
      }
    }

    &__label {
      cursor: inherit;
      padding-left: 8px;
      color: inherit;
      font-weight: inherit;
      font-size: inherit;
    }
  }
</style>
