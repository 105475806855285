<template>
  <div class="select-method">
    <MethodEditModal
      :name="modalName"
      :method="methodData"
      :configuration="configuration"
      :deviceId="deviceId"
      @update:method="onMethodChange"
      @created="onMethodChange"
    />

    <SelectMethodAsPreset
      :method="method"
      :configuration="configuration"
      class="select-method__select"
      :class="{
        'select-method__select--can-edit': method != null,
        'select-method__select--no-permissions': !hasPermissionToEdit,
      }"
      @change="onMethodChange"
    />

    <template v-if="hasPermissionToEdit">
      <Btn v-if="method" class="select-method__btn" @click="editMethod">Edit</Btn>
      <Btn class="select-method__btn" @click="addMethod">Add</Btn>
    </template>
  </div>
</template>

<script>
  import _ from 'lodash';
  import MethodEditModal from 'components/block/modal/MethodEditModal.vue';
  import SelectMethodAsPreset from '@/components/element/SelectMethodAsPreset.vue';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import { AuthService } from '@/services/auth.service';

  const EVENT_UPDATE_METHOD = 'update:method';

  export default {
    name: 'SelectMethodComponent',

    components: {
      Btn,
      SelectMethodAsPreset,
      MethodEditModal,
    },

    model: {
      prop: 'method',
      event: EVENT_UPDATE_METHOD,
    },

    props: {
      method: Object,
      configuration: { type: Object, default: undefined },
      modalName: { type: String, default: 'select-method__method-edit' },
      deviceId: {
        type: String,
      },
    },

    data() {
      return {
        methodData: this.method || {},
        hasPermissionToEdit: AuthService.userData().role === 'admin',
      };
    },
    watch: {
      method(newMethod) {
        this.methodData = newMethod || {};
      },
    },
    methods: {
      showMethodModal() {
        this.$modal.show(this.modalName);
      },
      onMethodChange(methodData) {
        this.$emit(EVENT_UPDATE_METHOD, _.clone(methodData));
      },

      editMethod() {
        this.methodData = this.method;
        this.showMethodModal();
      },
      addMethod() {
        this.methodData = undefined;
        this.showMethodModal();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .select-method {
    display: flex;
    align-items: center;

    &__select {
      width: calc(100% - 70px);

      &--can-edit {
        width: calc(100% - 140px);
      }

      &--no-permissions {
        width: 100%;
      }
    }

    &__btn {
      margin-left: 10px;
      width: 60px;
      flex: none;
    }
  }
</style>
