import RouterHelper from '@/utils/RouterHelper';

const SELECTOR_DUAL_SCREEN_VIEW = '.dual__side';
const SELECTOR_SINGLE_SCREEN_VIEW_SCROLLABLE = '.main-wrapper';
const CLASS_SCROLL_LOCK = 'scroll-lock';

export default class LayoutHelper {
  static get viewRoots(): HTMLElement[] {
    if (RouterHelper.isDualMode) {
      return Array.from(document.querySelectorAll(SELECTOR_DUAL_SCREEN_VIEW));
    }
    return [document.body];
  }

  static get scrollableViewRoots(): HTMLElement[] {
    if (RouterHelper.isDualMode) {
      return Array.from(document.querySelectorAll(SELECTOR_DUAL_SCREEN_VIEW));
    }

    const scrollableRoot = document.querySelector(SELECTOR_SINGLE_SCREEN_VIEW_SCROLLABLE);
    if (scrollableRoot instanceof HTMLElement) {
      return [scrollableRoot];
    }

    throw new Error('Scrollable root was not found in single mode');
  }

  public static setPageScroll(isDisable: boolean): void {
    isDisable
      ? this.viewRoots.forEach((root) => root.classList.add(CLASS_SCROLL_LOCK))
      : this.viewRoots.forEach((root) => root.classList.remove(CLASS_SCROLL_LOCK));
  }
}
