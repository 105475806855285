import _ from 'lodash';
import SocketCentrifugeBase, { SubscriptionError } from '@/api/sockets/SocketCentrifugeBase';
import { Callback, Dict } from '@/types/utility';

export enum SampleSocketEvents {
  SAMPLE = 'sample',
  SAMPLE_REFRESH = 'sample.refresh',
  SAMPLE_STATE = 'sample.state',
  // Internal app events. Not emitted by socket
  DEVICE = 'sample.device',
  METHOD = 'sample.method',
  COLUMN = 'sample.column',
  INJECTIONS = 'sample.injections',
}

enum SampleSocketRPC {
  GET = 'sample.get',
  ARCHIVE = 'sample.archive',
  RESTORE = 'sample.restore',
  UPDATE = 'sample.update',
  START_NEW_INJECTION = 'sample.startNewInjection',
  // CONVERT_TO_STANDARD = 'sample.convertToStandard',
  SHARE = 'sample.share',
  STOP_SHARING = 'sample.stopSharing',
}

export default class SampleSocket extends SocketCentrifugeBase<
  SampleSocketEvents,
  SampleSocketRPC
> {
  private static connections: Dict<SampleSocket> = {};

  protected listeners: Record<SampleSocketEvents, Callback[]> = {
    [SampleSocketEvents.SAMPLE]: [],
    [SampleSocketEvents.SAMPLE_REFRESH]: [],
    [SampleSocketEvents.SAMPLE_STATE]: [],
    [SampleSocketEvents.DEVICE]: [],
    [SampleSocketEvents.METHOD]: [],
    [SampleSocketEvents.COLUMN]: [],
    [SampleSocketEvents.INJECTIONS]: [],
  };

  private constructor(
    public id: number,
    onConnect?: Callback<[SocketCentrifugeBase<SampleSocketEvents, SampleSocketRPC>]>,
    onError?: Callback<[SubscriptionError]>,
  ) {
    super(`personal:sample-${id}`, {
      handleEvents: ({ data }) => {
        switch (data.event) {
          case SampleSocketEvents.SAMPLE: {
            if (_.has(data, 'sample')) {
              this.callListeners(SampleSocketEvents.SAMPLE, data.sample);
            }
            if (_.has(data, 'device')) {
              this.callListeners(SampleSocketEvents.DEVICE, data.device);
            }
            if (_.has(data, 'method')) {
              this.callListeners(SampleSocketEvents.METHOD, data.method);
            }
            if (_.has(data, 'column')) {
              this.callListeners(SampleSocketEvents.COLUMN, data.column);
            }
            if (_.has(data, 'injections')) {
              this.callListeners(SampleSocketEvents.INJECTIONS, data.injections);
            }
            break;
          }
          case SampleSocketEvents.SAMPLE_STATE: {
            this.callListeners(SampleSocketEvents.SAMPLE_STATE, data.sample, data.injections);
            break;
          }
          case SampleSocketEvents.SAMPLE_REFRESH: {
            this.callListeners(SampleSocketEvents.SAMPLE_REFRESH, data.sample);
            break;
          }
        }
      },
      onConnect,
      onError,
    });

    this.dataAddedToEveryRequest = { sample_id: this.id };
  }

  public static start(
    id: number,
    onConnect?: Callback<[SocketCentrifugeBase<SampleSocketEvents, SampleSocketRPC>]>,
    onError?: Callback<[SubscriptionError]>,
  ) {
    const connection = SampleSocket.connections[id];
    if (connection) {
      connection.addSession();
      onConnect?.(connection);
      return connection;
    }

    SampleSocket.connections[id] = new SampleSocket(id, onConnect, onError);
    return SampleSocket.connections[id];
  }

  close(listenersGroupId: string) {
    const isClosed = super.close(listenersGroupId);

    if (super.close()) {
      delete SampleSocket.connections[this.id];
    }

    return isClosed;
  }

  get() {
    return this.sendNamedRPC(SampleSocketRPC.GET);
  }

  archive() {
    return this.sendNamedRPC(SampleSocketRPC.ARCHIVE);
  }

  restore() {
    return this.sendNamedRPC(SampleSocketRPC.RESTORE);
  }

  update(sample) {
    return this.sendNamedRPC(SampleSocketRPC.UPDATE, { sample });
  }

  injectionNew(description, deviceId, vial) {
    return this.sendNamedRPC(SampleSocketRPC.START_NEW_INJECTION, {
      description,
      device_id: deviceId,
      vial,
    });
  }

  // convertToStandard() {
  //   return this.sendNamedRPC(SampleSocketRPC.CONVERT_TO_STANDARD);
  // }

  share() {
    return this.sendNamedRPC(SampleSocketRPC.SHARE);
  }

  stopSharing() {
    return this.sendNamedRPC(SampleSocketRPC.STOP_SHARING);
  }
}
