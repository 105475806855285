<template>
  <div class="organization-page">
    <NavbarSecondaryAccount />
    <div class="top-line--flex">
      <h1>Organization Info</h1>
    </div>

    <LoadingComponent v-if="isLoading" />
    <div v-else-if="organization" class="paper-block-container mt-2">
      <InputTitle
        v-model="inputs.organizationName"
        iconName="account_balance"
        data-test-id="sample-name"
        :isDisabled="!isOwner"
        @save="setOrganizationName"
      />

      <div v-if="isOwner">
        <h4>Members:</h4>
        <div>
          <div
            v-for="member of organization.members"
            :key="member.id"
            class="organization-page__member"
          >
            <IconMaterial title="account_circle" class="organization-page__icon-member" />
            <a :href="`mailto:${member.user.email}`" class="organization-page__email">{{
              member.user.email
            }}</a>

            <template v-if="member.user.id !== userId">
              <select
                v-model="roles[member.id]"
                name="role"
                class="organization-page__select-role"
                @change="setRole(member.id, $event.target.value)"
              >
                <option value="admin">Administrator</option>
                <option value="assistant">Assistant</option>
              </select>

              <BtnIcon
                iconMaterial="delete"
                class="organization-page__btn-remove"
                @click="removeMember(member.user.id)"
              />
            </template>
            <span v-else class="organization-page__label-owner">(owner)</span>
          </div>
        </div>

        <Btn type="primary" class="organization-page__get-link" @click="getInvitationLink"
          >Get invitation link</Btn
        >
      </div>
      <div v-else class="organization-page__no-owner">
        Only the owner of an organization can edit it. <br />
        Ask<a :href="`mailto:${organization.owner.email}`" class="organization-page__owner">{{
          organization.owner.email
        }}</a
        ><span class="organization-page__label-owner">(owner)</span>if you have any questions
      </div>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/mixins/page-title';
  import { AuthService } from '@/services/auth.service';
  import NavbarSecondaryAccount from '@/components/blocks/layouts/NavbarSecondaryAccount';
  import InputTitle from '@/uikitProject/inputs/inputTitle/InputTitle.vue';
  import LoadingComponent from '@/components/element/LoadingComponent.vue';
  import { apiOrganizations } from '@/api/graphql/cloud/organizations';
  import IconMaterial from '@/uikitBase/icons/IconMaterial.vue';
  import BtnIcon from '@/uikitBase/btns/BtnIcon.vue';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import { copyToClipboard } from '@/utils/browserHelpers';
  import { OrganizationsApi } from '@/api/organizations';

  export default {
    name: 'OrganizationPage',

    components: {
      Btn,
      BtnIcon,
      IconMaterial,
      LoadingComponent,
      InputTitle,
      NavbarSecondaryAccount,
    },

    mixins: [PageTitle],

    data() {
      return {
        pageTitle: 'HPLC Cloud: Organization',
        tokenPayload: AuthService.userData(),

        organization: {
          members: [],
        },

        isLoading: true,

        inputs: {
          organizationName: '',
        },

        roles: {
          // [memberId]: 'admin' | 'assistant'
        },
      };
    },

    computed: {
      organizationId() {
        return this.tokenPayload?.organization_id;
      },
      userId() {
        return this.tokenPayload?.user_id;
      },
      isOwner() {
        return this.userId === this.organization.owner.id;
      },
    },

    created() {
      this.initOrganization();
    },

    methods: {
      async initOrganization() {
        try {
          this.isLoading = true;
          this.organization = await apiOrganizations.getOrganization(this.organizationId);
          this.roles = Object.fromEntries(
            this.organization.members.map((member) => [member.id, member.role]),
          );
          this.inputs.organizationName = this.organization.name;
        } catch {
          this.notifyError(`Can not get user's organization!`);
        } finally {
          this.isLoading = false;
        }
      },
      saveName() {
        console.warn('save');
      },

      async getInvitationLink() {
        await copyToClipboard(
          encodeURI(
            `${window.location.origin}/app/invitation-to-organization/${
              this.organization.token
            }/?name=${this.inputs.organizationName.trim()}`,
          ),
        );
        this.notify('Invitation link was copied to the clipboard');
      },

      async setOrganizationName() {
        const newOrganizationName = this.inputs.organizationName.trim();
        if (newOrganizationName !== this.organization.name) {
          await apiOrganizations.setOrganizationName(this.organization.id, newOrganizationName);
          this.notify('Organization name was changed!');
        }
      },

      removeMember(memberId) {
        this.$modal.show('dialog', {
          title: 'Remove member?',
          text: `If you confirm, the member will lose access to all devices and projects of the organization`,
          buttons: [
            {
              title: 'Cancel',
              default: true,
              handler: () => {
                this.$modal.hide('dialog');
              },
              class: 'vue-dialog-button blue-text',
            },
            {
              title: 'Remove',
              handler: () => {
                this.$modal.hide('dialog');
                OrganizationsApi.removeMember(
                  {
                    memberId,
                    organizationId: this.organizationId,
                  },
                  () => {
                    this.organization.members = this.organization.members.filter(
                      (member) => member.user.id !== memberId,
                    );
                    this.notify('Member was removed!');
                  },
                  this.notifyResponseError,
                );
              },
              class: 'vue-dialog-button red-text',
            },
          ],
        });
      },

      async setRole(memberId, role) {
        console.warn('role', memberId, role);
        try {
          await apiOrganizations.setMemberRole(memberId, role);
          this.notify(
            'Role has been changed! Please log in to your account again to apply all settings',
            10,
          );
        } catch (e) {
          this.notifyError('Something went wrong! Please, try again!');
          this.roles[memberId] = role === 'admin' ? 'assistant' : 'admin';
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .organization-page {
    &__member {
      display: flex;
      align-items: center;
      margin-bottom: 2px;
    }

    &__icon-member {
      margin-right: 5px;
    }

    &__email {
      color: $color-text-default;
    }

    &__btn-remove {
      margin-left: 10px;
      color: $color-text-danger;
    }

    &__label-owner {
      color: $color-text-second;
      margin-left: 5px;
      margin-right: 5px;
    }

    &__get-link {
      margin-top: 20px;
    }

    &__no-owner {
      margin-top: 30px;
      padding: 10px;
      border-radius: $border-radius__sm;
      background-color: #ffeccc;
      width: max-content;
      max-width: 100%;
      line-height: 1.5;
    }

    &__owner {
      font-weight: $weight-bold;
      color: $color-text-default;
      margin-left: 5px;
    }

    &__select-role {
      margin-left: 5px;
      width: 86px !important;
      padding: 2px !important;
      text-indent: 2px !important;
    }
  }
</style>
