export const INJECTION_SUBSTATES = {
  CREATED: 'Created',
  CONFIGURATION_GETTING_READY: 'Get modules ready',
  WASHING_NEEDLE_BEFORE_INJECT: 'Washing needle before inject',
  SHAKING: 'Shaking samples',
  PUMP_START_PREVENTING_PHASE_MIXING: 'Pump starting, preventing phase mixing',
  PUMP_START_REACHING_MIN_PRESSURE: 'Pump starting, reaching min pressure',
  PUMP_START: 'Pump starting',
  INJECTION_DELAY: 'Injection delay',
  CALIBRATING_DETECTOR: 'Calibrating detector',
  INJECTING: 'Injecting',
  WASH_NEEDLE_AFTER_INJECT: 'Washing needle after inject',
  RUN_PREPARATION_DONE: 'Run preparation done',
};
