export const EVENT_MODEL = 'update:itemSelected';
export const EVENT_SEARCH = 'search';
export const EVENT_MOUNTED = 'mounted';
export const EVENT_SHOW_POPUP = 'showPopup';
export const EVENT_HIDE_POPUP = 'hidePopup';

export const model = {
  prop: 'itemSelected',
  event: EVENT_MODEL,
};

export const propsSelectTrigger = {
  isShowArrowToggle: {
    type: Boolean,
    default: true,
  },
  isTransparent: {
    type: Boolean,
    default: false,
  },
  isExcludeSelectedItem: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  hasSearch: {
    type: Boolean,
    default: false,
  },
  padding: {
    type: String,
    default: 'none',
    validator(value) {
      const availableValues = ['none', 'xs', 'sm', 'md'];
      return availableValues.includes(value);
    },
  },
  messageNoItemSelected: {
    type: String,
    default: 'Not selected',
  },
  messageNoItems: {
    type: String,
    default: 'No items',
  },
  placeholderSearch: {
    type: String,
  },
  isShowSelectedItem: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
  },

  canSearchById: {
    type: Boolean,
    default: false,
  },
};

export const props = {
  items: {
    type: Array,
    required: true,
  },
  itemSelected: {
    type: Object,
  },
  labelProp: {
    type: String,
    required: true,
  },

  ...propsSelectTrigger,

  dropdownStyles: {
    type: Object,
    default: () => ({
      maxWidth: '240px',
    }),
  },

  titleBottomSheet: {
    type: String,
  },

  canCreate: {
    type: Boolean,
    default: false,
  },
  canCreateIfNotFound: {
    type: Boolean,
    default: false,
  },
  messageCreate: {
    type: String,
    default: 'Create',
  },
};
