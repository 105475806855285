<template>
  <div ref="select" class="select" :class="classes" @click="$emit('click')">
    <div class="select__wrapper-item-selected">
      <input
        v-if="hasSearch && isSearchActive"
        ref="inputSearch"
        v-model="localSearchValue"
        type="text"
        :placeholder="placeholderSearch"
        class="select__input-search"
        :style="stylesInputSearch"
      />
      <template v-else-if="itemSelected && itemSelected[labelProp]">
        <span ref="label" class="select__label" :title="itemSelected[labelProp]">{{
          itemSelected[labelProp]
        }}</span>
        <span v-if="$scopedSlots.info" class="select__label-info">
          <slot name="info" :item="itemSelected" />
        </span>
      </template>

      <template v-else-if="isShowSelectedItem">
        <span class="select__empty">
          {{ isDisabled ? 'Not selected' : messageNoItemSelected }}
        </span>
      </template>

      <template v-else>
        <span class="select__without-selected"> {{ title }} </span>
      </template>
    </div>
    <div
      v-if="isShowArrowToggle"
      class="select__icon-toggle"
      :class="isShow && 'select__icon-toggle--active'"
    />
  </div>
</template>

<script>
  import { propsSelectTrigger } from '@/uikitBase/selects/vueSelect/shared/config';

  const EVENT_MODEL = 'update:searchValue';

  export default {
    name: 'SelectTrigger',

    model: {
      prop: 'searchValue',
      event: EVENT_MODEL,
    },

    props: {
      ...propsSelectTrigger,

      searchValue: {
        type: String,
      },
      itemSelected: {
        type: Object,
      },
      labelProp: {
        type: String,
        required: true,
      },
      isShow: {
        type: Boolean,
      },
      isSearchActive: {
        type: Boolean,
      },
      stylesInputSearch: {
        type: Object,
      },
    },

    computed: {
      localSearchValue: {
        get() {
          return this.searchValue;
        },
        set(value) {
          this.$emit(EVENT_MODEL, value);
        },
      },

      classTransparent() {
        return this.isTransparent && 'select--transparent';
      },
      classPadding() {
        return `select--padding--${this.padding}`;
      },
      classDisabled() {
        return this.isDisabled && 'select--disabled';
      },
      classes() {
        return [this.classTransparent, this.classPadding, this.classDisabled];
      },

      // Used outside
      labelWidth() {
        return this.$refs.label?.clientWidth;
      },
    },

    methods: {
      focus() {
        this.$refs.inputSearch?.focus();
      },
      click() {
        this.$refs.select.click();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .select {
    position: relative;

    display: flex;
    align-items: center;
    // TODO make editable
    height: 32px;
    width: 100%;
    border-radius: 4px;

    color: inherit;

    background-color: $color-bg-second;
    cursor: pointer;

    transition: filter 1s;

    user-select: none;

    &--disabled {
      cursor: not-allowed;

      user-select: none;
    }

    &--disabled &__icon-toggle {
      opacity: 0;
    }

    &--transparent {
      background-color: transparent;
      border: none;

      transition: filter 1s, background-color 0.3s;
    }

    &--padding {
      &--none {
        padding: 0;
      }

      &--xs {
        padding: 0 4px;
      }

      &--sm {
        padding: 0 8px;
      }

      &--md {
        padding: 0 12px;
      }
    }

    &__label {
      overflow: hidden;

      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__label-info {
      flex: none;
    }

    &__empty {
      color: $color-text-info;
    }

    &__wrapper-item-selected {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      margin-right: auto;
      overflow: hidden;
    }

    &__icon-toggle {
      content: '';
      margin-left: 10px;
      color: #999;
      border-color: #999 transparent transparent;
      border-style: solid;
      border-width: 5px 5px 0;
      transition: transform 0.3s, opacity 0.3s;

      &--active {
        transform: rotate(-180deg);
      }
    }

    &--transparent &__icon-toggle {
      opacity: 0;
    }

    &:hover#{&}--transparent:not(#{&}--disabled) &__icon-toggle {
      opacity: 1;
    }

    &__dropdown {
      max-height: 180px;
      overflow-y: auto;
    }

    &__input-search {
      min-width: 100px;
      width: 100%;
      background: transparent;
      text-indent: 0;

      &:focus {
        background: transparent;
      }
    }
  }
</style>
