import { render, staticRenderFns } from "./BtnIcon.vue?vue&type=template&id=3045f681&scoped=true&"
import script from "./BtnIcon.vue?vue&type=script&lang=js&"
export * from "./BtnIcon.vue?vue&type=script&lang=js&"
import style0 from "./BtnIcon.vue?vue&type=style&index=0&id=3045f681&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3045f681",
  null
  
)

export default component.exports