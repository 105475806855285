<template>
  <div class="py-8 my-8">
    <h4 style="margin: 0 0 16px 0" class="text-xl font-bold">
      Summary for {{ standardResults.compoundName }}
    </h4>
    <span v-if="standardResults.k"
      ><span class="text-gray">Response factor &nbsp; </span>1 mAU×min →
      {{ standardResults.k.toFixed(6) }} {{ standardResults.unit }}</span
    >
    <br />
    <span v-if="standardResults.r2">
      <span class="text-gray">R-Squared </span>{{ standardResults.r2.toFixed(6) }}
    </span>
    <br />
    <span v-if="standardResults.r2">
      <span class="text-gray">Retention time </span>
      {{ standardResults.time && standardResults.time.toFixed(3) }} min ±{{
        standardResults.time_spread && (standardResults.time_spread * 100).toFixed(0)
      }}%
    </span>
    <div style="height: fit-content; display: flex; padding-top: 16px">
      <div style="width: 50%; height: 300px; float: left; position: relative; padding-right: 8px">
        <StandardsChartComponent
          :injectionHighlightedId.sync="injectionHighlightedId"
          :k="standardResults.k"
          unit="mg/mL"
          :standards="standardResults.standards"
        />
      </div>
      <div style="width: 50%; height: 300px; float: left; position: relative; padding-left: 8px">
        <ChromatogramsComponent
          :standards="standardResults.standards"
          :injectionHighlightedId="injectionHighlightedId"
          style="height: 100%; width: 100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import StandardsChartComponent from '@/components/element/chart/StandardsChartComponent.vue';
  import ChromatogramsComponent from '@/components/element/chart/ChromatogramsComponent.vue';

  export default {
    name: 'StandardResults',

    components: {
      ChromatogramsComponent,
      StandardsChartComponent,
    },

    props: {
      standardResults: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      injectionHighlightedId: null,
    }),
  };
</script>

<style scoped lang="scss"></style>
