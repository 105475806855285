<template>
  <div class="prompt">
    <IconMaterial title="error" class="prompt__icon" />
    <span class="prompt__message">{{ message }}</span>
    <BtnIcon iconMaterial="close" class="prompt__btn-hide" @click.stop="$emit('hide')" />
  </div>
</template>

<script>
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import BtnIcon from '@/uikitBase/btns/BtnIcon';

  export default {
    name: 'Prompt',

    components: { BtnIcon, IconMaterial },

    props: {
      message: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .prompt {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 16px;
    background: #f0f0f0;
    border-radius: $border-radius__md;
    color: #8a7837;
    font-size: $size-xxs;
    font-weight: $weight-bold;

    @media (max-width: $screen-xs-max) {
      font-size: $size-xxxs;
      padding: 0 10px;
      height: 26px;
    }

    &__message {
      flex: 1;
    }

    &__icon {
      margin-right: 10px;
    }

    &__btn-hide {
      margin-left: 10px;
      color: #a2a2a2;
    }
  }
</style>
