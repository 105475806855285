<template>
  <div style="display: flex; justify-content: flex-end">
    <div class="navbar-secondary-account">
      <RouterLink
        style="display: block; white-space: nowrap"
        to="/methods"
        :class="{ 'navbar-secondary-account__active': this.$route.name === 'methods' }"
      >
        <i class="material-icons material-icon--18 icon_no_decoration"> ballot </i>
        Methods
      </RouterLink>

      <RouterLink
        style="display: block; white-space: nowrap"
        to="/postprocessing-templates"
        :class="{
          'navbar-secondary-account__active': this.$route.name === 'postprocessingTemplates',
        }"
      >
        <i class="material-icons material-icon--18 icon_no_decoration">query_stats</i>
        Postprocessing templates
      </RouterLink>

      <RouterLink
        style="display: block; white-space: nowrap; flex-direction: row-reverse"
        to="/columns"
        :class="{ 'navbar-secondary-account__active': this.$route.name === 'columns' }"
      >
        <i class="material-icons material-icon--18 icon_no_decoration"> hourglass_empty </i>
        Columns
      </RouterLink>

      <RouterLink
        to="/account"
        :class="{ 'navbar-secondary-account__active': this.$route.name === 'account' }"
      >
        <i class="material-icons material-icon--18 icon_no_decoration"> account_circle </i>
        My info
      </RouterLink>

      <RouterLink
        to="/organization"
        :class="{ 'navbar-secondary-account__active': this.$route.name === 'organization' }"
      >
        <i class="material-icons material-icon--18 icon_no_decoration"> account_balance </i>
        My organization
      </RouterLink>

      <a href="#" @click.prevent="logout">
        <i class="material-icons material-icon--18 icon_no_decoration">forward</i>
        Logout
      </a>
    </div>
  </div>
</template>

<script>
  import { AuthService } from '@/services/auth.service';

  export default {
    name: 'NavbarSecondaryAccount',

    methods: {
      async logout() {
        await AuthService.logout();
        window.location.href = '/';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .navbar-secondary-account {
    display: flex;
    flex-wrap: wrap;
    float: none;
    background-color: $color-bg-second;

    border-radius: $border-radius__md;
    width: auto;

    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 2px 16px 0 rgba(0, 0, 0, 0.12);

    a {
      margin: 0 12px;
      padding: 6px 0 5px;

      @media (max-width: $screen-sm-max) {
        margin: 0 10px;
      }

      @media (max-width: $screen-xs-max) {
        margin: 0 6px;
      }
    }

    @media only screen and (max-width: 440px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 11px;
    }
    @media only screen and (max-width: 370px) {
      font-size: 10px;
    }

    &__active {
      border-bottom: $color-bg-primary solid 2px;
      padding: 6px 0 3px;
    }
  }
</style>
