<template>
  <div class="details">
    <div v-if="isLoadingNetworkDetails || !networkDetails" class="details__loading">
      <LoaderSpinner />
    </div>
    <template v-else-if="networkDetails">
      <div v-if="ipAddress" class="details__id">
        <span class="details__label-ip">IP:</span> {{ ipAddress }}
      </div>
      <div class="details__mac">
        <span class="details__label-mac">MAC:</span> {{ networkDetails.mac }}
      </div>
    </template>
  </div>
</template>

<script>
  import LoaderSpinner from '@/uikitProject/loaders/LoaderSpinner.vue';
  import { ethernetAdapterCommands } from '@/constants/hardware/commands';
  import { DeviceSocketEvents } from '@/api/sockets/DeviceSocket';

  export default {
    name: 'Details',

    components: {
      LoaderSpinner,
    },

    props: {
      deviceSocket: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      networkDetails: null,
      isLoadingNetworkDetails: true,
    }),

    computed: {
      popperOptions() {
        return {
          modifiers: {
            preventOverflow: {
              boundariesElement: 'window',
              enabled: true,
            },
          },
        };
      },

      ipAddress() {
        return this.networkDetails?.private_ip?.split('/')[0] ?? null;
      },
    },

    created() {
      this.initEthernet();
    },

    beforeDestroy() {
      this.destroyEthernet();
    },

    methods: {
      async initEthernet() {
        const listenersGroup = this.deviceSocket.createEventListenersGroup();
        this.listenersGroupId = listenersGroup.id;

        listenersGroup.addEventListener(DeviceSocketEvents.NETWORK_DETAILS, (networkDetails) => {
          this.networkDetails = networkDetails;

          if (networkDetails.error) {
            this.notifyError(networkDetails.error);
          }
        });

        this.isLoadingNetworkDetails = true;

        try {
          await this.showNotificationIfRpcError(() => {
            this.deviceSocket.command('ethernet-adapter', ethernetAdapterCommands.GET_DETAILS);
          });
        } finally {
          this.isLoadingNetworkDetails = false;
        }
      },

      destroyEthernet() {
        this.deviceSocket.removeEventListenersByGroupId(this.listenersGroupId);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .details {
    text-align: left;
    padding: 10px;
    min-width: 154px;

    &__loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__label-ip,
    &__label-mac {
      color: $color-text-third;
    }
  }
</style>
