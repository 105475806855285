<template>
  <ModalComponent :width="750 - 128" name="modalSequenceNew" #default="{ close }">
    <SequenceNewComponent @create="handleCreate(close)" />
  </ModalComponent>
</template>

<script>
  import ModalComponent from 'components/element/ModalComponent.vue';
  import SequenceNewComponent from 'components/block/SequenceNewComponent';

  const EVENT_CREATE = 'create';

  export default {
    name: 'SequenceNewModal',

    components: {
      SequenceNewComponent,
      ModalComponent,
    },

    methods: {
      handleCreate(closeModal) {
        closeModal();
        this.$emit(EVENT_CREATE);
      },
    },
  };
</script>
