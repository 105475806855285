import { format, formatRelative } from 'date-fns';
import { enUS } from 'date-fns/locale';

export default class DateHelper {
  public static formatDate(date, formatStr = 'PP') {
    const options = {
      locale: enUS,
    };

    if (!date) return 'No date';

    return typeof date === 'string'
      ? format(new Date(date), formatStr, options)
      : format(date, formatStr, options);
  }

  public static formatDateRelative(date) {
    const options = {
      locale: enUS,
    };

    if (!date) return 'No date';

    return typeof date === 'string'
      ? formatRelative(new Date(date), new Date(), options)
      : formatRelative(date, new Date(), options);
  }
}
