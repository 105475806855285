<template>
  <div ref="wrapper" class="manual-baseline-drawer">
    <div v-if="error" class="manual-baseline-drawer__error">
      {{ error }}
    </div>

    <div ref="chart" key="chart"></div>

    <template v-if="isEditMode">
      <div class="manual-baseline-drawer__label-help">
        Draw baseline, then click finish to save it
      </div>

      <div class="manual-baseline-drawer__actions">
        <PopupHelper text="Restarts setpoint selection.">
          <Btn height="s" @click="redrawBaseline">
            <template #iconLeft>
              <IconMaterial title="clear" size="16" />
            </template>
            Start over
          </Btn>
        </PopupHelper>
        <PopupHelper text="Removes the most recent baseline setpoint.">
          <Btn height="s" @click="removeLastBaselineSegment">
            <template #iconLeft>
              <IconMaterial title="undo" size="16" />
            </template>
            Undo
          </Btn>
        </PopupHelper>
        <PopupHelper text="Establishes a baseline correction based on the selected setpoints.">
          <Btn height="s" @click="confirmBaseline">
            <template #iconLeft>
              <IconMaterial title="check" size="16" />
            </template>
            Finish
          </Btn>
        </PopupHelper>
      </div>
    </template>
    <Btn
      v-else
      height="s"
      class="manual-baseline-drawer__btn-start-editing"
      @click="drawNewBaseline"
    >
      Draw new baseline
    </Btn>
  </div>
</template>

<script>
  import { ChromatogramDrawer, Modes } from '@/uikitProject/charts/new/ChromatogramDrawer';
  import { persistentStorage, PersistentStorageKeys } from '@/utils/persistentStorage';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper.vue';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import IconMaterial from '@/uikitBase/icons/IconMaterial.vue';
  import { SIZES } from '@/uikitProject/charts/new/private/constants';
  import { apiMeasurements } from '@/api/graphql/cloud/measurements';

  const EVENT_SAVE = 'save';

  export default {
    name: 'ManualBaselineDrawer',

    components: {
      IconMaterial,
      Btn,
      PopupHelper,
    },

    props: {
      measurementId: {
        type: Number,
        required: true,
      },
      measurementUlid: {
        type: String,
        required: true,
      },
      method: {
        type: Object,
        required: true,
      },
      error: {
        type: String,
      },
    },

    data: () => ({
      isEditMode: true,
      baselinePoints: null,
    }),

    created() {
      this.initMeasurementData();
    },

    methods: {
      async initMeasurementData() {
        if (!this.measurementId) {
          throw new Error(`Can't establish a socket connection without id`);
        }

        const measurement = await apiMeasurements.getMeasurement(
          this.measurementId,
          this.measurementUlid,
        );

        this.initChart({
          ...measurement,
          data: await measurement.getData(false),
        });
      },
      async initChart(measurementWithData) {
        if (!measurementWithData.data) {
          return;
        }

        const { hasGridLines, hasTooltipOnHover } = this.getAppearance();

        this.drawer = new ChromatogramDrawer({
          container: this.$refs.chart,
          measurements: [measurementWithData],
          expectedDurationSeconds: this.method?.run_time_min * 60,
          detectionTime: null,
          isInProgress: false,
          isApplyBaseline: false,
          isApplyDetectionTime: false,
          hasGridLines,
          hasTooltipOnHover,
          zoomBounds: null,
          sizes: {
            ...SIZES,
            margins: {
              desktop: {
                l: 3,
                r: 3,
                b: 20,
                t: 20,
                pad: 0,
              },
              mobile: {
                l: 3,
                r: 3,
                b: 20,
                t: 20,
                pad: 0,
              },
            },
          },
        });

        await this.drawer.initializationPromise;

        this.drawer.setMode(Modes.BASELINE);

        this.drawer.on('baseline', (baseline) => {
          this.baselinePoints = baseline.map(([time, mau]) => ({ time, mau }));
        });
      },

      getAppearance() {
        return (
          persistentStorage.get(PersistentStorageKeys.CHROMATOGRAM_APPEARANCE) ?? {
            hasGridLines: false,
            hasTooltipOnHover: false,
          }
        );
      },

      redrawBaseline() {
        this.drawer.actions.resetBaseline();
      },
      removeLastBaselineSegment() {
        this.drawer.actions.removeLastBaselineSegment();
      },
      confirmBaseline() {
        if (this.baselinePoints?.length) {
          this.$emit(EVENT_SAVE, this.baselinePoints);
          this.finishEditing();
        } else {
          this.notifyError('You must add at least one baseline point!');
        }
      },

      startEditing() {
        this.drawer.setMode(Modes.BASELINE);
        this.isEditMode = true;
      },
      finishEditing() {
        this.drawer.setMode(Modes.DEFAULT);
        this.isEditMode = false;
      },

      drawNewBaseline() {
        this.baselinePoints = null;
        this.$emit(EVENT_SAVE, this.baselinePoints);
        this.startEditing();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .manual-baseline-drawer {
    width: 100%;

    &__error {
      color: $color-text-danger;
    }

    &__label-help {
      margin: 10px 0;
    }

    &__actions {
      display: flex;
      gap: 10px;
    }

    &__btn-start-editing {
      margin-top: 10px;
    }
  }
</style>
