import { makeGraphqlRequestCloud } from 'api/graphql/graphQLClient';
import { SampleFull } from '@/graphql/cloud/samples/queries/SampleFull.graphql';
import { SamplesShort } from '@/graphql/cloud/samples/queries/SamplesShort.graphql';
import { transformArrayToObject } from '@/utils/objectHelpers';
import DataNotFoundError from '@/errors/DataNotFoundError';

export const apiSamples = {
  async getSampleFull(id) {
    const { sample: sampleFull } = await makeGraphqlRequestCloud(SampleFull, { id });

    if (!sampleFull) {
      throw new DataNotFoundError();
    }

    const { device, injections, column, method, ...sample } = sampleFull;

    return {
      device,
      injections: transformArrayToObject(
        injections.map(({ device, column, method, ...injection }) => ({
          id: injection.id,
          device,
          column,
          method,
          injection: {
            ...injection,
            measurements: transformArrayToObject(injection.measurements, 'id'),
          },
        })),
        'id',
      ),
      column,
      method,
      sample: {
        ...sample,
        device_name: device?.name,
      },
    };
  },
  async getSamples({ search = '', isArchived = false, offset = 0, limit }) {
    const { samples, samples_aggregate } = await makeGraphqlRequestCloud(SamplesShort, {
      search: `%${search ?? ''}%`,
      isArchived,
      offset,
      limit,
    });

    return {
      samples,
      count: samples_aggregate.aggregate.count,
    };
  },
};
