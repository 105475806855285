<template>
  <div class="changes">
    <template v-if="hasChanges">
      <div v-if="added" class="changes__type">
        <div class="changes__subtitle">Added</div>
        <UnorderedList :items="added" />
      </div>
      <div v-if="changed" class="changes__type">
        <div class="changes__subtitle">Changed</div>
        <UnorderedList :items="changed" />
      </div>
      <div v-if="removed" class="changes__type">
        <div class="changes__subtitle">Removed</div>
        <UnorderedList :items="removed" />
      </div>
      <div v-if="fixed" class="changes__type">
        <div class="changes__subtitle">Fixed</div>
        <UnorderedList :items="fixed" />
      </div>
    </template>
    <div v-else>No changes!</div>
  </div>
</template>

<script>
  import UnorderedList from '@/uikitBase/lists/UnorderedList';

  export default {
    name: 'Changes',

    components: { UnorderedList },

    props: {
      added: {
        type: Array,
      },
      changed: {
        type: Array,
      },
      removed: {
        type: Array,
      },
      fixed: {
        type: Array,
      },
    },

    computed: {
      hasChanges() {
        return this.added || this.changed || this.removed || this.fixed;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .changes {
    &__subtitle {
      font-size: $size-md;
      font-weight: $weight-bold;
      margin-bottom: 10px;
    }

    &__type {
      margin-bottom: 20px;
    }
  }
</style>
