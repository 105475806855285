<template>
  <button class="btn-icon" :disabled="isDisabled" :title="tooltip" v-on="$listeners">
    <slot>
      <IconMaterial :title="iconMaterial" :size="size" />
    </slot>
  </button>
</template>

<script>
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  export default {
    name: 'BtnIcon',

    components: {
      IconMaterial,
    },

    props: {
      iconMaterial: {
        type: String,
      },
      isDisabled: {
        type: Boolean,
      },
      tooltip: {
        type: String,
      },
      size: {
        type: [Number, String],
      },
    },
  };
</script>

<style lang="scss" scoped>
  .btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: none;
    padding: 0;
    background-color: $color-bg-transparent;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: $color-bg-transparent--hover;
    }

    &:active {
      background-color: $color-bg-transparent--active;
    }

    &:disabled {
      cursor: not-allowed;
      color: inherit;
      opacity: 0.5;

      &:hover {
        background-color: transparent;
      }

      &:active {
        background-color: transparent;
      }
    }
  }
</style>
