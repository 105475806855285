<template>
  <div>
    <StandardComponent
      :id="id"
      :injectionID="iid"
      @update:injectionID="updatedInjectionID"
      @update:standardData="updatedStandardData"
    />
  </div>
</template>

<script>
  import PageTitle from '@/mixins/page-title';
  import { isSecondaryView } from 'components/blocks/layouts/dual/SecondaryView';
  import StandardComponent from '@/components/block/StandardComponent.vue';

  export default {
    name: 'StandardPage',

    inject: {
      isSecondaryView,
    },

    components: {
      StandardComponent,
    },

    mixins: [PageTitle],

    props: {
      id: Number,
      iid: Number,
    },

    data() {
      return {
        pageTitle: 'HPLC Cloud: Standard',
      };
    },

    methods: {
      updatedInjectionID(iid) {
        if (iid == null) {
          this.$router.push({
            name: 'standard',
            params: { id: this.id },
            query: { notOpenSecondaryTab: true },
          });
        } else {
          this.$router.push({
            name: 'standard injection',
            params: { id: this.id, iid },
            query: { notOpenSecondaryTab: true },
          });
        }
      },
      updatedStandardData(sd) {
        this.pageTitle = `${sd?.name}: Standard HPLC.cloud`;
      },
    },
  };
</script>
