import Vue from 'vue';
import VueTippy from '@/assets/libraries/vueTippy/src';
import './styles/themes/light.scss';
import './styles/themes/lightblue.scss';
import './styles/themes/lightyellow.scss';
import './styles/themes/autosuggest.scss';

Vue.use(VueTippy, {
  directive: 'tippy',
  flipDuration: 0,
  duration: [100, 67],
  arrow: true,
  maxWidth: 300,
  theme: 'custom-tooltip',
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false,
      },
    },
  },
});
