<template>
  <div
    v-if="
      configuration.hasOwnProperty('valve') &&
      configuration.valve != null &&
      configuration.cromite == null
    "
    class="hardware-tile valve"
  >
    <div class="hardware-tile__wrapper-title">
      <div>
        <i class="hardware-tile__title-icon">settings_applications</i>
        <h4>Valve</h4>
      </div>
      <Information :data="popupInfoData" class="hardware-tile__information" />
    </div>

    <hardware-state-component :state="currentState" />

    <HardwareErrorComponent :errors="errors" :error-list="errorList" />

    <template v-if="stateDetails">
      <div style="max-width: 180px; margin: 16px auto 0">
        <img v-if="isSwitching" src="../../../assets/img/valve/holes6/valve-switching.svg" />
        <img
          v-else-if="stateDetails.position === 1"
          src="../../../assets/img/valve/holes6/valve-12-34-56.svg"
        />
        <img
          v-else-if="stateDetails.position === 2"
          src="../../../assets/img/valve/holes6/valve-16-23-45.svg"
        />

        <div class="valve__status">
          <span v-if="isSwitching" class="text-process mb-2" style="font-weight: bold">
            Switching position
          </span>
          <span v-else-if="stateDetails.position === 1" class="text-green mb-2 font-bold">
            Position 1
          </span>
          <span v-else-if="stateDetails.position === 2" class="text-green mb-2 font-bold">
            Position 2
          </span>
        </div>
      </div>

      <Btn
        height="s"
        type="primary"
        :disabled="!canSwitchPosition"
        class="mt-16"
        data-test-id="buttonSwitchValvePosition"
        @click="switchPosition"
      >
        Switch position
      </Btn>
    </template>
  </div>
</template>

<script>
  import Base from './Base.vue';
  import HardwareErrorComponent from 'components/element/HardwareErrorComponent';
  import { GENERAL_ERRORS } from '@/constants/hardware/errors';
  import Information from 'components/block/hardware/private/Information';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import { valveCommands } from '@/constants/hardware/commands';

  const SWITCHING_TIMEOUT_MS = 1000;

  export default {
    name: 'Valve',

    components: { Btn, Information, HardwareErrorComponent },

    extends: Base,

    data: () => ({
      isSwitching: false,
      switchingTimeoutId: null,
    }),

    computed: {
      currentState() {
        return this.state?.valve;
      },
      stateDetails() {
        return this.state?.valve_details;
      },
      errors() {
        return this.stateDetails?.errors;
      },
      errorList() {
        return { ...GENERAL_ERRORS };
      },

      popupInfoData() {
        const { firmware_version, id, serial } = this.configuration.valve;

        return [
          {
            name: 'Id',
            value: id,
          },
          {
            name: 'Serial',
            value: serial,
          },
          {
            name: 'Firmware Version',
            value: firmware_version
              ? `${firmware_version.major}.${firmware_version.minor}`
              : 'Not available',
          },
        ];
      },

      commands() {
        return this.stateDetails?.commandDetails ?? {};
      },
      canSwitchPosition() {
        return this.commands[valveCommands.SWITCH_POSITION]?.status.available;
      },
    },

    watch: {
      'stateDetails.position'(newPosition, oldPosition) {
        if (newPosition != null && oldPosition != null && newPosition !== oldPosition) {
          clearTimeout(this.switchingTimeoutId);

          this.isSwitching = true;
          this.switchingTimeoutId = setTimeout(() => {
            this.isSwitching = false;
          }, SWITCHING_TIMEOUT_MS);
        }
      },
    },

    methods: {
      switchPosition() {
        this.ws.command('valve', valveCommands.SWITCH_POSITION);
      },
    },
  };
</script>

<style lang="scss">
  .operation {
    text-align: center;
    align-content: center;
    margin: 32px auto auto;
  }
</style>

<style lang="scss" scoped>
  .valve {
    &__position-title {
      font-weight: $weight-bold;
    }

    &__status {
      text-align: center;
    }
  }
</style>
