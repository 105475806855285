import Vue from 'vue';

import VModal from '@/assets/libraries/vueJsModal';

Vue.use(VModal, { dialog: true });

export const EVENT_VDIALOG_CLOSED = 'VDialog:before-closed';

const { Modal } = Vue.options.components;
Vue.component('Modal', Modal);

const { VDialog } = Vue.options.components;
const PatchedVDialog = VDialog.extend({
  methods: {
    beforeOpened(event) {
      window.addEventListener('keydown', this.onKeyUp);

      this.params = event.params || {};
      this.$emit('before-opened', event);
    },
    beforeClosed(event) {
      window.removeEventListener('keydown', this.onKeyUp);

      this.params = {};

      this.$root.$emit(EVENT_VDIALOG_CLOSED, event);
      this.$emit('before-closed', event);
    },
  },
});

Vue.component('VDialog', PatchedVDialog);
