import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import splitScreenManager from '@/router/app/hooks/global/splitScreenManager';

Vue.use(VueRouter);

// To hide Navigation Duplicated error message
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const pushOrigin = VueRouter.prototype.push;
// @ts-ignore
VueRouter.prototype.push = function (location, onResolve, onReject) {
  if (typeof location === 'object') {
    if (onResolve || onReject) {
      return pushOrigin.call(this, location, onResolve, onReject);
    }
    // @ts-ignore
    return pushOrigin.call(this, location).catch((err) => err);
  }
  throw new Error('Location must be an object!');
};

const replaceOrigin = VueRouter.prototype.replace;
// @ts-ignore
VueRouter.prototype.replace = function (location, onResolve, onReject) {
  if (typeof location === 'object') {
    const locationModified = {
      ...location,
      query: {
        ...location.query,
        doReplace: 'true',
      },
    };

    if (onResolve || onReject)
      return replaceOrigin.call(this, locationModified, onResolve, onReject);
    // @ts-ignore
    return replaceOrigin.call(this, locationModified).catch((err) => err);
  }
  throw new Error('Location must be an object!');
};

const router = new VueRouter({
  mode: 'history',
  routes,
  base: `${process.env.BASE_URL}app/`,
});

router.beforeEach(splitScreenManager);

export default router;
