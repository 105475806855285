<template>
  <div class="pager">
    <button
      :disabled="!prevEnabled"
      class="rect-button transparent pager__btn"
      @click="$emit('prevToStart')"
    >
      <i class="material-icons material-icon--18">first_page</i>
    </button>
    <button
      :disabled="!prevEnabled"
      class="rect-button transparent pager__btn"
      @click="$emit('prev')"
    >
      <i class="material-icons material-icon--18">keyboard_arrow_left</i>
    </button>
    <div class="pager__current">{{ text }}</div>
    <button
      :disabled="!nextEnabled"
      class="rect-button transparent pager__btn"
      @click="$emit('next')"
    >
      <i class="material-icons material-icon--18">keyboard_arrow_right</i>
    </button>
    <button
      :disabled="!nextEnabled"
      class="rect-button transparent pager__btn"
      @click="$emit('nextToEnd')"
    >
      <i class="material-icons material-icon--18">last_page</i>
    </button>
  </div>
</template>

<script>
  export default {
    name: 'PagerComponent',

    props: {
      prevEnabled: [String, Boolean, Number, Object],
      nextEnabled: [String, Boolean, Number, Object],
      current: [String, Number],
      total: [String, Number],
    },

    computed: {
      text() {
        return this.total ? this.current + ' / ' + this.total : this.current;
      },
    },
  };
</script>

<style scoped lang="scss">
  .pager {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    flex-grow: 1;
    flex-wrap: nowrap;
    align-items: stretch;
    margin: 2px 8px 0;

    @media (max-width: $screen-sm-max) {
      margin: 2px 1px 0;
    }

    &__btn {
      @media (max-width: $screen-sm-max) {
        padding: 0 1px;
      }
    }

    &__current {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin: 0 2px;
    }

    button {
      flex-grow: 1;
      padding-top: 17px;
      padding-bottom: 14px;

      &:first-child {
        text-align: right;
      }

      &:last-child {
        text-align: left;
      }
    }
  }
</style>
