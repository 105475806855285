<template>
  <div v-if="state == null || state === -1" class="hardware-state">
    <div class="material-icons material-icon--16 text-gray">help_outline</div>
    Unknown state
  </div>
  <!-- not used -->
  <div v-else-if="state === 0" class="hardware-state text-red">
    <div class="material-icons material-icon--16">highlight_off</div>
    Off
  </div>
  <!-- not used -->
  <div v-else-if="state === 1" class="hardware-state text-red">
    <div class="material-icons material-icon--16">error</div>
    Turning on
  </div>
  <!-- used up to version 1.96 -->
  <div v-else-if="state === 2" class="hardware-state text-yellow">
    <div class="material-icons material-icon--16">check_circle</div>
    On
  </div>
  <div v-else-if="state === 3" class="hardware-state text-green">
    <div class="material-icons material-icon--16" style="font-weight: bold">done</div>
    Ready
  </div>
  <!-- not used -->
  <div v-else-if="state === 4" class="hardware-state text-red">
    <div class="material-icons material-icon--16">error_outline</div>
    Turning off
  </div>
  <div v-else-if="state === 5" class="hardware-state text-yellow">
    <div class="material-icons material-icon--16">update</div>
    Updating
  </div>
  <div v-else-if="state === 6" class="hardware-state text-red">
    <div class="material-icons material-icon--16">error_outline</div>
    Error
  </div>
  <!-- used since version 1.96 -->
  <div v-else-if="state === 7" class="hardware-state hardware-state--process">
    <div class="hardware-state__process-icon" />
    Working
  </div>
  <!-- used since version 1.96 -->
  <div v-else-if="state === 8" class="hardware-state hardware-state--getting-ready">
    <div class="hardware-state__getting-ready-icon" />
    Getting ready
  </div>
</template>

<script>
  import 'assets/css/base/icons.scss';

  export default {
    name: 'HardwareStateComponent',
    props: {
      state: {
        type: Number,
        default: null,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .hardware-state {
    display: block;
    white-space: nowrap;
    font-weight: bold;

    &--process {
      color: $color-text-process;
    }

    &--getting-ready {
      color: $pure-color__yellow;
    }
  }

  .hardware-state__process-icon {
    display: inline-block;
    background-image: url('~assets/img/running.svg');
    background-size: contain;
    margin: 0 0 2px 0;
    vertical-align: bottom;
    text-decoration: none;
    width: 15px;
    height: 15px;
  }

  .hardware-state__getting-ready-icon {
    display: inline-block;
    background-image: url('~assets/img/getting-ready.svg');
    background-size: contain;
    margin: 0 0 2px 0;
    vertical-align: bottom;
    text-decoration: none;
    width: 15px;
    height: 15px;
  }
</style>
