<template>
  <Select
    v-if="isInitialized"
    ref="select"
    :itemSelected="privateMethod"
    :items="sortedMethods"
    labelProp="name"
    :hasSearch="true"
    :padding="padding"
    :placeholderSearch="placeholder"
    :titleBottomSheet="placeholder"
    class="select-method-as-preset"
    :canSearchById="true"
    :isTransparent="isTransparent"
    :isDisabled="isDisabled"
    :canCreate="isEditable"
    messageCreate="Create method"
    @update:itemSelected="selectedItemChanged"
    @search="getListItemsDebounced"
    @showPopup="handleShowPopup"
    @hidePopup="handleHidePopup"
    @create="$emit('createMethod')"
  >
    <template #rightSideInsideItem="{ item: _method, isBottomSheet, isInDropdown, hide }">
      <IconFavorite
        v-if="_method.favorite"
        class="select-method-as-preset__favorite"
        :class="{
          'select-method-as-preset__favorite--selected':
            privateMethod && _method.id === privateMethod.id && (isBottomSheet || isInDropdown),
        }"
      />

      <span
        class="select-method-as-preset__id"
        :class="{
          'select-method-as-preset__id--selected':
            privateMethod && _method.id === privateMethod.id && (isBottomSheet || isInDropdown),
        }"
        >#{{ _method.id }}</span
      >
      <BtnIcon
        v-if="isEditable && (isBottomSheet || isInDropdown)"
        iconMaterial="edit"
        class="select-method-as-preset__btn-edit"
        :class="{
          'select-method-as-preset__btn-edit--selected':
            privateMethod && _method.id === privateMethod.id && (isBottomSheet || isInDropdown),
        }"
        @click.stop="editMethod(_method, hide)"
      />
    </template>
  </Select>
</template>

<script>
  import { debounce } from 'lodash';
  import Select from '@/uikitBase/selects/vueSelect/Select';
  import IconFavorite from '@/uikitProject/icons/IconFavorite.vue';
  import hash from 'object-hash';
  import BtnIcon from '@/uikitBase/btns/BtnIcon.vue';

  const SEARCH_LIMIT = 25;
  const SEARCH_DELAY = 200;

  const ModelEvent = 'change';
  const CloseEvent = 'close';

  export default {
    name: 'SelectMethodAsPreset',

    components: {
      BtnIcon,
      IconFavorite,
      Select,
    },

    model: {
      prop: 'method',
      event: ModelEvent,
    },

    props: {
      method: {
        type: Object,
        default: null,
      },
      methodId: {
        type: Number,
        default: null,
      },
      placeholder: {
        type: String,
        default: 'Search method',
      },
      methodIdsToHide: {
        type: Array,
      },
      isTransparent: {
        type: Boolean,
        default: false,
      },
      padding: {
        type: String,
        default: 'md',
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      isEditable: {
        type: Boolean,
        default: false,
      },
      preloadedMethod: {
        type: Object,
      },
      configuration: {
        type: Object,
      },
    },

    data: () => ({
      isSearchActive: false,
      searchQuery: null,
      items: [],

      getListItemsDebounced: null,

      isSelectOpened: false,

      isInitialized: false,
    }),

    computed: {
      privateMethod() {
        return (
          this.methodsToShow.find((item) => item.id === (this.methodId ?? this.method?.id)) ??
          this.method ??
          null
        );
      },

      methodsFromStore() {
        return Object.values(this.$store.state.methods);
      },

      methodsToShow() {
        if (this.preloadedMethod) {
          const isPreloadedMethodAlreadyInList = this.items.some(
            (method) => method.id === this.preloadedMethod.id,
          );
          if (!isPreloadedMethodAlreadyInList) {
            return [...this.items, this.preloadedMethod];
          }
        }

        return this.items;
      },

      sortedMethods() {
        return this.privateMethod
          ? [...this.methodsToShow].sort((method) => {
              return method === this.privateMethod ? -1 : 1;
            })
          : this.methodsToShow;
      },
    },

    watch: {
      method(itemNew) {
        if (itemNew) {
          this.addItem(itemNew);
        }
      },
      methodsFromStore() {
        if (this.methodsFromStore.length === 0) {
          this.search(null);
        }
      },
      configuration() {
        this.refreshMethods();
      },
    },

    created() {
      // / NOTE: using hash instead of searching in array
      this.itemsHash = {};

      this.getListItemsDebounced = debounce(this.search, SEARCH_DELAY);

      this.search(null);
    },

    methods: {
      activate() {
        this.$refs.select.focus();
      },

      deactivate() {
        // this.$refs.select.deactivate();
      },

      onClose() {
        this.$emit(CloseEvent);
      },

      selectedItemChanged(item) {
        this.$emit(ModelEvent, item);
      },

      async search(query) {
        const searchingFirstTime =
          query == null && this.searchQuery == null && this.items.length === 0;

        this.isSearchActive = !searchingFirstTime;
        this.searchQuery = query;

        const variables = {
          search: this.searchQuery,
          isArchived: false,
          limit: SEARCH_LIMIT,
          hasOnlyFavorites: false,
          hasSortingByFavorites: 'desc',
          methodIdsToExclude: this.methodIdsToHide,
          hardwareConfiguration: this.configuration,
        };

        const variablesHash = hash(variables);
        try {
          if (!this.$store.state.methods[variablesHash]) {
            this.$store.dispatch('initMethods', variables);
          }

          const { methods } = await this.$store.state.methods[variablesHash];

          if (this.searchQuery !== query) return;
          this.isSearchActive = false;

          this.replaceItems(methods);
          this.isInitialized = true;
        } catch {
          if (this.searchQuery !== query) return;
          this.isSearchActive = false;
          this.clearItems();
        }
      },

      addItem(itemNew) {
        if (!(itemNew.id in this.itemsHash)) {
          this.itemsHash[itemNew.id] = true;
          this.items.unshift(itemNew);
        }
      },

      replaceItems(items) {
        this.itemsHash = {};
        items.forEach((item) => {
          this.itemsHash[item.id] = true;
        });
        this.items = items;
      },

      clearItems() {
        this.itemsHash = {};
        this.items = [];
      },

      handleShowPopup() {
        this.isSelectOpened = true;
        this.$emit('showPopup');
      },
      handleHidePopup() {
        this.isSelectOpened = false;
        this.$emit('hidePopup');
      },

      refreshMethods() {
        this.search(null);
      },

      editMethod(method, hide) {
        this.$emit('editMethod', method);
        hide();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .select-method-as-preset {
    width: 100%;

    &__id {
      color: $color-text-third;

      &--selected {
        color: white;
      }
    }

    &__favorite {
      margin-right: 10px;

      &--selected {
        color: white;
      }
    }

    &__btn-edit {
      margin-left: 5px;

      &--selected {
        color: white;
      }
    }
  }
</style>
