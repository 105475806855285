var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isInitialized)?_c('Select',{ref:"select",staticClass:"select-postprocessing-template-as-preset",attrs:{"itemSelected":_vm.privatePostprocessingTemplate,"items":_vm.postprocessingTemplatesToShow,"labelProp":"name","hasSearch":true,"padding":_vm.padding,"placeholderSearch":_vm.placeholder,"titleBottomSheet":_vm.placeholder,"canSearchById":true,"isTransparent":_vm.isTransparent,"isDisabled":_vm.isDisabled,"canCreate":_vm.isEditable,"messageCreate":"Create postprocessingTemplate"},on:{"update:itemSelected":_vm.selectedItemChanged,"search":_vm.getListItemsDebounced,"showPopup":_vm.handleShowPopup,"hidePopup":_vm.handleHidePopup,"create":function($event){return _vm.$emit('createPostprocessingTemplate')}},scopedSlots:_vm._u([{key:"rightSideInsideItem",fn:function(ref){
var _postprocessingTemplate = ref.item;
var isBottomSheet = ref.isBottomSheet;
var isInDropdown = ref.isInDropdown;
var hide = ref.hide;
return [(_postprocessingTemplate.favorite)?_c('IconFavorite',{staticClass:"select-postprocessing-template-as-preset__favorite",class:{
        'select-postprocessing-template-as-preset__favorite--selected':
          _vm.privatePostprocessingTemplate &&
          _postprocessingTemplate.id === _vm.privatePostprocessingTemplate.id &&
          (isBottomSheet || isInDropdown),
      }}):_vm._e(),_c('span',{staticClass:"select-postprocessing-template-as-preset__id",class:{
        'select-postprocessing-template-as-preset__id--selected':
          _vm.privatePostprocessingTemplate &&
          _postprocessingTemplate.id === _vm.privatePostprocessingTemplate.id &&
          (isBottomSheet || isInDropdown),
      }},[_vm._v("#"+_vm._s(_postprocessingTemplate.id))]),(_vm.isEditable && (isBottomSheet || isInDropdown))?_c('BtnIcon',{staticClass:"select-postprocessing-template-as-preset__btn-edit",attrs:{"iconMaterial":"edit"},on:{"click":function($event){$event.stopPropagation();return _vm.editPostprocessingTemplate(_postprocessingTemplate, hide)}}}):_vm._e()]}}],null,false,3824802295)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }