import { Callback, Dict, Nullable } from '@/types/utility';
import { subscribeToCentrifugeChannel, centrifuge } from '@/services/CentrifugeService';
import Centrifuge from 'centrifuge';
import ExtraListenerError from '@/errors/sockets/ExtraListenerError';
import { AuthService } from '@/services/auth.service';
import { generateId } from '@/utils/generateId';
import { consoleHelpers } from '@/utils/logHelpers';
import { HTTP_403_FORBIDDEN } from '@/constants/network/httpStatuses';

export type SubscriptionError = {
  code: Nullable<number>;
  message?: string;
};
type Options<EventsIn extends string, RPCList extends string> = {
  handleEvents: Centrifuge.SubscriptionEvents['publication'];
  // eslint-disable-next-line no-use-before-define
  onConnect?: Callback<[SocketCentrifugeBase<EventsIn, RPCList>]>;
  onError?: Callback<[SubscriptionError]>;
  shareToken?: string;
};

type ListenersGroupId = string;
const DEFAULT_ID: ListenersGroupId = 'no_id';

type EventListenerGroup<E> = {
  id: ListenersGroupId;
  addEventListener(event: E, Callback): void;
};

type Listener = {
  (...e): void;
  id?: string;
};

export default abstract class SocketCentrifugeBase<
  EventsIn extends string,
  RPCList extends string
> {
  public subscription: Nullable<Centrifuge.Subscription> = null;

  private sessionQuantity: number = 0;

  protected abstract listeners: Record<EventsIn, Listener[]>;

  protected dataAddedToEveryRequest: Dict<string | number> = {};

  protected constructor(
    private channelName: string,
    { onConnect, onError, handleEvents, shareToken }: Options<EventsIn, RPCList>,
  ) {
    const eventHandlers = {
      subscribed: () => {
        onConnect?.(this);
      },
      publication(data) {
        try {
          handleEvents(data);
        } catch (e) {
          consoleHelpers.warn('ERROR IN SOCKET HANDLER', e);
        }
      },
      error(e: Centrifuge.SubscriptionErrorContext) {
        const code = e.error.message.includes('ChannelNotAccessibleError')
          ? HTTP_403_FORBIDDEN
          : null;
        onError?.({ code });
      },
    };

    this.subscribeToChannel(channelName, eventHandlers, shareToken);
  }

  private async subscribeToChannel(
    channelName: string,
    eventHandlers: Partial<Centrifuge.SubscriptionEvents>,
    shareToken?: string,
  ) {
    const isUpdating = AuthService.requestRefreshToken;

    if (isUpdating) {
      await AuthService.requestRefreshToken;
      // centrifuge.setSubscribeParams({ token: AuthService.token() });
    }

    const publicationHandler = eventHandlers.publication;
    const subscribedHandler = eventHandlers.subscribed;
    const errorHandler = eventHandlers.error;

    this.subscription = subscribeToCentrifugeChannel(channelName, shareToken);
    if (publicationHandler) {
      this.subscription?.on('publication', publicationHandler);
    }
    if (subscribedHandler) {
      this.subscription?.on('subscribed', subscribedHandler);
    }
    this.subscription?.on('error', (e) => {
      if (e.type === 'subscribeToken') {
        queueMicrotask(() => {
          this.subscription?.unsubscribe();
        });
      }
      errorHandler?.(e);
    });

    this.addSession();
  }

  protected async sendNamedRPC(event: RPCList, data?: object): Promise<any> {
    const response = await centrifuge.rpc(event, {
      ...this.dataAddedToEveryRequest,
      ...data,
    });
    return response.data;
  }

  public close(listenersGroupId?: string): boolean {
    if (listenersGroupId) {
      this.removeEventListenersByGroupId(listenersGroupId);
    }

    // TODO should remove listeners?
    if (this.subscription) {
      // TODO leave comment here
      if (this.sessionQuantity === 1) {
        this.subscription.unsubscribe();
        return true;
      }

      this.removeSession();
    }
    return false;
  }

  /**
   * @deprecated remove duplicate
   */
  public subscribe(event: EventsIn, listener: Callback, id = DEFAULT_ID) {
    const _listener: Listener = listener;
    _listener.id = id;

    if (this.listeners?.[event]) {
      this.listeners[event].push(_listener);
    } else {
      throw new ExtraListenerError(`Extra listener for "${event}" event is not allowed!`);
    }
  }

  public createEventListenersGroup(): EventListenerGroup<EventsIn> {
    const id = generateId();

    return {
      id,
      addEventListener: (event: EventsIn, listener: Callback) =>
        this.addEventListener(event, listener, id),
    };
  }

  public addEventListener(event: EventsIn, listener: Callback, id = DEFAULT_ID) {
    const _listener: Listener = listener;
    _listener.id = id;

    if (this.listeners?.[event]) {
      this.listeners[event].push(_listener);
    } else {
      throw new ExtraListenerError(`Extra listener for "${event}" event is not allowed!`);
    }
  }

  /**
   * @deprecated
   */
  public unsubscribe(event: EventsIn, listener: Callback) {
    if (this.listeners?.[event]) {
      this.listeners[event] = this.listeners[event].filter((_listener) => _listener !== listener);
    } else {
      throw new ExtraListenerError(`Extra listener for "${event}" event is not allowed!`);
    }
  }

  public removeEventListener(event: EventsIn, listener: Callback) {
    if (this.listeners?.[event]) {
      this.listeners[event] = this.listeners[event].filter((_listener) => _listener !== listener);
    } else {
      throw new ExtraListenerError(`Extra listener for "${event}" event is not allowed!`);
    }
  }

  public removeEventListenersByGroupId(id: ListenersGroupId) {
    Object.entries(this.listeners).forEach(([event, listeners]) => {
      // TODO TS bug
      // @ts-ignore
      this.listeners[event] = listeners.filter((listener) => listener.id !== id);
    });
  }

  public callListeners(event: EventsIn, ...args) {
    this.listeners[event].forEach((listener) => {
      listener(...args);
    });
  }

  public addSession(): void {
    this.sessionQuantity++;
    consoleHelpers.warn('SESSION STARTED', this.channelName, ' : ', this.sessionQuantity);
  }

  public removeSession(): void {
    this.sessionQuantity--;
    consoleHelpers.warn('SESSION CLOSED', this.channelName, ' : ', this.sessionQuantity);
  }
}
