<template>
  <div class="row-creator" @click="add">
    <div class="row-creator__wrapper-icon">
      <IconMaterial title="add" class="row-creator__icon" />
    </div>
    <div class="row-creator__line"></div>
  </div>
</template>

<script>
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  export default {
    name: 'RowCreator',

    components: {
      IconMaterial,
    },

    methods: {
      add() {
        this.$emit('add');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .row-creator {
    margin-top: -5px;
    display: flex;
    align-items: center;
    height: 10px;
    opacity: 0;
    transition: opacity 67ms;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &__line {
      width: 100%;
      height: 2px;
      background-color: $color-text-primary;
    }

    &__wrapper-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $color-text-primary;
    }

    &__icon {
      color: $color-text-on-primary;
      font-size: 18px;
    }
  }
</style>
