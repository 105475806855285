<template>
  <modal
    name="column-create"
    doSubmitOnEnter
    :hasCloseConfirmationPopup="isChanged"
    closeConfirmationPopupMessage="Column wasn't saved! Do you want to close the window?"
    @submit="submit"
    @before-open="beforeOpen"
  >
    <div class="modal-content modal-content--no-padding">
      <div class="modal-header"><h4>Create Column</h4></div>
      <div class="modal-body">
        <div class="name-container">
          <field
            v-model="column.name"
            :type="'str'"
            :label="'Column name'"
            :is-title="true"
            :error="errors.name"
            :disabled="!hasPermissionToEdit"
          />
        </div>

        <ColumnEditComponent
          v-model="column"
          :errors="errors"
          :disabled="!hasPermissionToEdit"
          @updateField="clearError"
        />
      </div>
      <div class="modal-footer">
        <div v-if="hasPermissionToEdit" class="modal-content__actions">
          <Btn type="primary" :disabled="!isChanged" @click="submit">Create</Btn>
        </div>
        <div v-else class="modal-column-create__no-permissions">
          You can't edit and create columns. Ask the organization owner to give you more permissions
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import ColumnAPI from 'api/column';

  import ModalComponent from 'components/element/ModalComponent.vue';
  import StandardModelFieldComponent from 'components/element/StandardModelFieldComponent.vue';
  import ColumnEditComponent from 'components/block/ColumnEditComponent.vue';
  import 'assets/css/base/buttons.scss';
  import { COLUMN_EMPTY } from '@/constants/columns/presets';
  import _ from 'lodash';
  import Btn from '@/uikitBase/btns/Btn';
  import { convertNullValuesToUndefined } from '@/utils/objectHelpers';
  import { AuthService } from '@/services/auth.service';

  const CreatedEvent = 'created';

  const DEFAULT_COLUMN = { ...COLUMN_EMPTY, name: 'Column' };

  export default {
    name: 'ColumnCreateModal',

    components: {
      Btn,
      ColumnEditComponent,
      field: StandardModelFieldComponent,
      modal: ModalComponent,
    },

    data() {
      return {
        column: { ...DEFAULT_COLUMN },
        errors: { ...COLUMN_EMPTY },
        hasPermissionToEdit: AuthService.userData().role === 'admin',
      };
    },

    computed: {
      isChanged() {
        return !_.isEqual(DEFAULT_COLUMN, this.column);
      },
    },

    methods: {
      beforeOpen() {
        this.clearFields();
        this.clearErrors();
      },

      submitSuccessCallback(data) {
        const column = { ...data };
        this.$emit(CreatedEvent, column);
        this.close();
      },
      submitErrorCallback(data, status) {
        if (status === 400 && data) {
          Object.keys(data).forEach((field) => {
            this.errors[field] = data[field][0];
          });
        }
      },
      submit() {
        this.clearErrors();
        ColumnAPI.post(
          {
            ...convertNullValuesToUndefined(this.column),
            name: this.column.name?.trim(),
          },
          this.submitSuccessCallback.bind(this),
          this.submitErrorCallback.bind(this),
        );
      },

      clearFields() {
        this.column = { ...COLUMN_EMPTY, name: 'Column' };
      },
      clearErrors() {
        this.errors = { ...COLUMN_EMPTY };
      },
      clearError(fieldName) {
        this.errors = { ...this.errors, [fieldName]: null };
      },

      close() {
        this.$modal.hide('column-create');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .name-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 12px;

    .standard-model-field {
      flex: 1 1;
    }
  }

  .modal-column-create {
    &__no-permissions {
      color: $color-text-danger;
    }
  }
</style>
