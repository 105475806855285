var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select",on:{"click":function($event){$event.stopPropagation();}}},[_c('MQ',{attrs:{"mq":"desktop"}},[_c('DesktopSelect',_vm._g(_vm._b({ref:"desktopSelect",staticClass:"select__desktop",scopedSlots:_vm._u([{key:"leftSideInsideItem",fn:function(ref){
var item = ref.item;
var isInDropdown = ref.isInDropdown;
var hide = ref.hide;
return [_vm._t("leftSideInsideItem",null,{"item":item,"isInDropdown":isInDropdown,"hide":hide})]}},{key:"rightSideInsideItem",fn:function(ref){
var item = ref.item;
var isInDropdown = ref.isInDropdown;
var hide = ref.hide;
return [_vm._t("rightSideInsideItem",null,{"item":item,"isInDropdown":isInDropdown,"hide":hide})]}}],null,true)},'DesktopSelect',Object.assign({}, _vm.$props, _vm.$attrs),false),_vm.$listeners))],1),_c('MQ',{attrs:{"mq":"mobile"}},[_c('MobileSelect',_vm._g(_vm._b({ref:"mobileSelect",scopedSlots:_vm._u([{key:"mobileTrigger",fn:function(){return [_vm._t("mobileTrigger")]},proxy:true},{key:"rightSideInsideItem",fn:function(ref){
var item = ref.item;
var isBottomSheet = ref.isBottomSheet;
var hide = ref.hide;
return [_vm._t("rightSideInsideItem",null,{"item":item,"isBottomSheet":isBottomSheet,"hide":hide})]}}],null,true)},'MobileSelect',Object.assign({}, _vm.$props, _vm.$attrs),false),_vm.$listeners))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }