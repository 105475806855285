<template>
  <div v-if="property || $slots.property" class="decorator-property">
    <span class="decorator-property__label" :style="styleBg">
      <template v-if="property">{{ property }}</template>
      <slot v-else name="property" />
    </span>
    <span
      v-if="$slots.default"
      class="decorator-property__value"
      :class="classOverflow"
      :title="title"
    >
      <span :style="styleBg"><slot /></span>
    </span>
    <span
      v-else-if="$slots.value"
      class="decorator-property__value"
      :class="classOverflow"
      :title="title"
    >
      <span :style="styleBg"><slot name="value" /></span>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'DecoratorProperty',

    props: {
      property: {
        type: String,
      },
      bgColor: {
        type: String,
        required: true,
      },
      title: {
        type: String,
      },
      hasOverflowEllipsis: {
        type: Boolean,
      },
    },

    computed: {
      styleBg() {
        return {
          backgroundColor: this.bgColor,
        };
      },
      classOverflow() {
        return {
          'decorator-property__value--overflow': this.hasOverflowEllipsis,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .decorator-property {
    position: relative;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    overflow: hidden;
    color: $color-text-third;

    &::before {
      z-index: 0;
      position: absolute;
      white-space: nowrap;
      content: '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . '
        '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . '
        '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . '
        '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . '
        '. . . . . . . . . . . . . . . . . . . . ';
    }

    &__label,
    &__value {
      z-index: 1;
    }

    &__label {
      display: flex;
      align-items: center;
      padding-right: 4px;
      margin-right: 15px;
      white-space: nowrap;
    }

    &__value {
      padding-left: 4px;
      color: $color-text-default;
      text-align: right;

      &--overflow {
        @include textOverflow;
      }
    }
  }
</style>
