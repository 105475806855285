import { Dict, Nullable } from '@/types/utility';

// Decided not to use it, but kept the helper for the future
export default class LocationHelper {
  static get isSecuredConnection() {
    return location.protocol === 'https:';
  }

  // get object with get params without using Vue router
  public static getQueryParams(): Nullable<Dict<string>> {
    const params: Nullable<string> = window.location.href.split('?')[1];

    if (params) {
      const keyValue = params.split('&');

      return keyValue.reduce((queryParams, keyValue) => {
        const [key, value] = keyValue.split('=');

        queryParams[key] = value;
        return queryParams;
      }, {} as Dict<string>);
    }
  }
}
