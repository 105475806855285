export const INJECTION_ERRORS = {
  TIMEOUT_DEVICE_RESPOND: 1,
  TIMEOUT_PREPARING: 5,
  SHUT_DOWN: 2,
  NOT_FOUND: 3,
  GENERAL: 4,
  CANCELLED: 6,
  OTHER_STARTED: 7,
  PRESSURE_BOUNDARIES: 8,
  SLOW_PRESSURE_GROW: 9, // unused
  CONFIGURATION_CHANGED: 10,
  NOT_ENOUGH_SOLVENT: 11,
  UNABLE_TO_WASH_NEEDLE_AFTER_INJECTION: 12,
  SHAKING_ERROR: 13,
  HARDWARE_GET_READY_TIMEOUT: 14,
  PUMP_IS_EMPTY: 15,
  AUTOSAMPLER_LOOP_WASH_ERROR: 16,
  REACH_MIN_PRESSURE_ERROR: 17, // unused
  PRESSURE_ALIGN_ERROR: 18, // unused
  START_PUMP_GENERAL_ERROR: 19,
  HARDWARE_COMMUNICATION_ERROR: 20,
  GRADIENT_TABLE_NOT_FOUND: 21,
  UNKNOWN_PUMP_TYPE: 22,
  CANCELLED_BY_SERVER: 23,
  DOMP_OPERATION_FAILED: 24,
  HARDWARE_RESPONSE_VALIDATION_ERROR: 25,
  HARDWARE_COMMAND_ERROR: 26,
};
