<template>
  <div class="device-list-item" :title="device.name" :class="classes" @click="select">
    <span class="device-list-item__label">{{ device.name }}</span>
    <StatusOnline
      :isOnline="device.isOnline"
      :isWorking="isInjectionInProgress"
      class="device-list-item__status"
    />
  </div>
</template>

<script>
  import StatusOnline from '@/uikitProject/statuses/StatusOnline';

  const EVENT_SELECT = 'select';

  export default {
    name: 'PrDevice',

    components: {
      StatusOnline,
    },

    props: {
      device: {
        type: Object,
        required: true,
      },
      isSelected: {
        type: Boolean,
      },
      isSelectable: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      classes() {
        return {
          'device-list-item--selected': this.isSelected,
          'device-list-item--selectable': this.isSelectable,
        };
      },
      isInjectionInProgress() {
        return this.device.state.injection_id != null;
      },
    },

    methods: {
      select() {
        this.$emit(EVENT_SELECT, this.device);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .device-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    height: 32px;
    background-color: $color-bg-input;
    border-radius: $border-radius__sm;

    &--selectable {
      background-color: $color-bg-transparent;
      cursor: pointer;

      &:hover {
        background-color: $color-bg-transparent--hover;
      }

      &:active {
        color: $color-text-on-primary;
        background-color: $color-bg-primary--active;
      }
    }

    &--selected {
      background-color: $color-bg-primary;
    }

    &__label {
      @include textOverflow();
    }

    &__status {
      margin-left: 5px;
    }
  }
</style>
