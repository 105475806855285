<template>
  <div class="progress__wrapper">
    <div class="progress">
      <div class="progress-bar__bg">
        <div
          ref="bar"
          class="progress-bar__bar"
          :style="{ width: Math.min(1, progress) * 100 + '%' }"
        />
      </div>

      <div class="label">{{ label }}</div>
    </div>
  </div>
</template>

<script>
  import 'assets/css/base/icons.scss';

  export default {
    name: 'ProgressComponent',
    props: {
      progress: Number,
      label: String,
    },
  };
</script>

<style lang="scss">
  .progress__wrapper {
    width: 160px;
    @screen sm {
      width: 280px;
    }
  }
  @media only screen and (max-width: 400px) {
    .progress__wrapper {
      width: 120px;
    }
  }

  .progress {
    font-size: 13px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .progress-bar__bg {
    flex-grow: 1;
    background: #00000011;
    height: 4px;
    border-radius: 4px;
    display: block;
  }

  .progress-bar__bar {
    background: $color-bg-process;
    height: 100%;
    border-radius: 4px;
  }

  .label {
    flex-shrink: 0;
    margin-left: 16px;
  }

  .bg-process {
    background: $color-bg-process;
  }

  .bg-ready {
    background: $color-bg-success;
  }

  .bg-preparing {
    background: $pure-color__yellow;
  }
</style>
