<template>
  <div v-if="onlineAndConfigured" class="device-hardware">
    <div v-if="!configurationEmpty" class="device-hardware__hardware-equipment">
      <CromiteOperation
        :configuration="configuration"
        :state="state"
        :ws="socket"
        class="device-hardware__card-hardware"
        @start="start"
      />
      <Phase
        :configuration="configuration"
        :method="device.method"
        :state="state"
        :ws="socket"
        class="device-hardware__card-hardware"
      />
      <Pump
        :configuration="configuration"
        :state="state"
        :ws="socket"
        class="device-hardware__card-hardware"
        data-test-id="card-device-pump"
      />
      <Autosampler
        :configuration="configuration"
        :state="state"
        :ws="socket"
        class="device-hardware__card-hardware"
        data-test-id="card-device-autosampler"
        @start="start"
      />
      <AutosamplerFractionCollector
        :configuration="configuration"
        :state="state"
        :ws="socket"
        class="device-hardware__card-hardware device-hardware__card-hardware--fraction-collector"
        data-test-id="card-device-autosampler"
        @start="start"
      />
      <ValveLoop
        :configuration="configuration"
        :state="state"
        :ws="socket"
        class="device-hardware__card-hardware"
        @start="start"
      />
      <FractionCollectorValve
        :configuration="configuration"
        :state="state"
        :ws="socket"
        class="device-hardware__card-hardware"
      />
      <Valve
        :configuration="configuration"
        :method="method"
        :state="state"
        :ws="socket"
        class="device-hardware__card-hardware"
      />
      <AnalogDetector
        :configuration="configuration"
        :method="method"
        :state="state"
        class="device-hardware__card-hardware"
      />
      <Detector
        :configuration="configuration"
        :state="state"
        :ws="socket"
        class="device-hardware__card-hardware"
      />
    </div>
    <not-available-component v-else> No connected equipment</not-available-component>
  </div>
  <not-available-component v-else-if="device.is_online !== true" class="mt-8">
    Device is Offline
  </not-available-component>
  <not-available-component v-else class="mt-8"> Device not configured</not-available-component>
</template>

<script>
  import NotAvailableComponent from 'components/element/NotAvailableComponent';
  import Pump from 'components/block/hardware/Pump';
  import Autosampler from 'components/block/hardware/Autosampler';
  import ValveLoop from 'components/block/hardware/ValveLoop';
  import FractionCollectorValve from 'components/block/hardware/FractionCollectorValve';
  import AnalogDetector from 'components/block/hardware/AnalogDetector';
  import Detector from 'components/block/hardware/Detector';
  import AutosamplerFractionCollector from 'components/block/hardware/AutosamplerFractionCollector';
  import Phase from 'components/block/hardware/liquids/Phase';
  import CromiteOperation from '@/components/block/hardware/CromiteOperation.vue';
  import Valve from '@/components/block/hardware/Valve.vue';

  export default {
    name: 'DeviceHardwareComponent',

    components: {
      Valve,
      CromiteOperation,
      Phase,
      AutosamplerFractionCollector,
      FractionCollectorValve,
      Detector,
      AnalogDetector,
      ValveLoop,
      Autosampler,
      Pump,
      NotAvailableComponent,
    },

    props: {
      device: Object,
      method: Object,
      socket: Object, //DeviceSocket
    },

    computed: {
      configuration() {
        return this.device?.configuration;
      },
      state() {
        return this.device?.state;
      },

      onlineAndConfigured() {
        return this.device?.is_online === true && this.configuration && this.state?.ready != null;
      },

      configurationEmpty() {
        return (
          this.configuration == null ||
          (this.configuration.pump == null &&
            this.configuration.autosampler == null &&
            this.configuration.fraction_collector == null &&
            this.configuration.valve == null &&
            this.configuration.valve_loop == null &&
            this.configuration.detector == null &&
            this.configuration.analog_signal == null)
        );
      },
    },

    methods: {
      start(vial) {
        this.$emit('start', vial);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .device-hardware {
    overflow-x: hidden;

    &__hardware-equipment {
      display: flex;
      flex-wrap: wrap;
      margin-right: -2px;
    }

    &__card-hardware {
      max-width: 100%;
      margin-right: 2px;
      margin-bottom: 2px;
      flex: 1 1 auto;
      width: calc(25% - 2px);

      @media (max-width: $screen-sm-max) {
        width: calc(33.3% - 2px);
      }

      @media (max-width: $screen-xs-max) {
        width: calc(50% - 2px);
      }

      &--fraction-collector {
        min-width: 320px;
        width: calc(45% - 2px);

        @media (max-width: $screen-xs-max) {
          width: 100%;
        }
      }
    }
  }
</style>
