<template>
  <modal
    :name="name"
    :pivot-y="0"
    height="auto"
    class="modal-method-preview"
    @opened="opened"
    @closed="closed"
  >
    <div class="modal-content modal-content--no-padding">
      <div class="modal-header">
        <div class="header-container modal-method-preview__header">
          <h4 class="method-title">
            Method <span class="modal-method-preview__id">#{{ method.id }}</span>
          </h4>
          <Btn
            v-if="!isInSharedMode"
            height="s"
            padding="xs"
            class="modal-method-preview__btn-expand"
            @click="expand"
          >
            <IconMaterial title="open_in_full" />
          </Btn>
        </div>
        <h5>You cannot edit this method because it has already been used</h5>
      </div>

      <div class="modal-body">
        <div class="block-name">
          <StandardModelFieldComponent
            :value="_method.name"
            style="flex: 1 1"
            type="str"
            :is-title="true"
            label="Method name"
            disabled
            :isMultiline="true"
          />
        </div>

        <MethodEditComponent
          :method="_method"
          :configuration="configurationForMethod"
          class="mt-3"
          style="margin-left: -16px"
          :isReadonly="true"
          :disabled="true"
        />
      </div>
    </div>
  </modal>
</template>

<script>
  import ModalComponent from 'components/element/ModalComponent.vue';
  import MethodEditComponent from 'components/block/MethodEditComponent.vue';

  import { METHOD_DEFAULT } from '@/constants/methods/presets';
  import Btn from '@/uikitBase/btns/Btn';
  import StandardModelFieldComponent from 'components/element/StandardModelFieldComponent';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import { DEFAULT_DEVICE_CONFIGURATION } from '@/constants/methods/configuration';
  import { isMethodDefaultConfiguration } from '@/utils/methodHelpers';

  export default {
    name: 'MethodPreviewModal',

    components: {
      IconMaterial,
      StandardModelFieldComponent,
      Btn,
      MethodEditComponent,
      modal: ModalComponent,
    },

    props: {
      method: {
        type: Object,
        default: () => ({ ...METHOD_DEFAULT }),
      },
      name: {
        type: String,
        default: 'method-preview',
      },
      isInSharedMode: {
        type: Boolean,
      },
    },

    data: () => ({
      isLoading: false,
    }),

    computed: {
      _method() {
        return { ...METHOD_DEFAULT, ...this.method };
      },

      configurationForMethod() {
        const isDefaultConfiguration = isMethodDefaultConfiguration(
          this._method.hardware_configuration,
        );

        return isDefaultConfiguration
          ? DEFAULT_DEVICE_CONFIGURATION
          : this._method?.hardware_configuration ?? DEFAULT_DEVICE_CONFIGURATION;
      },
    },

    methods: {
      opened() {
        this.$emit('opened');
      },
      closed() {
        this.$emit('closed');
      },

      expand() {
        this.$router.push({
          name: 'methodPreview',
          params: {
            methodId: this.method.id,
          },
        });
      },

      close() {
        this.$modal.hide(this.name);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-method-preview {
    &__header {
      height: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__btn-expand {
      margin-left: 15px;
      font-size: 16px;
    }

    &__id {
      font-size: $size-xs;
      color: $color-text-third;
      margin-left: 5px;
    }
  }

  .header-container {
    & .method-title {
      align-self: self-start;
      flex: 0 0;
      white-space: nowrap;
    }
  }
</style>
