<template>
  <div v-if="error" class="app app--error">{{ error }}</div>
  <div v-else-if="!isLoading" class="app-body__flexbox">
    <Header />
    <router-view />

    <!--Absolute positioned-->
    <PortalTarget name="modal" style="z-index: 999" multiple />
    <PortalTarget name="mobilePopup" style="z-index: 1000" multiple />
    <v-dialog />
    <ChangelogModal v-if="versions" :versions="versions" />
    <Faq />
  </div>
</template>

<script>
  import NotificationService from '@/services/NotificationService.ts';
  import { ResponseActions } from 'api/sockets/NotificationSocket.ts';
  import hash from 'object-hash';
  import RouterHelper from 'utils/RouterHelper.ts';
  import Header from 'components/blocks/layouts/vueHeader/Header';
  import ChangelogModal from 'components/block/modal/vueChangelogModal/ChangelogModal';
  import * as Sentry from '@sentry/vue';

  import changelogParser from '@/assets/libraries/changelogParser';

  import CHANGELOG from './CHANGELOG.md';
  import VersionHelper from 'utils/VersionHelper.ts';
  import { AuthService } from '@/services/auth.service';
  import { connectToCentrifuge } from '@/services/CentrifugeService';
  import { persistentStorage, PersistentStorageKeys } from 'utils/persistentStorage';
  import { ERROR_CENTRIFUGE_NO_CONNECTION } from '@/constants/centrifuge/errors';
  import Faq from '@/components/blocks/reactComponents/Faq.vue';

  export default {
    name: 'App',

    components: {
      ChangelogModal,
      Header,
      Faq,
    },

    data: () => ({
      notificationService: null,

      versions: null,

      isLoading: true,
      error: null,
    }),

    async created() {
      this.initSentry();
      await this.initCentrifuge();
      this.initNotificationService();
      this.initDualMode();
      this.initChangelog();
    },

    beforeDestroy() {
      this.notificationService.disconnect();
    },

    methods: {
      initNotificationService() {
        const uiInteractors = {
          addStandardToSequence: () => {
            const pageName = this.$route.name;

            if (pageName === 'sequence') {
              return {
                action: ResponseActions.ADD_TO_SEQUENCE,
                data: {
                  sequence_id: this.$route.params.id,
                },
              };
            }

            return {
              action: ResponseActions.ADD_TO_LAST_SEQUENCE,
            };
          },
          // addStandardToCalibration: ({ data }) => {
          //   const calibrationId = data?.calibration_id;
          //   if (calibrationId) {
          //     return {
          //       action: ResponseActions.ADD_TO_CALIBRATION,
          //       calibrationId,
          //     };
          //   }
          //
          //   return {
          //     action: ResponseActions.ADD_TO_NEW_CALIBRATION,
          //   };
          // },
          getActionNameStandard: ({ data }) => {
            const standardId = data?.standard_id;
            if (standardId) {
              return 'CREATE STANDARD';
            }

            return 'NEW INJECTION';
          },
          getActionNameSequence: ({ data }) => {
            const pageName = this.$route.name;

            if (pageName === 'sequence') {
              return 'ENQUEUE HERE';
            }

            return `ADD TO ${data.sequence?.name}`;
          },
          redirect: ({ page, data }) => {
            const getRedirectData = (page, data) => {
              if (page === 'standard') {
                return {
                  redirect: {
                    name: 'standard injection new',
                    params: {
                      id: data.id,
                    },
                    query: {
                      amount: data.amount,
                    },
                  },
                  pageName: 'Standard injection new',
                };
              }
            };

            const redirectData = getRedirectData(page, data);
            const id = hash(redirectData);
            const actions = [
              {
                text: 'OPEN',
                onClick: () => {
                  this.$router.push(redirectData.redirect);
                  this.notifyHide(id);
                },
              },
            ];
            this.notifyWithActions(`Open page: ${redirectData.pageName}`, { actions }, id);
          },
        };

        this.notificationService = new NotificationService(
          AuthService.userData().sub,
          {
            showWithActions: this.notifyWithActions,
            showError: this.notifyError,
            hide: this.notifyHide,
          },
          uiInteractors,
        );
      },
      initDualMode() {
        const mediaQueryMinWidth = window.matchMedia('(min-width: 1600px)');
        const mediaQueryPrintMode = window.matchMedia('print');

        mediaQueryMinWidth.onchange = ({ matches: doUseDualLayout }) => {
          const isPrintMode = mediaQueryPrintMode.matches;
          if (
            !isPrintMode &&
            RouterHelper.isAllowDualMode &&
            RouterHelper.isDualMode !== doUseDualLayout
          ) {
            this.$router.go();
          }
        };
      },
      async initChangelog() {
        if (!AuthService.isAuthenticated()) {
          // To handle the case if a user clears the local storage manually
          return;
        }

        const changelog = changelogParser({ text: CHANGELOG });

        if (changelog.versions.length) {
          const [{ version: latestVersion }] = changelog.versions;
          this.versions = changelog.versions;

          const lastUsedAppVersion = persistentStorage.get(
            PersistentStorageKeys.LAST_USED_APP_VERSION,
          );
          if (!lastUsedAppVersion || VersionHelper.greaterThan(latestVersion, lastUsedAppVersion)) {
            persistentStorage.set(PersistentStorageKeys.LAST_USED_APP_VERSION, latestVersion);

            await this.$nextTick();
            this.$modal.show('changelog-modal');
          }
        }
      },
      async initCentrifuge() {
        try {
          await connectToCentrifuge();
        } catch {
          this.error = ERROR_CENTRIFUGE_NO_CONNECTION;
        } finally {
          this.isLoading = false;
        }
      },
      initSentry() {
        const user = AuthService.userData();

        if (user) {
          Sentry.setUser({
            id: user.user_id,
            organizationId: user.organization_id,
            isAdmin: user.is_superuser,
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app {
    &--error {
      margin: 20px 0;
      text-align: center;
      color: $color-text-danger;
    }
  }
</style>
