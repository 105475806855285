<template>
  <div class="disconnected-status" />
</template>

<script>
  export default {
    name: 'DisconnectedStatus',
  };
</script>

<style lang="scss" scoped>
  .disconnected-status {
    width: 8px;
    height: 8px;

    &:before,
    &:after {
      position: absolute;
      left: 3px;
      content: ' ';
      height: 9px;
      width: 1px;
      background-color: $color-bg-danger;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
</style>
