import {
  Fraction,
  FractionsCount,
  ServerFormatItem,
  ValidationErrorMessage,
  ValidationStatus,
} from '@/uikitProject/tables/gradient/utils/types';
import { generateId } from '@/utils/generateId';
import Decimal from 'decimal.js-light';

const addStepToTime = (time: number, step: number): number => {
  return Number((time + step).toFixed(2));
};

export const fixDataIfTimeEqual = (data: ServerFormatItem[], step: number): ServerFormatItem[] => {
  if (data.length < 2) return data;

  const [itemFirst, ...items] = data;

  let previousItem: ServerFormatItem = itemFirst;

  const itemsFixedTime = items.map((item) => {
    if (item.time === previousItem.time) {
      previousItem = {
        ...item,
        time: addStepToTime(item.time, step),
      };
      return previousItem;
    }

    previousItem = item;

    return item;
  });

  return [itemFirst, ...itemsFixedTime];
};

const checkDuplicateValues = (values: any[]): boolean => {
  const set = new Set(values);
  return set.size !== values.length;
};

const getDefaultFractions = (fractionsCount: FractionsCount): Fraction[] => {
  const fractionsEmpty = new Array(fractionsCount).fill(undefined);

  if (fractionsCount === 3) {
    const fractionsFilled = fractionsEmpty.map(() => 0.33);
    fractionsFilled[2] = 0.34;
    return fractionsFilled;
  }

  return new Array(fractionsCount).fill(undefined).map(() => 1 / fractionsCount);
};

export class GradientTableHelper {
  public static insertRow(
    data: ServerFormatItem[],
    insertBefore: number,
    step: number,
  ): ServerFormatItem[] {
    const rowPrevious = data[insertBefore - 1];

    const rowNew = {
      time: addStepToTime(rowPrevious.time, step),
      fractions: [...rowPrevious.fractions],
      id: generateId(),
    };

    const dataCopied = [...data];
    dataCopied.splice(insertBefore, 0, rowNew);

    return fixDataIfTimeEqual(dataCopied, step);
  }

  public static insertFirstRow(
    data: ServerFormatItem[],
    step: number,
    fractionsCount: FractionsCount,
  ): ServerFormatItem[] {
    const rowNew = {
      time: 0,
      fractions: getDefaultFractions(fractionsCount),
      id: generateId(),
    };
    const dataCopied = [rowNew, ...data];
    return fixDataIfTimeEqual(dataCopied, step);
  }

  public static validateGradient(
    items: ServerFormatItem[],
    maxTimeValue: number,
    step: number,
  ): ValidationStatus {
    const timeValues = items.map((row) => row.time);

    const isTimeInvalidStep = timeValues.some((time) => !new Decimal(time).mod(step).equals(0));
    if (isTimeInvalidStep) {
      return {
        isValid: false,
        error: ValidationErrorMessage.INVALID_TIME_STEP,
      };
    }

    if (checkDuplicateValues(timeValues)) {
      return {
        isValid: false,
        error: ValidationErrorMessage.DUPLICATE_TIME,
      };
    }

    if (Math.max(...timeValues) > maxTimeValue) {
      return {
        isValid: false,
        error: ValidationErrorMessage.MAX_ALLOWED_TIME,
      };
    }

    const hasInvalidPercentsInRow = items.some(({ fractions }) => {
      const totalPercent = fractions.reduce((acc, value) => acc.add(value), new Decimal(0));
      return !totalPercent.equals(1);
    });
    if (hasInvalidPercentsInRow) {
      return {
        isValid: false,
        error: ValidationErrorMessage.PERCENTS_NOT_100,
      };
    }

    return {
      isValid: true,
    };
  }
}
