<template>
  <div v-resize:throttle.initial="onResize" class="chart-chromatogram">
    <template v-if="doPerformanceOptimisations">
      <GlobalEvents @visibilitychange="onPageVisibilityChange" />
      <GlobalEvents target="window" @storage="onLocalStorageChange" />
    </template>

    <template v-if="isShowChart">
      <PrChartPressure
        v-if="measurementPressure"
        ref="chartPressure"
        :isShowPressure="isShowPressure"
        :measurement="measurementPressure"
        :timeMin="(detectionTime && detectionTime.start) || 0"
        :timeMax="timeMax"
        :injection="injection"
        :method="method"
        :detectionTime="measurements.length === 1 ? detectionTime : null"
        :isApplyDetectionTime="measurements.length === 1 && isApplyDetectionTime"
        :hasLiveUpdate="hasLiveUpdate"
        :sampleToken="sampleToken"
        :width="width"
        :initialZoom="initialZoom"
        class="chart-chromatogram__pressure"
        @currentPressure="$emit('currentPressure', $event)"
      />

      <PrChartChromatogramSingle
        v-if="measurements.length === 1 && runningChromatogram !== 'multiple'"
        ref="chartSingle"
        :key="chromatogramRefreshKey"
        :measurement="measurements[0]"
        :injection="injection"
        v-bind="$attrs"
        :sampleToken="sampleToken"
        :doPerformanceOptimisations="doPerformanceOptimisations"
        class="chart-chromatogram__chart"
        :toolbar="hasToolbar"
        :hasPadding="hasPadding"
        :hasLiveUpdate="hasLiveUpdate"
        :heightPx="heightPx"
        :timeMax="timeMax"
        :hasOnlyHorizontalZoom="hasOnlyHorizontalZoom"
        :hasSpacesIfOutOfDetectionTime="hasSpacesIfOutOfDetectionTime"
        :doShowExactBounds="doShowExactBounds"
        :detectionTime="detectionTime"
        :isApplyDetectionTime="isApplyDetectionTime"
        :creationDate="creationDate"
        :method="method"
        :width="width"
        :isApplyPostprocessing="isApplyPostprocessing"
        :initialZoom="initialZoom"
        :postprocessing="postprocessingForSingleChannel"
        :hasZoomActivationOverlay="hasZoomActivationOverlay"
        :hasPostprocessingsToApply="hasPostprocessingsToApply"
        :doReapplyPostprocessing="doReapplyPostprocessing"
        :editable="editable"
        :doRefreshPeaks="doRefreshPeaks"
        @zoom="syncZoom"
        @update="$emit('update')"
        @destroy="setRunningChromatogram(null)"
        @showFullChart="showFullChart"
        @showDetectionTime="showDetectionTime"
        @detectionTime="onDetectionTime"
        @applyPostprocessing="$emit('applyPostprocessing', $event)"
        @updateMeasurementBaseline="$emit('updateMeasurementBaseline', $event)"
        @refreshPostprocessing="$emit('refreshPostprocessing')"
        @zoomActivation="$emit('zoomActivation', $event)"
        @postprocessingDone="$emit('postprocessingDone')"
      >
        <template #peaks="{ peaks, measurementSocket, setPeakHighlighting, highlightedPeakId }">
          <slot
            name="peaks"
            v-bind="{
              peaks,
              measurementSocket,
              setPeakHighlighting,
              highlightedPeakId,
            }"
          />
        </template>
      </PrChartChromatogramSingle>
      <PrDataAdapter
        v-if="measurements.length && measurements.length !== 1 && runningChromatogram !== 'single'"
        :key="chromatogramRefreshKey"
        :measurements="measurements"
        :hasLiveUpdate="hasLiveUpdate"
        :sampleToken="sampleToken"
        :doSkipDataFetching="doSkipDataFetching"
        :isApplyPostprocessing="isApplyPostprocessing"
        :sampleId="injection.sample_id"
        #default="{ data, isApplyPostprocessingSignal, isFetchingData, onReapplyPostprocessings }"
        @create="setRunningChromatogram('multiple')"
        @destroy="setRunningChromatogram(null)"
      >
        <PrChartChromatogramMultiple
          ref="chartMultiple"
          :measurements="data"
          :injection="injection"
          v-bind="$attrs"
          :doPerformanceOptimisations="doPerformanceOptimisations"
          :hasPadding="hasPadding"
          :heightPx="heightPx"
          :timeMax="timeMax"
          :measurementColors="measurementColors"
          :hasOnlyHorizontalZoom="hasOnlyHorizontalZoom"
          :doShowExactBounds="doShowExactBounds"
          :hasMauScale="hasMauScale"
          :detectionTime="null"
          :hasSpacesIfOutOfDetectionTime="hasSpacesIfOutOfDetectionTime"
          :isApplyDetectionTime="isApplyDetectionTime"
          :isApplyPostprocessingSignal="isApplyPostprocessingSignal"
          :creationDate="creationDate"
          :method="method"
          :width="width"
          :toolbar="hasToolbar"
          :initialZoom="initialZoom"
          :postprocessings="postprocessings"
          :isShared="sampleToken != null"
          :isFetchingData="isFetchingData"
          :hasZoomActivationOverlay="hasZoomActivationOverlay"
          class="chart-chromatogram__chart"
          @reapplyPostprocessings="onReapplyPostprocessings"
          @zoom="syncZoom"
          @showFullChart="showFullChart"
          @showDetectionTime="showDetectionTime"
          @zoomActivation="$emit('zoomActivation', $event)"
        />
      </PrDataAdapter>
    </template>
  </div>
</template>

<script>
  import PrChartChromatogramMultiple from './private/PrChartChromatogramMultiple';
  import PrChartChromatogramSingle from './private/PrChartChromatogramSingle';
  import PrChartPressure from './private/PrChartPressure';
  import PrDataAdapter from './private/PrDataAdapter';
  import { generateId } from 'utils/generateId.ts';
  import { persistentStorage, PersistentStorageKeys } from 'utils/persistentStorage';
  import resize from 'vue-resize-directive';

  const EVENT_UPDATE_INITIAL_ZOOM = 'update:initialZoom';

  export default {
    name: 'NewChartChromatogram',

    components: {
      PrChartPressure,
      PrDataAdapter,
      PrChartChromatogramSingle,
      PrChartChromatogramMultiple,
    },

    directives: { resize },

    props: {
      measurements: {
        type: Array,
        required: true,
      },
      hasOnlyHorizontalZoom: {
        type: Boolean,
      },
      doPerformanceOptimisations: {
        type: Boolean,
        default: false,
      },
      measurementPressure: {
        type: Object,
        default: null,
      },
      isShowPressure: {
        type: Boolean,
      },
      hasToolbar: {
        type: Boolean,
        default: true,
      },
      hasPadding: {
        type: Boolean,
        default: true,
      },
      hasMauScale: {
        type: Boolean,
        default: true,
      },
      hasLiveUpdate: {
        type: Boolean,
        default: true,
      },
      heightPx: {
        type: Number,
        default: 300,
      },
      timeMax: {
        type: Number,
      },
      sampleToken: {
        type: String,
        default: null,
      },
      doShowExactBounds: {
        type: Boolean,
      },
      doSkipDataFetching: {
        type: Boolean,
      },

      /**
       * I wanted to add a color prop to each measurement from outside the current component, but I couldn't.
       * DataAdapter gets events from the measurementSocket and overrides an initial prop.
       * All solutions are not good(
       */
      measurementColors: {
        type: Object,
      },

      hasSpacesIfOutOfDetectionTime: {
        type: Boolean,
      },

      isApplyPostprocessing: {
        type: Boolean,
      },

      creationDate: {
        type: Date,
      },

      injection: {
        type: Object,
      },
      method: {
        type: Object,
      },

      initialZoom: {
        type: Object,
      },

      postprocessings: {
        type: Array,
      },

      hasZoomActivationOverlay: {
        type: Boolean,
        required: true,
      },

      hasPostprocessingsToApply: {
        type: Boolean,
        required: true,
      },

      editable: {
        type: Boolean,
        default: true,
      },

      doRefreshPeaks: {
        type: Boolean,
        required: false,
      },
      doReapplyPostprocessing: {
        type: Boolean,
      },
    },

    data() {
      return {
        id: generateId(),
        isShowChart: true,

        sharedZoom: this.initialZoom,

        // Single or multiple
        runningChromatogram: null,

        isApplyDetectionTime: true,

        width: null,

        detectionTime: null,
      };
    },

    computed: {
      chromatogramRefreshKey() {
        return this.measurements.map((measurement) => measurement.id).join();
      },

      postprocessingForSingleChannel() {
        return this.measurements.length === 1
          ? this.postprocessings?.find(
              (postprocessing) => postprocessing.measurement.id === this.measurements[0].id,
            )
          : null;
      },
    },

    watch: {
      // doPerformanceOptimisations(value) {
      //   if (!value) {
      //     this.isShowChart = true;
      //   }
      // },
      async measurementPressure() {
        await this.$nextTick();
        this.syncZoom();
      },
    },

    created() {
      persistentStorage.set(PersistentStorageKeys.ACTIVE_CHROMATOGRAM_ID, this.id);
    },

    methods: {
      syncZoom(zoomData = this.sharedZoom) {
        this.$refs.chartPressure?.zoom(zoomData);
        this.sharedZoom = zoomData;

        this.$emit(EVENT_UPDATE_INITIAL_ZOOM, zoomData);
      },

      onPageVisibilityChange() {
        if (document.visibilityState === 'visible') {
          persistentStorage.set(PersistentStorageKeys.ACTIVE_CHROMATOGRAM_ID, this.id);
          this.isShowChart = true;
        }
      },
      onLocalStorageChange(e) {
        if (e.key === PersistentStorageKeys.ACTIVE_CHROMATOGRAM_ID) {
          this.isShowChart = e.newValue === this.id;
        }
      },

      setRunningChromatogram(value) {
        this.runningChromatogram = value;
      },

      showFullChart() {
        this.isApplyDetectionTime = false;
      },
      showDetectionTime() {
        this.isApplyDetectionTime = true;
      },

      onResize(element) {
        this.width = element.getBoundingClientRect().width;
      },

      onDetectionTime(detectionTime) {
        this.detectionTime = detectionTime;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chart-chromatogram {
    &__edit-panel {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }

    &__pressure {
      margin-bottom: 20px;
    }

    &__chart {
      margin-top: -20px;
    }
  }
</style>
