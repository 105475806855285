<template>
  <Label class="radio" :isSelected="isSelected">
    <input v-model="_checked" type="radio" :name="name" :value="value" class="radio__input" />
    <slot />
  </Label>
</template>

<script>
  import Label from './private/Label';

  const EVENT_MODEL = 'model';

  export default {
    name: 'Radio',

    components: {
      Label,
    },

    model: {
      prop: 'checked',
      event: EVENT_MODEL,
    },

    props: {
      checked: {
        type: Number,
      },
      name: {
        type: String,
        required: true,
      },
      value: {
        type: Number,
        required: true,
      },
    },

    computed: {
      _checked: {
        get() {
          return this.checked;
        },
        set(value) {
          this.$emit(EVENT_MODEL, value);
        },
      },

      isSelected() {
        return this.checked === this.value;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .radio {
    &__input {
      display: none;
    }
  }
</style>
