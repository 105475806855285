<template>
  <div class="form-creation">
    <div class="form-creation__inputs">
      <slot />
    </div>
    <div class="form-creation__divider"></div>
    <div class="form-creation__settings">
      <slot name="settings" />

      <DeviceMethodColumnInputs
        :model="model"
        :isRequired="isRequiredDevice"
        :isReadonly="isReadonlyDevice"
        :hideColumn="!requireColumn"
        :canSelectMethod="canSelectMethod"
        :canSelectPostprocessing="canSelectPostprocessing"
        :isRequiredSingleChannelMode="isRequiredSingleChannelMode"
        :isRequiredPostprocessing="isRequiredPostprocessing"
        @update:model="setModel"
      />
    </div>
    <footer class="form-creation__footer">
      <div class="form-creation__label-help">Use Ctrl+Enter to create</div>
      <button
        class="button--square-image-primary form-creation__btn-submit"
        :disabled="!isValid"
        data-test-id="btn-create"
        @click="submit"
      >
        <IconComponent title="check" size="18" />
        Create
      </button>
    </footer>
  </div>
</template>

<script>
  import DeviceMethodColumnInputs from 'components/block/DeviceMethodColumnInputs';
  import IconComponent from '@/uikitBase/icons/IconMaterial';
  import { KEY_ENTER } from 'utils/key-codes';

  const EVENT_SUBMIT = 'submit';
  const EVENT_CHANGE_MODEL = 'update:model';

  export default {
    name: 'Creation',

    components: {
      DeviceMethodColumnInputs,
      IconComponent,
    },

    model: {
      prop: 'model',
      event: EVENT_CHANGE_MODEL,
    },

    props: {
      model: {
        type: Object,
        required: true,
      },
      isValid: {
        type: Boolean,
        required: true,
      },
      isRequiredDevice: {
        type: Boolean,
        required: true,
      },
      requireColumn: {
        type: Boolean,
        default: false,
      },
      isReadonlyDevice: {
        type: Boolean,
        default: false,
      },
      canSelectMethod: {
        type: Boolean,
        default: false,
      },
      canSelectPostprocessing: {
        type: Boolean,
        default: false,
      },
      isRequiredSingleChannelMode: {
        type: Boolean,
        default: false,
      },
      isRequiredPostprocessing: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      ctrlEnterHandler: null,
    }),

    mounted() {
      this.initializeSubmitByKeyboard();
    },
    beforeDestroy() {
      this.destroySubmitByKeyboard();
    },

    methods: {
      _ctrlEnterHandler(e) {
        if (e.keyCode === KEY_ENTER && e.ctrlKey) this.submit();
      },
      initializeSubmitByKeyboard() {
        this.ctrlEnterHandler = this._ctrlEnterHandler.bind(this);
        window.addEventListener('keydown', this.ctrlEnterHandler);
      },
      destroySubmitByKeyboard() {
        window.removeEventListener('keydown', this.ctrlEnterHandler);
      },

      submit() {
        this.isValid && this.$emit(EVENT_SUBMIT);
      },

      setModel(model) {
        this.$emit(EVENT_CHANGE_MODEL, model);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .form-creation {
    border-radius: $border-radius__lg;
    background: white;
    padding: 32px 16px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 20px 0 rgba(0, 0, 0, 0.09);

    &__settings {
      max-width: 470px;
      margin-bottom: 20px;
    }

    &__divider {
      margin: 32px 0 24px;
      height: 1px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.13333);
    }

    &__label-help {
      color: rgba(0, 0, 0, 0.4);
      font-size: 13px;
      margin-right: 24px;
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
</style>
