<template>
  <div v-if="type === 'bool'" class="model-field">
    <PopupHelper v-if="helper" :text="helper">
      <label class="model-field__label" style="display: flex">{{ label }}</label>
    </PopupHelper>
    <template v-else>
      <label>{{ label }}</label>
    </template>

    <div class="model-field__container">
      <div class="model-field__input-container">
        <button
          :class="{
            'button--underline': value,
            'button--preview': disabled,
          }"
          @click="updateValue(!value)"
          v-html="value ? 'ON' : 'OFF'"
        />
      </div>
      <slot v-if="afterSlotVisible" name="after"></slot>
    </div>
  </div>
  <label v-else class="model-field">
    <PopupHelper v-if="helper" :text="helper">
      <div style="display: flex">{{ label }}</div>
    </PopupHelper>
    <template v-else>
      {{ label }}
    </template>
    <div class="model-field__container">
      <div class="model-field__input-container">
        <div
          v-if="!isEditMode"
          ref="div"
          class="model-field__preview"
          :class="{
            'model-field__preview--no-value': !strValue,
          }"
          @click="edit"
        >
          {{ strValue || 'click to edit' }}
        </div>
        <InputAutosuggest
          v-else-if="hints && !disabled"
          :value="strValue || ''"
          :items="hints"
          labelProp="name"
          :placeholder="label"
          :titleBottomSheet="label"
          :isLoadingSuggestions="false"
          :isShowAllSuggestionsOnFocus="true"
          :type="inputType"
          :step="step"
          :isError="error"
          class="model-field__input-autosuggest"
          @focus="$emit('focus')"
          @model="updateValue"
        >
          <template
            #input="{
              value,
              tempValue,
              setValue,
              setFilterValue,
              handleInputFocus,
              handleInputBlur,
              isError,
            }"
          >
            <input
              ref="input"
              :value="tempValue != null ? tempValue : value"
              :type="inputType"
              :inputmode="inputMode"
              :step="step"
              :class="{ 'model-field__input--error': isError }"
              @keypress="checkSymbol"
              @keydown.enter="blur"
              @input="setValue($event.target.value) && setFilterValue($event.target.value)"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
            />
          </template>
          <template #mobileTrigger="{ value, isError }">
            <input
              :value="value"
              type="text"
              disabled
              class="model-field__trigger-mobile"
              :class="{ 'model-field__input--error': isError }"
            />
          </template>
        </InputAutosuggest>
        <template v-else-if="isMultiline">
          <InputFormMultiline
            :value="value"
            class="model-field__input-multiline"
            :isDisabled="disabled"
            @model="updateValue"
          />
        </template>
        <template v-else>
          <input
            ref="input"
            class="model-field__input"
            :type="inputType"
            :inputmode="inputMode"
            :class="{
              'error-input': error,
              'title-input': isTitle,
              'dont-round-right-corners': afterSlotVisible,
            }"
            :value="value"
            :disabled="disabled"
            :step="step"
            :placeholder="placeholder"
            @focus="$emit('focus')"
            @keypress="checkSymbol"
            @keydown.enter="blur"
            @input="updateValue($event.target.value)"
          />
        </template>
      </div>
      <slot v-if="afterSlotVisible" name="after"></slot>
    </div>
  </label>
</template>

<script>
  import InputAutosuggest from '@/uikitBase/inputs/vueInputAutosuggest/InputAutosuggest';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper';
  import InputFormMultiline from '@/uikitProject/inputs/InputFormMultiline';
  import { consoleHelpers } from 'utils/logHelpers';

  const intRegex = /^([+-]?[1-9]\d*|0)$/;
  const floatRegex = /^[+-]?((\.\d+)|(\d+(\.\d+)?))$/;

  const ALLOWED_SYMBOLS_FOR_INT_TYPE = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const ALLOWED_SYMBOLS_FOR_FLOAT_TYPE = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '.',
    ',',
  ];

  const inputTypesMap = {
    str: 'text',
    int: 'number',
    float: 'number',
    bool: 'checkbox',
  };
  const inputModesMap = {
    str: 'text',
    int: 'numeric',
    float: 'decimal',
  };

  const stepsTypeMap = {
    str: '',
    int: '1',
    float: 'any',
    bool: '',
  };

  const validatorsTypeMap = {
    str() {
      return true;
    },
    int(value) {
      return intRegex.test(value);
    },
    float(value) {
      return floatRegex.test(value);
    },
  };

  const ValidEvent = 'update:valid';
  const ModelEvent = 'update:value';

  const EVENT_EDIT = 'edit';

  export default {
    name: 'ModelFieldComponent',

    components: {
      InputFormMultiline,
      PopupHelper,
      InputAutosuggest,
    },

    model: {
      prop: 'value',
      event: ModelEvent,
    },

    props: {
      value: {},
      type: String,
      label: String,
      placeholder: String,
      disabled: Boolean,
      isTitle: Boolean,
      error: Boolean,
      hints: Array,
      afterSlotVisible: Boolean,
      helper: String,
      isMultiline: {
        type: Boolean,
        default: false,
      },
      canSelectOnlyFromHints: {
        type: Boolean,
        default: false,
      },
      canEdit: {
        type: Boolean,
        default: true,
      },
      isEditMode: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      inputType() {
        let value = inputTypesMap[this.type];
        if (!value) {
          consoleHelpers.error(
            `Unknown type "${this.type}" for field "${this.label}". Using text type`,
          );
          value = 'text';
        }
        return value;
      },
      inputMode() {
        return inputModesMap[this.type];
      },
      valid() {
        return this.isValid(this.value);
      },
      step() {
        return stepsTypeMap[this.type] || '';
      },
      strValue() {
        if (this.value === null || this.value === undefined || this.value === '') return null;
        return String(this.value);
      },
    },

    watch: {
      valid(value) {
        this.$emit(ValidEvent, value);
      },
    },

    methods: {
      isValid(value) {
        if (this.type in validatorsTypeMap) {
          return validatorsTypeMap[this.type](value);
        }
        return true;
      },
      updateValue(newValue) {
        if (this.disabled) {
          return;
        }

        if (
          this.canSelectOnlyFromHints &&
          this.hints &&
          newValue &&
          !this.hints?.some((hint) => hint.toLowerCase() === newValue.trim().toLowerCase())
        ) {
          return;
        }

        let value = newValue;
        if (newValue === null || newValue === undefined || newValue === '') {
          value = null;
        } else if (this.type === 'int' && typeof newValue === 'string') {
          value = parseInt(newValue, 10);
        } else if (this.type === 'float' && typeof newValue === 'string') {
          value = parseFloat(newValue.replace(',', '.'));
        }
        this.$emit(ModelEvent, value);
      },
      autosuggestSelected(event) {
        if (!event) return;
        this.updateValue(event.item);
      },

      checkSymbol(e) {
        if (
          (this.type === 'int' && !ALLOWED_SYMBOLS_FOR_INT_TYPE.includes(e.key)) ||
          (this.type === 'float' && !ALLOWED_SYMBOLS_FOR_FLOAT_TYPE.includes(e.key))
        ) {
          e.preventDefault();
        }

        if (
          this.type === 'float' &&
          (e.key === '.' || e.key === ',') &&
          /[.,]/.test(e.target.value)
        ) {
          e.preventDefault();
        }
      },

      blur() {
        this.$refs.input?.blur();
      },

      select() {
        this.$refs.input?.select();
      },

      edit() {
        if (this.canEdit) {
          this.$emit(EVENT_EDIT);
        }
      },

      focus() {
        this.$refs.input.focus();
      },
    },
  };
</script>

<style lang="scss">
  .button {
    &--preview {
      cursor: default;
      background-color: unset;

      &:enabled:hover,
      &:enabled:active {
        background-color: unset;
        color: black;
      }
    }
  }
</style>

<style lang="scss">
  .model-field__container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 1 0;
  }

  .model-field__input-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  .model-field__input {
    width: 100%;

    &.dont-round-right-corners {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .error-input {
      border: 1px solid $color-text-danger;
      padding: 7px 0 5px 0;
      text-indent: 11px;
    }

    &.title-input {
      font-size: 24px !important;
      font-weight: bold;
    }
  }

  .model-field__button {
    padding: 4px 16px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    vertical-align: center;

    &--in-field {
      border-radius: 0;

      &:last-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .model-field {
    &__input-autosuggest {
      flex: 1;
    }

    &__input {
      &--error {
        border-bottom: 1px solid $color-bg-danger;
      }
    }

    &__input-multiline {
      color: $color-text-default;
      font-size: $size-lg;
      font-weight: $weight-bold;
    }

    &__preview {
      font-size: 13px;
      line-height: 1.33;
      white-space: pre-line;
      word-break: break-word;
      color: black;

      &--no-value {
        color: $color-text-danger;
      }
    }
  }

  [disabled] {
    cursor: not-allowed;
  }
</style>
