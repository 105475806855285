<template>
  <DecoratorState
    :hasTitleOnAdaptive="hasTitleOnAdaptive"
    :title="title"
    :hasTitle="hasTitle"
    class="state-process"
    data-test-id="state-process"
  >
    <template #icon>
      <div class="state-process__icon" />
    </template>
  </DecoratorState>
</template>

<script>
  import DecoratorState from '@/uikitProject/decorators/DecoratorState';

  export default {
    name: 'StateProcess',

    components: {
      DecoratorState,
    },

    props: {
      title: {
        type: String,
      },
      hasTitle: {
        type: Boolean,
        default: true,
      },
      hasTitleOnAdaptive: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .state-process {
    color: $color-text-process;

    &__icon {
      display: inline-block;
      background-image: url('~assets/img/running.svg');
      background-size: contain;
      margin: 0 0 2px 0;
      vertical-align: bottom;
      text-decoration: none;
      width: 15px;
      height: 15px;
    }
  }
</style>
