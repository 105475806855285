import { envVariables } from '@/utils/envHelpers';

export const DELTAS_MAU = [
  0.001,
  0.01,
  0.1,
  0.2,
  0.5,
  1,
  2,
  5,
  10,
  20,
  25,
  50,
  100,
  200,
  250,
  500,
  1000,
  10000,
  100000,
  1000000,
  10000000,
  100000000,
];

export const DELTAS_TIME = [0.01, 0.1, 0.2, 0.1, 0.2, 0.5, 1, 2, 3, 4, 5, 10, 20, 25, 50, 100];

export const CHART_COLORS = {
  background: 'transparent',
  chartAxis: '#777777',
  scale: '#777777',
  timeLabels: '#00000088',
  mauLabels: '#00000088',
  peakNums: '#2a4cff',
  peakLineManual: '#e90f69',
  peakLineAuto: '#4076ff',
  text: '#00000088',
  line: '#000',
  baseline: 'rgb(230,173,0)',
  deleteZone: 'rgba(255, 38, 0, 0.1)',
};

export const ALT_CHART_COLORS = {
  background: '#00000008',
  scale: '#000000bb',
  grid: '#00000044',
  text: '#a8a8a8',
  line: '#f06',
  dot: '#312aa4',
  dotActive: '#f06',
};

export const PADDING_HORIZONTAL = 32;
export const PADDING_VERTICAL = 24;

// Values from server
export const WRONG_VALUE = 10000;
export const LOST_VALUE = 9999.999;
export const CALIBRATION_VALUE = 9999.998;

// Value is set by our application if data isn't received
export const UNKNOWN_VALUE = null;
// Value is set by our application to avoid printing edge points
export const OUT_OF_DETECTION_VALUE = 9999.996;

export const MAGIC_VALUES_ARE_NOT_SUPPORTED_AFTER_UNIXTIME_MS = Number(
  envVariables.MAGIC_VALUES_ARE_NOT_SUPPORTED_AFTER_UNIXTIME_MS,
);
export const THRESHOLD_FOR_VALUES_THAT_UNSUPPORTED_IN_OLD_MEASUREMENTS = 10000;
