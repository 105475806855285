<template>
  <div data-test-id="card-column">
    <ColumnCreateModal @created="columnCreatedInModal($event)" />

    <ColumnEditModal
      :column="pColumn"
      @created="columnCreatedInModal($event)"
      @updated="columnUpdatedInModal($event)"
    />

    <div class="column-card" @click="openColumnDetail">
      <div class="column-card__top">
        <div class="column-card__label">Column</div>

        <SelectColumnComponent
          :column="pColumn"
          class="column-card__select"
          :class="{
            'column-card__select--visible': isSelectAlwaysVisible,
          }"
          data-test-id="select-method-column"
          @change="columnUpdatedInModal"
          @showPopup="setIsSelectAlwaysVisible(true)"
          @hidePopup="setIsSelectAlwaysVisible(false)"
        />
        <span
          v-if="pColumn"
          class="column-card__name"
          :class="{
            'column-card__name--hidden': isSelectAlwaysVisible,
          }"
          >{{ pColumn.name }}</span
        >
        <div
          v-else
          class="column-card__name"
          :class="{
            'column-card__name--hidden': isSelectAlwaysVisible,
          }"
          data-test-id="column-name"
        >
          <i>Not selected</i>
        </div>

        <div
          v-if="hasPermissionToEdit"
          class="column-card__buttons"
          style="display: flex; margin-left: 16px"
        >
          <button
            class="link-button"
            data-test-id="btn-show-create-column-modal"
            @click.stop="openColumnCreate"
          >
            <i class="material-icons material-icon--14">add</i> New
          </button>
          <button class="link-button" @click.stop="openColumnDetail">
            <i class="material-icons material-icon--14">edit</i> Edit
          </button>
        </div>
      </div>

      <InfoColumn v-if="pColumn" :column="pColumn" :isMini="true" />
      <i v-else-if="hasPermissionToEdit" class="text-gray mt-2 block"
        >Choose column from the list, or create new</i
      >
      <i v-else class="text-gray mt-2 block">Choose column from the list</i>
    </div>
  </div>
</template>

<script>
  import SelectColumnComponent from 'components/element/SelectColumnComponent';
  import ColumnCreateModal from 'components/block/modal/ColumnCreateModal';
  import ColumnEditModal from 'components/block/modal/ColumnEditModal';
  import InfoColumn from '@/uikitProject/info/InfoColumn';
  import { AuthService as AuhService } from '@/services/auth.service';

  const EVENT_UPDATE_COLUMN = 'update:column';

  export default {
    name: 'ColumnCard',

    components: {
      InfoColumn,
      ColumnEditModal,
      ColumnCreateModal,
      SelectColumnComponent,
    },

    model: {
      prop: 'column',
      event: EVENT_UPDATE_COLUMN,
    },

    props: {
      column: Object,
    },

    data() {
      return {
        pColumn: this.column,

        isSelectAlwaysVisible: false,

        hasPermissionToEdit: AuhService.userData().role === 'admin',
      };
    },

    watch: {
      column(column) {
        this.pColumn = column;
      },
    },

    methods: {
      setIsSelectAlwaysVisible(value) {
        this.isSelectAlwaysVisible = value;
      },

      columnUpdatedInModal(column) {
        this.pColumn = column;
        this.$emit(EVENT_UPDATE_COLUMN, column);
      },
      columnCreatedInModal(column) {
        this.pColumn = column;
        this.$emit(EVENT_UPDATE_COLUMN, column);
      },

      openColumnCreate() {
        this.$modal.show('column-create');
      },
      openColumnDetail(e) {
        if (e.target.className.startsWith('multiselect')) return; // Filter autosuggest's clicks
        if (!this.pColumn) this.openColumnCreate();
        else this.$modal.show('column-detail');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/css/base/card';

  .column-card {
    &:hover,
    &:focus {
      .column-card__select {
        display: inherit;
      }

      .column-card__name {
        display: none;
      }

      .column-card__buttons {
        visibility: visible;
      }
    }

    &__top {
      display: flex;
      justify-content: flex-end;

      @media only screen and (max-width: 700px) {
        flex-wrap: wrap;
      }
    }

    &__label {
      font-size: 17px;
      font-weight: bold;
      margin-right: 16px;
      margin-top: 4px;
      margin-bottom: 4px;
      white-space: nowrap;
      flex: 0 0;
    }

    &__select {
      vertical-align: top;
      flex: 1 0;
      min-width: 200px;

      &:not(&--visible) {
        @include isHoverDevice {
          display: none;
        }
      }
    }

    &__name {
      margin-top: 4px;
      margin-bottom: 4px;
      display: flex;
      font-size: 17px;
      font-weight: bold;
      flex: 1 0;
      min-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $color-text-primary;

      @include isHoverDevice(false) {
        display: none;
      }

      &--hidden {
        display: none;
      }

      &:hover {
        text-decoration: underline;
        color: $color-text-primary--hover;
      }
    }

    &__buttons {
      flex: 0 0;

      @include isHoverDevice {
        visibility: hidden;
      }

      @media only screen and (max-width: 700px) {
        visibility: visible;
      }
    }
  }
</style>
