<template>
  <div class="measurements">
    <template v-if="canSelectMultiple">
      <Checkbox
        v-for="measurement of measurements"
        :key="measurement.id"
        v-model="localMeasurementsSelectedCheckbox[measurement.id]"
        :tooltip="measurement.meta ? getName(measurement) : null"
        :isDisabled="
          localMeasurementsSelectedCheckbox[measurement.id] && isSelectedOnlyOneMeasurement
        "
        :selectedColor="COLORS.measurements[(measurement.meta && measurement.meta.nm) || 'default']"
      >
        <template v-if="measurement.meta && measurement.meta.nm">
          <span>{{ measurement.meta.nm }}nm</span>
        </template>
        <template v-else>
          {{ getName(measurement) }}
        </template>
      </Checkbox>
    </template>
    <template v-else>
      <Radio
        v-for="measurement of measurements"
        :key="measurement.id"
        v-model="localMeasurementSelectedRadio"
        :name="name"
        :value="measurement.id"
      >
        {{ getName(measurement) }}
      </Radio>
    </template>
  </div>
</template>

<script>
  import Radio from '@/uikitProject/injections/measurements/switchers/Radio';
  import Checkbox from '@/uikitProject/injections/measurements/switchers/Checkbox';
  import { COLORS } from '@/uikitProject/charts/new/private/constants';

  const EVENT_MODEL = 'model';

  export default {
    name: 'Measurements',

    components: {
      Checkbox,
      Radio,
    },

    model: {
      prop: 'measurementsSelected',
      event: EVENT_MODEL,
    },

    props: {
      measurements: {
        type: Object,
        required: true,
      },
      measurementsSelected: {
        type: Array,
        required: true,
      },
      name: {
        type: String,
        default: 'measurement',
      },
      /**
       * Single select feature is not used now.
       */
      canSelectMultiple: {
        type: Boolean,
        required: true,
      },
    },

    data: () => ({
      _isModeMultipleChoice: false,

      localMeasurementsSelectedCheckbox: {},

      COLORS,
    }),

    computed: {
      measurementsList() {
        return Object.values(this.measurements);
      },

      localMeasurementSelectedRadio: {
        get() {
          return this.measurementsSelected[0] ?? null;
        },
        set(value) {
          this.$emit(EVENT_MODEL, [value]);
        },
      },

      isSelectedOnlyOneMeasurement() {
        return Object.values(this.localMeasurementsSelectedCheckbox).filter(Boolean).length === 1;
      },
    },

    watch: {
      measurements: {
        handler() {
          this.initCheckboxModel();
        },
        immediate: true,
      },
      measurementsSelected(measurements) {
        if (measurements.length) {
          const wasUpdated = Object.entries(this.localMeasurementsSelectedCheckbox).every(
            ([measurementId, value]) => measurements.includes(measurementId) === value,
          );

          if (wasUpdated) {
            this.initCheckboxModel();
          }
        }
      },
      localMeasurementsSelectedCheckbox: {
        handler(measurementsSelected) {
          const ids = this.parseCheckboxValuesToIds(measurementsSelected);
          this.$emit(EVENT_MODEL, ids);
        },
        deep: true,
      },
      canSelectMultiple(value) {
        if (value) {
          this.selectAllCheckboxes();
        } else {
          const { id } = this.measurementsList[0];
          this.$emit(EVENT_MODEL, [id]);
        }
      },
    },

    methods: {
      selectAllCheckboxes() {
        this.localMeasurementsSelectedCheckbox = Object.entries(
          this.localMeasurementsSelectedCheckbox,
        ).reduce((acc, [key]) => {
          acc[key] = true;
          return acc;
        }, {});
      },

      parseCheckboxValuesToIds(checkboxData) {
        return Object.entries(checkboxData)
          .filter(([, value]) => Boolean(value))
          .map(([key]) => Number(key));
      },

      getName(measurement) {
        if (measurement.name) return measurement.name;

        switch (measurement.type) {
          case 'AD':
            return 'Analog signal';
          case 'AD2':
            return 'Analog signal2';
          default:
            return measurement.type;
        }
      },

      initCheckboxModel() {
        this.localMeasurementsSelectedCheckbox = this.measurementsList.reduce(
          (acc, measurement) => {
            acc[measurement.id] = Boolean(this.measurementsSelected.includes(measurement.id));
            return acc;
          },
          {},
        );
      },

      setMeasurementsSelected(isChecked, measurementId) {
        if (
          !isChecked &&
          Object.values(this.localMeasurementsSelectedCheckbox).filter(Boolean).length <= 1
        ) {
          return;
        }

        this.localMeasurementsSelectedCheckbox = {
          ...this.localMeasurementsSelectedCheckbox,
          [measurementId]: isChecked,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .measurements {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;

    &__title {
      margin-right: 16px;
    }

    &__checkbox {
      margin-left: 20px;
    }
  }
</style>
