import {
  startOfToday,
  endOfToday,
  startOfYesterday,
  endOfYesterday,
  subWeeks,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  endOfWeek,
  startOfWeek,
} from 'date-fns';

const today = new Date();
const lastWeek = subWeeks(today, 1);
const lastYear = subMonths(today, 1);

export const DATE_RANGE_LOCALE = {
  direction: 'ltr', // direction of text
  format: 'dd MMM yyyy', // format of the dates displayed
  separator: ' - ', // separator between the two ranges
  applyLabel: 'Apply',
  cancelLabel: 'Cancel',
  weekLabel: 'W',
  customRangeLabel: 'Custom Range',
  daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  firstDay: 1,
  showWeekNumbers: false, // show week numbers on each row of the calendar
  autoApply: true,
};

export const DATE_RANGE_RANGES = {
  // default value for ranges object (if you set this to false ranges will no be rendered)
  Today: [startOfToday(), endOfToday()],
  Yesterday: [startOfYesterday(), endOfYesterday()],
  'This month': [startOfMonth(today), endOfMonth(today)],
  'This year': [startOfYear(today), endOfYear(today)],
  'Last week': [startOfWeek(lastWeek), endOfWeek(lastWeek)],
  'Last month': [startOfMonth(lastYear), endOfMonth(lastYear)],
};
