<template>
  <div class="qr-reader">
    <p v-if="errorMessage" class="error">
      {{ errorMessage }}
    </p>

    <QrcodeStream
      v-else-if="isStreamApiSupport"
      :camera="camera"
      :class="classMirror"
      @init="onInit"
      @decode="onDecode"
    >
      <div class="qr-reader__stream-content">
        <button
          v-if="isMobileDevice && !isLoading"
          class="qr-reader__btn-switch"
          @click="switchCamera"
        >
          <IconMaterial title="flip_camera_ios" />
        </button>
        <div v-if="isLoading">
          <LoadingComponent />
        </div>
      </div>
    </QrcodeStream>

    <QrcodeCapture v-else @decode="onDecode" />
  </div>
</template>

<script>
  import NavigatorHelper from 'utils/NavigatorHelper.ts';
  import { QrcodeStream, QrcodeCapture } from 'vue-qrcode-reader';
  import LoadingComponent from 'components/element/LoadingComponent';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  const EVENT_DATA = 'data';
  const EVENT_INIT = 'init';

  const IPAD_WIDTH = 1024;
  const isMobileSizeScreen = window.innerWidth <= IPAD_WIDTH;

  const isMobileDevice = NavigatorHelper.isMobile || isMobileSizeScreen;

  const getErrorMessage = (errorName, activeCamera) => {
    switch (errorName) {
      case 'NotAllowedError':
        return 'No permission to use your camera';
      case 'NotFoundError':
        return 'No suitable camera device installed';
      case 'InsecureContextError':
        return 'Use HTTPS or localhost rather than HTTP';
      case 'NotSupportedError':
        return 'Page is not served over HTTPS (or localhost)';
      case 'NotReadableError':
        return 'Maybe camera is already in use';
      case 'OverconstrainedError': {
        const isActiveRearCamera = activeCamera === 'rear';
        if (isActiveRearCamera) {
          return `You don't seem to have a rear camera on your device`;
        }
        const isActiveFrontCamera = activeCamera === 'front';
        if (isActiveFrontCamera) {
          return `You don't seem to have a front camera on your device`;
        }
        return 'Did you requested the front camera although there is none?';
      }
      default:
        return `Unknown error`;
    }
  };

  export default {
    name: 'QRReader',

    components: {
      IconMaterial,
      LoadingComponent,
      QrcodeStream,
      QrcodeCapture,
    },

    data: () => ({
      camera: isMobileDevice ? 'rear' : 'auto',
      errorMessage: null,
      isStreamApiSupport: true,
      isMobileDevice,
      isLoading: false,
      data: null,
    }),

    computed: {
      classMirror() {
        return this.camera !== 'rear' && 'qr-reader__stream--mirror';
      },
    },

    methods: {
      onDecode(data) {
        // The data may be an empty string because the plugin may react to the incorrect qr code
        data && this.$emit(EVENT_DATA, data);
      },

      async switchCamera() {
        this.camera = this.camera === 'front' ? 'rear' : 'front';
        await this.$nextTick();
        this.$emit(EVENT_INIT);
      },

      async onInit(promise) {
        this.isLoading = true;

        try {
          await promise;
        } catch (error) {
          const isOldBrowser = error.name === 'StreamApiNotSupportedError';
          if (isOldBrowser) {
            this.isStreamApiSupport = false;
            return;
          }

          this.errorMessage = getErrorMessage(error.name, this.camera);
        } finally {
          this.isLoading = false;
          await this.$nextTick();
          this.$emit(EVENT_INIT);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .qr-reader {
    &__stream {
      &--mirror {
        transform: scaleX(-1);
      }
    }

    &__stream--mirror &__stream-content {
      transform: scaleX(-1);
    }

    &__btn-switch {
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 9000;
      background-color: $color-bg-third;
      transition: background-color 0.3s;
      font-size: 24px;
      display: flex;
      align-items: center;
      padding: 5px;

      &:hover {
        background-color: $color-bg-second;
      }
    }
  }

  .error {
    color: red;
    font-weight: bold;
  }
</style>

<style lang="scss">
  // Fix bug in iOS safari: Switch button was not visible
  .qrcode-stream-camera {
    position: relative;
    z-index: -1;
  }
</style>
