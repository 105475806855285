<template>
  <div data-test-id="btn-show-device-form" class="btn-show-device-form" v-on="$listeners">
    + Add device
  </div>
</template>

<script>
  export default {
    name: 'PrBtnShowDeviceForm',
  };
</script>

<style lang="scss" scoped>
  .btn-show-device-form {
    border: 2px dashed rgba(0, 0, 0, 0.13333);
    border-radius: 2px;
    padding: 16px 32px;
    width: 100%;
    color: rgba(0, 0, 0, 0.26667);
    background-color: transparent;
    text-align: left;
    cursor: pointer;

    &:hover {
      border-color: $color-bg-primary;
      color: $color-text-primary;
    }
  }
</style>
