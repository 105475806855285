import { makeGraphqlRequestCloud } from 'api/graphql/graphQLClient';
import { InjectionsShort } from '@/graphql/cloud/injections/queries/InjectionsShort.graphql';

export const apiInjections = {
  async getInjections(sampleId) {
    const { injections } = await makeGraphqlRequestCloud(InjectionsShort, {
      sampleId,
    });

    return {
      injections,
    };
  },
};
