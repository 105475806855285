const getErf = (_x: number): number => {
  const sign = _x < 0 ? -1 : 1;
  const x = Math.abs(_x);

  const a1 = 0.254829592;
  const a2 = -0.284496736;
  const a3 = 1.421413741;
  const a4 = -1.453152027;
  const a5 = 1.061405429;
  const p = 0.3275911;

  const t = 1 / (1 + p * x);
  const y = ((((a5 * t + a4) * t + a3) * t + a2) * t + a1) * t;

  return sign * (1 - y * Math.exp(-x * x));
};

const getGaussian = (x: number, amplitude = 1, center = 0, sigma = 1): number => {
  const s2pi = Math.sqrt(2 * Math.PI);
  const tiny = Number.EPSILON;
  return (
    (amplitude / Math.max(tiny, s2pi * sigma)) *
    Math.exp(-Math.pow(x - center, 2) / Math.max(tiny, 2 * sigma * sigma))
  );
};

export type GaussianParams = {
  amplitude?: number;
  center?: number;
  sigma?: number;
  gamma?: number;
};

const getSkewedGaussian = (x, params: GaussianParams): number => {
  const { amplitude = 1, center = 0, sigma = 1, gamma = 0 } = params;
  const tiny = Number.EPSILON;
  const s2 = Math.sqrt(2);
  const asym = 1 + getErf((gamma * (x - center)) / Math.max(tiny, s2 * sigma));
  return asym * getGaussian(x, amplitude, center, sigma);
};

export const generateRange = (start: number, end: number, step: number): number[] => {
  const arr: number[] = [];
  for (let i = start; i <= end; i += step) {
    arr.push(i);
  }
  return arr;
};

export const generateSkewedGaussianRange = (range: number[], params: GaussianParams): number[] =>
  range.map((x) => getSkewedGaussian(x, params));
