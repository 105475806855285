import { REST } from 'utils/rest';
import RequestError from '@/errors/RequestError.ts';

export default class InjectionAPI {
  static getMinWithParents(id, onSuccess, onError) {
    const url = `/api/injections/${id}:min-with-parents/`;
    return REST.get(url, onSuccess, onError);
  }

  static sendReport(
    { sampleId, injectionId, email, message, report_data, report_type },
    onSuccess,
    onError,
  ) {
    const url = `/api/samples/${sampleId}/injections/${injectionId}:emailpdf`;
    return REST.post(url, { email, message, report_data, report_type }, onSuccess, onError);
  }

  static async downloadReport(data) {
    const url = `/report/sample-injection/`;
    const response = await fetch(url, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'text/plain',
      },
    });

    if (response.ok) {
      return response.blob();
    }

    throw new RequestError();
  }
}
