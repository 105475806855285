<template>
  <DecoratorState
    :hasTitleOnAdaptive="hasTitleOnAdaptive"
    title="Failed"
    :hasTitle="hasTitle"
    class="state-failed"
  >
    <template #icon>
      <IconMaterial title="info" size="16" />
    </template>
  </DecoratorState>
</template>

<script>
  import DecoratorState from '@/uikitProject/decorators/DecoratorState';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  export default {
    name: 'StateFailed',

    components: {
      DecoratorState,
      IconMaterial,
    },

    props: {
      hasTitle: {
        type: Boolean,
        default: true,
      },
      hasTitleOnAdaptive: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .state-failed {
    color: $color-text-danger;
  }
</style>
