<template>
  <div ref="inputAutosuggest" class="input-autosuggest">
    <Tippy
      ref="tippy"
      :isDisabled="
        isDisabled ||
        (this.isShowAllSuggestionsOnFocus && !this.isPopupOpened && !this.itemsFiltered.length)
      "
      trigger="focus"
      :triggerTarget="triggerTarget"
      :boundary="triggerTarget"
      :isInteractive="true"
      placement="bottom-start"
      :duration="[67, 0]"
      distance="0"
      :popperOptions="popperOptions"
      :arrow="false"
      :flip="true"
      :theme="this.itemsFiltered.length ? 'autosuggest' : 'autosuggest--no-elements'"
      :isHideOnClickInside="false"
      :isHideOnClickOutside="false"
      class="input-autosuggest__tippy"
      @show="handleShowPopup"
      @hide="handleHidePopup"
    >
      <template #trigger>
        <slot
          name="input"
          :value="localValue"
          :tempValue="filterValue"
          :setValue="setValue"
          :setFilterValue="setFilterValue"
          :handleInputFocus="handleInputFocus"
          :handleInputBlur="handleInputBlur"
          :isDisabled="isDisabled"
          :isError="isError"
          :placeholder="placeholder"
          v-bind="$attrs"
        >
          <!--IMPORTANT: slot can be only native html element-->
          <div class="input-autosuggest__wrapper-input" :class="classes">
            <input
              v-model="localValue"
              type="text"
              :placeholder="placeholder"
              v-bind="$attrs"
              class="input-autosuggest__input"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
            />
          </div>
        </slot>
      </template>

      <!--For custom dropdowns-->
      <template #default="{ isShow, triggerWidth, triggerOffsetLeft, hide }">
        <GlobalEvents v-if="isShow" @keydown.esc="hide" />

        <div
          ref="popup"
          :style="getPopupStyles(triggerWidth, triggerOffsetLeft)"
          class="input-autosuggest__dropdown"
        >
          <slot
            :previewItem="previewItem"
            :confirm="confirmItem"
            :itemsFiltered="itemsFiltered"
            :labelProp="labelProp"
            :isActive="isShow"
          >
            <component
              :is="dropdownComponent"
              :items="itemsFiltered"
              :labelProp="labelProp"
              :scrollContainer="$refs.popup"
              :isActive="isShow"
              @preview="previewItem"
              @confirm="confirmItem"
            />
          </slot>
        </div>
      </template>
    </Tippy>
  </div>
</template>

<script>
  import List from '../../../lists/List';
  import DomHelper from 'utils/DomHelper.ts';
  import NavigatorHelper from 'utils/NavigatorHelper.ts';
  import Tippy from '@/uikitBase/popups/vueTippy/Tippy';
  import { model, props } from '../shared/config';

  const EVENT_MODEL = 'model';
  const EVENT_INIT = 'init';
  const EVENT_FOCUS = 'focus';
  const EVENT_BLUR = 'blur';
  const EVENT_MOUNTED = 'mounted';

  export default {
    name: 'DesktopInputAutosuggest',

    components: {
      Tippy,
      List,
    },

    inheritAttrs: false,

    model,

    props,

    data() {
      return {
        triggerTarget: null,

        previewValue: null,
        filterValue: null,

        isActivated: false,
        isPopupOpened: false,
        isFocused: false,

        isJustFocused: false,

        popperOptions: {
          modifiers: {
            preventOverflow: {
              boundariesElement: 'window',
              enabled: true,
            },
            hide: {
              enabled: true,
              /**
               * Fires when the reference element is out of boundaries, useful to know when to hide the popper
               */
              fn: (e) => {
                if (
                  // isPopupOpened becomes true with little delay for opening a keyboard on touch devices
                  this.isPopupOpened &&
                  !DomHelper.isElementInsideViewport(e.instance.reference)
                ) {
                  this.refInput.blur();
                }
                return e;
              },
            },
          },
        },
      };
    },

    computed: {
      classPadding() {
        return `input-autosuggest__wrapper-input--padding--${this.padding}`;
      },
      classDisabled() {
        return this.isDisabled && 'input-autosuggest__wrapper-input--disabled';
      },
      classes() {
        return [this.classPadding, this.classDisabled];
      },

      dropdownComponent() {
        switch (this.dropdownType) {
          default:
            return List;
        }
      },

      /***
       * Convert items to Array of objects
       */
      itemsParsed() {
        if (this.items.length) {
          const [item] = this.items;

          if (typeof item !== 'object') {
            return this.items.map((item, index) => ({ name: String(item), id: index }));
          }
        }

        return this.items;
      },
      itemsFiltered() {
        if (this.isShowAllSuggestionsOnFocus && this.isJustFocused) {
          return this.itemsParsed;
        }

        if (!this.isShowAllSuggestionsIfEmpty && (!this.value || !this.filterValue)) {
          return [];
        }

        const _filterValue = this.filterValue || this.value;

        return this.itemsParsed.filter(
          (item) =>
            item[this.labelProp] !== _filterValue &&
            item[this.labelProp].toLowerCase().includes(_filterValue.toLowerCase()),
        );
      },

      localValue: {
        get() {
          return this.previewValue ?? this.value;
        },
        set(value) {
          this.$emit(EVENT_MODEL, value);
        },
      },

      refInput() {
        const wrapper = this.$refs.inputAutosuggest;
        const input = wrapper.querySelector('input');
        if (input) {
          return input;
        }
        throw new Error('The default slot must contain an input element');
      },
    },

    watch: {
      value(value) {
        if (value !== this.previewValue && this.isFocused) {
          this.$refs.tippy.show();
        }

        this.previewValue = null;
      },
      items(items) {
        if (items.length && this.isFocused) {
          this.$refs.tippy.show();
        }
      },

      async isJustFocused(value) {
        if (this.isShowAllSuggestionsOnFocus) {
          if (value) {
            await this.$nextTick();
            this.$refs.tippy.show();
          } else if (!this.isFocused) {
            this.$refs.tippy.hide();
          }
        }
      },
    },

    mounted() {
      this.initTriggerTarget();
      this.$emit(EVENT_MOUNTED);
    },

    methods: {
      initTriggerTarget() {
        this.triggerTarget = this.refInput;
      },
      activate() {
        this.isActivated = true;
      },

      getPopupStyles(triggerWidth) {
        return {
          minWidth: `${window.innerWidth < triggerWidth ? window.outerWidth - 30 : triggerWidth}px`,
          ...this.dropdownStyles,
        };
      },

      previewItem(item) {
        this.previewValue = item[this.labelProp];
      },
      confirmItem(item) {
        this.$refs.tippy.hide();

        this.$emit(EVENT_MODEL, item[this.labelProp]);
      },

      handleShowPopup() {
        // little delay for opening a keyboard on touch devices
        if (NavigatorHelper.isTouchDevice) {
          setTimeout(() => {
            this.isPopupOpened = true;
          }, 500);
        } else {
          this.isPopupOpened = true;
        }
      },
      handleHidePopup() {
        this.isPopupOpened = false;
        this.isJustFocused = false;

        if (this.previewValue) {
          this.setValue(this.previewValue);
        }
      },

      handleInputFocus() {
        if (!this.isActivated) {
          this.activate();
          this.$emit(EVENT_INIT);
        }

        this.isFocused = true;
        this.isJustFocused = true;

        this.$emit(EVENT_FOCUS);
      },
      handleInputBlur() {
        this.isFocused = false;

        this.$emit(EVENT_BLUR);
      },

      setValue(value) {
        this.isJustFocused = false;
        this.setFilterValue(null);

        this.$emit(EVENT_MODEL, value);
      },
      setFilterValue(value) {
        this.isJustFocused = false;

        this.filterValue = value;
      },

      focus() {
        this.refInput?.focus();
      },
      select() {
        this.refInput?.select();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .input-autosuggest {
    width: 100%;

    &__tippy {
      width: 100%;
    }

    &__label {
      overflow: hidden;

      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__dropdown {
      max-height: 180px;
      overflow-y: auto;
    }

    &__wrapper-input {
      position: relative;

      display: flex;
      align-items: center;
      height: 32px;
      width: 100%;

      color: inherit;

      background-color: transparent;
      cursor: pointer;

      transition: filter 1s;

      user-select: none;

      &--disabled {
        cursor: not-allowed;

        user-select: none;

        &:hover {
          background-color: transparent;
        }
      }

      &--padding {
        &--none {
          padding: 0;
        }

        &--xs {
          padding: 0 4px;
        }
      }
    }

    &__input {
      min-width: 100px;
      width: 100%;
      background: transparent;
      text-indent: 0;

      &:focus {
        background: transparent;
      }
    }
  }
</style>
