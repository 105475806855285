import Plotly, { PlotlyHTMLElement } from '@newcrom/plotly.js';
import { Callback, Nullable } from '@/types/utility';
import { generateId } from '@/utils/generateId';
import { convertCoordsToPath } from '@/utils/svgHelpers';
import { throwError } from '@/utils/errorHelpers';
import mitt, { Emitter, Handler } from 'mitt';
import {
  ChartEventListeners,
  Config,
  Coords,
  DataExtended,
  DetectionTime,
  Layout,
  Measurement,
  Peak,
  Shape,
  Sizes,
  ZoomBounds,
} from '@/uikitProject/charts/new/private/types';
import {
  AXIS_HOVER_FORMAT,
  COLORS,
  CONFIG,
  DATA_HOVER_TEMPLATE_FOR_CHROMATOGRAM,
  MESSAGES,
  SECONDS_IN_MINUTE,
  SHAPE_IDS,
  SIZES,
} from '@/uikitProject/charts/new/private/constants';
import {
  addStyleSheetForPeakHighlighting,
  createMessageOverlay,
  createZoomActivationOverlay,
  findYBetweenTwoPoints,
  getFilteredData,
  getHoverMessages,
  getMeasurementPart,
  getStartAndEndXValues,
  hasSamePeaks,
  trimDataIfOutOfDetection,
} from '@/uikitProject/charts/new/private/helpers';
import _ from 'lodash';
import { sendDebugEvent } from '@/utils/sentryHelpers';
import { generateRange, generateSkewedGaussianRange } from '@/utils/gaussianHelpers';
import { PEAK_ESTIMATED_SHAPES } from '@/constants/peaks/estimatedShapes';

type Events = {
  deletePeaks: number[];
  addPeak: {
    startMau: number;
    endMau: number;
    startMinutes: number;
    endMinutes: number;
  };
  baseline: [time: number, mau: number][];
  zoom: Nullable<ZoomBounds>;
  hasInvisiblePeak: boolean;
  highlightPeak: Nullable<number>;
  zoomActivation: void;
};

export enum Modes {
  DEFAULT,
  ADD_PEAK,
  REMOVE_PEAK,
  BASELINE,
}

type InitialOptions = Readonly<{
  container: HTMLElement;
  measurements: Measurement[];
  expectedDurationSeconds: number;
  detectionTime: DetectionTime;
  isApplyDetectionTime: boolean;
  isInProgress: boolean;
  isFetchingData: boolean;
  isShowBaseline: boolean;
  isShowPeaks: boolean;
  widthPx?: Nullable<number>;
  hasTooltipOnHover: boolean;
  hasGridLines: boolean;
  zoomBounds: Nullable<ZoomBounds>;
  sizes?: Sizes;
  hasZoomActivationOverlay: boolean;
  creationData: Date;
}>;

type RenderOptions = {
  highlightedPeakIds?: Set<number>;
  // this.chart.layout.shapes can be undefined. There is the error in Plotly typings
  shapes: Nullable<Shape[]>;
  hasZoom: boolean;
  isShowBaseline: boolean;
  isShowPeaks: boolean;
  isApplyDetectionTime: boolean;
  isInProgress: boolean;
  isFetchingData: boolean;
  detectionTime: DetectionTime;
  expectedDurationSeconds: number;
  widthPx: Nullable<number>;
  isShowEstimatedGaussians: boolean;
  hasTooltipOnHover: boolean;
  hasGridLines: boolean;
  overlayMessage: Nullable<string>;
};

type Actions = {
  resetBaseline: Nullable<Callback>;
  removeLastBaselineSegment: Nullable<Callback>;
  setIsShowBaseline: Callback<[isShowBaseline: boolean]>;
  setIsShowPeaks: Callback<[isShowPeaks: boolean]>;
  setPeakHighlighting: Callback<[peakId: number, isHighlighted: boolean]>;
  setIsApplyDetectionTime: Callback<[isApplyDetectionTime: boolean]>;
  setDetectionTime: Callback<[detectionTime: DetectionTime]>;
  setIsInProgress: Callback<[isInProgress: boolean]>;
  setIsFetchingData: Callback<[setIsFetchingData: boolean]>;
  setExpectedDurationSeconds: Callback<[expectedDurationSeconds: number]>;
  setWidth: Callback<[widthPx: number]>;
  setHasTooltipOnHover: Callback<[hasTooltipOnHover: boolean]>;
  setHasGridLines: Callback<[hasGridLines: boolean]>;
  setZoom: Callback<[bounds: ZoomBounds]>;
  setOverlayMessage: Callback<[message: Nullable<string>]>;
};

type DataForRender = {
  data: DataExtended[];
  layout: Layout;
  isInProgress: boolean;
  messageOverlay: Nullable<string>;
};

export class ChromatogramDrawer {
  private _chart: Nullable<PlotlyHTMLElement> = null;
  private mode: Modes = Modes.DEFAULT;
  private config: Nullable<Config> = CONFIG;
  private sizes: Sizes;
  private renderOptions: RenderOptions = {
    highlightedPeakIds: new Set(),
    shapes: [],
    hasZoom: true,
    isShowBaseline: false,
    isShowPeaks: false,
    isApplyDetectionTime: false,
    isInProgress: false,
    isFetchingData: false,
    detectionTime: null,
    expectedDurationSeconds: 0,
    widthPx: null,
    isShowEstimatedGaussians: true,
    hasTooltipOnHover: false,
    hasGridLines: false,
    overlayMessage: null,
  };
  private disableCurrentMode: Nullable<Callback> = null;
  private currentMeasurements: Measurement[];
  private currentIsApplyDetectionTime: boolean;
  private currentIsShowBaseline: boolean;

  private mediaQueryIsMobileSize: MediaQueryList;

  private readonly messageOverlay = createMessageOverlay();
  private zoomActivationOverlay: Nullable<ReturnType<typeof createZoomActivationOverlay>>;

  private emitter: Emitter<Events>;

  private resolveInitializationPromise: Nullable<Callback> = null;
  public readonly initializationPromise: Promise<unknown> = new Promise((resolve) => {
    this.resolveInitializationPromise = resolve;
  });

  private readonly creationDate: Date;

  /**
   * The only way to update measurements after initialization
   */
  public refresh: Callback<[measurements?: Measurement[]]> = (
    measurements = this.currentMeasurements,
  ) => {
    this.currentMeasurements = measurements;
    this.refreshWithDebounce(measurements);
  };

  /**
   * Debounced version of refreshImmediately function
   */
  private refreshWithDebounce: Callback<[measurements?: Measurement[]]> = _.debounce(
    this.refreshImmediately.bind(this),
    100,
  );

  // TODO make private and add public methods?
  public actions: Actions = {
    removeLastBaselineSegment: null,
    resetBaseline: null,
    setIsShowBaseline: (isShowBaseline: boolean) => {
      this.renderOptions.isShowBaseline = isShowBaseline;
      this.refresh();
    },
    setIsShowPeaks: (isShowPeaks: boolean) => {
      this.renderOptions.isShowPeaks = isShowPeaks;
      this.refresh();
    },
    setPeakHighlighting: (peakId: number, isHighlighted: boolean) => {
      isHighlighted
        ? this.renderOptions.highlightedPeakIds?.add(peakId)
        : this.renderOptions.highlightedPeakIds?.delete(peakId);
      this.refresh();
    },
    setIsApplyDetectionTime: (isApplyDetectionTime: boolean) => {
      this.renderOptions.isApplyDetectionTime = isApplyDetectionTime;
      this.refresh();
    },
    setDetectionTime: (detectionTime: DetectionTime) => {
      this.renderOptions.detectionTime = detectionTime;
      this.refresh();
    },
    setIsInProgress: (isInProgress: boolean) => {
      this.renderOptions.isInProgress = isInProgress;
      this.refresh();
    },
    setIsFetchingData: (isFetchingData: boolean) => {
      this.renderOptions.isFetchingData = isFetchingData;
      this.refresh();
    },
    setExpectedDurationSeconds: (expectedDurationSeconds: number) => {
      this.renderOptions.expectedDurationSeconds = expectedDurationSeconds;
      this.refresh();
    },
    setWidth: (widthPx: number) => {
      this.renderOptions.widthPx = widthPx;
      this.refresh();
    },
    setHasTooltipOnHover: (hasTooltipOnHover: boolean) => {
      this.renderOptions.hasTooltipOnHover = hasTooltipOnHover;
      this.refresh();
    },
    setHasGridLines: (hasGridLines: boolean) => {
      this.renderOptions.hasGridLines = hasGridLines;
      this.refresh();
    },
    setZoom: (bounds: ZoomBounds) => {
      this.zoom(bounds);
    },
    setOverlayMessage: (message: Nullable<string>) => {
      this.renderOptions.overlayMessage = message;
      this.refresh();
    },
  };

  constructor(initialOptions: InitialOptions) {
    this.currentMeasurements = initialOptions.measurements;
    this.renderOptions.isInProgress = initialOptions.isInProgress;
    this.renderOptions.isFetchingData = initialOptions.isFetchingData;
    this.renderOptions.detectionTime = initialOptions.detectionTime;
    this.renderOptions.isApplyDetectionTime = initialOptions.isApplyDetectionTime;
    this.renderOptions.expectedDurationSeconds = initialOptions.expectedDurationSeconds;
    this.renderOptions.isShowBaseline = initialOptions.isShowBaseline;
    this.renderOptions.isShowPeaks = initialOptions.isShowPeaks;
    this.renderOptions.widthPx = initialOptions.widthPx;
    this.renderOptions.hasTooltipOnHover = initialOptions.hasTooltipOnHover;
    this.renderOptions.hasGridLines = initialOptions.hasGridLines;
    this.currentIsApplyDetectionTime = this.renderOptions.isApplyDetectionTime;
    this.currentIsShowBaseline = this.renderOptions.isShowBaseline;
    this.sizes = initialOptions.sizes ?? SIZES;
    this.emitter = mitt<Events>();
    this.mediaQueryIsMobileSize = window.matchMedia(
      `(max-width: ${this.sizes.mobileScreenSizePx}px)`,
    );
    this.creationDate = initialOptions.creationData;

    this.initPlotly(initialOptions);
  }

  public on<Key extends keyof Events>(type: Key, handler: Handler<Events[Key]>) {
    this.emitter.on(type, handler);
  }

  private async initPlotly({
    container,
    measurements,
    zoomBounds,
    hasZoomActivationOverlay,
  }: InitialOptions) {
    container.style.position = 'relative';

    const { data, layout, isInProgress, messageOverlay: message } = await this.getDataForRender(
      measurements,
    );

    if (hasZoomActivationOverlay) {
      this.zoomActivationOverlay = createZoomActivationOverlay();
      this.zoomActivationOverlay.onActive = () => {
        container.style.touchAction = 'none';
        this.emitter.emit('zoomActivation');
      };
      this.zoomActivationOverlay.onDisable = () => {
        container.style.touchAction = 'none';
        this.emitter.emit('zoomActivation');
      };

      container.append(this.zoomActivationOverlay.element);
    } else {
      container.style.touchAction = 'none';
    }

    // messageOverlay should be after zoomActivationOverlay
    this.messageOverlay.edit(message, isInProgress);
    container.append(this.messageOverlay.element);

    addStyleSheetForPeakHighlighting();

    this.chart = await Plotly.newPlot(container, data, layout, this.config ?? undefined);

    if (zoomBounds) {
      this.zoom(zoomBounds);
    }

    this.chart.on('plotly_relayout', () => {
      const { xaxis, yaxis } = this.chart.layout;

      /**
       * I use the autorange property to define a scale reset for yaxis.
       * autorange for xaxis is always false due to more complex logic for detection time
       */
      this.emitter.emit('zoom', {
        x0: xaxis.range?.[0],
        x1: xaxis.range?.[1],
        y0: yaxis.autorange === true ? null : yaxis.range?.[0],
        y1: yaxis.autorange === true ? null : yaxis.range?.[1],
      });
    });

    // To use the same cursor for all cases and avoid blinking when drawing lines
    this.chart.style.cursor = 'crosshair';
    this.dragLayer.style.cursor = 'crosshair';

    this.mediaQueryIsMobileSize.onchange = () => {
      this.refresh();
    };

    // TODO use approach with a public initPlotly method?
    this.resolveInitializationPromise?.();
  }

  private get chart() {
    return this._chart ?? throwError('Chart was not initialized!');
  }
  private set chart(chartElement: PlotlyHTMLElement) {
    this._chart = chartElement;
  }

  private get layout() {
    return this.chart.layout;
  }

  private get dragLayer(): SVGElement {
    const dragElement =
      this.chart.querySelector(`.nsewdrag.drag`) ?? throwError('Drag element was not found!');

    if (dragElement instanceof SVGElement) {
      return dragElement;
    }

    throw new Error('Drag layer is not SVG element!');
  }

  private get data(): DataExtended[] {
    // TODO data is any in library typings. I can add a zod checking in the future
    const data: DataExtended[] = this.chart.data;
    return data.filter((item) => !item.isBaseline);
  }

  private get singleMeasurement(): DataExtended {
    if (this.data.length === 0) {
      throw new Error('There are no measurements!');
    }

    if (this.data.length === 1) {
      return this.data[0];
    }

    throw new Error('Chromatogram is in multiple mode!');
  }

  private get bounds(): ZoomBounds {
    const [minutesMin, minutesMax] = this.layout.xaxis.range ?? [];
    const [mauMin, mauMax] = this.layout.yaxis.range ?? [];

    if ([minutesMin, minutesMax, mauMin, mauMax].every((value) => typeof value === 'number')) {
      return {
        x0: minutesMin,
        x1: minutesMax,
        y0: mauMin,
        y1: mauMax,
      };
    }
    throw new Error('Bounds are not found!');
  }

  /**
   * Base method. A debounced version is preffered
   */
  private async refreshImmediately(measurements = this.currentMeasurements) {
    const { data, layout, isInProgress, messageOverlay: message } = await this.getDataForRender(
      measurements,
    );

    const isSingleMode = measurements.length === 1 && this.currentMeasurements.length === 1;
    const [newMeasurement] = measurements;
    const [currentMeasurement] = this.currentMeasurements;

    const _hasSamePeaks = isSingleMode && hasSamePeaks(newMeasurement, currentMeasurement);

    this.currentMeasurements = measurements;

    this.messageOverlay.edit(message, isInProgress);

    const yAutorangeFallback = this.chart._fullLayout.yaxis.autorange;
    const xAutorangeFallback = this.chart._fullLayout.xaxis.autorange;

    // Autorange must be true to always reset zoom
    if (this.renderOptions.isApplyDetectionTime !== this.currentIsApplyDetectionTime) {
      // this.chart._fullLayout.xaxis.autorange = true;
      this.chart._fullLayout.yaxis.autorange = true;
    }
    // Turn off autorange to keep zoom
    else if (this.renderOptions.isShowBaseline !== this.currentIsShowBaseline) {
      this.chart._fullLayout.yaxis.autorange = false;
      this.chart._fullLayout.xaxis.autorange = false;
    }

    Plotly.react(
      this.chart,
      data,
      layout,
      {
        ...this.config,
        doubleClick: this.renderOptions.hasZoom ? this.config?.doubleClick : false,
      },
      !_hasSamePeaks,
      layout.xaxis?.range != null ? [layout.xaxis.range[0], layout.xaxis.range[1]] : null,
    );

    // We need to get the zoom logic back after we intentionally changed it
    this.chart._fullLayout.yaxis.autorange = yAutorangeFallback;
    this.chart._fullLayout.xaxis.autorange = xAutorangeFallback;

    this.currentIsApplyDetectionTime = this.renderOptions.isApplyDetectionTime;
    this.currentIsShowBaseline = this.renderOptions.isShowBaseline;
  }

  public setMode(mode: Modes) {
    this.disableCurrentMode?.();

    this.mode = mode;

    switch (mode) {
      case Modes.DEFAULT:
        this.enableZoomMode();
        break;
      case Modes.ADD_PEAK:
        this.enableAddPeakMode();
        break;
      case Modes.REMOVE_PEAK:
        this.enableRemovePeakMode();
        break;
      case Modes.BASELINE:
        this.enableBaselineMode();
        break;
    }
  }

  private async getDataForRender(measurements: Measurement[]): Promise<DataForRender> {
    const {
      isApplyDetectionTime,
      isShowBaseline,
      isShowPeaks,
      detectionTime,
      expectedDurationSeconds,
      shapes: _shapes,
      hasZoom,
      isInProgress,
      isFetchingData,
      widthPx,
      hasTooltipOnHover,
      hasGridLines,
    } = this.renderOptions;
    const hasDetectionTime = detectionTime?.start != null || detectionTime?.end != null;

    const _isApplyDetectionTime = isApplyDetectionTime || isInProgress;

    const dataForDrawing: Array<DataExtended> = measurements.map((measurement) => {
      if (measurement.data == null) {
        // TODO Check error and remove
        sendDebugEvent('#2269: Front: Fix sentry issues', [
          ['All measurements', measurements],
          ['Current measurement', [measurement]],
          ['Task', 'https://gitlab.com/hplc-cloud-group/hplc-cloud-server/-/issues/2269'],
          [
            'Sentry issue',
            'https://newcrom.sentry.io/issues/4703701785/?project=6192768&query=is:unresolved&statsPeriod=90d&stream_index=0',
          ],
        ]);

        throw new Error('Data not found!');
      }

      const seconds = measurement.data.length / measurement.mps;
      const minutes = seconds / SECONDS_IN_MINUTE;
      const dx = minutes / measurement.data.length;

      const startIndex =
        detectionTime?.start != null
          ? detectionTime.start * SECONDS_IN_MINUTE * measurement.mps
          : 0;
      const endIndex =
        detectionTime?.end != null
          ? detectionTime.end * SECONDS_IN_MINUTE * measurement.mps + 1
          : measurement.data.length;

      const y = getFilteredData(
        hasDetectionTime && _isApplyDetectionTime
          ? trimDataIfOutOfDetection(measurement.data, startIndex, endIndex)
          : measurement.data,
        this.creationDate,
      );

      const x0 = hasDetectionTime && _isApplyDetectionTime ? startIndex * dx : 0;

      return {
        y,
        dx,
        x0,
        mps: measurement.mps,
        customdata: getHoverMessages(y, dx, x0, 'mAU'),
        hovertemplate: hasTooltipOnHover ? DATA_HOVER_TEMPLATE_FOR_CHROMATOGRAM : '',
        hoverinfo: hasTooltipOnHover ? 'all' : 'skip',
        mode: 'lines' as const,
        line: {
          color: COLORS.measurements[measurement.meta?.nm ?? 'default'],
        },
      };
    });

    const hasOnlyOneMeasurement = measurements.length === 1;
    // TODO add ts index access error
    const [firstMeasurement] = measurements;

    if (hasOnlyOneMeasurement && isShowBaseline) {
      const data = firstMeasurement.data;
      const baseline = await firstMeasurement.getBaseline();

      if (data.length !== 0 && baseline != null && baseline.length !== 0) {
        const seconds = data.length / firstMeasurement.mps;
        const minutes = seconds / SECONDS_IN_MINUTE;
        const dx = minutes / data.length;

        const startIndex =
          detectionTime?.start != null
            ? detectionTime.start * SECONDS_IN_MINUTE * firstMeasurement.mps
            : 0;
        const endIndex =
          detectionTime?.end != null
            ? detectionTime.end * SECONDS_IN_MINUTE * firstMeasurement.mps + 1
            : baseline.length;

        const y =
          hasDetectionTime && _isApplyDetectionTime
            ? trimDataIfOutOfDetection(baseline, startIndex, endIndex)
            : baseline;

        const x0 = hasDetectionTime && _isApplyDetectionTime ? startIndex * dx : 0;

        dataForDrawing.push({
          y,
          dx,
          x0,
          mps: firstMeasurement.mps,
          line: {
            dash: 'dash',
            color: COLORS.baseline,
          },
          customdata: getHoverMessages(y, dx, x0, 'mAU'),
          hovertemplate: hasTooltipOnHover ? DATA_HOVER_TEMPLATE_FOR_CHROMATOGRAM : '',
          hoverinfo: hasTooltipOnHover ? 'all' : 'skip',
          mode: 'lines' as const,
          isBaseline: true,
        });
      }
    }

    const shapes = [
      ...(_shapes != null ? _shapes : []),
      ...(isShowPeaks && hasOnlyOneMeasurement
        ? this.getPeakShapes({
            peaks: firstMeasurement.peaks ?? [],
            data: dataForDrawing[0],
          })
        : []),
    ];
    const hasMeasurementFromAnalogDetector = measurements.some(
      (measurement) => measurement.type === 'AD',
    );

    // TODO Make some fields not partial for Layout type?
    const layout: Layout = {
      showlegend: false,
      xaxis: {
        autorange: false,
        ticksuffix: ' min',
        showticksuffix: 'last',
        showgrid: hasGridLines,
        zeroline: hasGridLines,
        zerolinecolor: COLORS.zeroline,
        tickfont: {
          color: COLORS.axisLabel,
        },
        range: [
          // TODO +-0.00000001 is a little hack. Plotly can't detect a new range if we change 0 to 0. Try to fix the sources in the future
          _isApplyDetectionTime ? detectionTime?.start ?? -0.00000001 : 0,
          _isApplyDetectionTime
            ? (detectionTime?.end ?? expectedDurationSeconds / SECONDS_IN_MINUTE) + 0.00000001
            : expectedDurationSeconds / SECONDS_IN_MINUTE,
        ],
        // To save current zoom
        uirevision: 'time',
        hoverformat: AXIS_HOVER_FORMAT,
        fixedrange: !hasZoom,
        // rangemode: 'normal',
        ticks: 'inside',
        // @ts-ignore
        ticklabeloverflow: 'allow',
        ticklabelposition: 'inside top',
        tickcolor: COLORS.axisTick,
        showline: true,
      },
      yaxis: {
        ticksuffix: hasMeasurementFromAnalogDetector
          ? measurements.length === 1
            ? ' mV'
            : ''
          : ' mAU',
        showticksuffix: 'last',
        tickprefix: '',
        showtickprefix: 'all',
        showgrid: hasGridLines,
        zeroline: hasGridLines,
        zerolinecolor: COLORS.zeroline,
        ticks: 'outside',
        tickcolor: COLORS.axisTick,
        tickfont: {
          color: COLORS.axisLabel,
        },
        // @ts-ignore
        ticklabeloverflow: 'allow',
        ticklabelposition: 'inside top',
        // To save current zoom
        uirevision: 'time',
        fixedrange: !hasZoom,
        hoverformat: AXIS_HOVER_FORMAT,
        showline: true,
      },
      shapes,
      dragmode: hasZoom ? 'zoom' : false,
      margin: this.mediaQueryIsMobileSize.matches
        ? this.sizes.margins.mobile
        : this.sizes.margins.desktop,
      width: widthPx ?? undefined,
      height: this.sizes.height,
    };

    const hasMoreThan2Coords = dataForDrawing.some((d) => d.y.length > 2);

    const messageOverlay =
      this.renderOptions.overlayMessage ??
      (isFetchingData
        ? MESSAGES.loading
        : hasMoreThan2Coords
        ? null
        : isInProgress
        ? _isApplyDetectionTime && hasDetectionTime
          ? MESSAGES.waitingDetectionTime
          : MESSAGES.loading
        : _isApplyDetectionTime && hasDetectionTime
        ? MESSAGES.injectionFinishedBeforeDetectionTime
        : MESSAGES.noData);

    return {
      data: dataForDrawing,
      layout,
      isInProgress,
      messageOverlay,
    };
  }

  private disablePlotly() {
    this.renderOptions.hasZoom = false;
    this.refresh();
  }

  private enablePlotly() {
    this.renderOptions.hasZoom = true;
    this.refresh();
  }

  private getXY(e: PointerEvent): Coords {
    const dragLayerClientRect = this.dragLayer.getBoundingClientRect();

    const x = this.chart._fullLayout.xaxis.p2d(e.clientX - dragLayerClientRect.left);
    const y = this.chart._fullLayout.yaxis.p2d(e.clientY - dragLayerClientRect.top);

    return {
      x,
      y,
    };
  }

  private isOutOfChart(coords: Coords): boolean {
    return (
      coords.x < this.bounds.x0 ||
      coords.x > this.bounds.x1 ||
      coords.y < this.bounds.y0 ||
      coords.y > this.bounds.y1
    );
  }

  private addOrEditShape(shape: Shape) {
    // TODO check
    // this.chart.layout.shapes can be undefined. There is the error in Plotly typings
    if (this.renderOptions.shapes != null) {
      const shapeIndex = this.renderOptions.shapes.findIndex(
        (_shape) => _shape.name === shape.name,
      );

      if (shapeIndex !== -1) {
        this.renderOptions.shapes[shapeIndex] = shape;
      }
      this.renderOptions.shapes =
        shapeIndex !== -1 ? this.renderOptions.shapes : [...this.renderOptions.shapes, shape];
    } else {
      this.renderOptions.shapes = [shape];
    }
  }
  private removeShape(shapeName: string) {
    if (this.renderOptions.shapes != null) {
      this.renderOptions.shapes = this.renderOptions.shapes.filter(
        (shape) => shape.name !== shapeName,
      );
    }
  }

  private enableZoomMode() {
    this.enablePlotly();
  }

  private enableAddPeakMode() {
    this.disablePlotly();
    this.zoomActivationOverlay?.disable();

    let isMouseDown = false;
    let peakId = generateId();
    let startPosition: Nullable<Coords> = null;

    const onPointerMove = (e: PointerEvent) => {
      const { x, y } = this.getXY(e);

      if (!startPosition) {
        return;
      }

      const shape: Shape = {
        type: 'line',
        x0: startPosition?.x,
        x1: x,
        y0: startPosition?.y,
        y1: y,
        line: {
          color: COLORS.peakAddLine,
          width: 2,
        },
        name: peakId,
      };

      this.addOrEditShape(shape);
      this.refreshImmediately();
    };
    const onPointerDown = (e: PointerEvent) => {
      isMouseDown = true;
      e.preventDefault();
      startPosition = this.getXY(e);
      peakId = generateId();
    };
    const onPointerUp = (e: PointerEvent) => {
      if (isMouseDown && startPosition != null) {
        const upPosition = this.getXY(e);
        const start = startPosition.x < upPosition.x ? startPosition : upPosition;
        const end = startPosition.x < upPosition.x ? upPosition : startPosition;

        const [xStart, xEnd] = getStartAndEndXValues(this.singleMeasurement);
        if (xStart == null || xEnd == null) {
          throw new Error('Chart x axis is not found');
        }

        const isClickInsidePlot = xStart <= start.x && xEnd >= end.x;
        if (isClickInsidePlot) {
          this.emitter.emit('addPeak', {
            startMau: start.y,
            endMau: end.y,
            startMinutes: start.x,
            endMinutes: end.x,
          });
        } else {
          // TODO emit to show a notification
          // TODO add error codes?
          // throw new Error('One of the edge points of the peak is outside the measurement!');
        }

        isMouseDown = false;
        startPosition = null;
        this.removeShape(peakId);
        this.refreshImmediately();
      }
    };

    const removeListeners = this.addChartListeners({
      onPointerDown,
      onPointerMove,
      onPointerUp,
    });

    this.disableCurrentMode = () => {
      removeListeners();
    };
  }

  private enableRemovePeakMode() {
    this.disablePlotly();
    this.zoomActivationOverlay?.disable();

    let isMouseDown = false;
    let startPosition: Nullable<Coords> = null;

    const onPointerDown = (e: PointerEvent) => {
      e.preventDefault();
      isMouseDown = true;
      startPosition = this.getXY(e);
    };
    const onPointerMove = (e: PointerEvent) => {
      const { x } = this.getXY(e);

      if (!startPosition) {
        return;
      }

      const shape = {
        type: 'rect',
        x0: startPosition?.x,
        x1: x,
        y0: this.bounds.y0,
        y1: this.bounds.y1,
        fillcolor: COLORS.peakRemoveZone,
        line: {
          width: 0,
        },
        name: SHAPE_IDS.peakRemoveZone,
      } as const;

      const [measurement] = this.currentMeasurements;
      const xMin = Math.min(shape.x0, shape.x1);
      const xMax = Math.max(shape.x0, shape.x1);
      this.renderOptions.highlightedPeakIds?.clear();
      measurement.peaks
        .filter((peak) => {
          const isTimeStartInside = peak.start > xMin && peak.start < xMax;
          const isTimeEndInside = peak.end > xMin && peak.end < xMax;
          const isTimeBothInside = peak.start < xMin && peak.end > xMax;
          return isTimeStartInside || isTimeEndInside || isTimeBothInside;
        })
        .forEach((peak) => this.renderOptions.highlightedPeakIds?.add(peak.id));

      this.addOrEditShape(shape);
      this.refreshImmediately();
    };
    const onPointerUp = () => {
      if (isMouseDown) {
        isMouseDown = false;
        startPosition = null;
        this.removeShape(SHAPE_IDS.peakRemoveZone);

        this.renderOptions.highlightedPeakIds != null &&
          this.renderOptions.highlightedPeakIds?.size > 0 &&
          this.emitter.emit('deletePeaks', [...this.renderOptions.highlightedPeakIds]);
        this.renderOptions.highlightedPeakIds?.clear();

        this.refreshImmediately();
      }
    };

    const removeListeners = this.addChartListeners({
      onPointerDown,
      onPointerMove,
      onPointerUp,
    });

    this.disableCurrentMode = () => {
      removeListeners();
    };
  }

  private enableBaselineMode() {
    this.disablePlotly();
    this.zoomActivationOverlay?.disable();

    let baselineCoords: Array<[x: number, y: number]> = [];

    const getBaselineShape = (coords: Array<readonly [x: number, y: number]>) =>
      ({
        type: 'path',
        path: convertCoordsToPath(coords),
        line: {
          color: COLORS.baselineAdd,
          width: 2,
        },
        name: SHAPE_IDS.baseline,
      } as const);

    const refreshBaselinePreview = (e: PointerEvent) => {
      const { x, y } = this.getXY(e);
      const lastCoord = baselineCoords.at(-1);

      const isOutOfChart = this.isOutOfChart({ x, y });

      if (isOutOfChart && baselineCoords.length === 0) {
        this.removeShape(SHAPE_IDS.baseline);
      } else {
        const coords = [
          [this.bounds.x0, baselineCoords.length !== 0 ? baselineCoords[0][1] : y] as const,
          ...baselineCoords,
          ...(lastCoord != null && (x < lastCoord[0] || this.isOutOfChart({ x, y }))
            ? ([[this.bounds.x1, lastCoord[1]]] as const)
            : [[x, y] as const, [this.bounds.x1, y] as const]),
        ];

        const shape = getBaselineShape(coords);
        this.addOrEditShape(shape);
      }

      this.refreshImmediately();
    };

    const addCoordToBaseline = (e: PointerEvent) => {
      const { x, y } = this.getXY(e);
      const isOutOfChart = this.isOutOfChart({ x, y });
      const lastCoord = baselineCoords.at(-1);

      if (isOutOfChart || x < Number(lastCoord?.[0])) {
        return;
      }

      baselineCoords.push([x, y]);
      this.emitter.emit('baseline', baselineCoords);
    };

    this.actions.resetBaseline = () => {
      baselineCoords = [];
      this.removeShape(SHAPE_IDS.baseline);
      this.refreshImmediately();
    };
    this.actions.removeLastBaselineSegment = () => {
      baselineCoords.pop();

      if (baselineCoords.length === 0) {
        this.removeShape(SHAPE_IDS.baseline);
      } else {
        const firstCoord = baselineCoords.at(0);
        const lastCoord = baselineCoords.at(-1);
        if (firstCoord != null && lastCoord != null) {
          const coords = [
            [this.bounds.x0, firstCoord[1]] as const,
            ...baselineCoords,
            [this.bounds.x1, lastCoord[1]] as const,
          ];
          const shape = getBaselineShape(coords);
          this.addOrEditShape(shape);
        }
      }

      this.refreshImmediately();
    };

    const onKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Backspace': {
          this.actions.removeLastBaselineSegment?.();
          break;
        }
        case 'Escape': {
          this.actions.resetBaseline?.();
          break;
        }
      }
    };

    const onPointerDown = (e: PointerEvent) => {
      if (e.pointerType === 'mouse') {
        addCoordToBaseline(e);
      }
      if (e.pointerType === 'touch') {
        refreshBaselinePreview(e);
      }
    };
    const onPointerMove = (e: PointerEvent) => {
      refreshBaselinePreview(e);
    };
    const onPointerUp = (e: PointerEvent) => {
      if (e.pointerType === 'touch') {
        addCoordToBaseline(e);
      }
    };

    const removeListeners = this.addChartListeners({
      onPointerDown,
      onPointerMove,
      onPointerUp,
      onKeyDown,
    });

    this.disableCurrentMode = () => {
      removeListeners();

      this.actions.resetBaseline = null;
      this.actions.removeLastBaselineSegment = null;
    };
  }

  private addChartListeners(listeners: Partial<ChartEventListeners>): Callback {
    listeners.onPointerDown && this.chart.addEventListener('pointerdown', listeners.onPointerDown);
    // I use document here because plotly stops propagation for some events
    listeners.onPointerMove && document.addEventListener('pointermove', listeners.onPointerMove);
    listeners.onPointerUp && document.addEventListener('pointerup', listeners.onPointerUp);
    listeners.onKeyDown && document.addEventListener('keydown', listeners.onKeyDown);

    return () => {
      listeners.onPointerDown &&
        this.chart.removeEventListener('pointerdown', listeners.onPointerDown);
      listeners.onPointerMove &&
        document.removeEventListener('pointermove', listeners.onPointerMove);
      listeners.onPointerUp && document.removeEventListener('pointerup', listeners.onPointerUp);
      listeners.onKeyDown && document.removeEventListener('keydown', listeners.onKeyDown);
    };
  }

  // TODO memoize?
  private getPeakShapes(_data: { peaks: Peak[]; data: DataExtended }) {
    const { data, peaks } = _data;

    let visiblePeakCounter = 0;

    const peakShapes = peaks
      .flatMap((peak, index) => {
        const xPeakStart = peak.start;
        const xPeakEnd = peak.end;

        const xPeakStartWithDTIMCorrection = peak.start - data.x0;
        const _tempForXStart = Math.floor(xPeakStartWithDTIMCorrection / data.dx);
        const closestLeftXForXStart = _tempForXStart * data.dx;
        const closestLeftYForXStart = data.y[_tempForXStart];
        const closestRightXForXStart = (_tempForXStart + 1) * data.dx;
        const closestRightYForXStart = data.y[_tempForXStart + 1];

        const xPeakEndWithDTIMCorrection = peak.end - data.x0;
        const _tempForXEnd = Math.floor(xPeakEndWithDTIMCorrection / data.dx);
        const closestLeftXForXEnd = _tempForXEnd * data.dx;
        const closestLeftYForXEnd = data.y[_tempForXEnd];
        const closestRightXForXEnd = (_tempForXEnd + 1) * data.dx;
        const closestRightYForXEnd = data.y[_tempForXEnd + 1];

        if (
          closestLeftYForXStart == null ||
          closestRightYForXStart == null ||
          closestLeftYForXEnd == null ||
          closestRightYForXEnd == null
        ) {
          return null;
        }

        const yStartAttachmentToMeasurement = findYBetweenTwoPoints({
          x0: closestLeftXForXStart,
          x1: closestRightXForXStart,
          y0: closestLeftYForXStart,
          y1: closestRightYForXStart,
          xBetween: xPeakStartWithDTIMCorrection,
        });
        const yEndAttachmentToMeasurement = findYBetweenTwoPoints({
          x0: closestLeftXForXEnd,
          x1: closestRightXForXEnd,
          y0: closestLeftYForXEnd,
          y1: closestRightYForXEnd,
          xBetween: xPeakEndWithDTIMCorrection,
        });

        const yPeakStart = peak.start_mau == null ? yStartAttachmentToMeasurement : peak.start_mau;

        const yPeakEnd = peak.end_mau == null ? yEndAttachmentToMeasurement : peak.end_mau;

        const line = {
          type: 'line',
          x0: xPeakStart,
          y0: yPeakStart,
          x1: xPeakEnd,
          y1: yPeakEnd,
          line: {
            color: peak.is_manual ? COLORS.peakLineManual : COLORS.peakLineAuto,
            width: 2,
          },
          name: `peak-${peak.id}`,
          label: {
            text: String(index + 1),
            textangle: 0,
          },
        } as const;

        visiblePeakCounter++;

        const peakShapes: Shape[] = [line];

        const perpendicularLeft = {
          type: 'line',
          x0: xPeakStart,
          y0: yPeakStart,
          x1: xPeakStart,
          y1: yStartAttachmentToMeasurement,
          line: {
            color: COLORS.peakPerpendicular,
            width: 2,
            dash: 'dot',
          },
          name: `peak-${peak.id}-perpendicularLeft`,
        } as const;
        const perpendicularRight = {
          type: 'line',
          x0: xPeakEnd,
          y0: yPeakEnd,
          x1: xPeakEnd,
          y1: yEndAttachmentToMeasurement,
          line: {
            color: COLORS.peakPerpendicular,
            width: 2,
            dash: 'dot',
          },
          name: `peak-${peak.id}-perpendicularRight`,
        } as const;

        peakShapes.push(perpendicularLeft, perpendicularRight);

        const measurementPart: Array<[x: number, y: number]> = getMeasurementPart(
          data.y,
          data.dx,
          data.mps,
          data.x0,
          xPeakStart,
          xPeakEnd,
        );

        const coords: Array<[x: number, y: number]> = [
          [xPeakStart, yPeakStart],
          [xPeakStart, yStartAttachmentToMeasurement],
          ...measurementPart,
          [xPeakEnd, yEndAttachmentToMeasurement],
          [xPeakEnd, yPeakEnd],
        ];

        if (
          this.renderOptions.isShowEstimatedGaussians &&
          peak.estimated_shape === PEAK_ESTIMATED_SHAPES.SKEW_GAUSS &&
          peak.estimated_shape_params != null
        ) {
          const STEP = (1 / 60 / 1000) * 100;
          const xRange = generateRange(xPeakStart + STEP, xPeakEnd - STEP, STEP);
          const estimatedPeak = generateSkewedGaussianRange(xRange, peak.estimated_shape_params);

          const rangeExtended = [xPeakStart, ...xRange, xPeakEnd];
          const estimatedPeakExtended = [0, ...estimatedPeak, 0];

          const estimatedPeakCoords: [number, number][] = estimatedPeakExtended.map(
            (value, index) => {
              return [rangeExtended[index], value];
            },
          );

          const estimatedGaussian = {
            type: 'path',
            path: convertCoordsToPath(estimatedPeakCoords, true),
            fillcolor: COLORS.gaussianBackground,
            opacity: 1,
            line: {
              width: 1,
              color: COLORS.gaussianLine,
            },
            name: `peak-${peak.id}-estimated-gaussian`,
          } as const;

          peakShapes.push(estimatedGaussian);
        }

        const background = {
          type: 'path',
          path: convertCoordsToPath(coords, true),
          fillcolor:
            this.mode === Modes.REMOVE_PEAK
              ? COLORS.peakHighlightForRemoving
              : COLORS.peakHighlight,
          opacity: this.renderOptions.highlightedPeakIds?.has(peak.id) ? 1 : 0,
          line: {
            width: 0,
          },
          name: `peak-${peak.id}-background`,
          onMouseEnter: () => {
            if (this.mode === Modes.DEFAULT) {
              this.emitter.emit('highlightPeak', peak.id);
            }
          },
          onMouseLeave: () => {
            if (this.mode === Modes.DEFAULT) {
              this.emitter.emit('highlightPeak', null);
            }
          },
          // onClick() {
          //   console.warn('ON CLICK');
          // },
        } as const;
        peakShapes.push(background);

        return peakShapes;
      })
      .filter(Boolean);

    this.emitter.emit('hasInvisiblePeak', visiblePeakCounter < peaks.length);

    return peakShapes;
  }

  private zoom(bounds: ZoomBounds) {
    Plotly.zoom(this.chart, bounds);
  }
}
