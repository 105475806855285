import {
  CellTime,
  CellTypes,
  Row,
  ServerFormatItem,
} from '@/uikitProject/tables/gradient/utils/types';
import Decimal from 'decimal.js-light';

export class GradientTableAdapter {
  public static toRows(data: ServerFormatItem[]): Row[] {
    const DEFAULT_ACC: { [key: number]: number } = {};
    const uniqueTimeCounter = data.reduce((acc, { time }) => {
      if (acc[time]) {
        return {
          ...acc,
          [time]: acc[time] + 1,
        };
      }
      acc[time] = 1;
      return acc;
    }, DEFAULT_ACC);

    return data.map(({ time, fractions, id }) => {
      const columnTime = {
        value: time,
        type: CellTypes.TIME,
        isUnique: uniqueTimeCounter[time] <= 1,
      };

      const columnsFraction = fractions.map((fraction) => ({
        value: fraction,
        type: CellTypes.PERCENT,
      }));

      const totalPercent = fractions.reduce<Decimal>(
        (acc, value) => acc.add(value),
        new Decimal(0),
      );

      return {
        id,
        isValidPercent: new Decimal(totalPercent).equals(1),
        cells: [columnTime, ...columnsFraction],
      };
    });
  }

  public static fromRows(rows: Row[]): ServerFormatItem[] {
    return rows
      .map(({ id, cells: [cellFirst, ...fractions] }) => {
        return {
          id,
          time: (cellFirst as CellTime).value,
          fractions: fractions.map(({ value }) => value),
        };
      })
      .sort((a, b) => a.time - b.time);
  }
}
