<template>
  <div data-test-id="card-method">
    <MethodEditModal
      :configuration="configuration"
      :method="pMethod"
      :deviceId="deviceId"
      @update:method="methodUpdatedInModal($event)"
      @created="methodUpdatedInModal($event)"
    />

    <div class="method-card">
      <div class="method-card__top">
        <div class="method-card__label">Method</div>

        <div
          class="method-card__select"
          :class="{
            'method-card__select--visible': isSelectAlwaysVisible,
          }"
        >
          <SelectMethodAsPreset
            :method="method"
            :configuration="configuration"
            class="method-card__select"
            :class="{
              'method-card__select--visible': isSelectAlwaysVisible,
            }"
            @change="onPresetChosen"
            @showPopup="setIsSelectAlwaysVisible(true)"
            @hidePopup="setIsSelectAlwaysVisible(false)"
          />
        </div>
        <div
          v-if="method"
          class="method-card__wrapper-name"
          :class="{
            'method-card__wrapper-name--hidden': isSelectAlwaysVisible,
          }"
        >
          <span class="method-card__name">{{ method.name }}</span>
          <span class="method-card__id">#{{ method.id }}</span>
        </div>
        <div
          v-else
          class="method-card__wrapper-name"
          :class="{
            'method-card__wrapper-name--hidden': isSelectAlwaysVisible,
          }"
        >
          <div class="method-card__name">
            <i>Not specified</i>
          </div>
        </div>

        <div
          v-if="hasPermissionToEdit"
          class="method-card__buttons"
          style="display: flex; margin-left: 16px"
        >
          <button
            v-if="method"
            class="link-button"
            data-test-id="btn-edit-method"
            @click.stop="editMethod"
          >
            <i class="material-icons material-icon--14">edit</i> Edit
          </button>
          <button class="link-button" data-test-id="btn-edit-method" @click.stop="addMethod">
            <i class="material-icons material-icon--14">add</i> Add
          </button>
        </div>
      </div>

      <InfoMethod v-if="method" :method="method" @click="editMethod" />
      <i v-else-if="hasPermissionToEdit" class="text-gray mt-2 block" @click="addMethod"
        >Choose method from the list, or create new</i
      >
      <i v-else class="text-gray mt-2 block">Choose method from the list</i>
    </div>
  </div>
</template>

<script>
  import MethodEditModal from 'components/block/modal/MethodEditModal';
  import SelectMethodAsPreset from 'components/element/SelectMethodAsPreset';
  import InfoMethod from '@/uikitProject/info/InfoMethod';
  import { AuthService } from '@/services/auth.service';

  const EVENT_UPDATE_METHOD = 'update:method';

  export default {
    name: 'MethodCard',

    components: {
      InfoMethod,
      SelectMethodAsPreset,
      MethodEditModal,
    },

    model: {
      prop: 'method',
      event: EVENT_UPDATE_METHOD,
    },

    props: {
      method: Object,
      configuration: Object,
      deviceId: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        pMethod: this.method,

        isSelectAlwaysVisible: false,

        hasPermissionToEdit: AuthService.userData().role === 'admin',
      };
    },

    watch: {
      method(method) {
        this.pMethod = method;
      },
    },

    methods: {
      setIsSelectAlwaysVisible(value) {
        this.isSelectAlwaysVisible = value;
      },

      methodUpdatedInModal(method) {
        this.$emit(EVENT_UPDATE_METHOD, method);
      },

      showMethodModal() {
        this.$modal.show('method-edit');
      },

      onPresetChosen(method) {
        if (method) {
          this.pMethod = { ...method };
          this.$emit(EVENT_UPDATE_METHOD, this.pMethod);
        }
      },

      editMethod() {
        this.pMethod = this.method;
        this.showMethodModal();
      },
      addMethod() {
        this.pMethod = undefined;
        this.showMethodModal();
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/css/base/card';

  .method-card {
    &:hover {
      .method-card__select {
        display: inherit;
      }

      .method-card__wrapper-name {
        display: none;
      }

      .method-card__buttons {
        visibility: visible;
      }
    }

    &__top {
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: 700px) {
        flex-wrap: wrap;
      }
    }

    &__label {
      font-size: 17px;
      font-weight: bold;
      margin-right: 16px;
      margin-top: 4px;
      margin-bottom: 4px;
      white-space: nowrap;
      flex: 0 0;
    }

    &__select {
      vertical-align: top;
      flex: 1 0;
      min-width: 200px;

      &:not(&--visible) {
        @include isHoverDevice {
          display: none;
        }
      }
    }

    &__wrapper-name {
      display: flex;
      align-items: baseline;
      margin-right: auto;
      min-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include isHoverDevice(false) {
        display: none;
      }

      &--hidden {
        display: none;
      }

      &:hover {
        text-decoration: underline;
        color: $color-text-primary--hover;
      }
    }

    &__name {
      color: $color-text-primary;
      margin-top: 4px;
      margin-bottom: 4px;
      font-size: 17px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        text-decoration: underline;
        color: $color-text-primary--hover;
        cursor: pointer;
      }

      &:active {
        text-decoration: underline;
        color: $color-text-primary--active;
        cursor: pointer;
      }
    }

    &__id {
      color: $color-text-third;
      font-weight: $weight-normal;
      margin-left: 10px;
      font-size: $size-xs;
    }

    &__buttons {
      flex: 0 0;

      @include isHoverDevice {
        visibility: hidden;
      }

      @media only screen and (max-width: 700px) {
        visibility: visible;
      }
    }
  }
</style>
