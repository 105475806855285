import Vue from 'vue';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('min', {
  ...rules.min,
  message: 'Field must have at least {length} characters',
});

extend('email', {
  ...rules.email,
  message: 'Email is not valid',
});

extend('required', {
  ...rules.required,
  message: 'Field is required',
});

extend('max_value', {
  ...rules.max_value,
  message: (field, params) => `Must be higher than ${params.max}`,
});

extend('min_value', {
  ...rules.min_value,
  message: (field, params) => `Must be higher than ${params.min}`,
});
