export const ERRORS = {
  // Sequence controller
  'sequence_controller.device_offline': 'The device is offline',
  'sequence_controller.device_busy': 'The device is busy',
  'sequence_controller.no_instructions_in_sequence': 'No instructions found in the sequence table',
  'sequence_controller.no_draft_or_sequence_method':
    'Neither the draft nor the sequence method is configured',
  'sequence_controller.sequence_state_not_running': 'The sequence state must be RUNNING',
  'sequence_controller.sequence_current_draft_none': `The sequence's current draft is not set`,
  'sequence_controller.injection_not_finished': `The sequence's current injection is not marked as finished`,
  'sequence_controller.already_running': 'The sequence is already in the RUNNING state',
  'sequence_controller.cannot_resume_not_paused': 'Unable to RESUME, sequence is not PAUSED',
  'sequence_controller.cannot_resume_no_injection': 'Unable to RESUME, no injection',
  'sequence_controller.cannot_pause_not_running': `Can't PAUSE sequence that's not RUNNING`,
  'sequence_controller.sequence_not_found': 'Sequence is not found',
  'sequence_controller.injection_without_measurement': 'Injection exists but without measurement',
  'sequence_controller.device_not_configured': 'Method is not configured',
  'sequence_controller.device_not_connected': 'The device is not connected',
  'sequence_controller.sample_type_unexpected': 'Unexpected type of the sample',
  'sequence_controller.cannot_start_standard_not_ready':
    'Cannot start sequence. Define amount and time for all standards',

  // Calibration controller
  'standard_controller.none_device_method_column': 'Device, method, and column must not be None',
  'standard_controller.none_column': 'Column is not selected',
  'standard_controller.device_offline': 'The device is offline',
  'standard_controller.device_busy': 'The device is busy',

  // Device controller
  'device_controller.device_offline': 'The device is offline',
  'device_controller.device_busy': 'The The device is busy',
  'device_controller.column_not_configured': 'Column is not configured',
  'device_controller.different_device': 'Device is different',
  'device_controller.method_not_configured': 'Method is not configured',
  'device_controller.method_not_compatible':
    'Method is not compatible with the current device configuration',

  // Sample controller
  'sample_controller.device_offline': 'The device is offline',
  'sample_controller.device_busy': 'The device is busy',
  'sample_controller.standard_sample': 'Sample is a Standard, use StandardController',
  'sample_controller.none_device_method_column': 'Device, method, and column must not be None',
  'sample_controller.none_column': 'Column is not selected',

  // Autosampler controller
  'autosampler_collector_controller.invalid_interval': 'Fraction collector interval is invalid',
  'autosampler_collector_controller.fraction_collector_valve_required':
    'Configuration invalid. Fraction collector valve is required',
  'autosampler_collector_controller.not_enough_vials': 'Fraction Collector error, not enough vials',
  'autosampler_collector_controller.needle_wash_not_allowed':
    'Needle wash not allowed with autosampler fraction collector',

  // Sequence table controller
  'sequence_table_controller.draft_not_in_sequence': 'Draft not in Sequence',
  'sequence_table_controller.id_required_update_draft': 'ID required to update draft',
  'sequence_table_controller.invalid_sequence_id': 'Invalid Sequence ID',
  'sequence_table_controller.sample_must_be_in_sequence': 'Sample must be in Sequence',
  'sequence_table_controller.invalid_draft': 'Draft invalid',
  'sequence_table_controller.draft_cannot_be_enabled':
    'Draft cannot be enabled. Define the amount and time for the relevant standard ',

  // Measurement controller
  'measurement_controller.no_injection': 'No injection',
  'measurement_controller.no_peak': 'No peak',
  'measurement_controller.no_standard': 'No standard',
  'measurement_controller.no_response_factor_in_standard': 'No response factor in standard',

  // Column controller
  'column_controller.forbidden_to_add_run_time': 'It is forbidden add to the run time',

  // Method
  'method.only_method_is_changeable': 'Only name field is changeable',
  'method.hardware_configuration_mismatch': 'Hardware configuration mismatch',

  // Device
  device_not_configured: 'Device is not configured',
  'device.pump_is_not_ready': 'Pump is not ready',
  'device.injector_is_not_ready': 'Injector is not ready',
  'device.detector_is_not_ready': 'Detector is not ready',

  // Publishers
  'standard_injection_publisher.unsupported_key_in_data':
    'Not supported key "event" in property "data"',
  'standard_publisher.unsupported_key_in_data': 'Not supported key "event" in property "data"',
  'device_publisher.unsupported_key_in_data': 'Not supported key "event" in property "data"',
  'hardware_publisher.unsupported_key_in_data': 'Not supported key "event" in property "data"',
  'measurement_publisher.unsupported_key_in_data': 'Not supported key "event" in property "data"',
  'sample_injection_publisher.unsupported_key_in_data':
    'Not supported key "event" in property "data"',
  'sample_publisher.unsupported_key_in_data': 'Not supported key "event" in property "data"',
  'sequence_publisher.unsupported_key_in_data': 'Not supported key "event" in property "data"',

  // Peak processing tasks
  'peak_processing_task.general_error': 'General error',
  'peak_processing_task.no_peaks_found': 'No peaks found',

  // Wifi adapter
  'wifi.password_wrong': 'Password is wrong',
  'wifi.ssid_not_found': 'Network name is not found',

  server_error: 'The server was unable to execute the operation',
  timeout: 'Timeout',

  // Errors from Alltesta or Cromite

  // Input/output error
  101: 'Firmware error',
  // Operation was interrupted
  102: 'Firmware error',

  // Network errors
  200: 'Network was not found',
  201: 'Password is wrong',
  202: 'Connection failed',
  999: 'Unknown error',
} as const;
