<template>
  <FormCreation
    :model="dmc"
    :isValid="isValid"
    :requireColumn="isCalibrationMode"
    :canSelectMethod="isCalibrationMode"
    :canSelectPostprocessing="isCalibrationMode"
    :isRequiredDevice="true"
    :isRequiredSingleChannelMode="isCalibrationMode"
    :isRequiredPostprocessing="isCalibrationMode"
    class="sequence-new"
    @update:model="updateDMC"
    @submit="createSequence"
  >
    <template #default>
      <DecoratorInput iconSize="md" iconTitle="group_work" label="Name" style="margin-bottom: 16px">
        <input
          ref="name"
          v-model="name"
          autofocus="autofocus"
          class="header"
          type="text"
          @focus="$event.target.select()"
        />
      </DecoratorInput>

      <DecoratorInput
        iconSize="sm"
        iconTitle="notes"
        label="Description"
        style="margin-bottom: 16px"
      >
        <textarea v-model="description" class="field" rows="2"></textarea>
      </DecoratorInput>

      <!--      <DecoratorInput-->
      <!--        iconSize="sm"-->
      <!--        iconTitle="star"-->
      <!--        label="Expected compounds"-->
      <!--        style="margin-bottom: 16px"-->
      <!--      >-->
      <!--        <SelectorMultipleCompounds v-model="compounds" />-->
      <!--      </DecoratorInput>-->
    </template>

    <template #settings>
      <div class="sequence-new__mode">
        <Checkbox v-model="isCalibrationMode" class="sequence-new__mode-checkbox">
          Calibration mode
        </Checkbox>
        <div v-if="isCalibrationMode" class="sequence-new__mode-helper">
          Calibration Mode Sequence Restrictions:
          <br />

          <ul class="sequence-new__restrictions">
            <li class="sequence-new__restriction">
              Define a single method, column, and post-processing template for the entire sequence.
            </li>
            <li class="sequence-new__restriction">
              Use only one detector channel in the specified method.
            </li>
            <li class="sequence-new__restriction">
              This setting applies to all injections in the sequence.
            </li>
            <li class="sequence-new__restriction">
              Only automated peak identification is available.
            </li>
            <li class="sequence-new__restriction">
              Each vial can only be used for one sample or standard.
            </li>
            <li class="sequence-new__restriction">
              Download a detailed report after the sequence is finished. You have selected the
              calibration mode, which helps automate the experiment by using standards.
            </li>
          </ul>
        </div>
      </div>
    </template>
  </FormCreation>
</template>

<script>
  import DecoratorInput from '@/uikitProject/decorators/DecoratorInput';
  import { SequenceAPI } from 'api/sequence';
  import FormCreation from 'components/blocks/forms/Creation';
  import Checkbox from '@/uikitBase/switchers/Checkbox.vue';

  const EVENT_CREATE = 'create';

  export default {
    name: 'SampleNewComponent',

    components: {
      Checkbox,
      FormCreation,
      DecoratorInput,
    },

    data: () => {
      const defaultName = 'Sequence name';

      return {
        defaultName,
        name: defaultName,
        description: '',
        compounds: [],

        dmc: {
          device: null,
          method: null,
          column: null,
          postprocessings: {
            red: null,
            green: null,
            blue: null,
            uv: null,
          },
        },

        isCalibrationMode: false,
      };
    },

    computed: {
      isValid() {
        return this.dmc.device != null && this.name.length > 0 && this.isCalibrationMode
          ? this.dmc.column != null &&
              this.dmc.method != null &&
              Object.values(this.dmc.postprocessings).some(Boolean)
          : true;
      },

      postprocessingId() {
        return Object.values(this.dmc.postprocessings).find((channel) => channel != null).uuid;
      },
    },

    mounted() {
      this.$refs.name.focus();
    },

    methods: {
      createSequence() {
        if (!this.isValid) {
          return;
        }

        const data = {
          name: this.name !== this.defaultName ? this.name?.trim() : null,
          description: this.description,
          // compounds: this.compounds.map((compound) => compound.name).join('; '),
          device_id: this.dmc.device?.id,
          method_id: this.isCalibrationMode ? this.dmc.method?.id : null,
          column_id: this.isCalibrationMode ? this.dmc.column?.id : null,
          postprocessing_id: this.isCalibrationMode ? this.postprocessingId : null,
          is_calibration_mode: this.isCalibrationMode,
        };

        SequenceAPI.post(
          data,
          (r) => {
            const { id } = r;
            this.$router.push({ name: 'sequence', params: { id } });
            this.$emit(EVENT_CREATE);
          },
          (r, c) => {
            if (r) {
              this.message = r.detail;
            } else {
              this.message = `Error ${c}`;
            }

            this.notifyError(this.message);
          },
        );
      },
      updateDMC(model) {
        this.dmc = {
          device: model.device ?? null,
          method: model.method ?? null,
          // We need this handling because DeviceMethodColumnInputs returns undefined on start
          // Do not fix DeviceMethodColumnInputs. It will cause problems
          column: model.column ?? null,
          postprocessings: model.postprocessings ?? {
            red: null,
            green: null,
            blue: null,
            uv: null,
          },
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sequence-new {
    &__mode {
      padding-left: 10px;
    }

    &__mode-checkbox {
      margin-bottom: 10px;
    }

    &__mode-helper {
      padding: 10px 8px;
      border-radius: $border-radius__md;
      background-color: #ffeccc;
      margin-bottom: 20px;
    }

    &__restrictions {
      margin-top: 5px;
      list-style-type: disc;
      padding-left: 20px;
    }

    &__restriction {
      margin-bottom: 2px;
    }
  }
</style>
