<template>
  <Component :is="iconComponent" />
</template>

<script>
  import IconStandard from '../IconStandard';
  import IconSample from '../IconSample';
  import IconInjection from '../IconInjection';
  import IconSequence from '../IconSequence';

  export default {
    name: 'PackIconsEntity',

    props: {
      entity: {
        type: String,
        required: true,
        validator(value) {
          const availableValues = ['standard', 'sample', 'injection', 'sequence'];
          return availableValues.includes(value);
        },
      },
    },

    computed: {
      iconComponent() {
        switch (this.entity) {
          case 'standard':
            return IconStandard;
          case 'sample':
            return IconSample;
          case 'injection':
            return IconInjection;
          case 'sequence':
            return IconSequence;
          default:
            throw new Error('Wrong icon type');
        }
      },
    },
  };
</script>
