<template>
  <div v-if="errors != null">
    <div v-for="(item, index) in errors" :key="index" class="text-red">
      {{ getErrorMessageByCode(item) }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HardwareErrorComponent',
    props: {
      errors: Array,
      errorList: Object,
    },
    methods: {
      getErrorMessageByCode(code) {
        const message = this.errorList[code];
        return message ? message : 'Unknown error';
      },
    },
  };
</script>
