<template>
  <label class="input-file">
    <span v-if="files" class="input-fake">{{ files[0].name }}</span>
    <span v-else class="input-fake text-gray">Select File</span>
    <input
      ref="inputFile"
      class="link-button link-button--normal"
      name="name"
      pattern="pattern"
      type="file"
      @change="onInputChange"
    />
  </label>
</template>

<script>
  export default {
    name: 'InputFileComponent',
    props: {
      value: FileList,
      pattern: String,
      name: { type: String, default: 'file' },
    },
    data() {
      return {
        files: this.value,
      };
    },
    watch: {
      value(value) {
        this.files = value;
      },
    },
    methods: {
      onInputChange(e) {
        this.files = e.target.files;
        this.$emit('input', e.target.files);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/css/base/buttons';
  @import 'src/assets/css/base/input';

  .input-file {
    .input-fake {
      @extend .input--faked;

      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:active {
        background: #f4f4f4;
      }
    }
  }

  input[type='file'] {
    display: none;
  }
</style>
