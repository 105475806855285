<template>
  <div class="contact-list">
    <div v-if="isLoading" class="contact-list__wrapper-loader">
      <LoaderSpinner class="contact-list__loader" />
      <div>Loading contacts</div>
    </div>

    <template v-else>
      <div v-if="unknownEmails.length" class="contact-list__unknown-emails">
        <div class="contact-list__title-unknown">Unknown emails</div>

        <div
          v-for="email of unknownEmails"
          :key="email"
          class="contact-list__wrapper-unknown-email"
        >
          <span class="contact-list__unknown-email">{{ email }}</span>
          <slot name="action" :email="email" />
        </div>
      </div>

      <div v-if="isReadonly && contacts.length === 0" class="contact-list__no-contacts">
        No contacts
      </div>
      <template v-else>
        <Contact
          v-for="contact of contacts"
          :key="contact.email"
          :contact="contact"
          :isMe="userEmail === contact.email"
          :isReadonly="isReadonly"
          @edit="editContact"
          @remove="removeContact"
        >
          <template #action="{ contact }">
            <slot name="action" :contact="contact" />
          </template>
        </Contact>
      </template>
    </template>

    <template v-if="!isReadonly">
      <AddContact v-if="isShowForm" @cancel="hideForm" @create="addContact" />
      <PopupHelper
        v-else
        :isFullscreen="true"
        text="Click to enter the information for a new contact to send reports to."
      >
        <div class="contact-list__add-contact" @click="showForm">
          <IconMaterial title="add" class="contact-list__icon-plus" />
          <span class="contact-list__label-new-contact">New contact</span>
        </div>
      </PopupHelper>
    </template>
  </div>
</template>

<script>
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import Contact from './private/Contact';
  import AddContact from 'components/blocks/contacts/vueContactList/private/AddContact';
  import ContactsAPI from 'api/contacts';
  import { AuthService } from '@/services/auth.service.ts';
  import LoaderSpinner from '@/uikitProject/loaders/LoaderSpinner';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper.vue';

  const EVENT_REMOVE = 'remove';

  export default {
    name: 'ContactList',

    components: {
      PopupHelper,
      LoaderSpinner,
      AddContact,
      Contact,
      IconMaterial,
    },

    props: {
      selectedEmails: {
        type: Array,
      },
      isReadonly: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      contacts: [],

      isShowForm: false,
      isLoading: false,

      allUsedEmails: [],
    }),

    computed: {
      userEmail() {
        return AuthService.userData()?.email;
      },
      unknownEmails() {
        const uniqueEmails = new Set(this.allUsedEmails);

        return (
          [...uniqueEmails.values()].filter(
            (email) => !this.contacts?.find((contact) => contact.email === email),
          ) ?? []
        );
      },
    },

    watch: {
      selectedEmails: {
        handler(emails) {
          if (emails) {
            this.allUsedEmails.push(...emails);
          }
        },
        immediate: true,
      },
    },

    created() {
      this.initContacts();
    },

    methods: {
      showForm() {
        this.isShowForm = true;
      },
      hideForm() {
        this.isShowForm = false;
      },

      initContacts() {
        this.isLoading = true;

        ContactsAPI.getList(
          (contacts) => {
            this.contacts = contacts;
            this.isLoading = false;
          },
          () => {
            this.notifyError('Unable to get contact list!');
            this.isLoading = false;
          },
        );
      },
      addContact(contact) {
        this.hideForm();
        this.contacts.push(contact);
      },
      editContact(contact) {
        this.contacts = this.contacts.map((_contact) =>
          _contact.id === contact.id ? contact : _contact,
        );
      },
      removeContact(contact) {
        this.contacts = this.contacts.filter((_contact) => _contact.id !== contact.id);
        this.$emit(EVENT_REMOVE, contact.email);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .contact-list {
    padding: 8px 0;
    background-color: #f0f0f0;

    &__wrapper-loader {
      padding: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__loader {
      margin-right: 5px;
    }

    &__unknown-emails {
      padding: 10px 14px;
      text-align: right;
    }

    &__title-unknown {
      margin-bottom: 10px;
      font-weight: $weight-semi-bold;
    }

    &__wrapper-unknown-email {
      white-space: nowrap;
      display: flex;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &__unknown-email {
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
    }

    &__no-contacts {
      text-align: center;
    }

    &__add-contact {
      display: flex;
      align-items: center;
      height: 44px;
      padding-left: 17px;
      padding-right: 14px;
      background-color: #f6f6f6;
      cursor: pointer;
      transition: background-color 0.3s;
      font-size: 13px;
      color: $color-text-primary;
      font-weight: $weight-bold;

      &:hover {
        background-color: darken(#f6f6f6, 5%);
      }

      &:active {
        background-color: darken(#f6f6f6, 10%);
      }
    }

    &__icon-plus {
      display: flex;
      padding: 0;
      justify-content: center;
      width: 32px;
      margin-right: 13px;
      color: $color-text-primary;
      font-size: 22px;
      height: 22px;
    }

    &__label-new-contact {
      padding-top: 0.1em;
    }
  }
</style>
