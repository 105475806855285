<template>
  <div
    v-if="configuration.hasOwnProperty('autosampler') && configuration.autosampler != null"
    class="hardware-tile autosampler"
  >
    <div class="hardware-tile__wrapper-title">
      <div>
        <i class="hardware-tile__title-icon">group_work</i>
        <h4>Autosampler</h4>
      </div>
      <Information :data="popupInfoData" class="hardware-tile__information" />
    </div>

    <hardware-state-component
      :state="currentState"
      :errors="errors"
      data-test-id="autosampler-status"
    />
    <HardwareErrorComponent :errors="errors" :error-list="errorList" />

    <!--  OPERATIONS  -->

    <template v-if="operation === 'INJECTION'">
      <div class="operation text-process">
        <i>colorize</i>
        <span>
          Injecting {{ stateDetails.amount }} µL
          <br />
          from {{ stateDetails.vial }}
        </span>
      </div>

      <template v-if="stateDetails.vial && stateDetails.vial > 0">
        <div class="inline-block font-black mt-2 text-2xl">{{ vial || stateDetails.vial }}</div>
        <div class="font-black text-gray">Vial</div>
      </template>
    </template>

    <div v-else-if="operation === 'WASHING'" class="operation text-process">
      <i>gradient</i>
      <span>Washing needle</span>
    </div>

    <div v-else-if="operation === 'SHAKING'" class="operation text-process">
      <i>sync_alt</i>
      <span>Shaking</span>
    </div>

    <div v-else-if="operation === 'GETTING_READY'" class="operation text-yellow">
      <i>rotate_left</i>
      <span>Getting ready</span>
    </div>

    <div v-else-if="operation === 'WASHING_LOOP'" class="operation text-yellow">
      <i>360</i>
      <span>Washing loop</span>
    </div>

    <template v-else>
      <div v-if="!injectionInProgress" class="hardware-tile__wrapper-vial">
        <BtnSelectVial
          :isDisabled="state.ready !== true"
          :trayConfig="trayConfig"
          @select="setVial"
        />
      </div>

      <div v-if="canWashNeedle || canShake" class="mt-8">
        <Btn v-if="canWashNeedle" class="mr-2" icon-left="gradient" height="s" @click="washNeedle">
          Wash needle
        </Btn>
        <Btn v-if="canShake" class="mt-2" icon-left="sync_alt" height="s" @click="shake">
          Shake
        </Btn>
      </div>
    </template>

    <div v-if="canGetReady" class="mt-8">
      <Btn class="mt-2" icon-left="rotate_left" height="s" @click="get_ready">Cancel</Btn>
    </div>

    <!--Absolute positioned-->
    <BtnIcon
      :isDisabled="state.autosampler_details == null"
      iconMaterial="settings"
      class="autosampler__btn-service"
      @click="showServiceModal"
    />
    <AutosamplerServiceModal
      v-if="state.autosampler_details"
      :autosamplerState="state.autosampler_details"
      :deviceSocket="ws"
    />
  </div>
</template>

<script>
  import Base from './Base.vue';
  import 'assets/css/base/animation.scss';
  import BtnSelectVial from '../../../uikitProject/vials/vueBtnSelectVial/BtnSelectVial';
  import Btn from '@/uikitBase/btns/Btn';
  import HardwareErrorComponent from 'components/element/HardwareErrorComponent';
  import { AUTOSAMPLER_ERRORS, GENERAL_ERRORS } from '@/constants/hardware/errors';
  import Information from './private/Information';
  import { autosamplerCommands } from '@/constants/hardware/commands';
  import BtnIcon from '@/uikitBase/btns/BtnIcon.vue';
  import AutosamplerServiceModal from '@/components/block/modal/AutosamplerServiceModal.vue';

  const EVENT_START = 'start';

  export default {
    components: {
      AutosamplerServiceModal,
      BtnIcon,
      Information,
      HardwareErrorComponent,
      Btn,
      BtnSelectVial,
    },

    extends: Base,

    data: () => ({
      vial: null,
    }),

    computed: {
      currentState() {
        return this.state?.autosampler;
      },
      stateDetails() {
        return this.state?.autosampler_details;
      },
      operation() {
        return this.stateDetails?.operation;
      },
      errors() {
        return this.stateDetails?.errors;
      },
      errorList() {
        return { ...GENERAL_ERRORS, ...AUTOSAMPLER_ERRORS };
      },
      injectionInProgress() {
        return this.state.injection_id != null;
      },

      popupInfoData() {
        const { firmware_version, id, serial } = this.configuration.autosampler;

        const data = [
          {
            name: 'Id',
            value: id,
          },
          {
            name: 'Serial',
            value: serial,
          },
          {
            name: 'Firmware Version',
            value: firmware_version
              ? `${firmware_version.major}.${firmware_version.minor}`
              : 'Not available',
          },
          {
            name: 'Tray',
            value: this.trayConfig,
          },
          {
            name: 'Total injections',
            value: this.state?.autosampler_details?.total_injections,
          },
        ];

        return data;
      },

      commands() {
        return this.stateDetails?.commandDetails ?? {};
      },

      canWashNeedle() {
        return this.commands[autosamplerCommands.WASH]?.status.available;
      },
      canShake() {
        return this.commands[autosamplerCommands.SHAKE]?.status.available;
      },
      canGetReady() {
        return this.commands[autosamplerCommands.GET_READY]?.status.available;
      },
    },

    methods: {
      setVial(vial) {
        this.vial = vial;
        this.start();
      },

      start() {
        const { vial } = this;
        this.$emit(EVENT_START, vial);
      },

      washNeedle() {
        this.ws.command('autosampler', autosamplerCommands.WASH);
      },
      shake() {
        this.ws.command('autosampler', autosamplerCommands.SHAKE);
      },
      get_ready() {
        this.ws.command('autosampler', autosamplerCommands.GET_READY);
      },

      showServiceModal() {
        this.$modal.show('autosamplerService');
      },
    },
  };
</script>

<style lang="scss">
  @import '~assets/css/base/icons';
  @import '~assets/css/base/text';
  @import '~assets/css/base/animation';

  .hardware-tile {
    .operation {
      text-align: center;
      align-content: center;
      margin: 32px auto auto;

      span {
        font-weight: bold;
        margin-bottom: 4px;
      }

      i {
        @extend .material-icons;
        @extend .material-icon--24;
        @extend .blink;
        display: block;
        margin: auto;
      }
    }
  }

  .autosampler {
    position: relative;

    &:hover &__btn-service {
      opacity: 1;
    }

    &__btn-service {
      @include isHoverDevice {
        opacity: 0;
      }

      position: absolute;
      bottom: 16px;
      right: 16px;
      transition: opacity 0.3s;
    }
  }
</style>
