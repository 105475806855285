<template>
  <div
    v-if="standard != null"
    :key="standard.id"
    class="paper-block-container paper-block-container--main"
  >
    <div class="paper__top">
      <div class="paper__header">
        <div>
          <InputTitle
            id="block-name"
            v-model="standard.name"
            iconName="star"
            :maxLength="200"
            @save="saveName"
          />

          <div class="pt-1">
            <span class="mr-3">Standard</span>

            <span class="span-date mr-3" v-html="new Date(standard.created).toLocaleDateString()" />

            <StateArchived v-if="standard.archived" />
          </div>
        </div>
        <div>
          <button v-if="standard.archived" class="button--square-image" @click="restore">
            <i class="material-icons material-icon--18">restore</i>
            Restore
          </button>
          <button v-else-if="isEditMode" class="button--square-image" @click="finishEditing">
            <i class="material-icons material-icon--18">done</i>
            Finish <br />
            editing
          </button>

          <MoreMenuComponent v-show="!standard.archived && !isEditMode">
            <button class="button--square-image" @click="startEditing">
              <i class="material-icons material-icon--18">edit</i>
              Edit
            </button>
            <div v-if="isPaused" v-tippy content="Standard is paused! Stop it to archive!">
              <button class="button--square-image" data-test-id="btn-archive" disabled>
                <i class="material-icons material-icon--18">delete</i>
                Archive
              </button>
            </div>
            <button
              v-else-if="standard.can_archive && !isInProgress"
              class="button--square-image"
              @click="archive"
            >
              <i class="material-icons material-icon--18">delete</i>
              Archive
            </button>
          </MoreMenuComponent>
        </div>
      </div>
      <div class="paper__content--margin-32">
        <ModelTextareaComponent
          ref="description"
          v-model="standard.description"
          :canEdit="standard.can_edit"
          :isEditMode="isEditMode"
          :label="'Description'"
          style="margin: 16px 0 10px"
          @click="startEditing"
          @update:value="saveDescription"
        />
        <div>
          <label class="label-block-margin" style="margin: 16px 0 0"> Compound </label>
          <CompoundSelector
            v-if="isEditMode"
            v-model="inputs.compound"
            :sequenceId="standardData && standardData.sequence.id"
            :isDisabled="true"
          />
          <div v-else>{{ inputs.compound.name }}</div>
        </div>
        <div class="flex items-end">
          <ModelFieldComponent
            v-model="standard.amount"
            :label="`Standard concentration${
              isEditMode && canEditAmountAndPeakDetection ? '' : `, ${standard.unit}`
            }`"
            :type="'float'"
            :isEditMode="isEditMode && canEditAmountAndPeakDetection"
            :canEdit="standard.can_edit && canEditAmountAndPeakDetection"
            class="standard-component__field flex-1"
            @edit="startEditing"
          />
          <ModelFieldComponent
            v-if="isEditMode && canEditAmountAndPeakDetection"
            v-model="standard.unit"
            type="str"
            required
            :hints="['%', 'mg/mL', 'mol', 'ppm']"
            :canSelectOnlyFromHints="true"
            class="standard-component__field standard-component__field--unit"
          />
        </div>
        <ModelFieldComponent
          v-model="inputs.peak_detection_start"
          :label="'Start of peak search time, min'"
          :type="'float'"
          :isEditMode="isEditMode && canEditAmountAndPeakDetection"
          :canEdit="standard.can_edit && canEditAmountAndPeakDetection"
          class="standard-component__field"
          :disabled="!isCompoundEditable"
          @edit="startEditing"
        />
        <ModelFieldComponent
          v-model="inputs.peak_detection_end"
          :label="'End of peak search time, min'"
          :type="'float'"
          :isEditMode="isEditMode && canEditAmountAndPeakDetection"
          :canEdit="standard.can_edit && canEditAmountAndPeakDetection"
          :disabled="!isCompoundEditable"
          class="standard-component__field"
          @edit="startEditing"
        />
        <template v-if="isEditMode">
          <div
            v-if="!canEditAmountAndPeakDetection"
            class="standard-component__warning-not-editable"
          >
            Only the description can be edited after the injection has been started
          </div>
          <div v-else-if="!isCompoundEditable" class="standard-component__warning-not-editable">
            You can not edit the peak search time. The time must be the same for all uses of the
            same compound in a sequence in calibration mode. It's allowed to change the peak search
            time until no injection has started yet
          </div>
        </template>
      </div>
    </div>

    <!--I change v-show to v-if, because this component conflicts with the Chart component on the calibration page.
      v-show doesn't work in case of peak highlighting -->
    <div v-if="injectionsAll">
      <StandardInjectionsListComponent
        :archived="showArchivedInjections"
        :standard="standard"
        :class="{ 'block--shadow-in': !empty && standard.k }"
        :injectionID="injectionID"
        :injections="injections"
        :injectionHighlightedId.sync="injectionHighlightedId"
        :isInProgress="isInProgress"
        @update:archived="showArchivedInjections = $event"
        @update:injectionID="showInjection($event)"
      />
    </div>
    <div v-show="!injectionsAll" style="padding-bottom: 32px">
      <InjectionsNavBarComponent
        :archived="showArchivedInjections"
        :injectionID="injectionID"
        :injections="injections"
        :allowNew="false"
        :isInProgress="isInProgress"
        @update:archived="showArchivedInjections = $event"
        @update:injectionID="showInjection($event)"
      />

      <StandardInjectionComponent
        v-if="injectionData"
        ref="injection"
        :standard="standard"
        :injectionID="injectionID"
        :injection-data="injectionData"
        @selectPeak="updateInjectionPeak"
      />
      <not-available-component v-else>No data</not-available-component>
    </div>
  </div>
  <error-component v-else-if="errorCode != null" :code="errorCode" class="mt-16" />
  <loading-component v-else />
</template>

<script>
  import 'assets/css/base/table.scss';
  import _ from 'lodash';

  import StateArchived from '@/uikitProject/states/StateArchived';
  import StandardInjectionComponent from 'components/block/StandardInjectionComponent';
  import StandardInjectionsListComponent from 'components/block/StandardInjectionsListComponent';
  import StandardSocket, { StandardSocketEvents } from 'api/sockets/StandardSocket';
  import ErrorComponent from 'components/element/ErrorComponent';
  import InjectionsNavBarComponent from 'components/block/InjectionsNavBarComponent';
  import LoadingComponent from 'components/element/LoadingComponent';
  import ModelTextareaComponent from 'components/element/ModelTextareaComponent';
  import MoreMenuComponent from 'components/element/MoreMenuComponent';
  import NotAvailableComponent from 'components/element/NotAvailableComponent';
  import { isSecondaryView } from '@/components/blocks/layouts/dual/SecondaryView';
  import InputTitle from '@/uikitProject/inputs/inputTitle/InputTitle';
  import { apiStandards } from '@/api/graphql/cloud/standards';
  import { isSampleInProgress } from '@/utils/sampleHelpers';
  import DataNotFoundError from '@/errors/DataNotFoundError';
  import ModelFieldComponent from '@/components/element/ModelFieldComponent.vue';
  import CompoundSelector from '@/uikitProject/compounds/CompoundSelector.vue';

  const EVENT_UPDATE_INJECTION_ID = 'update:injectionID';
  const EVENT_UPDATE_STANDARD_DATA = 'update:standardData';
  const EVENT_EDIT = 'edit';
  const EVENT_ARCHIVE = 'archive';

  export default {
    name: 'StandardComponent',

    inject: {
      isSecondaryView,
    },

    components: {
      CompoundSelector,
      ModelFieldComponent,
      InputTitle,
      ErrorComponent,
      NotAvailableComponent,
      StandardInjectionsListComponent,
      InjectionsNavBarComponent,
      LoadingComponent,
      ModelTextareaComponent,
      MoreMenuComponent,
      StateArchived,
      StandardInjectionComponent,
    },

    props: {
      id: Number,
      injectionID: Number,
      standardData: Object,
      isPaused: {
        type: Boolean,
      },
    },

    data() {
      return {
        standard: null,
        device: null,
        method: null,
        column: null,
        injections: {},
        injectionData: null,
        standardSocket: this.createSocket(this.id),
        showArchivedInjections: false,
        errorCode: null,

        injectionHighlightedId: null,

        listenersGroupId: null,

        isEditMode: false,
        wasEditingStated: false,

        inputs: {
          compound: null,
          peak_detection_start: null,
          peak_detection_end: null,
        },

        isCompoundEditable: false,
      };
    },

    computed: {
      injectionsAll: {
        get() {
          return this.injectionID == null;
        },
        set(b) {
          if (b) this.showInjection(null);
        },
      },
      empty() {
        if (!this.injections) return true;

        const isFound = Object.keys(this.injections).find(
          (key) => this.archived || !this.injections[key].injection.archived,
        );
        return !isFound;
      },

      isInProgress() {
        return isSampleInProgress(this.standard);
      },

      hasInjections() {
        return Object.values(this.injections).length > 0;
      },

      canEditAmountAndPeakDetection() {
        return !this.hasInjections;
      },
    },

    watch: {
      id(id) {
        this.errorCode = null;
        this.standardSocket = this.createSocket(id);
        this.initData(id);
      },
      injectionID() {
        if (this.injectionID) {
          this.updateInjectionData();
        }
      },
      injections(injectionsNew) {
        this.updateInjectionData();

        const injectionList = Object.values(injectionsNew);
        if (!injectionList.length && !this.wasEditingStated) {
          this.startEditing();
        } else if (injectionList.length) {
          this.finishEditing();
        }
      },
      standardData(newData) {
        if (newData == null) return;
        if (newData.id !== this.id) return;
        if (this.standard == null || this.standard.id !== newData.id) {
          this.standard = newData;
        }
      },
      standard: {
        handler(value) {
          this.inputs = {
            peak_detection_start:
              value?.sequence_compound.peak_detection_start != null
                ? parseFloat((value.sequence_compound.peak_detection_start / 60).toFixed(3))
                : '',
            peak_detection_end:
              value?.sequence_compound.peak_detection_end != null
                ? parseFloat((value.sequence_compound.peak_detection_end / 60).toFixed(3))
                : '',
            compound: value?.sequence_compound.compound,
          };
          this.isCompoundEditable = value?.sequence_compound.is_editable;
        },
        immediate: true,
      },
      'inputs.compound'(compound) {
        if (!compound?.sequence_compounds) {
          return;
        }

        const [
          { peak_detection_start, peak_detection_end, is_editable },
        ] = compound.sequence_compounds;
        this.inputs.peak_detection_start = peak_detection_start;
        this.inputs.peak_detection_end = peak_detection_end;
        this.isCompoundEditable = is_editable;
      },
    },

    created() {
      this.initData(this.id);
    },

    beforeDestroy() {
      this.destroySocket();
    },

    methods: {
      async initData(standardId) {
        try {
          const data = await apiStandards.getStandardFull(standardId);

          if (_.has(data, 'standard')) {
            this.setStandard(data.standard);
          }
          if (_.has(data, 'device')) {
            this.device = data.device;
          }
          if (_.has(data, 'method')) {
            this.method = data.method;
          }
          if (_.has(data, 'column')) {
            this.column = data.column;
          }
          if (_.has(data, 'injections')) {
            this.injections = data.injections;
          }
        } catch (e) {
          if (e instanceof DataNotFoundError) {
            this.resetData();
          }
          throw e;
        }
      },

      resetData() {
        this.setStandard(undefined);
        this.device = undefined;
        this.method = undefined;
        this.column = undefined;
        this.injections = {};
      },

      setStandard(c) {
        // eslint-disable-next-line vue/no-mutating-props
        this.standardData = c;
        this.standard = c;
        this.$emit(EVENT_UPDATE_STANDARD_DATA, c);
      },

      destroySocket() {
        if (this.standardSocket) {
          this.standardSocket.close(this.listenersGroupId);
          this.standardSocket = null;
        }
      },
      createSocket(id) {
        this.destroySocket();

        const onStandardRefresh = (standardID) => {
          this.initData(standardID);
          this.$refs.injection.resetZoom();
        };
        const onStandard = (c) => {
          this.setStandard(c);
        };
        const onDevice = (d) => {
          this.device = d;
        };
        const onMethod = (m) => {
          this.method = m;
        };
        const onColumn = (c) => {
          this.column = c;
        };
        const onInjections = (i) => {
          this.injections = i;
        };

        const standardSocket = StandardSocket.start(id, null, (e) => {
          this.errorCode = e.code;
        });

        const listenersGroup = standardSocket.createEventListenersGroup();
        this.listenersGroupId = listenersGroup.id;

        listenersGroup.addEventListener(StandardSocketEvents.STANDARD_REFRESH, onStandardRefresh);
        listenersGroup.addEventListener(StandardSocketEvents.STANDARD, onStandard);
        listenersGroup.addEventListener(StandardSocketEvents.DEVICE, onDevice);
        listenersGroup.addEventListener(StandardSocketEvents.METHOD, onMethod);
        listenersGroup.addEventListener(StandardSocketEvents.COLUMN, onColumn);
        listenersGroup.addEventListener(StandardSocketEvents.INJECTIONS, onInjections);

        return standardSocket;
      },

      updateInjectionData() {
        if (this.injections[this.injectionID] != null) {
          this.injectionData = this.injections[this.injectionID];
        }
      },

      saveName(name) {
        this.showNotificationIfRpcError(() => this.standardSocket.update({ name }));
      },
      saveDescription() {
        this.showNotificationIfRpcError(() =>
          this.standardSocket.update({ description: this.standard.description }),
        );
      },

      archive() {
        this.showNotificationIfRpcError(() => this.standardSocket.archive());

        this.$emit(EVENT_ARCHIVE);
      },
      restore() {
        this.showNotificationIfRpcError(() => this.standardSocket.restore());
      },

      showInjection(iid) {
        this.$emit(EVENT_UPDATE_INJECTION_ID, iid);
      },

      updateCalibrationData() {
        if (
          this.standard.amount == null ||
          this.inputs.peak_detection_start === '' ||
          this.inputs.peak_detection_end === ''
        ) {
          this.notifyError('All fields should be filled!');
          return;
        }

        if (Number(this.inputs.peak_detection_start) >= Number(this.inputs.peak_detection_end)) {
          this.notifyError('Start time should be lover that end time!');
          return;
        }

        const peak_detection_start = this.inputs.peak_detection_start * 60;
        const peak_detection_end = this.inputs.peak_detection_end * 60;

        const sendRequest = () => {
          this.$refs.description?.save();

          if (this.canEditAmountAndPeakDetection) {
            this.showNotificationIfRpcError(async () => {
              await this.standardSocket.update({
                amount: this.standard.amount,
                unit: this.standard.unit,
                peak_detection_start,
                peak_detection_end,
              });
              this.$emit(EVENT_EDIT);
            });
          }

          this.isEditMode = false;
        };

        if (
          peak_detection_start !== this.standard?.sequence_compound.peak_detection_start ||
          peak_detection_end !== this.standard?.sequence_compound.peak_detection_end
        ) {
          this.$modal.show('dialog', {
            title: 'Are you sure?',
            text: `Peak search time will be updated for all standards that use the same compound`,
            buttons: [
              {
                title: 'Yes',
                default: true,
                handler: () => {
                  sendRequest();
                  this.$modal.hide('dialog');
                },
                class: 'vue-dialog-button blue-text',
              },
              {
                title: 'No',
                handler: () => {
                  this.$modal.hide('dialog');
                },
                class: 'vue-dialog-button red-text',
              },
            ],
          });
        } else {
          sendRequest();
        }
      },

      startEditing() {
        this.wasEditingStated = true;

        if (this.standard?.can_edit) {
          this.isEditMode = true;
        }
      },
      finishEditing() {
        if (this.isEditMode) {
          this.updateCalibrationData();
        }
      },

      updateInjectionPeak(injectionPeakInformation) {
        this.injections[injectionPeakInformation.id] = {
          ...this.injections[injectionPeakInformation.id],
          injection: {
            ...this.injections[injectionPeakInformation.id].injection,
            ...injectionPeakInformation,
          },
        };
        this.updateInjectionData();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .standard-component {
    &__field {
      display: block;
      margin: 10px 0;

      &--unit {
        max-width: 60px;
      }
    }

    &__warning-not-editable {
      margin-top: 10px;
      color: $color-text-warning;
    }
  }
</style>
