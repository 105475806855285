var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"32px 0 0 32px"}},[_c('ModalComponent',{attrs:{"scrollable":true,"width":"500","height":"auto","name":"preparation-log"}},[_c('div',{staticClass:"modal-content"},_vm._l((_vm.preparationLog),function(item,index){return _c('i',{key:index},[_vm._v(" "+_vm._s(index)+". "+_vm._s(new Date(item.time).toLocaleTimeString())+" - "+_vm._s(item.message)+" ")])}),0)]),(_vm.canCreateReport)?_c('SendReportModal',{attrs:{"sample":_vm.sample,"injection":_vm.injection,"method":_vm.method,"column":_vm.column,"owner":_vm.owner,"isShared":_vm.isShared,"sampleToken":_vm.sampleToken}}):_vm._e(),_c('div',{staticClass:"buttons--float-right"},[(!_vm.isShared)?[(_vm.injection.state === 'PRE' || _vm.injection.state === 'RUN')?_c('PopupHelper',{attrs:{"text":"Stops current Preparation or Injection step."}},[_c('button',{staticClass:"button--square-image",on:{"click":_vm.stop}},[_c('i',{staticClass:"material-icons material-icon--18"},[_vm._v("stop")]),_vm._v(" Stop ")])]):(_vm.injection.state === 'FIN')?_c('PopupHelper',{attrs:{"text":"Aborts any Post-Injection processes."}},[_c('button',{staticClass:"button--square-image",on:{"click":_vm.stop}},[_c('i',{staticClass:"material-icons material-icon--18"},[_vm._v("stop")]),_vm._v(" Abort ")])]):_vm._e(),(_vm.injection.archived)?_c('button',{staticClass:"button--square-image",on:{"click":_vm.restore}},[_c('i',{staticClass:"material-icons material-icon--18"},[_vm._v("restore")]),_vm._v(" Restore ")]):_vm._e()]:_vm._e(),(_vm.isEditMode)?_c('button',{staticClass:"button--square-image",on:{"click":_vm.finishEditing}},[_c('i',{staticClass:"material-icons material-icon--18"},[_vm._v("done")]),_vm._v(" Finish "),_c('br'),_vm._v(" editing ")]):_vm._e(),_c('MoreMenuComponent',{directives:[{name:"show",rawName:"v-show",value:(
        !_vm.injection.archived && !['PRE', 'RUN', 'FIN'].includes(_vm.injection.state) && !_vm.isEditMode
      ),expression:"\n        !injection.archived && !['PRE', 'RUN', 'FIN'].includes(injection.state) && !isEditMode\n      "}]},[(_vm.injection.can_edit)?_c('button',{staticClass:"button--square-image",on:{"click":_vm.startEditing}},[_c('i',{staticClass:"material-icons material-icon--18"},[_vm._v("edit")]),_vm._v(" Edit ")]):_vm._e(),(
          _vm.canCreateReport &&
          (_vm.injection.state === 'WOR' || _vm.injection.state === 'REA' || _vm.injection.state === 'FAI')
        )?_c('button',{staticClass:"button--square-image",on:{"click":_vm.sendPdf}},[_c('i',{staticClass:"material-icons material-icon--18"},[_vm._v("picture_as_pdf")]),_vm._v(" Report ")]):_vm._e(),(_vm.injection.can_archive && _vm.injection.state !== 'R')?_c('button',{staticClass:"button--square-image",on:{"click":_vm.archive}},[_c('i',{staticClass:"material-icons material-icon--18"},[_vm._v("delete")]),_vm._v(" Archive ")]):_vm._e(),(_vm.preparationLog != null)?_c('button',{staticClass:"button--square-image",on:{"click":_vm.viewPreparationLog}},[_c('i',{staticClass:"material-icons material-icon--18"},[_vm._v("assignment")]),_vm._v(" Log ")]):_vm._e()])],2),_c('h2',{staticStyle:{"display":"block","padding":"0","margin":"0 0 8px 0","color":"black","white-space":"nowrap"}},[_c('i',{staticClass:"submain"},[_vm._v("show_chart")]),_vm._v(" "+_vm._s(_vm.injection.name)+" "),(_vm.loading)?_c('loading-component',{staticStyle:{"display":"inline-block","width":"16px","margin":"0"},attrs:{"label":null}}):_vm._e(),(_vm.injection != null && _vm.injection.state === 'RUN')?_c('progress-component',{staticStyle:{"display":"inline-block","vertical-align":"bottom","margin-left":"32px","margin-bottom":"4px"},attrs:{"label":_vm.injection.progress_time_str,"progress":_vm.injection.progress}}):_vm._e()],1),_c('div',{staticStyle:{"min-height":"24px","vertical-align":"bottom","display":"block"}},[(_vm.label)?_c('span',{staticClass:"mr-3",staticStyle:{"display":"inline-block","color":"black"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_c('span',{staticClass:"mr-3",staticStyle:{"display":"inline-block","color":"black"}},[_vm._v(" Sample injection ")]),_c('span',{staticClass:"span-date mr-3",domProps:{"innerHTML":_vm._s(new Date(_vm.injection.date_started).toLocaleDateString())}}),_c('span',{staticClass:"span-date mr-3",domProps:{"innerHTML":_vm._s(_vm.formatTime(_vm.injection.date_started))}}),(
        (_vm.injection.state !== 'REA' && _vm.injection.error_str != null) ||
        _vm.injection.error_str === 'Stopped by user' ||
        _vm.injection.error_str === 'Cancelled by server'
      )?_c('span',{staticClass:"mr-3 text-red",staticStyle:{"white-space":"nowrap"}},[_c('i',{staticClass:"material-icons material-icon--16"},[_vm._v("error")]),_vm._v(" "+_vm._s(_vm.injection.error_str)+" ")]):_c('StatesForEntity',{staticClass:"mr-3",attrs:{"state":_vm.injection.state,"data-test-id":"injection-status"}}),(_vm.injection.archived)?_c('StateArchived'):_vm._e()],1),_c('model-textarea-component',{ref:"description",staticStyle:{"margin":"16px 32px 0 0"},attrs:{"isEditMode":_vm.isEditMode,"canEdit":!_vm.isShared && _vm.injection.can_edit && _vm.editable,"label":'Description'},on:{"click":_vm.startEditing},model:{value:(_vm.injection.description),callback:function ($$v) {_vm.$set(_vm.injection, "description", $$v)},expression:"injection.description"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }