<template>
  <InputTypeNumber
    v-bind="$attrs"
    :value="valueFloated"
    :step="step"
    :max="maxTime"
    :isFloat="true"
    :isDisabled="isDisabled"
    min="0"
    :title="errorMessage"
    :isValid="isUnique"
    class="input-time"
    :isChangeOnBlur="true"
    @change="change"
  />
</template>

<script>
  import InputTypeNumber from '@/uikitProject/tables/shared/vueTableCell/private/inputs/private/InputTypeNumber';

  export default {
    name: 'InputTime',

    components: {
      InputTypeNumber,
    },

    props: {
      value: {
        type: Number,
        required: true,
      },
      isUnique: {
        type: Boolean,
        required: true,
      },
      step: {
        type: Number,
        required: true,
      },
      maxTime: {
        type: Number,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      valueFloated() {
        return this.value.toFixed(2).replace(/\.?0*$/, '');
      },
      errorMessage() {
        if (this.valueFloated > this.maxTime) {
          return 'Value must be less than or equal to run time';
        }
        return null;
      },
    },

    methods: {
      change(value) {
        this.$emit('change', value);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .input-time {
    text-align: right;
  }
</style>
