<template>
  <div class="chromatogram-control">
    <Panel>
      <template v-if="hasGradient">
        <Item title="Gradient">
          <Checkbox
            :checked="config.isShowGradient"
            :selectedColor="COLORS.measurements.other"
            @model="editConfig({ isShowGradient: $event })"
          >
            <span class="chromatogram-control__label-gradient">G</span>
          </Checkbox>
        </Item>
        <Divider />
      </template>
      <template v-if="pressure">
        <Item title="Pressure">
          <Checkbox
            :checked="config.isShowPressure"
            :class="{ 'chromatogram-control__checkbox--warning': pressure.isOutOfBounds }"
            :selectedColor="COLORS.measurements.other"
            @model="editConfig({ isShowPressure: $event })"
          >
            <template v-if="isInjectionRunning && pressure.current != null"
              >{{ pressure.current }}psi</template
            >
            <span v-else class="chromatogram-control__label-pressure">P</span>
          </Checkbox>
        </Item>
        <Divider />
      </template>
      <Item title="Channels" :stylesTitle="{ left: 'min(80px, 50%)' }">
        <Measurements
          :measurementsSelected="config.measurementsSelectedIds"
          :measurements="measurements"
          :canSelectMultiple="true"
          @model="editConfig({ measurementsSelectedIds: $event })"
        />
      </Item>
      <template v-if="!isInjectionRunning && hasPostprocessing">
        <Divider />

        <Item title="Postprocessing" class="chromatogram-control__item--noise-reduction">
          <Checkbox
            :checked="config.isApplyPostprocessing"
            :selectedColor="COLORS.measurements.other"
            @model="editConfig({ isApplyPostprocessing: $event })"
          >
            <IconMaterial title="query_stats" size="18" />
          </Checkbox>
        </Item>
      </template>
    </Panel>
  </div>
</template>

<script>
  import Checkbox from '../../../injections/measurements/switchers/Checkbox';
  import Measurements from '../../../injections/measurements/vueMeasurements/Measurements';
  import Item from '../shared/Item';
  import Divider from '../shared/Divider';
  import Panel from '@/uikitProject/chromatogram/panels/shared/Panel';
  import IconMaterial from '@/uikitBase/icons/IconMaterial.vue';
  import { COLORS } from '@/uikitProject/charts/new/private/constants';

  const EVENT_MODEL = 'model';

  export default {
    name: 'ChromatogramControl',

    components: {
      IconMaterial,
      Panel,
      Divider,
      Item,
      Measurements,
      Checkbox,
    },

    model: {
      prop: 'config',
      event: EVENT_MODEL,
    },

    props: {
      /*
       * isShowGradient: boolean,
       * isShowPressure: boolean,
       * isShowBaseline: boolean,
       * isApplyPostprocessing: boolean,
       * measurementsSelectedIds: number[]
       * */
      config: {
        type: Object,
        required: true,
      },
      measurements: {
        type: Object,
        required: true,
      },
      pressure: {
        type: Object,
      },
      isInjectionRunning: {
        type: Boolean,
        required: true,
      },
      hasGradient: {
        type: Boolean,
        required: true,
      },
      hasPostprocessing: {
        type: Boolean,
        required: true,
      },
    },

    data: () => ({
      COLORS,
    }),

    computed: {
      selectedMeasurements() {
        return this.config.measurementsSelectedIds
          .map((id) => this.measurements[id])
          .filter(Boolean);
      },
    },

    methods: {
      editConfig(data) {
        this.$emit(EVENT_MODEL, { ...this.config, ...data });
      },

      getName(measurement) {
        if (measurement.name) return measurement.name;

        switch (measurement.type) {
          case 'AD':
            return 'Analog signal';
          case 'AD2':
            return 'Analog signal2';
          default:
            return measurement.type;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chromatogram-control {
    flex: none;
    display: inline-flex;
    align-items: center;
    color: $color-text-primary;
    font-size: $size-xxs;
    font-weight: $weight-medium;

    &__item {
      &--baseline,
      &--noise-reduction {
        &:hover {
          .chromatogram-control__label-baseline::before,
          .chromatogram-control__label-noise-reduction::before {
            background-color: #e2e2e2;
          }
        }

        &:active {
          .chromatogram-control__label-baseline::before,
          .chromatogram-control__label-noise-reduction::before {
            background-color: #d3d3d3;
          }
        }
      }
    }

    &__label-gradient,
    &__label-pressure {
      font-weight: $weight-bold;
      font-size: $size-xs;
    }

    &__label-baseline {
      font-weight: $weight-bold;
      font-size: $size-xs;
    }

    &__checkbox {
      &--warning {
        animation: warning 1s infinite alternate;
        font-weight: $weight-bold;
      }
    }

    &__label-noise-reduction {
      padding: 0 6px;
    }

    &__icon-noise-reduction {
      height: 23px;
    }
  }

  @keyframes warning {
    0% {
      color: #000;
    }

    100% {
      color: $color-text-danger;
    }
  }
</style>
