import { render, staticRenderFns } from "./MethodsPage.vue?vue&type=template&id=512b1064&scoped=true&"
import script from "./MethodsPage.vue?vue&type=script&lang=js&"
export * from "./MethodsPage.vue?vue&type=script&lang=js&"
import style0 from "./MethodsPage.vue?vue&type=style&index=0&id=512b1064&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "512b1064",
  null
  
)

export default component.exports