import NotificationSocket, {
  NotificationSocketEvents,
  ResponseActionNames,
  ResponseActions,
  SocketResponseActionTaken,
  SocketResponseError,
  SocketResponseNotifyNew,
  SocketResponseRedirect,
} from '../api/sockets/NotificationSocket';
import { Callback, Dict, Nullable } from '../types/utility';
import { getErrorMessage } from '@/utils/errorHelpers';

export enum ToastMessages {
  ON_USE_COLUMN = 'New column',
  ON_USE_APPLICATION = 'New application',
  ON_USE_STANDARD = 'New standard',
  ON_USE_SOLVENT = 'New solvent',
  ON_ERROR = 'Error',
}

export enum ToastActionNames {
  USE = 'Use',
  DISMISS = 'Dismiss',
  ADD_TO_SEQUENCE = 'Add to sequence',
  ADD_TO_STANDARD = 'Add to standard',
}

export enum Icons {
  DISMISS = 'close',
  STAR = 'star',
}

export type ToastAction = {
  onClick(): void;
  text?: ToastActionNames | string;
  icon?: Icons;
  class?: string;
};

export type ToastManager = {
  showWithActions(
    message: ToastMessages | string,
    options: {
      actions: ToastAction[];
      onNoActionDetect: Callback<never>;
      duration?: number;
      icon?: Icons;
    },
    id: number,
  ): void;
  showError(message: string): void;
  hide(id: number): void;
};

type UiInteractorNames =
  | 'useColumn'
  | 'useApplication'
  | 'useSolvent'
  | 'addStandardToSequence'
  | 'addStandardToStandard'
  | 'redirect'
  | 'error';

type UiGetterNames = 'getActionNameSequence' | 'getActionNameStandard';

// To bind ui interactions
type ResponseAction = {
  action: ResponseActionNames;
  data?: Dict;
};
export type UiInteractors = {
  [key in UiInteractorNames]?: (data: Dict) => Nullable<ResponseAction>;
} &
  {
    [key in UiGetterNames]?: (data: Dict) => string;
  };

export default class NotificationService {
  private notificationSocket: NotificationSocket;

  constructor(
    email: string,
    private toastManager: ToastManager,
    private uiInteractors: UiInteractors,
  ) {
    this.notificationSocket = NotificationSocket.start(email);

    this.initListeners();
  }

  public disconnect() {
    this.notificationSocket.close();
  }

  private initListeners() {
    this.notificationSocket.addEventListener(NotificationSocketEvents.USE_COLUMN, (e) =>
      this.onUseBase(e, ToastMessages.ON_USE_COLUMN, 'useColumn'),
    );
    this.notificationSocket.addEventListener(NotificationSocketEvents.USE_APPLICATION, (e) =>
      this.onUseBase(e, ToastMessages.ON_USE_APPLICATION, 'useApplication'),
    );
    this.notificationSocket.addEventListener(NotificationSocketEvents.USE_SOLVENT, (e) =>
      this.onUseBase(e, ToastMessages.ON_USE_SOLVENT, 'useSolvent'),
    );

    this.notificationSocket.addEventListener(NotificationSocketEvents.USE_STANDARD, (e) =>
      this.onUseStandard(e),
    );

    this.notificationSocket.addEventListener(NotificationSocketEvents.ACTION_TAKEN, (e) =>
      this.onActionTaken(e),
    );
    this.notificationSocket.addEventListener(NotificationSocketEvents.REDIRECT, (e) =>
      this.onRedirect(e),
    );
    this.notificationSocket.addEventListener(NotificationSocketEvents.ERROR, (e) =>
      this.onError(e),
    );
  }

  private onUseBase(
    data: SocketResponseNotifyNew,
    message: ToastMessages,
    interactorName: UiInteractorNames,
  ): void {
    this.toastManager.showWithActions(
      message,
      {
        actions: this.getBaseActions(data, interactorName),
        onNoActionDetect: () => this.sendDismissAction(data.id),
      },
      data.id,
    );
  }

  private onUseStandard(data: SocketResponseNotifyNew) {
    const handleInteractorResponse = (response: Nullable<ResponseAction>, id: number) => {
      if (response) {
        const { action, data } = response;
        this.notificationSocket.sendResponse({
          id,
          action,
          data,
        });
      }
    };

    const actions: ToastAction[] = [
      {
        text:
          this.uiInteractors['getActionNameStandard']?.(data) ?? ToastActionNames.ADD_TO_STANDARD,
        onClick: () => {
          const interactorResponse = this.uiInteractors['addStandardToStandard']?.(data);
          handleInteractorResponse(interactorResponse, data.id);

          this.toastManager.hide(data.id);
        },
      },
    ];

    if (data.data.sequence?.id) {
      const actionAddToSequence = {
        text:
          this.uiInteractors['getActionNameSequence']?.(data) ?? ToastActionNames.ADD_TO_SEQUENCE,
        onClick: () => {
          const interactorResponse = this.uiInteractors['addStandardToSequence']?.(data);
          handleInteractorResponse(interactorResponse, data.id);

          this.toastManager.hide(data.id);
        },
      };

      actions.push(actionAddToSequence);
    }

    const actionDismiss = {
      icon: Icons.DISMISS,
      class: 'dismiss',
      onClick: () => this.sendDismissAction(data.id),
    };

    actions.push(actionDismiss);

    this.toastManager.showWithActions(
      data.data.standard_name ?? 'Standard',
      {
        actions,
        icon: Icons.STAR,
        onNoActionDetect: () => this.sendDismissAction(data.id),
      },
      data.id,
    );
  }

  private onActionTaken({ id }: SocketResponseActionTaken) {
    this.toastManager.hide(id);
  }

  private onRedirect(data: SocketResponseRedirect) {
    this.uiInteractors['redirect']?.(data);
  }

  private onError(data: SocketResponseError) {
    const error = getErrorMessage(data.code) ?? 'Unknown error';
    this.toastManager.showError(`${ToastMessages.ON_ERROR}: ${error}`);
    this.uiInteractors['error']?.(data);
  }

  private getBaseActions(
    { id, data }: SocketResponseNotifyNew,
    uiInteractorName: UiInteractorNames,
  ) {
    return [
      {
        text: ToastActionNames.USE,
        onClick: () => {
          const dataFromInteractor = this.uiInteractors[uiInteractorName]?.(data);

          if (dataFromInteractor) {
            const { action, data } = dataFromInteractor;
            this.notificationSocket.sendResponse({ id, action, data });
          } else {
            this.notificationSocket.sendResponse({ id, action: ResponseActions.USE });
          }

          this.toastManager.hide(id);
        },
      },
      {
        icon: Icons.DISMISS,
        class: 'dismiss',
        onClick: () => this.sendDismissAction(id),
      },
    ];
  }

  private sendDismissAction(id) {
    this.notificationSocket.sendResponse({
      id,
      action: ResponseActions.DISMISS,
    });
    this.toastManager.hide(id);
  }
}
