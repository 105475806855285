<template>
  <Input
    ref="input"
    v-model="_value"
    :placeholder="placeholder"
    :hasClearBtn="true"
    v-bind="$attrs"
    class="input-search"
    v-on="$listeners"
  >
    <template #iconLeft>
      <IconMaterial title="search" class="input-search__icon-search" />
    </template>
  </Input>
</template>

<script>
  import Input from '@/uikitBase/inputs/Input';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  const EVENT_MODEL = 'model';

  export default {
    name: 'InputSearch',

    components: {
      IconMaterial,
      Input,
    },

    model: {
      props: 'value',
      event: EVENT_MODEL,
    },

    props: {
      value: {
        type: String,
        required: true,
        default: '',
      },
      placeholder: {
        type: String,
        default: 'Search',
      },
    },

    computed: {
      _value: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit(EVENT_MODEL, value);
        },
      },
    },

    methods: {
      focus() {
        this.$refs.input.focus();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .input-search {
    width: 170px;

    @media (max-width: 640px) {
      width: 132px;
    }

    &__icon-search {
      color: $color-text-third;
      font-size: $size-sm;
    }
  }
</style>
