<template>
  <DecoratorInput
    label="Device"
    :isOptional="!isRequired"
    iconSize="sm"
    iconTitle="input"
    :messageRequired="messageRequired"
    class="device-selector"
  >
    <div v-if="isLoading" class="device-selector__no-data">
      <DataStateComponent :updating="true" class="device-selector__state" />
    </div>
    <div v-else-if="devicesSorted.length">
      <SelectDeviceComponent
        v-if="deviceSelected || !isRequired"
        :device="deviceSelected"
        :devices="devicesSorted"
        :required="isRequired"
        :readonly="isReadonly"
        @change="setDevice"
      />
      <PrList
        v-else
        :devices="devicesSorted"
        :isLoading="isLoading"
        class="device-selector__list"
        @select="setDevice"
      />
    </div>
    <div v-else class="device-selector__no-data">
      <IconMaterial title="warning" size="16" />
      No devices, <router-link :to="{ name: 'devices' }">click to configure</router-link>
    </div>
  </DecoratorInput>
</template>

<script>
  import DecoratorInput from '@/uikitProject/decorators/DecoratorInput';
  import PrList from '@/uikitProject/devices/selector/components/PrList';
  import SelectDeviceComponent from 'components/element/SelectDeviceComponent';
  import DataStateComponent from 'components/element/DataStateComponent';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  import _ from 'lodash';

  const EVENT_UPDATE_DEVICE_SELECTED = 'update:deviceSelected';

  export default {
    name: 'DeviceSelector',

    components: {
      IconMaterial,
      DataStateComponent,
      DecoratorInput,
      PrList,
      SelectDeviceComponent,
    },

    model: {
      prop: 'deviceSelected',
      event: EVENT_UPDATE_DEVICE_SELECTED,
    },

    props: {
      devices: {
        type: Array,
        required: true,
      },
      deviceSelected: {
        type: Object,
        default: undefined,
      },
      isRequired: {
        type: Boolean,
        default: false,
      },
      isReadonly: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      messageRequired() {
        return !this.deviceSelected ? 'select a device' : '';
      },

      devicesSorted() {
        return _.orderBy(this.devices, ['isOnline', 'name'], ['desc']);
      },
    },

    watch: {
      devicesSorted: {
        handler() {
          this.initDefaultDevice();
        },
        immediate: true,
      },
    },

    methods: {
      setDevice(device) {
        this.$emit(EVENT_UPDATE_DEVICE_SELECTED, device);
      },

      initDefaultDevice() {
        if (this.devicesSorted.length === 1) {
          this.setDevice(this.devicesSorted[0]);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .device-selector {
    &__list {
      max-height: 200px;
      overflow-y: auto;
    }

    &__no-data {
      background: $color-bg-input;
      padding: 8px 12px 7px;
    }

    &__state {
      margin: 0;
    }
  }
</style>
