<template>
  <div>
    <slot :data="measurementResults" />
  </div>
</template>

<script>
  import MeasurementSocket, { MeasurementSocketEvents } from 'api/sockets/MeasurementSocket';
  import SharedMeasurementSocket, {
    SharedMeasurementSocketEvents,
  } from 'api/sockets/SharedMeasurementSocket';
  import { consoleHelpers } from 'utils/logHelpers';

  const EVENT_UPDATE_MEASUREMENT = 'updateMeasurement';
  const EVENT_CREATE = 'create';
  const EVENT_DESTROY = 'destroy';

  export default {
    name: 'PrDataAdapter',

    props: {
      measurements: {
        type: Array,
        required: true,
      },
      sampleToken: {
        type: String,
        default: null,
      },
      hasLiveUpdate: {
        type: Boolean,
        default: true,
      },
      doSkipDataFetching: {
        type: Boolean,
      },
    },

    data: () => ({
      activeSockets: [],
      measurementResults: [],
    }),

    watch: {
      measurements: {
        handler(measurements, measurementsOld) {
          if (this.doSkipDataFetching) {
            this.measurementResults = this.measurements;
            return;
          }

          const initMeasurements = () => {
            if (measurementsOld) {
              const measurementsOldDict = this.measurementResults.reduce(
                (dictionary, measurement) => {
                  dictionary.set(measurement.id, measurement);
                  return dictionary;
                },
                new Map(),
              );

              return measurements.map((measurement) => {
                const measurementOld = measurementsOldDict.get(measurement.id);
                if (measurementOld) {
                  return {
                    ...measurement,
                    data:
                      (measurementOld.data?.length > measurement.data?.length
                        ? measurementOld.data
                        : measurement.data) ?? [],
                  };
                }
                return {
                  ...measurement,
                  data: measurement.data ?? [],
                };
              });
            }
            return measurements.map((measurement) => ({
              ...measurement,
              data: measurement.data ?? [],
            }));
          };

          this.measurementResults = initMeasurements();
          this.initSockets();
        },
        immediate: true,
      },
    },

    created() {
      this.$emit(EVENT_CREATE);
    },

    beforeDestroy() {
      this.destroySockets();
      this.$emit(EVENT_DESTROY);
    },

    methods: {
      initSockets() {
        const socketIdsShouldBeInit = this.measurements.map(({ id }) => id);
        consoleHelpers.warn('Socket ids', socketIdsShouldBeInit);
        const socketsWithoutClosed = this.activeSockets.filter((socket) => {
          if (socketIdsShouldBeInit.includes(socket.id)) return true;
          socket.close();
          return false;
        });
        consoleHelpers.warn('Sockets without closed', socketsWithoutClosed);

        this.activeSockets = !this.hasLiveUpdate
          ? []
          : this.measurements.map((measurement) => {
              const alreadyConnectedSocket = socketsWithoutClosed.find(
                (socket) => socket.id === measurement.id,
              );
              return alreadyConnectedSocket ?? this.createSocket(measurement.id);
            });
      },

      createSocket(id) {
        const onMeasurement = (measurement) => {
          if (!measurement.data) return;

          this.measurementResults = this.measurementResults.map((_measurement) =>
            _measurement.id === measurement.id ? measurement : _measurement,
          );

          this.$emit(EVENT_UPDATE_MEASUREMENT, measurement);
        };

        const onConnection = async (connection) => {
          const measurement = await connection.get();
          onMeasurement(measurement);
        };

        const socket = this.sampleToken
          ? SharedMeasurementSocket.start(id, onConnection, {
              shareToken: this.sampleToken,
            })
          : MeasurementSocket.start(id, onConnection);

        const socketEvents = this.sampleToken
          ? SharedMeasurementSocketEvents
          : MeasurementSocketEvents;

        // TODO remove listeners on close
        socket.addEventListener(socketEvents.MEASUREMENT, onMeasurement);

        return socket;
      },
      destroySockets() {
        if (!this.hasLiveUpdate) {
          return;
        }

        this.activeSockets.forEach((socket) => socket.close());
      },
    },
  };
</script>
