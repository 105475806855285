import SocketCentrifugeBase from '@/api/sockets/SocketCentrifugeBase';
import { Callback, Dict } from '@/types/utility';

export enum SampleInjectionSocketEvents {
  INJECTION = 'injection',
  PROGRESS = 'injection.progress',
}

enum SampleInjectionSocketRPC {
  GET = 'sample_injection.get',
  RUN = 'sample_injection.run',
  STOP = 'sample_injection.stop',
  CANCEL = 'sample_injection.cancel',
  RESTART = 'sample_injection.restart',
  SEEN = 'sample_injection.seen',
  ARCHIVE = 'sample_injection.archive',
  RESTORE = 'sample_injection.restore',
  PEAK_SELECT = 'sample_injection.peakSelect',
  UPDATE = 'sample_injection.update',
}

export default class SampleInjectionSocket extends SocketCentrifugeBase<
  SampleInjectionSocketEvents,
  SampleInjectionSocketRPC
> {
  private static connections: Dict<SampleInjectionSocket> = {};

  protected listeners: Record<SampleInjectionSocketEvents, Callback[]> = {
    [SampleInjectionSocketEvents.INJECTION]: [],
    [SampleInjectionSocketEvents.PROGRESS]: [],
  };

  private constructor(
    public id: number,
    onConnect?: Callback<
      [SocketCentrifugeBase<SampleInjectionSocketEvents, SampleInjectionSocketRPC>]
    >,
  ) {
    super(`personal:sample-injection-${id}`, {
      handleEvents: ({ data }) => {
        switch (data.event) {
          case SampleInjectionSocketEvents.INJECTION: {
            this.callListeners(SampleInjectionSocketEvents.INJECTION, data.injection);
            break;
          }
          case SampleInjectionSocketEvents.PROGRESS: {
            this.callListeners(SampleInjectionSocketEvents.PROGRESS, data.injection);
            break;
          }
        }
      },
      onConnect,
    });

    this.id = id;

    this.dataAddedToEveryRequest = { injection_id: this.id };
  }

  public static start(
    id: number,
    onConnect?: Callback<
      [SocketCentrifugeBase<SampleInjectionSocketEvents, SampleInjectionSocketRPC>]
    >,
  ) {
    const connection = SampleInjectionSocket.connections[id];
    if (connection) {
      connection.addSession();

      onConnect?.(connection);
      return connection;
    }

    SampleInjectionSocket.connections[id] = new SampleInjectionSocket(id, onConnect);

    return SampleInjectionSocket.connections[id];
  }

  public close(listenersGroupId: string): boolean {
    const isClosed = super.close(listenersGroupId);

    if (isClosed) {
      delete SampleInjectionSocket.connections[this.id];
    }

    return isClosed;
  }

  async get() {
    const data = await this.sendNamedRPC(SampleInjectionSocketRPC.GET);
    return data.injection;
  }

  run() {
    return this.sendNamedRPC(SampleInjectionSocketRPC.RUN);
  }

  stop() {
    return this.sendNamedRPC(SampleInjectionSocketRPC.STOP);
  }

  cancel() {
    return this.sendNamedRPC(SampleInjectionSocketRPC.CANCEL);
  }

  restart() {
    return this.sendNamedRPC(SampleInjectionSocketRPC.RESTART);
  }

  seen() {
    return this.sendNamedRPC(SampleInjectionSocketRPC.SEEN);
  }

  archive() {
    return this.sendNamedRPC(SampleInjectionSocketRPC.ARCHIVE);
  }

  restore() {
    return this.sendNamedRPC(SampleInjectionSocketRPC.RESTORE);
  }

  peakSelect(peakID) {
    return this.sendNamedRPC(SampleInjectionSocketRPC.PEAK_SELECT, { id: peakID });
  }

  update(injection) {
    return this.sendNamedRPC(SampleInjectionSocketRPC.UPDATE, { injection });
  }
}
