<template>
  <input
    ref="input"
    :value="value"
    :disabled="isDisabled"
    class="cell-input"
    :class="classInvalid"
    @input="handleKeypress"
    @change="handleBlur"
    @keydown.enter.stop="blur"
    @focus="selectValue"
    @blur="$emit('blur')"
    @dragstart.prevent
  />
</template>

<script>
  export default {
    name: 'StyledInput',

    model: {
      prop: 'value',
      event: 'change',
    },

    props: {
      value: {
        type: [String, Number],
        required: true,
      },
      isValid: {
        type: Boolean,
        default: true,
      },
      isChangeOnBlur: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      classInvalid() {
        return !this.isValid && 'cell-input--invalid';
      },
    },

    methods: {
      handleKeypress(e) {
        if (!this.isChangeOnBlur) {
          this.$emit('change', e.target.value);
        }
      },
      handleBlur(e) {
        this.$emit('change', e.target.value);
      },

      selectValue() {
        // setTimeout to make selection more stable
        setTimeout(() => {
          this.$refs.input?.select();
        }, 100);
      },
      blur() {
        this.$refs.input.blur();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .cell-input {
    width: 100%;
    height: 34px;
    outline: none;
    color: inherit;
    padding: 0 5px !important;
    transition: border-color 67ms;
    border: none;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    text-indent: 6px;

    &:focus {
      border-color: $color-text-primary;
      border-radius: 4px;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:invalid,
    &--invalid {
      color: $color-text-danger;
      box-shadow: none;
      -webkit-text-fill-color: $color-text-danger !important;
    }
  }
</style>
