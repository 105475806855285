import { makeGraphqlRequestCloud } from 'api/graphql/graphQLClient';
import { Columns } from '@/graphql/cloud/columns/queries/Columns.graphql';
import { Column } from '@/graphql/cloud/columns/queries/Column.graphql';

export const apiColumns = {
  async getColumns({ search = '', isArchived = false, offset = 0, limit }) {
    const { columns, columns_aggregate } = await makeGraphqlRequestCloud(Columns, {
      search: `%${search ?? ''}%`,
      isArchived,
      offset,
      limit,
    });

    return {
      columns,
      count: columns_aggregate.aggregate.count,
    };
  },
  async getColumn(id) {
    return (await makeGraphqlRequestCloud(Column, { id })).column;
  },
};
