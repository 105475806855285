<template>
  <div>
    <MQ mq="desktop">
      <DesktopBtnSelectVial v-bind="$props" v-on="$listeners" #default="slotData">
        <slot v-bind="slotData" />
      </DesktopBtnSelectVial>
    </MQ>
    <MQ mq="mobile">
      <MobileBtnSelectVial v-bind="$props" v-on="$listeners" #default="slotData">
        <slot :isMobile="true" v-bind="slotData" />
      </MobileBtnSelectVial>
    </MQ>
  </div>
</template>

<script>
  import { props } from '@/uikitProject/vials/vueBtnSelectVial/shared/config.ts';

  export default {
    name: 'BtnSelectVial',

    components: {
      DesktopBtnSelectVial: () => import('./desktop/BtnSelectVial.desktop'),
      MobileBtnSelectVial: () => import('./mobile/BtnSelectVial.mobile'),
    },

    props,
  };
</script>
