<template>
  <ModalComponent :name="modalName" :width="320" doSubmitOnEnter @opened="initForm" @closed="reset">
    <div class="modal-content modal-content--no-padding wifi-auth-modal">
      <div class="modal-header"><h4>Connect to Wi-Fi</h4></div>

      <div class="modal-body">
        <ValidationObserver
          #default="{ invalid, isSubmitting }"
          tag="form"
          autocomplete="off"
          @submit.prevent="submit"
        >
          <input style="display: none" autocomplete="off" />

          <div v-if="network">
            Enter a password for "<span class="wifi-auth-modal__network-name">{{ network.id }}</span
            >"
          </div>
          <InputForm
            v-else
            ref="inputName"
            v-model="inputs.id"
            title="Network name"
            rules="required"
            aria-autocomplete="none"
            autocomplete="new-password"
            class="wifi-auth-modal__input"
          />

          <br />

          <Select
            v-if="network == null"
            v-model="inputs.security"
            :items="securityOptions"
            labelProp="name"
            :isTransparent="false"
            :hasSearch="false"
            title="Security"
            placeholderSearch="Security"
            titleBottomSheet="Security"
            padding="md"
            class="wifi-auth-modal__input"
          />

          <InputForm
            v-if="inputs.security.id === 'wpa'"
            ref="inputPassword"
            v-model="inputs.password"
            title="Password"
            type="password"
            rules="required|min:8"
            autocomplete="new-password"
            class="wifi-auth-modal__input"
          />

          <div>
            <Btn
              borderRadius="sm"
              type="primary"
              class="wifi-auth-modal__btn-connect"
              :disabled="invalid"
              :isLoading="isSubmitting"
              :isSubmit="true"
            >
              Connect
            </Btn>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </ModalComponent>
</template>

<script>
  import ModalComponent from 'components/element/ModalComponent.vue';
  import InputForm from '@/uikitProject/inputs/InputForm.vue';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import { wifiAdapterCommands } from '@/constants/hardware/commands';
  import Select from '@/uikitBase/selects/vueSelect/Select.vue';
  import { WIFI_SECURITY_OPTIONS } from '@/constants/network/wifiSecurity';

  const MODAL_NAME = 'wifiAuth';

  const EVENT_CONNECT = 'connect';

  const getDefaultInputValues = () => ({
    id: '',
    password: '',
    security: WIFI_SECURITY_OPTIONS.WPA,
  });

  export default {
    name: 'WifiAuthModal',

    components: {
      Select,
      Btn,
      InputForm,
      ModalComponent,
    },

    props: {
      deviceSocket: {
        type: Object,
        required: true,
      },
      network: {
        type: Object,
      },
    },

    data: () => ({
      modalName: MODAL_NAME,

      inputs: getDefaultInputValues(),
      error: null,

      securityOptions: Object.values(WIFI_SECURITY_OPTIONS),
    }),

    methods: {
      show() {
        this.$modal.show(this.modalName);
      },
      close() {
        this.$modal.hide(this.modalName);
      },

      submit() {
        return this.showNotificationIfRpcError(async () => {
          await this.deviceSocket.command('wifi-adapter', wifiAdapterCommands.CONNECT, {
            ssid: this.inputs.id,
            password:
              this.inputs.security === WIFI_SECURITY_OPTIONS.WPA ? this.inputs.password : '',
          });

          this.$emit(EVENT_CONNECT);
          this.close();
        });
      },

      reset() {
        this.inputs = getDefaultInputValues();
      },

      initForm() {
        if (this.network) {
          this.inputs.id = this.network.id;
          this.$refs.inputPassword.focus();
        } else {
          this.$refs.inputName.focus();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .wifi-auth-modal {
    &__network-name {
      font-weight: $weight-bold;
    }

    &__input {
      width: 100%;
      margin-bottom: 10px;
    }

    &__btn-connect {
      width: 100%;
      margin-top: 10px;
    }
  }
</style>
