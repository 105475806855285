import { makeGraphqlRequestCloud } from 'api/graphql/graphQLClient';
import { PostprocessingTemplates } from '@/graphql/cloud/postprocessingTemplates/queries/PostprocessingTemplates.graphql';
import { SetFavoriteStatus } from '@/graphql/cloud/postprocessingTemplates/mutations/SetFavoriteStatus.graphql';
import { SetArchivingStatus } from '@/graphql/cloud/postprocessingTemplates/mutations/SetArchivingStatus.graphql';
import { CreateOrUpdatePostprocessingTemplate } from '@/graphql/cloud/postprocessingTemplates/mutations/CreateOrUpdatePostprocessingTemplate.graphql';
import { parsePostprocessingTemplate } from '@/utils/postprocessingTemplateHelpers';
import { convertNullValuesToUndefined } from '@/utils/objectHelpers';

export const apiPostprocessingTemplates = {
  async createOrUpdatePostprocessingTemplate(postprocessingTemplatePayload) {
    const {
      algorithm_base,
      algorithm_binj,
      algorithm_peak,
      detection_time_start_sec,
      detection_time_end_sec,
      blank_injection_measurement_ulid,
      blank_injection_measurement_id,
      peak_detection_min_height,
      peak_detection_min_distance,
      peak_detection_min_width,
      peak_detection_min_prominence,
      peak_integration_quality_threshold,
      baseline_correction_anchor_point,
      baseline_correction_points,
      ..._postprocessingTemplatePayload
    } = postprocessingTemplatePayload;

    const {
      postprocessingTemplate: {
        params_pdet,
        params_peak,
        params_base,
        params_binj,
        params_nois,
        params_dtim,
        ...postprocessingTemplate
      },
    } = await makeGraphqlRequestCloud(CreateOrUpdatePostprocessingTemplate, {
      ..._postprocessingTemplatePayload,
      algorithm_base,
      algorithm_binj,
      algorithm_peak,
      params_pdet: convertNullValuesToUndefined({
        peak_detection_min_distance,
        peak_detection_min_width,
        peak_detection_min_prominence,
        peak_detection_min_height,
      }),
      params_peak:
        algorithm_peak === 'none'
          ? {}
          : convertNullValuesToUndefined({
              peak_integration_quality_threshold,
            }),
      params_base:
        algorithm_base === 'manual'
          ? convertNullValuesToUndefined({
              baseline_correction_points,
            })
          : algorithm_base === 'horizontal_correction'
          ? convertNullValuesToUndefined({
              baseline_correction_anchor_point,
            })
          : {},
      params_binj:
        algorithm_binj === 'none'
          ? {}
          : convertNullValuesToUndefined({
              blank_injection_measurement_ulid,
              blank_injection_measurement_id,
            }),
      params_nois: {},
      params_dtim: convertNullValuesToUndefined({
        detection_time_start_sec,
        detection_time_end_sec,
      }),
    });

    const _postprocessingTemplate = {
      ...postprocessingTemplate,
      ...params_pdet,
      ...params_peak,
      ...params_base,
      ...params_binj,
      ...params_nois,
      ...params_dtim,
    };

    return parsePostprocessingTemplate(_postprocessingTemplate);
  },
  async getPostprocessingTemplates({
    search = '',
    isArchived = false,
    offset = 0,
    limit,
    hasOnlyFavorites,
    hasSortingByFavorites,
    postprocessingTemplateIdsToExclude,
  }) {
    const id = Number(search);
    const _ilike = `%${search ?? ''}%`;
    const searchById = Number.isInteger(id) && id > 0 ? id : undefined;
    const whereSearchByNameOrId = [{ name: { _ilike } }];
    if (searchById) {
      whereSearchByNameOrId.push({ id: { _eq: searchById } });
    }

    const {
      postprocessingTemplates,
      postprocessingTemplates_aggregate,
    } = await makeGraphqlRequestCloud(PostprocessingTemplates, {
      whereSearchByNameOrId,
      offset,
      limit,
      hasOnlyFavorites: hasOnlyFavorites ? true : null,
      isArchived,
      hasSortingByFavorites,
      postprocessingTemplateIdsToExclude,
    });

    return {
      postprocessingTemplates: postprocessingTemplates.map(
        ({
          params_pdet,
          params_peak,
          params_base,
          params_binj,
          params_nois,
          params_dtim,
          ...postprocessingTemplate
        }) =>
          parsePostprocessingTemplate({
            ...postprocessingTemplate,
            ...params_pdet,
            ...params_peak,
            ...params_base,
            ...params_binj,
            ...params_nois,
            ...params_dtim,
          }),
      ),
      count: postprocessingTemplates_aggregate.aggregate.count,
    };
  },
  addToFavorites(postprocessingTemplateUuid) {
    return makeGraphqlRequestCloud(SetFavoriteStatus, {
      postprocessingTemplateUuid,
      isFavorite: true,
    });
  },
  removeFromFavorites(postprocessingTemplateUuid) {
    return makeGraphqlRequestCloud(SetFavoriteStatus, {
      postprocessingTemplateUuid,
      isFavorite: false,
    });
  },
  archive(postprocessingTemplateUuid) {
    return makeGraphqlRequestCloud(SetArchivingStatus, {
      postprocessingTemplateUuid,
      isArchived: true,
    });
  },
  restore(postprocessingTemplateUuid) {
    return makeGraphqlRequestCloud(SetArchivingStatus, {
      postprocessingTemplateUuid,
      isArchived: false,
    });
  },
};
