import { render, staticRenderFns } from "./NavbarSecondaryAccount.vue?vue&type=template&id=9134d964&scoped=true&"
import script from "./NavbarSecondaryAccount.vue?vue&type=script&lang=js&"
export * from "./NavbarSecondaryAccount.vue?vue&type=script&lang=js&"
import style0 from "./NavbarSecondaryAccount.vue?vue&type=style&index=0&id=9134d964&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9134d964",
  null
  
)

export default component.exports