<template>
  <div class="method-edit">
    <MQ mq="mobile">
      <SectionDropdown
        :title="titleSectionDropdown"
        class="method-edit__section-dropdown--settings"
        :class="[`method-edit__section-dropdown--settings--${padding}`]"
      >
        <div class="method-edit__mobile-settings">
          <div class="method-edit__mobile-toggles">
            <div
              v-for="section in sections"
              :key="section.id"
              class="method-edit__checkbox"
              @click="showSection(section.id)"
            >
              <i style="font-size: 9px; margin: 3px 5px 4px 2px; width: 9px; height: 9px">
                fiber_manual_record
              </i>
              {{ section.name }}
            </div>
          </div>
        </div>
      </SectionDropdown>
      <div class="method-edit__wrapper-divider-section-dropdown">
        <div class="method-edit__divider-section-dropdown"></div>
      </div>
    </MQ>
    <MQ mq="desktop">
      <div class="method-edit__column method-edit__column--toggles method-edit__column-left">
        <div
          v-for="section in sections"
          :key="section.id"
          class="method-edit__checkbox"
          @click="showSection(section.id)"
        >
          <i style="font-size: 9px; margin: 1px 2px 4px 2px; width: 9px; height: 9px">
            fiber_manual_record
          </i>
          {{ section.name }}
        </div>
      </div>
    </MQ>
    <div
      class="method-edit__column method-edit__column--content"
      :class="[`method-edit__column--content--${padding}`]"
    >
      <PortalTarget name="selectStandardModal" />

      <div
        ref="section-general"
        class="method-edit__section"
        :class="{ 'method-edit__section--highlighted': highlightedSectionId === 'general' }"
      >
        <h4 class="section first"><i>timer</i> General</h4>

        <field
          ref="comment"
          :disabled="disabled"
          :error="errors && errors.comment"
          :label="'Description'"
          :type="'str'"
          :value="pMethod.comment"
          :defaultValue="defaultValues.comment"
          helper="Space to leave comments on your method [optional]."
          data-test-id="modal-input-field-comment"
          @update:value="update('comment', $event)"
        />

        <field
          ref="run_time_min"
          :disabled="disabled"
          :error="errors && errors.run_time_min"
          :hints="[0.25, 0.5, 1, 2, 3, 4, 5, 8, 10, 15, 20, 25]"
          :label="'Run time, min'"
          :type="'float'"
          :value="pMethod.run_time_min"
          :defaultValue="defaultValues.run_time_min"
          helper="How long (in min) your method will run. Since the total working volume of the pump is 5.0 mL, the maximum time possible is 5.0/Flow rate. For a gradient system with two pumps the total volume is 10 mL, the maximum time possible is 10.0/Flow rate."
          data-test-id="modal-input-field-run-time"
          @update:value="update('run_time_min', $event)"
        />
      </div>

      <div
        ref="section-pump"
        class="method-edit__section"
        :class="{ 'method-edit__section--highlighted': highlightedSectionId === 'pump' }"
      >
        <h4 class="section"><i>arrow_forward</i> Pump</h4>

        <field
          ref="solvents"
          :disabled="disabled"
          :error="errors && errors.solvents"
          label="A phase"
          type="str"
          helper="Mobile Phase in Pump A. Ex. MeCN/H2O/H2SO4 - 10/90/.2%."
          :value="pMethod.solvents && pMethod.solvents.a && pMethod.solvents.a.name"
          :defaultValue="
            defaultValues.solvents &&
            defaultValues.solvents.a &&
            defaultValues.solvents.a.name &&
            defaultValues.solvents.a.name
          "
          @update:value="updateSolvent('a', $event)"
        />

        <field
          v-if="hasGradient"
          :disabled="disabled"
          :error="errors && errors.solvents"
          label="B phase"
          type="str"
          helper="Mobile Phase in Pump B. Ex. MeCN/H2O/H2SO4 - 90/10/.2%."
          :value="pMethod.solvents && pMethod.solvents.b && pMethod.solvents.b.name"
          :defaultValue="
            defaultValues.solvents &&
            defaultValues.solvents.b &&
            defaultValues.solvents.b.name &&
            defaultValues.solvents.b.name
          "
          @update:value="updateSolvent('b', $event)"
        />

        <div class="method-edit__injection-container">
          <field
            ref="flow_rate_ml_per_min"
            style="flex: 1"
            :disabled="disabled"
            :error="errors && errors.flow_rate_ml_per_min"
            :hints="[0.7, 1.0, 1.3, 1.5, 2]"
            :label="'Flow rate, mL/min'"
            :type="'float'"
            :value="pMethod.flow_rate_ml_per_min"
            :defaultValue="defaultValues.flow_rate_ml_per_min"
            helper="How quickly the pump will push the mobile phase through the system (in mL/min). For a standard column with an internal diameter (ID) of 3.2 mm, a flow rate between 0.5 - 0.7 mL/min is recommended for most operations. For a 2.1 mm ID column, a flow rate between 0.2-0.4 mL/min is recommended. Note: The faster the flow rate, the higher the internal pressure. The system can operate with up to 3000 psi pressure."
            @update:value="update('flow_rate_ml_per_min', $event)"
          />
          <field
            ref="refill_flow_rate_ml_per_min"
            style="flex: 1"
            :disabled="disabled"
            :error="errors && errors.refill_flow_rate_ml_per_min"
            :hints="[0.5, 1.0, 1.5, 2, 2.5, 3, 3.5, 4, 5, 6, 7, 8]"
            :label="'Refill flow rate, mL/min'"
            :type="'float'"
            helper="How quickly the pump(s) will refill after completing an injection. 8 mL/min is the maximum speed. Sometimes slow refill flow rate works better preventing bubbles formation in the pump."
            :value="pMethod.refill_flow_rate_ml_per_min"
            :defaultValue="defaultValues.refill_flow_rate_ml_per_min"
            @update:value="update('refill_flow_rate_ml_per_min', $event)"
          />
        </div>

        <div class="method-edit__injection-container">
          <field
            ref="pressure_min"
            :disabled="disabled"
            :error="errors && errors.pressure_min"
            :label="'Pressure min, psi'"
            :type="'int'"
            :value="pMethod.pressure_min"
            :defaultValue="defaultValues.pressure_min"
            helper="Minimum pressure for the system (in psi). It is recommended to set it at least 100 psi."
            class="method-edit__injection-field"
            @update:value="update('pressure_min', $event)"
          />
        </div>
      </div>
      <div
        v-if="hasGradient"
        ref="section-gradient"
        class="method-edit__section"
        :class="{ 'method-edit__section--highlighted': highlightedSectionId === 'gradient' }"
        style="margin-top: 12px"
      >
        <PopupHelper
          text="When enabled in a two-pump gradient set-up, this table specifies  how and when each pump operates."
        >
          <label>Gradient</label>
        </PopupHelper>
        <TableGradient
          ref="gradient"
          v-model="gradientTableModel"
          style="margin-bottom: 15px"
          :fractionsCount="fractionsCount"
          :maxTime="pMethod.run_time_min || 0"
          :error="errors && errors.gradient"
          :isDisabled="disabled"
        />
      </div>

      <div
        ref="section-detector"
        class="method-edit__section"
        :class="{ 'method-edit__section--highlighted': highlightedSectionId === 'detector' }"
      >
        <h4 class="section"><i>flare</i> Detector</h4>
        <field
          ref="detector_single_channel"
          :disabled="disabled"
          :error="errors && errors.detector_single_channel"
          :canSelectOnlyFromHints="true"
          :hints="['All', 'UV', 'Red', 'Green', 'Blue']"
          label="Channel mode"
          :type="'str'"
          helper="Sets the default viewing channel for the detector. These can be changed during and after the injection."
          :value="pMethod.detector_single_channel || 'All'"
          :defaultValue="defaultValues.detector_single_channel"
          class="method-edit__injection-field"
          @update:value="updateDetectorSingleChannel($event)"
        />
        <field
          ref="calibration_delay_sec"
          :disabled="disabled"
          :error="errors && errors.calibration_delay_sec"
          :hints="[1, 5, 10, 30, 60, 300, 600]"
          :label="'Calibration delay, s'"
          :type="'int'"
          :value="pMethod.calibration_delay_sec"
          :defaultValue="defaultValues.calibration_delay_sec"
          class="method-edit__injection-field"
          @update:value="update('calibration_delay_sec', $event)"
        />
      </div>

      <div
        ref="section-injection"
        class="method-edit__section"
        :class="{ 'method-edit__section--highlighted': highlightedSectionId === 'injection' }"
      >
        <h4 class="section"><i>group_work</i> Injection</h4>

        <div class="method-edit__injection-container">
          <field
            ref="injection_ul"
            :disabled="disabled"
            :error="errors && errors.injection_ul"
            :hints="[1, 2, 3, 5, 10]"
            :label="'Sample amount, μl'"
            :type="'int'"
            :value="pMethod.injection_ul"
            :defaultValue="defaultValues.injection_ul"
            helper="Volume of sample that will be collected (in μL). Range: 1-120 μL."
            class="method-edit__injection-field"
            data-test-id="modal-input-field-sample-amount"
            @update:value="update('injection_ul', $event)"
          />
          <field
            ref="injection_delay_sec"
            :disabled="disabled"
            :error="errors && errors.injection_delay_sec"
            :label="'Injection delay, s'"
            :type="'float'"
            :value="pMethod.injection_delay_sec"
            :defaultValue="defaultValues.injection_delay_sec"
            helper="How long the injection will be delayed (in s). About 15-30 s is recommended in order for the detector to reach an optimal baseline [optional]."
            class="method-edit__injection-field"
            @update:value="update('injection_delay_sec', $event)"
          />
        </div>
      </div>
      <div
        v-if="hasAutosampler"
        ref="section-autosampler"
        class="method-edit__section"
        :class="{ 'method-edit__section--highlighted': highlightedSectionId === 'autosampler' }"
      >
        <div class="method-edit__injection-container">
          <field
            ref="needle_depth_mm"
            :disabled="disabled"
            :error="errors && errors.needle_depth_mm"
            :hints="[10, 15, 20, 25]"
            :label="'Needle depth, mm'"
            :type="'int'"
            :value="pMethod.needle_depth_mm"
            :defaultValue="defaultValues.needle_depth_mm"
            helper="How deep the autosampler's needle will be injected into the sample vial (in mm) [optional]. Max 30 mm. The default value is 0 mm."
            class="method-edit__injection-field"
            @update:value="update('needle_depth_mm', $event)"
          />
          <field
            ref="syringe_refill_speed"
            :disabled="disabled"
            :error="errors && errors.syringe_refill_speed"
            :hints="[50, 100, 150, 200, 300, 500, 1000]"
            :label="'Intake rate, μl/min'"
            :type="'int'"
            :value="pMethod.syringe_refill_speed"
            :defaultValue="defaultValues.syringe_refill_speed"
            helper="Sets how quickly the syringe pump will aspirate the sample from the vial [optional]. If no value is entered, the default speed is 1000 μl\Min. Range: 10-1000 μl\Min."
            class="method-edit__injection-field"
            @update:value="update('syringe_refill_speed', $event)"
          />
        </div>
        <field
          ref="shaking_duration_sec"
          :disabled="disabled"
          :error="errors && errors.shaking_duration_sec"
          :label="'Shaking before injection, s'"
          :type="'float'"
          :value="pMethod.shaking_duration_sec"
          :defaultValue="defaultValues.shaking_duration_sec"
          helper="Sets how long the shaking mode before injection will last (in seconds)."
          class="method-edit__injection-field"
          @update:value="update('shaking_duration_sec', $event)"
        />
        <field
          ref="wash_after_injection"
          :disabled="disabled"
          :error="errors && errors.wash_after_injection"
          :label="'Wash after injection'"
          :type="'bool'"
          :value="pMethod.wash_after_injection"
          :defaultValue="defaultValues.wash_after_injection"
          helper="Enables the needle wash cycle after each injection. If injection time is equal to the run time then no needle wash is possible after injection."
          @update:value="update('wash_after_injection', $event)"
        />
      </div>

      <div
        v-if="hasValve"
        ref="section-valve"
        class="method-edit__section"
        :class="{ 'method-edit__section--highlighted': highlightedSectionId === 'valve' }"
      >
        <h4 class="section"><i>settings_applications</i> Valve</h4>

        <field
          ref="valve_initial_position"
          :disabled="disabled"
          :error="errors && errors.valve_initial_position"
          :canSelectOnlyFromHints="true"
          :hints="['Do not change', 'Change position', 'Position 1', 'Position 2']"
          label="Initial valve position"
          :type="'str'"
          :value="valveInitialPositionMap[pMethod.valve_initial_position]"
          :defaultValue="defaultValues.valve_initial_position"
          class="method-edit__injection-field"
          @update:value="updateValveInitialPosition"
        />
        <Checkbox
          :checked="method.valve_does_change_position || false"
          class="method-edit__checkbox-valve-change-position"
          @change="update('valve_does_change_position', $event)"
        >
          Change position during injection
        </Checkbox>
        <field
          v-if="method.valve_does_change_position"
          ref="valve_change_position_after_sec"
          :disabled="disabled"
          :error="errors && errors.valve_change_position_after_sec"
          :canSelectOnlyFromHints="true"
          label="Change valve position after, sec"
          :type="'float'"
          :value="pMethod.valve_change_position_after_sec"
          :defaultValue="defaultValues.valve_change_position_after_sec"
          class="method-edit__injection-field"
          @update:value="update('valve_change_position_after_sec', $event)"
        />
      </div>

      <div
        ref="section-report"
        class="method-edit__section"
        :class="{ 'method-edit__section--highlighted': highlightedSectionId === 'report' }"
      >
        <h4 class="section">
          <PopupHelper
            text="Check the box next to the contact of whomever you would like to share the injection results with."
          >
            <span>Send a report at the end of the injection</span>
          </PopupHelper>
        </h4>
        <div>
          <div
            v-if="
              isReadonly &&
              (!method.report_emails || (method.report_emails && method.report_emails.length === 0))
            "
          >
            Without a report
          </div>
          <ContactList
            v-else
            :isReadonly="disabled || isReadonly"
            class="method-edit__contact-list"
            :selectedEmails="method.report_emails"
          >
            <template #action="{ contact, email }">
              <Checkbox
                :checked="isEmailForReportSelected(contact ? contact.email : email)"
                @change="toggleEmailForReport(contact ? contact.email : email, $event)"
              />
            </template>
          </ContactList>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { METHOD_DEFAULT } from '@/constants/methods/presets';
  import StandardModelFieldComponent from 'components/element/StandardModelFieldComponent.vue';
  import TableGradient from '@/uikitProject/tables/gradient/TableGradient';
  import ContactList from 'components/blocks/contacts/vueContactList/ContactList';
  import Checkbox from '@/uikitBase/switchers/Checkbox';
  import SectionDropdown from '@/uikitProject/sections/SectionDropdown';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper';
  import { valveInitialPositionMap } from '@/utils/methodHelpers';

  const EVENT_UPDATE_METHOD = 'update:method';
  const EVENT_VALIDATION = 'validation';
  const EVENT_UPDATE_FIELD = 'updateField';

  export default {
    name: 'MethodEditComponent',

    components: {
      PopupHelper,
      SectionDropdown,
      Checkbox,
      ContactList,
      TableGradient,
      field: StandardModelFieldComponent,
    },

    model: {
      prop: 'method',
      event: EVENT_UPDATE_METHOD,
    },

    props: {
      method: {
        type: Object,
        default: () => ({ ...METHOD_DEFAULT }),
      },
      errors: {
        type: Object,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      configuration: {
        type: [Object, Array],
        required: true,
      },
      isReadonly: Boolean,
      padding: {
        type: String,
        default: 'md',
        validator(value) {
          const availableValues = ['sm', 'md'];
          return availableValues.includes(value);
        },
      },
    },

    data() {
      return {
        gradientTableModel: {
          validation: {
            isValid: null,
          },
          gradient: [],
        },
        autoPeaksTableModel: {
          validation: {
            isValid: null,
          },
          peaks: [],
        },
        privateGradientColumnsCount: 2,

        highlightedSectionId: null,

        valveInitialPositionMap,
      };
    },

    computed: {
      pMethod() {
        return this.method ? this.method : {};
      },
      defaultValues() {
        return this.pMethod.defaultValues;
      },

      localGradientColumnsCount: {
        get() {
          return this.privateGradientColumnsCount;
        },
        set(value) {
          if (value) {
            this.privateGradientColumnsCount = value;
          }
        },
      },
      fractionsCount() {
        return 2;
      },
      titleSectionDropdown() {
        const sectionsWithoutGeneral = Object.values(this.sections).filter(
          (info) => info.name !== 'General',
        );
        const sectionNames = sectionsWithoutGeneral.map((section) => section.name);
        return `Sections: ${sectionNames.join(', ')}`;
      },
      isCromite() {
        return this.configuration?.device_type === 'CROMITE';
      },

      sections() {
        return [
          {
            id: 'general',
            name: 'General',
          },
          {
            id: 'pump',
            name: 'Pump',
          },
          ...(this.hasGradient
            ? [
                {
                  id: 'gradient',
                  name: 'Gradient',
                  fields: ['gradient', 'solvents'],
                },
              ]
            : []),
          {
            id: 'detector',
            name: 'Detector',
          },
          {
            id: 'injection',
            name: 'Injection',
          },
          ...(this.hasAutosampler
            ? [
                {
                  id: 'autosampler',
                  name: 'Autosampler',
                  fields: [
                    'needle_depth_mm',
                    'syringe_refill_speed',
                    'shaking_duration_sec',
                    'wash_after_injection',
                  ],
                },
              ]
            : []),
          ...(this.hasValve
            ? [
                {
                  id: 'valve',
                  name: 'Valve',
                  fields: [
                    'valve_initial_position',
                    'valve_does_change_position',
                    'valve_change_position_after_sec',
                  ],
                },
              ]
            : []),
          {
            id: 'report',
            name: 'Report',
          },
        ];
      },

      hasGradient() {
        return this.configuration?.pump?.length >= 2;
      },
      hasAutosampler() {
        return this.configuration?.autosampler.length > 0;
      },
      hasValve() {
        return this.configuration?.valve?.length > 0;
      },
    },

    watch: {
      method: {
        handler(method) {
          this.gradientTableModel.gradient = method?.gradient ?? [];
          this.localGradientColumnsCount = this.gradientTableModel.gradient[0]?.fractions?.length;

          this.autoPeaksTableModel.peaks = method?.peaks ?? [];
        },
        immediate: true,
      },
      gradientTableModel({ validation, gradient }) {
        this.$emit(EVENT_VALIDATION, Boolean(validation.isValid));
        this.update('gradient', this.hasGradient ? gradient : null);
      },
      autoPeaksTableModel({ validation, peaks }) {
        this.$emit(EVENT_VALIDATION, Boolean(validation.isValid));
        this.update('peaks', peaks);
      },
      sections: {
        handler({ gradient }) {
          if (gradient) {
            this.$emit(EVENT_VALIDATION, Boolean(this.gradientTableModel.validation.isValid));
          } else {
            this.$emit(EVENT_VALIDATION, true);
          }
        },
        deep: true,
      },
    },

    methods: {
      update(key, value) {
        this.$emit(EVENT_UPDATE_METHOD, {
          ...this.pMethod,
          [key]: value,
        });
        this.$emit(EVENT_UPDATE_FIELD, key);
      },
      updateFields(fields) {
        this.$emit(EVENT_UPDATE_METHOD, {
          ...this.pMethod,
          ...fields,
        });
      },

      isEmailForReportSelected(email) {
        if (this.method.report_emails) {
          return this.method.report_emails?.includes(email);
        }
        return false;
      },
      toggleEmailForReport(email, value) {
        const emails = this.method.report_emails ?? [];

        if (value) {
          this.update('report_emails', [...emails, email]);
        } else {
          const filteredEmails = emails.filter((_email) => _email !== email);
          this.update('report_emails', filteredEmails.length === 0 ? null : filteredEmails);
        }
      },
      updateSolvent(solvent, value) {
        this.update('solvents', {
          ...this.pMethod.solvents,
          [solvent]: {
            name: value,
            // stubs
            sielc_solvent_id: 111,
            type: 'type_stub',
          },
        });
      },
      updateDetectorSingleChannel(value) {
        const fieldsToUpdate = {
          detector_single_channel: value,
        };

        if (value !== 'All') {
          fieldsToUpdate.default_measurement = null;
        }

        this.updateFields(fieldsToUpdate);
      },
      updateValveInitialPosition(value) {
        this.updateFields({
          valve_initial_position: valveInitialPositionMap[value],
        });
      },

      scrollToField(fieldName) {
        this.$refs[fieldName]?.$el.scrollIntoView({
          behavior: 'smooth',
        });
      },

      showSection(sectionId) {
        this.highlightedSectionId = sectionId;
        this.$refs[`section-${sectionId}`]?.scrollIntoView({
          behavior: 'smooth',
        });

        setTimeout(() => {
          this.highlightedSectionId = null;
        }, 500);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .method-edit {
    &__section {
      transition: background-color 0.3s;

      &--highlighted {
        background-color: transparentize($color-bg-primary, 0.9);
      }
    }

    &__section-dropdown {
      &--settings {
        &--md {
          margin-left: 16px;
        }

        &--sm {
          margin-left: 10px;
        }

        .method-edit__checkbox {
          display: flex;
          align-items: center;
          padding: 9px 8px 8px 16px;
          min-height: 36px;
          cursor: pointer;

          @include isHoverDevice {
            &:hover {
              background: #00000011;
            }
          }
        }
      }
    }

    &__mobile-settings {
      i.method-edit__icon-section {
        font-size: 13px;
        width: 13px;
        height: 13px;
        padding-bottom: 2px;
      }
    }

    &__mobile-toggles {
      margin-left: -14px;
    }

    &__wrapper-divider-section-dropdown {
      position: relative;
      margin: 11px 0;
    }

    &__divider-section-dropdown {
      position: absolute;
      height: 1px;
      background-color: $color-bg-second;
      width: 100vw;
    }

    &__section-extra {
      width: 100%;
    }

    &__divider-desktop {
      height: 1px;
      width: 100%;
      margin: 10px 0;
      padding: 0 !important;
      background-color: $color-bg-third;
    }

    &__checkbox-valve-change-position {
      margin-top: 10px;
    }

    &__checkbox-auto-peaks {
      &:not(:last-child) {
        margin-top: 10px;
      }
    }

    &__wrapper-table-auto-peaks {
      overflow-x: auto;
      overflow-y: hidden;
    }

    &__contact-list,
    &__table-auto-peaks {
      margin-top: 10px;
    }

    &__label-input {
      color: $color-text-third;
    }

    &__table-auto-peaks {
      min-width: 500px;
    }
  }
</style>

<style lang="scss">
  @import '~assets/css/base/icons';

  .method-edit {
    display: grid;
    grid-template-columns: 120px calc(100% - 120px);
    padding-left: 16px;

    @media (max-width: $screen-sm-max) {
      grid-template-columns: 1fr;
      padding: 0;
    }

    &__icon-section {
      margin-right: 3px !important;
    }

    i {
      @extend .material-icons;
      @extend .material-icon--18;
    }

    .method-edit__column-left i.method-edit__icon-section {
      @extend .material-icon--14;
    }

    &__column {
      flex: 1 1 auto;

      h4.section {
        margin-top: 32px;

        i {
          @extend .material-icons;
          @extend .material-icon--18;
          padding: 0 0 2px 0;
        }

        &.first {
          margin-top: 8px;
        }
      }

      &--toggles {
        flex: 1 0 120px;
        max-width: 148px;
        margin-left: -16px;

        @media (max-width: $screen-sm-max) {
          display: grid;
          grid-auto-flow: column;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: repeat(5, auto);
          max-width: 100%;
          margin: 0;
        }

        .method-edit__checkbox {
          padding: 8px 8px 8px 16px;
          cursor: pointer;

          @include isHoverDevice {
            &:hover {
              background: #00000011;
            }
          }
        }
      }

      &--content {
        @media (max-width: $screen-sm-max) {
          max-width: 100%;
          overflow: hidden;
        }

        &--md {
          margin-left: 16px;
        }

        &--sm {
          margin-left: 10px;
        }
      }
    }

    &__injection-container {
      display: flex;
      flex-direction: row;
    }

    &__injection-field {
      flex: 1 1;
    }

    .standard-model-field {
      margin-top: 8px;
      display: block;
    }
  }
</style>
