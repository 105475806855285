import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { isProduction } from '@/utils/appHelpers';
import { envVariables } from '@/utils/envHelpers';
import VersionHelper from '@/utils/VersionHelper';

if (envVariables.SENTRY_DSN && isProduction()) {
  Sentry.init({
    release: VersionHelper.clientVersion,
    Vue,
    dsn: envVariables.SENTRY_DSN,
    environment: envVariables.APP_MODE,
    beforeSend: (event, hint) => {
      if (hint) {
        console.error(hint.originalException || hint.syntheticException);
      }

      return event;
    },
  });
}
