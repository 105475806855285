import SocketCentrifugeBase from '@/api/sockets/SocketCentrifugeBase';
import { Callback, Dict } from '@/types/utility';

export enum StandardInjectionSocketEvents {
  INJECTION = 'injection',
  PROGRESS = 'injection.progress',
}

enum StandardInjectionSocketRPC {
  GET = 'standard_injection.get',
  RUN = 'standard_injection.run',
  STOP = 'standard_injection.stop',
  CANCEL = 'standard_injection.cancel',
  RESTART = 'standard_injection.restart',
  SEEN = 'standard_injection.seen',
  ARCHIVE = 'standard_injection.archive',
  RESTORE = 'standard_injection.restore',
  PEAK_SELECT = 'standard_injection.peakSelect',
  UPDATE = 'standard_injection.update',
}

export default class StandardInjectionSocket extends SocketCentrifugeBase<
  StandardInjectionSocketEvents,
  StandardInjectionSocketRPC
> {
  private static connections: Dict<StandardInjectionSocket> = {};

  protected listeners: Record<StandardInjectionSocketEvents, Callback[]> = {
    [StandardInjectionSocketEvents.INJECTION]: [],
    [StandardInjectionSocketEvents.PROGRESS]: [],
  };

  private constructor(
    public id: number,
    onConnect?: Callback<
      [SocketCentrifugeBase<StandardInjectionSocketEvents, StandardInjectionSocketRPC>]
    >,
  ) {
    super(`personal:standard-injection-${id}`, {
      handleEvents: ({ data }) => {
        switch (data.event) {
          case StandardInjectionSocketEvents.INJECTION: {
            this.callListeners(StandardInjectionSocketEvents.INJECTION, data.injection);
            break;
          }
          case StandardInjectionSocketEvents.PROGRESS: {
            this.callListeners(StandardInjectionSocketEvents.PROGRESS, data.injection);
            break;
          }
        }
      },
      onConnect,
    });

    this.id = id;

    this.dataAddedToEveryRequest = { injection_id: this.id };
  }

  public static start(
    id: number,
    onConnect?: Callback<
      [SocketCentrifugeBase<StandardInjectionSocketEvents, StandardInjectionSocketRPC>]
    >,
  ) {
    const connection = StandardInjectionSocket.connections[id];
    if (connection) {
      connection.addSession();
      onConnect?.(connection);
      return connection;
    }

    StandardInjectionSocket.connections[id] = new StandardInjectionSocket(id, onConnect);
    return StandardInjectionSocket.connections[id];
  }

  close(listenersGroupId: string) {
    const isClosed = super.close(listenersGroupId);

    if (isClosed) {
      delete StandardInjectionSocket.connections[this.id];
    }

    return isClosed;
  }

  async get() {
    const data = await this.sendNamedRPC(StandardInjectionSocketRPC.GET);
    return data.injection;
  }

  run() {
    return this.sendNamedRPC(StandardInjectionSocketRPC.RUN);
  }

  stop() {
    return this.sendNamedRPC(StandardInjectionSocketRPC.STOP);
  }

  cancel() {
    return this.sendNamedRPC(StandardInjectionSocketRPC.CANCEL);
  }

  restart() {
    return this.sendNamedRPC(StandardInjectionSocketRPC.RESTART);
  }

  seen() {
    return this.sendNamedRPC(StandardInjectionSocketRPC.SEEN);
  }

  archive() {
    return this.sendNamedRPC(StandardInjectionSocketRPC.ARCHIVE);
  }

  restore() {
    return this.sendNamedRPC(StandardInjectionSocketRPC.RESTORE);
  }

  peakSelect(peakID) {
    return this.sendNamedRPC(StandardInjectionSocketRPC.PEAK_SELECT, { id: peakID });
  }

  update(injection) {
    return this.sendNamedRPC(StandardInjectionSocketRPC.UPDATE, { injection });
  }
}
