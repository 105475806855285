<template>
  <div
    v-if="configuration.hasOwnProperty('valve_loop') && configuration.valve_loop != null"
    class="hardware-tile"
  >
    <div class="hardware-tile__wrapper-title">
      <div>
        <i class="hardware-tile__title-icon">settings_applications</i>
        <h4>Valve loop</h4>
      </div>
      <Information :data="popupInfoData" class="hardware-tile__information" />
    </div>

    <hardware-state-component :state="currentState" data-test-id="valve-loop-status" />

    <HardwareErrorComponent :errors="errors" :error-list="errorList" />

    <template v-if="stateDetails">
      <div style="max-width: 180px; margin: 16px auto 0; text-align: center">
        <template v-if="operation === 'MOVING_TO_POSITION'">
          <img src="@/assets/img/valve-loop-switching.svg" />
          <!--<i class="hardware-tile__operation">rotate_right</i>-->
          <span class="text-green mb-2" style="font-weight: bold">
            Switching to {{ stateDetails.position !== 2 ? 'sample loading' : 'sample injection' }}
          </span>
        </template>
        <template v-else>
          <img v-if="stateDetails.position === 2" src="@/assets/img/valve-loop-16.svg" />
          <img v-else src="@/assets/img/valve-loop-12.svg" />
          <span v-if="stateDetails.position === 2" class="text-green" style="font-weight: bold">
            Sample loading
          </span>
          <span v-else class="text-process" style="font-weight: bold">Sample injection</span>
        </template>
      </div>
    </template>

    <div v-if="operation === 'IN_POSITION_AND_CHARGING'" class="operation">
      <i class="hardware-tile__operation">rotate_left</i>
      <span class="text-green mb-2">Getting ready</span>
    </div>

    <template v-if="!injectionInProgress">
      <Btn
        height="s"
        type="primary"
        icon-left="play_arrow"
        :disabled="state.ready !== true"
        class="mt-16"
        data-test-id="btn-device-quick-start-valve"
        @click="start"
      >
        Single injection
      </Btn>
    </template>
  </div>
</template>

<script>
  import Base from './Base.vue';
  import Btn from '@/uikitBase/btns/Btn';
  import HardwareErrorComponent from 'components/element/HardwareErrorComponent';
  import { GENERAL_ERRORS } from '@/constants/hardware/errors';
  import Information from 'components/block/hardware/private/Information';

  const EVENT_START = 'start';

  export default {
    components: { Information, HardwareErrorComponent, Btn },
    extends: Base,

    computed: {
      currentState() {
        return this.state?.valve_loop;
      },
      stateDetails() {
        return this.state?.valve_loop_details;
      },
      operation() {
        return this.stateDetails?.operation;
      },
      errors() {
        return this.stateDetails?.errors;
      },
      errorList() {
        return { ...GENERAL_ERRORS };
      },
      injectionInProgress() {
        return this.state.injection_id != null;
      },

      popupInfoData() {
        const { firmware_version, id, serial } = this.configuration.valve_loop;

        return [
          {
            name: 'Id',
            value: id,
          },
          {
            name: 'Serial',
            value: serial,
          },
          {
            name: 'Firmware Version',
            value: firmware_version
              ? `${firmware_version.major}.${firmware_version.minor}`
              : 'Not available',
          },
        ];
      },
    },

    methods: {
      start() {
        this.$emit(EVENT_START, null);
      },
    },
  };
</script>
<style lang="scss">
  .operation {
    text-align: center;
    align-content: center;
    margin: 32px auto auto;
  }
</style>
