export default class FPSService {
  public static readonly REGULAR_FPS = 60;
  public static readonly IS_SUPPORTED = Boolean(window.requestAnimationFrame);

  public framesCurrentSecond: number = 0;
  public framesLastSecond: number = 0;
  /**
   * frames per second
   */
  private framesList: number[] = [];
  private readonly maxSavedFrames: number;
  private readonly maxFramesForMeasurement: number;
  private startTimeMs: number = 0;
  private isRunning: boolean = false;
  /**
   * Period to collect enough data to provide meaningful results.
   * While there are no enough measurements, the public methods will return stubs
   */
  private readonly preparationPeriodMs: number;
  private isUseStubValues: boolean;

  constructor({
    isStart = true,
    preparationPeriodMs = 5000,
    maxFramesForMeasurement = 60,
    maxSavedFrames = 120,
  } = {}) {
    if (!FPSService.IS_SUPPORTED) {
      throw new Error('Your browser must support RequestAnimationFrame');
    }

    if (maxSavedFrames < maxFramesForMeasurement) {
      throw new Error('"maxSavedFrames" must be higher than "maxFramesForMeasurement"');
    }

    this.preparationPeriodMs = preparationPeriodMs;
    this.isUseStubValues = Boolean(preparationPeriodMs);
    this.maxFramesForMeasurement = maxFramesForMeasurement;
    this.maxSavedFrames = maxSavedFrames;

    if (isStart) {
      this.start();
    }
  }

  private startNewMeasurement() {
    this.framesCurrentSecond = 0;
    this.startTimeMs = window.performance.now();
    window.requestAnimationFrame(this.startNewCycle.bind(this));
  }

  private startNewCycle() {
    if (this.isRunning) {
      const currentTimeMs = window.performance.now();

      if (currentTimeMs - this.startTimeMs < 1000) {
        this.framesCurrentSecond += 1;
        window.requestAnimationFrame(this.startNewCycle.bind(this));
      } else {
        this.framesLastSecond = this.framesCurrentSecond;
        this.framesList.unshift(this.framesCurrentSecond);

        this.framesList = [
          this.framesCurrentSecond,
          ...(this.framesList.length >= this.maxSavedFrames
            ? this.framesList.slice(0, this.maxSavedFrames - 1)
            : this.framesList),
        ];

        this.startNewMeasurement();
      }
    }
  }

  private get framesForMeasurement() {
    return this.framesList.slice(0, this.maxFramesForMeasurement);
  }

  public start() {
    setTimeout(() => {
      this.isUseStubValues = false;
    }, this.preparationPeriodMs);

    this.isRunning = true;
    this.startNewMeasurement();
  }

  public stop() {
    this.isRunning = false;
    this.isUseStubValues = Boolean(this.preparationPeriodMs);
  }

  public get averageFPS() {
    if (this.isUseStubValues) {
      return FPSService.REGULAR_FPS;
    }

    const totalTime = this.framesForMeasurement.reduce(
      (totalTime, detection) => totalTime + detection,
      0,
    );

    if (this.framesForMeasurement.length) {
      return Math.round(totalTime / this.framesForMeasurement.length);
    }
    return 0;
  }

  public get highestFPS() {
    if (this.isUseStubValues) {
      return FPSService.REGULAR_FPS;
    }

    return Math.max(...this.framesForMeasurement);
  }

  public get lowestFPS() {
    if (this.isUseStubValues) {
      return FPSService.REGULAR_FPS;
    }

    return Math.min(...this.framesForMeasurement);
  }
}
