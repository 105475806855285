<template>
  <div class="liquids">
    <div
      v-if="hasContainerA"
      v-tippy
      :content="`${amountA.toFixed(1)}/${volumeA} mL - ${nameA}`"
      class="liquids__item"
    >
      <div class="liquids__name">A</div>
      <Scale :amount="amountA" :volume="volumeA" class="liquids__scale" />
    </div>

    <div
      v-if="hasContainerB"
      v-tippy
      :content="`${amountB.toFixed(1)}/${volumeB} mL - ${nameB}`"
      class="liquids__item"
    >
      <div class="liquids__name">B</div>
      <Scale :amount="amountB" :volume="volumeB" class="liquids__scale" />
    </div>

    <div
      v-if="configuration.waste"
      v-tippy
      :content="`${amountWaste.toFixed(1)}/${volumeWaste} mL - Waste`"
      class="liquids__item liquids__item--waste"
    >
      <div class="liquids__name">W</div>
      <Scale :amount="amountWaste" :volume="configuration.waste.volume" class="liquids__scale" />
    </div>
  </div>
</template>

<script>
  import Scale from '@/uikitProject/devices/liquids/Scale';

  export default {
    name: 'Liquids',

    components: { Scale },

    props: {
      device: {
        type: Object,
        required: true,
      },
      method: {
        type: Object,
        required: true,
      },
    },

    computed: {
      configuration() {
        return this.device.configuration;
      },
      state() {
        return this.device.state;
      },

      hasContainerA() {
        return this.configuration.phase.phaseContainers?.a;
      },
      hasContainerB() {
        return this.configuration.phase.phaseContainers?.b;
      },

      volumeA() {
        return this.configuration.phase.phaseContainers?.a.volume;
      },
      volumeB() {
        return this.configuration.phase.phaseContainers?.b.volume;
      },
      volumeWaste() {
        return this.configuration.waste.volume;
      },
      amountA() {
        return this.state.phase && this.state.phase.a ? Number(this.state.phase.a.toFixed(1)) : 0.0;
      },
      amountB() {
        return this.state.phase && this.state.phase.b ? Number(this.state.phase.b.toFixed(1)) : 0.0;
      },
      amountWaste() {
        return this.state.waste ? Number(this.state.waste.toFixed(1)) : 0.0;
      },
      nameA() {
        return this.method?.solvents?.a?.name ? this.method.solvents.a.name : 'Unknown';
      },
      nameB() {
        return this.method?.solvents?.b?.name ? this.method.solvents.b.name : 'Unknown';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .liquids {
    display: flex;
    align-items: center;

    &__item {
      display: flex;
      align-items: baseline;
      margin-right: 15px;

      @media (max-width: $screen-xs-max) {
        margin-right: 6px;
      }

      &--waste {
        margin-left: 20px;

        @media (max-width: $screen-xs-max) {
          margin-left: 0;
        }
      }
    }

    &__scale {
      width: 140px;

      @media (max-width: $screen-xs-max) {
        width: 100px;
      }

      @media (max-width: $screen-xxs-max) {
        width: 60px;
      }
    }

    &__name {
      min-width: 14px;
      text-align: center;
      margin-right: 5px;
      color: $color-text-second;
    }

    &__result {
      padding-right: 10px;
    }
  }
</style>
