<template>
  <div ref="inputVials" class="input-vials">
    <div class="input-vials__wrapper-input" @click="showPopup">
      <!--Added an extra wrapper over the input element because firefox doesn't emit a click event if a target element is disabled-->
      <div class="input-vials__click-preventer">
        <slot :vial="_vial" :handleBlur="handleBlur">
          <input v-test="{ id: 'input' }" :value="_vial" disabled placeholder="Vial" />
        </slot>
      </div>
    </div>

    <BottomSheet :isShow="isShowPopup" title="Select vial" @hide="hidePopup">
      <TrayVials
        v-model="_vial"
        :isInPopup="true"
        :config="trayConfig"
        class="input-vials__tray"
        :class="{ 'input-vials__tray--small': isSmallTray }"
      />
    </BottomSheet>
  </div>
</template>

<script>
  import TrayVials from '../../vueTrayVials/TrayVials';
  import testDirective from '@/directives/test';
  import { model, props, EVENT_MODEL } from '@/uikitProject/vials/vueInputVials/shared/config.ts';
  import BottomSheet from '@/uikitBase/popups/BottomSheet';

  export default {
    name: 'MobileInputVials',

    components: {
      BottomSheet,
      TrayVials,
    },

    directives: { test: testDirective },

    model,

    props,

    data: () => ({
      isShowPopup: false,
    }),

    computed: {
      _vial: {
        get() {
          return this.vial;
        },
        set(value) {
          this.$emit(EVENT_MODEL, value && value.toUpperCase());
          this.hidePopup();
        },
      },
      vialsQuantity() {
        return Number(this.trayConfig?.split('_')[0]) ?? 0;
      },
      isSmallTray() {
        return this.vialsQuantity === 48;
      },
    },

    methods: {
      showPopup() {
        this.isShowPopup = true;
      },
      hidePopup() {
        this.isShowPopup = false;
      },

      handleBlur() {
        // Stub for mobile version
      },
    },
  };
</script>

<style lang="scss" scoped>
  .input-vials {
    display: flex;

    &__wrapper-input {
      width: 100%;
    }

    &__click-preventer {
      pointer-events: none;
    }

    &__tray {
      margin-top: 30px;
      margin-left: -4px;

      &--small {
        margin-left: -14px;
      }
    }
  }
</style>
