export const METHOD_EMPTY = {
  id: null,

  name: '',
  comment: null,

  run_time_min: null,

  mobile_phase: null,
  buffer: null,

  flow_rate_ml_per_min: null,
  refill_flow_rate_ml_per_min: null,

  injection_ul: null,
  injection_sec: null,
  injection_delay_sec: null,
  needle_depth_mm: null,
  wash_after_injection: null,
  syringe_refill_speed: null,
  shaking_mode: null,
  shaking_duration_sec: null,

  valve_routine: null,

  analog_signal_type: null,
  analog_signal_description: null,

  detector_single_channel: null,
  calibration_delay_sec: null,

  temperature: null,

  gradient: null,
  solvents: null,

  pressure_min: null,
  pressure_reach_sec: null,

  report_emails: null,

  peaks: null,

  fraction_collector_starts_with_vial: null,
  fraction_collector_interval_sec: null,
  fraction_collector_start_delay_sec: null,

  favorite: false,

  archived: false,

  hardware_configuration: null,
  device_id: null,

  valve_initial_position: null,
  valve_does_change_position: false,
  valve_change_position_after_sec: null,

  defaultValues: {},
};

export const METHOD_DEFAULT = {
  ...METHOD_EMPTY,
};

export const METHOD_ERRORS_DEFAULT = {
  ...METHOD_EMPTY,
};

export const METHOD_DEFAULT_NAME_NEW = 'New Method';
