<template>
  <div class="data-state text-gray">
    <div v-if="updating">
      <img src="@/assets/img/updating.svg" />
      <span class="data-state__text">Updating</span>
    </div>
    <div v-else-if="failed">
      <span>
        Update failed.
        <a href="#" @click.prevent="retryClicked">Retry</a>
      </span>
    </div>
  </div>
</template>

<script>
  const RETRY_EVENT = 'retry';

  export default {
    name: 'DataStateComponent',
    props: {
      updating: {
        type: Boolean,
        default: false,
      },
      failed: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      retryClicked() {
        this.$emit(RETRY_EVENT);
      },
    },
  };
</script>

<style lang="scss">
  .data-state {
    height: 16px;
    box-sizing: border-box;

    margin: 0;
    // TODO марджины нужно ставить в родительских компонентах
    @screen sm {
      margin: 0 16px;
    }

    &__text {
      margin-left: 4px;

      display: none;
      @screen sm {
        display: inline;
      }
    }

    img {
      width: 16px;
      height: 16px;
      margin: 0 0 2px 0;
      display: inline-block;
      vertical-align: bottom;
    }
  }
</style>
