var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-test-id":"card-method"}},[_c('MethodEditModal',{attrs:{"configuration":_vm.configuration,"method":_vm.pMethod,"deviceId":_vm.deviceId},on:{"update:method":function($event){return _vm.methodUpdatedInModal($event)},"created":function($event){return _vm.methodUpdatedInModal($event)}}}),_c('div',{staticClass:"method-card"},[_c('div',{staticClass:"method-card__top"},[_c('div',{staticClass:"method-card__label"},[_vm._v("Method")]),_c('div',{staticClass:"method-card__select",class:{
          'method-card__select--visible': _vm.isSelectAlwaysVisible,
        }},[_c('SelectMethodAsPreset',{staticClass:"method-card__select",class:{
            'method-card__select--visible': _vm.isSelectAlwaysVisible,
          },attrs:{"method":_vm.method,"configuration":_vm.configuration},on:{"change":_vm.onPresetChosen,"showPopup":function($event){return _vm.setIsSelectAlwaysVisible(true)},"hidePopup":function($event){return _vm.setIsSelectAlwaysVisible(false)}}})],1),(_vm.method)?_c('div',{staticClass:"method-card__wrapper-name",class:{
          'method-card__wrapper-name--hidden': _vm.isSelectAlwaysVisible,
        }},[_c('span',{staticClass:"method-card__name"},[_vm._v(_vm._s(_vm.method.name))]),_c('span',{staticClass:"method-card__id"},[_vm._v("#"+_vm._s(_vm.method.id))])]):_c('div',{staticClass:"method-card__wrapper-name",class:{
          'method-card__wrapper-name--hidden': _vm.isSelectAlwaysVisible,
        }},[_vm._m(0)]),(_vm.hasPermissionToEdit)?_c('div',{staticClass:"method-card__buttons",staticStyle:{"display":"flex","margin-left":"16px"}},[(_vm.method)?_c('button',{staticClass:"link-button",attrs:{"data-test-id":"btn-edit-method"},on:{"click":function($event){$event.stopPropagation();return _vm.editMethod($event)}}},[_c('i',{staticClass:"material-icons material-icon--14"},[_vm._v("edit")]),_vm._v(" Edit ")]):_vm._e(),_c('button',{staticClass:"link-button",attrs:{"data-test-id":"btn-edit-method"},on:{"click":function($event){$event.stopPropagation();return _vm.addMethod($event)}}},[_c('i',{staticClass:"material-icons material-icon--14"},[_vm._v("add")]),_vm._v(" Add ")])]):_vm._e()]),(_vm.method)?_c('InfoMethod',{attrs:{"method":_vm.method},on:{"click":_vm.editMethod}}):(_vm.hasPermissionToEdit)?_c('i',{staticClass:"text-gray mt-2 block",on:{"click":_vm.addMethod}},[_vm._v("Choose method from the list, or create new")]):_c('i',{staticClass:"text-gray mt-2 block"},[_vm._v("Choose method from the list")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"method-card__name"},[_c('i',[_vm._v("Not specified")])])}]

export { render, staticRenderFns }