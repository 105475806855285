import { Callback, Dict } from '@/types/utility';
import SocketCentrifugeBase, { SubscriptionError } from '@/api/sockets/SocketCentrifugeBase';
import { consoleHelpers } from '@/utils/logHelpers';

export enum DeviceSocketEvents {
  DEVICE = 'device',
  STATE = 'device.state',
  RECENT_RUNS = 'device.recentRuns',
  DOMP_DESCRIPTION = 'device.dompDescription',
  COMMAND_RESPONSE = 'device.command.response',
  ERROR = 'device.error',
  NETWORK_DETAILS = 'device.networkDetails',
  METHOD_RESET = 'device.methodReset',
}

enum DeviceSocketRPC {
  GET = 'device.get',
  DONT_SLEEP = 'device.dontsleep',
  PUSH_LOGS = 'device.pushLogs',
  GET_RECENT_RUNS = 'device.getRecentRuns',
  COMMAND = 'device.command',
  GET_DOMP_DESCRIPTION = 'device.getDompDescription',
}

export default class DeviceSocket extends SocketCentrifugeBase<
  DeviceSocketEvents,
  DeviceSocketRPC
> {
  private static connections: Dict<DeviceSocket> = {};

  public deviceId: string;

  protected listeners: Record<DeviceSocketEvents, Callback[]> = {
    [DeviceSocketEvents.DEVICE]: [],
    [DeviceSocketEvents.STATE]: [],
    [DeviceSocketEvents.RECENT_RUNS]: [],
    [DeviceSocketEvents.DOMP_DESCRIPTION]: [],
    [DeviceSocketEvents.COMMAND_RESPONSE]: [],
    [DeviceSocketEvents.ERROR]: [],
    [DeviceSocketEvents.NETWORK_DETAILS]: [],
    [DeviceSocketEvents.METHOD_RESET]: [],
  };

  private constructor(
    public id: string,
    onConnect?: Callback<[SocketCentrifugeBase<DeviceSocketEvents, DeviceSocketRPC>]>,
    onError?: Callback<[SubscriptionError]>,
  ) {
    super(`personal:device-${id}`, {
      handleEvents: ({ data }) => {
        switch (data.event) {
          case DeviceSocketEvents.DEVICE:
            this.callListeners(DeviceSocketEvents.DEVICE, data.device);
            break;
          case DeviceSocketEvents.STATE:
            this.callListeners(DeviceSocketEvents.STATE, data.state);
            consoleHelpers.warn('Device state', data.state);
            break;
          case DeviceSocketEvents.RECENT_RUNS:
            this.callListeners(DeviceSocketEvents.RECENT_RUNS, data.runs);
            break;
          case DeviceSocketEvents.DOMP_DESCRIPTION:
            this.callListeners(
              DeviceSocketEvents.DOMP_DESCRIPTION,
              data.serial_device_id,
              data.data,
            );
            break;
          case DeviceSocketEvents.COMMAND_RESPONSE:
            this.callListeners(
              DeviceSocketEvents.COMMAND_RESPONSE,
              data.from_who,
              data.response,
              data.varStr,
            );
            break;
          case DeviceSocketEvents.NETWORK_DETAILS:
            this.callListeners(DeviceSocketEvents.NETWORK_DETAILS, data);
            break;
          case DeviceSocketEvents.METHOD_RESET:
            this.callListeners(DeviceSocketEvents.METHOD_RESET);
            break;
          case DeviceSocketEvents.ERROR:
            this.callListeners(DeviceSocketEvents.ERROR, data.error);
            break;
        }
      },
      onConnect,
      onError,
    });

    this.deviceId = id;
    this.dataAddedToEveryRequest = {
      device_id: this.deviceId,
    };
  }

  public static start(
    id: string,
    onConnect?: Callback<[SocketCentrifugeBase<DeviceSocketEvents, DeviceSocketRPC>]>,
    onError?: Callback<[SubscriptionError]>,
  ) {
    const connection = DeviceSocket.connections[id];
    if (connection) {
      connection.addSession();
      onConnect?.(connection);
      return connection;
    }

    DeviceSocket.connections[id] = new DeviceSocket(id, onConnect, onError);
    return DeviceSocket.connections[id];
  }

  close(listenersGroupId: string): boolean {
    const isClosed = super.close(listenersGroupId);

    if (isClosed) {
      delete DeviceSocket.connections[this.id];
    }

    return isClosed;
  }

  public get() {
    return this.sendNamedRPC(DeviceSocketRPC.GET);
  }

  public dontsleep() {
    return this.sendNamedRPC(DeviceSocketRPC.DONT_SLEEP);
  }

  public pushLogs() {
    return this.sendNamedRPC(DeviceSocketRPC.PUSH_LOGS);
  }

  public getRecentRuns() {
    return this.sendNamedRPC(DeviceSocketRPC.GET_RECENT_RUNS);
  }

  public command(to, name, payload) {
    return this.sendNamedRPC(DeviceSocketRPC.COMMAND, { to, name, payload });
  }

  public getDompDescription(serialDeviceId) {
    return this.sendNamedRPC(DeviceSocketRPC.GET_DOMP_DESCRIPTION, {
      serial_device_id: serialDeviceId,
    });
  }
}
