var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"device-method-column"},[[((_vm.device && _vm.device.id) || _vm.method || _vm.column)?[(_vm.device && _vm.device.id)?[_c('div',{staticClass:"device-method-column__device"},[_c('div',{staticClass:"device-method-column__label",class:{
              'device-method-column__label--wide': _vm.postprocessings,
            }},[_vm._v(" Device ")]),(_vm.disableClickAway)?[_vm._v(_vm._s(_vm.device.name))]:_c('div',{staticClass:"device-method-column__wrapper-link-device"},[_c('router-link',{staticStyle:{"display":"inline"},attrs:{"to":_vm.linkToDevice}},[_vm._v(_vm._s(_vm.device.name))])],1),(_vm.vial)?_c('span',{staticClass:"ml-2",staticStyle:{"flex":"none"}},[_vm._v("Vial "+_vm._s(_vm.vial))]):_vm._e()],2)]:_vm._e(),(_vm.method)?[_c('MethodPreviewModal',{attrs:{"name":("method-preview-" + _vm.id + "}"),"method":_vm.method,"isInSharedMode":_vm.isInSharedMode}}),_c('div',{staticStyle:{"display":"flex","width":"fit-content","max-width":"100%","align-items":"center"}},[_c('div',{staticClass:"device-method-column__label",class:{
              'device-method-column__label--wide': _vm.postprocessings,
            },on:{"click":_vm.showPreviewModal}},[_vm._v(" Method ")]),_c('PopupInfoMethodColumn',{staticClass:"device-method-column__link",attrs:{"method":_vm.method,"isDisabled":_vm.methodExpanded},on:{"click":_vm.showPreviewModal}},[_c('a',{on:{"click":function($event){_vm.methodExpanded = !_vm.methodExpanded}}},[_vm._v(" #"+_vm._s(_vm.method.id)+" "+_vm._s(_vm.method.name)+" ")])]),_c('BtnIcon',{staticClass:"device-method-column__btn-details",attrs:{"type":"transparent","iconMaterial":"my_library_books"},on:{"click":_vm.showPreviewModal}}),(!_vm.isInSharedMode)?_c('BtnIcon',{staticClass:"device-method-column__btn-open-in-new",attrs:{"title":"Open in new page","iconMaterial":"open_in_new"},on:{"click":_vm.openMethodPreviewPage}}):_vm._e()],1),(_vm.methodExpanded)?_c('InfoMethod',{staticStyle:{"margin":"8px 0"},attrs:{"method":_vm.method,"bgColor":"#ecefff"},on:{"click":_vm.showPreviewModal}}):_vm._e()]:_vm._e(),(_vm.column)?[_c('div',{staticClass:"device-method-column__column-title"},[_c('div',{staticClass:"device-method-column__label",class:{
              'device-method-column__label--wide': _vm.postprocessings,
            }},[_vm._v(" Column ")]),_c('PopupInfoMethodColumn',{staticClass:"device-method-column__link",attrs:{"column":_vm.column,"isDisabled":_vm.columnExpanded}},[_c('a',{on:{"click":function($event){_vm.columnExpanded = !_vm.columnExpanded}}},[_vm._v(" "+_vm._s(_vm.column.name)+" ")])]),(!_vm.isInSharedMode)?_c('BtnIcon',{staticClass:"device-method-column__btn-open-in-new",attrs:{"title":"Open in new page","iconMaterial":"open_in_new"},on:{"click":_vm.openColumnPage}}):_vm._e()],1),(_vm.columnExpanded)?_c('InfoColumn',{staticStyle:{"margin":"8px 0"},attrs:{"column":_vm.column,"bgColor":"#ecefff"}}):_vm._e()]:_vm._e(),(_vm.isShowSequenceInfo)?[_c('div',{staticClass:"device-method-column__column-title"},[_c('div',{staticClass:"device-method-column__label",class:{
              'device-method-column__label--wide': _vm.postprocessings,
            }},[_vm._v(" Sequence ")]),_c('RouterLink',{attrs:{"to":_vm.linkToSequence}},[_vm._v(" "+_vm._s(_vm.sample.sequence.name)+" ")])],1)]:_vm._e()]:_vm._e()],(!_vm.device && _vm.vial)?[_c('div',{staticClass:"device-method-column__column-title"},[_c('div',{staticClass:"device-method-column__label device-method-column__label--wide"},[_vm._v("Vial")]),_vm._v(" "+_vm._s(_vm.vial)+" ")])]:_vm._e(),(_vm.postprocessings)?[_c('div',{staticClass:"device-method-column__column-title"},[_c('div',{staticClass:"device-method-column__label device-method-column__label--wide"},[_vm._v(" Postprocessing ")]),_vm._l((_vm.postprocessings),function(postprocessing,index){return _c('span',{key:index,staticClass:"device-method-column__measurement"},[(index !== 0)?_c('span',[_vm._v(", ")]):_vm._e(),_c('a',{staticStyle:{"display":"inline"},on:{"click":function($event){return _vm.togglePostprocessing(postprocessing.measurement.id)}}},[_vm._v(" "+_vm._s(postprocessing.measurement.waveLength)+"nm ")])])})],2),_vm._l((_vm.postprocessingsToShow),function(postprocessing){return _c('AppliedPostprocessing',{key:postprocessing.name,staticClass:"device-method-column__postprocessing-details",attrs:{"postprocessing":postprocessing}})})]:_vm._e(),(_vm.postprocessingsToApply)?[_c('div',{staticClass:"device-method-column__column-title"},[_c('div',{staticClass:"device-method-column__label device-method-column__label--wide"},[_vm._v(" Postprocessing ")]),_vm._l((_vm.postprocessingsToApply),function(ref,index){
            var channel = ref[0];
return _c('span',{key:index,staticClass:"device-method-column__measurement"},[(index !== 0)?_c('span',[_vm._v(", ")]):_vm._e(),_c('a',{staticStyle:{"display":"inline"},on:{"click":function($event){return _vm.togglePostprocessing(channel)}}},[_vm._v(" "+_vm._s(_vm.CHANNEL_WAVELENGTH_MAP[channel])+" ")])])})],2),_vm._l((_vm.postprocessingsToApplyToShow),function(ref){
            var channel = ref[0];
            var postprocessing = ref[1];
return _c('PostprocessingToApply',{key:channel,staticClass:"device-method-column__postprocessing-details",attrs:{"postprocessing":postprocessing,"channel":channel}})})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }