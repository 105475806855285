<template>
  <div class="method-preview">
    <template v-if="method">
      <h4>
        Method <span class="method-preview__id">#{{ methodId }}</span>
      </h4>
      <h5>You cannot edit this method because it has already been used</h5>

      <div class="method-preview__line" />

      <MethodPreviewDetails :method="method" class="method-preview__data" />
    </template>

    <div v-else-if="isLoading">
      <LoadingComponent />
    </div>
  </div>
</template>

<script>
  import LoadingComponent from 'components/element/LoadingComponent';
  import MethodPreviewDetails from 'components/blocks/method/preview/MethodPreviewDetails';
  import MethodAPI from '@/api/method';

  export default {
    name: 'MethodPreview',

    components: {
      LoadingComponent,
      MethodPreviewDetails,
    },

    props: {
      methodId: {
        type: Number,
        required: true,
      },
    },

    data: () => ({
      method: null,
      isLoading: false,
    }),

    watch: {
      methodId: {
        handler() {
          this.setMethod(null);
          this.initMethod();
        },
        immediate: true,
      },
    },

    methods: {
      setMethod(method) {
        this.method = method;
      },

      handleSubmitError(data, status) {
        const { method, name } = data;

        if (status === 400) {
          if (method) {
            Object.keys(method).forEach((field) => {
              this.errors[field] = method[field][0];
            });
          }

          if (name) {
            this.errors.name = name[0];
          }
        } else {
          this.notifyResponseError(data, status);
        }
      },

      initMethod() {
        this.isLoading = true;

        MethodAPI.get(
          this.methodId,
          (method) => {
            this.setMethod(method);
            this.isLoading = false;
          },
          () => {
            this.notifyError('Unable to get a method!');
            this.isLoading = false;
          },
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .method-preview {
    h4 {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    h5 {
      padding-top: 0;
      margin-top: 8px;
      margin-bottom: 32px;
    }

    @media (max-width: $screen-sm-max) {
      padding: 0 10px;
    }

    &__line {
      height: 2px;
      background: $color-bg-third;
      margin-bottom: 32px;
    }

    &__title {
      font-size: $size-xl;
      font-weight: $weight-semi-bold;
      margin-bottom: 30px;
    }

    &__data {
      margin-bottom: 20px;
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
    }

    &__btn {
      margin-left: 15px;

      @media (max-width: $screen-sm-max) {
        &:first-child {
          margin-left: 0;
        }
      }
    }

    &__id {
      font-size: $size-xs;
      color: $color-text-third;
      margin-left: 5px;
    }
  }
</style>
