<template>
  <button v-if="!show && nArchived" class="link-button link-button--normal" @click="show = true">
    Show {{ nArchived }} archived
  </button>
  <button v-else-if="nArchived" class="link-button link-button--normal" @click="show = false">
    Hide archived
  </button>
</template>

<script>
  export default {
    name: 'ShowArchivedToggle',
    model: {
      prop: 'value',
      event: 'update:value',
    },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      nArchived: {
        type: [Number, Boolean],
        default: 0,
      },
    },
    data() {
      return {
        show: this.value,
      };
    },
    watch: {
      value(show) {
        this.show = show;
      },
      show(show) {
        this.$emit('update:value', show);
      },
    },
  };
</script>
