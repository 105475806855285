import { REST } from 'utils/rest';

export class InjectionCsv {
  static urlFor(id, shareToken) {
    return `/api/injection/${id.toString()}/measurements/csv/${
      shareToken ? `?share_token=${shareToken}` : ''
    }`;
  }

  static get({ id, shareToken }, onSuccess, onError) {
    return REST.get(InjectionCsv.urlFor(id, shareToken), onSuccess, onError);
  }
}
