<template>
  <div class="injection-new">
    <div style="margin: 32px 0 0 32px">
      <h2 class="inline-block" style="padding: 0; margin: 0 0 8px 0">
        <i class="submain">show_chart</i>
        {{ injectionName }}
      </h2>
      <div style="font-size: 13px">Setup new injection</div>
    </div>

    <div style="margin: 32px 32px 0">
      <DecoratorInput iconSize="sm" iconTitle="notes" style="margin-bottom: 16px">
        <textarea
          v-model="description"
          rows="1"
          placeholder="Description"
          class="injection-new__input"
        />
      </DecoratorInput>

      <TransitionExpand>
        <DecoratorInput
          v-if="fieldVial"
          iconSize="sm"
          iconTitle="group_work"
          style="margin-bottom: 16px"
        >
          <InputVials v-model="vial" :trayConfig="trayConfig" data-test-id="input-vial" />
        </DecoratorInput>
      </TransitionExpand>

      <span style="padding: 16px 0 3px 0; display: inline-block; margin-bottom: 10px">
        Device and configuration
      </span>

      <div class="flex-responsive-2-columns">
        <DeviceMethodColumnInputs
          v-model="dmc"
          :isReadonly="readonlyDMC"
          :isRequired="true"
          style="flex: 1 1 0"
        />
        <div class="button-container">
          <button
            class="button--square-image-primary"
            :disabled="!valid || isStarting"
            data-test-id="btn-start"
            @click="start"
          >
            <LoaderSpinner v-if="isStarting" class="injection-new__loader" />
            <template v-else>
              <i class="material-icons material-icon--18">play_arrow</i>
              Start
            </template>
          </button>
        </div>
      </div>
    </div>

    <div v-if="isShowConfigurationChangedMessage" class="injection-new__warning">
      <template v-if="hasInjections">
        The last used method is not available for the current device configuration! <br />
        All injections within the standard must use the same method.
      </template>
      <template v-else>
        The selected method is not available for the current device configuration! <br />
        Edit the standard settings to set a new method.
      </template>
    </div>
  </div>
</template>

<script>
  import DeviceMethodColumnInputs from 'components/block/DeviceMethodColumnInputs.vue';
  import DecoratorInput from '@/uikitProject/decorators/DecoratorInput';

  import DeviceAPI from '@/api/device';
  import InputVials from '../../uikitProject/vials/vueInputVials/InputVials';
  import TransitionExpand from '@/uikitProject/transitions/TransitionExpand';
  import LoaderSpinner from '@/uikitProject/loaders/LoaderSpinner';
  import SampleSocket, { SampleSocketEvents } from 'api/sockets/SampleSocket';
  import { StandardSocketEvents } from 'api/sockets/StandardSocket';
  import { getErrorMessage } from '@/utils/errorHelpers';

  const EVENT_INJECTION_CREATE = 'injectionCreate';

  export default {
    name: 'InjectionNewComponent',

    components: {
      LoaderSpinner,
      TransitionExpand,
      InputVials,
      DeviceMethodColumnInputs,
      DecoratorInput,
    },

    props: {
      sampleSocket: Object,

      injectionName: { type: String, default: '1' },

      defaultVial: { type: [Number, String], default: '' },

      readonlyDMC: { type: Boolean, default: false }, // disable device, method, column changes
      defaultDevice: { type: Object, default: undefined }, // { id: ## }
      defaultMethod: { type: Object, default: undefined }, // { id: ## }
      defaultColumn: { type: Object, default: undefined }, // { id: ## }

      doResetOnDefaultValuesUpdate: {
        type: Boolean,
      },
      isStandard: {
        type: Boolean,
      },
      hasInjections: {
        type: Boolean,
      },
    },

    data() {
      return {
        description: '',
        vial: this.defaultVial,
        dmc: {
          device: this.defaultDevice,
          method: this.defaultMethod,
          column: this.defaultColumn,
        },
        isInitialized: true,
        isStarting: false,
      };
    },

    computed: {
      isShowConfigurationChangedMessage() {
        return this.isStandard && !this.dmc.method;
      },

      valid() {
        return (
          this.dmc.device != null &&
          this.dmc.method != null &&
          this.dmc.column != null &&
          (!this.fieldVial || this.vial)
        );
      },
      fieldVial() {
        return (
          this.dmc.device?.configuration?.autosampler != null ||
          this.dmc.device?.configuration?.autosampler_fraction_collector
        );
      },

      trayConfig() {
        const { autosampler } = this.dmc.device?.configuration;
        if (autosampler?.supported_trays && autosampler?.tray != null) {
          return Object.entries(autosampler?.supported_trays).find(
            ([, id]) => id === autosampler?.tray,
          )[0];
        }
        // const autosamplerFractionCollector = this.dmc.device?.configuration
        //   ?.autosampler_fraction_collector;
        // if (
        //   autosamplerFractionCollector?.supported_injection_trays &&
        //   autosamplerFractionCollector?.injection_tray != null
        // ) {
        //   return Object.entries(autosamplerFractionCollector?.supported_injection_trays).find(
        //     ([, id]) => id === autosamplerFractionCollector.injection_tray,
        //   )[0];
        // }
        return null;
      },

      socketEvents() {
        return this.sampleSocket instanceof SampleSocket
          ? SampleSocketEvents
          : StandardSocketEvents;
      },

      defaultValues() {
        return [this.defaultDevice, this.defaultMethod, this.defaultColumn];
      },
    },

    watch: {
      defaultValues() {
        if (this.doResetOnDefaultValuesUpdate) {
          this.resetDMC();
        }
      },
    },

    methods: {
      resetDMC() {
        this.dmc = {
          device: this.defaultDevice,
          method: this.defaultMethod,
          column: this.defaultColumn,
        };
      },

      start() {
        const onPatchSuccess = () => {
          try {
            this.showNotificationIfRpcError(async () => {
              const data = await this.sampleSocket.injectionNew(
                this.description,
                this.dmc.device?.id,
                this.vial,
              );
              this.$emit(EVENT_INJECTION_CREATE, data);
            });
          } catch (e) {
            this.notifyError(getErrorMessage(e.message));
          } finally {
            this.isStarting = false;
          }
        };

        this.isStarting = true;

        this.patchDevice(
          this.dmc.device?.id,
          this.dmc.method?.id,
          this.dmc.column?.id,
          onPatchSuccess,
          () => {
            this.isStarting = false;
          },
        );
      },

      patchDevice(deviceID, methodID, columnID, onSuccess, onError) {
        const data = { column_id: columnID, method_id: methodID };
        DeviceAPI.patch(deviceID, data, onSuccess, (data, code) => {
          this.notifyResponseError(data, code);
          onError?.();
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .injection-new {
    &__loader {
      display: inline-block;
    }

    &__warning {
      margin: 20px 32px 0;
      padding: 10px;
      background-color: $pure-color__yellow;
      text-align: center;
      border-radius: $border-radius__sm;
    }
  }

  .flex-responsive-2-columns {
    display: flex;
    align-items: stretch;

    flex-direction: column;
    @screen sm {
      flex-direction: row;
    }

    .button-container {
      display: flex;
      padding: 32px 0 0 32px;

      flex-direction: row-reverse;
      @screen sm {
        flex-direction: column-reverse;
      }
    }
  }
</style>
