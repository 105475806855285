<template>
  <div v-if="hasData" class="chromatogram-edit">
    <!-- Div for centering -->
    <div class="chromatogram-edit__spacer"></div>

    <Panel>
      <template v-if="hasJustOneChart">
        <template v-if="isEditable">
          <Item title="Edit peaks">
            <PopupHelper text="Manual peak integration" placement="bottom">
              <RadioBtn
                :checked="mode"
                name="mode"
                value="peakAdd"
                :doUncheckIfSelected="true"
                fallbackValue="zoom"
                class="chromatogram-edit__btn chromatogram-edit__btn--radio"
                @change="setMode"
              >
                M∫
              </RadioBtn>
            </PopupHelper>
            <PopupHelper text="Remove peak integration" placement="bottom">
              <RadioBtn
                :checked="mode"
                name="mode"
                value="peakDelete"
                class="chromatogram-edit__btn chromatogram-edit__btn--radio"
                :doUncheckIfSelected="true"
                fallbackValue="zoom"
                @change="setMode"
              >
                <IconMaterial title="delete_outline" />
              </RadioBtn>
            </PopupHelper>
          </Item>
          <Divider />
          <Item title="Postprocessing" :stylesTitle="{ left: '24px' }">
            <template>
              <PopupHelper
                v-if="hasJustOneChart"
                :text="
                  hasPostprocessing
                    ? 'Reset postprocessing'
                    : 'Select and apply postprocessing template to selected channel'
                "
                placement="bottom"
              >
                <Btn
                  padding="sm"
                  type="transparent"
                  class="chromatogram-edit__btn"
                  @click="
                    $emit(hasPostprocessing ? 'resetPostprocessing' : 'applyPostprocessingTemplate')
                  "
                >
                  <IconMaterial v-if="hasPostprocessing" title="delete_outline" />
                  <IconMaterial v-else title="query_stats" size="18" />
                </Btn>
              </PopupHelper>
              <PopupHelper
                v-if="measurementsWithPostprocessingError.length && !isShared"
                text="Postprocessing was computed with error. Click to recalculate"
                placement="bottom"
              >
                <Btn
                  type="transparent"
                  padding="xs"
                  height="m"
                  class="chromatogram-edit__btn chromatogram-edit__btn-recalculate"
                  @click="recalculatePostprocessing"
                  >Rerun postprocessing
                </Btn>
              </PopupHelper>
            </template>
            <Divider />
          </Item>
        </template>
        <template v-else>
          <Item v-if="measurementsWithPostprocessingError.length" title="Postprocessing">
            <template>
              <PopupHelper
                v-if="measurementsWithPostprocessingError.length && !isShared"
                text="Postprocessing was computed with error. Click to recalculate"
                placement="bottom"
              >
                <Btn
                  type="transparent"
                  padding="xs"
                  height="m"
                  class="chromatogram-edit__btn chromatogram-edit__btn-recalculate"
                  @click="recalculatePostprocessing"
                  >Rerun postprocessing
                </Btn>
              </PopupHelper>
            </template>
            <Divider />
          </Item>
        </template>

        <Item title="Tools">
          <BtnCompare v-if="canCompare && id" :id="id" class="chromatogram-edit__btn" />

          <template v-if="hasDetectionTime">
            <PopupHelper v-if="!isFullChart" text="Show original detection time" placement="bottom">
              <Btn
                padding="sm"
                type="transparent"
                class="chromatogram-edit__btn"
                @click="showFullChart"
              >
                <IconMaterial title="crop_free" />
              </Btn>
            </PopupHelper>
            <PopupHelper v-else text="Show detection time from method" placement="bottom">
              <RadioBtn
                :checked="mode"
                name="mode"
                value="selectDetectionZone"
                class="chromatogram-edit__btn chromatogram-edit__btn--radio"
                :doUncheckIfSelected="true"
                fallbackValue="zoom"
                @change="showDetectionTime"
              >
                <IconMaterial title="crop" />
              </RadioBtn>
            </PopupHelper>
          </template>

          <PopupHelper text="Edit the appearance of the chromatogram" placement="bottom">
            <Btn
              padding="sm"
              type="transparent"
              class="chromatogram-edit__btn"
              @click="editChromatogramAppearance"
            >
              <IconMaterial title="settings" />
            </Btn>
          </PopupHelper>

          <!--        <Btn padding="sm" type="transparent" class="chromatogram-edit__btn">-->
          <!--          <IconMaterial title="star" />-->
          <!--        </Btn>-->
        </Item>
      </template>
      <template v-else>
        <Item v-if="measurementsWithPostprocessingError.length" title="Postprocessing">
          <template>
            <PopupHelper
              v-if="measurementsWithPostprocessingError.length && !isShared"
              text="Postprocessing was computed with error. Click to recalculate"
              placement="bottom"
            >
              <Btn
                type="transparent"
                padding="xs"
                height="m"
                class="chromatogram-edit__btn chromatogram-edit__btn-recalculate"
                @click="recalculatePostprocessing"
                >Rerun postprocessing
              </Btn>
            </PopupHelper>
          </template>
          <Divider />
        </Item>

        <Item title="Tools">
          <template v-if="hasDetectionTime">
            <PopupHelper v-if="!isFullChart" text="Show original detection time" placement="bottom">
              <Btn
                padding="sm"
                type="transparent"
                class="chromatogram-edit__btn"
                @click="showFullChart"
              >
                <IconMaterial title="crop_free" />
              </Btn>
            </PopupHelper>
            <PopupHelper v-else text="Show detection time from method" placement="bottom">
              <RadioBtn
                :checked="mode"
                name="mode"
                value="selectDetectionZone"
                class="chromatogram-edit__btn chromatogram-edit__btn--radio"
                :doUncheckIfSelected="true"
                fallbackValue="zoom"
                @change="showDetectionTime"
              >
                <IconMaterial title="crop" />
              </RadioBtn>
            </PopupHelper>
          </template>

          <PopupHelper text="Edit the appearance of the chromatogram" placement="bottom">
            <Btn
              padding="sm"
              type="transparent"
              class="chromatogram-edit__btn"
              @click="editChromatogramAppearance"
            >
              <IconMaterial title="settings" />
            </Btn>
          </PopupHelper>
        </Item>
      </template>
    </Panel>

    <Btn
      type="transparent"
      class="chromatogram-edit__btn chromatogram-edit__btn--close"
      padding="sm"
      @click="close"
    >
      <IconMaterial title="close" />
    </Btn>

    <!--Absolute-->
    <EditChromatogramAppearanceModal @updateAppearance="$emit('updateAppearance', $event)" />
  </div>
  <div
    v-else-if="measurementsWithPostprocessingError.length && !isShared"
    class="chromatogram-edit"
  >
    <Panel>
      <Item>
        <PopupHelper
          text="Postprocessing was computed with error. Click to recalculate"
          placement="bottom"
        >
          <Btn
            type="transparent"
            padding="xs"
            height="m"
            class="chromatogram-edit__btn chromatogram-edit__btn-recalculate"
            @click="recalculatePostprocessing"
            >Rerun postprocessing</Btn
          >
        </PopupHelper>
      </Item>
    </Panel>
  </div>
</template>

<script>
  import Panel from '@/uikitProject/chromatogram/panels/shared/Panel';
  import Item from '@/uikitProject/chromatogram/panels/shared/Item';
  import Divider from '@/uikitProject/chromatogram/panels/shared/Divider';
  import RadioBtn from '@/uikitProject/switchers/RadioBtn';
  import Btn from '@/uikitBase/btns/Btn';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import BtnCompare from '@/uikitProject/chromatogram/panels/vueChromatogramEdit/private/BtnCompare';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper';
  import EditChromatogramAppearanceModal from '@/uikitProject/chromatogram/panels/vueChromatogramEdit/private/EditChromatogramAppearanceModal.vue';
  import { apiMeasurements } from '@/api/graphql/cloud/measurements';
  import { reapplyPostprocessing } from '@/utils/postprocessingHelpers';

  const EVENT_MODEL = 'model';
  const EVENT_CLOSE = 'close';
  const EVENT_SHOW_FULL_CHART = 'showFullChart';
  const EVENT_SHOW_DETECTION_TIME = 'showDetectionTime';
  const EVENT_REAPPLY_POSTPROCESSING = 'reapplyPostprocessing';

  export default {
    name: 'ChromatogramEdit',

    components: {
      EditChromatogramAppearanceModal,
      PopupHelper,
      BtnCompare,
      IconMaterial,
      Btn,
      RadioBtn,
      Divider,
      Item,
      Panel,
    },

    model: {
      prop: 'mode',
      event: EVENT_MODEL,
    },

    props: {
      id: {
        type: Number,
      },

      mode: {
        type: String,
        required: true,
        validator(value) {
          const availableValues = ['peakAddMagnet', 'peakAdd', 'peakDelete', 'zoom'];
          return availableValues.includes(value);
        },
      },
      isFullChart: {
        type: Boolean,
      },
      hasDetectionTime: {
        type: Boolean,
      },

      hasJustOneChart: {
        type: Boolean,
        default: true,
      },

      hasPeaksWithEstimatedGaussian: {
        type: Boolean,
      },

      isEditable: {
        type: Boolean,
        default: true,
      },
      canCompare: {
        type: Boolean,
        default: true,
      },

      hasPostprocessing: {
        type: Boolean,
      },

      measurements: {
        type: Array,
        required: true,
      },

      sampleId: {
        type: Number,
        required: true,
      },

      hasData: {
        type: Boolean,
        required: true,
      },
      isShared: {
        type: Boolean,
        required: true,
      },
    },

    computed: {
      measurementsWithPostprocessingError() {
        return this.measurements.filter((measurement) => measurement?.processing_state === 'error');
      },
    },

    methods: {
      setMode(mode) {
        this.$emit(EVENT_MODEL, mode);
      },
      close() {
        this.$emit(EVENT_CLOSE);
      },

      showFullChart() {
        this.$emit(EVENT_SHOW_FULL_CHART);
      },
      showDetectionTime() {
        this.$emit(EVENT_SHOW_DETECTION_TIME);
      },

      editChromatogramAppearance() {
        this.$modal.show('editChromatogramAppearance');
      },

      async recalculatePostprocessing() {
        await Promise.all(
          this.measurementsWithPostprocessingError.map(async (_measurement) => {
            const measurement = await apiMeasurements.getMeasurement(
              _measurement.id,
              _measurement.ulid,
              this.sampleId,
            );
            try {
              await reapplyPostprocessing(measurement);
            } catch (e) {
              this.notifyError(e.message);
            }
          }),
        );
        this.$emit(EVENT_REAPPLY_POSTPROCESSING);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chromatogram-edit {
    display: flex;
    align-items: center;

    &__btn {
      min-width: 43px;
      font-weight: $weight-normal;

      &--radio {
        color: $color-text-primary;

        &--selected {
          color: white;
        }
      }

      &--loading {
        cursor: wait;
      }
    }

    &__icon-gaussian {
      width: 16px;
    }

    &__wrapper-icon-add-magnet {
      position: relative;
      display: flex;
      align-items: center;
    }

    &__icon-magnet {
      position: absolute;
      width: 4px;
      bottom: 0;
      right: 1px;
    }

    &__btn-close {
      color: $color-text-third;
    }

    &__spacer {
      width: 43px;

      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }

    &__checkbox-postprocessing {
      height: 30px;
    }

    &__btn-recalculate {
      font-weight: $weight-normal;
    }
  }
</style>
