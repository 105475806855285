<template>
  <div class="divider"></div>
</template>

<script>
  export default {
    name: 'Divider',
  };
</script>

<style lang="scss" scoped>
  .divider {
    height: 17px;
    width: 1px;
    background-color: #c4c4c4;
    margin: 0 12px;
  }
</style>
