<template>
  <div>
    <div class="grid-header-container">
      <router-link style="display: inline-block; white-space: nowrap" to="/sequences/">
        <i class="material-icons material-icon--16 no-text-decoration">keyboard_backspace</i>
        Sequences
      </router-link>

      <span id="title_new_injection" style="display: block; color: #00000066; text-align: center">
        <i class="material-icons material-icon--14">group_work</i>
        Create a New Sequence
      </span>
    </div>

    <SequenceNewComponent style="margin-top: 16px" />
  </div>
</template>

<script>
  import PageTitle from '@/mixins/page-title';
  import SequenceNewComponent from 'components/block/SequenceNewComponent';

  export default {
    name: 'SampleNewPage',

    components: {
      SequenceNewComponent,
    },

    mixins: [PageTitle],

    data: () => ({
      pageTitle: 'HPLC Cloud: New Sequence',
    }),
  };
</script>

<style lang="scss">
  .field-wrapper {
    padding-top: 16px;

    & .small-field {
      min-width: 280px;
      width: 60%;
    }
  }

  .row {
    display: flex;

    &.center {
      align-items: center;
    }
  }
</style>
