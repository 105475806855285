<template>
  <div class="table">
    <div class="table__head" :class="classHead">
      <div v-for="column of columnsFiltered" :key="column.id" class="table__column">
        <div class="table__cell table__cell--head">
          <PopupHelper :text="column.hint">
            <span>
              {{ column.title }}<span class="table__label-data-type">, {{ column.dataType }}</span>
            </span>
          </PopupHelper>
        </div>
      </div>
    </div>
    <div class="table__body">
      <PrRowCreator
        v-if="!isDisabled && rows.length"
        v-test="{ id: 'btnAddRowFirst' }"
        class="table__add-row"
        @add="addRowFirst"
      />
      <transition-group v-if="rows.length" name="row-position">
        <div
          v-for="({ id, cells, isValidPercent }, indexRow) of rows"
          :key="id"
          class="table__row"
          :class="!isValidPercent && 'table__row--invalid'"
        >
          <div v-test="{ id: 'row' }" class="table__row-cells" :class="classRowCells">
            <PrCell
              v-for="(cell, indexCell) of cells"
              :key="cell.indexCell"
              v-test="{ id: 'cell' }"
              :data="cell"
              class="table__cell table__cell--data"
              :step="step"
              :maxTime="maxTime"
              :isDisabled="(indexRow === 0 && indexCell === 0) || isDisabled"
              @change="editCell({ indexCell, indexRow, value: $event })"
            />
            <div
              v-if="!isDisabled && rows.length !== 1 && indexRow !== 0"
              class="table__cell table__cell--delete"
            >
              <BtnIcon
                v-test="{ id: 'btnDeleteRow' }"
                iconMaterial="clear"
                class="table__btn-delete"
                @click="deleteRow(id)"
              />
            </div>
          </div>
          <PrRowCreator
            v-if="!isDisabled && indexRow !== rows.length - 1"
            v-test="{ id: 'btnAddRowInside' }"
            class="table__add-row"
            @add="addRow(indexRow + 1)"
          />
        </div>
        <PopupHelper
          v-if="!isDisabled"
          key="row-add"
          v-test="{ id: 'btnAddRowLast' }"
          :isFullscreen="true"
          text="Adds another row to the gradient table."
          class="table__row table__row--clickable"
          @click="addRowLast"
        >
          <div class="table__row-cells" :class="classRowCells">
            <div class="table__cell table__cell--add-row">
              <div class="table__add-last-row">
                <IconMaterial title="add" class="table__icon-add" />
                Add row
              </div>
            </div>
          </div>
        </PopupHelper>
      </transition-group>
      <!--Duplicate this row for nice animation. We need to skip the animation on initial render-->
      <div
        v-if="!isDisabled && !rows.length"
        key="row-add"
        v-test="{ id: 'btnAddRowLast' }"
        class="table__row table__row--clickable"
        @click="addRowLast"
      >
        <div class="table__row-cells" :class="classRowCells">
          <div class="table__cell table__cell--add-row">
            <div class="table__add-last-row">
              <IconMaterial title="add" class="table__icon-add" />
              Add row
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isDisabled && !rows.length" class="table__no-data">No data</div>
    <div v-if="error" class="table__error">
      {{ error }}
    </div>
  </div>
</template>

<script>
  import Decimal from 'decimal.js-light';

  import PrCell from '@/uikitProject/tables/shared/vueTableCell/TableCell';
  import PrRowCreator from '@/uikitProject/tables/gradient/private/PrRowCreator';
  import BtnIcon from '@/uikitBase/btns/BtnIcon';

  import testDirective from '@/directives/test';

  import { GradientTableHelper } from '@/uikitProject/tables/gradient/utils/GradientTableHelper.ts';
  import { GradientTableAdapter } from '@/uikitProject/tables/gradient/utils/GradientTableAdapter.ts';

  import { COLUMNS } from '@/uikitProject/tables/gradient/constants/index.ts';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper.vue';

  const EVENT_MODEL = 'model';

  const FRACTIONS_MIN_COUNT = 2;
  const FRACTIONS_MAX_COUNT = 4;

  export default {
    name: 'TableGradient',

    directives: { test: testDirective },

    components: {
      PopupHelper,
      IconMaterial,
      BtnIcon,
      PrRowCreator,
      PrCell,
    },

    model: {
      prop: 'data',
      event: EVENT_MODEL,
    },

    props: {
      /* example:
      {
        validation: {isValid: Boolean, error: string},
        gradient: [{time: '10.2', fractions: [0.2, 0.3, 0.5]}]
      }
      */
      data: {
        type: Object,
        required: true,
      },
      fractionsCount: {
        type: Number,
        default: FRACTIONS_MIN_COUNT,
        validator(value) {
          return value >= FRACTIONS_MIN_COUNT && value <= FRACTIONS_MAX_COUNT;
        },
      },
      step: {
        type: Number,
        default: 0.01,
      },
      maxTime: {
        type: Number,
        required: true,
      },
      error: String,
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      columns: COLUMNS,
    }),

    computed: {
      classHead() {
        return `table__head--fractions--${this.fractionsCount}`;
      },
      classRowCells() {
        return `table__row-cells--fractions--${this.fractionsCount}`;
      },

      columnsFiltered() {
        if (this.fractionsCount >= FRACTIONS_MAX_COUNT) return this.columns;

        const NOT_FRACTION_COLUMNS = 1;
        return this.columns.slice(0, this.fractionsCount + NOT_FRACTION_COLUMNS);
      },
      gradientFiltered() {
        return this.data.gradient.map((_item) => {
          const item = { ..._item };
          const { fractions } = item;
          if (fractions.length !== this.fractionsCount) {
            item.fractions =
              fractions.length >= this.fractionsCount
                ? fractions.slice(0, this.fractionsCount)
                : [...fractions, ...Array(this.fractionsCount - fractions.length).fill(0)];
          }

          return item;
        });
      },
      rows() {
        return this.data.gradient.length
          ? GradientTableAdapter.toRows(this.gradientFiltered, this.fractionsCount)
          : [];
      },
    },

    watch: {
      maxTime() {
        this.setData(this.gradientFiltered);
      },
      fractionsCount() {
        this.setData(this.gradientFiltered);
      },
      data() {
        this.initDefaultData();
      },
    },

    created() {
      this.initDefaultData();
      this.initValidation();
    },

    methods: {
      setData(gradient) {
        this.$emit(EVENT_MODEL, {
          validation: this.validateGradient(gradient),
          gradient,
        });
      },

      validateGradient(data) {
        return GradientTableHelper.validateGradient(data, this.maxTime, this.step);
      },

      insertRow(insertBefore) {
        const gradient = GradientTableHelper.insertRow(
          this.data.gradient,
          insertBefore,
          this.step,
          this.fractionsCount,
        );

        this.setData(gradient);
      },

      insertFirstRow() {
        const gradient = GradientTableHelper.insertFirstRow(
          this.data.gradient,
          this.step,
          this.fractionsCount,
        );

        this.setData(gradient);
      },

      editCell({ indexRow, indexCell, value: _value }) {
        const isPercentCellChanged = indexCell > 0;
        const value = Number(_value);
        const rows = [...this.rows];

        // Special logic if there are two fractions in the table
        if (isPercentCellChanged && this.fractionsCount === 2) {
          const secondValue = new Decimal(1).minus(value).toNumber();

          rows[indexRow].cells = [
            // Time cell
            rows[indexRow].cells[0],
            // Next cells have percent values
            {
              ...rows[indexRow].cells[1],
              value: indexCell === 1 ? value : secondValue < 0 ? 0 : secondValue,
            },
            {
              ...rows[indexRow].cells[2],
              value: indexCell === 2 ? value : secondValue < 0 ? 0 : secondValue,
            },
          ];
        } else {
          rows[indexRow].cells[indexCell] = {
            ...rows[indexRow].cells[indexCell],
            value: Number(value),
          };
        }

        const gradient = GradientTableAdapter.fromRows(rows);

        this.setData(gradient);
      },

      addRow(insertBefore) {
        this.insertRow(insertBefore);
      },
      addRowFirst() {
        this.insertFirstRow();
      },
      addRowLast() {
        this.rows.length ? this.insertRow(this.rows.length) : this.addRowFirst();
      },

      deleteRow(rowId) {
        const rows = this.rows.filter((row) => row.id !== rowId);

        const gradient = GradientTableAdapter.fromRows(rows);

        this.setData(gradient);
      },

      initValidation() {
        this.setData(this.data.gradient);
      },
      initDefaultData() {
        if (!this.data.gradient.length) {
          this.addRowFirst();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .table {
    text-align: center;
    position: relative;
    width: 100%;

    &__error {
      color: $color-text-danger;
      margin-bottom: 10px;
      margin-top: 0;
      text-align: left;
    }

    &__no-data {
      text-align: center;
      padding: 10px 0;
    }

    &__head,
    &__row-cells {
      display: grid;
      grid-auto-flow: column;
      color: $color-text-header;

      &--fractions {
        &--2 {
          grid-template-columns: 1fr 1fr 1fr 34px;
        }
        &--3 {
          grid-template-columns: 1fr 1fr 1fr 1fr 34px;
        }
        &--4 {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 34px;
        }
      }
    }

    &__head {
      text-align: right;
      height: 32px;
      font-size: 13px;
      background-color: $color-bg-transparent--nth;
      font-weight: $weight-bold;
    }

    &__footer {
      text-align: left;
    }

    &__row {
      position: relative;
      transition: background-color 0.3s;
      background-color: white;
      text-align: right;

      &:nth-child(even) {
        background-color: $color-bg-transparent--nth;
      }

      &--invalid,
      &--invalid:nth-child(even) {
        background-color: $color-bg-highlight-danger;
      }

      &--clickable {
        cursor: pointer;

        &:hover {
          background-color: $color-bg-second;
        }
      }
    }

    &__row-cells {
      color: $color-text-default;
      height: 34px;
    }

    &__cell {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &--head {
        padding: 0 20px;

        @media (max-width: $screen-md-max) {
          padding: 0 5px;
        }

        @media (max-width: $screen-xs-max) {
          padding: 0 10px;
        }
      }

      &--add-row {
        width: 100%;
        display: flex;
        grid-column-start: 1;
        grid-column-end: -1;
        padding: 0 20px;

        @media (max-width: $screen-md-max) {
          padding: 0 5px;
        }
      }
    }

    &__label-data-type {
      font-weight: $weight-medium;
      font-style: italic;
    }

    &__add-row {
      position: absolute;
      width: 100%;
      z-index: 2;
    }

    &__btn-delete {
      height: 34px;
      width: 34px;
      font-size: 16px;
    }

    &__add-last-row {
      text-align: left;
      width: 100%;
    }

    &__icon-add {
      font-size: 18px;
      margin-right: 10px;
    }
  }

  .row-position {
    &-move {
      transition: transform 200ms;
    }

    &-leave-active {
      width: 100%;
      position: absolute;
    }

    &-leave-to {
      opacity: 0;
    }
  }
</style>
