var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Tippy',{ref:"tippy",attrs:{"isDisabled":_vm.isDisabled,"trigger":"click","isInteractive":true,"placement":"bottom-start","duration":[67, 0],"distance":"0","arrow":false,"popperOptions":{
    modifiers: { preventOverflow: { boundariesElement: 'window' } },
  }},on:{"show":_vm.handleShowPopup,"hide":_vm.handleHidePopup},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
  var isShow = ref.isShow;
return [_vm._t("select",[(_vm.title)?_c('div',{staticClass:"select-desktop__title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('SelectTrigger',{ref:"selectTrigger",attrs:{"itemSelected":_vm.itemSelected,"labelProp":_vm.labelProp,"stylesInputSearch":_vm.stylesInputSearch,"isShow":isShow,"isSearchActive":_vm.isSearchActive,"isTransparent":_vm.isTransparent,"isDisabled":_vm.isDisabled,"hasSearch":_vm.hasSearch,"padding":_vm.padding,"messageNoItemSelected":_vm.messageNoItemSelected,"messageNoItems":_vm.messageNoItems,"placeholderSearch":_vm.placeholderSearch,"isShowSelectedItem":_vm.isShowSelectedItem,"title":_vm.title},on:{"click":_vm.activate},scopedSlots:_vm._u([{key:"info",fn:function(){return [_vm._t("rightSideInsideItem",null,{"item":_vm.itemSelected,"hide":_vm.hidePopup})]},proxy:true}],null,true),model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],{"itemSelected":_vm.itemSelected,"isShow":isShow})]}},{key:"default",fn:function(ref){
  var isShow = ref.isShow;
  var triggerWidth = ref.triggerWidth;
  var triggerOffsetLeft = ref.triggerOffsetLeft;
  var hide = ref.hide;
return [(isShow)?_c('GlobalEvents',{on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return hide($event)}}}):_vm._e(),_c('div',{ref:"popup",staticClass:"select__dropdown",style:(_vm.getPopupStyles(triggerWidth, triggerOffsetLeft))},[_vm._t("default",[_c(_vm.dropdownComponent,{tag:"component",attrs:{"items":_vm.itemsFiltered,"itemSelected":_vm.itemSelected,"labelProp":_vm.labelProp,"messageNoItems":_vm.messageNoItems,"isActive":isShow,"canCreate":_vm.canCreate,"canCreateIfNotFound":_vm.canCreateIfNotFound,"messageCreate":_vm.messageCreate},on:{"select":_vm.selectItem,"create":function($event){return _vm.$emit('create', _vm.searchValue)},"hide":_vm.hidePopup},scopedSlots:_vm._u([{key:"leftSideInsideItem",fn:function(ref){
  var item = ref.item;
return [_vm._t("leftSideInsideItem",null,{"item":item,"isInDropdown":true})]}},{key:"rightSideInsideItem",fn:function(ref){
  var item = ref.item;
return [_vm._t("rightSideInsideItem",null,{"item":item,"hide":_vm.hidePopup,"isInDropdown":true})]}}],null,true)})],{"selectItem":_vm.selectItem,"itemsFiltered":_vm.itemsFiltered,"messageNoItems":_vm.messageNoItems,"labelProp":_vm.labelProp,"isActive":isShow})],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }