<template>
  <div>
    <MQ mq="desktop">
      <DesktopInputVials v-bind="$props" v-on="$listeners" #default="slotData">
        <slot v-bind="slotData" />
      </DesktopInputVials>
    </MQ>
    <MQ mq="mobile">
      <MobileInputVials v-bind="$props" v-on="$listeners" #default="slotData">
        <slot :isMobile="true" v-bind="slotData" />
      </MobileInputVials>
    </MQ>
  </div>
</template>

<script>
  import DesktopInputVials from './desktop/InputVials.desktop';
  import MobileInputVials from './mobile/InputVials.mobile';

  import { model, props } from '@/uikitProject/vials/vueInputVials/shared/config.ts';

  export default {
    name: 'InputVials',

    components: {
      DesktopInputVials,
      MobileInputVials,
    },

    model,

    props,
  };
</script>
