<template>
  <div class="item" @click="$emit('click')">
    <div v-if="title" class="item__title" :style="stylesTitle">{{ title }}</div>
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'Item',

    props: {
      title: {
        type: String,
      },
      stylesTitle: {
        type: Object,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .item {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    &__title {
      white-space: nowrap;
      font-size: $size-xxs + 1px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 100%;
      color: $color-text-third;
      // To prevent hover
      pointer-events: none;
      font-weight: $weight-normal;
    }
  }
</style>
