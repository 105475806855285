<template>
  <div class="postprocessing-templates-page">
    <NavbarSecondaryAccount />

    <PostprocessingTemplateEditModal
      :postprocessingTemplate="detailDialogPostprocessingTemplate"
      :isCloseOnArchivingStatus="true"
      @update:postprocessingTemplate="detailPostprocessingTemplateUpdated"
      @update:archived="detailArchivedUpdated"
      @created="addPostprocessingTemplateToTable"
    />

    <div class="top-line--flex postprocessing-templates-page__header">
      <div class="postprocessing-templates-page__wrapper-title">
        <h1 class="text-2xl sm:text-4xl" style="display: inline-block">
          <template v-if="archived">Archived Postprocessing Templates</template>
          <template v-else>Postprocessing Templates</template>
        </h1>
        <Btn
          v-if="!archived"
          height="s"
          icon-left="delete"
          type="transparent"
          class="archived-btn postprocessing-templates-page__btn-switch"
          @click="goToArchived()"
          >View archived</Btn
        >
        <Btn
          v-else
          height="s"
          icon-left="keyboard_arrow_left"
          type="transparent"
          class="archived-btn postprocessing-templates-page__btn-switch"
          @click="goToNotArchived()"
          >Back</Btn
        >

        <Btn
          v-if="isFavorites"
          height="s"
          icon-left="star_border"
          type="transparent"
          class="archived-btn postprocessing-templates-page__btn-switch"
          @click="showAll"
          >Show all</Btn
        >
        <Btn
          v-else
          height="s"
          icon-left="star"
          type="transparent"
          class="archived-btn postprocessing-templates-page__btn-switch"
          @click="showFavorites"
          >Show favorites</Btn
        >
      </div>
      <div class="top-line__actions postprocessing-templates-page__actions">
        <InputSearch v-model="searchQuery" placeholder="Search by name or id" />

        <button v-if="!archived" class="button--square-image" @click="showCreateModal()">
          <i class="material-icons material-icon--18">add</i>
          New
        </button>
      </div>
    </div>

    <table v-if="postprocessingTemplates.length" class="table-default">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="tbody__td--nowrap">
        <tr
          v-for="postprocessingTemplate in postprocessingTemplates"
          :key="postprocessingTemplate.id"
          class="row--clickable"
          @click="showDetail(postprocessingTemplate)"
        >
          <td class="postprocessing-templates-page__id">
            {{ postprocessingTemplate.id }}
          </td>
          <td class="td--overflow-hidden">
            {{ postprocessingTemplate.name }}
          </td>
          <td
            :class="{
              'postprocessing-templates-page__cell--selected':
                Number(selectedPostprocessingTemplateId) === postprocessingTemplate.id,
            }"
          >
            <IconFavorite v-if="postprocessingTemplate.is_favorite" />
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="showMoreBtnVisible" class="table-bottom-container">
      <button class="button--unostentatious" @click="getNextItems()">Show more</button>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';

  import PageTitle from '@/mixins/page-title';
  import Btn from '@/uikitBase/btns/Btn';
  import NavbarSecondaryAccount from 'components/blocks/layouts/NavbarSecondaryAccount';
  import InputSearch from '@/uikitBase/inputs/InputSearch';
  import RouterHelper from 'utils/RouterHelper.ts';
  import {
    EVENT_POSTPROCESSING_TEMPLATE_ARCHIVE,
    EVENT_POSTPROCESSING_TEMPLATE_CREATE,
    EVENT_POSTPROCESSING_TEMPLATE_UPDATE,
  } from '@/constants/events/cabinet.ts';
  import { EVENT_BEFORE_DESTROY } from '@/constants/events/global.ts';
  import { apiPostprocessingTemplates } from '@/api/graphql/cloud/postprocessingTemplates';
  import PostprocessingTemplateEditModal from '@/components/block/modal/PostprocessingTemplateEditModal.vue';
  import IconFavorite from '@/uikitProject/icons/IconFavorite.vue';

  export default {
    name: 'PostprocessingTemplatesPage',

    components: {
      IconFavorite,
      PostprocessingTemplateEditModal,
      InputSearch,
      NavbarSecondaryAccount,
      Btn,
    },

    mixins: [PageTitle],

    data() {
      return {
        pageTitle: 'HPLC Cloud: Postprocessing Templates',

        archived: false,

        postprocessingTemplates: [],
        postprocessingTemplatesHash: {},

        postprocessingTemplatesCount: -1,

        detailDialogPostprocessingTemplate: undefined,

        searchQuery: undefined,
      };
    },

    computed: {
      showMoreBtnVisible() {
        return this.postprocessingTemplatesCount > this.postprocessingTemplates.length;
      },
      selectedPostprocessingTemplateId() {
        if (RouterHelper.isDualMode) {
          return RouterHelper.getRouteSecondary(this.$route)?.params?.postprocessingTemplateId;
        }
        return null;
      },

      isFavorites() {
        return this.$route.query.isFavorites != null;
      },
    },

    watch: {
      searchQuery() {
        this.getPostprocessingTemplates();
      },
      $route(to, from) {
        if (to.query.archived !== from.query.archived) {
          this.useRouteQuery(this.$route.query);
        }
      },
      isFavorites() {
        this.getPostprocessingTemplates();
      },
    },

    created() {
      this.initEventBus();
    },

    mounted() {
      this.useRouteQuery(this.$route.query);
    },

    methods: {
      initEventBus() {
        this.$events.on(
          EVENT_POSTPROCESSING_TEMPLATE_ARCHIVE,
          ({ postprocessingTemplate, isArchived }) => {
            if (this.archived === isArchived) {
              this.postprocessingTemplates.unshift(postprocessingTemplate);
              this.postprocessingTemplatesCount++;
            } else {
              const postprocessingTemplatesFiltered = this.postprocessingTemplates.filter(
                (_postprocessingTemplate) =>
                  _postprocessingTemplate.id !== postprocessingTemplate.id,
              );

              if (postprocessingTemplatesFiltered.length < this.postprocessingTemplates.length) {
                this.postprocessingTemplates = postprocessingTemplatesFiltered;
                this.postprocessingTemplatesCount--;
              }
            }
          },
        );
        this.$events.on(EVENT_POSTPROCESSING_TEMPLATE_UPDATE, (postprocessingTemplate) => {
          this.postprocessingTemplates = this.postprocessingTemplates.map(
            (_postprocessingTemplate) =>
              _postprocessingTemplate.id === postprocessingTemplate.id
                ? postprocessingTemplate
                : _postprocessingTemplate,
          );
        });
        this.$events.on(EVENT_POSTPROCESSING_TEMPLATE_CREATE, (postprocessingTemplate) =>
          this.addPostprocessingTemplateToTable(postprocessingTemplate),
        );

        this.$once(EVENT_BEFORE_DESTROY, () => {
          this.$events.off([
            EVENT_POSTPROCESSING_TEMPLATE_UPDATE,
            EVENT_POSTPROCESSING_TEMPLATE_CREATE,
          ]);
        });
      },

      useRouteQuery(query) {
        if (!!query && !!query.archived) {
          this.showArchived();
        } else {
          this.showNotArchived();
        }
      },
      showArchived() {
        this.archived = true;
        this.postprocessingTemplates = [];
        this.getPostprocessingTemplates();
      },
      showNotArchived() {
        this.archived = false;
        this.postprocessingTemplates = [];
        this.getPostprocessingTemplates();
      },

      goToArchived() {
        this.$router.push({ query: { archived: true } });
      },

      goToNotArchived() {
        this.$router.push({ query: { archived: undefined } });
      },

      showFavorites() {
        this.$router.push({ query: { ...this.$route.query, isFavorites: true } });
      },
      showAll() {
        this.$router.push({ query: { ...this.$route.query, isFavorites: undefined } });
      },

      showCreateModal() {
        this.detailDialogPostprocessingTemplate = undefined;
        this.$modal.show('postprocessing-template-edit');
      },

      async showDetail(postprocessingTemplate) {
        // if (RouterHelper.isDualMode) {
        //   this.$router.push({
        //     name: 'postprocessingTemplateDetails',
        //     params: {
        //       postprocessingTemplateId: postprocessingTemplate.id,
        //     },
        //   });
        // } else {
        //   this.detailDialogPostprocessingTemplate = postprocessingTemplate;
        //   await this.$nextTick();
        //   this.$modal.show('postprocessing-template-edit');
        //   console.warn('showDetail show modal', postprocessingTemplate);
        // }

        this.detailDialogPostprocessingTemplate = postprocessingTemplate;
        await this.$nextTick();
        this.$modal.show('postprocessing-template-edit');
        console.warn('showDetail show modal', postprocessingTemplate);
      },

      detailPostprocessingTemplateUpdated(newPostprocessingTemplateData) {
        _.assign(this.detailDialogPostprocessingTemplate, newPostprocessingTemplateData);
      },
      detailArchivedUpdated(isArchived) {
        if (isArchived !== this.archived) {
          const index = this.postprocessingTemplates.indexOf(
            this.detailDialogPostprocessingTemplate,
          );
          if (index >= 0) {
            this.postprocessingTemplates.splice(index, 1);
            delete this.postprocessingTemplatesHash[this.detailDialogPostprocessingTemplate.id];
            this.postprocessingTemplatesCount -= 1;
          }
        }
      },

      addPostprocessingTemplateToTable(postprocessingTemplate) {
        this.postprocessingTemplatesCount += 1;
        this.postprocessingTemplates.unshift(postprocessingTemplate);
        this.postprocessingTemplatesHash[postprocessingTemplate.id] = true;
      },
      async getNextItems() {
        const {
          postprocessingTemplates,
          count,
        } = await apiPostprocessingTemplates.getPostprocessingTemplates({
          search: this.searchQuery,
          isArchived: this.archived,
          hasOnlyFavorites: this.isFavorites,
          offset: this.postprocessingTemplates.length,
        });
        this.postprocessingTemplatesCount = count;

        if (postprocessingTemplates.length > 0) {
          const newPostprocessingTemplates = [];

          newPostprocessingTemplates.push(
            ...postprocessingTemplates.filter(
              (postprocessingTemplate) =>
                !(postprocessingTemplate.id in this.postprocessingTemplatesHash),
            ),
          );

          if (newPostprocessingTemplates.length > 0) {
            // / NOTE: sort postprocessingTemplates if cur last column has less last_modified than first new column
            let isSort = false;
            if (this.postprocessingTemplates.length > 0) {
              const curLastPostprocessingTemplate = this.postprocessingTemplates[
                this.postprocessingTemplates.length - 1
              ];
              const firstNewPostprocessingTemplate = newPostprocessingTemplates[0];
              isSort =
                firstNewPostprocessingTemplate.last_modified_time >
                curLastPostprocessingTemplate.last_modified_time;
            }
            this.postprocessingTemplates.push(...newPostprocessingTemplates);
            if (isSort) {
              this.postprocessingTemplates.sort(
                (a, b) => b.last_modified_time - a.last_modified_time,
              );
            }
          }
        }
      },

      async getPostprocessingTemplates() {
        const {
          postprocessingTemplates,
          count,
        } = await apiPostprocessingTemplates.getPostprocessingTemplates({
          search: this.searchQuery,
          isArchived: this.archived,
          hasOnlyFavorites: this.isFavorites,
        });

        this.postprocessingTemplates = postprocessingTemplates;
        this.postprocessingTemplatesHash = {};
        this.postprocessingTemplates.forEach((postprocessingTemplate) => {
          this.postprocessingTemplatesHash[postprocessingTemplate.id] = true;
        });
        this.postprocessingTemplatesCount = count;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .postprocessing-templates-page {
    &__header {
      display: flex;
      align-items: baseline;

      @media (max-width: $screen-sm-max) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__wrapper-title {
      display: flex;
      align-items: baseline;

      @media (max-width: $screen-xs-max) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__btn-switch {
      margin-left: 30px;

      @media (max-width: $screen-sm-max) {
        margin-left: 15px;
      }

      @media (max-width: $screen-xs-max) {
        margin-left: 0;
      }
    }

    &__actions {
      margin-left: 5px;

      @media (max-width: $screen-xs-max) {
        margin-top: 10px;
        align-self: flex-end;
      }
    }

    &__cell {
      &--selected {
        position: relative;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          content: '';
          height: 100%;
          width: 2px;
          background-color: $color-bg-primary;
        }
      }
    }

    &__id {
      width: max-content;
      color: $color-text-third;
    }
  }

  .table-bottom-container {
    display: flex;
    justify-content: center;
  }
</style>
