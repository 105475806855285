<template>
  <ModalComponent :width="750 - 128" name="modalSampleNew" #default="{ close }">
    <SampleNewComponent @create="handleCreate(close)" />
  </ModalComponent>
</template>

<script>
  import ModalComponent from 'components/element/ModalComponent.vue';
  import SampleNewComponent from 'components/block/SampleNewComponent';

  const EVENT_CREATE = 'create';

  export default {
    name: 'SampleModal',

    components: {
      SampleNewComponent,
      ModalComponent,
    },

    methods: {
      handleCreate(closeModal) {
        closeModal();
        this.$emit(EVENT_CREATE);
      },
    },
  };
</script>
