<template>
  <IconMaterial title="star" :size="size" />
</template>

<script>
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  export default {
    name: 'IconStandard',

    components: {
      IconMaterial,
    },

    props: {
      size: {
        type: Number,
        default: 16,
      },
    },
  };
</script>
