import { REST } from 'utils/rest';

const url = '/api/my/hardware/';

export default class HardwareAPI {
  static urlFor(serial, params = []) {
    return `${url + serial.toString()}/?${params.join('&')}`;
  }

  static get(serial, onSuccess, onError) {
    return REST.get(HardwareAPI.urlFor(serial), onSuccess, onError);
  }

  static put(serial, key, force, onSuccess, onError) {
    const data = { key };
    const params = force ? ['force=true'] : [];
    return REST.put(HardwareAPI.urlFor(serial, params), data, onSuccess, onError);
  }

  static delete(serial, onSuccess, onError) {
    return REST.delete(HardwareAPI.urlFor(serial), onSuccess, onError);
  }

  static getList(onSuccess, onError) {
    return REST.get(url, onSuccess, onError);
  }
}
