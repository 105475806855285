import { render, staticRenderFns } from "./RecentInjections.vue?vue&type=template&id=4a17e43e&scoped=true&"
import script from "./RecentInjections.vue?vue&type=script&lang=js&"
export * from "./RecentInjections.vue?vue&type=script&lang=js&"
import style0 from "./RecentInjections.vue?vue&type=style&index=0&id=4a17e43e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a17e43e",
  null
  
)

export default component.exports