<template>
  <InputAutosuggest
    :id="id"
    ref="inputAutosuggest"
    :value="value"
    :items="items"
    labelProp="name"
    class="compound-autosuggest"
    :isLoadingSuggestions="isLoadingItems"
    :isShowAllSuggestionsIfEmpty="isShowAllSuggestionsIfEmpty"
    :isShowAllSuggestionsOnFocus="isShowAllSuggestionsOnFocus"
    :isDisabled="isDisabled"
    titleBottomSheet="Compound"
    :placeholder="placeholder"
    @model="change"
    @init="getListItems"
    @mounted="$emit('mounted')"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    @mobileSave="$emit('mobileSave')"
    @search="getListItemsDebounced"
  >
    <template
      #input="{ value, setValue, handleInputFocus, handleInputBlur, isDisabled, id, placeholder }"
    >
      <slot
        :id="id"
        :value="value"
        :isDisabled="isDisabled"
        :placeholder="placeholder"
        :setValue="setValue"
        :handleInputFocus="handleInputFocus"
        :handleInputBlur="handleInputBlur"
      >
        <div class="compound-autosuggest__wrapper-input">
          <input
            :id="id"
            :value="value"
            type="text"
            :disabled="isDisabled"
            :placeholder="placeholder"
            class="compound-autosuggest__input"
            autocomplete="off"
            @input="setValue($event.target.value)"
            @focus="handleInputFocus"
            @blur="handleInputBlur"
          />
        </div>
      </slot>
    </template>

    <template #mobileTrigger="slotMobileTriggerData">
      <slot name="mobileTrigger" :isMobile="true" v-bind="slotMobileTriggerData" />
    </template>
  </InputAutosuggest>
</template>

<script>
  import InputAutosuggest from '@/uikitBase/inputs/vueInputAutosuggest/InputAutosuggest';
  import { debounce } from 'lodash';
  import { apiCompounds } from '@/api/graphql/cloud/compounds';

  const EVENT_CHANGE = 'change';

  export default {
    name: 'CompoundAutosuggest',

    components: { InputAutosuggest },

    inheritAttrs: false,

    model: {
      prop: 'value',
      event: EVENT_CHANGE,
    },

    props: {
      value: {
        type: String,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      isShowAllSuggestionsIfEmpty: {
        type: Boolean,
        default: true,
      },
      isShowAllSuggestionsOnFocus: {
        type: Boolean,
      },
      id: {
        type: [String, Number],
      },
      placeholder: {
        type: String,
      },
    },

    data: () => ({
      items: [],

      isLoadingItems: false,

      getListItemsDebounced: null,

      currentSearchQuery: '',
    }),

    watch: {
      value(value) {
        this.getListItemsDebounced(value);
      },
    },

    created() {
      this.getListItemsDebounced = debounce(this.getListItems, 300);
    },

    methods: {
      change(value) {
        this.$emit(EVENT_CHANGE, value);
      },

      async getListItems(query) {
        this.isLoadingItems = true;

        this.currentSearchQuery = query;

        try {
          const compounds = await apiCompounds.getCompounds(query);

          if (this.currentSearchQuery !== query) {
            return;
          }

          this.items = compounds;
        } finally {
          this.isLoadingItems = false;
        }
      },

      focus() {
        this.$refs.inputAutosuggest.focus();
      },
      select() {
        this.$refs.inputAutosuggest?.select();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .compound-autosuggest {
    width: 100%;

    &--disabled {
      text-align: left;
      white-space: nowrap;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 240px;
    }

    &__wrapper-input {
      position: relative;
    }

    &__input {
      flex: 1;
      width: 100%;
      outline: none;
      color: inherit;
      padding: 0 5px;
      transition: border-color 67ms;
      border: none;
      background-color: transparent;
      text-indent: 6px;
      height: 34px;
      border-bottom: 2px solid transparent;
      -webkit-appearance: none;

      &:focus-within {
        border-bottom-color: $color-text-primary;
        border-radius: 4px;
      }

      &:disabled {
        cursor: not-allowed;
      }

      &:invalid,
      &--invalid {
        color: $color-text-danger;
        box-shadow: none;
      }
    }
  }
</style>
