<template>
  <div>
    <ReactWrapper :component="Faq" :props="props" />
  </div>
</template>

<script>
  import { Faq } from '../../../../../frontendV2/dist/reactComponents';
  import ReactWrapper from '@/components/blocks/reactComponents/ReactWrapper';

  export default {
    name: 'Account',

    components: {
      ReactWrapper,
    },

    data: () => ({
      Faq,
    }),

    computed: {
      props() {
        return {};
      },
    },
  };
</script>
