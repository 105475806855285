<template>
  <div>
    <MQ mq="desktop">
      <DesktopInputAutosuggest
        v-bind="{ ...$props, ...$attrs }"
        ref="inputAutosuggest"
        v-on="$listeners"
      >
        <template #input="slotInputData">
          <slot name="input" v-bind="slotInputData" />
        </template>
        <template #default="slotDefaultData">
          <slot v-bind="slotDefaultData" />
        </template>
      </DesktopInputAutosuggest>
    </MQ>
    <MQ mq="mobile">
      <MobileInputAutosuggest v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
        <template #mobileTrigger="slotMobileTriggerData">
          <slot name="mobileTrigger" :isMobile="true" v-bind="slotMobileTriggerData" />
        </template>
      </MobileInputAutosuggest>
    </MQ>
  </div>
</template>

<script>
  import DesktopInputAutosuggest from './desktop/InputAutosuggest.desktop';
  import MobileInputAutosuggest from './mobile/InputAutosuggest.mobile';

  import { model, props } from './shared/config';

  export default {
    name: 'InputAutosuggest',

    components: {
      DesktopInputAutosuggest,
      MobileInputAutosuggest,
    },

    model,

    props,

    methods: {
      focus() {
        this.$refs.inputAutosuggest?.focus();
      },
      select() {
        this.$refs.inputAutosuggest?.select();
      },
    },
  };
</script>
