import { Config, Sizes } from '@/uikitProject/charts/new/private/types';

export const COLORS = {
  gaussianLine: '#088fbe',
  gaussianBackground: '#088F8F50',
  peakLineManual: '#e90f69',
  peakLineAuto: '#4076ff',
  peakAddLine: 'black',
  peakPerpendicular: '#000',
  peakNumber: '#2a4cff',
  peakHighlight: 'rgba(0,0,0,0.1)',
  peakRemoveZone: 'rgba(255, 38, 0, 0.1)',
  peakHighlightForRemoving: 'rgba(255,0,102,0.5)',
  baseline: 'rgb(230,173,0)',
  baselineAdd: '#2491bb',
  measurements: {
    275: '#8b00ff',
    460: 'blue',
    520: 'green',
    630: 'red',
    default: 'black',
    other: 'black',
  },
  zeroline: 'rgba(0, 0, 0, 0.2)',
  axisTick: '#8c8c8c',
  axisLabel: '#8c8c8c',
};

export const CONFIG: Config = {
  responsive: true,
  doubleClick: 'reset+autosize',
  displaylogo: false,
  // showAxisDragHandles: false,
  doubleClickDelay: 450,
  modeBarButtonsToRemove: [
    'zoom2d',
    'pan2d',
    'select2d',
    'lasso2d',
    'zoomIn2d',
    'zoomOut2d',
    'autoScale2d',
    'resetScale2d',
    'hoverClosestCartesian',
    'hoverCompareCartesian',
    'zoom3d',
    'pan3d',
    'resetCameraDefault3d',
    'resetCameraLastSave3d',
    'hoverClosest3d',
    'orbitRotation',
    'tableRotation',
    'zoomInGeo',
    'zoomOutGeo',
    'resetGeo',
    'hoverClosestGeo',
    'toImage',
    'sendDataToCloud',
    'hoverClosestGl2d',
    'hoverClosestPie',
    'toggleHover',
    'resetViews',
    'toggleSpikelines',
    'resetViewMapbox',
  ],
};

export const SECONDS_IN_MINUTE = 60;

export const SHAPE_IDS = {
  baseline: 'baseline',
  peakRemoveZone: 'peakRemoveZone',
};

// <extra> is used to remove a suffix
export const DATA_HOVER_TEMPLATE_FOR_CHROMATOGRAM = `%{customdata}<extra></extra>`;
export const DATA_HOVER_TEMPLATE_FOR_PRESSURE = `%{customdata}<extra></extra>`;
export const AXIS_HOVER_FORMAT = '.2r';

export const MESSAGES = {
  waitingDetectionTime: 'Waiting for detection time',
  loading: 'Loading',
  injectionFinishedBeforeDetectionTime: 'Injection finished before detection time',
  noData: 'No data',
} as const;

export const SIZES: Sizes = {
  margins: {
    desktop: {
      l: 32,
      r: 32,
      b: 20,
      t: 20,
      pad: 0,
    },
    mobile: {
      l: 10,
      r: 10,
      b: 20,
      t: 20,
      pad: 0,
    },
  },
  height: 300,
  mobileScreenSizePx: 767,
};
