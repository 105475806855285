<template>
  <div>
    <Select
      :itemSelected="localColumn"
      :items="items"
      labelProp="name"
      :hasSearch="true"
      padding="md"
      placeholderSearch="Choose column"
      titleBottomSheet="Choose column"
      @update:itemSelected="selectedItemChanged"
      @search="getListItemsDebounced"
      @showPopup="handleShowPopup"
      @hidePopup="handleHidePopup"
    />
  </div>
</template>

<script>
  import Select from '@/uikitBase/selects/vueSelect/Select';
  import _, { debounce } from 'lodash';
  import { apiColumns } from 'api/graphql/cloud/columns';

  const SEARCH_LIMIT = 25;
  const SEARCH_DELAY = 200;

  const ModelEvent = 'change';

  export default {
    name: 'SelectColumnComponent',

    components: { Select },

    model: {
      prop: 'column',
      event: ModelEvent,
    },

    props: {
      column: {
        type: Object,
        default: null,
      },
    },

    data: () => ({
      isSearchActive: false,
      searchQuery: null,
      items: [],
      localColumn: null,

      getListItemsDebounced: null,

      isSelectOpened: false,
    }),

    watch: {
      column: {
        handler(column, oldColumn) {
          if (column) {
            this.addItem(column);

            if (this.isSelectOpened && _.isEqual(column, oldColumn)) {
              return;
            }

            this.localColumn = column;
          }
        },
        immediate: true,
      },
    },

    created() {
      // / NOTE: using hash instead of searching in array
      this.itemsHash = {};

      this.getListItemsDebounced = debounce(this.search, SEARCH_DELAY);

      this.search(null);
    },

    methods: {
      handleShowPopup() {
        this.isSelectOpened = true;
        this.$emit('showPopup');
      },
      handleHidePopup() {
        this.isSelectOpened = false;
        this.$emit('hidePopup');
      },

      selectedItemChanged(item) {
        this.$emit(ModelEvent, item);
      },

      async search(query) {
        const searchingFirstTime =
          query == null && this.searchQuery == null && this.items.length === 0;

        this.isSearchActive = !searchingFirstTime;
        this.searchQuery = query;

        try {
          const { columns } = await apiColumns.getColumns({
            search: this.searchQuery,
            isArchived: this.archived,
            limit: SEARCH_LIMIT,
          });

          if (this.searchQuery !== query) return;
          this.replaceItems(columns);
        } catch {
          if (this.searchQuery !== query) return;
          this.clearItems();
        } finally {
          this.isSearchActive = false;
        }
      },

      addItem(itemNew) {
        this.itemsHash = this.itemsHash ?? [];

        if (!(itemNew.id in this.itemsHash)) {
          this.itemsHash[itemNew.id] = true;
          this.items.unshift(itemNew);
        }
      },

      replaceItems(items) {
        if (!_.isEqual(items, this.items)) {
          this.itemsHash = {};
          items.forEach((item) => {
            this.itemsHash[item.id] = true;
          });
          this.items = items;
        }
      },

      clearItems() {
        this.itemsHash = {};
        this.items = [];
      },
    },
  };
</script>
