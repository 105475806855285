import {
  CALIBRATION_VALUE,
  LOST_VALUE,
  THRESHOLD_FOR_VALUES_THAT_UNSUPPORTED_IN_OLD_MEASUREMENTS,
  UNKNOWN_VALUE,
} from '@/utils/chart/chart-constants';
import { Nullable } from '@/types/utility';

const hasErrorValueToReplace = (value) =>
  value === LOST_VALUE ||
  value === CALIBRATION_VALUE ||
  value >= THRESHOLD_FOR_VALUES_THAT_UNSUPPORTED_IN_OLD_MEASUREMENTS;
const checkErrorValueToStop = (value) => value === UNKNOWN_VALUE;
const applyBaseline = (data: number[], baseline: number[]) =>
  data.map((item, i) => Number(item) - Number(baseline[i]));

type Data = {
  data: Nullable<number[]>;
  isApplyBaseline: boolean;
  isSupportMagicValues: boolean;
  baseline: Nullable<number[]>;
};

export default class ChromatogramHelper {
  public static getModifiedData({
    data,
    baseline,
    isSupportMagicValues,
    isApplyBaseline,
  }: Data): number[] {
    if (!data) {
      return [];
    }

    const _data = [...data];

    const indexErrorValueToStop = data.findIndex(checkErrorValueToStop);
    if (indexErrorValueToStop !== -1) {
      _data.splice(indexErrorValueToStop);
    }

    if (isSupportMagicValues) {
      const hasErrorValues = data.some(hasErrorValueToReplace);
      if (hasErrorValues) {
        for (let i = 0; _data.length > i; i++) {
          if (hasErrorValueToReplace(_data[i])) {
            _data[i] = 0;
          }
        }
      }
    }

    const hasBaseline = isApplyBaseline && baseline;

    return hasBaseline ? applyBaseline(_data, baseline) : _data;
  }
}
