<template>
  <DecoratorState
    :hasTitleOnAdaptive="hasTitleOnAdaptive"
    title="Recording"
    :hasTitle="hasTitle"
    class="state-record"
  >
    <template #icon>
      <img src="@/assets/img/rec.gif" class="state-record__icon" />
    </template>
  </DecoratorState>
</template>

<script>
  import DecoratorState from '@/uikitProject/decorators/DecoratorState';

  export default {
    name: 'StateRecord',

    components: {
      DecoratorState,
    },

    props: {
      hasTitle: {
        type: Boolean,
        default: true,
      },
      hasTitleOnAdaptive: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .state-record {
    color: $color-text-info;

    &__icon {
      display: inline-block;
      width: 11px;
      height: 11px;
    }
  }
</style>
