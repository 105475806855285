<template>
  <div
    class="network"
    :class="{
      'network--not-selected': !network.in_use,
    }"
    @click="$emit('select', network)"
  >
    <WifiIcon
      :signal="network.signal"
      :isConnected="network.in_use"
      :isConnecting="isConnecting"
      class="network__icon"
    />
    <span :title="network.id" class="network__name">{{ network.id }}</span>

    <IconMaterial v-if="network.security" title="lock" class="network__has-password" />
  </div>
</template>

<script>
  import IconMaterial from '@/uikitBase/icons/IconMaterial.vue';
  import WifiIcon from '@/uikitProject/devices/vueDeviceConnection/private/vueWifi/private/private/private/WifiIcon.vue';

  export default {
    name: 'Network',

    components: {
      IconMaterial,
      WifiIcon,
    },

    props: {
      network: {
        type: Object,
        required: true,
      },
      isConnecting: {
        type: Boolean,
      },
    },

    data: () => ({
      details: null,
    }),

    computed: {},
  };
</script>

<style lang="scss" scoped>
  .network {
    display: flex;
    align-items: center;
    color: #000;
    height: 40px;
    border-radius: $border-radius__md;
    padding: 0 2px;

    &--not-selected {
      cursor: pointer;

      &:hover {
        background-color: $color-bg-second;
      }

      &:active {
        background-color: $color-bg-third;
      }
    }

    &__icon {
      flex: none;
    }

    &__icon,
    &__name {
      margin-right: 8px;
      @include textOverflow;
    }

    &__has-password {
      margin-left: auto;
    }
  }
</style>
