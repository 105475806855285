<template>
  <div v-if="isValidData" v-resize:throttle="handleResize" class="chart-gradient">
    <div ref="chart" class="chart-gradient__chart"></div>
  </div>
  <div v-else>Gradient data is not valid</div>
</template>

<script>
  import GradientChart from './private/GradientChart.ts';
  import resize from 'vue-resize-directive';

  export default {
    name: 'ChartGradient',

    directives: { resize },

    props: {
      gradient: {
        type: Array,
        required: true,
      },
      injectionMinutes: {
        type: Number,
        required: true,
      },
      hasTitle: {
        type: Boolean,
        default: true,
      },
    },

    data: () => ({
      chart: null,
    }),

    computed: {
      options() {
        return {
          el: this.$refs.chart,
          injectionMinutes: this.injectionMinutes,
          data: this.gradient,
          hasTitle: this.hasTitle,
        };
      },
      isValidData() {
        return Math.max(...this.gradient.map(({ time }) => time)) <= this.injectionMinutes;
      },
    },

    watch: {
      gradient: {
        handler() {
          this.refresh(this.options);
        },
        deep: true,
      },
      injectionMinutes() {
        this.refresh(this.options);
      },
    },

    mounted() {
      this.initChart();
    },

    methods: {
      initChart() {
        if (this.isValidData) {
          this.chart = new GradientChart(this.options);

          this.chart.draw();
        }
      },
      refresh(options) {
        if (this.isValidData) {
          this.chart.refresh(options);
        }
      },

      handleResize() {
        this.refresh();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chart-gradient {
    display: flex;
    justify-content: center;
    height: 70px;
    margin-top: -10px;

    &__chart {
      flex: 1;
      margin-left: -15px;
    }
  }
</style>
