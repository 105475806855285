<template>
  <div class="input-title">
    <IconMaterial v-if="iconName" :title="iconName" class="input-title__icon" />
    <div v-else-if="$slots.icon" class="input-title__icon">
      <slot name="icon" />
    </div>

    <div
      ref="input"
      v-test="{ id: 'input' }"
      :contenteditable="!isDisabled"
      class="input-title__editable"
      @blur="onBlur"
      @input="onInput"
      @keydown="onKeydown"
      @keydown.enter.prevent="onKeydownEnter"
      @paste.prevent="onPaste"
    >
      {{ valueDefault }}
    </div>
  </div>
</template>

<script>
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import testDirective from '@/directives/test';

  const EVENT_MODEL = 'model';
  const EVENT_SAVE = 'save';

  export default {
    name: 'InputTitle',

    directives: { test: testDirective },

    components: { IconMaterial },

    model: {
      prop: 'value',
      event: EVENT_MODEL,
    },

    props: {
      value: {
        type: String,
        required: true,
      },
      iconName: {
        type: String,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      maxLength: {
        type: Number,
      },
    },

    data: () => ({
      valueDefault: '',
      previousSavedValue: '',
    }),

    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit(EVENT_MODEL, value);
        },
      },
    },

    // Hack to achieve two way binding with content editable div
    watch: {
      value(value) {
        if (value !== this.$refs.input.innerText) {
          this.$refs.input.innerText = value;
          this.previousSavedValue = this.value;
        }
      },
    },

    created() {
      this.initDefaultValue();
      this.initSavedValue();
    },

    methods: {
      restorePreviousValue() {
        this.$refs.input.innerText = this.previousSavedValue;
        this.localValue = this.previousSavedValue;
      },

      initDefaultValue() {
        this.valueDefault = this.value;
      },
      initSavedValue() {
        this.previousSavedValue = this.value;
      },

      save() {
        if (this.value) {
          this.$emit(EVENT_SAVE, this.value);
          this.previousSavedValue = this.value;
        } else {
          this.restorePreviousValue();
        }
      },

      onInput(e) {
        this.localValue = e.target.innerText;
      },
      onBlur() {
        this.save();
      },
      onKeydown(e) {
        const hasSelection = !window.getSelection().isCollapsed;

        if (
          this.maxLength &&
          !hasSelection &&
          e.key !== 'Backspace' &&
          !e.metaKey &&
          !e.ctrlKey &&
          e.key !== 'ArrowUp' &&
          e.key !== 'ArrowDown' &&
          e.key !== 'ArrowLeft' &&
          e.key !== 'ArrowRight' &&
          !e.shiftKey &&
          e.target.innerText.length + 1 > this.maxLength
        ) {
          e.preventDefault();
        }
      },
      onKeydownEnter() {
        this.$refs.input.blur();
        this.save();
      },
      onPaste(e) {
        // To clean external styles
        const plainText = e.clipboardData.getData('text/plain');

        if (this.maxLength) {
          const availableLength = this.maxLength - this.localValue.length;
          if (availableLength > 0) {
            document.execCommand('insertText', false, plainText.slice(0, availableLength));
          }
        } else {
          document.execCommand('insertText', false, plainText);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/css/base/icons';

  .input-title {
    position: relative;
    font-size: 3.157em;
    margin: 0 0 0 -12px;
    padding: 0;

    @media (max-width: 700px) {
      font-size: 1.777em;
      .input-title__editable {
        padding: 5px 4px 2px 12px;
        margin-bottom: 2px;
      }
    }

    .model-text {
      width: calc(100% - 48px);
    }

    &__icon {
      @extend .material-icon--32;
      position: absolute;
      left: 12px;
      top: 13px;
      float: left;

      @media (max-width: 700px) {
        font-size: 18px;
        top: 8px;
      }
    }

    &__editable {
      padding: 6px 4px 2px 12px;
      text-indent: 1.1em;
      word-break: break-word;
      hyphens: auto;
      outline: none;
      line-height: 1.22em;
      border: none;
      border-radius: 2px;

      transition: background-color 0.1s;

      &[contenteditable='true'] {
        cursor: pointer;

        &:hover {
          background-color: $pure-color__black--alp-06;
        }

        &:focus {
          background-color: $pure-color__black--alp-04;
        }
      }
    }
  }
</style>
