import { makeGraphqlRequestCloud } from 'api/graphql/graphQLClient';
import { StandardFull } from '@/graphql/cloud/standards/queries/StandardFull.graphql';
import { StandardsForMeasurement } from '@/graphql/cloud/standards/queries/StandardsForMeasurement.graphql';
import { transformArrayToObject } from '@/utils/objectHelpers';
import DataNotFoundError from '@/errors/DataNotFoundError';

export const apiStandards = {
  async getStandardFull(id) {
    const { standard: standardFull } = await makeGraphqlRequestCloud(StandardFull, { id });

    if (!standardFull) {
      throw new DataNotFoundError();
    }

    const { device, injections, column, method, ...standard } = standardFull;

    const [{ sequence_compound } = {}] = standard.sequence_compounds;

    return {
      device,
      injections: transformArrayToObject(
        injections.map(({ device, column, method, ...injection }) => ({
          id: injection.id,
          device,
          column,
          method,
          injection: {
            ...injection,
            measurements: transformArrayToObject(injection.measurements, 'id'),
          },
        })),
        'id',
      ),
      column,
      method,
      standard: {
        ...standard,
        sequence_compound,
        device_name: device?.name,
      },
    };
  },
  async getForEstimationList(measurementId) {
    const { standards } = await makeGraphqlRequestCloud(StandardsForMeasurement, {
      measurementId,
    });

    return standards.map(({ id, sample }) => ({
      id,
      ...sample,
      device_name: sample?.device?.name,
    }));
  },
};
