<template>
  <modal ref="modal" :width="320" name="autosamplerService">
    <div class="modal-content modal-content--no-padding modal-autosampler-service">
      <div class="modal-header">
        <h4 class="modal-autosampler-service__title">Autosampler service</h4>
      </div>

      <div class="modal-body modal-autosampler-service__body">
        <div class="modal-autosampler-service__actions">
          <Btn
            type="primary"
            height="s"
            :disabled="!canReturnPlungerToHomePosition"
            @click="returnPlungerToHomePosition"
            >Plunger to home position</Btn
          >
          <Btn type="primary" height="s" :disabled="!canRaiseArm" @click="raiseArm">Raise arm</Btn>
          <Btn
            type="primary"
            height="s"
            :disabled="!canMoveArmFromInjectionPort"
            @click="moveArmFromInjectionPort"
            >Move arm from injection port</Btn
          >
          <Btn type="primary" height="s" :disabled="!canResetNeedle" @click="resetNeedle"
            >Reset needle</Btn
          >
          <Btn type="primary" height="s" :disabled="!canResetValve" @click="resetValve"
            >Reset valve</Btn
          >
        </div>

        <div class="modal-autosampler-service__learn-how-to">
          <div class="modal-autosampler-service__label-learn-how-to">Learn how to:</div>
          <a href="#" target="_blank" class="modal-autosampler-service__manual">Replace needle</a>
          <a href="#" target="_blank" class="modal-autosampler-service__manual"
            >Replace injection port</a
          >
          <a href="#" target="_blank" class="modal-autosampler-service__manual"
            >Replace valve rotor</a
          >
          <a href="#" target="_blank" class="modal-autosampler-service__manual"
            >Assemble syringe cylinder</a
          >
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import ModalComponent from 'components/element/ModalComponent.vue';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import DeviceSocket from '@/api/sockets/DeviceSocket';
  import { autosamplerCommands } from '@/constants/hardware/commands';

  const MODAL_NAME = 'AutosamplerServiceModal';

  export default {
    name: 'AutosamplerServiceModal',

    components: {
      Btn,
      modal: ModalComponent,
    },

    props: {
      autosamplerState: {
        type: Object,
        required: true,
      },
      deviceSocket: {
        type: DeviceSocket,
        required: true,
      },
    },

    computed: {
      commands() {
        return this.autosamplerState.commandDetails ?? {};
      },

      canReturnPlungerToHomePosition() {
        return this.commands[autosamplerCommands.RETURN_PLUNGER_TO_HOME_POSITION]?.status.available;
      },
      canRaiseArm() {
        return this.commands[autosamplerCommands.RAISE_ARM]?.status.available;
      },
      canMoveArmFromInjectionPort() {
        return this.commands[autosamplerCommands.MOVE_ARM_FROM_INJECTION_PORT]?.status.available;
      },
      canResetNeedle() {
        return this.commands[autosamplerCommands.RESET_NEEDLE]?.status.available;
      },
      canResetValve() {
        return this.commands[autosamplerCommands.RESET_VALVE]?.status.available;
      },
    },

    methods: {
      show() {
        this.$modal.show(MODAL_NAME);
      },
      close() {
        this.$modal.hide(MODAL_NAME);
      },

      returnPlungerToHomePosition() {
        this.deviceSocket.command(
          'autosampler',
          autosamplerCommands.RETURN_PLUNGER_TO_HOME_POSITION,
        );
      },
      raiseArm() {
        this.deviceSocket.command('autosampler', autosamplerCommands.RAISE_ARM);
      },
      moveArmFromInjectionPort() {
        this.deviceSocket.command('autosampler', autosamplerCommands.MOVE_ARM_FROM_INJECTION_PORT);
      },
      resetNeedle() {
        this.deviceSocket.command('autosampler', autosamplerCommands.RESET_NEEDLE);
      },
      resetValve() {
        this.deviceSocket.command('autosampler', autosamplerCommands.RESET_VALVE);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-autosampler-service {
    &__title {
      width: 100%;
      display: block;
      text-align: center;
    }

    &__body {
      @media (max-width: $screen-sm-max) {
        padding: 16px;
      }
    }

    &__actions {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
      margin-bottom: 30px;
    }

    &__learn-how-to {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: start;
      gap: 8px;
    }

    &__label-learn-how-to {
      font-weight: $weight-bold;
    }

    &__manual {
      display: block;
    }
  }
</style>
