<template>
  <div class="panel" :class="{ 'panel--clickable': isClickable }" v-on="$listeners">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'Panel',

    props: {
      isClickable: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .panel {
    display: flex;
    align-items: center;
    height: 32px;
    user-select: none;
    border-radius: $border-radius__md;
    background-color: #f0f0f0;
    padding: 0 8px;

    &--clickable {
      cursor: pointer;

      &:hover {
        background-color: darken(#f0f0f0, 5%);
      }

      &:active {
        background-color: darken(#f0f0f0, 10%);
      }
    }
  }
</style>
