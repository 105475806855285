<template>
  <div class="column-edit">
    <div class="column-edit__column">
      <field
        :value="pColumn.type"
        :type="'str'"
        :label="'Type'"
        :error="errors && errors.type"
        data-test-id="modal-input-field-type"
        :disabled="disabled"
        @update:value="update('type', $event)"
      />
      <field
        :value="pColumn.serial"
        :type="'str'"
        :label="'Serial'"
        :error="errors && errors.serial"
        :disabled="disabled"
        :after-slot-visible="!disabled"
        data-test-id="modal-input-field-serial"
        @update:value="update('serial', $event)"
      >
        <template #after>
          <button class="model-field__button model-field__button--in-field" @click="showModalScan">
            <i class="material-icons material-icon--16">photo_camera</i>
          </button>
          <button
            v-if="pColumn.serial"
            class="model-field__button model-field__button--in-field"
            @click="requestColumnData"
          >
            <i class="material-icons material-icon--16">transit_enterexit</i>
          </button>
        </template>
      </field>
      <field
        :value="pColumn.lot"
        :type="'str'"
        :label="'Lot'"
        data-test-id="modal-input-field-lot"
        :error="errors && errors.lot"
        :disabled="disabled"
        @update:value="update('lot', $event)"
      />
      <field
        :value="pColumn.max_pressure"
        :type="'int'"
        :label="'Max pressure, psi'"
        :error="errors && errors.max_pressure"
        :disabled="disabled"
        :hints="[500, 1000, 2000]"
        data-test-id="modal-input-field-required"
        @update:value="update('max_pressure', $event)"
      />
    </div>
    <div class="column-edit__column">
      <field
        :value="pColumn.length"
        :type="'int'"
        :label="'Length, mm'"
        :error="errors && errors.length"
        :disabled="disabled"
        :hints="[10, 20, 25, 50, 100, 150, 250]"
        data-test-id="modal-input-field-required"
        @update:value="update('length', $event)"
      />
      <field
        :value="pColumn.internal_diameter"
        :type="'float'"
        :label="'Internal diameter, mm'"
        :error="errors && errors.internal_diameter"
        :disabled="disabled"
        :hints="[0.3, 0.5, 0.7, 1.0, 2.1, 3.2, 4.6, 10, 22, 30, 50]"
        data-test-id="modal-input-field-required"
        @update:value="update('internal_diameter', $event)"
      />
      <field
        :value="pColumn.particle_size"
        :type="'float'"
        :label="'Particle size, μm'"
        :error="errors && errors.particle_size"
        :disabled="disabled"
        :hints="[2.7, 3, 5, 7, 10, 20, 50]"
        data-test-id="modal-input-field-required"
        @update:value="update('particle_size', $event)"
      />
      <field
        :value="pColumn.pore_size"
        :type="'int'"
        :label="'Pore size, Å'"
        :error="errors && errors.pore_size"
        :disabled="disabled"
        :hints="[60, 90, 100, 200, 300, 800, 1000, 2000]"
        data-test-id="modal-input-field-required"
        @update:value="update('pore_size', $event)"
      />
    </div>
    <ScanQRModal @data="autofillForm" />
  </div>
</template>

<script>
  import StandardModelFieldComponent from 'components/element/StandardModelFieldComponent.vue';
  import ColumnAPI from 'api/column';
  import ScanQRModal from 'components/block/modal/ScanQRModal';

  const UpdateColumnEvent = 'update:column';
  const EVENT_UPDATE_FIELD = 'updateField';

  export default {
    name: 'ColumnEditComponent',
    components: {
      ScanQRModal,
      field: StandardModelFieldComponent,
    },
    model: {
      prop: 'column',
      event: UpdateColumnEvent,
    },
    props: {
      column: Object,
      errors: Object,
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      pColumn() {
        return this.column ? this.column : {};
      },
    },
    methods: {
      update(key, value) {
        this.$emit(UpdateColumnEvent, {
          ...this.pColumn,
          [key]: value,
        });
        this.$emit(EVENT_UPDATE_FIELD, key);
      },
      requestColumnData() {
        const onSuccess = (data) => {
          Object.keys(this.pColumn)
            .filter((key) => key in data)
            .forEach((key) => {
              this.pColumn[key] = data[key];
            });
        };

        ColumnAPI.getHplcproductionData(this.column.serial, onSuccess, this.notifyResponseError);
      },

      showModalScan() {
        this.$modal.show('scan-qr-modal');
      },

      parseColumnLink(link) {
        const result = link.match(/https?:\/\/.+?\/(?<serial>\w+)\/?/);
        if (result?.groups) {
          return result.groups;
        }
        this.notifyError('Link is illegal');
        throw new Error('Link is illegal');
      },

      async autofillForm(link) {
        const { serial } = this.parseColumnLink(link);

        this.update('serial', serial);
        await this.$nextTick();
        this.requestColumnData();
      },
    },
  };
</script>

<style scoped lang="scss">
  .column-edit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $screen-xs-max) {
      flex-direction: column;
    }

    &__column {
      flex: 1 0;
    }

    &__column:not(:first-child) {
      margin-left: 16px;

      @media (max-width: $screen-xs-max) {
        margin-left: 0;
      }
    }

    .standard-model-field {
      margin-top: 8px;
      display: block;
    }
  }
</style>
