const EVENT_MODEL = 'model';

export const model = {
  prop: 'value',
  event: EVENT_MODEL,
};

export const props = {
  value: {
    type: String,
    required: true,
  },

  items: {
    type: Array,
    required: true,
  },
  labelProp: {
    type: String,
    default: 'name',
  },

  isDisabled: {
    type: Boolean,
    default: false,
  },

  isShowAllSuggestionsOnFocus: {
    type: Boolean,
  },
  isShowAllSuggestionsIfEmpty: {
    type: Boolean,
    default: true,
  },

  isError: {
    type: Boolean,
  },

  placeholder: {
    type: String,
  },
  titleBottomSheet: {
    type: String,
  },

  dropdownStyles: {
    type: Object,
    default: () => ({
      maxWidth: '240px',
    }),
  },

  dropdownType: {
    type: String,
    default: 'default',
    validator(value) {
      const availableValues = ['default'];
      return availableValues.includes(value);
    },
  },
  padding: {
    type: String,
    default: 'none',
    validator(value) {
      const availableValues = ['none', 'xs'];
      return availableValues.includes(value);
    },
  },
};
