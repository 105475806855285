<template>
  <div class="add-device">
    <PrForm
      v-if="isShowForm"
      :initialData="initialFormData"
      @submit="addDevice"
      @scan="showModalScan"
      @cancel="hideForm"
    />
    <PrBtnShowDeviceForm v-else @click="showForm" />

    <ScanQRModal @data="autofillForm" />
  </div>
</template>

<script>
  import PrBtnShowDeviceForm from 'components/blocks/devices/add/private/PrBtnShowDeviceForm';
  import PrForm from 'components/blocks/devices/add/private/PrForm';
  import ScanQRModal from 'components/block/modal/ScanQRModal';
  import HardwareAPI from 'api/hardware';
  import RouterHelper from 'utils/RouterHelper.ts';

  const EVENT_ADD = 'add';

  export default {
    name: 'AddDevice',

    components: {
      ScanQRModal,
      PrForm,
      PrBtnShowDeviceForm,
    },

    props: {
      hasDevices: {
        type: Boolean,
        required: true,
      },
    },

    data() {
      return {
        initialFormData: {},

        isShowForm: !this.hasDevices,
      };
    },

    created() {
      this.initFormData();
    },

    methods: {
      showForm() {
        this.isShowForm = true;
      },
      hideForm() {
        this.isShowForm = false;
        this.resetInitialData();
      },

      initFormData() {
        const { serial: id, key } = this.$route.query;

        if (id || key) {
          RouterHelper.deleteQueryParams(['serial', 'key']);

          this.initialFormData = {
            id,
            key,
          };
        }
      },
      resetInitialData() {
        this.initialFormData = {};
      },

      showModalScan() {
        this.$modal.show('scan-qr-modal');
      },

      parseDeviceLink(link) {
        const result = link.match(/https?:\/\/.+?\/(?<serial>.+?)\/(?<key>\d+)\/?/);
        if (result?.groups) {
          return result.groups;
        }

        this.notifyError('Link is illegal');
        throw new Error('Link is illegal');
      },

      autofillForm(link) {
        const { serial: id, key } = this.parseDeviceLink(link);

        this.initialFormData = {
          id,
          key,
        };
      },

      addDevice({ id, key }, force = false) {
        const realId = id.replace('-', '').toLowerCase();

        HardwareAPI.put(
          realId,
          key.toLowerCase(),
          force,
          (data, code) => {
            this.hideForm();

            if (code === 204) this.notify('This device already added');
            else this.$emit(EVENT_ADD);
          },
          (data, code) => {
            if (code === 409) {
              this.showChangeHardwareOwnerModalDialog(data['organization_name'], { id, key });
            } else {
              this.notifyResponseError(data, code);
            }
          },
        );
      },

      showChangeHardwareOwnerModalDialog(organizationName, formData) {
        this.$modal.show('dialog', {
          title: 'Change device owner?',
          text: `This device is currently assigned to the organization named “${organizationName}“. Would you like to remove it from their organization and add it to yours?`,
          buttons: [
            {
              title: 'Yes',
              default: true,
              handler: () => {
                this.addDevice(formData, true);
                this.$modal.hide('dialog');
              },
              class: 'vue-dialog-button blue-text',
            },
            {
              title: 'No',
              handler: () => {
                this.$modal.hide('dialog');
              },
              class: 'vue-dialog-button red-text',
            },
          ],
        });
      },
    },
  };
</script>
