<template>
  <DecoratorState :hasTitleOnAdaptive="hasTitleOnAdaptive" title="Archived" class="state-archived">
    <template #icon>
      <IconMaterial title="delete" size="16" />
    </template>
  </DecoratorState>
</template>

<script>
  import DecoratorState from '@/uikitProject/decorators/DecoratorState';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  export default {
    name: 'StateArchived',

    components: {
      DecoratorState,
      IconMaterial,
    },

    props: {
      hasTitleOnAdaptive: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .state-archived {
    color: $color-text-danger;
  }
</style>
