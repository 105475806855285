// https://github.com/AlexandreBonaventure/vue-mq
/* eslint-disable */

import json2mq from 'json2mq';
/*
lock vue-fragment 1.5.1v, because there is a bug with Node insertion
Issues:
https://github.com/Thunberg087/vue-fragment/issues/18
https://github.com/Thunberg087/vue-fragment/issues/61
*/
import { Fragment } from 'vue-fragment'

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

    return arr2;
  }
  return Array.from(arr);
}

function convertBreakpointsToMediaQueries(breakpoints) {
  const keys = Object.keys(breakpoints);
  const values = keys.map(function(key) {
    return breakpoints[key];
  });
  const breakpointValues = [0].concat(_toConsumableArray(values.slice(0, -1)));
  const mediaQueries = breakpointValues.reduce(function(sum, value, index) {
    const options = {
      minWidth: value,
      ...(index < keys.length - 1
        ? {
            maxWidth: breakpointValues[index + 1] - 1,
          }
        : {}),
    };
    const mediaQuery = json2mq(options);
    return Object.assign(sum, _defineProperty({}, keys[index], mediaQuery));
  }, {});
  return mediaQueries;
}
function selectBreakpoints(breakpoints, currentBreakpoint) {
  const index = breakpoints.findIndex(function(b) {
    return b === currentBreakpoint;
  });
  return breakpoints.slice(index);
}
function subscribeToMediaQuery(mediaQuery, enter) {
  const mql = window.matchMedia(mediaQuery);

  const cb = function cb(_ref) {
    const matches = _ref.matches;
    if (matches) enter();
  };

  mql.addListener(cb); // subscribing

  cb(mql); // initial trigger
}

function isArray(arg) {
  return Object.prototype.toString.call(arg) === '[object Array]';
}

// USAGE
// mq-layout(mq="lg")
//   p I’m lg
const component = {
  components:{
    Fragment
  },
  props: {
    mq: {
      required: true,
      type: [String, Array],
    },
  },
  computed: {
    plusModifier: function plusModifier() {
      return !isArray(this.mq) && this.mq.slice(-1) === '+';
    },
    activeBreakpoints: function activeBreakpoints() {
      const breakpoints = Object.keys(this.$mqAvailableBreakpoints);
      const mq = this.plusModifier ? this.mq.slice(0, -1) : isArray(this.mq) ? this.mq : [this.mq];
      return this.plusModifier ? selectBreakpoints(breakpoints, mq) : mq;
    },
  },
  render: function render(h, props) {
    const shouldRenderChildren = this.activeBreakpoints.includes(this.$mq);
    return shouldRenderChildren ? h('Fragment', this.$slots.default) : h('Fragment');
  },
};

const DEFAULT_BREAKPOINT = {
  sm: 450,
  md: 1250,
  lg: Infinity,
};

const install = function install(Vue) {
  const _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const _ref$breakpoints = _ref.breakpoints;
  const breakpoints = _ref$breakpoints === void 0 ? DEFAULT_BREAKPOINT : _ref$breakpoints;
  const _ref$defaultBreakpoin = _ref.defaultBreakpoint;
  const defaultBreakpoint = _ref$defaultBreakpoin === void 0 ? 'sm' : _ref$defaultBreakpoin;

  let hasSetupListeners = false; // Init reactive component

  const reactorComponent = new Vue({
    data: function data() {
      return {
        currentBreakpoint: defaultBreakpoint,
      };
    },
  });
  Vue.mixin({
    computed: {
      $mq: function $mq() {
        return reactorComponent.currentBreakpoint;
      },
    },
    mounted: function mounted() {
      if (!hasSetupListeners) {
        const mediaQueries = convertBreakpointsToMediaQueries(breakpoints); // setup listeners

        const _loop = function _loop(key) {
          const mediaQuery = mediaQueries[key];

          const enter = function enter() {
            reactorComponent.currentBreakpoint = key;
          };

          subscribeToMediaQuery(mediaQuery, enter);
        };

        for (const key in mediaQueries) {
          _loop(key);
        }

        hasSetupListeners = true;
      }
    },
  });
  Vue.prototype.$mqAvailableBreakpoints = breakpoints;
  Vue.component('MQ', component);
};

const index = {
  install,
};

export default index;
