<template>
  <div class="chart-pressure">
    <div v-show="hasData">
      <div v-resize:throttle="onResize" style="position: relative; width: 100%">
        <div ref="divChart" class="chart-pressure__chart" />
      </div>
    </div>
  </div>
</template>

<script>
  import resize from 'vue-resize-directive';

  import { CHART_COLORS } from 'utils/chart/chart-constants.ts';

  import ChromatogramPainter from 'utils/chart/chromatogram.ts';
  import { consoleHelpers } from 'utils/logHelpers';

  export default {
    name: 'PrChartPressure',

    directives: { resize },

    props: {
      measurement: Object,
      sampleToken: { type: String, default: null },
      colorScheme: { type: Object, default: () => CHART_COLORS },
      hasPadding: {
        type: Boolean,
        default: true,
      },
      timeMax: {
        type: Number,
      },
    },

    data() {
      return {
        cPaint: null,
        chart: {
          mouseDownX: -1,
          mouseDownY: -1,
          touchAccepted: null,
          peakLine: null,
          zoomRect: null,
          deleteRect: null,
          baseline: null,
          baselinePoints: null,
        },

        isZoomed: false,
      };
    },

    computed: {
      hasData() {
        return this.measurement?.data_length > 0;
      },
      minutesMin() {
        return this.timeMin;
      },
      minutesMax() {
        return this.timeMax;
      },
    },

    watch: {
      'measurement.data': 'refresh',
    },

    mounted() {
      const { colorScheme, indexMax, bounds } = this;
      const { divChart } = this.$refs;

      const scaleConfig = {
        factor: 6,
        enableOverlay: true,
        indexMax,
        mauMax: bounds?.mauMax,
        mauMin: bounds?.mauMin,
        minutesMin: this.timeMin,
        minutesMax: this.timeMax,
        hasGrid: false,
        padding: this.hasPadding
          ? { left: 32, top: 0, right: 32, bottom: 0 }
          : { top: 0, bottom: 0, left: 0, right: 0 },
      };

      const options = {
        hasTimeScale: false,
        hasMauScale: true,
        yAxisName: ' psi',
        hasOnlyMinMaxMau: true,
        isRoundYValues: true,
        canSelectPeak: false,
        hasAxisLabels: false,
      };

      this.cPaint = new ChromatogramPainter(divChart, scaleConfig, colorScheme, options);

      this.refresh();

      this.cPaint.resetZoom();
    },

    methods: {
      refresh({ doResetZoom = false } = {}) {
        if (this.minutesMin) this.cPaint.updateMinutesMin(this.minutesMin);
        if (this.minutesMax) this.cPaint.updateMinutesMax(this.minutesMax);
        if (this.bounds) {
          this.cPaint.updateMauMax(this.bounds.mauMax);
          this.cPaint.updateMauMin(this.bounds.mauMin);
        }

        this.cPaint.addMeasurement(this.measurement.data, this.measurement.mps, 'main', {
          drawOnly: { peaks: false, baseline: false, polyline: true },
          doResetZoom: !this.isZoomed || doResetZoom,
          doRedraw: true,
        });

        if (!this.isZoomed || doResetZoom) this.cPaint.resetZoom();
      },

      onResize() {
        this.cPaint.updateSize();
      },

      zoom({ pStart, pFinish }) {
        const coordsStart = this.cPaint.getMinutesMau(pStart);
        const coordsFinish = this.cPaint.getMinutesMau(pFinish);

        let [_minutesMin, _minutesMax, _min, _max] = [
          coordsStart.minutes,
          coordsFinish.minutes,
          this.cPaint.zoom.mauMin,
          this.cPaint.zoom.mauMax,
        ];
        if (_minutesMin > _minutesMax) {
          [_minutesMin, _minutesMax] = [_minutesMax, _minutesMin];
        }

        if (_min > _max) {
          [_min, _max] = [_max, _min];
        }

        const minimumIntervalInMinutes = 0.00166; // 0.1 second
        const hasMovement =
          _minutesMax - _minutesMin > minimumIntervalInMinutes &&
          coordsFinish.mau - coordsStart.mau !== 0;

        if (!hasMovement && this.isZoomed) {
          this.cPaint.resetZoom();
          this.isZoomed = false;
        } else if (hasMovement) {
          this.cPaint.setZoom({
            minutesMin: _minutesMin,
            minutesMax: _minutesMax,
            mauMin: _min,
            mauMax: _max,
          });

          this.isZoomed = true;
        }
      },

      resetZoom() {
        // TODO: Remove if doesn't occur
        if (!this.cPaint) {
          // eslint-disable-next-line no-console
          consoleHelpers.error("ChartPressure: Can't resetZoom(), cPaint==null; Do it nextTick()");
          this.$nextTick(() => this.resetZoom());
          return;
        }
        this.cPaint.resetZoom();
        this.isZoomed = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chart-pressure {
    page-break-inside: avoid;
    &__chart {
      page-break-inside: avoid;
      width: 100%;
      height: 64px;
    }
  }
</style>
