<template>
  <div ref="reactRoot"></div>
</template>

<script>
  import { ReactManager } from '../../../../../frontendV2/dist/reactComponents.js';

  export default {
    name: 'ReactWrapper',

    props: {
      component: {
        type: Function,
        required: true,
      },
      props: {
        type: Object,
      },
    },

    data: () => ({
      reactInstance: null,
    }),

    watch: {
      props() {
        this.reactInstance.updateProps(this.props);
      },
    },

    created() {
      this.reactInstance = new ReactManager(this.component);
    },

    mounted() {
      this.reactInstance.mount(this.$refs.reactRoot, this.props);
    },

    beforeDestroy() {
      this.reactInstance.unmount();
    },
  };
</script>
