<template>
  <modal
    name="column-detail"
    doSubmitOnEnter
    :hasCloseConfirmationPopup="isDataChanged"
    closeConfirmationPopupMessage="Column wasn't saved! Do you want to close the window?"
    class="modal-column-edit"
    @submit="submit"
    @opened="opened"
    @closed="closed"
  >
    <div class="modal-content modal-content--no-padding modal-column-edit">
      <div class="modal-header modal-column-edit__header">
        <h4>Column</h4>
        <Btn height="s" padding="xs" class="modal-column-edit__btn-expand" @click="expand">
          <IconMaterial title="open_in_full" />
        </Btn>
      </div>
      <div class="modal-body">
        <div class="name-container">
          <field
            v-model="columnData.name"
            style="flex: 1 1"
            :type="'str'"
            :is-title="true"
            :label="'Column name'"
            :error="errors.name"
            :disabled="isArchived || !hasPermissionToEdit"
          />

          <template v-if="hasPermissionToEdit && canAchieve">
            <button
              v-if="isArchived"
              class="button--square-image column-archive-button"
              @click="setArchived(false)"
            >
              <i class="material-icons material-icon--18">restore</i>Restore
            </button>
            <button
              v-if="!isArchived"
              class="button--square-image column-archive-button"
              @click="setArchived(true)"
            >
              <i class="material-icons material-icon--18">delete</i>Archive
            </button>
          </template>
        </div>

        <StateArchived v-if="isArchived" />

        <ColumnEditComponent
          v-model="columnData"
          :errors="errors"
          :disabled="isArchived || !hasPermissionToEdit"
          class="mt-3"
          @updateField="clearError"
        />
      </div>
      <div v-if="!isArchived" class="modal-footer">
        <div v-if="hasPermissionToEdit" class="modal-content__actions-panel">
          <div class="actions-hints">
            <p>
              <i>Save</i> - change values everywhere you used <b>column</b> before.<br />
              <i>Save as new</i> - keep original <b>column</b>.
            </p>
          </div>
          <div class="actions">
            <button :disabled="!isDataChanged" @click="reset">Revert</button>
            <button
              :disabled="!isDataChanged"
              :class="{ 'button--accent': serialChanged }"
              data-test-id="btn-save-column"
              @click="createNew"
            >
              Save as new
            </button>
            <button
              :disabled="!isDataChanged"
              :class="{ 'button--accent': !serialChanged }"
              @click="saveChanges"
            >
              Save
            </button>
          </div>
        </div>
        <div v-else class="modal-column-edit__no-permissions">
          You can't edit and create columns. Ask the organization owner to give you more permissions
        </div>
      </div>

      <LoadingComponent
        v-if="isLoadingColumnInjections"
        label="Loading recent injections"
        class="modal-column-edit__loader-injections"
      />
      <div v-else-if="columnData.injections" class="modal-column-edit__injections">
        <h4 class="modal-column-edit__title-injections">
          Recent injections
          <span class="modal-column-edit__total"
            >(total {{ columnData.injections_aggregate.aggregate.count }})</span
          >
        </h4>

        <RecentInjections v-if="columnData.injections.length" :injections="columnData.injections" />
        <div v-else class="modal-column-edit__message-no-injections">
          The column hasn't been used yet
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import _ from 'lodash';
  import ColumnAPI from 'api/column';
  import StandardModelFieldComponent from 'components/element/StandardModelFieldComponent.vue';
  import ModalComponent from 'components/element/ModalComponent.vue';
  import ColumnEditComponent from 'components/block/ColumnEditComponent.vue';
  import StateArchived from '@/uikitProject/states/StateArchived.vue';
  import { COLUMN_EMPTY } from '@/constants/columns/presets';
  import Btn from '@/uikitBase/btns/Btn';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import { apiColumns } from 'api/graphql/cloud/columns';
  import LoadingComponent from 'components/element/LoadingComponent';
  import RecentInjections from 'components/blocks/injections/RecentInjections';
  import { convertNullValuesToUndefined } from '@/utils/objectHelpers';
  import { AuthService } from '@/services/auth.service';

  const UpdatedEvent = 'updated';
  const CreatedEvent = 'created';
  const ArchivedEvent = 'archived';

  export default {
    name: 'ColumnEditModal',

    components: {
      RecentInjections,
      LoadingComponent,
      IconMaterial,
      Btn,
      ColumnEditComponent,
      StateArchived,
      modal: ModalComponent,
      field: StandardModelFieldComponent,
    },

    props: {
      column: Object,
      canAchieve: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      const column = { ...COLUMN_EMPTY };
      _.assign(column, this.column);

      return {
        columnData: column,
        errors: { ...COLUMN_EMPTY },
        watchColumnDataChanges: true,
        serialChanged: false,

        isOpen: false,

        isLoadingColumnInjections: false,

        hasPermissionToEdit: AuthService.userData().role === 'admin',
      };
    },

    computed: {
      isArchived() {
        return !(!!this.column && !this.column.archived);
      },
      isDataChanged() {
        if (!this.column || typeof this.column !== 'object') return false;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
        const { injections, injections_aggregate, ...columnFieldsForEditing } = this.columnData;

        return Boolean(
          Object.keys(columnFieldsForEditing).find(
            (key) => this.column[key] !== this.columnData[key],
          ),
        );
      },
    },

    watch: {
      column: {
        handler(newColumn) {
          if (!this.isOpen) {
            this.updateColumnDataFromObject(newColumn);
          }
        },
        deep: true,
      },
      'columnData.serial': {
        handler() {
          if (this.watchColumnDataChanges) {
            this.serialChanged = true;
          }
        },
      },
      isOpen(value) {
        if (value) {
          this.initColumn();
        }
      },
    },

    methods: {
      opened() {
        this.clearErrors();
        this.$emit('opened');
        this.isOpen = true;

        this.columnData = { ...COLUMN_EMPTY, ...this.column };
        this.errors = { ...COLUMN_EMPTY };
        this.watchColumnDataChanges = false;
      },
      closed() {
        this.$emit('closed');
        this.isOpen = false;
      },

      resetChangedStatus() {
        this.serialChanged = false;
      },
      submitErrorCallback(data, status) {
        if (status === 400) {
          Object.keys(data).forEach((field) => {
            this.errors[field] = data[field][0];
          });
        } else this.notifyResponseError(data, status);
      },
      submitSuccessCallback(data) {
        this.resetChangedStatus();
        const column = { ...data };
        this.updateColumnDataFromObject(column);
        this.$emit(UpdatedEvent, column);
        this.close();
      },
      createNewSuccessCallback(data) {
        this.resetChangedStatus();
        const column = { ...data };
        this.updateColumnDataFromObject(column);
        this.$emit(CreatedEvent, column);
        this.close();
      },
      updateColumnDataFromObject(newColumn) {
        this.watchColumnDataChanges = false;
        this.setColumnData(newColumn);
        this.resetChangedStatus();
        this.serialChanged = false;
        // NOTE: because of internal work of watch there is need to set watchColumnDataChanges at nextTick;
        this.$nextTick(() => {
          this.watchColumnDataChanges = true;
        });
      },
      setArchived(isArchived) {
        const columnId = this.column.id;

        const onSuccess = (c) => {
          this.columnData = c;
          this.$emit(ArchivedEvent, isArchived);
          if (isArchived) {
            this.close();
          }
        };

        ColumnAPI.archive(columnId, isArchived, onSuccess, this.notifyResponseError);
      },
      submit() {
        if (this.serialChanged) {
          this.createNew();
        } else {
          this.saveChanges();
        }
      },
      saveChanges() {
        if (!this.isDataChanged) {
          return;
        }

        this.clearErrors();
        ColumnAPI.put(
          this.column.id,
          {
            ...convertNullValuesToUndefined(this.columnData),
            name: this.columnData.name?.trim(),
          },
          this.submitSuccessCallback.bind(this),
          this.submitErrorCallback.bind(this),
        );
      },

      createNew() {
        if (!this.isDataChanged) {
          return;
        }

        this.clearErrors();
        ColumnAPI.post(
          {
            ...convertNullValuesToUndefined(this.columnData),
            name: this.columnData.name?.trim(),
          },
          this.createNewSuccessCallback.bind(this),
          this.submitErrorCallback.bind(this),
        );
      },

      reset() {
        this.updateColumnDataFromObject(this.column);
      },
      clearErrors() {
        this.errors = { ...COLUMN_EMPTY };
      },
      clearError(fieldName) {
        this.errors = { ...this.errors, [fieldName]: null };
      },

      close() {
        this.$modal.hide('column-detail');
      },

      expand() {
        this.$router.push({
          name: 'columnDetails',
          params: {
            columnId: this.column.id,
          },
        });
      },

      setColumnData(column) {
        this.columnData = {
          ...this.columnData,
          ...column,
        };
      },

      async initColumn() {
        this.isLoadingColumnInjections = true;

        try {
          const column = await apiColumns.getColumn(this.column.id);
          this.setColumnData(column);
        } catch (e) {
          this.notifyError('Unable to get a column!');
        } finally {
          this.isLoadingColumnInjections = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-column-edit {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__btn-expand {
      margin-left: 15px;
      font-size: 16px;
    }

    &__title-injections {
      margin: 16px;
    }

    &__total {
      color: $color-text-third;
      font-size: $size-sm;
    }

    &__message-no-injections {
      margin: 16px;
    }

    &__loader-injections {
      margin: 16px 0;
    }

    &__no-permissions {
      color: $color-text-danger;
    }
  }

  .column-archive-button {
    margin-left: 32px;
    align-self: flex-end;
  }

  .name-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>
