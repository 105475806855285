<template>
  <div class="in-use-status" />
</template>

<script>
  export default {
    name: 'InUseStatus',
  };
</script>

<style lang="scss" scoped>
  .in-use-status {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: $color-bg-success;
    border: 1px solid white;
  }
</style>
