// Columns page
export const EVENT_COLUMN_UPDATE = 'columnPage:columnUpdate';
export const EVENT_COLUMN_CREATE = 'columnPage:columnCreate';
export const EVENT_COLUMN_ARCHIVE = 'columnPage:columnArchive';

// Methods page
export const EVENT_METHOD_UPDATE = 'methodPage:methodUpdate';
export const EVENT_METHOD_CREATE = 'methodPage:methodCreate';
export const EVENT_METHOD_ARCHIVE = 'columnPage:methodArchive';

// Postprocessing templates page
export const EVENT_POSTPROCESSING_TEMPLATE_UPDATE =
  'postprocessingTemplatePage:postprocessingTemplateUpdate';
export const EVENT_POSTPROCESSING_TEMPLATE_CREATE =
  'postprocessingTemplatePage:postprocessingTemplateCreate';
export const EVENT_POSTPROCESSING_TEMPLATE_ARCHIVE =
  'postprocessingTemplatePage:postprocessingTemplateArchive';
