<template>
  <div class="section-dropdown">
    <div v-if="!isShow" class="section-dropdown__header" @click="toggleSection">
      <div class="section-dropdown__title">
        {{ title }}
      </div>
      <div class="section-dropdown__toggle" :class="classToggle">
        <IconMaterial title="keyboard_arrow_down" class="section-dropdown__icon-toggle" />
      </div>
    </div>

    <div class="section-dropdown__content">
      <TransitionExpand>
        <div v-if="isShow">
          <slot></slot>
        </div>
      </TransitionExpand>
    </div>
  </div>
</template>

<script>
  import TransitionExpand from '@/uikitProject/transitions/TransitionExpand';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  export default {
    name: 'SectionDropdown',

    components: { IconMaterial, TransitionExpand },

    props: {
      title: {
        type: String,
        required: true,
      },
      defaultIsShow: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      isShow: false,
    }),

    computed: {
      classToggle() {
        return this.isDisabled && 'section-dropdown__toggle--disabled';
      },
    },

    methods: {
      show() {
        this.isShow = true;
      },
      hide() {
        this.isShow = false;
      },

      toggleSection() {
        if (this.isDisabled) return;

        this.isShow ? this.hide() : this.show();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .section-dropdown {
    overflow: hidden;
    min-height: 22px;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      cursor: pointer;
      user-select: none;
      border-radius: 4px;
    }

    &__toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;

      &--disabled {
        cursor: default;
        opacity: 0.3;
      }
    }

    &__title {
      color: $color-text-default;
      line-height: 22px;

      @include textOverflow;

      &--disabled {
        cursor: default;
      }
    }

    &__content {
    }

    &__icon-toggle {
      font-size: $size-md;
    }

    &__footer {
      position: relative;
    }
  }
</style>
