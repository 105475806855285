import Plotly, { PlotlyHTMLElement } from '@newcrom/plotly.js';
import { Callback, Nullable } from '@/types/utility';
import {
  Config,
  DataExtended,
  DetectionTime,
  Layout,
  Measurement,
  HorizontalZoomBounds,
} from '@/uikitProject/charts/new/private/types';
import {
  AXIS_HOVER_FORMAT,
  COLORS,
  CONFIG,
  DATA_HOVER_TEMPLATE_FOR_PRESSURE,
  SECONDS_IN_MINUTE,
  SIZES,
} from '@/uikitProject/charts/new/private/constants';
import {
  getHoverMessages,
  numberFormatterWith2FractionDigits,
  trimDataIfOutOfDetection,
} from '@/uikitProject/charts/new/private/helpers';

type InitialOptions = {
  container: HTMLElement;
  measurement: Measurement;
  detectionTime: DetectionTime;
  isApplyDetectionTime: boolean;
  expectedDurationSeconds: number;
  isInProgress: boolean;
  widthPx: number;
};

type RenderOptions = {
  isApplyDetectionTime: boolean;
  zoomBounds: Nullable<Partial<HorizontalZoomBounds>>;
  detectionTime: DetectionTime;
  expectedDurationSeconds: number;
  isInProgress: boolean;
  widthPx: number;
};

type Actions = {
  setIsApplyDetectionTime: Callback<[isApplyDetectionTime: boolean]>;
  zoom: Callback<[zoomBounds: Partial<HorizontalZoomBounds>]>;
  setDetectionTime: Callback<[detectionTime: DetectionTime]>;
  setExpectedDurationSeconds: Callback<[expectedDurationSeconds: number]>;
  setIsInProgress: Callback<[isInProgress: boolean]>;
  setWidth: Callback<[widthPx: number]>;
};

export class PressureDrawer {
  private chart: Nullable<PlotlyHTMLElement> = null;
  private config: Config = CONFIG;
  private renderOptions: RenderOptions = {
    isApplyDetectionTime: true,
    zoomBounds: null,
    detectionTime: null,
    expectedDurationSeconds: 0,
    isInProgress: false,
    widthPx: 0,
  };
  private currentMeasurement: Measurement;

  private mediaQueryIsMobileSize: MediaQueryList = window.matchMedia(
    `(max-width: ${SIZES.mobileScreenSizePx}px)`,
  );

  // TODO make private and add public methods?
  public actions: Actions = {
    setIsApplyDetectionTime: (isApplyDetectionTime: boolean) => {
      this.renderOptions.isApplyDetectionTime = isApplyDetectionTime;
      this.renderOptions.zoomBounds = null;
    },
    zoom: (zoomBounds: Partial<HorizontalZoomBounds>) => {
      this.renderOptions.zoomBounds = zoomBounds;
      this.refresh();
    },
    setDetectionTime: (detectionTime: DetectionTime) => {
      this.renderOptions.detectionTime = detectionTime;
    },
    setExpectedDurationSeconds: (expectedDurationSeconds: number) => {
      this.renderOptions.expectedDurationSeconds = expectedDurationSeconds;
    },
    setIsInProgress: (isInProgress: boolean) => {
      this.renderOptions.isInProgress = isInProgress;
    },
    setWidth: (widthPx: number) => {
      this.renderOptions.widthPx = widthPx;
      this.refresh();
    },
  };

  constructor(private initialOptions: InitialOptions) {
    this.currentMeasurement = initialOptions.measurement;
    this.renderOptions.isInProgress = initialOptions.isInProgress;
    this.renderOptions.detectionTime = initialOptions.detectionTime;
    this.renderOptions.isApplyDetectionTime = initialOptions.isApplyDetectionTime;
    this.renderOptions.expectedDurationSeconds = initialOptions.expectedDurationSeconds;
    this.renderOptions.widthPx = initialOptions.widthPx;
    this.initPlotly(initialOptions);
  }

  private async initPlotly({ container, measurement }: InitialOptions) {
    const { data, layout } = this.getDataForRender(measurement);
    const hasMoreThan1Coord = data.some((d) => d.y.length > 1);
    this.initialOptions.container.hidden = !hasMoreThan1Coord;
    this.chart = await Plotly.newPlot(container, data, layout, this.config);

    this.mediaQueryIsMobileSize.onchange = () => {
      this.refresh();
    };
  }

  public refresh(measurement = this.currentMeasurement) {
    const { data, layout } = this.getDataForRender(measurement);
    this.currentMeasurement = measurement;

    const hasMoreThan1Coord = data.some((d) => d.y.length > 1);
    this.initialOptions.container.hidden = !hasMoreThan1Coord;

    Plotly.react(
      this.initialOptions.container,
      data,
      layout,
      undefined,
      undefined,
      layout.xaxis?.range != null ? [layout.xaxis.range[0], layout.xaxis.range[1]] : null,
    );
  }

  private getDataForRender(measurement: Measurement): { data: DataExtended[]; layout: Layout } {
    const {
      isApplyDetectionTime,
      zoomBounds,
      detectionTime,
      expectedDurationSeconds,
      isInProgress,
      widthPx,
    } = this.renderOptions;
    // this.renderOptions.zoomBounds = null;
    const hasDetectionTime = detectionTime?.start != null || detectionTime?.end != null;
    const _isApplyDetectionTime = isApplyDetectionTime || isInProgress;

    const seconds = measurement.data.length / measurement.mps;
    const minutes = seconds / SECONDS_IN_MINUTE;
    const dx = minutes / measurement.data.length;

    const startTimeMinutes =
      zoomBounds?.x0 ?? (hasDetectionTime && _isApplyDetectionTime ? detectionTime?.start : null);
    const endTimeMinutes =
      zoomBounds?.x1 ?? (hasDetectionTime && _isApplyDetectionTime ? detectionTime?.end : null);
    const hasRangeBounds = startTimeMinutes != null || endTimeMinutes != null;

    const startIndex = Math.floor(
      startTimeMinutes != null && startTimeMinutes >= 0
        ? startTimeMinutes * SECONDS_IN_MINUTE * measurement.mps
        : 0,
    );
    const endIndex = Math.ceil(
      endTimeMinutes != null
        ? endTimeMinutes * SECONDS_IN_MINUTE * measurement.mps
        : measurement.data.length,
    );

    const yList = measurement.data;

    const y = hasRangeBounds
      ? trimDataIfOutOfDetection(
          yList,
          startIndex,
          endIndex < yList.length - 1 ? endIndex + 1 : endIndex,
        )
      : yList;

    const x0 = hasRangeBounds ? Math.max(startIndex * dx, 0) : 0;

    const data = [
      {
        y,
        dx,
        x0,
        mps: measurement.mps,
        hovertemplate: DATA_HOVER_TEMPLATE_FOR_PRESSURE,
        customdata: getHoverMessages(y, dx, x0, 'psi'),
        mode: 'lines' as const,
      },
    ];

    const notNullableYValues = yList.filter(Boolean);
    const maxMau = Math.max(...notNullableYValues);
    const maxMauWithGap = maxMau * 1.03;
    const minMauWithGap = -(maxMauWithGap * 0.03);

    const layout: Layout = {
      showlegend: false,
      xaxis: {
        visible: true,
        autorange: false,
        ticksuffix: ' min',
        showticksuffix: 'last',
        showticklabels: false,
        showgrid: false,
        range: [
          zoomBounds?.x0 ?? (_isApplyDetectionTime ? detectionTime?.start ?? 0 : 0),
          zoomBounds?.x1 ??
            (_isApplyDetectionTime
              ? detectionTime?.end ?? expectedDurationSeconds / SECONDS_IN_MINUTE
              : expectedDurationSeconds / SECONDS_IN_MINUTE),
        ],
        // To save current zoom
        uirevision: 'time',
        zerolinecolor: COLORS.zeroline,
        hoverformat: AXIS_HOVER_FORMAT,
        fixedrange: true,
        rangemode: 'normal',
      },
      yaxis: {
        // To save current zoom
        uirevision: 'time',
        // @ts-ignore
        ticklabeloverflow: 'allow',
        ticklabelposition: 'inside top',
        ticks: 'outside',
        tickcolor: COLORS.axisTick,
        tickfont: {
          color: COLORS.axisLabel,
        },
        zeroline: false,
        fixedrange: true,
        hoverformat: AXIS_HOVER_FORMAT,
        range: [minMauWithGap, maxMauWithGap],
        tickmode: 'array',
        tickvals: [0, maxMau],
        // TODO Little hack to avoid rounding of tickvals. tickformat doesn't work. Check the source code to see why
        ticktext: [`0`, ` ${numberFormatterWith2FractionDigits.format(maxMau)} psi`],
        rangemode: 'tozero',
        showline: true,
      },
      dragmode: false,
      margin: this.mediaQueryIsMobileSize.matches ? SIZES.margins.mobile : SIZES.margins.desktop,
      width: widthPx,
      height: 100,
      colorway: [COLORS.measurements.other],
    };

    return {
      data,
      layout,
    };
  }
}
