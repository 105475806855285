<template>
  <modal :width="750 - 256" name="import-csv">
    <div class="modal-content modal-content--no-padding">
      <div class="modal-header">
        <h4>Create injection from CSV</h4>
      </div>
      <div class="modal-body modal-body--big-padding">
        <label>Method</label>
        <SelectMethodComponent
          v-model="methodField"
          :configuration="configurationForMethod"
          modal-name="import-csv-modal__method-edit"
          style="width: 100%"
        />
        <br />
        <label>Column</label>
        <SelectColumnComponent v-model="columnField" style="width: 100%" />
        <form enctype="multipart/form-data" method="post">
          <br />
          <label>CSV file</label><br />
          <InputFileComponent v-model="files" pattern="*.csv" />
        </form>
      </div>
      <div class="modal-footer">
        <div class="modal-content__actions">
          <button :disabled="!valid" class="button--accent" @click="post">Upload</button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import { SampleAPI } from 'api/sample';

  import ModalComponent from 'components/element/ModalComponent.vue';
  import SelectMethodComponent from 'components/element/SelectMethodComponent.vue';
  import SelectColumnComponent from 'components/element/SelectColumnComponent.vue';
  import InputFileComponent from 'components/element/InputFileComponent';
  import { DEVICE_CONFIGURATION_FOR_IMPORT } from '@/constants/methods/configuration';

  const MODAL_NAME = 'import-csv';

  export default {
    name: 'ImportCsvModal',

    components: {
      InputFileComponent,
      SelectColumnComponent,
      SelectMethodComponent,
      modal: ModalComponent,
    },

    props: {
      sampleId: Number,
      method: Object,
      column: Object,
    },

    data() {
      return {
        methodField: this.method,
        columnField: this.column,
        files: null,

        configurationForMethod: DEVICE_CONFIGURATION_FOR_IMPORT,
      };
    },

    computed: {
      valid() {
        return this.methodField && this.columnField && this.files?.[0];
      },
    },

    methods: {
      close() {
        this.$modal.hide(MODAL_NAME);
      },
      post() {
        const formData = new FormData();

        formData.append('file', this.files[0]);
        formData.append('method_id', this.methodField.id);
        formData.append('column_id', this.columnField.id);

        SampleAPI.postInjectionCsv(
          this.sampleId,
          formData,
          this.close.bind(this),
          this.notifyResponseError,
        );
      },
    },
  };
</script>
