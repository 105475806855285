<template>
  <div class="columns-page">
    <NavbarSecondaryAccount />
    <ColumnEditModal
      :column="detailDialogColumn"
      canAchieve
      @closed="detailClosed"
      @updated="updateDetailColumn"
      @created="addColumnToTable"
      @archived="archiveColumn"
    />

    <column-create-modal @created="addColumnToTable" />

    <div class="top-line--flex columns-page__header">
      <div class="columns-page__wrapper-title">
        <h1 class="text-2xl sm:text-4xl" style="display: inline-block">
          <template v-if="archived">Archived Columns</template>
          <template v-else>Columns</template>
        </h1>
        <Btn
          v-if="!archived"
          height="s"
          icon-left="delete"
          type="transparent"
          class="archived-btn columns-page__btn-switch"
          @click="goToArchived()"
          >View archived</Btn
        >
        <Btn
          v-else
          height="s"
          icon-left="keyboard_arrow_left"
          type="transparent"
          class="archived-btn columns-page__btn-switch"
          @click="goToNotArchived()"
          >Back</Btn
        >
      </div>
      <div class="top-line__actions columns-page__actions">
        <InputSearch v-model="searchQuery" />
        <button
          v-if="hasPermissionToEdit && !archived"
          class="button--square-image"
          @click="openColumnCreate()"
        >
          <i class="material-icons material-icon--18">add</i>
          New
        </button>
      </div>
    </div>

    <table v-if="columns.length" class="table-default">
      <thead>
        <tr>
          <th>Name</th>
          <th>Serial</th>
          <th class="hidden sm:table-cell">Type</th>
          <th class="hidden sm:table-cell">Lot</th>
          <th class="hidden md:table-cell">Pressure</th>
          <th class="hidden md:table-cell">Length × ID</th>
          <th class="hidden sm:table-cell">Particle</th>
          <th class="hidden sm:table-cell">Pore</th>
        </tr>
      </thead>
      <tbody class="tbody__td--nowrap">
        <tr
          v-for="column in columns"
          :key="column.id"
          class="row--clickable"
          @click="showDetail(column)"
        >
          <td class="td--overflow-hidden table__cell table__cell--name" :title="column.name">
            {{ column.name }}
          </td>
          <td class="text-black table__cell table__cell--serial" :title="column.serial">
            {{ column.serial }}
          </td>
          <td class="hidden sm:table-cell text-black table__cell" :title="column.type">
            {{ column.type }}
          </td>
          <td class="hidden sm:table-cell text-gray table__cell" :title="column.lot">
            {{ column.lot }}
          </td>
          <td class="hidden md:table-cell text-gray table__cell" :title="column.max_pressure">
            {{ column.max_pressure }} psi
          </td>
          <td
            class="hidden md:table-cell text-gray table__cell"
            :title="`${round(column.length)} mm × ${round(column.internal_diameter)} mm`"
          >
            {{ round(column.length) }} mm × {{ round(column.internal_diameter) }} mm
          </td>
          <td class="hidden sm:table-cell text-gray table__cell" :title="column.name">
            {{ round(column.particle_size) }} μm
          </td>
          <td
            class="hidden sm:table-cell text-gray table__cell"
            :class="{ 'columns-page__cell--selected': Number(selectedColumnId) === column.id }"
            :title="column.name"
          >
            {{ column.pore_size }} Å
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="showMoreBtnVisible" class="table-bottom-container">
      <button class="button--unostentatious" @click="getNextColumns()">Show more</button>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';

  import 'assets/css/base/table.scss';

  import ColumnCreateModal from 'components/block/modal/ColumnCreateModal.vue';
  import ColumnEditModal from 'components/block/modal/ColumnEditModal.vue';
  import PageTitle from '@/mixins/page-title';
  import Btn from '@/uikitBase/btns/Btn';
  import NavbarSecondaryAccount from 'components/blocks/layouts/NavbarSecondaryAccount';
  import InputSearch from '@/uikitBase/inputs/InputSearch';
  import RouterHelper from 'utils/RouterHelper.ts';
  import { EVENT_BEFORE_DESTROY } from '@/constants/events/global.ts';
  import {
    EVENT_COLUMN_ARCHIVE,
    EVENT_COLUMN_CREATE,
    EVENT_COLUMN_UPDATE,
  } from '@/constants/events/cabinet.ts';
  import { apiColumns } from 'api/graphql/cloud/columns';
  import { AuthService } from '@/services/auth.service';

  export default {
    name: 'ColumnsPage',

    components: {
      InputSearch,
      NavbarSecondaryAccount,
      Btn,
      ColumnCreateModal,
      ColumnEditModal,
    },

    mixins: [PageTitle],

    data() {
      return {
        pageTitle: 'HPLC Cloud: Columns',
        archived: false,
        columns: [],
        columnsHash: {},

        columnsCount: -1,
        detailDialogColumn: null,
        detailColumnArchiveChanged: false,

        searchQuery: undefined,

        hasPermissionToEdit: AuthService.userData().role === 'admin',
      };
    },

    computed: {
      showMoreBtnVisible() {
        return this.columnsCount > this.columns.length;
      },
      selectedColumnId() {
        if (RouterHelper.isDualMode) {
          return RouterHelper.getRouteSecondary(this.$route)?.params?.columnId;
        }
        return null;
      },
    },

    watch: {
      searchQuery() {
        this.doSearch();
      },
      $route(to, from) {
        if (to.query.archived !== from.query.archived) {
          this.useRouteQuery(this.$route.query);
        }
      },
    },

    created() {
      this.initEventBus();

      this.doSearch = _.debounce(this.doSearch, 100);
    },

    mounted() {
      this.useRouteQuery(this.$route.query);
    },

    methods: {
      initEventBus() {
        this.$events.on(EVENT_COLUMN_ARCHIVE, ({ column, isArchived }) => {
          if (this.archived === isArchived) {
            this.columns.unshift(column);
            this.columnsCount++;
          } else {
            const columnsFiltered = this.columns.filter((_column) => _column.id !== column.id);

            if (columnsFiltered.length < this.columns.length) {
              this.columns = columnsFiltered;
              this.columnsCount--;
            }
          }
        });
        this.$events.on(EVENT_COLUMN_UPDATE, (column) => {
          this.columns = this.columns.map((_column) =>
            _column.id === column.id ? column : _column,
          );
        });
        this.$events.on(EVENT_COLUMN_CREATE, (column) => this.addColumnToTable(column));

        this.$once(EVENT_BEFORE_DESTROY, () => {
          this.$events.off([EVENT_COLUMN_UPDATE, EVENT_COLUMN_CREATE]);
        });
      },
      round(val) {
        return _.round(val, 2);
      },

      detailClosed() {
        if (this.detailColumnArchiveChanged) {
          // NOTE: test if column archive prop really changed then remove column
          // else do nothing
          if (this.detailDialogColumn.archived !== this.archived) {
            const index = this.columns.indexOf(this.detailDialogColumn);
            if (index !== -1) {
              this.columns.splice(index, 1);
              delete this.columnsHash[this.detailDialogColumn.id];
              this.columnsCount -= 1;
            }
          }
        }
      },

      useRouteQuery(query) {
        if (!!query && !!query.archived) {
          this.showArchived();
        } else {
          this.showNotArchived();
        }
      },
      showArchived() {
        this.archived = true;
        this.columns = [];
        this.getColumns();
      },
      showNotArchived() {
        this.archived = false;
        this.columns = [];
        this.getColumns();
      },

      goToArchived() {
        this.$router.push({ query: { archived: true } });
      },
      goToNotArchived() {
        this.$router.push({ query: { archived: undefined } });
      },

      openColumnCreate() {
        this.$modal.show('column-create');
      },

      showDetail(column) {
        if (RouterHelper.isDualMode) {
          this.$router.push({
            name: 'columnDetails',
            params: {
              columnId: column.id,
            },
          });
        } else {
          this.detailColumnArchiveChanged = false;
          this.detailDialogColumn = column;
          this.$modal.show('column-detail');
        }
      },

      archiveColumn(isArchived) {
        this.detailColumnArchiveChanged = true;
        this.detailDialogColumn.archived = isArchived;
      },
      updateDetailColumn(newColumnData) {
        const index = this.columns.indexOf(this.detailDialogColumn);
        if (index !== -1) {
          this.columns.splice(index, 1);
          _.assign(this.detailDialogColumn, newColumnData);
          this.columns.unshift(this.detailDialogColumn);
        }
      },

      addColumnToTable(column) {
        this.columnsCount += 1;
        this.columns.unshift(column);
        this.columnsHash[column.id] = true;
      },
      async getNextColumns() {
        const { columns, count } = await apiColumns.getColumns({
          search: this.searchQuery,
          isArchived: this.archived,
          offset: this.columns.length,
        });

        this.columnsCount = count;
        if (columns.length > 0) {
          const newColumns = [];

          Object.keys(columns).forEach((columnIndex) => {
            if (_.has(columns, columnIndex)) {
              const column = columns[columnIndex];
              if (!(column.id in this.columnsHash)) {
                newColumns.push(column);
              }
            }
          });

          if (newColumns.length > 0) {
            // / NOTE: sort columns if cur last column has less last_modified than first new column
            let isSort = false;
            if (this.columns.length > 0) {
              const curLastColumn = this.columns[this.columns.length - 1];
              const firstNewColumn = newColumns[0];
              isSort = firstNewColumn.last_modified_time > curLastColumn.last_modified_time;
            }
            this.columns.push(...newColumns);
            if (isSort) {
              this.columns.sort((a, b) => b.last_modified_time - a.last_modified_time);
            }
          }
        }
      },
      async getColumns() {
        const { columns, count } = await apiColumns.getColumns({
          search: this.searchQuery,
          isArchived: this.archived,
        });
        this.columns = columns;
        this.columnsHash = {};
        this.columns.forEach((column) => {
          this.columnsHash[column.id] = true;
        });
        this.columnsCount = count;
      },

      doSearch() {
        this.getColumns();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .columns-page {
    &__header {
      display: flex;
      align-items: baseline;

      @media (max-width: $screen-xs-max) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__wrapper-title {
      display: flex;
      align-items: baseline;

      @media (max-width: $screen-xs-max) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__btn-switch {
      margin-left: 30px;

      @media (max-width: $screen-sm-max) {
        margin-left: 15px;
      }

      @media (max-width: $screen-xs-max) {
        margin-left: 0;
      }
    }

    &__actions {
      @media (max-width: $screen-xs-max) {
        margin-top: 10px;
        align-self: flex-end;
      }
    }

    &__cell {
      &--selected {
        position: relative;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          content: '';
          height: 100%;
          width: 2px;
          background-color: $color-bg-primary;
        }
      }
    }
  }

  .table-bottom-container {
    display: flex;
    justify-content: center;
  }

  .table {
    &__cell {
      max-width: 80px;
      @include textOverflow;

      &--name {
        min-width: 140px;
      }

      &--serial {
        @media (max-width: $screen-sm-max) {
          max-width: 100px;
        }
      }
    }
  }
</style>
