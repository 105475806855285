<template>
  <span class="time">
    <slot :time="time">
      {{ time }}
    </slot>
  </span>
</template>

<script>
  const placeholder = '--:--';

  export default {
    name: 'Time',

    props: {
      date: {
        type: [String, Number, Date],
        required: true,
      },
    },

    computed: {
      time() {
        if (this.date) {
          return new Date(this.date).toLocaleTimeString({ hour: '2-digit', minute: '2-digit' });
        }
        return placeholder;
      },
    },
  };
</script>
