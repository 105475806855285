<template>
  <label
    class="decorator-btn-measurements"
    :class="{
      'decorator-btn-measurements--selected': this.isSelected,
      'decorator-btn-measurements--disabled': this.isDisabled,
    }"
    :style="{
      borderBottomColor: this.isSelected && this.selectedColor ? selectedColor : null,
    }"
  >
    <slot />
  </label>
</template>

<script>
  export default {
    name: 'Label',

    props: {
      isSelected: {
        type: Boolean,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      selectedColor: {
        type: String,
      },
    },

    computed: {
      classSelected() {
        return this.isSelected && 'decorator-btn-measurements--selected';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .decorator-btn-measurements {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 16px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    font-size: $size-xxs;
    cursor: pointer;
    color: $color-text-primary;
    min-width: 48px;

    &:hover {
      background-color: $color-bg-transparent--hover;
    }

    &:active {
      background-color: $color-bg-transparent--active;
    }

    &--selected {
      border-bottom-color: $color-bg-primary;
    }

    &--disabled {
      cursor: not-allowed;

      &:hover {
        background-color: unset;
      }

      &:active {
        background-color: unset;
      }
    }
  }
</style>
