var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Radio',{directives:[{name:"tippy",rawName:"v-tippy",value:({ touch: false }),expression:"{ touch: false }"}],attrs:{"content":_vm.value,"name":"vials","value":_vm.value,"isDisabled":_vm.isDisabled},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isChecked = ref.isChecked;
return [_c('div',{staticClass:"vial",class:{ 'vial--disabled': _vm.isDisabled }},[_c('div',{staticClass:"vial__circle",class:{
          'vial__circle--selected': isChecked,
          'vial__circle--disabled': _vm.isDisabled,
          'vial__circle--used': _vm.isUsed && !isChecked,
          'vial__circle--processing': _vm.isProcessing,
        }})])]}}]),model:{value:(_vm._checkedVial),callback:function ($$v) {_vm._checkedVial=$$v},expression:"_checkedVial"}})}
var staticRenderFns = []

export { render, staticRenderFns }