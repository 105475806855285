<template>
  <div>
    <Select
      :itemSelected="injection"
      :items="items"
      labelProp="name"
      :hasSearch="true"
      padding="md"
      :isDisabled="sampleId == null"
      placeholderSearch="Choose injection"
      titleBottomSheet="Choose injection"
      @update:itemSelected="selectedItemChanged"
      @showPopup="handleShowPopup"
      @hidePopup="handleHidePopup"
    />
  </div>
</template>

<script>
  import Select from '@/uikitBase/selects/vueSelect/Select';
  import { apiInjections } from '@/api/graphql/cloud/injections';

  const ModelEvent = 'change';

  export default {
    name: 'SelectInjection',

    components: { Select },

    model: {
      prop: 'injection',
      event: ModelEvent,
    },

    props: {
      injection: {
        type: Object,
        default: null,
      },
      sampleId: {
        type: Number,
      },
    },

    data: () => ({
      items: [],
      isSelectOpened: false,
    }),

    watch: {
      sampleId: {
        handler() {
          if (this.sampleId) {
            this.initInjections();
          }
        },
        immediate: true,
      },
    },

    methods: {
      handleShowPopup() {
        this.isSelectOpened = true;
        this.$emit('showPopup');
      },
      handleHidePopup() {
        this.isSelectOpened = false;
        this.$emit('hidePopup');
      },

      selectedItemChanged(item) {
        this.$emit(ModelEvent, item);
      },

      async initInjections() {
        const { injections } = await apiInjections.getInjections(this.sampleId);
        this.items = injections;
      },
    },
  };
</script>
