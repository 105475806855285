import { REST } from 'utils/rest';
import { METHOD_EMPTY } from '@/constants/methods/presets';
import RequestError from '@/errors/RequestError';

class MethodAPI {
  static get(id, onSuccess, onError) {
    const url = `/api/methods/${id}/`;
    REST.get(url, onSuccess, onError);
  }

  static prepare(data, onSuccess, onError) {
    const url = '/api/methods/:prepare/';
    REST.post(url, data, onSuccess, onError);
  }

  static post(method, onSuccess, onError) {
    const url = '/api/methods/';
    const data = {};
    const dataFields = Object.keys(METHOD_EMPTY);

    dataFields.forEach((field) => {
      if (field in method) {
        data[field] = method[field];
      }
    });

    REST.post(url, data, onSuccess, onError);
  }

  static put(method, onSuccess, onError) {
    const url = `/api/methods/${method.id}/`;
    const data = {};
    const dataFields = Object.keys(METHOD_EMPTY);

    dataFields.forEach((field) => {
      if (field in method) {
        data[field] = method[field];
      }
    });

    REST.put(url, data, onSuccess, onError);
  }

  static async getDefaultValues() {
    const url = `https://get.chrom.app/method_default_values.json`;
    const response = await fetch(url, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    });

    if (response.ok) {
      return response.json();
    }

    throw new RequestError();
  }
}

export default MethodAPI;
