import { SVG, Svg, Use } from '@svgdotjs/svg.js';
import { ElementBuilder } from '@/uikitProject/charts/vueChartGradient/private/ElementBuilder';
import {
  SIZES_PX,
  SVG_HEIGHT,
  SVG_POSITIONS,
} from '@/uikitProject/charts/vueChartGradient/private/config';

type GradientStep = {
  time: number;
  fractions: number[];
};
type Gradient = GradientStep[];

type Options = {
  el: HTMLElement;
  data: Gradient;
  // Injection duration
  injectionMinutes: number;
  hasTitle: boolean;
};

export default class GradientChart {
  private readonly svg: Svg;
  private readonly elementBuilder: ElementBuilder;

  constructor(private options: Options) {
    const { el } = options;

    this.svg = SVG()
      .addTo(el)
      .size('100%', SVG_HEIGHT)
      .css('width', `calc(100% + ${SIZES_PX.PADDING_RIGHT}px)`);
    this.elementBuilder = new ElementBuilder(this.svg);
  }

  get data(): Gradient {
    return this.options.data;
  }

  /**
   * Main line on the chart
   */
  get gradientLinePoints(): number[] {
    const points = this.data.reduce<number[]>((points, { time, fractions: [fractionA] }) => {
      const x = SVG_POSITIONS.CHART_X1 + this.getXByMinutes(time);
      const y = SVG_POSITIONS.CHART_Y1 + SIZES_PX.HEIGHT_CHART * fractionA;
      points.push(x, y);

      return points;
    }, []);

    // Add first and last point with the same gradient state
    const firstPoint = [
      SVG_POSITIONS.CHART_X1 + this.getXByMinutes(0), // x
      points[1], // y
    ];
    const lastPoint = [
      SVG_POSITIONS.CHART_X1 + this.getXByMinutes(this.options.injectionMinutes), // x
      points[points.length - 1], // y
    ];

    return [...firstPoint, ...points, ...lastPoint];
  }

  get width(): number {
    return this.svg.node.clientWidth - SIZES_PX.PADDING_LEFT - SIZES_PX.PADDING_RIGHT;
  }

  private getXByMinutes(minutes: number): number {
    return this.width / (this.options.injectionMinutes / minutes);
  }

  /**
   * Order of use calls is important
   * */
  public draw() {
    if (this.options.hasTitle) {
      const title = this.elementBuilder.createTitle();
      this.svg.use(title);
    }

    const [labelA, labelB] = this.elementBuilder.createLabels();
    this.svg.use(labelA);
    this.svg.use(labelB);

    const { gradientLinePoints } = this;

    const [areaA, areaB] = this.elementBuilder.createAreas(gradientLinePoints);
    this.svg.use(areaA);
    this.svg.use(areaB);

    const scale = this.elementBuilder.createScale();
    this.svg.use(scale);

    const gradientLine = this.elementBuilder.createLine(gradientLinePoints);
    this.svg.use(gradientLine);

    const points: Use[] = [];
    const point = this.elementBuilder.createPoint();
    // Loop excludes first and 2 last points
    for (let i = 2, x = 0; i < gradientLinePoints.length - 2; i += 2, x++) {
      const svgPoint = this.svg
        .use(point)
        .center(gradientLinePoints[i] - 5, gradientLinePoints[i + 1] - 5);

      points.push(svgPoint);
    }

    // Add information popup to every point
    points.forEach((svgPoint, index) => {
      const [valueA, valueB] = this.data[index].fractions;
      const popup = this.elementBuilder.createPopup(
        String(Math.round(valueA * 100)),
        String(Math.round(valueB * 100)),
      );

      // To prevent overflow on the right side of chart
      const xCoordinate = this.width - svgPoint.cx() < 50 ? svgPoint.cx() - 42 : svgPoint.cx() + 7;
      const svgPopup = this.svg
        .use(popup)
        .center(xCoordinate, svgPoint.cy() - 18)
        .hide();

      svgPoint.mouseover(() => svgPopup.show());
      svgPoint.mouseout(() => svgPopup.hide());
      svgPopup.mouseover(() => svgPopup.show());
      svgPopup.mouseout(() => svgPopup.hide());
    });
  }

  /**
   * To clear and redraw a chart
   * */
  public refresh(options?: Options) {
    if (options) {
      this.options = options;
    }

    this.svg.clear();
    this.draw();
  }
}
