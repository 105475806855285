<template>
  <div class="column-details">
    <GlobalEvents @keypress.enter="submit" />

    <template v-if="column">
      <h1>Column details</h1>

      <ColumnForm
        v-model="columnForEdit"
        :canArchive="true"
        :errors="errors"
        :isArchived="isArchived"
        class="column-details__form"
        @update:isArchive="setArchiveStatus"
        @updateField="clearError"
      />

      <div v-if="hasPermissionToEdit && !isArchived" class="column-details__actions">
        <div class="column-details__hints">
          <i>Save</i> - change values everywhere you used <b>column</b> before.<br />
          <i>Save as new</i> - keep original <b>column</b>.
        </div>
        <div class="column-details__btns">
          <Btn :disabled="!isDataChanged" class="column-details__btn" @click="revert"> Revert </Btn>
          <Btn
            :disabled="!isDataChanged"
            :type="isSerialChanged ? 'primary' : null"
            class="column-details__btn"
            @click="saveAsNew"
          >
            Save as new
          </Btn>
          <Btn
            :disabled="!isDataChanged"
            :type="!isSerialChanged ? 'primary' : null"
            class="column-details__btn"
            @click="save"
          >
            Save
          </Btn>
        </div>
      </div>
      <div v-else class="column-details__no-permissions">
        You can't edit and create columns. Ask the organization owner to give you more permissions
      </div>

      <h4 class="column-details__title-injections">
        Recent injections
        <span class="modal-column-edit__total"
          >(total {{ column.injections_aggregate.aggregate.count }})</span
        >
      </h4>
      <RecentInjections v-if="column.injections.length" :injections="column.injections" />
      <div v-else class="column-details__message-no-injections">
        The column hasn't been used yet
      </div>
    </template>
    <div v-else-if="isLoadingColumn">
      <LoadingComponent />
    </div>
  </div>
</template>

<script>
  import ColumnAPI from 'api/column';
  import ColumnForm from 'components/blocks/column/ColumnForm';
  import { COLUMN_EMPTY } from '@/constants/columns/presets';
  import { openInNewTab } from 'utils/browserHelpers.ts';
  import {
    EVENT_COLUMN_ARCHIVE,
    EVENT_COLUMN_CREATE,
    EVENT_COLUMN_UPDATE,
  } from '@/constants/events/cabinet.ts';
  import LoadingComponent from 'components/element/LoadingComponent';
  import { apiColumns } from 'api/graphql/cloud/columns';
  import RecentInjections from 'components/blocks/injections/RecentInjections';
  import Btn from '@/uikitBase/btns/Btn';
  import { AuthService } from '@/services/auth.service';

  export default {
    name: 'ColumnDetails',

    components: {
      Btn,
      RecentInjections,
      LoadingComponent,
      ColumnForm,
    },

    props: {
      columnId: {
        type: Number,
        required: true,
      },
    },

    data: () => ({
      column: null,
      columnForEdit: null,
      isLoadingColumn: false,
      isUpdatingColumn: false,
      errors: { ...COLUMN_EMPTY },

      hasPermissionToEdit: AuthService.userData().role === 'admin',
    }),

    computed: {
      isArchived() {
        return !(this.column && !this.column.archived);
      },
      isDataChanged() {
        if (this.column && typeof this.column === 'object' && this.columnForEdit) {
          return Object.keys(this.columnForEdit).some(
            (key) => this.column[key] !== this.columnForEdit[key],
          );
        }

        return false;
      },
      isSerialChanged() {
        return this.column.serial !== this.columnForEdit.serial;
      },
    },

    watch: {
      columnId: {
        handler() {
          this.initColumn();
        },
        immediate: true,
      },
    },

    methods: {
      setColumn(column) {
        this.column = {
          ...this.column,
          ...column,
        };
        this.setColumnForEdit(column);
      },
      setColumnForEdit(column) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
        const { injections, injections_aggregate, ...columnFieldsToEdit } = column;
        this.columnForEdit = { ...columnFieldsToEdit };
      },

      clearErrors() {
        this.errors = { ...COLUMN_EMPTY };
      },
      clearError(fieldName) {
        this.errors = { ...this.errors, [fieldName]: null };
      },

      handleSubmitError(data, status) {
        if (status === 400) {
          Object.keys(data).forEach((field) => {
            this.errors[field] = data[field][0];
          });
        } else {
          this.notifyResponseError(data, status);
        }
      },

      async initColumn() {
        this.isLoadingColumn = true;

        try {
          const column = await apiColumns.getColumn(this.columnId);
          this.setColumn(column);
        } catch (e) {
          this.notifyError('Unable to get a column!');
        } finally {
          this.isLoadingColumn = false;
        }
      },

      setArchiveStatus(isArchived) {
        const columnId = this.column.id;

        const onSuccess = (column) => {
          this.setColumn(column);
          this.$events.emit(EVENT_COLUMN_ARCHIVE, { column, isArchived });
        };

        ColumnAPI.archive(columnId, isArchived, onSuccess, this.notifyResponseError);
      },

      saveAsNew() {
        if (this.isDataChanged) {
          this.isUpdatingColumn = true;
          this.clearErrors();

          ColumnAPI.post(
            this.columnForEdit,
            (column) => {
              this.isUpdatingColumn = false;
              this.$events.emit(EVENT_COLUMN_CREATE, column);
              openInNewTab(`/app/columns/${column.id}`);
            },
            (data, status) => {
              this.handleSubmitError(data, status);
              this.isUpdatingColumn = false;
            },
          );
        }
      },
      save() {
        if (this.isDataChanged) {
          this.isUpdatingColumn = true;

          this.clearErrors();

          ColumnAPI.put(
            this.column.id,
            this.columnForEdit,
            (column) => {
              this.setColumn(column);
              this.$events.emit(EVENT_COLUMN_UPDATE, column);
              this.isUpdatingColumn = false;
            },
            (data, status) => {
              this.handleSubmitError(data, status);
              this.isUpdatingColumn = false;
            },
          );
        }
      },
      revert() {
        this.setColumnForEdit(this.column);
      },
      submit() {
        if (this.isDataChanged && !this.isUpdatingColumn) {
          document.activeElement.blur();
          this.isSerialChanged ? this.saveAsNew() : this.save();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .column-details {
    @media (max-width: $screen-sm-max) {
      padding: 0 10px;
    }

    &__title {
      font-size: $size-xl;
      font-weight: $weight-semi-bold;
      margin-bottom: 30px;
    }

    &__form {
      margin-bottom: 20px;
    }

    &__actions {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__hints {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    &__btns {
      margin-left: auto;
    }

    &__btn {
      margin-left: 15px;

      @media (max-width: $screen-sm-max) {
        &:first-child {
          margin-left: 0;
        }
      }
    }

    &__title-injections,
    &__message-no-injections {
      margin: 16px 0;
    }

    &__no-permissions {
      color: $color-text-danger;
    }
  }
</style>
