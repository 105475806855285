export const COLUMN_EMPTY = {
  name: null,
  type: null,
  serial: null,
  length: null,
  internal_diameter: null,
  lot: null,
  max_pressure: null,
  particle_size: null,
  pore_size: null,
};
