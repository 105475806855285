<template>
  <div class="loading-component">
    <img src="@/assets/img/spinner.gif" />
    <div v-if="label">{{ label }}</div>
    <div v-else-if="label !== null">Loading</div>
  </div>
</template>

<script>
  export default {
    name: 'LoadingComponent',
    props: ['label'],
  };
</script>

<style lang="scss">
  .loading-component {
    text-align: center;
    margin-top: 64px;
    width: 100%;
    color: #00000090;
    align-content: center;

    img {
      width: 16px;
      height: 16px;
      margin: 0 auto 0 auto;
      display: block;
    }

    div {
      margin-top: 16px;
    }
  }

  .loading-component--sm-inline {
    margin: 0 0 2px 0;
    display: inline-block;
    width: initial;
    vertical-align: bottom;
  }
</style>
