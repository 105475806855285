<template>
  <component
    :is="selectedComponent"
    class="state"
    :title="titleProcess"
    :hasTitle="hasTitle"
    :hasTitleOnAdaptive="hasTitleOnAdaptive"
    :isShortTitle="isShortTitle"
  />
</template>

<script>
  import StateNew from '@/uikitProject/states/StateNew';
  import StateFailed from '@/uikitProject/states/StateFailed';
  import StateProcess from '@/uikitProject/states/StateProcess';
  import StateReady from '@/uikitProject/states/StateReady';
  import StateRecord from '@/uikitProject/states/StateRecord';
  import StateConnectionLost from '@/uikitProject/states/StateConnectionLost';
  import StatePaused from '@/uikitProject/states/StatePaused';
  import StatePending from '@/uikitProject/states/StatePending';
  import StateExecuting from '@/uikitProject/states/StateExecuting';
  import StateUpdating from '@/uikitProject/states/StateUpdating';

  export const availableStates = [
    'NEW',
    'REC',
    '-',
    '@',
    'FAI',
    'RUN',
    'EXE',
    'PAU',
    'PEN',
    'PRE',
    'REA',
    'FIN',
    'WOR',
  ];

  // Набор состояний, который используется главными сущностями приложения:
  // Sample, Calibration, Sequences, Injections
  export default {
    name: 'StatesForEntity',

    props: {
      state: {
        type: String,
        validator(value) {
          return availableStates.includes(value);
        },
      },
      hasTitle: {
        type: Boolean,
        default: true,
      },
      hasTitleOnAdaptive: {
        type: Boolean,
        default: true,
      },
      isShortTitle: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      titleProcess() {
        switch (this.state) {
          case 'PRE':
            return 'Preparing';
          case 'RUN':
            return 'Running';
          case 'FIN':
            return 'Finishing';
          default:
            return null;
        }
      },

      selectedComponent() {
        switch (this.state) {
          case 'NEW':
            return StateNew;
          case 'REC':
            return StateRecord;
          case 'PAU':
            return StatePaused;
          case 'EXE':
            return StateExecuting;
          case 'FAI':
            return StateFailed;
          case '-':
            return StateConnectionLost;
          case '@':
            return StateUpdating;
          case 'PEN':
            return StatePending;
          case 'PRE':
          case 'RUN':
          case 'FIN':
            return StateProcess;
          case 'REA':
            return StateReady;
          default:
            return null;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .state {
    display: inline-block;
  }
</style>
