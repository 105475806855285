<template>
  <div>
    <ReactWrapper :component="Account" :props="props" />
  </div>
</template>

<script>
  import { Account } from '../../../../../frontendV2/dist/reactComponents';
  import ReactWrapper from '@/components/blocks/reactComponents/ReactWrapper';

  export default {
    name: 'Account',

    components: {
      ReactWrapper,
    },

    data: () => ({
      Account,
    }),

    computed: {
      props() {
        return {
          onShowVersionHistory: this.showVersionHistory,
        };
      },
    },

    methods: {
      showVersionHistory() {
        this.$modal.show('changelog-modal');
      },
    },
  };
</script>
