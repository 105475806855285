const isPointInside = ([x, y]: [number, number], coords: [number, number][]) => {
  let isInside = false;

  // eslint-disable-next-line no-plusplus
  for (let i = 0, j = coords.length - 1; i < coords.length; j = i++) {
    const xi = coords[i][0];
    const yi = coords[i][1];
    const xj = coords[j][0];
    const yj = coords[j][1];

    // eslint-disable-next-line eqeqeq
    const isIntersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (isIntersect) isInside = !isInside;
  }
  return isInside;
};

export default isPointInside;
