<template>
  <Btn type="transparent" :isLoading="isRemoving" padding="xs" @click="removePeak">
    <IconMaterial title="close" size="16" />
  </Btn>
</template>

<script>
  import MeasurementSocket from '@/api/sockets/MeasurementSocket';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import IconMaterial from '@/uikitBase/icons/IconMaterial.vue';

  export default {
    name: 'RemovePeakBtn',

    components: {
      IconMaterial,
      Btn,
    },

    props: {
      peakId: {
        type: Number,
        required: true,
      },
      measurementSocket: {
        type: MeasurementSocket,
        required: true,
      },
    },

    data: () => ({
      isRemoving: false,
    }),

    methods: {
      removePeak() {
        this.showNotificationIfRpcError(async () => {
          this.isRemoving = true;
          try {
            await this.measurementSocket.peakRemove(this.peakId);
          } finally {
            this.isRemoving = false;
          }
        });
      },
    },
  };
</script>
