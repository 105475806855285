<template>
  <modal name="sendReport" :pivot-y="0" :width="485" @opened="initData" @closed="reset">
    <div class="modal-content modal-content--no-padding">
      <div class="modal-header">
        <h4>Injection report</h4>
      </div>
      <div class="modal-body modal-body--big-padding send-report">
        <div v-if="isPreparingReportData" class="send-report__wrapper-loader">
          <LoadingComponent class="send-report__loader"> Loading report data </LoadingComponent>
        </div>
        <div class="send-report__content">
          <label>Format</label>
          <div class="send-report__wrapper-selector">
            <Tabs v-model="tabSelected" :tabs="tabs" class="send-report__selector" />
            <Btn
              type="primary"
              borderRadius="sm"
              :isLoading="isLoading || isPreparingReportData"
              class="send-report__btn-download send-report__btn-download--desktop"
              @click="download"
            >
              Download
            </Btn>
          </div>

          <Btn
            type="primary"
            borderRadius="sm"
            :isLoading="isLoading || isPreparingReportData"
            class="send-report__btn-download send-report__btn-download--adaptive"
            @click="download"
          >
            Download
          </Btn>

          <Select
            v-if="tabSelected.code === 'pdf'"
            :itemSelected="reportTypeSelected"
            :items="reportTypes"
            labelProp="title"
            :hasSearch="true"
            padding="md"
            placeholderSearch="Choose report type"
            titleBottomSheet="Choose report type"
            title="Report type"
            class="send-report__type"
            @update:itemSelected="selectReportType"
          />

          <div
            v-if="reportTypeSelected.id === 'rawData' && tabSelected.code === 'pdf'"
            class="send-report__information"
          >
            The report will only contain the raw data from the device. <br />
            If you want to see the processed version, select another report type.
          </div>

          <template v-if="!isShared">
            <InputForm
              v-model="inputs.message"
              :isMultiline="true"
              placeholder="Notes about injection to share with the recipient"
              name="Message"
              class="send-report__message"
            />

            <label>Contacts</label>
            <ContactList class="send-report__contacts">
              <template #action="{ contact }">
                <BtnSend
                  :wasSent="wasSent(contact.id)"
                  :contact="contact"
                  report="injection"
                  :reportType="tabSelected.code"
                  :sampleId="sample.id"
                  :injectionId="injection.id"
                  :message="inputs.message"
                  :measurementsSelectedIds="measurementIds"
                  :reportData="reportData"
                  :isPreparingReportData="isPreparingReportData"
                  @send="markAsSent"
                />
              </template>
            </ContactList>
          </template>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import ModalComponent from 'components/element/ModalComponent.vue';
  import Tabs from '@/uikitProject/selectors/Tabs';
  import Btn from '@/uikitBase/btns/Btn';
  import ContactList from 'components/blocks/contacts/vueContactList/ContactList';
  import BtnSend from '@/components/blocks/reports/BtnSend.vue';
  import InjectionAPI from 'api/injection';
  import { downloadFile } from 'utils/fileHelpers.ts';
  import InputForm from '@/uikitProject/inputs/InputForm';
  import { AuthService } from '@/services/auth.service.ts';
  import { InjectionCsv } from 'api/injection-csv';
  import _ from 'lodash';
  import LoadingComponent from '@/components/element/LoadingComponent.vue';
  import { apiMeasurements } from '@/api/graphql/cloud/measurements';
  import Select from '@/uikitBase/selects/vueSelect/Select.vue';

  const tabs = [
    { title: 'PDF', code: 'pdf' },
    { title: 'CSV', code: 'csv' },
    // { title: 'Method', code: 'method' },
  ];

  const REPORT_TYPES = [
    { title: 'Print raw data', id: 'rawData' },
    { title: 'Print processed data', id: 'processedData' },
    { title: 'Print all data', id: 'full' },
  ];

  export default {
    name: 'SendReportModal',

    components: {
      Select,
      LoadingComponent,
      InputForm,
      BtnSend,
      ContactList,
      Btn,
      Tabs,
      modal: ModalComponent,
    },

    props: {
      sample: {
        type: Object,
        required: true,
      },
      injection: {
        type: Object,
        required: true,
      },
      method: {
        type: Object,
        required: true,
      },
      column: {
        type: Object,
        required: true,
      },
      isShared: {
        type: Boolean,
      },
      owner: {
        type: Object,
      },
      sampleToken: {
        type: String,
      },
    },

    data: () => ({
      tabs,
      tabSelected: tabs[0],

      requestsSent: [],

      inputs: {
        message: '',
      },

      isLoading: false,
      isPreparingReportData: true,

      reportData: null,

      reportTypeSelected: REPORT_TYPES[1],
      reportTypes: REPORT_TYPES,
    }),

    computed: {
      wasSent() {
        return (contactId) => this.requestsSent.includes(contactId);
      },
      measurementIds() {
        return (
          Object.values(this.injection?.measurements)?.map((measurement) => measurement.id) ?? []
        );
      },
    },

    watch: {
      tabSelected() {
        this.requestsSent = [];
      },
      reportTypeSelected() {
        this.initData();
      },
    },

    methods: {
      async initData() {
        try {
          this.isPreparingReportData = true;
          this.reportData = await this.getReportData();
        } finally {
          this.isPreparingReportData = false;
        }
      },

      async getReportData() {
        const baseUrl = this.isShared ? null : window.origin;

        return {
          user: this.owner ?? {
            email: AuthService.userData()?.email,
          },
          sample: this.sample,
          method: this.method,
          injection: {
            ...this.injection,
            measurements: await Promise.all(
              Object.values(this.injection.measurements).map((measurement) =>
                this.getMeasurementData(measurement.id, measurement.ulid),
              ),
            ),
          },

          column: this.column,
          baseUrl,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
      },

      reset() {
        this.tabSelected = tabs[0];
        this.requestsSent = [];
      },

      async downloadPDF() {
        try {
          this.isLoading = true;

          const blob = await InjectionAPI.downloadReport(this.reportData);

          downloadFile(blob, `${this.sample.name}#${this.injection.name}.pdf`);
        } catch (e) {
          this.notifyError('Unable to download a report!');
        } finally {
          this.isLoading = false;
        }
      },
      async downloadCSV() {
        const measurement = Object.values(this.injection.measurements).find(
          (measurement) => measurement.name !== 'Pressure',
        );

        if (measurement) {
          const { response } = await InjectionCsv.get({
            id: this.injection.id,
            shareToken: this.sampleToken,
          });
          const blob = await response.blob();

          const filename = _.trim(
            response.headers.get('Content-Disposition').split('filename=')[1],
            '"',
          );

          downloadFile(blob, filename);
        } else {
          throw new Error('Injection must contain at least one measurement!');
        }
      },

      async download() {
        try {
          this.isLoading = true;

          if (!Object.values(this.injection.measurements).length) {
            this.notifyError('Select at least one measurement!');
            return;
          }

          switch (this.tabSelected.code) {
            case 'pdf': {
              await this.downloadPDF();
              break;
            }
            case 'csv': {
              await this.downloadCSV();
              break;
            }
            default:
              this.notifyError('This report type is not supported!');
          }
        } catch (e) {
          this.notifyError('Unable to download a report!');
        } finally {
          this.isLoading = false;
        }
      },

      markAsSent({ contactId, reportType }) {
        if (reportType === this.tabSelected.code) {
          this.requestsSent.push(contactId);
        }
      },

      async getMeasurementData(measurementId, measurementUlid) {
        const measurement = await apiMeasurements.getMeasurement(
          measurementId,
          measurementUlid,
          this.sample.id,
          this.sampleToken,
        );

        const isMeasurementPressure = measurement.name === 'Pressure';
        const isShowRawData =
          isMeasurementPressure ||
          this.reportTypeSelected.id === 'rawData' ||
          this.reportTypeSelected.id === 'full';
        const isShowProcessedData =
          !isMeasurementPressure &&
          (this.reportTypeSelected.id === 'processedData' || this.reportTypeSelected.id === 'full');

        const rawData = isShowRawData ? await measurement.getData(false) : null;
        const hasPostprocessing = measurement.postprocessings.length > 0;
        const processedData = isShowProcessedData
          ? hasPostprocessing
            ? await measurement.getData(true)
            : // We must show a raw chart if postprocessing has not been applied
            !isShowRawData
            ? await measurement.getData(false)
            : null
          : null;

        return {
          ...measurement,
          peaks: isShowProcessedData ? measurement.peaks : [],
          rawData,
          processedData,
        };
      },

      selectReportType(reportType) {
        this.reportTypeSelected = reportType;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .send-report {
    position: relative;

    @media (max-width: $screen-xxs-max) {
      padding: 16px;
    }

    &__wrapper-loader {
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.7);
    }

    &__loader {
      margin: 0;
    }

    &__wrapper-selector {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      @media (max-width: $screen-xxs-max) {
        margin-bottom: 16px;
      }
    }

    &__btn-download,
    &__btn-send {
      font-weight: $weight-bold;
    }

    &__btn-download {
      &--adaptive {
        display: none;

        @media (max-width: $screen-xxs-max) {
          display: block;
          margin-bottom: 24px;
          margin-left: auto;
        }
      }

      &--desktop {
        display: block;

        @media (max-width: $screen-xxs-max) {
          display: none;
        }
      }
    }

    &__type {
      margin-bottom: 20px;
    }

    &__message {
      width: 100%;
      margin-bottom: 10px;
    }

    &__selector {
      margin-right: 10px;

      @media (max-width: 440px) {
        width: 100%;
        margin-right: 0;
      }
    }

    &__information {
      width: 100%;
      border-radius: $border-radius__md;
      background-color: $color-bg-information;
      padding: 10px;
      margin-bottom: 20px;
    }
  }
</style>
