<template>
  <div class="method-preview-details">
    <div class="method-preview-details__wrapper-name">
      <StandardModelFieldComponent
        :value="_method.name"
        style="flex: 1 1"
        type="str"
        :is-title="true"
        label="Method name"
        disabled
        :isMultiline="true"
      />
    </div>

    <MethodEditComponent
      :method="_method"
      :configuration="configurationForMethod"
      class="mt-3"
      :isReadonly="true"
      :disabled="true"
    />
  </div>
</template>

<script>
  import MethodEditComponent from 'components/block/MethodEditComponent';
  import StandardModelFieldComponent from 'components/element/StandardModelFieldComponent';
  import { METHOD_DEFAULT } from '@/constants/methods/presets';
  import { isMethodDefaultConfiguration } from '@/utils/methodHelpers';
  import { DEFAULT_DEVICE_CONFIGURATION } from '@/constants/methods/configuration';

  export default {
    name: 'MethodPreviewDetails',

    components: {
      StandardModelFieldComponent,
      MethodEditComponent,
    },

    props: {
      method: {
        type: Object,
        default: () => ({ ...METHOD_DEFAULT }),
      },
      name: {
        type: String,
        default: 'method-preview',
      },
    },

    computed: {
      _method() {
        return { ...METHOD_DEFAULT, ...this.method };
      },
      configurationForMethod() {
        const isDefaultConfiguration = isMethodDefaultConfiguration(
          this._method.hardware_configuration,
        );

        return isDefaultConfiguration
          ? DEFAULT_DEVICE_CONFIGURATION
          : this._method?.hardware_configuration ?? DEFAULT_DEVICE_CONFIGURATION;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .method-preview-details {
    &__wrapper-name {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    &__state-archived {
      display: block;
      margin: 10px 0;
    }
  }
</style>
