<template>
  <IconMaterial :title="icon" />
</template>

<script>
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  export default {
    name: 'SortDirection',

    components: { IconMaterial },

    props: {
      direction: {
        type: String,
        required: true,
        validator(value) {
          const availableValues = ['asc', 'desc'];
          return availableValues.includes(value);
        },
      },
    },

    computed: {
      icon() {
        return this.direction === 'asc' ? 'arrow_upward' : 'arrow_downward';
      },
    },
  };
</script>
