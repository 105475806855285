import { AuthService } from '@/services/auth.service';
import { isProduction } from '@/utils/appHelpers';
import { envVariables } from '@/utils/envHelpers';
import * as Sentry from '@sentry/vue';

(async () => {
  if (
    envVariables.OPENREPLAY_KEY &&
    isProduction() &&
    (envVariables.APP_MODE === 'dev' || envVariables.APP_MODE === 'staging')
  ) {
    const { default: Tracker } = await import('@openreplay/tracker');

    const tracker = new Tracker({
      projectKey: envVariables.OPENREPLAY_KEY,
      onStart: ({ sessionToken }) => {
        Sentry.setTag('openReplaySessionToken', sessionToken);
        Sentry.setTag('openReplaySessionURL', tracker.getSessionURL());
      },
      // All options are not required here
      // @ts-ignore
      network: {
        sanitizer: (data) => {
          data.url = data.url.replace(/token=.+/, 'token=SANITIZED');
          return data;
        },
      },
    });

    tracker.start();
    tracker.setUserID(AuthService.userData().user_id ?? 'guest');
  }
})();
