import Vue from 'vue';

// @ts-ignore
import VueMq from '@/assets/libraries/vueMq';

export const breakpoints = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};

Vue.use(VueMq, {
  breakpoints: {
    [breakpoints.MOBILE]: 767,
    [breakpoints.DESKTOP]: Infinity,
  },
});
