<template>
  <div class="sequence-analysis-results">
    <template v-if="allCompounds.size">
      <div
        v-for="sample of parsedSamples"
        :key="sample.id"
        class="sequence-analysis-results__sample"
      >
        <div
          class="sequence-analysis-results__name"
          :class="{
            'sequence-analysis-results__name--bigger': isBigger,
          }"
        >
          Sample: {{ sample.name }}
        </div>
        <div class="sequence-analysis-results__wrapper-table">
          <table>
            <thead>
              <tr>
                <th>Injection</th>
                <th v-for="compound of allCompounds" :key="compound" :title="compound">
                  {{ compound }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="injection of sample.injections" :key="injection.id">
                <td>{{ injection.name }}</td>
                <td v-for="compound of allCompounds" :key="compound">
                  {{ injection.compounds[compound] || '-' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <div v-else>No compounds have been found yet</div>
  </div>
</template>

<script>
  export default {
    name: 'SequenceAnalysisResults',

    components: {},

    props: {
      samples: {
        type: Array,
        required: true,
      },
      isBigger: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      injectionHighlightedId: null,
    }),

    computed: {
      allCompounds() {
        return new Set(
          this.samples
            .flatMap((sample) =>
              sample.injections.flatMap((injection) =>
                injection.measurements.flatMap((measurement) =>
                  measurement.peaks.flatMap((peak) =>
                    peak.estimations.map((estimation) => estimation?.compound?.name),
                  ),
                ),
              ),
            )
            .filter(Boolean),
        );
      },

      parsedSamples() {
        return this.samples.map((sample) => ({
          id: sample.id,
          name: sample.name,
          injections: sample.injections.map((injection) => ({
            name: injection.name,
            compounds: Object.fromEntries(
              injection.measurements.flatMap((measurement) => {
                return measurement.peaks.flatMap((peak) =>
                  peak.estimations.map((estimation) => [
                    estimation.compound?.name,
                    estimation.amount ? `${estimation.amount.toFixed(3)} ${estimation.unit}` : '-',
                  ]),
                );
              }),
            ),
          })),
        }));
      },
    },
  };
</script>

<style scoped lang="scss">
  .sequence-analysis-results {
    &__sample {
      margin-bottom: 20px;
    }

    &__name {
      margin-bottom: 5px;

      &--bigger {
        font-size: $size-lg;
      }
    }

    &__wrapper-table {
      overflow-x: auto; /* Горизонтальный скролл */
      max-width: 100%;
    }

    table {
      table-layout: fixed;

      thead tr {
        background-color: $color-bg-third;
      }

      th,
      td {
        padding: 5px;
      }

      th,
      td {
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90px;
      }
    }
  }
</style>
