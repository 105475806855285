<template>
  <button
    class="btn"
    :type="!isSubmit && 'button'"
    :disabled="$attrs.disabled || isLoading"
    :class="classes"
    :style="styles"
    v-on="$listeners"
  >
    <div v-if="$slots.iconLeft || iconLeft" class="btn__icon btn__icon--left">
      <slot name="iconLeft">
        <IconMaterial :title="iconLeft" />
      </slot>
    </div>
    <span class="btn__content">
      <slot />
    </span>
    <div v-if="$slots.iconRight || iconRight" class="btn__icon btn__icon--right">
      <slot name="iconRight">
        <IconMaterial :title="iconRight" />
      </slot>
    </div>
  </button>
</template>

<script>
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  export default {
    name: 'Btn',

    components: {
      IconMaterial,
    },

    props: {
      type: {
        type: String,
        default: null,
        validator(value) {
          const availableValues = [
            'primary',
            'secondary',
            'transparent',
            'accent',
            'danger',
            'danger-inverse',
            null,
          ];
          return availableValues.includes(value);
        },
      },
      height: {
        type: String,
        default: 'm',
        validator(value) {
          const availableValues = ['auto', 'xxs', 'xs', 's', 'm'];
          return availableValues.includes(value);
        },
      },
      padding: {
        type: String,
        default: 'md',
        validator(value) {
          const availableValues = ['none', 'xxs', 'xs', 'sm', 'md', 'xl'];
          return availableValues.includes(value);
        },
      },
      borderRadius: {
        type: String,
        default: 'md',
        validator(value) {
          const availableValues = ['none', 'sm', 'md'];
          return availableValues.includes(value);
        },
      },
      maxWidth: {
        type: Number,
      },
      isSubmit: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      iconLeft: {
        type: String,
      },
      iconRight: {
        type: String,
      },
    },

    computed: {
      classBtnType() {
        return `btn--type--${this.type}`;
      },
      classBtnHeight() {
        return `btn--height--${this.height}`;
      },
      classPadding() {
        return `btn--padding--${this.padding}`;
      },
      classBorderRadius() {
        return `btn--radius--${this.borderRadius}`;
      },
      classLoading() {
        return this.isLoading && `btn--loading`;
      },

      classes() {
        const {
          classBtnType,
          classBtnHeight,
          classPadding,
          classSibling,
          classBorderRadius,
          classLoading,
        } = this;

        return [
          classBtnType,
          classBtnHeight,
          classPadding,
          classSibling,
          classBorderRadius,
          classLoading,
        ];
      },
      styles() {
        return {
          maxWidth: this.maxWidth ? `${this.maxWidth}px` : null,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;

    color: $color-text-default;
    background: $color-bg-button;
    font-weight: inherit;
    font-size: inherit;
    border: none;
    cursor: pointer;
    outline: none;
    white-space: nowrap;

    transition: background-color 67ms;

    &:hover {
      background-color: $color-bg-button--hover;
    }

    &:active {
      color: $color-text-default--active;
      background-color: $color-bg-button--active;
    }

    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      color: $color-text-default--disabled;
      background-color: $color-bg-button--disabled;
    }

    //Основные вариации дизайна
    &--type {
      &--primary {
        color: $color-text-on-primary;
        background-color: $color-bg-primary;

        &:hover {
          background-color: $color-bg-primary--hover;
        }

        &:active {
          color: $color-text-on-primary--active;
          background-color: $color-bg-primary--active;
        }

        &:disabled,
        &[disabled] {
          cursor: not-allowed;
          color: $color-text-on-primary--disabled;
          background-color: $color-bg-primary--disabled;
        }
      }

      &--secondary {
        color: $color-text-primary;
        background-color: #e3e3e3;

        &:hover {
          background-color: darken(#e3e3e3, 5%);
        }

        &:active {
          color: $color-text-primary;
          background-color: darken(#e3e3e3, 7%);
        }

        &:disabled,
        &[disabled] {
          cursor: not-allowed;
          color: lighten($color-text-primary, 10%);
          background-color: lighten(#e3e3e3, 5%);
        }
      }

      &--accent {
        color: $color-text-on-primary;
        background-color: $color-bg-accent;

        &:hover {
          background-color: $color-bg-accent--hover;
        }

        &:active {
          color: $color-text-on-primary--active;
          background-color: $color-bg-accent--active;
        }

        &:disabled,
        &[disabled] {
          cursor: not-allowed;
          color: $color-text-on-primary--disabled;
          background-color: $color-bg-primary--disabled;
        }
      }

      &--danger {
        color: $color-text-on-primary;
        background-color: $color-bg-danger;

        &:hover {
          background-color: $color-bg-danger--hover;
        }

        &:active {
          color: $color-text-on-primary--active;
          background-color: $color-bg-danger--active;
        }

        &:disabled,
        &[disabled] {
          cursor: not-allowed;
          color: $color-text-on-primary--disabled;
          background-color: $color-bg-primary--disabled;
        }
      }

      &--danger-inverse {
        color: $color-text-danger;
        background-color: #e3e3e3;

        &:hover {
          color: darken($color-text-danger, 3%);
        }

        &:active {
          color: darken($color-text-danger, 5%);
        }

        &:disabled,
        &[disabled] {
          cursor: not-allowed;
          color: lighten($color-text-danger, 10%);
          background-color: lighten(#e3e3e3, 5%);
        }
      }

      &--transparent {
        color: $color-text-primary;
        background-color: $color-bg-transparent;
        font-weight: $weight-semi-bold;

        &:hover {
          color: $color-text-primary--hover;
          background-color: $color-bg-transparent--hover;
        }

        &:active {
          color: $color-text-primary--active;
          background-color: $color-bg-transparent--active;
        }

        &:disabled,
        &[disabled] {
          cursor: not-allowed;
          color: $color-text-default--disabled;
          background-color: $color-bg-transparent--disabled;
        }
      }
    }

    &--height {
      &--auto {
        height: auto;
      }

      &--xxs {
        height: 16px;
      }

      &--xs {
        height: 20px;
      }

      &--s {
        height: 24px;

        i {
          margin-bottom: 1px;
        }
      }

      &--m {
        height: 32px;
      }
    }

    &--padding {
      &--none {
        padding: 0;
      }

      &--xxs {
        padding: 0 4px;
      }

      &--xs {
        padding: 0 8px;
      }

      &--sm {
        padding: 0 12px;
      }

      &--md {
        padding: 0 16px;
      }

      &--xl {
        padding: 0 24px;
      }
    }

    &--radius {
      &--sm {
        border-radius: $border-radius__sm;
      }

      &--md {
        border-radius: $border-radius__md;
      }
    }

    &--loading {
      cursor: wait;

      &:disabled,
      &[disabled] {
        cursor: wait;
      }
    }

    &__icon {
      display: flex;
      align-items: center;

      // TODO better to use em, when we specify all style and delete globals
      &--left {
        margin-right: 5px;
      }

      &--right {
        margin-left: 5px;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
