<template>
  <div class="value">
    <form class="value__form" @submit.prevent="update">
      <Input v-model="localValue" placeholder="Print new value" class="value__input" />

      <Btn :isSubmit="true" type="primary" class="value__btn-set">Set</Btn>
    </form>

    <Btn class="value__btn-get" @click="getCurrentValue">Get</Btn>

    <div class="value__current">{{ value }}</div>
  </div>
</template>

<script>
  import Input from '@/uikitBase/inputs/Input';
  import Btn from '@/uikitBase/btns/Btn';

  const EVENT_UPDATE = 'update';
  const EVENT_GET_CURRENT_VALUE = 'get';

  export default {
    name: 'Value',

    components: {
      Btn,
      Input,
    },

    props: {
      value: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        localValue: this.value,
      };
    },

    methods: {
      getCurrentValue() {
        this.$emit(EVENT_GET_CURRENT_VALUE);
      },

      update() {
        this.$emit(EVENT_UPDATE, this.localValue);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .value {
    display: flex;
    align-items: center;
    height: 34px;

    &__form {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &__input {
      margin-right: 10px;
      max-width: 120px;
      height: 100%;
      padding: 0;
    }

    &__btn-set,
    &__btn-get {
      height: 100%;
      margin-right: 10px;
    }

    &__current {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 12px;
      transition: background-color 0.3s;
      border-radius: $border-radius__md;
      border: 1px solid $color-bg-third;
      cursor: not-allowed;
    }
  }
</style>
