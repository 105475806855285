<template>
  <span>
    <div v-if="message" class="firmware-status">
      <template v-if="device.is_online">
        <span v-if="data.deprecated" class="firmware-status__message mr-4">
          <div class="deprecated">
            <IconMaterial title="warning" style="vertical-align: bottom; padding-bottom: 3px" />
            Version no longer supported
          </div>
        </span>

        <span class="firmware-status__message mr-4">
          <IconMaterial title="info" size="14" />
          {{ message }}
        </span>

        <div v-if="!state" class="firmware-status__actions">
          <Btn type="danger" height="s" icon-left="system_update" @click="update">Update now</Btn>
          <Btn type="transparent" height="s" class="firmware-status__btn-later" @click="updateLater"
            >Remind me later</Btn
          >
        </div>

        <Btn
          v-if="state === 'UPDATE_SUCCESS' || state === 'UPDATE_FAILED'"
          height="s"
          icon-left="check"
          @click="confirm"
        >
          Ok
        </Btn>

        <LoadingComponent v-if="isLoading" :label="null" class="firmware-status__loader" />
      </template>
      <template v-else-if="isLoading">
        <span class="firmware-status__message mr-4">
          <IconMaterial title="info" size="14" />
          Do not turn off your system during the update
        </span>
        <LoadingComponent :label="null" class="firmware-status__loader" />
      </template>
    </div>
    <div v-if="isSuperuser" class="force-update-container">
      <input v-model="targetVersion" class="firmware-version-input" @keypress="validateSymbol" />
      <Btn height="s" @click="forceUpdate(targetVersion)">Update to version</Btn>
    </div>
  </span>
</template>

<script>
  import LoadingComponent from 'components/element/LoadingComponent.vue';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  import VersionHelper from 'utils/VersionHelper.ts';
  import Btn from '@/uikitBase/btns/Btn';
  import { AuthService } from '@/services/auth.service.ts';

  const EVENT_UPDATE = 'update';
  const EVENT_UPDATE_FORCE = 'forceUpdate';
  const EVENT_CONFIRM = 'confirm';
  const EVENT_LATER = 'later';

  const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
  const UPDATE_FAILED = 'UPDATE_FAILED';
  const UPDATE_PENDING = 'UPDATE_PENDING';
  const UPDATING = 'UPDATING';

  export default {
    name: 'FirmwareStatus',

    components: {
      Btn,
      LoadingComponent,
      IconMaterial,
    },

    props: {
      device: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        targetVersion: null,
        tokenPayload: AuthService.userData(),
      };
    },

    computed: {
      data() {
        return this.device.versioning;
      },
      state() {
        return this.data?.state;
      },
      versionCurrent() {
        return this.data?.current_version;
      },
      versionLatest() {
        return this.data?.latest_version;
      },
      versionTarget() {
        return this.data?.target_version;
      },

      isSuperuser() {
        return this.tokenPayload?.is_superuser;
      },
      message() {
        const { formatVersion } = this;

        if (this.state === UPDATE_SUCCESS) {
          return `Successfully updated to ${formatVersion(this.versionCurrent)}`;
        } else if (this.state === UPDATE_FAILED) {
          return `Update to ${formatVersion(this.versionTarget)} failed`;
        }

        if (
          JSON.stringify(this.versionCurrent) !== JSON.stringify([1, 70]) && // TODO remove after all devices updated > v1.70
          VersionHelper.greaterThan(this.versionLatest, this.versionCurrent)
        ) {
          if (this.state === null) {
            return `New firmware version available (${formatVersion(this.versionLatest)})`;
          } else if (this.state === UPDATE_PENDING) {
            return `Waiting for response`;
          } else if (this.state === UPDATING) {
            return `Updating firmware`;
          }
        }

        return null;
      },

      isLoading() {
        return this.state === UPDATE_PENDING || this.state === UPDATING;
      },
    },

    methods: {
      formatVersion(version) {
        return version.join('.');
      },

      update() {
        this.$emit(EVENT_UPDATE);
      },
      updateLater() {
        this.$emit(EVENT_LATER, this.versionLatest);
      },

      forceUpdate(targetVersion) {
        // Correct format is 11.1111
        if (/^\d{1,2}([,.]\d{1,4})?$/.test(targetVersion)) {
          this.$emit(EVENT_UPDATE_FORCE, targetVersion.replace(',', '.'));
        } else {
          this.notifyError('Version format is invalid!');
        }
      },
      confirm() {
        this.$emit(EVENT_CONFIRM);
      },

      validateSymbol(e) {
        if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','].includes(e.key)) {
          e.preventDefault();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .firmware-status {
    display: flex;
    align-items: center;
    flex-direction: row;
    min-height: 25px;

    @media (max-width: $screen-xs-max) {
      flex-direction: column;
      align-items: flex-start;
    }

    &__message {
      margin-right: 10px;

      @media (max-width: $screen-xs-max) {
        margin-bottom: 8px;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
    }

    &__loader {
      display: inline-block;
      width: 16px;
      margin: 0;
    }

    &__btn-later {
      margin-left: 5px;
    }
  }

  .deprecated {
    display: inline-block;
    background-color: $color-bg-danger;
    color: white;
    padding: 1px 8px 1px;
    border-radius: 2px;
  }

  .force-update-container {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
  }

  .firmware-version-input {
    width: 100px;
    margin-right: 10px;
    height: 24px;
  }
</style>
