<template>
  <component :is="component" v-bind="componentProps" />
</template>

<script>
  export const isSecondaryView = Symbol('isSecondaryView');
  export const isPrimaryView = Symbol('isPrimaryView');

  export default {
    name: 'SecondaryView',

    provide: {
      [isPrimaryView]: false,
      [isSecondaryView]: true,
    },

    props: {
      component: {
        type: Object,
        required: true,
      },
      componentProps: {
        type: Object,
        required: true,
      },
    },
  };
</script>
