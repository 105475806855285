<template>
  <div class="standard-injection">
    <injection-header-component
      :label="'Standard injection'"
      :loading="updated !== true"
      :injection="injection"
      :injectionSocket="injectionSocket"
    />

    <InjectionErrors :error="injection.error" :method="method" />

    <div class="standard-injection__information">
      <DeviceMethodColumnComponent
        :vial="injection.vial"
        :postprocessings="postprocessings"
        :method="method"
        :column="injectionData.column"
        :device="injectionData.device"
        :sample="standard"
      />
      <Liquids
        v-if="isInjectionInProgress && deviceFull"
        :device="deviceFull"
        :method="method"
        class="standard-injection__liquids"
      />
    </div>

    <div style="margin: 24px 32px 0 32px">
      <table style="width: 100%; max-width: 400px">
        <tr>
          <td class="text-gray" style="width: 56px; vertical-align: bottom; padding-bottom: 5px">
            Peak
          </td>
          <td style="padding-top: 8px">
            <div
              class="input--faked input--faked-readonly text-right"
              style="width: 100%; padding-right: 12px"
            >
              <template v-if="injection.peak_id > 0">
                <b>{{ injection.peak_area.toFixed(3) }}</b>
                mAU×min at
                <b>{{ injection.peak_apex_time_str }}</b>
              </template>
              <i v-else class="text-gray">Peak not selected</i>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <template v-if="injection.state === 'NEW'">
      <not-available-component class="mt-8">
        <div>Did not started</div>
        <Btn class="mt-2" @click="restart">Restart</Btn>
      </not-available-component>
    </template>
    <template v-else-if="injection.state === 'PEN' || injection.state === 'PRE'">
      <RunSubstate
        v-if="injection.substate != null"
        :substate="injection.substate"
        style="margin: 64px 0 40px 0"
      />
      <nothing-there-component v-else style="margin-top: 32px">
        No recorded data
      </nothing-there-component>
    </template>
    <template v-else>
      <div class="standard-injection__wrapper-panel-control">
        <ChromatogramControl
          v-model="config"
          :measurements="measurementsSelectable"
          :pressure="pressure"
          :isInjectionRunning="injection.state === 'RUN'"
          :hasGradient="hasGradient"
          :hasPostprocessing="hasPostprocessing"
          class="standard-injection__panel-control"
        />
      </div>

      <ChartGradient
        v-if="config.isShowGradient && method.gradient"
        :gradient="method.gradient"
        :injectionMinutes="method.run_time_min"
        class="standard-injection__chart-gradient"
      />

      <div v-if="measurementsSelectedFiltered.length" style="margin-top: 16px">
        <NewChartChromatogram
          :measurements="measurementsSelectedFiltered"
          :isShowPressure="config.isShowPressure"
          :measurementPressure="measurementPressure"
          :method="method"
          :injection="injection"
          :doPerformanceOptimisations="isInjectionRunning"
          :hasToolbar="!isInjectionRunning && !standard.archived && !injection.archived"
          :injectionSocket="injectionSocket"
          :canSelectPeak="false"
          :timeMax="method.run_time_min"
          :initialZoom.sync="initialZoom"
          :creationDate="new Date(injection.created)"
          :isApplyPostprocessing="config.isApplyPostprocessing && !isInjectionInProgress"
          :postprocessings="postprocessings"
          :hasZoomActivationOverlay="hasZoomActivationOverlay"
          :editable="false"
          :hasPostprocessingsToApply="hasPostprocessingsToApply"
          :doReapplyPostprocessing="false"
          @currentPressure="setCurrentPressure"
          @update="$emit('update')"
          @applyPostprocessing="setIsApplyPostprocessing"
          @refreshPostprocessing="initPostprocessings"
          @zoomActivation="activateZoom"
          @postprocessingDone="refreshSelectedPeakInformation"
        >
          <template #peaks="{ peaks, setPeakHighlighting, highlightedPeakId }">
            <StandardPeaksComponent
              v-if="!standard.archived && !injection.archived"
              :selectedPeakID="injection.peak_id"
              :ppeaks="peaks"
              :injection-socket="injectionSocket"
              :highlightedPeakId="highlightedPeakId"
              @selection="setPeakHighlighting"
            />
          </template>
        </NewChartChromatogram>
      </div>

      <LoadingComponent v-else-if="updated == null" />
      <NothingThereComponent v-else style="margin-top: 32px">
        No measurement
      </NothingThereComponent>
    </template>
  </div>
</template>

<script>
  import NothingThereComponent from 'components/element/NothingThereComponent.vue';
  import LoadingComponent from 'components/element/LoadingComponent.vue';
  import NotAvailableComponent from 'components/element/NotAvailableComponent.vue';
  import StandardPeaksComponent from 'components/block/StandardPeaksComponent.vue';
  import InjectionHeaderComponent from 'components/block/InjectionHeaderComponent.vue';
  import { isPrevDateOlder } from 'utils/utils.ts';
  import Btn from '@/uikitBase/btns/Btn';
  import InjectionErrors from 'components/element/InjectionErrors';
  import ChromatogramControl from '@/uikitProject/chromatogram/panels/vueChromatogramControl/ChromatogramControl';
  import ChartGradient from '@/uikitProject/charts/vueChartGradient/ChartGradient';
  import RunSubstate from '@/uikitProject/states/packs/RunSubstate';
  import { consoleHelpers } from 'utils/logHelpers';
  import DeviceSocket, { DeviceSocketEvents } from 'api/sockets/DeviceSocket';
  import Liquids from 'components/blocks/injections/liquids/Liquids';
  import { sendDebugEvent } from 'utils/sentryHelpers';
  import faviconService, { FaviconStatuses } from '@/services/FaviconService';
  import { INJECTION_STATES } from '@/constants/injections/states';
  import NewChartChromatogram from '@/components/blocks/charts/chromatogramNew/NewChartChromatogram.vue';
  import { apiMeasurements } from '@/api/graphql/cloud/measurements';
  import DeviceMethodColumnComponent from '@/components/element/DeviceMethodColumnComponent.vue';
  import SampleInjectionSocket from '@/api/sockets/SampleInjectionSocket';
  import { StandardInjectionSocketEvents } from '@/api/sockets/StandardInjectionSocket';

  const DEBUG_TASK_MEASUREMENT_CAN_BE_UNDEFINED =
    'https://gitlab.com/hplc-cloud-group/hplc-cloud-server/-/issues/1841';

  export default {
    name: 'StandardInjectionComponent',

    components: {
      DeviceMethodColumnComponent,
      NewChartChromatogram,
      Liquids,
      RunSubstate,
      ChartGradient,
      ChromatogramControl,
      InjectionErrors,
      Btn,
      NotAvailableComponent,
      LoadingComponent,
      StandardPeaksComponent,
      NothingThereComponent,
      InjectionHeaderComponent,
    },

    props: {
      injectionID: Number,
      injectionData: Object,
      standard: Object,
    },

    data() {
      const { id } = this.injectionData.injection;

      return {
        id,
        injection: this.injectionData.injection,
        method: this.injectionData.method,
        unit: this.standard.unit,
        measurement: null,
        updated: null,
        timerConnectionLost: null,

        measurementsSelected: [],

        config: {
          measurementsSelectedIds: [],
          isShowGradient: false,
          isShowPressure: false,
          isApplyPostprocessing: false,
        },

        injectionSocket: this.createInjectionSocket(id),
        listenersInjectionSocketGroupId: null,
        isLoadingInjection: false,

        deviceSocket: null,
        listenersDeviceSocketGroupId: null,
        deviceFull: null,

        wasInProgressRecently: false,

        initialZoom: null,

        postprocessings: null,

        hasZoomActivationOverlay: true,

        currentPressure: null,
      };
    },

    computed: {
      configuration() {
        return this.injectionData?.injection?.configuration ?? {};
      },

      measurements() {
        return this.injection.measurements;
      },
      measurementPressure() {
        return Object.values(this.injection.measurements).find(
          (measurement) => measurement?.name === 'Pressure',
        );
      },
      measurementsSelectable() {
        return Object.values(this.injection.measurements).reduce((measurements, measurement) => {
          if (!measurement) {
            sendDebugEvent('#1841: Measurement is undefined', [
              ['All measurements', this.injection.measurements],
              ['Task', DEBUG_TASK_MEASUREMENT_CAN_BE_UNDEFINED],
            ]);
          }

          if (measurement && measurement?.name !== 'Pressure') {
            measurements[measurement.id] = measurement;
          }
          return measurements;
        }, {});
      },

      isPressureOutOfBounds() {
        if (this.currentPressure != null) {
          const { pressure_min, pressure_max } = this.injectionData.method;

          const isOutOfMin = pressure_min != null && this.currentPressure < pressure_min;
          const isOutOfMax = pressure_max != null && this.currentPressure > pressure_max;

          return this.isInjectionRunning && (isOutOfMin || isOutOfMax);
        }
        return false;
      },

      pressure() {
        if (this.measurementPressure) {
          return {
            current: this.currentPressure?.toFixed(),
            isOutOfBounds: this.isPressureOutOfBounds,
          };
        }
        return null;
      },
      isInjectionRunning() {
        return this.injection.state === INJECTION_STATES.RUNNING;
      },
      isInjectionInProgress() {
        return ['PEN', 'PRE', 'RUN', 'FIN', 'EXE'].includes(this.injection.state);
      },

      hasGradient() {
        return (
          this.configuration?.pump?.type === 'TWO SYRINGE GRADIENT' &&
          Boolean(this.method?.gradient?.length)
        );
      },

      hasPostprocessing() {
        return (
          this.postprocessings?.some((postprocessing) =>
            this.measurementsSelectedFiltered.some(
              (measurement) => measurement.id === postprocessing.measurement.id,
            ),
          ) ?? false
        );
      },

      measurementsSelectedFiltered() {
        return this.measurementsSelected.filter(Boolean);
      },

      hasPostprocessingsToApply() {
        // TODO this.injection.postprocessing_id in the future
        return (
          this.standard?.sequence?.postprocessing_id != null ||
          this.injection?.postprocessing_id != null
        );
      },
    },

    watch: {
      'config.measurementsSelectedIds'(ids) {
        setTimeout(() => {
          this.measurementsSelected = ids.map((id) => this.injection.measurements[id]);
        }, 0);
      },
      injectionData(injectionData) {
        if (injectionData == null) throw Error('injectionData should not be null');

        const idChanged = this.id !== injectionData.injection.id;

        if (idChanged) {
          this.hasZoomActivationOverlay = true;
        }

        const prevDateOlder = isPrevDateOlder(
          injectionData.injection.date_synced,
          this.injection.date_synced,
        );

        if (!idChanged && !prevDateOlder) {
          consoleHelpers.warn('Ignoring injectionData');
          return;
        }

        this.updated = null;

        this.id = injectionData.injection.id;
        const measurementsOld = this.injection.measurements;
        this.injection = injectionData.injection;

        if (idChanged) {
          this.initMeasurementsSelected(measurementsOld);

          this.injectionSocket = this.createInjectionSocket(this.id);
        }
      },
      standard(vn) {
        this.unit = vn.unit;
      },
      isInjectionRunning: {
        handler(value) {
          if (value && this.measurementPressure) {
            this.config.isShowPressure = true;
          }
        },
        immediate: true,
      },
      isInjectionInProgress: {
        handler(value) {
          if (value) {
            faviconService.setStatus(FaviconStatuses.RUNNING);
            this.deviceSocket = this.createDeviceSocket(this.injectionData.device.id);
          } else {
            this.destroyDeviceSocket();
          }
        },
        immediate: true,
      },
    },

    beforeDestroy() {
      if (this.timerConnectionLost != null) clearTimeout(this.timerConnectionLost);
      this.destroyInjectionSocket();
      this.destroyDeviceSocket();

      faviconService.setStatus(FaviconStatuses.NONE);
    },

    methods: {
      initMeasurementsSelected(_measurementsOld) {
        const measurementsNew = Object.values(this.injection.measurements);
        const measurementsOld = Object.values(_measurementsOld);

        const isSelectedAllMeasurements =
          measurementsOld.length === this.measurementsSelected.length;

        const measurementsSelectedTypes = this.measurementsSelected.map(
          (measurement) => measurement?.type,
        );
        const measurementsSelectedBasedOnPrevChoice = measurementsNew.filter((measurement) =>
          measurementsSelectedTypes.includes(measurement?.type),
        );

        if (isSelectedAllMeasurements && measurementsOld.length > 1) {
          this.config.measurementsSelectedIds = measurementsNew.map(({ id }) => id);
          this.measurementsSelected = measurementsNew;
        } else if (measurementsSelectedBasedOnPrevChoice.length) {
          this.config.measurementsSelectedIds = measurementsSelectedBasedOnPrevChoice.map(
            ({ id }) => id,
          );
          this.measurementsSelected = measurementsSelectedBasedOnPrevChoice;
        } else {
          const defaultMeasurementName = this.method.default_measurement ?? 'UV';
          // Analog detector's name is null. So I need to use its type field
          const defaultMeasurementType =
            this.method.default_measurement === 'Analog signal' && 'AD';

          const defaultMeasurement =
            measurementsNew.find((measurement) => {
              return (
                measurement.name === defaultMeasurementName ||
                measurement.type.startsWith(defaultMeasurementType)
              );
            }) ?? this.injection.measurements[this.injection.primary_measurement_id];

          if (defaultMeasurement) {
            this.config.measurementsSelectedIds = [defaultMeasurement.id];
            this.measurementsSelected = [defaultMeasurement];
          }
        }

        this.initPostprocessings();
      },

      createInjectionSocket(id) {
        this.destroyInjectionSocket();

        const injectionSocket = SampleInjectionSocket.start(id, async (connection) => {
          const injection = await connection.get();
          this.onInjection(injection);
        });

        const listenersGroup = injectionSocket.createEventListenersGroup();
        this.listenersInjectionSocketGroupId = listenersGroup.id;

        listenersGroup.addEventListener(StandardInjectionSocketEvents.INJECTION, (i) =>
          this.onInjection(i),
        );
        listenersGroup.addEventListener(StandardInjectionSocketEvents.PROGRESS, (i) => {
          if (this.injectionData.injection.id !== i.id) {
            return;
          }

          this.injection.state = i.state;
          this.injection.substate = i.substate;
          this.injection.progress = i.progress;
          this.injection.progress_time_str = i.progress_time_str;
          this.injection.date_synced = i.date_synced;

          // eslint-disable-next-line vue/no-mutating-props
          this.injectionData.injection = this.injection;

          this.onInjectionChanged(i);
        });

        return injectionSocket;
      },
      destroyInjectionSocket() {
        if (this.injectionSocket != null) {
          this.injectionSocket.close(this.listenersInjectionSocketGroupId);
        }
      },

      onInjectionChanged(i) {
        if (this.timerConnectionLost != null) clearTimeout(this.timerConnectionLost);
        if (
          [
            INJECTION_STATES.PENDING,
            INJECTION_STATES.PREPARING,
            INJECTION_STATES.RUNNING,
            INJECTION_STATES.NO_INTERNET,
          ].includes(i.state)
        ) {
          this.timerConnectionLost = setTimeout(() => this.connectionLost(), 4500);
          this.wasInProgressRecently = true;
        }

        if (i.state !== 'WOR' && i.state !== 'RUN' && i.state !== '-') {
          setTimeout(() => {
            this.showNotificationIfRpcError(() => this.injectionSocket.seen());
          }, 1000);
        }

        if (i.state === INJECTION_STATES.FAILED || i.state === INJECTION_STATES.READY) {
          setTimeout(() => {
            this.showNotificationIfRpcError(() => this.injectionSocket.seen());
          }, 1000);

          if (this.wasInProgressRecently) {
            faviconService.setStatus(
              i.state === INJECTION_STATES.FAILED
                ? FaviconStatuses.ERROR
                : FaviconStatuses.COMPLETED,
            );
          }
        }
      },

      connectionLost() {
        this.showNotificationIfRpcError(async () => {
          consoleHelpers.warn('No internet!');
          this.injection.state = '@';
          const injection = await this.injectionSocket.get();
          this.onInjection(injection);
        });
      },

      onInjection(i) {
        if (this.injectionData.injection.id !== i.id) {
          return;
        }

        const measurementsOld = this.injection.measurements;
        this.injection = i;
        // eslint-disable-next-line vue/no-mutating-props
        this.injectionData.injection = i;

        this.initMeasurementsSelected(measurementsOld);

        this.updated = true;

        this.onInjectionChanged(i);
      },

      restart() {
        return this.showNotificationIfRpcError(() => this.injectionSocket.restart());
      },

      createDeviceSocket(id) {
        this.deviceSocket?.close(this.listenersDeviceSocketGroupId);

        const onDevice = (deviceData) => {
          this.deviceFull = deviceData;
        };

        const deviceSocket = DeviceSocket.start(id, async (connection) => {
          connection.dontsleep();
          const { device } = await connection.get();
          onDevice(device);
        });

        const { id: groupId, addEventListener } = deviceSocket.createEventListenersGroup();
        this.listenersDeviceSocketGroupId = groupId;

        addEventListener(DeviceSocketEvents.DEVICE, onDevice);
        addEventListener(DeviceSocketEvents.STATE, (s) => {
          if (this.deviceFull) {
            this.deviceFull.state = s;
          }
        });

        return deviceSocket;
      },
      destroyDeviceSocket() {
        if (this.deviceSocket) {
          this.deviceSocket.close(this.listenersDeviceSocketGroupId);
          this.deviceSocket = null;
          this.deviceFull = null;
        }
      },

      setIsApplyPostprocessing(isApplyPostprocessing) {
        this.config.isApplyPostprocessing = isApplyPostprocessing;
      },

      resetZoom() {
        this.initialZoom = null;
      },

      async initPostprocessings() {
        this.postprocessings = await apiMeasurements.getPostprocessings(this.injection.id);
        this.config.isApplyPostprocessing = this.postprocessings != null;
      },

      activateZoom() {
        this.hasZoomActivationOverlay = false;
      },

      async refreshSelectedPeakInformation() {
        const { peak_apex_time_str, peak_area, peak_id } = await this.injectionSocket.get();

        if (peak_id != null) {
          this.injection = {
            ...this.injection,
            peak_apex_time_str,
            peak_area,
            peak_id,
          };
        } else {
          setTimeout(() => {
            this.refreshSelectedPeakInformation();
          }, 3000);
        }
      },

      setCurrentPressure(currentPressure) {
        this.currentPressure = currentPressure;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .standard-injection {
    &__wrapper-panel-control {
      display: flex;
      justify-content: center;
      margin: 48px 0 8px;

      @media (max-width: $screen-xs-max) {
        padding-top: 16px;
        overflow-x: auto;
        justify-content: flex-start;

        &::before,
        &::after {
          content: '';
          display: block;
          width: 16px;
          flex: none;
        }
      }
    }

    &__panel-control {
      margin: auto auto 24px;
    }

    &__chart-gradient {
      padding: 0 32px;
      margin-bottom: 20px;

      @media (max-width: $screen-sm-max) {
        padding: 0 10px;
      }
    }

    &__information {
      margin-top: 24px;
      padding: 0 32px;

      @media (max-width: $screen-sm-max) {
        padding: 0 16px;
      }
    }

    &__liquids {
      min-width: 280px;
      margin: 20px 0;
    }
  }
</style>
