<template>
  <div class="container">
    <div v-if="isEditMode" class="container__form">
      <div v-if="name" class="container__title-form">{{ name }}</div>

      <ValidationObserver #default="{ invalid }" tag="form" @submit.prevent="updateAmount">
        <InputForm
          ref="inputAmount"
          v-model="inputs.amount"
          title="New amount, ml"
          :rules="`required|max_value:${volume}|min_value:0`"
          type="number"
          step="0.1"
          class="container__input"
        />

        <div>
          <Btn
            borderRadius="sm"
            type="primary"
            height="s"
            padding="xs"
            class="container__btn-set"
            :disabled="invalid"
            :isSubmit="true"
          >
            Set
          </Btn>
          <Btn
            borderRadius="sm"
            type="transparent"
            height="s"
            padding="xs"
            class="container__btn-cancel"
            :isSubmit="false"
            @click="finishEditMode"
          >
            Cancel
          </Btn>
        </div>
      </ValidationObserver>
    </div>

    <template v-else>
      <div class="container__wrapper-bottle">
        <Bottle
          :volume="volume"
          :amount="amount"
          :label="label"
          :isInPourInMode="isPourInMode"
          class="container__bottle"
          @click="startEditMode"
        />

        <div class="container__info">
          <div
            v-if="name"
            v-tippy
            :content="name"
            class="container__item-info container__item-info--name"
          >
            {{ name }}
          </div>
          <div v-if="!isPourInMode && hasTooMuchLiquid" class="container__warning">Overflow</div>

          <div class="container__item-info container__item-info--amount">
            {{ amount }} <span class="container__unit">mL</span>
          </div>

          <!--<div
            v-if="isPourInMode"
            class="container__item-info container__item-info&#45;&#45;runs"
            :class="{ 'container__item-info&#45;&#45;warning': isPourInMode && hasFewRuns }"
          >
            {{ runs }} runs remain
          </div>-->
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import Bottle from '@/uikitProject/devices/liquids/Bottle';
  import InputForm from '@/uikitProject/inputs/InputForm';
  import Btn from '@/uikitBase/btns/Btn';

  const EVENT_UPDATE_AMOUNT = 'updateAmount';

  const SAVE_RUNS_NUMBER = 5;
  const MUCH_LIQUID_COEFFICIENT = 0.85;

  export default {
    name: 'Container',

    components: { Btn, InputForm, Bottle },

    props: {
      volume: {
        type: Number,
        required: true,
      },
      amount: {
        type: Number,
        required: true,
      },
      runs: {
        type: Number,
      },
      name: {
        type: String,
      },
      label: {
        type: String,
      },
      mode: {
        type: String,
        required: true,
        validator(value) {
          const availableValues = ['in', 'out'];
          return availableValues.includes(value);
        },
      },
    },

    data: () => ({
      isEditMode: false,

      inputs: {
        amount: '',
      },
    }),

    computed: {
      hasFewRuns() {
        return this.runs < SAVE_RUNS_NUMBER;
      },
      hasTooMuchLiquid() {
        return this.amount / this.volume > MUCH_LIQUID_COEFFICIENT;
      },

      isPourInMode() {
        return this.mode === 'in';
      },
    },

    methods: {
      async startEditMode() {
        this.isEditMode = true;

        this.inputs.amount = String(this.amount);

        await this.$nextTick();
        this.$refs.inputAmount.focus();
      },
      finishEditMode() {
        this.isEditMode = false;
      },

      updateAmount() {
        this.$emit(EVENT_UPDATE_AMOUNT, this.inputs.amount);
        this.finishEditMode();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .container {
    &__wrapper-bottle {
      display: flex;
      align-items: center;
    }

    &__bottle {
      margin-right: 10px;
      min-width: 44px;
      flex-shrink: 0;

      @media (max-width: $screen-xxs-max) {
        min-width: 35px;
        margin-right: 5px;
      }
    }

    &__info {
      overflow: hidden;
    }

    &__item-info {
      white-space: nowrap;
      @include textOverflow;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &--name {
        font-weight: $weight-bold;
      }

      &--amount {
        font-size: $size-md;

        @media (max-width: $screen-xxs-max) {
          font-size: $size-xs;
        }
      }
    }

    &__unit {
      font-size: $size-xs;
    }

    &__warning {
      margin-top: 5px;
      color: $color-text-process;
      animation: blink 1s infinite alternate;
      font-weight: $weight-bold;
    }

    &__title-form {
      font-weight: $weight-bold;
    }

    &__input {
      margin-bottom: 10px;
    }

    &__btn-set {
      margin-right: 10px;
    }
  }

  @keyframes blink {
    0% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
</style>
