<template>
  <div v-if="injectionsAll" class="block--gray block--shadow-in-top" style="padding-bottom: 32px">
    <div class="injections-list__header-block">
      <div>
        <h4 class="injections-list__title">Injections</h4>
        <ShowArchivedToggle v-model="showArchived" :n-archived="countArchived" />
      </div>
    </div>

    <div v-if="injectionsAll">
      <table v-if="!empty" class="table-default table-default--offset-32">
        <thead style="background-color: #0000000a">
          <tr>
            <th></th>
            <th style="text-align: right">#</th>
            <th></th>
            <th style="text-align: right">
              Amount<span class="sm:inline">, {{ standard.unit }}</span>
            </th>
            <th class="hidden sm:table-cell"><span class="mr-3">→</span>Peak, mAU×min</th>
            <th class="hidden sm:table-cell">Retention time</th>
            <th v-if="showDate">Date</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody class="tbody__td--nowrap">
          <tr
            v-for="i in shownInjections"
            :key="i.injection.id"
            class="row--clickable"
            :class="{ hover: injectionHighlightedId === i.injection.id }"
            @click="selectID(i.injection.id)"
            @mouseenter="setHighlight(true, i.injection.id)"
            @mouseleave="setHighlight(false, i.injection.id)"
          >
            <td class="td--shrink">
              <i v-if="!i.injection.archived" class="material-icons material-icon--16">
                show_chart
              </i>
              <i v-else class="material-icons material-icon--16 text-red">delete</i>
            </td>
            <td class="td--shrink" style="font-weight: bold; text-align: right">
              {{ i.injection.name }}
            </td>
            <td class="td--shrink">
              <StatesForEntity :hasTitleOnAdaptive="false" :state="i.injection.state" />
            </td>
            <td class="text-black td--overflow-hidden" style="width: 40%; text-align: right">
              <template v-if="standard.amount">
                {{ standard.amount }}
              </template>
            </td>
            <td class="text-black td--overflow-hidden hidden sm:table-cell" style="width: 40%">
              <template v-if="i.injection.peak_area">
                <span class="sm:inline mr-3">→</span>{{ i.injection.peak_area.toFixed(3) }}
              </template>
            </td>
            <td class="text-black td--overflow-hidden hidden sm:table-cell" style="width: 40%">
              {{ i.injection.peak_apex_time_str }}
            </td>
            <td
              v-if="showDate"
              class="td--shrink text-gray right"
              v-html="new Date(i.injection.date_started).toLocaleDateString()"
            />
            <td class="td--shrink text-gray" v-html="formatTime(i.injection.date_started)" />
          </tr>
        </tbody>
      </table>
      <nothing-there-component v-else style="margin-top: 16px">
        No injections for this standard.
        <template>
          <b>Add instructions</b> in sequence table and <b>start</b> the sequence.
        </template>
      </nothing-there-component>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import NothingThereComponent from 'components/element/NothingThereComponent.vue';
  import StatesForEntity from '@/uikitProject/states/packs/StatesForEntity.vue';
  import ShowArchivedToggle from 'components/element/ShowArchivedToggle.vue';

  export default {
    name: 'StandardInjectionsListComponent',

    components: {
      StatesForEntity,
      NothingThereComponent,
      ShowArchivedToggle,
    },

    model: {
      prop: 'injectionID',
      event: 'update:injectionID',
    },

    props: {
      isInProgress: {
        type: Boolean,
      },
      injectionID: Number,
      injections: Object,
      standard: Object,
      archived: Boolean,
      injectionHighlightedId: Number,
    },

    data() {
      return {
        showArchived: false,
      };
    },

    computed: {
      showDate() {
        if (this.shownInjections == null || _.isEmpty(this.shownInjections)) return false;
        const getDate = (i) => new Date(i?.injection?.date_started)?.toLocaleDateString();
        const dates = _.map(_.values(this.shownInjections), getDate);
        return !_.every(dates, (i) => i === dates[0]);
      },
      shownInjections() {
        if (this.archived) {
          return this.injections;
        }
        return _.pickBy(this.injections, (i) => !i.injection.archived);
      },
      injectionsAll: {
        get() {
          return this.injectionID == null;
        },
        set(b) {
          if (b) this.selectID(null);
        },
      },
      injectionNew: {
        get() {
          return this.injectionID === -1;
        },
        set(b) {
          if (b) this.selectID(-1);
        },
      },
      countArchived() {
        if (!this.injections) return false;

        return Object.values(this.injections).filter((i) => i.injection.archived).length;
      },
      empty() {
        if (!this.injections) return true;
        const isFound = Object.keys(this.injections).find(
          (key) => this.archived || !this.injections[key].injection.archived,
        );
        return !isFound;
      },
    },

    watch: {
      showArchived: 'setArchived',
    },

    methods: {
      selectID(id) {
        this.$emit('update:injectionID', id);
      },
      setArchived(b) {
        this.$emit('update:archived', b);
      },

      setHighlight(value, injectionId) {
        this.$emit('update:injectionHighlightedId', value ? injectionId : null);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .injections-list {
    &__header-block {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 24px 32px 16px 32px;
    }

    &__title {
      margin: 2px 16px 0 0;
      display: inline-block;
    }
  }
</style>
