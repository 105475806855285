// Can be used only for main app
import router from '@/router/app';
import { Nullable } from '@/types/utility';
import { dualModeRoutesPrimary } from '@/router/app/routes';
import { Query, Route } from '@/router/app/types';
import SplitScreenDisabledError from '@/utils/errors/SplitScreenDisabledError';
import { persistentStorage, PersistentStorageKeys } from '@/utils/persistentStorage';

export default class RouterHelper {
  public static isRoutePrimary(routeName: string): boolean {
    return Boolean(dualModeRoutesPrimary.find((route) => route.name?.split('.')[1] === routeName));
  }

  /**
   * Pass a currentRoute to save a reactivity in computed props
   * */
  public static getQuery(
    isSecondaryView = false,
    currentRoute: Route = router.currentRoute,
  ): Query {
    if (RouterHelper.isDualMode && isSecondaryView) {
      return RouterHelper.resolvePath(currentRoute.query.pathSecondary as string)?.query ?? {};
    }

    return currentRoute.query;
  }

  public static deleteQueryParams(paramNames: string[], doUseHistory = false): Promise<Route> {
    const query = { ...router.currentRoute.query };
    // Must assign undefined value to delete get param
    // @ts-ignore
    paramNames.forEach((name) => (query[name] = undefined));

    if (doUseHistory) {
      return router.push({ query });
    }
    return router.replace({ query });
  }

  public static get isSingleMode(): boolean {
    return !RouterHelper.isDualMode;
  }

  public static get isDualMode(): boolean {
    return router.currentRoute.path.startsWith('/dual/');
  }

  public static get isAllowDualMode(): boolean {
    return Boolean(persistentStorage.get(PersistentStorageKeys.IS_DUAL_MODE));
  }

  public static resolvePath(fullPath: string): Nullable<Route> {
    return router.resolve(decodeURIComponent(fullPath)).route;
  }

  public static closePrimary(): void {
    const { pathSecondary } = RouterHelper.getQuery();
    if (pathSecondary && typeof pathSecondary === 'string') {
      const { route } = router.resolve(decodeURIComponent(pathSecondary));
      router.push({
        ...route,
        name: `dual.${route.name}`,
        query: {
          ...route.query,
          rawLocation: 'true',
        },
      });
    }
  }

  public static closeSecondary(): void {
    RouterHelper.deleteQueryParams(['pathSecondary'], true);
  }

  public static get routePrimary(): Route {
    return this.getRoutePrimary();
  }

  /**
   * Pass a currentRoute to save a reactivity in computed props
   * */
  public static getRoutePrimary(currentRoute: Route = router.currentRoute): Route {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { pathSecondary, ...queryWithoutSecondary } = currentRoute.query;
    const _route = { ...router.currentRoute, query: queryWithoutSecondary };

    return router.resolve({
      ..._route,
      name: _route.name as string,
    }).route;
  }

  public static get routeSecondary(): Nullable<Route> {
    return this.getRouteSecondary();
  }

  /**
   * Pass a currentRoute to save a reactivity in computed props
   * */
  public static getRouteSecondary(currentRoute: Route = router.currentRoute): Nullable<Route> {
    const { pathSecondary } = RouterHelper.getQuery(false, currentRoute);

    if (!RouterHelper.isDualMode) {
      throw new SplitScreenDisabledError();
    }

    if (pathSecondary && typeof pathSecondary === 'string') {
      return router.resolve(decodeURIComponent(pathSecondary)).route;
    }
  }
}
