<template>
  <div class="input-autosuggest">
    <div class="input-autosuggest__wrapper-trigger" @click="showPopup">
      <div class="input-autosuggest__click-preventer">
        <slot name="mobileTrigger" :value="value" :isError="isError">
          <input :value="value" disabled class="input-autosuggest__trigger" />
        </slot>
      </div>
    </div>

    <BottomSheet
      :isShow="isShowPopup"
      :title="titleBottomSheet || placeholder"
      :hasPadding="false"
      @hide="hidePopup"
    >
      <div class="input-autosuggest__content">
        <div class="input-autosuggest__wrapper-input">
          <Input
            :value="localValue"
            class="input-autosuggest__input"
            :placeholder="placeholder"
            height="md"
            :hasClearBtn="true"
            v-bind="$attrs"
            @model="setLocalValue"
          />
          <Btn
            type="primary"
            padding="xs"
            borderRadius="sm"
            class="input-autosuggest__btn input-autosuggest__btn--done"
            @click="hidePopupWithSave(localValue)"
          >
            <IconMaterial title="done" />
          </Btn>
        </div>

        <div class="input-autosuggest__list">
          <LoaderSpinner v-if="isLoadingSuggestions" class="input-autosuggest__loader-spinner" />
          <List
            :items="itemsFiltered"
            :labelProp="labelProp"
            padding="md"
            @confirm="useSuggestedItem"
          />
        </div>
      </div>
    </BottomSheet>
  </div>
</template>

<script>
  import { model, props } from '../shared/config';
  import BottomSheet from '@/uikitBase/popups/BottomSheet';
  import Input from '@/uikitBase/inputs/Input';
  import List from '@/uikitBase/lists/List';
  import Btn from '@/uikitBase/btns/Btn';
  import LoaderSpinner from '@/uikitProject/loaders/LoaderSpinner';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  const EVENT_MODEL = 'model';
  const EVENT_SEARCH = 'search';
  const EVENT_MOUNTED = 'mounted';
  const EVENT_MOBILE_SAVE = 'mobileSave';

  const INPUT_TYPE_NUMBER = 'number';

  export default {
    name: 'MobileInputAutosuggest',

    components: {
      IconMaterial,
      LoaderSpinner,
      Btn,
      List,
      Input,
      BottomSheet,
    },

    inheritAttrs: false,

    model,

    props: {
      ...props,

      isLoadingSuggestions: {
        type: Boolean,
        required: true,
      },
    },

    data: () => ({
      isShowPopup: false,
      localValue: '',
    }),

    computed: {
      /***
       * Convert items to Array of objects
       */
      itemsParsed() {
        if (this.items.length) {
          const [item] = this.items;

          if (typeof item !== 'object') {
            return this.items.map((item, index) => ({ name: String(item), id: index }));
          }
        }

        return this.items;
      },
      itemsFiltered() {
        if (this.isAutosuggestDisabled) {
          return this.itemsParsed;
        }

        return this.itemsParsed.filter(
          (item) =>
            item[this.labelProp] !== this.localValue &&
            item[this.labelProp].toLowerCase().includes(this.localValue.toLowerCase()),
        );
      },

      isAutosuggestDisabled() {
        return this.$attrs.type === INPUT_TYPE_NUMBER;
      },
    },

    mounted() {
      this.$emit(EVENT_MOUNTED);
    },

    methods: {
      showPopup() {
        if (!this.isDisabled) {
          this.isShowPopup = true;
          this.initLocalValue();
        }
      },
      hidePopup() {
        this.isShowPopup = false;
      },
      hidePopupWithSave(value = this.localValue) {
        this.hidePopup();
        this.saveChanges(value);
        this.$emit(EVENT_MOBILE_SAVE);
      },

      setLocalValue(value) {
        this.localValue = value;
        this.$emit(EVENT_SEARCH, value);
      },
      clear() {
        this.setLocalValue('');
      },

      initLocalValue() {
        this.setLocalValue(this.value);
      },
      saveChanges(value = this.localValue) {
        this.$emit(EVENT_MODEL, value);
      },

      useSuggestedItem(item) {
        if (this.isAutosuggestDisabled) {
          this.hidePopupWithSave(item[this.labelProp]);
        } else {
          this.setLocalValue(item[this.labelProp]);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .input-autosuggest {
    display: flex;

    &__wrapper-trigger {
      width: 100%;
    }

    &__click-preventer {
      pointer-events: none;
    }

    &__trigger {
      background-color: transparent;
    }

    &__content {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      max-height: calc(100% - 15px);
    }

    &__wrapper-input {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      height: 38px;
      padding: 0 14px;
    }

    &__input {
      margin-right: 10px;
      font-size: $size-sm;
      height: 100%;
    }

    &__btn {
      flex: none;
      font-size: 20px;
      height: 38px;
      width: 38px;
    }

    &__list {
      flex: 1;
      position: relative;
      overflow-y: auto;
      min-height: 100px;
    }

    &__loader-spinner {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
</style>
