export const COLORS = {
  AREA_A: '#FFE7EC',
  AREA_B: '#EDEFFE',
  LABEL_A: '#FF003D',
  LABEL_B: '#243EFF',
  LABEL_BASE: '#949494',
  TITLE: '#949494',
  SCALE_VERTICAL: '#777777',
  GRADIENT_LINE: '#000',
  GRADIENT_POINT_OUTSIDE: '#000',
  GRADIENT_POINT_INSIDE: '#FF416E',
};

export const SIZES_PX = {
  PADDING_LEFT: 15,
  PADDING_RIGHT: 15,
  PADDING_TOP: 10,
  PADDING_BOTTOM: 15,
  HEIGHT_CHART: 25,
  HEIGHT_SCALE_VERTICAL: 55,
  GRADIENT_LINE_WIDTH: 2,
};

/**
 * Y1 - top left corner
 *
 * Y2 - bottom left corner
 * */
export const SVG_POSITIONS = {
  MAIN_RECT_Y1: SIZES_PX.PADDING_TOP,
  MAIN_RECT_Y2: SIZES_PX.PADDING_TOP + SIZES_PX.HEIGHT_SCALE_VERTICAL,

  CHART_X1: SIZES_PX.PADDING_LEFT,
  CHART_Y1: SIZES_PX.PADDING_TOP + SIZES_PX.HEIGHT_SCALE_VERTICAL / 2 - SIZES_PX.HEIGHT_CHART / 2,
  CHART_Y2: SIZES_PX.PADDING_TOP + SIZES_PX.HEIGHT_SCALE_VERTICAL / 2 + SIZES_PX.HEIGHT_CHART / 2,
};

export const SVG_HEIGHT =
  SIZES_PX.PADDING_TOP + SIZES_PX.HEIGHT_SCALE_VERTICAL + SIZES_PX.PADDING_BOTTOM;
