const updatePageTitle = function (title) {
  document.title = title;
};

export default {
  created() {
    updatePageTitle(this.pageTitle);
  },
  data() {
    return {
      pageTitle: 'HPLC Cloud by Sielc',
    };
  },
  watch: {
    pageTitle(newTitle) {
      updatePageTitle(newTitle);
    },
  },
};
