<template>
  <div class="previous-version">
    <div class="previous-version__title" @click="toggleIsShowChanges">#{{ version.title }}</div>

    <TransitionExpand>
      <Changes
        v-if="isShowChanges"
        :added="version.parsed.Added"
        :changed="version.parsed.Changed"
        :removed="version.parsed.Removed"
        :fixed="version.parsed.Fixed"
        class="previous-version__changes"
      />
    </TransitionExpand>
  </div>
</template>

<script>
  import Changes from 'components/block/modal/vueChangelogModal/shared/Changes';
  import TransitionExpand from '@/uikitProject/transitions/TransitionExpand';

  export default {
    name: 'PreviousVersion',

    components: { TransitionExpand, Changes },

    props: {
      version: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      isShowChanges: false,
    }),

    methods: {
      toggleIsShowChanges() {
        this.isShowChanges = !this.isShowChanges;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .previous-version {
    &__title {
      margin-bottom: 10px;
      color: $color-text-primary;
      cursor: pointer;

      &:hover {
        color: $color-text-primary--hover;
      }

      &:active {
        color: $color-text-primary--active;
      }
    }

    &__changes {
      margin-bottom: 20px;
    }
  }
</style>
