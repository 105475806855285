<template>
  <div class="nothing-there-yet print-hidden">
    <div class="nothing-there-yet__image-container">
      <img src="@/assets/img/cat-in-the-box.png" />
    </div>
    <div v-if="hasSlot" class="nothing-there-yet__content">
      <slot></slot>
    </div>
    <span v-else class="nothing-there-yet__content">Nothing there yet</span>
  </div>
</template>

<script>
  export default {
    name: 'NothingThereComponent',
    computed: {
      hasSlot() {
        return !!this.$slots.default;
      },
    },
  };
</script>

<style lang="scss">
  .nothing-there-yet {
    text-align: center;
    width: 100%;
    color: #00000099;
    align-content: center;

    &__image-container {
      width: 100%;
      clear: both;

      img {
        width: 96px;
        height: 58.8px;
        margin: 0 auto 16px;
        display: block;
        opacity: 0.5;
      }
    }

    &__content {
      margin: 0 32px;
    }
  }
</style>
