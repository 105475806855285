import Vue from 'vue';

import 'assets/css/plugin/tailwind.css';
import 'assets/css/style.scss';

import GlobalEvents from 'vue-global-events';
import Toasted from 'vue-toasted';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import '@/plugins/veeValidate';

import DateMixin from '@/mixins/date';
import NotifyMixin from '@/mixins/notify';
import store from '@/store';
import router from '@/router/app';
import App from '@/App.vue';
import '@/directives/global';
import '@/override/vue-js-modal'; // Vue.use(VModal, { dialog: true });
import { checkAuthOrRedirect } from '@/utils/url-protect';
import RouterHelper from 'utils/RouterHelper.ts';

Vue.component('GlobalEvents', GlobalEvents);
Vue.use(Toasted);
Vue.mixin(DateMixin);
Vue.mixin(NotifyMixin);

// Plugins
import '@/plugins/openreplay.ts';
import '@/plugins/tippy/tippy.ts';
import '@/plugins/vueEvents.ts';
import '@/plugins/hammer.ts';
import '@/plugins/portal.ts';
import '@/plugins/mediaQuery.ts';
import '@/plugins/sentry.ts';

import '../../registerServiceWorker';

Vue.prototype.$routerHelper = RouterHelper;

Vue.config.productionTip = false;

window.SEND_ACCEPT_VERSIONS = true;

// TODO Auth logic must be implemented in router hooks, because in the current case Vue launches the app anyway
checkAuthOrRedirect();

// Init scripts
import '@/services/FaviconService';

/* eslint-disable no-new */
const app = new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');

export default app;
