<template>
  <Tippy
    v-if="!isTouchDevice && hasAnyData"
    trigger="mouseenter"
    placement="bottom"
    :duration="[67, 0]"
    arrow
    :is-interactive="false"
    :isDisabled="isDisabled"
    :popperOptions="popperOptions"
    theme="lightblue"
    :isOverflowEllipsis="isOverflowEllipsis"
    class="popup-info-method-column"
  >
    <template #trigger>
      <slot />
    </template>
    <template #default>
      <div class="popup-info-method-column__popup">
        <InfoMethod
          v-if="method"
          :method="method"
          :hasMethodName="hasMethodName"
          :gridColumns="1"
          bgColor="#ecefff"
        />
        <InfoColumn v-else-if="column" :column="column" :gridColumns="1" bgColor="#ecefff" />
      </div>
    </template>
  </Tippy>
  <div v-else class="no-popup">
    <slot />
  </div>
</template>

<script>
  import NavigatorHelper from 'utils/NavigatorHelper.ts';

  import Tippy from '@/uikitBase/popups/vueTippy/Tippy';
  import InfoMethod from '@/uikitProject/info/InfoMethod';
  import InfoColumn from '@/uikitProject/info/InfoColumn';

  export default {
    name: 'PopupInfoMethodColumn',

    components: {
      InfoColumn,
      InfoMethod,
      Tippy,
    },

    props: {
      method: {
        type: Object,
      },
      column: {
        type: Object,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      hasMethodName: {
        type: Boolean,
      },
      isOverflowEllipsis: {
        type: Boolean,
      },
    },

    data: () => ({
      isTouchDevice: NavigatorHelper.isTouchDevice,
    }),

    computed: {
      hasAnyData() {
        return Boolean(this.method || this.column);
      },

      popperOptions() {
        return {
          modifiers: {
            preventOverflow: {
              boundariesElement: 'window',
              enabled: true,
            },
          },
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .popup-info-method-column {
    display: flex;
    align-items: center;

    &__popup {
      padding: 8px 12px;
      text-align: left;
      overflow: hidden;
      min-width: 300px;
      max-width: 500px;
    }
  }

  .no-popup {
    display: flex;
    align-items: center;
  }
</style>
