<template>
  <div
    v-if="
      configuration.hasOwnProperty('analog_signal') &&
      configuration.analog_signal != null &&
      configuration.analog_signal !== 0 /* Alltesta version < 1.97*/
    "
    class="hardware-tile"
  >
    <div class="hardware-tile__wrapper-title">
      <div>
        <i class="hardware-tile__title-icon">flare</i>
        <h4 v-if="method && method.analog_signal_type">
          {{ method.analog_signal_type }}
        </h4>
        <h4 v-else>Analog signal</h4>
      </div>
      <Information :data="popupInfoData" class="hardware-tile__information" />
    </div>

    <hardware-state-component :state="currentState" />

    <span v-if="method">{{ method.analog_signal_description }}</span>

    <template v-if="state">
      <ChromatogramLive
        ref="chromatogram"
        :mps="configuration.analog_signal.mps || 20"
        style="margin: 0 -16px -16px -16px"
        :chartBackground="'#00000000'"
        :chartAxisColor="'#00000000'"
        :chartTimeLabelsColor="'#00000000'"
        :chartTimeLabelsBg="'#00000000'"
      />
    </template>
  </div>
</template>

<script>
  import ChromatogramLive from '@/uikitProject/charts/vueChromatogramLive/ChromatogramLive.vue';
  import Base from './Base.vue';
  import Information from 'components/block/hardware/private/Information';

  export default {
    components: { Information, ChromatogramLive },

    extends: Base,

    computed: {
      currentState() {
        return this.state?.analog_signal;
      },

      popupInfoData() {
        const { mps, period } = this.configuration.analog_signal;

        return [
          {
            name: 'Measurements per second',
            value: mps,
          },
          {
            name: 'Period',
            value: period,
          },
        ];
      },
    },

    watch: {
      state(state) {
        if (state.analog_signal_details && this.$refs.chromatogram) {
          this.$refs.chromatogram.append(state.analog_signal_details.values);
        }
      },
    },
  };
</script>
