<template>
  <header class="header">
    <div class="header__content">
      <a class="header__wrapper-logo" href="/">
        <img
          src="@/assets/media/logo-brighter.png"
          srcset="@/assets/media/logo-brighter@2x.png 2x"
          class="header__logo"
        />
      </a>

      <div class="header__items">
        <Item :link="{ name: 'devices' }" icon="input">Devices</Item>
        <Item :link="{ name: 'samples' }" icon="opacity">Samples</Item>
        <Item :link="{ name: 'sequences' }" icon="group_work">Sequences</Item>
        <Item :link="{ name: 'methods' }" icon="account_circle" :isSelected="isUserInCabinet">
          Account
        </Item>
      </div>
    </div>
  </header>
</template>

<script>
  import Item from './private/Item';

  export default {
    name: 'Header',

    components: { Item },

    computed: {
      isUserInCabinet() {
        return ['account', 'methods', 'columns'].includes(this.$route.name);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .header {
    max-width: 100vw;
    overflow: hidden;
    z-index: 2;
    position: relative;
    height: 60px;
    background-color: $color-bg-second;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15), 0 3px 20px 0 rgba(0, 0, 0, 0.12);

    @media print {
      display: none;
    }

    &__content {
      display: flex;
      width: 100%;
      max-width: 900px;
      margin: auto;
      background-color: $color-bg-second;
    }

    &__wrapper-logo {
      flex: none;
      height: 60px;
      width: 60px;
      padding: 5px;
      margin: 0;

      &:hover {
        background: $color-bg-transparent--hover;
      }

      &:active {
        background: $color-bg-transparent--active;
      }
    }

    &__logo {
      flex-wrap: nowrap;
      height: 50px;
      padding: 0;
      margin: 0;
    }

    &__items {
      display: flex;
      margin-left: auto;
    }
  }
</style>
