<template>
  <DecoratorState
    :hasTitleOnAdaptive="hasTitleOnAdaptive"
    title="Paused"
    :hasTitle="hasTitle"
    class="state-paused"
  >
    <template #icon>
      <IconMaterial title="pause" size="16" class="state-paused__icon" />
    </template>
  </DecoratorState>
</template>

<script>
  import DecoratorState from '@/uikitProject/decorators/DecoratorState';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  export default {
    name: 'StatePaused',

    components: {
      IconMaterial,
      DecoratorState,
    },

    props: {
      hasTitle: {
        type: Boolean,
        default: true,
      },
      hasTitleOnAdaptive: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .state-paused {
    color: $pure-color__yellow;
    font-weight: $weight-bold;

    &__icon {
      margin-right: -3px;
    }
  }
</style>
