import { REST } from 'utils/rest';

const url = '/api/alltesta/';

export default class DeviceAlltestaAPI {
  static urlFor(serial) {
    return `${url + serial.toString()}/`;
  }

  static get(serial, onSuccess, onError) {
    return REST.get(DeviceAlltestaAPI.urlFor(serial), onSuccess, onError);
  }

  static put(serial, data, onSuccess, onError) {
    return REST.put(DeviceAlltestaAPI.urlFor(serial), data, onSuccess, onError);
  }
}
