import { REST } from 'utils/rest';

const URL_BASE = '/api/my/contacts/';

export default class ContactsAPI {
  static urlFor(id) {
    return `${URL_BASE}${id}/`;
  }

  static getList(onSuccess, onError) {
    return REST.get(URL_BASE, onSuccess, onError);
  }

  static create(data, onSuccess, onError) {
    return REST.post(URL_BASE, data, onSuccess, onError);
  }

  static edit(contactId, data, onSuccess, onError) {
    return REST.put(this.urlFor(contactId), data, onSuccess, onError);
  }

  static remove(contactId, onSuccess, onError) {
    return REST.delete(this.urlFor(contactId), onSuccess, onError);
  }
}
