<template>
  <Portal to="mobilePopup">
    <Transition name="slide">
      <div v-if="isShow" class="bottom-sheet" @click="onClickOverlay">
        <div
          class="bottom-sheet__popup"
          :style="stylePopup"
          @click.capture="onClickPopup"
          @click.stop
        >
          <header
            v-hammer:pan.vertical="onPan"
            v-hammer:panstart="onPanStart"
            v-hammer:panend="onPanEnd"
            class="bottom-sheet__header"
          >
            <!--            <div class="bottom-sheet__header-left">-->
            <!--              <slot name="headerLeft" />-->
            <!--            </div>-->

            <div class="bottom-sheet__title">
              {{ title }}
            </div>

            <!--            <div class="bottom-sheet__header-right">-->
            <!--              <slot name="headerRight" />-->
            <!--            </div>-->
          </header>
          <div
            class="bottom-sheet__content"
            :class="classContentPadding"
            @click="$emit('clickInside')"
          >
            <slot />
          </div>
        </div>
      </div>
    </Transition>
  </Portal>
</template>

<script>
  import LayoutHelper from 'utils/LayoutHelper.ts';

  const HEIGHT_OUT_OF_SCREEN_PX = 150;
  const TRANSITION_TIME_MS = 300;
  const EVENT_HIDE = 'hide';

  export default {
    name: 'BottomSheet',

    props: {
      isShow: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      hasPadding: {
        type: Boolean,
        default: true,
      },
    },

    data: () => ({
      popupTranslateY: 0,
      isPanActive: false,
      panStartY: null,
      hasTransition: false,
    }),

    computed: {
      popupTranslateYBounded() {
        const isInPx = this.popupTranslateY.includes('px');

        if (isInPx) {
          const [popupTranslateYPx] = this.popupTranslateY.split('px');

          return popupTranslateYPx < -HEIGHT_OUT_OF_SCREEN_PX
            ? -HEIGHT_OUT_OF_SCREEN_PX
            : this.popupTranslateY;
        }

        return this.popupTranslateY;
      },
      stylePopup() {
        if (this.isPanActive) {
          return {
            transform: `translateY(${this.popupTranslateYBounded})`,
            transition: this.hasTransition ? 'transform 0.2s ease-out' : 'none',
          };
        }

        return {};
      },
      classContentPadding() {
        return this.hasPadding && 'bottom-sheet__content--padding';
      },
    },

    watch: {
      isShow(isShow) {
        LayoutHelper.setPageScroll(isShow);
      },
    },

    methods: {
      onClickOverlay() {
        this.hide();
      },

      // Used in a capture phase
      onClickPopup(e) {
        if (this.isPanActive) {
          e.stopPropagation();
        }
      },

      hide() {
        this.$emit(EVENT_HIDE);
      },

      onPan(e) {
        this.popupTranslateY = `${e.center.y - this.panStartY}px`;
      },
      onPanStart(e) {
        this.isPanActive = true;
        this.panStartY = e.center.y;
      },
      onPanEnd(e) {
        if (this.panStartY < e.center.y / 2) {
          this.hasTransition = true;

          this.popupTranslateY = '80vh';

          setTimeout(() => {
            this.hide();
            this.isPanActive = false;
            this.hasTransition = false;
          }, TRANSITION_TIME_MS);

          return;
        }

        this.isPanActive = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  $height-out-of-screen: 150px;

  .bottom-sheet {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    background: rgba(200, 200, 200, 0.33);
    backdrop-filter: blur(5px);

    &__popup {
      position: absolute;
      bottom: -$height-out-of-screen;

      width: 100%;
      height: 100%;
      max-height: calc(80vh + #{$height-out-of-screen});
      padding-bottom: 150px;
      background-color: white;
      border-radius: 10px 10px 0 0;
      box-shadow: $shadow-light;
      transition: transform 0.2s ease-out;
      will-change: transform;
    }

    &__header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      padding: 10px 14px 0;
      border-bottom: 1px solid $color-bg-third;
    }

    &__title {
      width: 100%;
      font-weight: $weight-semi-bold;
      font-size: $size-md + 0.2;
      text-align: center;
      @include textOverflow;
    }

    &__header-left,
    &__header-right {
      display: flex;
      width: 100%;
      max-width: 20%;
      overflow: hidden;
    }

    &__header-right {
      display: flex;
      flex-direction: row-reverse;
    }

    &__content {
      position: relative;
      height: calc(100% - 54px);
      overflow-y: auto;
      text-align: justify;

      &--padding {
        padding: 0 14px 10px;
      }
    }
  }

  .slide {
    &-enter,
    &-leave-to {
      background-color: transparent;
      backdrop-filter: blur(0px);

      .bottom-sheet__popup {
        box-shadow: 0 5px 18px 14px transparent;
        transform: translateY(80vh) !important;
      }
    }

    &-enter-active {
      transition: background-color 0.2s, backdrop-filter 0.2s, box-shadow 0.2s !important;
      transition-timing-function: ease-out;

      .bottom-sheet__popup {
        transition: transform 0.2s, box-shadow 0.2s !important;
        transition-timing-function: ease-out;
      }
    }

    &-leave-active {
      transition: background-color 0.2s, backdrop-filter 0.2s, box-shadow 0.15s !important;
      transition-timing-function: ease-out;

      .bottom-sheet__popup {
        transition: transform 0.2s, box-shadow 0.15s !important;
        transition-timing-function: ease-out;
      }
    }
  }
</style>
