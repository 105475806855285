<template>
  <DecoratorPaperBlock :isClickable="true" :styles="{ padding: 0 }">
    <router-link
      class="device"
      :class="{
        'device--editable': isEditable,
      }"
      :to="linkToDeviceDetails"
    >
      <div class="device__main">
        <div class="device__wrapper-title">
          <IconMaterial title="input" size="18" class="device__icon-device" />

          <h2 class="device__title" data-test-id="device-title">
            {{ device.name }}
          </h2>
        </div>

        <div>
          <DeviceType :model="device.model" class="device__type" />

          <span class="device__connection">
            <DeviceConnection :device="device" />
          </span>
        </div>
      </div>
      <div class="device__wrapper-status">
        <Btn
          v-if="isEditable"
          height="s"
          type="transparent"
          class="device__btn-delete"
          data-test-id="btn-remove-device"
          @click.stop.prevent="remove"
        >
          <template #iconLeft>
            <IconMaterial title="delete" size="16" class="device__icon-delete" />
          </template>
          Delete
        </Btn>

        <StatusOnline
          v-else
          :isOnline="device.isOnline"
          :isWorking="isInjectionInProgress"
          class="device__status"
        />
      </div>
    </router-link>
  </DecoratorPaperBlock>
</template>

<script>
  import StatusOnline from '@/uikitProject/statuses/StatusOnline';
  import Btn from '@/uikitBase/btns/Btn';
  import DeviceType from '@/uikitProject/devices/DeviceType';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import DeviceConnection from '@/uikitProject/devices/vueDeviceConnection/DeviceConnection.vue';
  import DecoratorPaperBlock from '@/uikitProject/decorators/DecoratorPaperBlock';
  import { sendDebugEvent } from '@/utils/sentryHelpers';

  const EVENT_REMOVE = 'remove';

  export default {
    name: 'DeviceCard',

    components: {
      DecoratorPaperBlock,
      DeviceConnection,
      IconMaterial,
      DeviceType,
      Btn,
      StatusOnline,
    },

    props: {
      device: {
        type: Object,
        required: true,
      },
      isEditable: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      linkToDeviceDetails() {
        return {
          name: 'device',
          params: {
            id: this.device.id,
          },
        };
      },

      isInjectionInProgress() {
        if (this.device.state == null) {
          // TODO Check error and remove
          sendDebugEvent('#2269: Front: Fix sentry issues', [
            ['Device', this.device],
            ['Task', 'https://gitlab.com/hplc-cloud-group/hplc-cloud-server/-/issues/2269'],
            [
              'Sentry issue',
              'https://newcrom.sentry.io/issues/4709312795/?project=6192768&query=is:unresolved&statsPeriod=90d&stream_index=1',
            ],
          ]);
        }

        return this.device.state.injection_id != null;
      },
    },

    methods: {
      remove() {
        this.$emit(EVENT_REMOVE, this.device.id);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .device {
    display: flex;
    justify-content: space-between;
    padding: 16px;

    &--editable {
      cursor: move;
    }

    &:hover {
      text-decoration: none;
    }

    &__wrapper-title {
      margin-right: 20px;
      hyphens: auto;
      word-break: break-word;
    }

    &__icon-device {
      margin: 0 3px 3px 0;
      color: $color-text-primary;
    }

    &__title {
      display: inline;
      font-size: 22px;
      color: $color-text-primary;
      margin: 0;
      padding: 0;
      font-weight: $weight-bold;
    }

    &__type {
      color: $color-text-default;
    }

    &__connection {
      margin-left: 8px;
      display: inline-block;
      color: #00000066;
      font-weight: $weight-normal;
    }

    &__wrapper-status {
      display: flex;
    }

    &__status {
      flex: none;
    }

    &__btn-delete {
      align-self: center;
    }
  }
</style>
