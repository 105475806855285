<template>
  <Radio v-model="_checked" :name="name" :value="value">
    <template #default="{ isChecked }">
      <div
        class="btn"
        :class="{
          'btn--selected': isChecked,
        }"
        @click="uncheckIfSelected"
      >
        <slot :isChecked="isChecked" />
      </div>
    </template>
  </Radio>
</template>

<script>
  import Radio from '@/uikitBase/switchers/Radio';

  const EVENT_MODEL = 'change';

  export default {
    name: 'RadioBtn',

    components: { Radio },

    model: {
      prop: 'checked',
      event: EVENT_MODEL,
    },

    props: {
      checked: {
        type: [String, Number],
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        required: true,
      },
      doUncheckIfSelected: {
        type: Boolean,
        default: false,
      },
      fallbackValue: {
        type: String,
        default: '',
      },
    },

    computed: {
      _checked: {
        get() {
          return this.checked;
        },
        set(value) {
          this.$emit(EVENT_MODEL, value);
        },
      },
    },

    methods: {
      uncheckIfSelected() {
        if (this.doUncheckIfSelected && this.checked === this.value) {
          setTimeout(() => {
            this.$emit(EVENT_MODEL, this.fallbackValue);
          }, 0);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 15px;
    border-radius: $border-radius__md;
    font-size: $size-xxs + 1px;
    cursor: pointer;

    &:hover {
      background-color: $color-bg-transparent--hover;
    }

    &:active {
      background-color: $color-bg-transparent--active;
    }

    &--selected {
      background-color: $color-bg-primary;
      color: white;

      &:hover {
        background-color: $color-text-primary--hover;
      }

      &:active {
        background-color: $color-text-primary--active;
      }
    }
  }
</style>
