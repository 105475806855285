<template>
  <div class="sample-injection">
    <injection-header-component
      :loading="updated !== true"
      :injection="{ substate: null, ...injection }"
      :injectionSocket="injectionSocket"
      :sampleSocket="sampleSocket"
      :sample="sample"
      :method="method"
      :column="column"
      :measurementsSelectedIds="config.measurementsSelectedIds"
    />

    <InjectionErrors :error="injection.error" :method="method" />

    <div class="sample-injection__information">
      <DeviceMethodColumnComponent
        :device="device"
        :vial="injection.vial"
        :method="method"
        :column="column"
        :sample="sample"
        :postprocessings="postprocessings"
        :injectionId="injection.id"
        class="sample-injection__device-method-column"
      />
      <Liquids
        v-if="isInjectionInProgress && deviceFull"
        :device="deviceFull"
        :method="method"
        class="sample-injection__liquids"
      />
    </div>

    <not-available-component v-if="injection.state === 'NEW'" style="margin-top: 32px">
      <div>Did not started</div>
      <Btn class="mt-2" @click="restart">Restart</Btn>
    </not-available-component>
    <template v-else-if="injection.state === 'PEN' || injection.state === 'PRE'">
      <RunSubstate
        v-if="injection.substate != null"
        :substate="injection.substate"
        style="margin: 64px 0 40px 0"
      />
      <nothing-there-component v-else style="margin-top: 32px">
        No recorded data
      </nothing-there-component>
    </template>
    <template v-else>
      <div class="sample-injection__wrapper-panel-control">
        <ChromatogramControl
          v-model="config"
          :measurements="measurementsSelectable"
          :pressure="pressure"
          :isInjectionRunning="injection.state === 'RUN'"
          :hasGradient="hasGradient"
          :hasPostprocessing="hasPostprocessing"
          class="sample-injection__panel-control"
        />
      </div>

      <ChartGradient
        v-if="config.isShowGradient && method.gradient"
        :gradient="method.gradient"
        :injectionMinutes="method.run_time_min"
        class="sample-injection__chart-gradient"
      />

      <NewChartChromatogram
        v-if="measurementsSelectedFiltered.length"
        :measurements="measurementsSelectedFiltered"
        :method="method"
        :injection="injection"
        :isShowPressure="config.isShowPressure"
        :measurementPressure="measurementPressure"
        :showPeaksList="!sample.archived && !injection.archived"
        canSelectPeak
        :editable="canEditChromatogram"
        :doPerformanceOptimisations="isInjectionRunning"
        :hasToolbar="!isInjectionRunning && !sample.archived && !injection.archived"
        :timeMax="method.run_time_min"
        :initialZoom.sync="initialZoom"
        :creationDate="new Date(injection.created)"
        :isApplyPostprocessing="config.isApplyPostprocessing && !isInjectionInProgress"
        :postprocessings="postprocessings"
        :hasZoomActivationOverlay="hasZoomActivationOverlay"
        :hasPostprocessingsToApply="hasPostprocessingsToApply"
        :doRefreshPeaks="isSequenceCalibrationMode && isVisible"
        :doReapplyPostprocessing="!isSequenceCalibrationMode"
        @currentPressure="setCurrentPressure"
        @applyPostprocessing="setIsApplyPostprocessing"
        @refreshPostprocessing="initPostprocessings"
        @zoomActivation="activateZoom"
      />

      <LoadingComponent v-else-if="updated == null" />
      <NothingThereComponent v-else style="margin-top: 32px">
        No measurement
      </NothingThereComponent>
    </template>
  </div>
</template>

<script>
  import SampleInjectionSocket, {
    SampleInjectionSocketEvents,
  } from 'api/sockets/SampleInjectionSocket';
  import NewChartChromatogram from '@/components/blocks/charts/chromatogramNew/NewChartChromatogram.vue';
  import NothingThereComponent from 'components/element/NothingThereComponent.vue';
  import DeviceMethodColumnComponent from 'components/element/DeviceMethodColumnComponent.vue';
  import LoadingComponent from 'components/element/LoadingComponent.vue';
  import NotAvailableComponent from 'components/element/NotAvailableComponent.vue';
  import InjectionHeaderComponent from 'components/block/InjectionHeaderComponent.vue';
  import { isPrevDateOlder } from 'utils/utils.ts';
  import Btn from '@/uikitBase/btns/Btn';
  import InjectionErrors from 'components/element/InjectionErrors';
  import ChromatogramControl from '@/uikitProject/chromatogram/panels/vueChromatogramControl/ChromatogramControl';
  import ChartGradient from '@/uikitProject/charts/vueChartGradient/ChartGradient';
  import RunSubstate from '@/uikitProject/states/packs/RunSubstate';
  import { consoleHelpers } from 'utils/logHelpers';
  import DeviceSocket, { DeviceSocketEvents } from 'api/sockets/DeviceSocket';
  import Liquids from 'components/blocks/injections/liquids/Liquids';
  import { sendDebugEvent } from 'utils/sentryHelpers';
  import faviconService, { FaviconStatuses } from '@/services/FaviconService';
  import { INJECTION_STATES } from '@/constants/injections/states';
  import { apiMeasurements } from '@/api/graphql/cloud/measurements';

  const DEBUG_TASK_MEASUREMENT_CAN_BE_UNDEFINED =
    'https://gitlab.com/hplc-cloud-group/hplc-cloud-server/-/issues/1841';

  export default {
    name: 'SampleInjectionComponent',

    components: {
      NewChartChromatogram,
      Liquids,
      RunSubstate,
      ChartGradient,
      ChromatogramControl,
      InjectionErrors,
      Btn,
      NotAvailableComponent,
      LoadingComponent,
      DeviceMethodColumnComponent,
      NothingThereComponent,
      InjectionHeaderComponent,
    },

    props: {
      injectionData: Object,
      sample: {
        type: Object,
        required: true,
      },
      sampleSocket: {
        type: Object,
        required: true,
      },
      canEditChromatogram: {
        type: Boolean,
        default: true,
      },

      isVisible: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      const { id } = this.injectionData.injection;

      return {
        id,

        injection: this.injectionData.injection,
        device: this.injectionData.device,
        method: this.injectionData.method,
        column: this.injectionData.column,
        postprocessings: null,
        updated: null,
        timerConnectionLost: null,

        measurementsSelected: [],

        config: {
          measurementsSelectedIds: [],
          isShowGradient: false,
          isShowPressure: false,
          isApplyPostprocessing: false,
        },

        isLoadingInjection: false,

        injectionSocket: this.createInjectionSocket(id),
        listenersSampleSocketGroupId: null,

        deviceSocket: null,
        listenersDeviceSocketGroupId: null,
        deviceFull: null,

        wasInProgressRecently: false,

        initialZoom: null,
        hasZoomActivationOverlay: true,

        currentPressure: null,
      };
    },

    computed: {
      configuration() {
        return this.injectionData?.injection?.configuration ?? {};
      },
      measurements() {
        return this.injection.measurements;
      },
      measurementPressure() {
        return Object.values(this.injection.measurements).find(
          (measurement) => measurement?.name === 'Pressure',
        );
      },
      measurementsSelectable() {
        return Object.values(this.injection.measurements).reduce((measurements, measurement) => {
          if (!measurement) {
            sendDebugEvent('#1841: Measurement is undefined', [
              ['All measurements', this.injection.measurements],
              ['Task', DEBUG_TASK_MEASUREMENT_CAN_BE_UNDEFINED],
            ]);
          }

          if (measurement && measurement?.name !== 'Pressure') {
            measurements[measurement.id] = measurement;
          }
          return measurements;
        }, {});
      },

      isPressureOutOfBounds() {
        if (this.currentPressure != null) {
          const { pressure_min, pressure_max } = this.method;

          const isOutOfMin = pressure_min != null && this.currentPressure < pressure_min;
          const isOutOfMax = pressure_max != null && this.currentPressure > pressure_max;

          return this.isInjectionRunning && (isOutOfMin || isOutOfMax);
        }
        return false;
      },
      pressure() {
        if (this.measurementPressure) {
          return {
            current: this.currentPressure?.toFixed(),
            isOutOfBounds: this.isPressureOutOfBounds,
          };
        }
        return null;
      },
      isInjectionRunning() {
        return this.injection.state === INJECTION_STATES.RUNNING;
      },
      isInjectionInProgress() {
        return ['PEN', 'PRE', 'RUN', 'FIN', 'EXE'].includes(this.injection.state);
      },

      hasGradient() {
        return (
          this.configuration?.pump?.type === 'TWO SYRINGE GRADIENT' &&
          Boolean(this.method?.gradient?.length)
        );
      },

      hasPostprocessing() {
        return (
          this.postprocessings?.some((postprocessing) =>
            this.measurementsSelectedFiltered.some(
              (measurement) => measurement.id === postprocessing.measurement.id,
            ),
          ) ?? false
        );
      },

      measurementsSelectedFiltered() {
        return this.measurementsSelected.filter(Boolean);
      },

      hasPostprocessingsToApply() {
        // TODO this.injection.postprocessing_id in the future
        return (
          this.sample?.sequence?.postprocessing_id != null ||
          this.injection?.postprocessing_id != null
        );
      },

      isSequenceCalibrationMode() {
        return this.sample?.sequence?.is_calibration_mode;
      },
    },

    watch: {
      'config.measurementsSelectedIds'(ids) {
        setTimeout(() => {
          this.measurementsSelected = ids.map((id) => this.injection.measurements[id]);
        }, 0);
      },
      injectionData(injectionData) {
        if (injectionData == null) throw Error('injectionData should not be null');

        const idChanged = this.id !== injectionData.injection.id;

        const prevDateOlder = isPrevDateOlder(
          injectionData.injection.date_synced,
          this.injection.date_synced,
        );

        if (idChanged) {
          this.hasZoomActivationOverlay = true;
        }

        if (!idChanged && !prevDateOlder) {
          return;
        }

        this.device = injectionData.device;
        this.method = injectionData.method;
        this.column = injectionData.column;
        this.updated = null;

        this.id = injectionData.injection.id;
        const measurementsOld = this.injection.measurements;
        this.injection = injectionData.injection;

        if (idChanged) {
          this.initMeasurementsSelected(measurementsOld);

          this.injectionSocket = this.createInjectionSocket(this.id);
        }
      },
      isInjectionRunning: {
        handler(value) {
          if (value && this.measurementPressure) {
            this.config.isShowPressure = true;
          }
        },
        immediate: true,
      },
      isInjectionInProgress: {
        handler(value) {
          if (value) {
            faviconService.setStatus(FaviconStatuses.RUNNING);
            this.deviceSocket = this.createDeviceSocket(this.injectionData.device.id);
          } else {
            this.destroyDeviceSocket();
          }
        },
        immediate: true,
      },
      measurementsSelectedFiltered() {
        if (this.config.isApplyPostprocessing) {
          const hasAtLeastOneMeasurementWithPostprocessing = this.postprocessings?.some(
            (postprocessing) =>
              this.measurementsSelectedFiltered.some(
                (measurement) => measurement.id === postprocessing.measurement.id,
              ),
          );

          if (!hasAtLeastOneMeasurementWithPostprocessing) {
            this.config.isApplyPostprocessing = false;
          }
        }
      },
    },

    beforeDestroy() {
      if (this.timerConnectionLost != null) clearTimeout(this.timerConnectionLost);
      this.destroyInjectionSocket();
      this.destroyDeviceSocket();

      faviconService.setStatus(FaviconStatuses.NONE);
    },

    methods: {
      initMeasurementsSelected(_measurementsOld) {
        const measurementsNew = Object.values(this.injection.measurements);
        const measurementsOld = Object.values(_measurementsOld);

        const isSelectedAllMeasurements =
          measurementsOld.length === this.measurementsSelected.length;

        const measurementsSelectedTypes = this.measurementsSelected.map(
          (measurement) => measurement?.type,
        );
        const measurementsSelectedBasedOnPrevChoice = measurementsNew.filter((measurement) =>
          measurementsSelectedTypes.includes(measurement?.type),
        );

        if (isSelectedAllMeasurements && measurementsOld.length > 1) {
          this.config.measurementsSelectedIds = measurementsNew.map(({ id }) => id);
          this.measurementsSelected = measurementsNew;
        } else if (measurementsSelectedBasedOnPrevChoice.length) {
          this.config.measurementsSelectedIds = measurementsSelectedBasedOnPrevChoice.map(
            ({ id }) => id,
          );
          this.measurementsSelected = measurementsSelectedBasedOnPrevChoice;
        } else {
          const defaultMeasurementName = this.method?.default_measurement ?? 'UV';
          // Analog detector's name is null. So I need to use its type field
          const defaultMeasurementType =
            this.method?.default_measurement === 'Analog signal' && 'AD';

          const defaultMeasurement =
            measurementsNew.find((measurement) => {
              return (
                measurement.name === defaultMeasurementName ||
                measurement.type.startsWith(defaultMeasurementType)
              );
            }) ?? this.injection.measurements[this.injection.primary_measurement_id];

          if (defaultMeasurement) {
            this.config.measurementsSelectedIds = [defaultMeasurement.id];
            this.measurementsSelected = [defaultMeasurement];
          }
        }

        this.initPostprocessings();
      },

      createInjectionSocket(id) {
        this.injectionSocket?.close(this.listenersSampleSocketGroupId);

        const injectionSocket = SampleInjectionSocket.start(id, async (connection) => {
          const injection = await connection.get();
          this.onInjection(injection);
        });

        const { id: groupId, addEventListener } = injectionSocket.createEventListenersGroup();
        this.listenersSampleSocketGroupId = groupId;

        addEventListener(SampleInjectionSocketEvents.INJECTION, (i) => this.onInjection(i));
        addEventListener(SampleInjectionSocketEvents.PROGRESS, (i) => {
          if (this.injectionData.injection.id !== i.id) {
            return;
          }

          this.injection.state = i.state;
          this.injection.substate = i.substate;
          this.injection.progress = i.progress;
          this.injection.progress_time_str = i.progress_time_str;
          this.injection.date_synced = i.date_synced;

          // eslint-disable-next-line vue/no-mutating-props
          this.injectionData.injection = this.injection;

          this.onInjectionChanged(i);
        });

        return injectionSocket;
      },
      destroyInjectionSocket() {
        if (this.injectionSocket != null) {
          this.injectionSocket?.close(this.listenersSampleSocketGroupId);
        }
      },

      createDeviceSocket(id) {
        this.deviceSocket?.close(this.listenersDeviceSocketGroupId);

        const onDevice = (deviceData) => {
          this.deviceFull = deviceData;
        };

        const deviceSocket = DeviceSocket.start(id, async (connection) => {
          connection.dontsleep();
          const { device } = await connection.get();
          onDevice(device);
        });

        const { id: groupId, addEventListener } = deviceSocket.createEventListenersGroup();
        this.listenersDeviceSocketGroupId = groupId;

        addEventListener(DeviceSocketEvents.DEVICE, onDevice);
        addEventListener(DeviceSocketEvents.STATE, (s) => {
          if (this.deviceFull) {
            this.deviceFull.state = s;
          }
        });

        return deviceSocket;
      },
      destroyDeviceSocket() {
        if (this.deviceSocket) {
          this.deviceSocket.close(this.listenersDeviceSocketGroupId);
          this.deviceSocket = null;
          this.deviceFull = null;
        }
      },

      onInjectionChanged(i) {
        if (this.timerConnectionLost != null) clearTimeout(this.timerConnectionLost);
        consoleHelpers.warn('STATE', i.state);
        if (
          [
            INJECTION_STATES.PENDING,
            INJECTION_STATES.PREPARING,
            INJECTION_STATES.RUNNING,
            INJECTION_STATES.NO_INTERNET,
          ].includes(i.state)
        ) {
          this.wasInProgressRecently = true;
          this.timerConnectionLost = setTimeout(() => this.connectionLost(), 4500);
        }

        if (i.state === INJECTION_STATES.FAILED || i.state === INJECTION_STATES.READY) {
          setTimeout(() => {
            this.showNotificationIfRpcError(() => this.injectionSocket.seen());
          }, 1000);

          if (this.wasInProgressRecently) {
            faviconService.setStatus(
              i.state === INJECTION_STATES.FAILED
                ? FaviconStatuses.ERROR
                : FaviconStatuses.COMPLETED,
            );
          }
        }
      },

      connectionLost() {
        this.showNotificationIfRpcError(async () => {
          consoleHelpers.warn('No internet!');
          this.injection.state = '@';
          const injection = await this.injectionSocket.get();
          this.onInjection(injection);
        });
      },

      onInjection(injection) {
        if (this.injectionData.injection.id !== injection.id) {
          return;
        }

        const measurementsOld = injection.measurements;

        this.injection = injection;
        // eslint-disable-next-line vue/no-mutating-props
        this.injectionData.injection = this.injection;
        this.initMeasurementsSelected(measurementsOld);

        this.updated = true;

        this.onInjectionChanged(this.injection);
      },

      restart() {
        return this.showNotificationIfRpcError(() => this.injectionSocket.restart());
      },

      setIsApplyPostprocessing(isApplyPostprocessing) {
        console.warn('setIsApplyPostprocessing');
        this.config.isApplyPostprocessing = isApplyPostprocessing;
      },

      setCurrentPressure(currentPressure) {
        this.currentPressure = currentPressure;
      },

      resetZoom() {
        this.initialZoom = null;
      },

      async initPostprocessings() {
        this.postprocessings = await apiMeasurements.getPostprocessings(this.injection.id);
        this.config.isApplyPostprocessing = this.postprocessings?.some((postprocessing) =>
          this.measurementsSelectedFiltered.some(
            (measurement) => measurement.id === postprocessing.measurement.id,
          ),
        );
      },

      activateZoom() {
        this.hasZoomActivationOverlay = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sample-injection {
    &__information {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      margin-bottom: -20px;
      padding: 0 32px;

      @media (max-width: $screen-sm-max) {
        padding: 0 16px;
      }
    }

    &__device-method-column {
      margin-right: 40px;
      margin-bottom: 20px;
    }

    &__liquids {
      min-width: 280px;
      margin-bottom: 20px;
    }

    &__wrapper-panel-control {
      display: flex;
      justify-content: center;
      margin: 48px 0 8px;

      @media (max-width: $screen-xs-max) {
        padding-top: 16px;
        overflow-x: auto;
        justify-content: flex-start;

        &::before,
        &::after {
          content: '';
          display: block;
          width: 16px;
          flex: none;
        }
      }
    }

    &__panel-control {
      margin: auto auto 24px;
    }

    &__chart-gradient {
      padding: 0 32px;
      margin-bottom: 20px;

      @media (max-width: $screen-sm-max) {
        padding: 0 10px;
      }
    }
  }
</style>
