<template>
  <PopupInfoWithProperties
    :data="localData"
    :style="styleVisible"
    @hide="handleHide"
    @show="handleShow"
  >
    <IconMaterial title="info" class="information" />
  </PopupInfoWithProperties>
</template>

<script>
  import PopupInfoWithProperties from '@/uikitProject/popups/info/PopupInfoWithProperties';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import _ from 'lodash';

  export default {
    name: 'Information',

    components: {
      PopupInfoWithProperties,
      IconMaterial,
    },

    props: {
      data: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        isPopupOpened: false,

        localData: this.data,
      };
    },

    computed: {
      styleVisible() {
        if (this.isPopupOpened) {
          return { opacity: '1 !important' };
        }

        return null;
      },
    },

    watch: {
      data(newValue, oldValue) {
        if (this.isPopupOpened && _.isEqual(newValue, oldValue)) {
          return;
        }
        this.localData = newValue;
      },
    },

    created() {
      this.localData = this.data;
    },

    methods: {
      handleShow() {
        this.isPopupOpened = true;
      },
      handleHide() {
        this.isPopupOpened = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .information {
    position: relative;
    top: 1px;
    cursor: pointer;
  }
</style>
