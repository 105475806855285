<template>
  <div class="details">
    <div v-if="isLoadingData || networkDetails == null" class="details__loading">
      <LoaderSpinner />
    </div>
    <template v-else-if="networkDetails">
      <div v-if="selectedNetwork" class="details__selected">
        <Network :network="selectedNetwork" />
      </div>

      <div v-if="ipAddress" class="details__id">
        <span class="details__label-ip">IP:</span> {{ ipAddress }}
      </div>
      <div class="details__mac">
        <span class="details__label-mac">MAC:</span> {{ networkDetails.mac }}
      </div>

      <div class="details__networks">
        <div class="details__header-networks">
          <div class="details__label-networks">Networks</div>
          <LoaderSpinner v-if="isRefreshing || networksWithoutSelected.length === 0" />
        </div>

        <div v-if="networksWithoutSelected.length" class="details__networks-list">
          <Network
            v-for="network of networksWithoutSelected"
            :key="network.id"
            :network="network"
            class="details__network"
            @select="trySelectNetwork"
          />
        </div>
        <span v-else class="details__no-networks">Wi-Fi networks are not found</span>
      </div>

      <div class="details__other-network" @click="selectOtherNetwork">Other...</div>
    </template>
  </div>
</template>

<script>
  import LoaderSpinner from '@/uikitProject/loaders/LoaderSpinner.vue';
  import { DeviceSocketEvents } from '@/api/sockets/DeviceSocket';
  import { wifiAdapterCommands } from '@/constants/hardware/commands';
  import Network from '@/uikitProject/devices/vueDeviceConnection/private/vueWifi/private/private/Network.vue';

  const EVENT_SHOW_DIALOG_TO_CONNECT = 'showDialogToConnect';
  const EVENT_HIDE = 'hide';
  const EVENT_CONNECT = 'connect';

  const REFRESH_INTERVAL_MS = 10000;

  export default {
    name: 'Details',

    components: {
      LoaderSpinner,
      Network,
    },

    props: {
      deviceSocket: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      networkDetails: null,
      networks: [],

      isLoadingData: true,
      networkToConnect: null,
      refreshWifiDataTimerId: null,

      listenersGroupId: null,

      isRefreshing: false,
    }),

    computed: {
      selectedNetwork() {
        return this.networkDetails?.private_ip && this.networks.find((network) => network.in_use);
      },
      networksWithoutSelected() {
        return this.networks.filter((network) => !network.in_use);
      },

      ipAddress() {
        return this.networkDetails?.private_ip?.split('/')[0] ?? null;
      },
    },

    created() {
      this.initWifi();
    },

    beforeDestroy() {
      this.destroyWifi();
    },

    methods: {
      async initWifi() {
        this.isLoadingData = true;

        const listenersGroup = this.deviceSocket.createEventListenersGroup();
        this.listenersGroupId = listenersGroup.id;

        listenersGroup.addEventListener(DeviceSocketEvents.NETWORK_DETAILS, (data) => {
          const { networks, ...networkDetails } = data;
          this.networkDetails = networkDetails;
          this.networks = networks;
          this.isRefreshing = false;
        });

        try {
          await this.initWifiData();

          let refreshAttemptCounter = 0;
          this.refreshWifiDataTimerId = setInterval(() => {
            if (this.networksWithoutSelected.length === 0 || refreshAttemptCounter % 3 === 2) {
              this.initWifiData();
            }
            refreshAttemptCounter++;
          }, REFRESH_INTERVAL_MS);
        } finally {
          this.isLoadingData = false;
        }
      },

      initWifiData() {
        return this.showNotificationIfRpcError(() => {
          this.isRefreshing = true;
          this.deviceSocket.command('wifi-adapter', wifiAdapterCommands.GET_DETAILS);
        });
      },

      cancelRefreshRequest() {
        clearInterval(this.refreshWifiDataTimerId);
      },
      removeEventListeners() {
        this.deviceSocket.removeEventListenersByGroupId(this.listenersGroupId);
      },
      destroyWifi() {
        this.cancelRefreshRequest();
        this.removeEventListeners();
      },

      trySelectNetwork(network) {
        this.networkToConnect = network;

        if (network.security == null || network.security === '' || network.security === '--') {
          this.selectNetworkWithoutPassword(network.id);
          this.$emit(EVENT_CONNECT);
        } else {
          this.$emit(EVENT_SHOW_DIALOG_TO_CONNECT, network);
        }
        this.$emit(EVENT_HIDE);
      },

      selectNetworkWithoutPassword(ssid) {
        this.showNotificationIfRpcError(() =>
          this.deviceSocket.command('wifi-adapter', wifiAdapterCommands.CONNECT, {
            ssid,
            password: '',
          }),
        );
      },

      selectOtherNetwork() {
        this.networkToConnect = null;
        this.$emit(EVENT_HIDE);
        this.$emit(EVENT_SHOW_DIALOG_TO_CONNECT);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .details {
    text-align: left;
    padding: 10px;
    width: 240px;

    &__loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__selected {
      margin-bottom: 5px;
    }

    &__label-ip,
    &__label-mac {
      color: $color-text-third;
    }

    &__mac {
      margin-bottom: 16px;
    }

    &__networks {
      margin-bottom: 6px;
    }

    &__header-networks {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    &__networks-list {
      max-height: 244px;
      overflow: auto;
    }

    &__label-networks {
      font-weight: $weight-bold;
    }

    &__no-networks {
      color: $color-text-third;
    }

    &__other-network {
      display: flex;
      align-items: center;
      color: #000;
      height: 40px;
      border-radius: $border-radius__md;
      padding: 0 8px;
      cursor: pointer;

      &:hover {
        background-color: $color-bg-second;
      }

      &:active {
        background-color: $color-bg-third;
      }
    }
  }
</style>
