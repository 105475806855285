var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('InputAutosuggest',{ref:"inputAutosuggest",staticClass:"compound-autosuggest",attrs:{"id":_vm.id,"value":_vm.value,"items":_vm.items,"labelProp":"name","isLoadingSuggestions":_vm.isLoadingItems,"isShowAllSuggestionsIfEmpty":_vm.isShowAllSuggestionsIfEmpty,"isShowAllSuggestionsOnFocus":_vm.isShowAllSuggestionsOnFocus,"isDisabled":_vm.isDisabled,"titleBottomSheet":"Compound","placeholder":_vm.placeholder},on:{"model":_vm.change,"init":_vm.getListItems,"mounted":function($event){return _vm.$emit('mounted')},"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"mobileSave":function($event){return _vm.$emit('mobileSave')},"search":_vm.getListItemsDebounced},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var value = ref.value;
var setValue = ref.setValue;
var handleInputFocus = ref.handleInputFocus;
var handleInputBlur = ref.handleInputBlur;
var isDisabled = ref.isDisabled;
var id = ref.id;
var placeholder = ref.placeholder;
return [_vm._t("default",[_c('div',{staticClass:"compound-autosuggest__wrapper-input"},[_c('input',{staticClass:"compound-autosuggest__input",attrs:{"id":id,"type":"text","disabled":isDisabled,"placeholder":placeholder,"autocomplete":"off"},domProps:{"value":value},on:{"input":function($event){return setValue($event.target.value)},"focus":handleInputFocus,"blur":handleInputBlur}})])],{"id":id,"value":value,"isDisabled":isDisabled,"placeholder":placeholder,"setValue":setValue,"handleInputFocus":handleInputFocus,"handleInputBlur":handleInputBlur})]}},{key:"mobileTrigger",fn:function(slotMobileTriggerData){return [_vm._t("mobileTrigger",null,{"isMobile":true},slotMobileTriggerData)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }