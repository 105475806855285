<template>
  <div class="device-terminal-page">
    <div v-if="device != null" class="top-line--flex">
      <div class="device-terminal-page__content">
        <div class="device-terminal-page__wrapper-title">
          <i class="main" style="margin-top: 23px">input</i>
          <h1 v-if="device" class="model-text-wrapper">
            {{ device.name }}
          </h1>

          <div class="pt-0 pb-2">
            <template v-if="device.name !== ' '">
              <DeviceType :model="device.model" class="mr-2" />
              <DeviceConnection :device="device" class="mr-2" />
              <StatusOnline
                :isOnline="device.is_online"
                :wasOnline="device.date_last_online"
                class="mr-2"
              />
            </template>
          </div>
        </div>

        <div v-if="device.is_online" class="device-terminal-page__wrapper-section">
          <label class="block" style="margin-bottom: 3px">Actions</label>
          <Btn height="s" type="primary" icon-left="send" @click="pushLogs">Push Logs</Btn>
        </div>

        <template v-if="device.is_online">
          <div class="device-terminal-page__wrapper-section">
            <label class="block" style="margin-bottom: 3px">Serial Device</label>
            <select v-model="serialDeviceId" required name="position_id">
              <option
                v-for="serialDevice in serialDevices"
                :key="serialDevice.serial"
                :value="serialDevice.id"
              >
                {{ serialDevice.name }} - {{ serialDevice.serial }} - {{ serialDevice.id }}
              </option>
            </select>
          </div>

          <DompBrowser
            :key="serialDeviceId"
            :serialDeviceId="serialDeviceId"
            :deviceSocket="socket"
          />
        </template>
      </div>
    </div>
    <ErrorComponent v-else-if="errorCode != null" :code="errorCode" style="margin-top: 64px" />
  </div>
</template>

<script>
  import 'assets/css/base/icons.scss';

  import DeviceSocket, { DeviceSocketEvents } from 'api/sockets/DeviceSocket';
  import StatusOnline from '@/uikitProject/statuses/StatusOnline.vue';
  import Btn from '@/uikitBase/btns/Btn';
  import PageTitle from '@/mixins/page-title';
  import NavigatorHelper from 'utils/NavigatorHelper.ts';
  import DeviceType from '@/uikitProject/devices/DeviceType';
  import DeviceConnection from '@/uikitProject/devices/vueDeviceConnection/DeviceConnection.vue';
  import DompBrowser from 'components/blocks/devices/vueDompBrowser/DompBrowser';
  import ErrorComponent from '@/components/element/ErrorComponent.vue';

  const preventWhiteRectForMobileSafari = () => {
    if (NavigatorHelper.isMobile && NavigatorHelper.browserName === 'safari') {
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
      }, 400);
    }
  };

  export default {
    name: 'DeviceTerminalPage',

    components: {
      ErrorComponent,
      Btn,
      DompBrowser,
      DeviceConnection,
      DeviceType,
      StatusOnline,
    },

    mixins: [PageTitle],

    props: {
      id: String,
    },

    data() {
      return {
        pageTitle: 'HPLC Cloud: Device',

        device: null,

        socket: this.createDeviceSocket(this.id),

        connectionLost: false,
        timerConnectionLost: null,

        errorCode: null,

        showVersioning: false,

        serialDeviceId: '',
        serial: '',
        command: '',
      };
    },

    computed: {
      state() {
        return this.device?.state;
      },
      configuration() {
        return this.device?.configuration;
      },
      serialDevices() {
        return this.device?.configuration?.alltesta?.serial_devices;
      },
    },

    watch: {
      id(id) {
        this.destroyDeviceSocket();
        this.socket = this.createDeviceSocket(id);
      },
      device(d) {
        if (d?.is_online !== false) {
          this.onConnectionConfirmed();
        }
        this.pageTitle = d?.name || 'HPLC Cloud: Device';
      },
      connectionLost(connectionLost) {
        if (!connectionLost) {
          this.socket.dontsleep();
        }
      },
      state(s) {
        if (s.ready == null) return;
        if (s.sleep_countdown && s.sleep_countdown <= 1) {
          this.socket.dontsleep();
        }
        this.onConnectionConfirmed();
      },
    },

    mounted() {
      preventWhiteRectForMobileSafari();
    },

    beforeDestroy() {
      if (this.timerConnectionLost != null) clearTimeout(this.timerConnectionLost);
      this.destroyDeviceSocket();
    },

    methods: {
      pushLogs() {
        this.showNotificationIfRpcError(() => this.socket.pushLogs());
      },
      onConnectionConfirmed() {
        this.connectionLost = false;
        this.updateConnectionLostTimeout();
      },
      updateConnectionLostTimeout() {
        if (this.timerConnectionLost != null) clearTimeout(this.timerConnectionLost);
        this.timerConnectionLost = setTimeout(this.onConnectionLost, 4500);
      },
      onConnectionLost() {
        this.connectionLost = true;
        this.socket.dontsleep();
        this.updateConnectionLostTimeout();
      },

      createDeviceSocket(id) {
        const socket = DeviceSocket.start(
          id,
          async (connection) => {
            connection.dontsleep();
            const { device } = await connection.get();
            if (this.device?.state) device.state = { ...this.device?.state, ...device.state };
            this.device = device;
          },
          (e) => {
            this.errorCode = e.code;
          },
        );

        socket.addEventListener(DeviceSocketEvents.DEVICE, (deviceData) => {
          if (this.device?.state) deviceData.state = { ...this.device?.state, ...deviceData.state };
          this.device = deviceData;
        });
        socket.addEventListener(DeviceSocketEvents.STATE, (s) => {
          this.device.state = s;
        });
        socket.addEventListener(DeviceSocketEvents.RECENT_RUNS, (runs) => {
          this.runs = runs;
        });
        socket.addEventListener(DeviceSocketEvents.ERROR, (error) => this.notifyError(error));

        return socket;
      },
      destroyDeviceSocket() {
        if (this.socket != null) this.socket.close();
        this.socket = null;
      },
    },
  };
</script>

<style scoped lang="scss">
  .device-terminal-page {
    &__content {
      overflow: hidden;
    }

    &__wrapper-title {
      align-items: baseline;
    }

    &__wrapper-section {
      margin-top: 20px;
    }
  }
</style>
