import _ from 'lodash';
import { Nullable } from '@/types/utility';

const CLASS_PULSE_COLOR = 'chromatogram-pulse-color';
const CLASS_MESSAGE_OVERLAY = 'chromatogram-message-overlay';

const addStyleSheetForMessageOverlay = _.once(() => {
  const css = window.document.styleSheets[0];
  css.insertRule(
    `
    .${CLASS_MESSAGE_OVERLAY} {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: white;
      font-size: 18px;
    }
    `,
    css.cssRules.length,
  );
  css.insertRule(
    `
    @keyframes ${CLASS_PULSE_COLOR} {
      0%, 100% {
          color: black;
      }
      50% {
          color: grey;
      }
    }
`,
    css.cssRules.length,
  );
  css.insertRule(
    `
    .${CLASS_PULSE_COLOR} {
      animation: ${CLASS_PULSE_COLOR} 2s infinite;
    }
`,
    css.cssRules.length,
  );
});

export const createMessageOverlay = () => {
  addStyleSheetForMessageOverlay();

  const messageOverlay = document.createElement('div');
  messageOverlay.classList.add(CLASS_MESSAGE_OVERLAY, CLASS_PULSE_COLOR);
  messageOverlay.innerText = '';

  return {
    element: messageOverlay,
    edit(message: Nullable<string>, isAnimated: boolean = false) {
      messageOverlay.hidden = message == null;

      if (message != null) {
        messageOverlay.innerText = message;
        isAnimated
          ? messageOverlay.classList.add(CLASS_PULSE_COLOR)
          : messageOverlay.classList.remove(CLASS_PULSE_COLOR);
      }
    },
  };
};
