<template>
  <div ref="wrapper" class="chromatogram-single chromatogram">
    <div>
      <GlobalEvents @keydown.enter="chartEnter" @click="handleClickOutsideChart" />

      <div style="position: relative; width: 100%" @click.stop>
        <loading-component
          v-show="updated !== true"
          :label="null"
          style="position: absolute; width: 16px; margin: 0; z-index: 100; right: 32px; top: 24px"
        />
        <div ref="divChart" class="chromatogram-chart" />

        <Btn
          v-if="hasData && toolbar && !isShowEditPanel"
          padding="xl"
          class="chromatogram__btn-show-edit-panel"
          @click="showEditPanel"
        >
          <IconMaterial title="build" class="chromatogram__icon-build" />
        </Btn>
        <Prompt
          v-if="isShowPrompt"
          :message="helpMessage"
          class="chromatogram-single__prompt"
          @hide="hidePrompt"
        />
      </div>

      <div v-if="toolbar && isShowEditPanel" class="chromatogram-single__wrapper-edit-panel">
        <ChromatogramEdit
          :id="id"
          v-model="mode"
          :hasBaseline="Boolean(baseline)"
          :isFullChart="!isApplyDetectionTime"
          :hasDetectionTime="hasDetectionTime"
          :hasPeaksWithEstimatedGaussian="hasPeaksWithEstimatedGaussian"
          :isEditable="editable"
          :canCompare="!this.sampleToken"
          class="chromatogram-single__edit-panel"
          :hasPostprocessing="postprocessing != null"
          :measurements="[internalMeasurement]"
          :sampleId="injection.sample_id"
          :hasData="hasData"
          :isShared="isShared"
          @reapplyPostprocessing="refreshMeasurement"
          @showFullChart="$emit('showFullChart')"
          @showDetectionTime="$emit('showDetectionTime')"
          @updateAppearance="updateAppearance"
          @applyPostprocessingTemplate="applyPostprocessingTemplate"
          @resetPostprocessing="resetPostprocessing"
          @close="hideEditPanel"
        />
      </div>

      <div
        v-if="modeBaselineManual"
        style="margin: 0 32px 24px; display: flex; align-items: center; flex-wrap: wrap"
      >
        <span style="margin-right: 5px">Draw baseline, then click finish to save it</span>
        <PopupHelper text="Restarts setpoint selection.">
          <button
            v-show="!baseline"
            class="chromatogram-single__btn-edit-baseline toolbar__button"
            @click.stop="redrawBaseline"
          >
            <i class="material-icons material-icon--14">clear</i>
            Start over
          </button>
        </PopupHelper>
        <PopupHelper text="Removes the most recent baseline setpoint.">
          <button
            v-show="!baseline"
            class="chromatogram-single__btn-edit-baseline toolbar__button ml-1"
            @click.stop="removeLastBaselineSegment"
          >
            <i class="material-icons material-icon--14">undo</i>
            Undo
          </button>
        </PopupHelper>
        <PopupHelper text="Establishes a baseline correction based on the selected setpoints.">
          <button
            v-show="!baseline"
            class="chromatogram-single__btn-edit-baseline chromatogram-single__btn-edit-baseline--active toolbar__button toolbar__button--active ml-1"
            @click.stop="chartEnter"
          >
            <i class="material-icons material-icon--14">check</i>
            Finish
          </button>
        </PopupHelper>
      </div>

      <template v-if="hasData">
        <PrTableSamplePeaks
          v-if="showPeaksList"
          :editable="editable"
          :ppeaks="peaks"
          :measurement-socket="measurementSocket"
          :highlightedPeakId="highlightedPeakId"
          :focusedCompoundInputId.sync="focusedCompoundInputId"
          @selection="setPeakHighlighting"
        />
        <slot
          name="peaks"
          :peaks="peaks"
          :measurement-socket="measurementSocket"
          :setPeakHighlighting="setPeakHighlighting"
          :highlightedPeakId="highlightedPeakId"
          style="margin-top: 16px"
        />
      </template>
    </div>

    <!--Absolute positioned-->
    <PostprocessingTemplateEditModal
      :postprocessingTemplate="undefined"
      :selectedMeasurement="measurement"
      :method="method"
      :isApplyMode="true"
      @apply="refreshPostprocessing"
    />
  </div>
</template>

<script>
  import resize from 'vue-resize-directive';
  import MeasurementSocket, { MeasurementSocketEvents } from 'api/sockets/MeasurementSocket';

  import LoadingComponent from 'components/element/LoadingComponent';
  import PrTableSamplePeaks from 'components/blocks/charts/chromatogramNew/private/PrTableSamplePeaks.vue';

  import {
    // CHART_COLORS,
    UNKNOWN_VALUE,
  } from 'utils/chart/chart-constants.ts';

  import ChromatogramEdit from '@/uikitProject/chromatogram/panels/vueChromatogramEdit/ChromatogramEdit';
  import Btn from '@/uikitBase/btns/Btn';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import Prompt from '@/uikitProject/chromatogram/help/Prompt';
  import SharedMeasurementSocket, {
    SharedMeasurementSocketEvents,
  } from 'api/sockets/SharedMeasurementSocket';
  import { consoleHelpers } from 'utils/logHelpers';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper.vue';
  import { ChromatogramDrawer, Modes } from '@/uikitProject/charts/new/ChromatogramDrawer';
  import { isInjectionInProgress } from '@/utils/injectionHelpers';
  import { PEAK_ESTIMATED_SHAPES } from '@/constants/peaks/estimatedShapes';
  import { persistentStorage, PersistentStorageKeys } from '@/utils/persistentStorage';
  import PostprocessingTemplateEditModal from '@/components/block/modal/PostprocessingTemplateEditModal.vue';
  import { apiMeasurements } from '@/api/graphql/cloud/measurements';
  import { sendDebugEvent } from '@/utils/sentryHelpers';

  const EVENT_ZOOM = 'zoom';
  const EVENT_UPDATE = 'update';
  const EVENT_CREATED = 'created';
  const EVENT_MOUNTED = 'mounted';
  const EVENT_UPDATE_APPLY_BASELINE = 'update:applyBaseline';
  const EVENT_DETECTION_TIME = 'detectionTime';
  const EVENT_DESTROY = 'destroy';
  const EVENT_REFRESH_POSTPROCESSING = 'refreshPostprocessing';
  const EVENT_ZOOM_ACTIVATION = 'zoomActivation';
  const EVENT_POSTPROCESSING_DONE = 'postprocessingDone';

  const MODE_ZOOM = 'zoom';
  const MODE_PEAK_ADD = 'peakAdd';
  const MODE_PEAK_ADD_MAGNET = 'peakAddMagnet';
  const MODE_PEAK_DELETE = 'peakDelete';
  const MODE_BASELINE_EDIT = 'baselineEdit';
  const MODE_DETECTION_ZONE = 'selectDetectionZone';

  const MESSAGE_APPLYING_PP = 'Applying postprocessing';

  export default {
    name: 'PrChartChromatogramSingle',

    components: {
      PostprocessingTemplateEditModal,
      PopupHelper,
      Prompt,
      IconMaterial,
      Btn,
      ChromatogramEdit,
      LoadingComponent,
      PrTableSamplePeaks,
    },

    directives: { resize },

    props: {
      measurement: Object,
      canSelectPeak: { type: Boolean, default: false },
      editable: { type: Boolean, default: true },
      sampleToken: { type: String, default: null },
      // colorScheme: { type: Object, default: () => CHART_COLORS },
      toolbar: { type: Boolean, default: true },
      showPeaksList: { type: Boolean, default: false },
      // Chart shouldn't be smaller than bounds
      bounds: { type: Object, default: null },
      // heightPx: { type: Number, default: 300 },
      // hasPadding: { type: Boolean, default: true },
      hasLiveUpdate: { type: Boolean, default: true },
      timeMin: {
        type: Number,
        default: 0,
      },
      timeMax: {
        type: Number,
      },
      hasOnlyHorizontalZoom: {
        type: Boolean,
      },
      // doShowExactBounds: {
      //   type: Boolean,
      // },

      // hasSpacesIfOutOfDetectionTime: {
      //   type: Boolean,
      // },
      isApplyDetectionTime: {
        type: Boolean,
      },

      isApplyPostprocessing: {
        type: Boolean,
      },

      creationDate: {
        type: Date,
      },

      injection: {
        type: Object,
      },
      method: {
        type: Object,
      },
      width: {
        type: Number,
      },

      initialZoom: {
        type: Object,
      },

      postprocessing: {
        type: Object,
      },

      hasZoomActivationOverlay: {
        type: Boolean,
        required: true,
      },

      hasPostprocessingsToApply: {
        type: Boolean,
        required: true,
      },

      doRefreshPeaks: {
        type: Boolean,
        required: false,
      },
      doReapplyPostprocessing: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        mode: MODE_ZOOM, // 0 - zoom, 1 - add peak, 2 - draw baseline, 3 - delete peak
        internalMeasurement: this.measurement?.data ? this.measurement : null,
        measurementSocket: this.createSocket(this.measurement?.id),
        updated: this.hasLiveUpdate ? null : true,

        highlightedPeakId: null,
        focusedCompoundInputId: null,

        isShowEditPanel: true,
        isShowPrompt: false,

        listenersGroupId: null,

        drawer: null,

        baselinePoints: null,

        wasShownPeakWarningMessage: false,

        onBaselineUpdateCallback: null,

        isFetchingData: true,

        isInjectionInProgress: false,

        refreshPeaksTimerId: null,

        postprocessingRefreshIntervalId: null,

        messageOverlay: null,
      };
    },

    computed: {
      empty() {
        return this.internalMeasurement?.data_length <= 0;
      },
      peaks() {
        return this.internalMeasurement?.peaks;
      },
      hasAutoPeaks() {
        return this.internalMeasurement?.peaks?.some((peak) => !peak.is_manual);
      },
      minutesMax() {
        return this.privateTimeMax;
      },
      modeZoom: {
        get() {
          return this.mode === MODE_ZOOM;
        },
        set(b) {
          if (b) this.mode = MODE_ZOOM;
        },
      },
      modePeakAdd: {
        get() {
          return this.mode === MODE_PEAK_ADD || this.mode === MODE_PEAK_ADD_MAGNET;
        },
        set(b) {
          this.mode = b ? MODE_PEAK_ADD : MODE_ZOOM;
        },
      },
      modePeakAddMagnet: {
        get() {
          return this.mode === MODE_PEAK_ADD_MAGNET;
        },
        set(b) {
          this.mode = b ? MODE_PEAK_ADD_MAGNET : MODE_ZOOM;
        },
      },
      modeBaselineManual: {
        get() {
          return this.mode === MODE_BASELINE_EDIT;
        },
        set(b) {
          this.mode = b ? MODE_PEAK_ADD_MAGNET : MODE_ZOOM;
        },
      },
      modePeakDelete: {
        get() {
          return this.mode === MODE_PEAK_DELETE;
        },
        set(b) {
          this.mode = b ? MODE_PEAK_DELETE : MODE_ZOOM;
        },
      },
      modeDetectionZone: {
        get() {
          return this.mode === MODE_DETECTION_ZONE;
        },
        set(b) {
          this.mode = b ? MODE_DETECTION_ZONE : MODE_ZOOM;
        },
      },
      id() {
        return this.internalMeasurement?.id;
      },
      baseline() {
        const b = this.internalMeasurement?.baseline;
        return b && b.length ? b : null;
      },
      mps() {
        return this.internalMeasurement?.mps;
      },
      data() {
        return this.internalMeasurement?.data;
      },
      helpMessage() {
        switch (this.mode) {
          case MODE_PEAK_DELETE:
            return 'Click and hold to remove all peaks inside';
          case MODE_PEAK_ADD:
          case MODE_PEAK_ADD_MAGNET:
            return 'Click and hold to draw a new peak';
          case MODE_BASELINE_EDIT:
            return 'Click at least once to add a baseline';
          case MODE_DETECTION_ZONE:
            return `Click and hold to define a detection zone`;
          default:
            return null;
        }
      },

      detectionTime() {
        const dtimPostprocessing = this.internalMeasurement?.postprocessings?.find(
          (postprocessing) => postprocessing.name === 'DTIM',
        );

        return dtimPostprocessing
          ? {
              start: dtimPostprocessing.params?.detection_time_start_sec
                ? dtimPostprocessing.params.detection_time_start_sec / 60
                : null,
              end: dtimPostprocessing.params?.detection_time_end_sec
                ? Math.min(
                    dtimPostprocessing.params.detection_time_end_sec / 60,
                    this.method?.run_time_min ?? this.injection.expected_duration_sec / 60,
                  )
                : null,
            }
          : null;
      },

      hasDetectionTime() {
        return Boolean(
          this.detectionTime &&
            (this.detectionTime.start ||
              (this.detectionTime.end &&
                this.detectionTime.end !==
                  (this.method?.run_time_min ?? this.injection.expected_duration_sec / 60))),
        );
      },
      privateTimeMin() {
        if (!this.isApplyDetectionTime) {
          return this.timeMin;
        }

        return this.detectionTime?.start ?? this.timeMin;
      },
      privateTimeMax() {
        if (!this.isApplyDetectionTime) {
          return this.timeMax;
        }

        if (this.detectionTime?.end) {
          return this.timeMax
            ? Math.min(this.detectionTime?.end, this.timeMax)
            : this.detectionTime?.end;
        }
        return this.timeMax;
      },

      hasData() {
        return this.data?.length > 1;
      },

      hasPeaksWithEstimatedGaussian() {
        return this.peaks?.some(
          (peak) =>
            peak.estimated_shape === PEAK_ESTIMATED_SHAPES.SKEW_GAUSS &&
            peak.estimated_shape_params != null,
        );
      },

      wasPostprocessed() {
        return this.internalMeasurement.postprocessings?.length > 0;
      },

      isShowBaseline() {
        return !this.isApplyPostprocessing && this.wasPostprocessed;
      },
      isShowPeaks() {
        return (this.wasPostprocessed && this.isApplyPostprocessing) || !this.wasPostprocessed;
      },

      isShared() {
        return this.sampleToken != null;
      },
    },

    watch: {
      updated(value) {
        consoleHelpers.warn('UPDATED', value);
      },
      data: 'refresh',
      bounds: 'refresh',
      width(value) {
        this.drawer?.actions.setWidth(value);
      },
      isApplyDetectionTime(value) {
        this.drawer?.actions.setIsApplyDetectionTime(value);
      },
      privateTimeMin() {
        this.refresh();
      },
      privateTimeMax() {
        this.refresh();
      },
      measurement(m, oldM) {
        if (m === this.internalMeasurement) return;

        const idChanged = oldM?.id !== m?.id;

        if (!idChanged && m.data == null) return;
        this.internalMeasurement = m;

        // this.chart.baselinePoints = null;

        // if (this.internalMeasurement?.baseline) {
        //   this.$emit(EVENT_UPDATE_APPLY_BASELINE, true);
        // }

        if (idChanged) {
          this.updated = false;
          this.destroySocket();

          if (m?.id) {
            this.measurementSocket = this.createSocket(m.id);
          }
        }
      },
      mode() {
        this.isShowPrompt = Boolean(this.helpMessage);

        switch (this.mode) {
          case MODE_ZOOM: {
            this.drawer.setMode(Modes.DEFAULT);
            break;
          }
          case MODE_BASELINE_EDIT: {
            this.drawer.setMode(Modes.BASELINE);
            break;
          }
          case MODE_PEAK_ADD: {
            this.onEditPeaks();
            this.drawer.setMode(Modes.ADD_PEAK);
            break;
          }
          case MODE_PEAK_DELETE: {
            this.onEditPeaks();
            this.drawer.setMode(Modes.REMOVE_PEAK);
            break;
          }
        }
      },
      async isApplyPostprocessing() {
        if (!this.internalMeasurement) {
          return;
        }

        if (typeof this.internalMeasurement.getData !== 'function') {
          sendDebugEvent('#2413: Front: Fix sentry issues', [
            ['injectionId', this.internalMeasurement?.id],
            ...Object.entries(this.internalMeasurement),
            [
              'Sentry issue',
              'https://newcrom.sentry.io/issues/5644525960/?project=6192768&query=is:unresolved&statsPeriod=30d&stream_index=21&utc=true',
            ],
          ]);
        }

        if (
          this.isApplyPostprocessing &&
          !this.internalMeasurement.wasPostprocessingComputed &&
          this.internalMeasurement?.processing_state !== 'done' &&
          this.internalMeasurement?.processing_state !== 'error'
        ) {
          this.messageOverlay = MESSAGE_APPLYING_PP;
          return;
        }

        this.internalMeasurement = {
          ...this.internalMeasurement,
          data: (await this.internalMeasurement.getData?.(this.isApplyPostprocessing)) ?? [],
        };

        this.drawer?.actions.setIsShowBaseline(this.isShowBaseline);
        this.drawer?.actions.setIsShowPeaks(this.isShowPeaks);
        this.refresh();
      },
      isFetchingData(value) {
        this.drawer?.actions.setIsFetchingData(value);
      },
      messageOverlay(value) {
        console.warn('set messageOverlay', value);
        this.drawer?.actions.setOverlayMessage(value);
      },
      'injection.state': {
        handler() {
          const oldIsInjectionInProgress = this.isInjectionInProgress;
          this.isInjectionInProgress = isInjectionInProgress(this.injection);

          this.drawer?.actions.setIsInProgress(this.isInjectionInProgress);

          if (
            !this.isInjectionInProgress &&
            oldIsInjectionInProgress !== this.isInjectionInProgress
          ) {
            console.warn(
              'from injection.state',
              this.isInjectionInProgress,
              oldIsInjectionInProgress,
              this.isInjectionInProgress,
            );
            this.initRefreshingAfterFinish();
          }
        },
        immediate: true,
      },
    },

    created() {
      this.$emit(EVENT_CREATED);

      if (!this.doReapplyPostprocessing && !this.isInjectionInProgress) {
        console.warn('from created');

        this.initRefreshingAfterFinish();
      }
    },

    mounted() {
      this.initChart();
      this.$emit(EVENT_MOUNTED);
    },

    beforeDestroy() {
      this.destroySocket();
      clearInterval(this.refreshPeaksTimerId);
      this.$emit(EVENT_DESTROY);
    },

    methods: {
      initChart() {
        if (!this.internalMeasurement?.data) {
          return;
        }

        if (this.$refs.wrapper == null) {
          sendDebugEvent('#2413: Front: Fix sentry issues', [
            ['measurementId', this.internalMeasurement?.id],
            [
              'Sentry issue',
              'https://newcrom.sentry.io/issues/5618065224/?project=6192768&query=is:unresolved+issue.priority:%5Bhigh,+medium%5D&statsPeriod=30d&stream_index=3&utc=true',
            ],
          ]);

          return;
        }

        const { hasGridLines, hasTooltipOnHover } = this.getAppearance();

        this.drawer = new ChromatogramDrawer({
          container: this.$refs.divChart,
          measurements: [this.internalMeasurement],
          expectedDurationSeconds:
            this.method?.run_time_min * 60 ?? this.injection.expected_duration_sec,
          detectionTime: this.detectionTime
            ? {
                start: this.detectionTime.start,
                end: this.detectionTime.end,
              }
            : null,
          isInProgress: isInjectionInProgress(this.injection),
          isShowBaseline: this.isShowBaseline,
          isShowPeaks: this.isShowPeaks,
          isFetchingData: this.isFetchingData,
          widthPx: this.$refs.wrapper.getBoundingClientRect().width,
          isApplyDetectionTime: this.isApplyDetectionTime,
          hasGridLines,
          hasTooltipOnHover,
          zoomBounds: this.initialZoom,
          hasZoomActivationOverlay: this.hasZoomActivationOverlay,
          creationData: this.creationDate,
        });

        this.drawer.on('addPeak', ({ startMau, endMau, startMinutes, endMinutes }) => {
          const start = { mau: startMau, time: startMinutes };
          const end = { mau: endMau, time: endMinutes };
          this.peakAdd(start, end);
          this.modeZoom = true;
        });

        // this.drawer.on('baseline', (baseline) => {
        //   this.baselinePoints = baseline.map(([time, mau]) => ({ time, mau }));
        // });

        this.drawer.on('deletePeaks', (peaksForDelete) => {
          this.removePeaks(peaksForDelete);
          this.modeZoom = true;
        });

        this.drawer.on('zoom', (zoomBounds) => {
          this.$emit(EVENT_ZOOM, zoomBounds);
        });

        this.drawer.on('hasInvisiblePeak', (hasInvisiblePeak) => {
          if (hasInvisiblePeak && !this.wasShownPeakWarningMessage) {
            this.notify('One or more peaks are out of detection time', 10);
            this.wasShownPeakWarningMessage = true;
          }
        });

        this.drawer.on('highlightPeak', (peakId) => {
          this.highlightedPeakId = peakId;
        });

        this.drawer.on('zoomActivation', () => {
          this.$emit(EVENT_ZOOM_ACTIVATION);
        });
      },

      refresh() {
        if (this.internalMeasurement?.data == null) {
          return;
        }

        if (this.drawer) {
          this.drawer.actions.setIsInProgress(isInjectionInProgress(this.injection));
          this.drawer.actions.setDetectionTime(
            this.detectionTime
              ? {
                  start: this.detectionTime.start,
                  end: this.detectionTime.end,
                }
              : null,
          );
          this.drawer.actions.setExpectedDurationSeconds(
            this.method?.run_time_min * 60 ?? this.injection.expected_duration_sec,
          );
          this.drawer.refresh([this.internalMeasurement]);
        } else {
          this.initChart();
        }
      },

      showEditPanel() {
        this.isShowEditPanel = true;
      },
      hideEditPanel() {
        this.isShowEditPanel = false;
      },
      hidePrompt() {
        this.isShowPrompt = false;
      },

      setPeakHighlighting({ peakId, isSelected }) {
        this.drawer.actions.setPeakHighlighting(peakId, isSelected);
      },

      confirmBaseline() {
        if (this.baselinePoints?.length) {
          this.baselineSet(this.baselinePoints);
          this.$emit(EVENT_UPDATE_APPLY_BASELINE, true);
          this.drawer.actions.resetBaseline();
        } else {
          this.notifyError('You must add at least one baseline point!');
        }

        this.mode = MODE_ZOOM;
      },

      setZoom(zoomBounds) {
        this.chart.actions.setZoom(zoomBounds);
      },

      onPeaks(data) {
        this.internalMeasurement = {
          ...this.internalMeasurement,
          ...data,
        };
        this.refresh();
      },

      async onMeasurement(m) {
        console.warn(
          'from onMeasurement',
          m.postprocessings.length > 0,
          !this.isInjectionInProgress,
          m.id === this.id,
          m.data_length === 0,
        );

        if (
          m.id === this.id &&
          m.data_length === 0 &&
          m.processing_state === this.internalMeasurement?.processing_state
        ) {
          return;
        }

        console.warn('from onMeasurement 2');
        const data = await m.getData(
          this.isApplyPostprocessing && m.postprocessings.length > 0 && !this.isInjectionInProgress,
        );
        this.internalMeasurement = {
          ...this.internalMeasurement,
          ...m,
          // HOTFIX: m.data can be empty after start
          // TODO baseline?
          data: this.internalMeasurement?.data
            ? data?.length >= this.internalMeasurement.data?.length
              ? data
              : this.internalMeasurement.data
            : data,
        };

        consoleHelpers.warn('UPDATED TRUE', m);
        this.updated = true;

        this.$emit(EVENT_DETECTION_TIME, this.detectionTime);

        this.drawer?.actions.setIsShowBaseline(this.isShowBaseline);
        this.drawer?.actions.setIsShowPeaks(this.isShowPeaks);
        this.refresh();
      },

      createSocket(id) {
        if (!id) {
          throw new Error(`Can't establish a socket connection without id`);
        }

        this.isFetchingData = true;

        if (!this.hasLiveUpdate) {
          return {};
        }

        const onMeasurement = (m) => {
          this.onMeasurement(m);
        };
        const onPeaks = (data) => {
          this.onPeaks(data);
        };

        const onNewData = (from, values) => {
          const currentData = this.internalMeasurement?.data;

          if (currentData?.length === from) {
            const data = [...this.internalMeasurement.data, ...values];
            this.internalMeasurement = {
              ...this.internalMeasurement,
              data,
              data_length: data.length,
            };
          } else if (currentData?.length < from) {
            const difference = from - currentData.length;
            const unknownValues = Array(difference).fill(UNKNOWN_VALUE);
            const data = [...this.internalMeasurement.data, ...unknownValues, ...values];

            this.internalMeasurement = {
              ...this.internalMeasurement,
              data,
              data_length: data.length,
            };
          } else {
            const data = [...(this.internalMeasurement?.data ?? [])];
            data.splice(from, values.length, ...values);

            this.internalMeasurement = {
              ...this.internalMeasurement,
              data,
              data_length: data.length,
            };
          }
        };

        const onConnection = async () => {
          try {
            const measurement = await apiMeasurements.getMeasurement(
              this.measurement.id,
              this.measurement.ulid,
              this.injection.sample_id,
              this.sampleToken,
              !this.isInjectionInProgress && this.doReapplyPostprocessing,
            );
            onMeasurement(measurement);

            if (this.doRefreshPeaks && measurement.data_length > 0) {
              this.refreshPeaksTimerId = setInterval(async () => {
                if (!this.isInjectionInProgress && this.doRefreshPeaks) {
                  const peaks = await apiMeasurements.getPeaks(
                    this.measurement.id,
                    this.injection.sample_id,
                    this.sampleToken,
                    this.sampleToken,
                  );
                  onPeaks({ peaks });
                }
              }, 5000);
            }
          } finally {
            this.isFetchingData = false;
          }
        };

        const socket = this.sampleToken
          ? SharedMeasurementSocket.start(id, onConnection, {
              shareToken: this.sampleToken,
            })
          : MeasurementSocket.start(id, onConnection);

        const socketEvents = this.sampleToken
          ? SharedMeasurementSocketEvents
          : MeasurementSocketEvents;

        const listenersGroup = socket.createEventListenersGroup();
        this.listenersGroupId = listenersGroup.id;

        listenersGroup.addEventListener(socketEvents.NEW_VALUES, onNewData);
        listenersGroup.addEventListener(socketEvents.PEAKS, onPeaks);

        return socket;
      },

      chartEnter() {
        if (this.modeBaselineManual) {
          this.confirmBaseline();
        }
      },

      handleClickOutsideChart() {
        if (this.modeBaselineManual) {
          this.confirmBaseline();
        }
      },

      resetZoom() {
        console.warn('RESET ZOOM');
      },

      // socket methods
      destroySocket() {
        if (!this.hasLiveUpdate) {
          return;
        }

        if (this.measurementSocket) {
          this.measurementSocket.close(this.listenersGroupId);
        }
      },

      peakAdd(start, end) {
        this.showNotificationIfRpcError(() => this.measurementSocket.peakAdd(start, end));
        this.$emit(EVENT_UPDATE);
      },
      removePeaks(ids) {
        this.showNotificationIfRpcError(() => this.measurementSocket.peaksListRemove(ids));
        this.$emit(EVENT_UPDATE);
      },

      baselineSet(points) {
        this.showWarningAboutPeakReset(() => {
          this.showNotificationIfRpcError(() => this.measurementSocket.baselineSet(points));
          this.$emit(EVENT_UPDATE);
        });
      },
      baselineReset() {
        this.showWarningAboutPeakReset(() => {
          this.showNotificationIfRpcError(() => this.measurementSocket.baselineReset());
          this.$emit(EVENT_UPDATE);
        });
      },

      redrawBaseline() {
        this.drawer.actions.resetBaseline();
      },
      removeLastBaselineSegment() {
        this.drawer.actions.removeLastBaselineSegment();
      },

      getAppearance() {
        return (
          persistentStorage.get(PersistentStorageKeys.CHROMATOGRAM_APPEARANCE) ?? {
            hasGridLines: false,
            hasTooltipOnHover: false,
          }
        );
      },

      updateAppearance(appearance) {
        this.drawer?.actions.setHasGridLines(appearance.hasGridLines);
        this.drawer?.actions.setHasTooltipOnHover(appearance.hasTooltipOnHover);
      },

      showWarningAboutPeakReset(onConfirm) {
        if (!this.hasAutoPeaks) {
          onConfirm();
        } else {
          this.$modal.show('dialog', {
            title: 'Reset baseline',
            text: `Automatically generated peaks (blue) will be removed if the baseline is changed`,
            buttons: [
              {
                title: 'Confirm',
                handler: () => {
                  onConfirm();
                  this.$modal.hide('dialog');
                },
                class: 'vue-dialog-button blue-text',
              },
              {
                title: 'Cancel',
                default: true,
                class: 'vue-dialog-button red-text',
              },
            ],
          });
        }
      },

      applyPostprocessingTemplate() {
        this.$modal.show('postprocessing-template-edit');
      },
      resetPostprocessing() {
        this.$modal.show('dialog', {
          title: 'Reset postprocessing',
          text: `Current data (peaks, baseline, noise reduction) will be removed`,
          buttons: [
            {
              title: 'Confirm',
              handler: async () => {
                try {
                  await apiMeasurements.resetPostprocessing(this.measurement.id);
                } catch (e) {
                  this.notifyError(`Can't reset postprocessing`);
                  return;
                }

                await this.refreshPostprocessing();
                this.$emit('applyPostprocessing', false);
                this.$modal.hide('dialog');
              },
              class: 'vue-dialog-button blue-text',
            },
            {
              title: 'Cancel',
              default: true,
              class: 'vue-dialog-button red-text',
            },
          ],
        });
      },

      async refreshPostprocessing() {
        await this.onMeasurement(
          await apiMeasurements.getMeasurement(
            this.measurement.id,
            this.measurement.ulid,
            this.injection.sample_id,
            this.sampleToken,
            false,
          ),
        );
        this.$emit(EVENT_REFRESH_POSTPROCESSING);
        await this.$nextTick();
        this.$emit(EVENT_ZOOM, null);
      },

      onEditPeaks() {
        if (this.postprocessing && !this.isApplyPostprocessing) {
          this.$emit('applyPostprocessing', true);
          this.notify('You can only edit peaks on post-processed data if it exists', 10);
        }
      },

      initRefreshingAfterFinish() {
        console.warn('initRefreshingAfterFinish');
        clearInterval(this.postprocessingRefreshIntervalId);

        this.postprocessingRefreshIntervalId = setInterval(async () => {
          const needRefresh =
            !this.isInjectionInProgress &&
            this.hasPostprocessingsToApply &&
            (this.internalMeasurement?.processing_state === 'not_started' ||
              this.internalMeasurement?.processing_state === 'in_progress') &&
            !this.internalMeasurement.wasPostprocessingComputed;

          if (needRefresh) {
            console.warn(
              'needRefresh',
              !this.isInjectionInProgress,
              this.hasPostprocessingsToApply,
              this.internalMeasurement?.processing_state,
              !this.internalMeasurement.wasPostprocessingComputed,
            );
            this.messageOverlay = MESSAGE_APPLYING_PP;
            await this.refreshPostprocessing();
          } else {
            clearInterval(this.postprocessingRefreshIntervalId);

            if (
              this.internalMeasurement?.processing_state === 'done' &&
              this.internalMeasurement.peaks.length
            ) {
              this.$emit(EVENT_POSTPROCESSING_DONE);
            }

            this.messageOverlay = null;
          }
        }, 1000);
      },

      async refreshMeasurement() {
        const measurement = await apiMeasurements.getMeasurement(
          this.measurement.id,
          this.measurement.ulid,
          this.injection.sample_id,
          this.sampleToken,
          !this.isInjectionInProgress && this.doReapplyPostprocessing,
        );
        this.onMeasurement(measurement);

        if (measurement?.processing_state === 'done' && measurement.peaks.length) {
          this.$emit(EVENT_POSTPROCESSING_DONE);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chromatogram-single {
    &__wrapper-edit-panel {
      display: flex;
      justify-content: center;
      margin: 10px 0;
      padding-top: 16px;

      @media print {
        display: none;
      }

      @media (max-width: $screen-xs-max) {
        overflow-x: auto;
        justify-content: flex-start;

        &::before,
        &::after {
          content: '';
          display: block;
          width: 16px;
          flex: none;
        }
      }
    }

    &__prompt {
      position: absolute;
      top: 30px;
      right: 40px;
      z-index: 5;
    }

    &__btn-edit-baseline {
      padding: 4px 12px;

      &--active {
        background: $color-bg-primary;
        color: $color-text-on-primary;

        &:enabled:hover,
        &:enabled:focus {
          background: $color-bg-primary--hover;
        }

        &:enabled:active {
          background: $color-bg-primary--active;
          color: $color-text-on-primary--active;
        }

        &:disabled,
        &[disabled] {
          cursor: not-allowed;
          color: $color-text-on-primary--disabled;
          background-color: $color-bg-primary--disabled;
        }
      }
    }
  }
</style>

<style lang="scss">
  .chromatogram {
    padding: 0;
    margin: 0 0 0 0;

    &__btn-show-edit-panel {
      position: absolute;
      top: 20px;
      right: 40px;
    }

    &__icon-build {
      color: $color-text-primary;
    }
  }

  .chromatogram-chart {
    position: relative;
    width: 100%;
    margin: 0;
    height: 300px;
  }
</style>
