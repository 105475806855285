<template>
  <Select
    :itemSelected="localDevice"
    :items="localDevices"
    labelProp="name"
    :hasSearch="true"
    padding="md"
    placeholderSearch="Choose device"
    titleBottomSheet="Choose device"
    :isDisabled="readonly"
    @update:itemSelected="selectedDeviceChanged"
    @showPopup="handleShowPopup"
    @hidePopup="handleHidePopup"
  >
    <template #rightSideInsideItem="{ item: device }">
      <StatusOnline
        :isOnline="device ? device.isOnline : null"
        :isWorking="isInjectionInProgress"
        class="option__online"
      />
    </template>
  </Select>
</template>

<script>
  import _ from 'lodash';

  import StatusOnline from '@/uikitProject/statuses/StatusOnline.vue';
  import Select from '@/uikitBase/selects/vueSelect/Select';

  const EVENT_CHANGE = 'change';

  export default {
    name: 'SelectDeviceComponent',

    components: {
      Select,
      StatusOnline,
    },

    model: {
      prop: 'device',
      event: EVENT_CHANGE,
    },

    props: {
      devices: {
        type: Array,
        required: true,
      },
      device: {
        type: Object,
        default: undefined,
      },
      required: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        localDevices: this.devices,
        localDevice: this.device,

        isSelectOpened: false,
      };
    },

    computed: {
      isInjectionInProgress() {
        return this.device?.state?.injection_id != null;
      },
    },

    watch: {
      devices(devices, oldDevices) {
        this.invalidateSelectedDevice();

        if (!this.device && this.required && devices && devices.length) this.autoSelect();

        if (this.isPopupOpened && _.isEqual(devices, oldDevices)) {
          return;
        }
        this.localDevices = devices;
      },
      device(device, oldDevice) {
        this.invalidateSelectedDevice();

        if (this.isPopupOpened && _.isEqual(device, oldDevice)) {
          return;
        }
        this.localDevice = device;
      },
    },

    created() {
      this.invalidateSelectedDevice();
    },

    methods: {
      handleShowPopup() {
        this.isSelectOpened = true;
        this.$emit('showPopup');
      },
      handleHidePopup() {
        this.isSelectOpened = false;
        this.$emit('hidePopup');
      },

      autoSelect() {
        const deviceOnline = this.devices.find((device) => device.isOnline);
        const device = deviceOnline ? deviceOnline : this.devices[0];
        this.selectedDeviceChanged(device);
      },
      invalidateSelectedDevice() {
        if (!this.device || this.device in this.devices) return;
        const device = this.devices.find((device) => device.id === this.device.id);
        this.selectedDeviceChanged(device);
      },
      selectedDeviceChanged(item) {
        this.$emit(EVENT_CHANGE, item);
      },
    },
  };
</script>

<style lang="scss">
  .option {
    &__desc {
      display: flex;
      justify-content: space-between;
      padding-right: 10px;
    }

    &__wrapper-title {
      flex: 1;
      @include textOverflow;
    }

    &__online {
      margin-left: 5px;

      &--selected {
        margin-right: 24px;
      }
    }
  }
</style>
