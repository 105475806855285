export default class DomHelper {
  public static isElementInsideViewport(element: HTMLElement) {
    const elementRect = element.getBoundingClientRect();

    return (
      elementRect.top >= 0 &&
      elementRect.left >= 0 &&
      elementRect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      elementRect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  /**
   * To replace the scrollIntoView function. Because we need only horizontal scroll.
   */
  public static scrollToHorizontally(element: HTMLElement) {
    const elementRightSide = element.offsetLeft + element.offsetWidth;
    const elementLeftSide = element.offsetLeft;

    const { parentNode } = element;

    if (parentNode != null && parentNode instanceof HTMLElement) {
      const parentElementRightSide = parentNode.offsetLeft + parentNode.offsetWidth;
      const parentElementLeftSide = parentNode.offsetLeft;

      // check if right side of the element is not in view
      if (elementRightSide > parentElementRightSide + parentNode.scrollLeft) {
        parentNode.scrollLeft = elementRightSide - parentElementRightSide;
      }

      // check if left side of the element is not in view
      else if (elementLeftSide < parentElementLeftSide + parentNode.scrollLeft) {
        parentNode.scrollLeft = elementLeftSide - parentElementLeftSide;
      }
    }
  }
}
