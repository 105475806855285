<template>
  <div class="more-menu">
    <GlobalEvents target="window" @resize="updatePopupPosition" />

    <button
      ref="button"
      class="more-menu__button-more button--square-image"
      :class="{ 'visibility-hidden': popupVisible }"
      @click="showPopup"
    >
      <i class="material-icons material-icon--18">more_vert</i>
    </button>

    <div
      class="overlay--light"
      :class="{ 'visibility-hidden': !popupVisible }"
      @click="hidePopup"
    />

    <div
      ref="popup"
      class="more-menu__popup form-container"
      :class="{ 'visibility-hidden': !popupVisible }"
      @click="hidePopup"
    >
      <slot />
    </div>
  </div>
</template>

<script>
  import 'assets/css/component/modal.scss';
  import RouterHelper from 'utils/RouterHelper.ts';
  import LayoutHelper from 'utils/LayoutHelper.ts';

  export default {
    name: 'MoreMenuComponent',

    data: () => ({
      popupVisible: false,
    }),

    watch: {
      popupVisible(value) {
        if (value) {
          this.updatePopupPosition();
        }

        LayoutHelper.setPageScroll(value);
      },
    },

    methods: {
      setPopupPosition(top, left) {
        const { popup } = this.$refs;
        popup.style.left = `${left}px`;
        popup.style.top = `${top}px`;
      },

      updatePopupPosition() {
        const { button, popup } = this.$refs;

        if (RouterHelper.isDualMode) {
          const left = button.offsetLeft + button.clientWidth - popup.clientWidth;
          const top = button.offsetTop;

          this.setPopupPosition(top, left);
        } else {
          const buttonRect = button.getBoundingClientRect();
          const popupWidth = popup.getBoundingClientRect().width;
          const left = buttonRect.left + buttonRect.width - popupWidth;
          const top = buttonRect.top + window.scrollY;

          this.setPopupPosition(top, left);
        }
      },

      showPopup() {
        this.popupVisible = true;
      },
      hidePopup() {
        this.popupVisible = false;
      },
    },
  };
</script>

<style lang="scss">
  .more-menu {
    display: inline-block;

    .more-menu__button-more {
      width: 32px;
    }

    .more-menu__popup {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      padding: 0 8px 0 0;
      background: #ffffff;
      border-radius: 4px;
      z-index: 100;
      white-space: nowrap;
    }

    .more-menu__popup:first-child {
      margin-left: 0;
    }
  }

  @media print {
    .more-menu {
      display: none;
    }
  }

  .visibility-hidden {
    visibility: hidden;
  }
</style>
