<script>
  export default {
    name: 'WithDelay',

    props: {
      delay: {
        type: [Number, String],
        required: true,
      },
    },

    data: () => ({
      isShow: false,
    }),

    created() {
      setTimeout(() => {
        this.isShow = true;
      }, this.delay);
    },

    render() {
      return this.isShow ? this.$slots.default : null;
    },
  };
</script>
