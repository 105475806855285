import { Svg, Symbol } from '@svgdotjs/svg.js';
import {
  COLORS,
  SIZES_PX,
  SVG_POSITIONS,
} from '@/uikitProject/charts/vueChartGradient/private/config';

/**
 * ElementBuilder creates visual elements for GradientChart
 *
 * Created elements are not visible.
 *
 * Instance methods return SVG Symbol elements which must be explicitly used to become visible
 * */
export class ElementBuilder {
  // eslint-disable-next-line no-useless-constructor,no-empty-function
  constructor(private svg: Svg) {}

  get width(): number {
    return this.svg.node.clientWidth - SIZES_PX.PADDING_LEFT - SIZES_PX.PADDING_RIGHT;
  }

  /**
   * The leftmost element on a chart - Y axis
   * */
  public createScale(): Symbol {
    const symbol = this.svg.symbol();
    //Main vertical scale line
    symbol
      .line(
        SVG_POSITIONS.CHART_X1,
        SVG_POSITIONS.MAIN_RECT_Y1,
        SVG_POSITIONS.CHART_X1,
        SVG_POSITIONS.MAIN_RECT_Y2,
      )
      .stroke(COLORS.SCALE_VERTICAL);

    //Horizontal lines (divisions on a scale)
    const y1 =
      SVG_POSITIONS.MAIN_RECT_Y1 + (SIZES_PX.HEIGHT_SCALE_VERTICAL - SIZES_PX.HEIGHT_CHART) / 2;
    const y2 = y1 + SIZES_PX.HEIGHT_CHART;
    symbol
      .line(SVG_POSITIONS.CHART_X1, y1, SVG_POSITIONS.CHART_X1 + 3, y1)
      .stroke(COLORS.SCALE_VERTICAL);
    symbol
      .line(SVG_POSITIONS.CHART_X1, y2, SVG_POSITIONS.CHART_X1 + 3, y2)
      .stroke(COLORS.SCALE_VERTICAL);

    return symbol;
  }

  /**
   * Main gradient line
   * */
  public createLine(points: number[]): Symbol {
    const symbol = this.svg.symbol();

    symbol
      .polyline(points)
      .fill('transparent')
      .stroke({ width: SIZES_PX.GRADIENT_LINE_WIDTH, color: COLORS.GRADIENT_LINE });

    return symbol;
  }

  /**
   * A and B areas with corresponding background
   * */
  public createAreas(points: number[]): [Symbol, Symbol] {
    const symbolA = this.svg.symbol();
    const symbolB = this.svg.symbol();

    symbolA
      .polygon([
        SVG_POSITIONS.CHART_X1,
        SVG_POSITIONS.CHART_Y1,
        ...points,
        SVG_POSITIONS.CHART_X1 + this.width,
        SVG_POSITIONS.CHART_Y1,
      ])
      .fill(COLORS.AREA_A);
    symbolB
      .polygon([
        SVG_POSITIONS.CHART_X1,
        SVG_POSITIONS.CHART_Y2,
        ...points,
        SVG_POSITIONS.CHART_X1 + this.width,
        SVG_POSITIONS.CHART_Y2,
      ])
      .fill(COLORS.AREA_B);

    return [symbolA, symbolB];
  }

  public createPoint() {
    const point = this.svg.symbol();

    point.circle(5).center(5, 5).fill(COLORS.GRADIENT_POINT_INSIDE).stroke({
      color: COLORS.GRADIENT_POINT_OUTSIDE,
      width: 2,
    });

    return point;
  }

  public createPopup(a: string, b: string): Symbol {
    const popup = this.svg.symbol();

    const PADDING = 8;

    const text = popup
      .text((add) => {
        add.tspan(a).fill(COLORS.LABEL_A);
        add.tspan(' / ').fill('#000');
        add.tspan(b).fill(COLORS.LABEL_B);
      })
      .font({ size: 13 })
      .move(PADDING / 2, PADDING / 2);
    popup
      .rect(text.bbox().width + PADDING, text.bbox().height + PADDING)
      .radius(4)
      .opacity(0.9)
      .fill('#f0f0f0')
      .back();

    return popup;
  }

  public createLabels(): [Symbol, Symbol] {
    const FONT_SIZE = 13;

    const labelA = this.svg.symbol();
    labelA
      .text((add) => {
        add.tspan('A').fill(COLORS.LABEL_A).font({ weight: '700' });
        // add.tspan(' MeCN/H20 - 20/80%').fill(COLORS.LABEL_BASE);
      })
      .font({ size: FONT_SIZE })
      .move(SVG_POSITIONS.CHART_X1 + 4, SVG_POSITIONS.MAIN_RECT_Y1);

    const labelB = this.svg.symbol();
    labelB
      .text((add) => {
        add.tspan('B').fill(COLORS.LABEL_B).font({ weight: '700' });
        // add.tspan(' MeCN/H20 - 20/80%').fill(COLORS.LABEL_BASE);
      })
      .font({ size: FONT_SIZE })
      .move(SVG_POSITIONS.CHART_X1 + 4, SVG_POSITIONS.MAIN_RECT_Y2 - FONT_SIZE);

    return [labelA, labelB];
  }

  public createTitle(): Symbol {
    const FONT_SIZE = 13;

    const symbol = this.svg.symbol();

    const title = symbol.text('Gradient profile').fill(COLORS.TITLE).font({ size: FONT_SIZE });
    title.move(SIZES_PX.PADDING_LEFT + this.width - title.bbox().w, SIZES_PX.PADDING_TOP);

    return symbol;
  }
}
