<template>
  <div class="invitation-to-organization-page">
    <div class="top-line--flex">
      <h1>Invitation</h1>
    </div>

    <LoadingComponent v-if="hasAnswer && isLoading" />
    <LoadingComponent v-else label="You will be redirected in a few seconds" />
  </div>
</template>

<script>
  import PageTitle from '@/mixins/page-title';
  import LoadingComponent from '@/components/element/LoadingComponent.vue';
  import { OrganizationsApi } from '@/api/organizations';
  import { AuthService } from '@/services/auth.service';

  export default {
    name: 'OrganizationPage',

    components: { LoadingComponent },

    mixins: [PageTitle],

    data() {
      return {
        pageTitle: 'HPLC Cloud: Invitation to organization',
        isLoading: true,
        hasAnswer: false,
      };
    },

    mounted() {
      this.showConfirmation();
    },

    methods: {
      showConfirmation() {
        const organizationName = this.$route.query.name;

        this.$modal.show('dialog', {
          title: 'Accept invitation?',
          text: `You are invited to <b>${organizationName}</b>.<br/><br/>If you accept the invitation, you will get access to all devices and projects of the organization.`,
          buttons: [
            {
              title: 'Decline',
              handler: () => {
                this.hasAnswer = true;
                this.$modal.hide('dialog');
                this.$router.push({ name: 'devices' });
              },
              class: 'vue-dialog-button red-text',
            },
            {
              title: 'Accept',
              default: true,
              handler: async () => {
                this.hasAnswer = true;
                this.$modal.hide('dialog');
                await this.acceptInvitation();
                AuthService.refreshAccessToken();
              },
              class: 'vue-dialog-button blue-text',
            },
          ],
          canCloseOnClickOutside: false,
        });
      },
      async acceptInvitation() {
        try {
          await OrganizationsApi.join(this.$route.params.token);
          this.$router.push({ name: 'devices' });
        } catch (e) {
          this.notifyError(e);
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .invitation-to-organization-page {
  }
</style>
