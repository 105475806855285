<template>
  <div class="error">
    <div class="error__image-container">
      <img src="@/assets/img/cat-in-the-box.png" />
    </div>

    <div v-if="hasSlot">
      <slot></slot>
    </div>
    <div v-else-if="code != null">
      <template v-if="code === 401">Not authorized</template>
      <template v-else-if="code === 403">Forbidden</template>
      <template v-else-if="code === 404">Not found</template>
      <template v-else-if="code === 408">Timeout</template>
      <template v-else-if="code === 500">Server Error</template>
      <template v-else-if="code === -1">Client side error</template>

      <template v-else>Error</template>
    </div>
    <span v-else>Error</span>

    <div v-if="showRetry" class="vertical-column">
      <button class="button--square-image" @click="retryClicked">
        <i class="material-icons material-icon--18">refresh</i>
        Retry
      </button>
    </div>
  </div>
</template>

<script>
  const RETRY_EVENT = 'retry';

  export default {
    name: 'ErrorComponent',
    props: {
      code: Number,
      showRetry: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      hasSlot() {
        return !!this.$slots.default;
      },
    },
    methods: {
      retryClicked() {
        this.$emit(RETRY_EVENT);
      },
    },
  };
</script>

<style lang="scss">
  .error {
    text-align: center;
    width: 100%;
    color: #00000099;
    align-content: center;

    .vertical-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .button--square-image {
      margin: 10px 0 0 0;
    }
  }

  .error__image-container {
    width: 100%;

    img {
      width: 96px;
      margin: 0 auto 16px;
      display: block;
      opacity: 0.5;
    }
  }
</style>
