import { getErrorMessage } from '../utils/errorHelpers';
import { WS_ERROR_CODES } from '@/constants/errors/wsErrorCodes';
import { ERRORS } from '@/constants/errors/errors';

const DEFAULT_DURATION = 5;
const DEFAULT_DURATION_WITH_ACTIONS = 10;
const MS_IN_SECOND = 1000;

const activeToasts = Symbol('activeToasts');

export default {
  data: () => ({
    [activeToasts]: new Map(),
  }),

  methods: {
    notify(message, duration = DEFAULT_DURATION) {
      this.$toasted.show(message, {
        duration: duration * 1000,
      });
    },
    notifyWithActions(
      message,
      { duration: _duration = DEFAULT_DURATION_WITH_ACTIONS, actions, onNoActionDetect, icon },
      id,
    ) {
      const duration = _duration * MS_IN_SECOND;

      const toast = this.$toasted.show(message, {
        duration,
        action: actions,
        icon,
        // Called when the user doesn't press action buttons or closes the toast with a swipe
        onComplete() {
          onNoActionDetect();
        },
      });

      if (id) {
        this.notifyHide(id);

        this.$data[activeToasts].set(id, toast);

        setTimeout(() => {
          this.$data[activeToasts].delete(id);
        }, duration);
      }
    },
    notifyHide(id) {
      const toast = this.$data[activeToasts].get(id);
      if (toast) {
        toast.goAway(0);
        this.$data[activeToasts].delete(id);
      }
    },
    notifyError(message, { durationSeconds = DEFAULT_DURATION, hasCloseButton = false } = {}) {
      const config = {
        type: 'error',
        duration: durationSeconds * MS_IN_SECOND,
        className: ['toast-error'],
        action: hasCloseButton && {
          icon: 'close',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      };

      this.$toasted.show(message, config);
    },
    notifyResponseError(data, code) {
      let message = getErrorMessage(data?.detail || data?.error || data?.msg || data?.message);
      if (!message) {
        if (code === 401) message = 'Not authorized';
        else if (code === 400) message = 'Bad request';
        else if (code === 403) message = 'Forbidden';
        else if (code === 404) message = 'Not found';
        else if (code === 408) message = 'Timeout';
        else if (code === 500) message = 'Server error';
        else if (code === -1) message = 'Client side error';
        else message = `Error code ${code}`;
      }
      this.notifyError(message);
    },
    async showNotificationIfRpcError(sendRPCRequest) {
      try {
        return await sendRPCRequest();
      } catch (e) {
        if (e.code === WS_ERROR_CODES.CONNECTION_CLOSED) {
          this.notifyError(ERRORS.timeout);
        } else {
          this.notifyError(getErrorMessage(e.message));
        }
        throw e;
      }
    },
  },
};
