<template>
  <div class="avatar" :class="classSize" :style="styles" :title="name">
    <span class="avatar__letter">{{ name[0] }}</span>
  </div>
</template>

<script>
  const PALETTE = [
    '#f8e3d3',
    '#DEEDF0',
    '#F4C7AB',
    '#B2B8A3',
    '#C37B89',
    '#EAE7C6',
    '#C56824',
    '#C6D57E',
    '#ECD662',
    '#FF616D',
    '#E2BCB7',
  ];

  export default {
    name: 'Avatar',

    props: {
      name: {
        type: String,
        required: true,
      },
      size: {
        type: String,
        default: 'md',
        validator(value) {
          const availableValues = ['md'];
          return availableValues.includes(value);
        },
      },
    },

    computed: {
      classSize() {
        return `avatar--size--${this.size}`;
      },
      styles() {
        return {
          backgroundColor: PALETTE[this.name.charCodeAt() % (PALETTE.length - 1)],
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .avatar {
    position: relative;
    display: inline-flex;
    flex: none;
    align-items: center;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    border-radius: 50%;
    font-weight: $weight-bold;

    &--size {
      &--md {
        width: 32px;
        height: 32px;
        font-size: $size-xs - 1px;
      }
    }

    &__letter {
      padding-top: 0.1em;
    }
  }
</style>
