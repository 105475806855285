<template>
  <modal ref="modal" :width="750 - 128" name="scan-qr-modal">
    <div class="modal-content">
      <h1 class="text-gray">Scan QR code</h1>
      <QRReader @data="scan" @init="fixSafariBug" />
    </div>
  </modal>
</template>

<script>
  import ModalComponent from 'components/element/ModalComponent.vue';
  import QRReader from '@/uikitProject/camera/QRReader';

  const MODAL_NAME = 'scan-qr-modal';

  const EVENT_DATA = 'data';

  export default {
    name: 'ScanQRModal',

    components: {
      QRReader,
      modal: ModalComponent,
    },

    methods: {
      show() {
        this.$modal.show(MODAL_NAME);
      },
      close() {
        this.$modal.hide(MODAL_NAME);
      },
      scan(data) {
        this.close();
        this.$emit(EVENT_DATA, data);
      },

      fixSafariBug() {
        // Safari can't show video until scrolling
        const { $el } = this.$refs.modal;
        $el.scrollTop++;
        $el.scrollTop--;
      },
    },
  };
</script>
