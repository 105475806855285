import { render, staticRenderFns } from "./QRReader.vue?vue&type=template&id=641d8d12&scoped=true&"
import script from "./QRReader.vue?vue&type=script&lang=js&"
export * from "./QRReader.vue?vue&type=script&lang=js&"
import style0 from "./QRReader.vue?vue&type=style&index=0&id=641d8d12&lang=scss&scoped=true&"
import style1 from "./QRReader.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641d8d12",
  null
  
)

export default component.exports