<template>
  <div class="applied-postprocessing">
    <div class="applied-postprocessing__title">
      {{ postprocessing.measurement.name }} - {{ postprocessing.measurement.waveLength }}mn
    </div>

    <div class="applied-postprocessing__details">
      <div v-if="postprocessingTypes.PDET" class="applied-postprocessing__section">
        <div class="applied-postprocessing__title">Peak detection</div>
        <DecoratorProperty
          bgColor="#ecefff"
          property="Algorithm"
          class="applied-postprocessing__property"
        >
          {{ POSTPROCESSING_TEMPLATE_MAP[postprocessingTypes.PDET.algorithm] }}
        </DecoratorProperty>
        <DecoratorProperty
          v-if="postprocessingTypes.PDET.peak_detection_min_height != null"
          bgColor="#ecefff"
          :property="`Min height ${
            postprocessingTypes.PDET.peak_detection_min_height !== -1 ? ', mAU' : ''
          }`"
          class="applied-postprocessing__property"
        >
          {{
            postprocessingTypes.PDET.peak_detection_min_height !== -1
              ? postprocessingTypes.PDET.peak_detection_min_height
              : '10 * Noise level'
          }}
        </DecoratorProperty>
        <DecoratorProperty
          v-if="postprocessingTypes.PDET.peak_detection_min_prominence != null"
          bgColor="#ecefff"
          property="Min Prominence (relative height), mAU"
          class="applied-postprocessing__property"
        >
          {{ postprocessingTypes.PDET.peak_detection_min_prominence }}
        </DecoratorProperty>
        <DecoratorProperty
          v-if="postprocessingTypes.PDET.peak_detection_min_width != null"
          bgColor="#ecefff"
          property="Min Width, sec"
          class="applied-postprocessing__property"
        >
          {{ postprocessingTypes.PDET.peak_detection_min_width }}
        </DecoratorProperty>
        <DecoratorProperty
          v-if="postprocessingTypes.PDET.peak_detection_min_distance != null"
          bgColor="#ecefff"
          property="Min Distance between peaks, min"
          class="applied-postprocessing__property"
        >
          {{ postprocessingTypes.PDET.peak_detection_min_distance }}
        </DecoratorProperty>
      </div>

      <div v-if="postprocessingTypes.DTIM" class="applied-postprocessing__section">
        <DecoratorProperty
          v-if="postprocessingTypes.DTIM.detection_time_start_sec != null"
          bgColor="#ecefff"
          property="Start time, min"
          class="applied-postprocessing__property"
        >
          {{ postprocessingTypes.DTIM.detection_time_start_sec / 60 }}
        </DecoratorProperty>
        <DecoratorProperty
          v-if="postprocessingTypes.DTIM.detection_time_end_sec != null"
          bgColor="#ecefff"
          property="End time, min"
          class="applied-postprocessing__property"
        >
          {{ postprocessingTypes.DTIM.detection_time_end_sec / 60 }}
        </DecoratorProperty>
      </div>

      <div
        v-if="postprocessingTypes.PEAK && postprocessingTypes.PEAK.algorithm !== 'none'"
        class="applied-postprocessing__section"
      >
        <div class="applied-postprocessing__title">Peak integration</div>
        <DecoratorProperty
          bgColor="#ecefff"
          property="Algorithm"
          class="applied-postprocessing__property"
        >
          {{ POSTPROCESSING_TEMPLATE_MAP[postprocessingTypes.PEAK.algorithm] }}
        </DecoratorProperty>
        <DecoratorProperty
          bgColor="#ecefff"
          property="Quality threshold"
          class="applied-postprocessing__property"
        >
          {{ postprocessingTypes.PEAK.peak_integration_quality_threshold }}
        </DecoratorProperty>
      </div>

      <div
        v-if="postprocessingTypes.BASE && postprocessingTypes.BASE.algorithm !== 'none'"
        class="applied-postprocessing__section"
      >
        <div class="applied-postprocessing__title">Baseline correction</div>
        <DecoratorProperty
          bgColor="#ecefff"
          property="Algorithm"
          class="applied-postprocessing__property"
        >
          {{ POSTPROCESSING_TEMPLATE_MAP[postprocessingTypes.BASE.algorithm] }}
        </DecoratorProperty>
        <DecoratorProperty
          v-if="postprocessingTypes.BASE.algorithm === 'horizontal_correction'"
          bgColor="#ecefff"
          property="Anchor point"
          class="applied-postprocessing__property"
        >
          {{ postprocessingTypes.BASE.baseline_correction_anchor_point }}
        </DecoratorProperty>
      </div>

      <div
        v-if="postprocessingTypes.BINJ && postprocessingTypes.BINJ.algorithm !== 'none'"
        class="applied-postprocessing__section"
      >
        <div class="applied-postprocessing__title">Blank injection</div>
        <DecoratorProperty
          bgColor="#ecefff"
          property="Algorithm"
          class="applied-postprocessing__property"
        >
          {{ POSTPROCESSING_TEMPLATE_MAP[postprocessingTypes.BINJ.algorithm] }}
        </DecoratorProperty>
        <DecoratorProperty
          v-if="postprocessingTypes.BINJ.algorithm === 'simple_subtraction'"
          bgColor="#ecefff"
          property="Measurement"
          class="applied-postprocessing__property"
        >
          <LoaderSpinner v-if="isLoadingMeasurement" />
          <RouterLink
            v-else-if="blankInjectionMeasurement"
            :to="linkToInjection"
            class="select-measurement__link-to-measurement"
          >
            <IconStandard v-if="blankInjectionMeasurement.injection.sample.isStandard" />
            <IconSample v-else />
            {{ blankInjectionMeasurement.injection.sample.name }} #{{
              blankInjectionMeasurement.injection.name
            }}
            : {{ blankInjectionMeasurement.name }}
          </RouterLink>
        </DecoratorProperty>
      </div>

      <div
        v-if="postprocessingTypes.NOIS && postprocessingTypes.NOIS.algorithm !== 'none'"
        class="applied-postprocessing__section"
      >
        <div class="applied-postprocessing__title">Noise reduction</div>
        <DecoratorProperty
          bgColor="#ecefff"
          property="Algorithm"
          class="applied-postprocessing__property"
        >
          {{ POSTPROCESSING_TEMPLATE_MAP[postprocessingTypes.NOIS.algorithm] }}
        </DecoratorProperty>
      </div>
    </div>
  </div>
</template>

<script>
  import DecoratorProperty from '@/uikitProject/decorators/DecoratorProperty.vue';
  import { POSTPROCESSING_TEMPLATE_MAP } from '@/utils/postprocessingTemplateHelpers';
  import { apiMeasurements } from '@/api/graphql/cloud/measurements';
  import IconStandard from '@/uikitProject/icons/IconStandard.vue';
  import IconSample from '@/uikitProject/icons/IconSample.vue';
  import LoaderSpinner from '@/uikitProject/loaders/LoaderSpinner.vue';

  export default {
    name: 'AppliedPostprocessing',

    components: {
      LoaderSpinner,
      IconSample,
      IconStandard,
      DecoratorProperty,
    },

    props: {
      postprocessing: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      POSTPROCESSING_TEMPLATE_MAP,

      blankInjectionMeasurement: null,

      isLoadingMeasurement: true,
    }),

    computed: {
      linkToInjection() {
        return `/${
          this.blankInjectionMeasurement.injection.sample.isStandard ? 'standards' : 'samples'
        }/${this.blankInjectionMeasurement.injection.sample.id}/injection/${
          this.blankInjectionMeasurement.injection.id
        }`;
      },

      postprocessingTypes() {
        return this.postprocessing.postprocessings;
      },
    },

    created() {
      this.initBlankInjectionMeasurement();
    },

    methods: {
      async initBlankInjectionMeasurement() {
        if (
          !this.blankInjectionMeasurement &&
          this.postprocessingTypes.BINJ?.blank_injection_measurement_id
        ) {
          try {
            this.isLoadingMeasurement = true;
            this.blankInjectionMeasurement = await apiMeasurements.getMeasurementShort(
              this.postprocessingTypes.BINJ.blank_injection_measurement_id,
            );
          } finally {
            this.isLoadingMeasurement = false;
          }
        }
        this.isLoadingMeasurement = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .applied-postprocessing {
    &__title {
      width: 100%;
      display: flex;
      align-items: center;
      font-weight: $weight-bold;
    }

    &__section {
      margin-bottom: 5px;
    }

    &__details {
      margin-top: 5px;
    }

    &__title {
      font-weight: $weight-bold;
    }
  }
</style>
