<template>
  <router-link
    :to="link"
    class="item"
    :class="{
      'item--selected':
        isSelected || this.$route.name === link.name || this.$route.name === `dual.${link.name}`,
    }"
  >
    <IconMaterial size="18" :title="icon" />
    <span class="item__label"><slot /></span>
  </router-link>
</template>

<script>
  import IconMaterial from '@/uikitBase/icons/IconMaterial';

  export default {
    name: 'Item',

    components: { IconMaterial },

    props: {
      link: {
        type: Object,
        required: true,
      },
      icon: {
        type: String,
        required: true,
      },
      isSelected: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/css/base/block';

  .item {
    @extend .disable-selection;
    @extend .disable-tap-highlight;
    display: flex;
    align-items: center;
    padding: 0 24px 0 20px;
    white-space: nowrap;
    text-decoration: none;

    &:hover {
      background: $color-bg-transparent--hover;
    }

    &:active {
      background: $color-bg-transparent--active;
    }

    @media (max-width: 700px) {
      padding: 3px 8px 0;
    }

    @media (max-width: 440px) {
      padding: 3px 6px 0;
    }

    &--selected {
      border-top: 2px solid transparent;
      border-bottom: 2px solid $color-text-primary;

      &:hover {
        border-bottom: 2px solid $color-text-primary--hover;
      }

      &:active {
        border-bottom: 2px solid $color-text-primary--active;
      }
    }

    @media (max-width: 700px) {
      flex-direction: column;
      justify-content: center;
    }

    @media (max-width: 440px) {
      font-size: 12px;
    }

    @media (max-width: 400px) {
      font-size: 11px;
    }

    @media (max-width: 370px) {
      font-size: 10px;
    }

    &__label {
      padding-left: 2px;

      @media (max-width: 700px) {
        padding-left: 0;
        padding-top: 1px;
      }
    }
  }
</style>
