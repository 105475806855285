import _ from 'lodash';
import MethodAPI from '@/api/method';
import { METHOD_EMPTY } from '@/constants/methods/presets';
import { SECONDS_IN_MINUTE } from '@/uikitProject/charts/new/private/constants';

type Method = any;

export const prepareMethodForRequest = (_method: Method) => {
  const method = {
    ...METHOD_EMPTY,
    ..._.cloneDeep(_method),
  };

  delete method.defaultValues;

  method.name = method.name?.trim() ?? '';
  method.flow_rate_ml_per_min = method.flow_rate_ml_per_min ?? undefined;
  method.run_time_min = method.run_time_min ?? undefined;
  method.refill_flow_rate_ml_per_min = method.refill_flow_rate_ml_per_min ?? undefined;
  method.injection_sec =
    method.run_time_min != null ? method.run_time_min * SECONDS_IN_MINUTE : undefined;
  method.injection_ul = method.injection_ul ?? undefined;

  if (method?.injection_delay_sec === 0) {
    method.injection_delay_sec = null;
  }

  if (method?.fraction_collector_interval_sec === 0) {
    method.fraction_collector_interval_sec = null;
  }

  if (method?.fraction_collector_start_delay_sec === 0) {
    method.fraction_collector_start_delay_sec = null;
  }

  if (method?.detector_single_channel === 'All') {
    method.detector_single_channel = null;
  }

  if (!method?.valve_does_change_position) {
    method.valve_change_position_after_sec = 5;
  }

  return method;
};

let defaultValues;
export const getMethodWithDefaultValuesByConfiguration = async (method, confuguration) => {
  defaultValues ??= await MethodAPI.getDefaultValues();

  const defaultValuesByDeviceType =
    confuguration.device_type === 'CROMITE'
      ? { ...defaultValues.common, ...defaultValues.cromite }
      : { ...defaultValues.common, ...defaultValues.alltesta };

  const valuesToRemove: string[] = [];

  if (!(confuguration.pump?.length >= 2)) {
    valuesToRemove.push('gradient', 'solvents');
  }

  if (!(confuguration.autosampler.length > 0)) {
    valuesToRemove.push(
      'needle_depth_mm',
      'syringe_refill_speed',
      'shaking_duration_sec',
      'wash_after_injection',
    );
  }

  const methodWithDefaultValues = {
    ...METHOD_EMPTY,
    ...defaultValuesByDeviceType,
    ...method,
    defaultValues: defaultValuesByDeviceType,
  };

  valuesToRemove.forEach((value) => delete methodWithDefaultValues[value]);

  return methodWithDefaultValues;
};

export const isMethodDefaultConfiguration = (methodConfiguration) => {
  return Array.isArray(methodConfiguration);
};

export const canUseMethodWithDevice = (deviceConfigurationForMethod, methodConfiguration) => {
  return (
    isMethodDefaultConfiguration(methodConfiguration) ||
    (deviceConfigurationForMethod != null &&
      methodConfiguration != null &&
      JSON.stringify(deviceConfigurationForMethod) === JSON.stringify(methodConfiguration))
  );
};

const valvePositionNames = {
  DO_NOT_CHANGE: 'Do not change',
  CHANGE: 'Change position',
  POSITION_1: 'Position 1',
  POSITION_2: 'Position 2',
} as const;

export const valveInitialPositionMap = {
  '-2': valvePositionNames.CHANGE,
  '-1': valvePositionNames.DO_NOT_CHANGE,
  '1': valvePositionNames.POSITION_1,
  '2': valvePositionNames.POSITION_2,
  [valvePositionNames.CHANGE]: '-2',
  [valvePositionNames.DO_NOT_CHANGE]: '-1',
  [valvePositionNames.POSITION_1]: '1',
  [valvePositionNames.POSITION_2]: '2',
} as const;
