import { REST } from 'utils/rest';
import RequestError from '@/errors/RequestError';

const url = '/api/sequences/';

export class SequenceAPI {
  static get(onSuccess, onError) {
    return REST.get(url, onSuccess, onError);
  }

  static post(data, onSuccess, onError) {
    return REST.post(url, data, onSuccess, onError);
  }

  static async downloadReport(data) {
    const url = `/report/sequence/`;
    const response = await fetch(url, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'text/plain',
      },
    });

    if (response.ok) {
      return response.blob();
    }

    throw new RequestError();
  }

  static sendReport({ sequenceId, email, message, report_data, report_type }, onSuccess, onError) {
    return REST.post(
      `/api/sequences/${sequenceId}:emailpdf`,
      { email, message, report_data, report_type },
      onSuccess,
      onError,
    );
  }
}
