import RouterHelper from '@/utils/RouterHelper';
import router from '@/router/app';
import { NavigationGuard } from 'vue-router';
import { consoleHelpers } from '@/utils/logHelpers';

export const ENOUGH_SCREEN_WIDTH_PX = 1600;

/**
 * Path without prefix '/dual/a/b/c' => '/a/b/c'
 */
const removeDualPrefix = (fullpath: string) => fullpath.slice(5);

const splitScreenManager: NavigationGuard = (to, from, next) => {
  const isEnoughScreenWidth = window.innerWidth >= ENOUGH_SCREEN_WIDTH_PX;

  const locationCurrent = { ...from };
  const locationNext = { ...to };
  const {
    primaryMustBe,
    makeSecondaryAsPrimary,
    makePrimaryAsSecondary,
    setAsPrimary,
    isOnlyQueryUpdate,
    notOpenSecondaryTab,
    doReplace,
    rawLocation,
    ...otherQuery
  } = locationNext.query;
  // To delete special query
  locationNext.query = otherQuery;

  if (rawLocation) {
    return next({
      ...locationNext,
      name: locationNext.name as string,
    });
  }

  const isLocationNextGoToDual = locationNext.path?.startsWith('/dual/');

  if (isOnlyQueryUpdate) {
    switch (isOnlyQueryUpdate) {
      case 'primary': {
        if (locationCurrent.name) {
          next({
            ...locationCurrent,
            name: locationCurrent.name,
            query: {
              ...locationCurrent.query,
              ...locationNext.query,
            },
            replace: Boolean(doReplace),
          });
        }
        return;
      }
      case 'secondary': {
        if (locationCurrent.name) {
          const { pathSecondary } = locationCurrent.query;
          if (pathSecondary && typeof pathSecondary === 'string') {
            const secondaryRoute = router.resolve(decodeURIComponent(pathSecondary)).route;
            const secondaryRouteWithUpdatedQuery = router.resolve({
              ...secondaryRoute,
              name: secondaryRoute.name as string,
              query: {
                ...secondaryRoute.query,
                ...locationNext.query,
              },
            }).route;

            next({
              ...locationCurrent,
              name: locationCurrent.name,
              query: {
                ...locationCurrent.query,
                pathSecondary: encodeURIComponent(secondaryRouteWithUpdatedQuery.fullPath),
              },
              replace: Boolean(doReplace),
            });
          }

          throw Error(`Can't add query to secondary route`);
        }
        return;
      }
    }
  }

  if (!isEnoughScreenWidth) {
    if (isLocationNextGoToDual) {
      const { pathSecondary } = locationNext.query;
      if (pathSecondary && typeof pathSecondary === 'string') {
        next(router.resolve(decodeURIComponent(pathSecondary)).route.fullPath);
        return;
      }

      next(removeDualPrefix(locationNext.path));
      return;
    }

    next();
    return;
  }

  if (!RouterHelper.isAllowDualMode) {
    if (isLocationNextGoToDual) {
      const { pathSecondary } = locationNext.query;
      if (pathSecondary && typeof pathSecondary === 'string') {
        next(router.resolve(decodeURIComponent(pathSecondary)).route.fullPath);
        return;
      }

      next(removeDualPrefix(locationNext.fullPath));
      return;
    }
  }

  if (isLocationNextGoToDual) {
    next();
    return;
  }

  if (RouterHelper.isAllowDualMode && locationNext.name) {
    // @ts-ignore
    const { route: toRoute } = router.resolve(locationNext);

    if (RouterHelper.isRoutePrimary(locationNext.name)) {
      consoleHelpers.warn('NAVIGATE TO PRIMARY');
      return next({
        ...locationNext,
        name: `dual.${locationNext.name}`,
      });
    }

    if (setAsPrimary) {
      if (makePrimaryAsSecondary) {
        return next({
          ...locationNext,
          name: `dual.${locationNext.name}`,
          query: {
            ...locationNext.query,
            pathSecondary: removeDualPrefix(RouterHelper.routePrimary.fullPath),
          },
          replace: Boolean(doReplace),
        });
      }

      return next({
        ...locationNext,
        name: `dual.${locationNext.name}`,
        query: {
          ...locationCurrent.query,
          ...locationNext.query,
        },
        replace: Boolean(doReplace),
      });
    }

    if (notOpenSecondaryTab) {
      const hasSecondaryTab = locationCurrent.query.pathSecondary;
      if (hasSecondaryTab) {
        return next({
          ...locationCurrent,
          name: locationCurrent.name as string,
          query: {
            ...locationCurrent.query,
            pathSecondary: encodeURIComponent(toRoute.fullPath),
          },
        });
      }
      return next({
        ...locationNext,
        name: `dual.${locationNext.name}`,
        query: {
          ...locationNext.query,
          ...locationCurrent.query,
        },
      });
    }

    if (makeSecondaryAsPrimary) {
      const currentSecondaryPage = router.resolve(
        decodeURIComponent(locationCurrent.query.pathSecondary as string),
      );

      if (currentSecondaryPage.route.name) {
        consoleHelpers.warn('makeSecondaryAsPrimary');
        if (`dual.${primaryMustBe}` !== locationCurrent.name) {
          return next({
            ...currentSecondaryPage.route,
            name: `dual.${currentSecondaryPage.route.name}`,
            query: {
              ...router.currentRoute.query,
              pathSecondary: encodeURIComponent(toRoute.fullPath),
            },
          });
        }
      }
    }

    consoleHelpers.warn('NAVIGATE TO NEW SECONDARY');

    if (locationCurrent.name) {
      return next({
        ...locationCurrent,
        name: locationCurrent.name,
        query: {
          ...locationCurrent.query,
          pathSecondary: encodeURIComponent(toRoute.fullPath),
        },
      });
    }

    if (toRoute.meta?.primaryView) {
      return next({
        ...router.currentRoute,
        name: toRoute.meta?.primaryView,
        query: {
          ...router.currentRoute.query,
          pathSecondary: encodeURIComponent(toRoute.fullPath),
        },
      });
    }

    throw Error('Error! Maybe you need to add this route to the primary group');
  }

  const pathWithoutExtraQuery = router.resolve({
    name: locationNext.name as string,
    params: locationNext.params,
    query: locationNext.query,
  }).route.fullPath;
  const wasPathUpdated = locationNext.fullPath !== pathWithoutExtraQuery;
  if (wasPathUpdated) {
    next(pathWithoutExtraQuery);
  } else {
    next();
  }
};

export default splitScreenManager;
