<template>
  <span class="status-online" :class="classOnline">
    <template v-if="isOnline">• Online</template>
    <!--TODO это реальный кейс, что с бекенда может прийти null или undefined?-->
    <template v-else-if="isOnline == null">• ?</template>
    <template v-else-if="wasOnline">
      <span>
        • Offline
        <span class="status-online__offline-since"> since <Datetime :date="wasOnline" /> </span
      ></span>
    </template>
    <template v-else>• Offline</template>
  </span>
</template>

<script>
  import Datetime from '@/uikitProject/dates/Datetime';

  export default {
    name: 'StatusOnline',

    components: {
      Datetime,
    },

    props: {
      isOnline: {
        type: Boolean,
        required: false,
      },
      isWorking: {
        type: Boolean,
        required: false,
      },
      wasOnline: {
        type: [String, Number, Date],
      },
      isSmallerOnAdaptive: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      classOnline() {
        if (this.isWorking) {
          return 'status-online--working';
        }

        return this.isOnline && 'status-online--online';
      },
      classSmallerOnAdaptive() {
        return this.isSmallerOnAdaptive && 'status-online__offline-since--adaptive--small';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .status-online {
    flex: none;
    display: inline-flex;
    align-items: center;
    height: 20px;
    background: $color-bg-offline;
    border-radius: 2px;
    padding: 0 8px;
    color: $color-text-header;
    font-weight: $weight-bold;
    font-size: 13px;

    @include textOverflow;

    &--online {
      background-color: $color-bg-success;
      color: white;
    }

    &--working {
      background-color: $color-bg-process;
      color: white;
    }

    &__offline-since {
      &--adaptive--small {
        @media (max-width: $screen-xs-max) {
          display: none;
        }
      }
    }
  }
</style>
