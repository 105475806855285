<template>
  <Tippy
    :trigger="trigger"
    placement="bottom"
    :duration="[67, 0]"
    arrow
    :isInteractive="canSelectText"
    :isDisabled="isDisabled"
    :popperOptions="popperOptions"
    :isHideOnClickInside="!canSelectText"
    theme="lightblue"
    class="popup-info-with-properties"
    @show="$emit('show')"
    @hide="$emit('hide')"
  >
    <template #trigger>
      <slot />
    </template>
    <template #default>
      <div v-if="data && data.length" class="popup-info-with-properties__popup">
        <template v-for="(item, index) of data">
          <div v-if="item.type === 'title'" :key="index" class="popup-info-with-properties__title">
            {{ item.value }}
          </div>
          <DecoratorProperty v-else :key="index" bgColor="#ecefff" :property="item.name">
            <template v-if="item.isBoolean">
              <IconMaterial :title="item.value ? 'done' : 'close'" />
            </template>
            <template v-else>
              {{ item.value }}
            </template>
          </DecoratorProperty>
        </template>
      </div>
      <div v-else class="popup-info-with-properties__popup">No data!</div>
    </template>
  </Tippy>
</template>

<script>
  import Tippy from '@/uikitBase/popups/vueTippy/Tippy';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import DecoratorProperty from '@/uikitProject/decorators/DecoratorProperty';

  export default {
    name: 'PopupInfoWithProperties',

    components: {
      DecoratorProperty,
      IconMaterial,
      Tippy,
    },

    props: {
      data: {
        type: Array,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      canSelectText: {
        type: Boolean,
        default: true,
      },
      trigger: {
        type: String,
        default: 'click',
        validator(value) {
          const availableValues = ['click', 'mouseenter'];
          return availableValues.includes(value);
        },
      },
    },

    computed: {
      popperOptions() {
        const pageContentContainer = document.querySelector('.main-wrapper');

        return {
          modifiers: {
            preventOverflow: {
              boundariesElement: pageContentContainer,
              enabled: true,
            },
          },
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .popup-info-with-properties {
    display: flex;
    align-items: center;

    &__popup {
      padding: 8px 12px;
      text-align: left;
      overflow: hidden;
      min-width: 300px;
      max-width: 500px;
    }

    &__title {
      margin: 7px 0;
    }
  }
</style>
