<template>
  <Select
    v-if="isInitialized"
    ref="select"
    :itemSelected="privateCompound"
    :items="sortedCompounds"
    labelProp="name"
    :hasSearch="true"
    :padding="padding"
    :placeholderSearch="placeholder"
    :titleBottomSheet="placeholder"
    class="select-compound"
    :class="{
      'select-compound--transparent': isTransparent,
    }"
    :canSearchById="true"
    :isTransparent="isTransparent"
    :isDisabled="isDisabled"
    :canCreate="canCreate"
    :canCreateIfNotFound="false"
    :messageNoItemSelected="messageNoItemSelected"
    messageCreate="This compound is not found. Click here to save!"
    @update:itemSelected="selectedItemChanged"
    @search="getListItemsDebounced"
    @showPopup="handleShowPopup"
    @hidePopup="handleHidePopup"
    @create="createCompound"
  />
</template>

<script>
  import { debounce } from 'lodash';
  import Select from '@/uikitBase/selects/vueSelect/Select';
  import { apiCompounds } from '@/api/graphql/cloud/compounds';

  const SEARCH_DELAY = 200;

  const ModelEvent = 'change';
  const CloseEvent = 'close';

  export default {
    name: 'CompoundSelector',

    components: {
      Select,
    },

    model: {
      prop: 'compound',
      event: ModelEvent,
    },

    props: {
      compound: {
        type: Object,
        default: null,
      },
      compoundId: {
        type: Number,
        default: null,
      },
      sequenceId: {
        type: Number,
        default: null,
      },
      placeholder: {
        type: String,
        default: 'Search compound',
      },
      compoundIdsToHide: {
        type: Array,
      },
      isTransparent: {
        type: Boolean,
        default: false,
      },
      padding: {
        type: String,
        default: 'md',
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      isEditable: {
        type: Boolean,
        default: false,
      },
      preloadedCompound: {
        type: Object,
      },
      messageNoItemSelected: {
        type: String,
      },
    },

    data: () => ({
      isSearchActive: false,
      searchQuery: null,
      items: [],

      getListItemsDebounced: null,

      isSelectOpened: false,

      isInitialized: false,
    }),

    computed: {
      privateCompound() {
        return (
          this.compoundsToShow.find((item) => item.id === (this.compoundId ?? this.compound?.id)) ??
          this.compound ??
          null
        );
      },

      compoundsToShow() {
        if (this.preloadedCompound) {
          const isPreloadedCompoundAlreadyInList = this.items.some(
            (compound) => compound.id === this.preloadedCompound.id,
          );
          if (!isPreloadedCompoundAlreadyInList) {
            return [...this.items, this.preloadedCompound];
          }
        }

        return this.items;
      },

      sortedCompounds() {
        return this.privateCompound
          ? [...this.compoundsToShow].sort((compound) => {
              return compound === this.privateCompound ? -1 : 1;
            })
          : this.compoundsToShow;
      },

      canCreate() {
        return (
          this.searchQuery?.length > 0 &&
          this.compoundsToShow.find(
            (item) => item.name.toLowerCase() === this.searchQuery.toLowerCase(),
          ) == null
        );
      },
    },

    created() {
      // / NOTE: using hash instead of searching in array
      this.itemsHash = {};

      this.getListItemsDebounced = debounce(this.search, SEARCH_DELAY);

      this.search(null);
    },

    methods: {
      activate() {
        this.$refs.select.focus();
      },

      onClose() {
        this.$emit(CloseEvent);
      },

      selectedItemChanged(item) {
        this.$emit(ModelEvent, item);
      },

      async search(query) {
        const searchingFirstTime =
          query == null && this.searchQuery == null && this.items.length === 0;

        this.isSearchActive = !searchingFirstTime;
        this.searchQuery = query;

        try {
          const compounds = await apiCompounds.getCompounds(this.searchQuery, this.sequenceId);

          if (this.searchQuery !== query) return;
          this.isSearchActive = false;

          this.items = compounds;

          this.isInitialized = true;
        } catch {
          if (this.searchQuery !== query) return;
          this.isSearchActive = false;
          this.clearItems();
        }
      },

      clearItems() {
        this.itemsHash = {};
        this.items = [];
      },

      handleShowPopup() {
        this.isSelectOpened = true;
        this.$emit('showPopup');
      },
      handleHidePopup() {
        this.isSelectOpened = false;
        this.$emit('hidePopup');
      },

      async createCompound(compoundName) {
        try {
          const compound = await apiCompounds.createCompound(compoundName);

          this.items = [...this.items, compound];
          this.selectedItemChanged(compound);
        } catch {
          this.notifyError('Can not create a new compound');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .select-compound {
    width: 100%;
    color: $color-text-default;

    ::v-deep .select {
      height: 34px;
      background-color: rgba(0, 0, 0, 0.06);
    }

    &--transparent {
      ::v-deep .select {
        background-color: transparent;
      }
    }

    ::v-deep input {
      text-indent: 0 !important;
    }

    &__id {
      color: $color-text-third;

      &--selected {
        color: white;
      }
    }

    &__favorite {
      margin-right: 10px;

      &--selected {
        color: white;
      }
    }

    &__btn-edit {
      margin-left: 5px;

      &--selected {
        color: white;
      }
    }
  }
</style>
