import _ from 'lodash';
import { POSTPROCESSING_TEMPLATE_EMPTY } from '@/constants/postprocessingTemplates/presets';
import { PostprocessingTemplatesApi } from '@/api/postprocessingTemplates';
import { throwError } from '@/utils/errorHelpers';

type PostprocessingTemplate = any;

// const MOCK_DEFAULT_VALUES = {
//   algorithm_base: 'horizontal_correction',
//   baseline_correction_anchor_point: 'finish',
//   algorithm_binj: 'none',
//   algorithm_pdet: 'default',
//   algorithm_peak: 'peak_fitting_v1',
//   peak_integration_quality_threshold: 0.95,
//   algorithm_nois: 'wavelet_v1',
//   peak_detection_min_width: 5,
//   peak_detection_min_prominence: 5,
// };

export const POSTPROCESSING_TEMPLATE_MAP = {
  diagonal_correction: 'Diagonal correction',
  'Diagonal correction': 'diagonal_correction',

  horizontal_correction: 'Horizontal correction',
  'Horizontal correction': 'horizontal_correction',

  manual: 'Manual',
  Manual: 'manual',

  simple_subtraction: 'Simple subtraction',
  'Simple subtraction': 'simple_subtraction',

  default: 'Default',
  Default: 'default',

  peak_fitting_v1: 'Peak Fitting v1',
  'Peak Fitting v1': 'peak_fitting_v1',

  wavelet_v1: 'Wavelet v1',
  'Wavelet v1': 'wavelet_v1',

  start: 'Start',
  Start: 'start',

  median: 'Median',
  Median: 'median',

  finish: 'Finish',
  Finish: 'finish',

  none: 'None',
  None: 'none',
} as const;

let defaultValues;
export const getPostprocessingTemplateWithDefaultValues = async (postprocessingTemplate) => {
  defaultValues ??= Object.fromEntries(
    Object.entries(await PostprocessingTemplatesApi.getDefaultValues()).map(([key, value]) => [
      key,
      POSTPROCESSING_TEMPLATE_MAP[String(value)] ?? value,
    ]),
  );

  console.warn('defaultValues', defaultValues);

  const methodWithDefaultValues = {
    ...POSTPROCESSING_TEMPLATE_EMPTY,
    ...defaultValues,
    ...postprocessingTemplate,
    defaultValues,
  };

  return methodWithDefaultValues;
};

const throwUnknownPostprocessingValue = () => throwError('Unknown postprocessing value');

export const parsePostprocessingTemplate = ({
  algorithm_base,
  algorithm_binj,
  algorithm_pdet,
  algorithm_peak,
  algorithm_nois,
  baseline_correction_anchor_point,
  ...postprocessingTemplate
}: PostprocessingTemplate) => {
  return {
    ...postprocessingTemplate,
    algorithm_base:
      POSTPROCESSING_TEMPLATE_MAP[algorithm_base] ?? throwUnknownPostprocessingValue(),
    algorithm_binj:
      POSTPROCESSING_TEMPLATE_MAP[algorithm_binj] ?? throwUnknownPostprocessingValue(),
    algorithm_pdet:
      POSTPROCESSING_TEMPLATE_MAP[algorithm_pdet] ?? throwUnknownPostprocessingValue(),
    algorithm_peak:
      POSTPROCESSING_TEMPLATE_MAP[algorithm_peak] ?? throwUnknownPostprocessingValue(),
    algorithm_nois:
      POSTPROCESSING_TEMPLATE_MAP[algorithm_nois] ?? throwUnknownPostprocessingValue(),
    baseline_correction_anchor_point:
      POSTPROCESSING_TEMPLATE_MAP[baseline_correction_anchor_point] ??
      baseline_correction_anchor_point,
  };
};

export const preparePostprocessingTemplateForRequest = (
  _postprocessingTemplate: PostprocessingTemplate,
) => {
  const postprocessingTemplate = {
    ...POSTPROCESSING_TEMPLATE_EMPTY,
    ..._.cloneDeep(_postprocessingTemplate),
  };

  // postprocessingTemplate.uuid ??= generateUuidv4();

  delete postprocessingTemplate.defaultValues;
  delete postprocessingTemplate.id;
  delete postprocessingTemplate.baseline_correction_points;

  postprocessingTemplate.name = postprocessingTemplate.name?.trim() || 'Default name';
  postprocessingTemplate.algorithm_base =
    postprocessingTemplate.algorithm_base === 'manual'
      ? 'horizontal_correction'
      : postprocessingTemplate.algorithm_base;

  postprocessingTemplate.detection_time_start_sec =
    postprocessingTemplate.detection_time_start_min != null
      ? Math.round(postprocessingTemplate.detection_time_start_min * 60)
      : undefined;
  postprocessingTemplate.detection_time_end_sec =
    postprocessingTemplate.detection_time_end_min != null
      ? Math.round(postprocessingTemplate.detection_time_end_min * 60)
      : undefined;

  return parsePostprocessingTemplate(postprocessingTemplate);
};
