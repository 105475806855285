<template>
  <div class="chromatograms-compare">
    <div class="chromatograms-compare__modes">
      <Checkbox
        v-if="measurementList.length > 1"
        v-model="isOverlayMode"
        class="chromatograms-compare__overlay-mode"
        >Overlay mode</Checkbox
      >
      <br />
      <Checkbox
        v-if="measurementList.length"
        v-model="isFullHeightMode"
        class="chromatograms-compare__full-height-mode"
        >Full height mode</Checkbox
      >
    </div>

    <div
      v-if="measurementList.length > 1 && isOverlayMode"
      class="chromatograms-compare__mode-overlay"
    >
      <div class="chromatograms-compare__measurement-list">
        <template v-for="m of measurementList">
          <div :key="m.id" class="chromatograms-compare__measurement">
            <div class="chromatograms-compare__wrapper-link-with-color">
              <span class="chromatograms-compare__wrapper-link">
                <InjectionLinkComponent :isOpenEntityAsSecondary="true" :injection="m.injection" />
                <span class="chromatograms-compare__channel"
                  >Channel: {{ m.name }} <template v-if="m.meta">{{ m.meta.nm }}nm</template></span
                >
              </span>
              <div
                class="chromatograms-compare__color-assigned"
                :style="{ backgroundColor: measurementColors[m.id] }"
              ></div>
            </div>
            <div class="chromatograms-compare__actions">
              <Btn
                v-if="!shownDetails[m.id]"
                class="chromatograms-compare__btn-show-details"
                @click="showDetails(m.id)"
                >Show details</Btn
              >
              <Btn
                v-else
                class="chromatograms-compare__btn-show-details"
                @click="hideDetails(m.id)"
              >
                Hide details
              </Btn>
              <Btn
                class="chromatograms-compare__btn-delete chromatograms-compare__btn-delete--in-overlay"
                @click="remove(m.id)"
              >
                <i class="material-icons material-icon--16">delete</i>
              </Btn>
            </div>
          </div>
          <DeviceMethodColumnComponent
            v-if="shownDetails[m.id]"
            :key="m.id"
            :device="m.injection.device"
            :vial="m.injection.vial"
            :method="m.injection.method"
            :column="m.injection.column"
            :sample="m.injection.sample"
            :injectionId="m.injection.id"
            class="chromatograms-compare__details"
          />
        </template>
      </div>

      <ChartChromatogram
        v-if="measurementList.length <= 15"
        ref="chartOverlay"
        :measurements="measurementList"
        :bounds="bounds"
        :timeMax="bounds.timeMax"
        :hasToolbar="false"
        :applyBaseline="true"
        :doUseColorPreset="true"
        :doSkipDataFetching="true"
        :hasMauScale="!isFullHeightMode"
        :hasSpacesIfOutOfDetectionTime="true"
        :detectionTime="detectionTime"
        :measurementColors="measurementColors"
      />

      <div v-else>
        The overlay chart can't be displayed. <br />
        So many measurements are not supported.
      </div>
    </div>

    <transition-group v-else name="fade-height" tag="p">
      <div v-for="(m, index) in measurementList" :key="m.id" style="margin-bottom: 32px">
        <div class="chromatograms-compare__measurement">
          <span class="chromatograms-compare__wrapper-link">
            <InjectionLinkComponent
              :isOpenEntityAsSecondary="true"
              :injection="m.injection"
              style="flex-grow: 1"
            />
            <span class="chromatograms-compare__channel"
              >Channel: {{ m.name }} <template v-if="m.meta">{{ m.meta.nm }}nm</template></span
            >
          </span>
          <div class="chromatograms-compare__actions">
            <Btn
              v-if="!shownDetails[m.id]"
              class="chromatograms-compare__btn-show-details"
              @click="showDetails(m.id)"
              >Show details
            </Btn>
            <Btn v-else class="chromatograms-compare__btn-show-details" @click="hideDetails(m.id)">
              Hide details
            </Btn>
            <Btn @click="remove(m.id)">
              <i class="material-icons material-icon--16">delete</i>
            </Btn>
          </div>
        </div>
        <DeviceMethodColumnComponent
          v-if="shownDetails[m.id]"
          :key="m.id"
          :device="m.injection.device"
          :vial="m.injection.vial"
          :method="m.injection.method"
          :column="m.injection.column"
          :sample="m.injection.sample"
          :injectionId="m.injection.id"
          class="chromatograms-compare__details"
        />
        <ChartChromatogram
          ref="charts"
          :measurements="[m]"
          :bounds="bounds"
          :timeMax="bounds.timeMax"
          :hasToolbar="false"
          :applyBaseline="true"
          :doShowExactBounds="!isFullHeightMode"
          :hasSpacesIfOutOfDetectionTime="true"
          :detectionTime="detectionTime"
          :creationDate="new Date(m.injection.created)"
          @zoom="syncZoom(index, $event)"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
  import _ from 'lodash';
  import ChartChromatogram from '@/components/blocks/charts/chromatogram/ChartChromatogram.vue';
  import ChromatogramsCompare from '@/services/ChromatogramsCompare.ts';
  import InjectionLinkComponent from 'components/element/InjectionLinkComponent.vue';
  import Checkbox from '@/uikitBase/switchers/Checkbox';
  import {
    CALIBRATION_VALUE,
    LOST_VALUE,
    MAGIC_VALUES_ARE_NOT_SUPPORTED_AFTER_UNIXTIME_MS,
    THRESHOLD_FOR_VALUES_THAT_UNSUPPORTED_IN_OLD_MEASUREMENTS,
  } from 'utils/chart/chart-constants';
  import ChromatogramHelper from '@/components/blocks/charts/chromatogram/private/ChromatogramHelper';
  import DeviceMethodColumnComponent from '@/components/element/DeviceMethodColumnComponent.vue';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import { apiMeasurements } from '@/api/graphql/cloud/measurements';

  const MEASUREMENT_PALETTE_SM = [
    '#1e90ff',
    '#00ff00',
    '#40e0d0',
    '#ffd700',
    '#c71585',
    '#0000ff',
    '#ff0000',
    '#000000',
  ];

  const MEASUREMENT_PALETTE_MD = [
    '#000080',
    '#ff1493',
    '#ff00ff',
    '#0000ff',
    '#00ffff',
    '#00fa9a',
    '#00ff00',
    '#ffd700',
    '#8b4513',
    '#006400',
    '#ffa500',
    '#4b0082',
    '#1e90ff',
    '#ff0000',
    '#000000',
  ];

  const isSpecialValue = (value) =>
    LOST_VALUE === value ||
    CALIBRATION_VALUE === value ||
    value >= THRESHOLD_FOR_VALUES_THAT_UNSUPPORTED_IN_OLD_MEASUREMENTS;

  export default {
    name: 'ChromatogramsCompare',

    components: {
      Btn,
      DeviceMethodColumnComponent,
      Checkbox,
      InjectionLinkComponent,
      ChartChromatogram,
    },

    props: {
      ids: {
        type: Array,
        default: null,
      },
    },

    data() {
      return {
        measurements: {},

        isOverlayMode: false,
        isFullHeightMode: false,

        palette: this.createPalette(this.ids?.length),
        colorsAssigned: {},

        shownDetails: {},
      };
    },

    computed: {
      bounds() {
        const measurements = this.measurementList;

        const defaultMinMaxValues = {
          mauMaxValues: [],
          mauMinValues: [],
          timeMaxValues: [],
        };

        const minMaxValues =
          measurements?.reduce((bounds, measurement) => {
            const dataWithoutSpecialValues =
              new Date(measurement.injection.created).getTime() <
              MAGIC_VALUES_ARE_NOT_SUPPORTED_AFTER_UNIXTIME_MS
                ? measurement.data.filter((point) => !isSpecialValue(point))
                : measurement.data;

            const data =
              (measurement.baseline?.length
                ? dataWithoutSpecialValues.map(
                    (point, index) => Number(point) - Number(measurement.baseline[index]),
                  )
                : dataWithoutSpecialValues) ?? [];
            const peaksMau =
              measurement.peaks?.reduce((data, peak) => {
                data.push(peak.start_mau, peak.end_mau);
                return data;
              }, []) ?? [];
            bounds.mauMaxValues.push(Math.max(...data, ...peaksMau));
            bounds.mauMinValues.push(Math.min(...data, ...peaksMau));
            bounds.timeMaxValues.push(measurement.data_length / measurement.mps / 60);
            return bounds;
          }, defaultMinMaxValues) ?? defaultMinMaxValues;

        const bounds = {
          timeMax: Math.max(...minMaxValues.timeMaxValues),
        };

        if (!this.isFullHeightMode) {
          const mauMaxValue = Math.max(...minMaxValues.mauMaxValues);
          const mauMinValue = Math.min(...minMaxValues.mauMinValues);
          const range = mauMaxValue - mauMinValue;
          const offset = range * 0.1;

          bounds.mauMin = mauMinValue - offset;
          bounds.mauMax = mauMaxValue + offset;
        }

        return bounds;
      },

      measurementColors() {
        return this.measurementList.reduce((measurementColors, measurement) => {
          const color = this.colorsAssigned[measurement.id];

          if (!color && this.palette.length) {
            this.colorsAssigned[measurement.id] = this.palette.pop();
          }

          measurementColors[measurement.id] = this.colorsAssigned[measurement.id] ?? null;

          return measurementColors;
        }, {});
      },

      measurementList() {
        if (this.isOverlayMode && this.isFullHeightMode) {
          const mauDifferences = [];

          const measurements = Object.values(this.measurements).map((measurement) => {
            const data = ChromatogramHelper.getModifiedData({
              data: measurement.data,
              // baseline: [],
              isApplyBaseline: false,
              isSupportMagicValues:
                new Date(measurement.injection.created).getTime() <
                MAGIC_VALUES_ARE_NOT_SUPPORTED_AFTER_UNIXTIME_MS,
            });

            const mauMin = Math.min(...data);
            const mauMax = Math.max(...data);
            const mauDifference = mauMax - mauMin;

            mauDifferences.push(mauDifference);

            return {
              ...measurement,
              data,
              baseline: null,
              mauMin,
              mauMax,
              mauDifference,
              creationDate: new Date(measurement.injection.created),
            };
          });

          const maxMauDifference = Math.max(...mauDifferences);

          const scaledMeasurements = measurements.map((measurement) => {
            const _measurement = { ...measurement };

            if (_measurement.mauDifference < maxMauDifference) {
              const scaleFactor = maxMauDifference / _measurement.mauDifference;

              if (isFinite(scaleFactor) && !isNaN(scaleFactor)) {
                _measurement.data = _measurement.data.map(
                  (point) => point * scaleFactor - measurement.mauMin * scaleFactor,
                );
              }
            } else {
              _measurement.data = _measurement.data.map((point) => point - measurement.mauMin);
            }

            return _measurement;
          });

          return scaledMeasurements;
        }

        return Object.values(this.measurements);
      },

      detectionTime() {
        const detectionRunTimeList = this.measurementList.reduce(
          (acc, measurement) => {
            acc.start.push(
              measurement.detectionTime?.start_sec ? measurement.detectionTime.start_sec / 60 : 0,
            );

            const measurementTimeMax = measurement.expected_data_length / measurement.mps / 60;
            acc.end.push(
              measurement.detectionTime?.end_sec
                ? measurement.detectionTime.end_sec / 60
                : measurementTimeMax,
            );

            return acc;
          },
          {
            start: [],
            end: [],
          },
        );

        return {
          start: Math.min(...detectionRunTimeList.start),
          end: Math.max(...detectionRunTimeList.end),
        };
      },
    },

    watch: {
      ids(ids) {
        this.updateMeasurements(ids);
      },
      isFullHeightMode() {
        this.$refs.charts?.forEach((chart) => chart.resetZoom());
      },
      async measurementList(listNew, listOld) {
        // TODO Bad decision. Fix it in the future!
        // All is working, but code can be simplified
        await this.$nextTick();
        await this.$nextTick();

        if (listNew.length > 8 && listOld.length && listOld.length <= 8) {
          this.notify('Colors have been changed to be more distinctive!', 5);
          this.resetColors(listNew.length);
        }

        this.$refs.chartOverlay?.resetZoom();
        this.$refs.charts?.forEach((chart) => chart.resetZoom());
      },
    },

    mounted() {
      if (this.ids == null) {
        this.updateRouter(ChromatogramsCompare.getIDs());
      }
      this.updateMeasurements(this.ids);

      this.updateRouter = this.updateRouter.bind(this);
      ChromatogramsCompare.subscribe(this.updateRouter);
    },
    beforeDestroy() {
      ChromatogramsCompare.unsubscribe(this.updateRouter);
    },

    methods: {
      syncZoom(initiatorChartIndex, { pStart, pFinish }) {
        this.$refs.charts?.forEach(
          (chart, index) =>
            Number(initiatorChartIndex) !== index && chart.zoom({ pStart, pFinish }),
        );
      },
      remove(id) {
        if (this.ids == null) return;
        const ids = [...this.ids];
        ids.splice(ids.indexOf(id), 1);

        if (this.measurementColors[id]) {
          this.palette.push(this.measurementColors[id]);
          this.colorsAssigned[id] = null;
        }

        ChromatogramsCompare.save(ids);
      },
      async updateMeasurements(ids) {
        Object.keys(this.measurements).forEach((id) => {
          if (!_.includes(ids, parseInt(id, 10))) {
            this.$delete(this.measurements, id);
          }
        });

        if (ids) {
          const measurementsWithUlid = await apiMeasurements.getMeasurementsWithUlid(ids);

          const measurements = await Promise.all(
            measurementsWithUlid.map(({ id, ulid }) =>
              apiMeasurements.getMeasurementForCompare(id, ulid),
            ),
          );

          this.measurements = Object.fromEntries(
            measurements.map((measurement) => [measurement.id, measurement]),
          );
        }
      },

      updateRouter(ids) {
        const q = { name: 'chromatograms compare' };
        if (ids != null && ids.length > 0) {
          q.query = { ids: ids.join('.') };
        }
        this.$router.push(q).catch(() => {
          // stub
        });
      },

      createPalette(colorsNumber = 0) {
        if (colorsNumber <= 8) {
          return MEASUREMENT_PALETTE_SM;
        }
        if (colorsNumber <= 15) {
          return MEASUREMENT_PALETTE_MD;
        }

        this.notifyError('To many charts for comparing!');
      },
      resetColors(colorsNumber) {
        this.palette = this.createPalette(colorsNumber);
        this.colorsAssigned = {};
      },

      showDetails(measurementId) {
        this.shownDetails = {
          ...this.shownDetails,
          [measurementId]: true,
        };
      },
      hideDetails(measurementId) {
        this.shownDetails = {
          ...this.shownDetails,
          [measurementId]: false,
        };
      },
    },
  };
</script>

<style>
  .chromatograms-compare .chromatogram {
    padding: 0;
  }
</style>

<style lang="scss" scoped>
  .chromatograms-compare {
    &__mode-overlay {
      display: flex;
      flex-direction: column;
    }

    &__measurement-list {
      align-self: flex-start;
      display: grid;
      max-width: 100%;
      grid-template-columns: 100%;
    }

    &__measurement {
      display: flex;
      width: 100%;
      margin-bottom: 16px;
      position: relative;
      justify-content: space-between;
      align-items: center;
      z-index: 1;

      @media (max-width: $screen-sm-max) {
        flex-direction: column;
        align-items: start;
      }
    }

    &__actions {
      display: flex;
      align-items: center;

      @media (max-width: $screen-sm-max) {
        margin-top: 10px;
      }
    }

    &__btn-show-details {
      margin-right: 10px;
    }

    &__modes,
    &__measurement {
      @media (max-width: $screen-md-max) {
        padding: 0 1rem;
      }
    }

    &__overlay-mode,
    &__full-height-mode {
      margin-bottom: 20px;
    }

    &__wrapper-link-with-color {
      margin-right: 15px;
    }

    &__color-assigned {
      width: 200px;
      height: 2px;
      margin-top: 5px;
      border-radius: $border-radius__sm;
    }

    &__btn-delete {
      &--in-overlay {
        height: 32px;
      }
    }

    &__details {
      margin-bottom: 20px;

      @media (max-width: $screen-md-max) {
        margin: 0 1rem 20px;
      }
    }

    &__wrapper-link {
      margin-right: 10px;
    }

    &__channel {
      margin-left: 16px;
      color: $color-text-second;
    }
  }
</style>
