<template>
  <div class="table-cell">
    <component
      :is="cellContentComponent"
      :value="data.value"
      :isUnique="data.isUnique"
      v-bind="$attrs"
      :isDisabled="isDisabled"
      v-on="$listeners"
    />
  </div>
</template>

<script>
  import InputTime from './private/inputs/InputTime';
  import InputPercent from './private/inputs/InputPercent';
  import CompoundAutosuggest from './private/CompoundAutosuggest';
  import InputResponse from './private/inputs/InputResponse';
  import UnknownSwitchCaseError from 'utils/errors/UnknownSwitchCaseError.ts';

  export default {
    name: 'TableCell',

    inheritAttrs: false,

    props: {
      data: {
        type: Object,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      cellContentComponent() {
        switch (this.data.type) {
          case 'percent':
            return InputPercent;
          case 'time':
            return InputTime;
          case 'compound':
            return CompoundAutosuggest;
          case 'response':
            return InputResponse;
          // case 'text':
          //   return InputText;
          default:
            throw new UnknownSwitchCaseError();
        }
      },
    },
  };
</script>
