<template>
  <label
    v-show="(copyValue != null && copyValue !== '') || (isEditMode === true && canEdit)"
    class="label-block-margin model-textarea-component"
    @click="$emit('click')"
  >
    <span @click="edit">{{ label }}</span>
    <textarea
      v-show="isEditMode && canEdit"
      ref="textarea"
      v-model="newValue"
      style="line-height: 1.33"
      class="model-textarea-component__textarea"
      v-on:keydown.ctrl.13="save"
      v-on:focusout="focusOut"
    />
    <div
      v-show="!isEditMode"
      ref="div"
      style="
        font-size: 13px;
        color: black;
        line-height: 1.33;
        white-space: pre-line;
        word-break: break-word;
      "
      @click="edit"
      v-html="copyValue"
    />
  </label>
</template>

<script>
  const EVENT_EDIT = 'edit';
  const EVENT_BLUR = 'blur';

  export default {
    name: 'ModelTextareaComponent',
    model: {
      prop: 'value',
      event: 'update:value',
    },
    props: {
      value: String,
      label: String,
      canEdit: {
        type: Boolean,
        default: true,
      },
      isEditMode: {
        type: Boolean,
      },
    },
    data() {
      return {
        copyValue: this.value,
        newValue: this.value,
      };
    },
    watch: {
      value(nv) {
        if (this.isEditMode === false) {
          this.copyValue = nv;
          this.newValue = nv;
        }
      },

      isEditMode: {
        async handler() {
          if (!this.canEdit) {
            return;
          }

          this.newValue = this.copyValue;

          if (this.value?.length) {
            await this.$nextTick();
            this.$refs.textarea.style.height = `${Math.max(
              64,
              this.$refs.div.getBoundingClientRect().height + 24,
            )}px`;
          }
        },
        immediate: true,
      },
    },
    methods: {
      edit() {
        if (this.canEdit) {
          this.$emit(EVENT_EDIT);
        }
      },

      save() {
        this.copyValue = this.newValue;
        this.$emit('update:value', this.copyValue);
      },

      focusOut() {
        this.$emit(EVENT_BLUR);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .model-textarea-component {
    &__textarea {
      min-height: 32px;
      height: 32px;
    }
  }
</style>
