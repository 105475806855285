export const INJECTION_STATES = {
  NEW: 'NEW', // Brand new injection, that was never manipulated
  PENDING: 'PEN', // Pending response from the box

  PREPARING: 'PRE', // Preparing before recording will start
  RUNNING: 'RUN',
  CONNECTION_LOST: '-',
  FINISHING: 'FIN',

  READY: 'REA',
  FAILED: 'FAI',

  WORKING: 'WOR',
  ACCEPTED: 'ACC',
  REJECTED: 'REJ',

  // Exists only on the frontend
  NO_INTERNET: '@',
} as const;
