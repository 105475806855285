<template>
  <label class="input-form">
    <div v-if="title" class="input-form__placeholder">{{ title }}</div>

    <ValidationProvider
      ref="ValidationProvider"
      #default="{ errors, dirty, touched }"
      :rules="rules"
      :name="name"
    >
      <Textarea
        v-if="isMultiline"
        v-bind="$attrs"
        ref="input"
        v-model="_value"
        :isDarken="isDarken"
        :placeholder="placeholder"
        v-on="$listeners"
      />
      <Input
        v-else
        v-bind="$attrs"
        ref="input"
        v-model="_value"
        :isDarken="isDarken"
        v-on="$listeners"
      />

      <div v-if="errors.length && dirty && touched" class="input-form__error">
        {{ errors[0] }}
      </div>
    </ValidationProvider>
  </label>
</template>

<script>
  import Input from '@/uikitBase/inputs/Input';
  import Textarea from '@/uikitBase/inputs/Textarea';

  const EVENT_MODEL = 'update:value';

  export default {
    name: 'InputForm',

    components: { Textarea, Input },

    inheritAttrs: false,

    model: {
      props: 'value',
      event: EVENT_MODEL,
    },

    props: {
      title: {
        type: String,
      },
      placeholder: {
        type: String,
      },
      value: {
        type: [Number, String],
        required: true,
      },
      isDarken: {
        type: Boolean,
      },
      mode: {
        type: String,
        default: 'eager',
      },
      rules: {
        type: [String, Object],
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
      isMultiline: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      _value: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit(EVENT_MODEL, value);
        },
      },
    },

    methods: {
      focus() {
        this.$refs.input.focus();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .input-form {
    display: inline-block;

    &__placeholder {
      font-size: 13px;
      color: $color-text-third;
      margin-bottom: 3px;
    }

    &__error {
      margin-top: 3px;
      color: $color-text-danger;
    }
  }
</style>
