<template>
  <transition :css="false" @beforeEnter="onBeforeEnter" @enter="onEnter" @leave="onLeave">
    <slot />
  </transition>
</template>

<script>
  import anime from 'animejs/lib/anime.es.js';

  export default {
    name: 'TransitionExpand',

    props: {
      animationTimeMs: {
        type: Number,
        default: 300,
      },
    },

    methods: {
      onBeforeEnter(element) {
        element.style.opacity = 0;
        element.style.height = 0;
      },
      onEnter(element, done) {
        anime({
          targets: element,
          keyframes: [
            {
              opacity: 0,
              height: element.scrollHeight * 0.8,
              easing: 'linear',
              duration: this.animationTimeMs * 0.8,
            },
            { opacity: 1, height: element.scrollHeight },
          ],
          duration: this.animationTimeMs,
          easing: 'easeOutSine',
          complete: () => {
            element.style.opacity = null;
            element.style.height = null;
            done();
          },
        });
      },
      onLeave(element, done) {
        anime({
          targets: element,
          keyframes: [
            { opacity: 0 },
            {
              height: 0,
              duration: this.animationTimeMs * 0.8,
            },
          ],
          duration: this.animationTimeMs,
          easing: 'easeInSine',
          complete: done,
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  * {
    will-change: height;
    backface-visibility: hidden;
  }
</style>
