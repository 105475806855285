import { makeGraphqlRequestCloud } from 'api/graphql/graphQLClient';
import { SequenceReportData } from '@/graphql/cloud/sequences/queries/SequenceReportData.graphql';
import { convertNullValuesToUndefined } from '@/utils/objectHelpers';
import { CreateOrUpdateSequencePostprocessing } from '@/graphql/cloud/sequences/mutations/CreateOrUpdateSequencePostprocessing.graphql';

export const apiSequences = {
  async getSequenceReportData(id) {
    const { sequence: sequenceReportData } = await makeGraphqlRequestCloud(SequenceReportData, {
      id,
    });

    if (!sequenceReportData) {
      throw new Error('Sequence report data was not received!');
    }

    return sequenceReportData;
  },
  async createOrUpdateSequencePostprocessing(sequencePostprocessingPayload) {
    const {
      algorithm_base,
      algorithm_binj,
      algorithm_peak,
      detection_time_start_sec,
      detection_time_end_sec,
      blank_injection_measurement_ulid,
      blank_injection_measurement_id,
      peak_detection_min_height,
      peak_detection_min_distance,
      peak_detection_min_width,
      peak_detection_min_prominence,
      peak_integration_quality_threshold,
      baseline_correction_anchor_point,
      baseline_correction_points,
      ..._postprocessingTemplatePayload
    } = sequencePostprocessingPayload;

    const {
      sequencePostprocessing: { uuid },
    } = await makeGraphqlRequestCloud(CreateOrUpdateSequencePostprocessing, {
      ..._postprocessingTemplatePayload,
      algorithm_base,
      algorithm_binj,
      algorithm_peak,
      params_pdet: convertNullValuesToUndefined({
        peak_detection_min_distance,
        peak_detection_min_width,
        peak_detection_min_prominence,
        peak_detection_min_height,
      }),
      params_peak:
        algorithm_peak === 'none'
          ? {}
          : convertNullValuesToUndefined({
              peak_integration_quality_threshold,
            }),
      params_base:
        algorithm_base === 'manual'
          ? convertNullValuesToUndefined({
              baseline_correction_points,
            })
          : algorithm_base === 'horizontal_correction'
          ? convertNullValuesToUndefined({
              baseline_correction_anchor_point,
            })
          : {},
      params_binj:
        algorithm_binj === 'none'
          ? {}
          : convertNullValuesToUndefined({
              blank_injection_measurement_ulid,
              blank_injection_measurement_id,
            }),
      params_nois: {},
      params_dtim: convertNullValuesToUndefined({
        detection_time_start_sec,
        detection_time_end_sec,
      }),
    });

    return uuid;
  },
};
