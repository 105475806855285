import { REST } from 'utils/rest';

const url = '/api/search/';

export default class SearchAPI {
  static search(
    type,
    query,
    search_description,
    deviceId,
    archive,
    start,
    end,
    onSuccess,
    onError,
  ) {
    const params = [];

    if (type) params.push(`type=${type}`);

    if (query) params.push(`query=${query}`);

    if (search_description) params.push(`search_description=${search_description}`);

    if (deviceId) params.push(`device_id=${deviceId}`);

    if (archive) params.push(`archive=${archive}`);

    if (start) params.push(`start=${start}`);

    if (end) params.push(`end=${end}`);

    let resultUrl = url;
    if (params.length > 0) {
      resultUrl += `?${params.join('&')}`;
    }

    return REST.get(resultUrl, onSuccess, onError);
  }
}
