export const GENERAL_ERRORS = {
  HARDWARE_FIRMWARE_ERROR: 'Not supported hardware firmware',
  HARDWARE_NOT_RESPONDING_ERROR: "Hardware connected but doesn't respond",
};

export const AUTOSAMPLER_ERRORS = {
  /* AlltestaAutosampler */
  SYRINGE_ERROR: 'Syringe error',
  SYRINGE_CALIBRATION: 'Syringe calibration',
  NEEDLE_ERROR: 'Needle error',
  NEEDLE_CALIBRATION: 'Needle calibration',
  VALVE_ERROR: 'Valve error',
  VALVE_CALIBRATION: 'Valve calibration',
  TRAY_ERROR: 'Tray error',
  TRAY_CALIBRATION: 'Tray calibration',
  ARM_ERROR: 'Arm error',
  ARM_CALIBRATION: 'Arm calibration',
  ALL_BLOCK_ERROR: 'All block error',
  /* Fake Autosampler */
  TRAY_ROTATION: 'Tray rotation error',
  ARM_ROTATION_BLOCKED: 'Arm rotation blocked',
  ARM_ELEVATION_BLOCKED: 'Arm elevation blocked',
};

export const PUMP_ERRORS = {
  GENERAL_ERROR: 'General error',
  OVERPRESSURE: 'Overpressure',
};
