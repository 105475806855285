<template>
  <button class="btn-width-100" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
  export default {
    name: 'BtnWidth100',
  };
</script>

<style lang="scss" scoped>
  .btn-width-100 {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: none;
    padding: 0 10px;
    background-color: $color-bg-transparent;
    transition: background-color 0.3s, color 0.3s;
    outline: none;
    cursor: pointer;
    color: $color-text-third;

    &:hover {
      color: $color-text-primary;
      background-color: $color-bg-transparent--hover;
    }

    &:active {
      background-color: $color-bg-transparent--active;
    }
  }
</style>
