<template>
  <WithKeyboardSelection
    #default="{ selectedId }"
    :isActive="canSelectByKeyboard"
    :ids="entityIds"
    :onSelect="openRecord"
    class="table-entity"
  >
    <GlobalEvents target="window" @resize="updateScreenWidth" />

    <LoadingComponent v-if="!hasData && isLoading" />
    <template v-else-if="hasData">
      <div class="table-entity__main" :style="styleColumns">
        <div class="table-entity__head">
          <div class="table-entity__cell">
            <LoaderSpinner v-if="isLoading" />
          </div>
          <div class="table-entity__cell">Name</div>
          <div v-if="hasColumnSequence" class="table-entity__cell">Sequence</div>
          <div v-if="hasColumnState" class="table-entity__cell">Status</div>
          <div v-if="hasColumnDevice" class="table-entity__cell">Device</div>
          <div v-if="hasColumnDate" class="table-entity__cell">Date</div>
          <div v-if="hasColumnTime" class="table-entity__cell">Time</div>
        </div>
        <div class="table-entity__body" data-test-id="table-body">
          <div
            v-for="(group, index) of groups"
            :key="index"
            v-test="{ id: 'section' }"
            class="table-entity__section"
            :style="styleSection"
          >
            <div v-if="group.date" class="table-entity__section-title" :style="styleSection">
              {{ group.date }}
            </div>
            <RouterLink
              v-for="entity of group.entities"
              :key="`${entity.entity}-${entity.id}`"
              :to="entity.link"
              class="table-entity__row"
              :class="{
                'table-entity__row--selected': `${entity.entity}-${entity.id}` === selectedId,
              }"
            >
              <div
                :data-id="`${entity.entity}-${entity.id}`"
                class="table-entity__cell table-entity__cell--icon"
              >
                <PackIconsEntity :entity="entity.entity" />
              </div>
              <div
                :title="entity.title"
                class="table-entity__cell table-entity__cell--title"
                data-test-id="table-cell-title"
              >
                <span class="table-entity__wrapper-title">
                  <span class="table-entity__title">
                    {{ entity.title }}
                  </span>
                </span>
              </div>
              <div
                v-if="hasColumnSequence"
                :title="entity.sequenceName"
                class="table-entity__cell table-entity__cell--title"
              >
                <template v-if="entity.sequenceId">
                  <PackIconsEntity entity="sequence" />
                  {{ entity.sequenceName }}
                </template>
              </div>
              <div v-if="hasColumnState" class="table-entity__cell table-entity__cell--state">
                <StatesForEntity
                  :state="entity.state"
                  :hasTitleOnAdaptive="false"
                  :isShortTitle="true"
                />
              </div>
              <div
                v-if="hasColumnDevice"
                :title="entity.device.name"
                class="table-entity__cell table-entity__cell--device"
              >
                <div class="table-entity__label-device-name">{{ entity.device.name }}</div>
              </div>
              <div v-if="hasColumnDate" class="table-entity__cell table-entity__cell--date">
                {{ entity.date }}
              </div>
              <div
                v-if="hasColumnTime"
                class="table-entity__cell table-entity__cell--time"
                :class="{
                  'table-entity__cell--time--selected': entity.id === Number(selectedItemId),
                }"
              >
                {{ entity.time }}
              </div>
            </RouterLink>
          </div>
        </div>
      </div>

      <LoadingComponent v-if="isLoadingMoreItems" />
      <BtnWidth100
        v-else-if="hasBtnShowMore"
        v-test="{ id: 'btnShowMore' }"
        class="table-entity__btn-show-more"
        @click="showMore"
      >
        Show more
      </BtnWidth100>
    </template>
    <NothingThereComponent v-else v-test="{ id: 'noDataMessage' }" class="table-entity__no-data">
      {{ messageNoData }}
    </NothingThereComponent>
  </WithKeyboardSelection>
</template>

<script>
  import PackIconsEntity from '../../icons/packs/PackIconsEntity';
  import StatesForEntity from '../../states/packs/StatesForEntity';
  import NothingThereComponent from 'components/element/NothingThereComponent';
  import BtnWidth100 from '@/uikitBase/btns/BtnWidth100';
  import LoadingComponent from 'components/element/LoadingComponent';
  import WithKeyboardSelection from '@/hoc/WithKeyboardSelection';
  import testDirective from '@/directives/test';
  import LoaderSpinner from '@/uikitProject/loaders/LoaderSpinner';
  import { breakpoints } from '@/plugins/mediaQuery';

  const BREAKPOINT_PHONE = 425;
  const EVENT_SHOW_MORE = 'showMore';

  export default {
    name: 'TableEntity',

    directives: { test: testDirective },

    components: {
      LoaderSpinner,
      WithKeyboardSelection,
      LoadingComponent,
      BtnWidth100,
      NothingThereComponent,
      StatesForEntity,
      PackIconsEntity,
    },

    props: {
      groups: {
        type: Array,
        required: true,
      },
      messageNoData: {
        type: String,
        required: true,
      },
      hasBtnShowMore: {
        type: Boolean,
        default: false,
      },
      hasColumnDate: {
        type: Boolean,
        default: false,
      },
      hasColumnDevice: {
        type: Boolean,
        default: true,
      },
      hasColumnState: {
        type: Boolean,
        default: false,
      },
      hasColumnSequence: {
        type: Boolean,
        default: false,
      },
      hasColumnTimeOnMobile: {
        type: Boolean,
        default: true,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      isLoadingMoreItems: {
        type: Boolean,
        default: false,
      },
      canSelectByKeyboard: {
        type: Boolean,
        default: false,
      },
      selectedItemId: {
        type: [Number, String],
      },
    },

    data: () => ({
      screenWidthPx: window.innerWidth,
    }),

    computed: {
      hasColumnTime() {
        if (this.$mq === breakpoints.MOBILE && this.screenWidthPx <= BREAKPOINT_PHONE) {
          return this.hasColumnTimeOnMobile;
        }

        return true;
      },

      styleColumns() {
        const columnIcon = 'auto';
        const columnTitle = 'minmax(80px, 3fr)';
        const columnSequence = this.hasColumnSequence ? 'minmax(80px, 3fr)' : '';
        const columnState = this.hasColumnState ? 'auto' : '';
        const columnDate = this.hasColumnDate ? 'auto' : '';
        const columnDevice = this.hasColumnDevice ? 'minmax(73px, 2fr)' : '';
        const columnTime = this.hasColumnTime ? 'auto' : '';

        return {
          gridTemplateColumns: `${columnIcon} ${columnTitle} ${columnSequence} ${columnState} ${columnDevice} ${columnDate} ${columnTime}`,
        };
      },
      styleSection() {
        const COLUMNS_MIN = 2;

        const columnsAdditional = [
          this.hasColumnSequence,
          this.hasColumnState,
          this.hasColumnDate,
          this.hasColumnDevice,
          this.hasColumnTime,
        ].filter(Boolean);

        return {
          gridColumn: `span ${COLUMNS_MIN + columnsAdditional.length}`,
        };
      },

      entities() {
        return this.groups.reduce((entities, group) => {
          entities.push(...group.entities);
          return entities;
        }, []);
      },
      entityIds() {
        return this.entities.map(({ id, entity }) => `${entity}-${id}`);
      },
      hasData() {
        return Boolean(this.entities.length);
      },
    },

    methods: {
      showMore() {
        this.$emit(EVENT_SHOW_MORE);
      },

      openRecord(id) {
        const entity = this.entities.find(({ entity, id: _id }) => `${entity}-${_id}` === id);
        this.$router.push(entity.link);
      },

      getLinkToDevice(id) {
        return {
          name: 'device',
          params: {
            id,
          },
        };
      },

      updateScreenWidth() {
        this.screenWidthPx = window.innerWidth;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .table-entity {
    font-size: 13px;

    &__main {
      display: grid;
      color: $color-text-primary;
    }

    &__head,
    &__body,
    &__section,
    &__row {
      display: contents;
    }

    &__head {
      height: 37px;
      color: $color-text-default;
      font-weight: $weight-bold;
    }

    &__row {
      height: 34px;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    &__section-title {
      padding-top: 8px;
      padding-left: 8px;
      color: $color-text-third;
      line-height: 1.9;
    }

    &__row:nth-child(even) &__cell {
      background-color: $color-bg-transparent--nth;
    }

    &__row--selected &__cell,
    &__row--selected:nth-child(even) &__cell {
      background-color: $pure-color__black--alp-09;

      &--title,
      &--icon {
        color: $color-text-primary--hover;
      }
    }

    &__row:hover &__cell {
      background-color: $color-bg-transparent--hover;
    }

    &__row:active &__cell {
      background-color: $color-bg-transparent--active;

      &--title,
      &--icon {
        color: $color-text-primary--active;
      }
    }

    &__cell {
      display: flex;
      align-items: center;
      height: 34px;
      padding-right: 12px;

      &:first-child {
        border-bottom-left-radius: $border-radius__sm;
        border-top-left-radius: $border-radius__sm;

        padding-left: 16px;
      }

      &:last-child {
        border-bottom-right-radius: $border-radius__sm;
        border-top-right-radius: $border-radius__sm;

        padding-right: 16px;
      }

      &--title {
        font-weight: $weight-bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &--device {
        color: $color-text-default;
      }

      &--time,
      &--date {
        color: $color-text-third;
      }

      &--time {
        &--selected {
          border-right: 2px solid $color-bg-primary;
        }
      }
    }

    &__wrapper-title,
    &__label-device-name {
      flex: 1;
      @include textOverflow;
    }

    &__title {
      margin-right: 20px;
    }

    &__btn-show-more {
      margin-top: 15px;
    }

    &__no-data {
      margin-top: 64px;
    }
  }
</style>
