<template>
  <div class="account-page">
    <NavbarSecondaryAccount />
    <div class="top-line--flex">
      <h1>Account Info</h1>
    </div>

    <div v-if="tokenPayload" class="paper-block-container mt-2">
      <Account />

      <div style="margin: 16px 0 0 0">
        <div class="account-page__wrapper-checkbox-dual">
          <Checkbox v-model="isAllowDualMode" class="account-page__checkbox-dual">
            Allow split view
          </Checkbox>
          <Reference :message="helpMessage" />
        </div>
      </div>

      <div v-if="isSuperuser" style="margin: 0">
        <div style="background-color: #00000044; height: 1px; margin: 16px 0 32px"></div>
        <div style="margin-left: 16px">
          <h4>Admin Features</h4>
          <div style="margin-top: 16px; margin-bottom: 16px">
            <a @click="syncDevices">Sync Devices with Catalog</a>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="paper-block-container paper-block-container--logged-out"
      style="margin-top: 8px"
    >
      You are logged out
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/mixins/page-title';
  import { AuthService } from '@/services/auth.service';
  import AdminAPI from 'api/admin';
  import Checkbox from '@/uikitBase/switchers/Checkbox';
  import NavbarSecondaryAccount from '@/components/blocks/layouts/NavbarSecondaryAccount';
  import Reference from '@/uikitProject/help/Reference';
  import NavigatorHelper from 'utils/NavigatorHelper.ts';
  import { ENOUGH_SCREEN_WIDTH_PX } from '@/router/app/hooks/global/splitScreenManager.ts';
  import VersionHelper from 'utils/VersionHelper';
  import { persistentStorage, PersistentStorageKeys } from 'utils/persistentStorage';
  import Account from '@/components/blocks/reactComponents/Account.vue';

  const hotkeyIncreaseResolution = NavigatorHelper.OS === 'mac' ? '⌘ + (-)' : 'Ctrl + (-)';

  const currentVersion = VersionHelper.clientVersion?.split('-')?.[0] ?? 'Unknown';

  export default {
    name: 'AccountPage',

    components: {
      Account,
      Reference,
      Checkbox,
      NavbarSecondaryAccount,
    },

    mixins: [PageTitle],

    data() {
      return {
        pageTitle: 'HPLC Cloud: Account',
        tokenPayload: AuthService.userData(),
        _isAllowDualMode: Boolean(persistentStorage.get(PersistentStorageKeys.IS_DUAL_MODE)),
        currentVersion,
      };
    },

    computed: {
      isSuperuser() {
        return this.tokenPayload?.is_superuser;
      },

      isAllowDualMode: {
        get() {
          return this.$data._isAllowDualMode;
        },
        set(value) {
          if (value && window.innerWidth < ENOUGH_SCREEN_WIDTH_PX) {
            this.notifyError(
              `Split view is not available! It works only on high resolution screen (width > 1600px). Use ${hotkeyIncreaseResolution} to increase resolution.`,
              8,
            );
          }

          this.$data._isAllowDualMode = value;
          persistentStorage.set(PersistentStorageKeys.IS_DUAL_MODE, value);
        },
      },

      helpMessage() {
        return `Split view works only on high resolution screen (width > 1600px). Use ${hotkeyIncreaseResolution} to increase resolution.`;
      },
    },
    methods: {
      syncDevices() {
        AdminAPI.syncCatalogDevices(this.notify('Ok', 1));
      },
    },
  };
</script>

<style lang="scss" scoped>
  .account-page {
    &__wrapper-checkbox-dual {
      display: flex;
      align-items: center;
      margin-top: 15px;
    }

    &__checkbox-dual {
      margin-right: 10px;
    }
  }
</style>
