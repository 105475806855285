<template>
  <div class="select-method">
    <SelectMethodAsPreset
      ref="methodSelect"
      :method="method"
      :configuration="configuration"
      :methodId="methodId"
      :preloadedMethod="preloadedMethod"
      :isTransparent="true"
      :isDisabled="isDisabled"
      :isEditable="isEditable"
      padding="none"
      class="select-method__select"
      @change="onMethodChange"
      @showPopup="$emit('showPopup')"
      @hidePopup="$emit('hidePopup')"
      @createMethod="$emit('createMethod')"
      @editMethod="$emit('editMethod', $event)"
    />
  </div>
</template>

<script>
  import _ from 'lodash';
  import SelectMethodAsPreset from '@/components/element/SelectMethodAsPreset.vue';

  const EVENT_UPDATE_METHOD = 'update:method';

  export default {
    name: 'SelectMethod',

    components: {
      SelectMethodAsPreset,
    },

    model: {
      prop: 'method',
      event: EVENT_UPDATE_METHOD,
    },

    props: {
      method: Object,
      methodId: Number,
      isDisabled: {
        type: Boolean,
        default: false,
      },
      preloadedMethod: {
        type: Object,
      },
      configuration: {
        type: Object,
      },
      isEditable: {
        type: Boolean,
        default: true,
      },
    },

    methods: {
      onMethodChange(methodData) {
        this.$emit(EVENT_UPDATE_METHOD, _.clone(methodData));
      },
      refreshMethods() {
        this.$refs.methodSelect.refreshMethods();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .select-method {
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;

    &__select {
      width: 100%;
      min-width: 200px;
    }
  }
</style>
