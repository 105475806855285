<template>
  <div
    v-if="sample != null"
    class="paper-block-container paper-block-container--main sample-component"
  >
    <import-csv-modal :sample-id="sample.id" :method="method" :column="column" />

    <div class="paper__top">
      <div class="paper__header">
        <div>
          <InputTitle
            id="block-name"
            v-model="sample.name"
            iconName="opacity"
            data-test-id="sample-name"
            @save="saveName"
          />

          <div class="pt-1">
            <template v-if="sample.was_calibration">
              <span class="sample-component__warning"
                >This sample was originally created as a calibration. <br />
                It was converted to a sample to maintain compatibility after a major software
                upgrade.</span
              >
              <br /><br />
            </template>
            <span class="mr-3">Sample</span>

            <span class="span-date mr-3" v-html="new Date(sample.created).toLocaleDateString()" />

            <div v-if="sample.device_id != null" style="margin-right: 12px; display: inline">
              <router-link :to="linkToDevice">
                <IconMaterial title="input" :size="16" />
                {{ sample.device_name }}</router-link
              >
            </div>

            <StateArchived v-if="sample.archived" style="margin-right: 12px" />

            <shareable-link-component
              :url="shareableLink"
              @action:share="share"
              @action:stopSharing="stopSharing"
            />
          </div>
        </div>
        <div>
          <button
            v-if="sample.archived"
            class="button--square-image"
            data-test-id="btn-restore"
            @click="restore"
          >
            <i class="material-icons material-icon--18">restore</i>
            Restore
          </button>
          <button v-else-if="isEditMode" class="button--square-image" @click="finishEditing">
            <i class="material-icons material-icon--18">done</i>
            Finish <br />
            editing
          </button>

          <MoreMenuComponent v-show="!isEditMode && !sample.archived" data-test-id="btn-more-menu">
            <button
              class="button--square-image"
              style="width: auto; padding: 8px"
              @click="openImportCsv"
            >
              <i class="material-icons material-icon--18">vertical_align_top</i>
              Import
            </button>
            <!--            <div-->
            <!--              v-tippy-->
            <!--              :disabled="hasInjections && !isInProgress"-->
            <!--              :content="-->
            <!--                isInProgress-->
            <!--                  ? `You can't convert while the sample is running`-->
            <!--                  : 'You should make at least one injection'-->
            <!--              "-->
            <!--            >-->
            <!--              <button-->
            <!--                :disabled="!hasInjections || isInProgress"-->
            <!--                class="button&#45;&#45;square-image"-->
            <!--                style="width: auto; padding: 8px"-->
            <!--                @click="convertToStandard"-->
            <!--              >-->
            <!--                <i class="material-icons material-icon&#45;&#45;18">call_made</i>-->
            <!--                To standard-->
            <!--              </button>-->
            <!--            </div>-->
            <button class="button--square-image" @click="startEditing">
              <i class="material-icons material-icon--18">edit</i>
              Edit
            </button>
            <div v-if="isPaused" v-tippy content="Sample is paused! Stop it to archive!">
              <button class="button--square-image" data-test-id="btn-archive" disabled>
                <i class="material-icons material-icon--18">delete</i>
                Archive
              </button>
            </div>
            <button
              v-else-if="sample.can_archive && !isInProgress"
              class="button--square-image"
              data-test-id="btn-archive"
              @click="archive"
            >
              <i class="material-icons material-icon--18">delete</i>
              Archive
            </button>
          </MoreMenuComponent>
        </div>
      </div>
      <div class="paper__content--margin-32">
        <ModelTextareaComponent
          ref="description"
          v-model="sample.description"
          :isEditMode="isEditMode"
          :label="'Description'"
          :canEdit="sample.can_edit"
          style="margin: 16px 0 0"
          data-test-id="sample-description"
          @click="startEditing"
          @update:value="saveDescription"
        />

        <!--        <label-->
        <!--          v-if="sample.compounds || isEditMode"-->
        <!--          class="label-block-margin"-->
        <!--          style="margin: 16px 0 0"-->
        <!--        >-->
        <!--          Compounds-->
        <!--        </label>-->
        <!--        <SelectorMultipleCompounds-->
        <!--          v-if="isEditMode"-->
        <!--          :compounds="compoundsParsed"-->
        <!--          @update:compounds="saveCompounds"-->
        <!--        />-->
        <!--        <div v-else-if="sample.compounds" @click="startEditing">-->
        <!--          {{ sample.compounds }}-->
        <!--        </div>-->

        <!--<DeviceMethodColumnComponent-->
        <!--  v-if="sample.can_edit && (device || method || column)"-->
        <!--  ref="dmc"-->
        <!--  editable-->
        <!--  style="margin: 16px 0 0"-->
        <!--  :device="device"-->
        <!--  :method="method"-->
        <!--  :column="column"-->
        <!--  @update="updateDMC"-->
        <!--/>-->
      </div>
    </div>

    <sample-injections-list-component
      v-show="injectionsAll"
      :injections="injections"
      :injectionID="injectionID"
      :archived="showArchivedInjections"
      :allowNew="sample.sequence_id == null"
      :isInProgress="isInProgress"
      hasArchiveToggle
      @update:injectionID="showInjection($event)"
      @update:archived="showArchivedInjections = $event"
    />
    <div v-show="!injectionsAll" style="padding-bottom: 32px">
      <InjectionsNavBarComponent
        :injections="injections"
        :injectionID="injectionID"
        :archived="showArchivedInjections"
        :allowNew="sample.sequence_id == null"
        :isInProgress="isInProgress"
        @update:injectionID="showInjection($event)"
        @update:archived="showArchivedInjections = $event"
      />

      <SampleInjectionComponent
        v-if="injectionData"
        v-show="!injectionNew"
        ref="injection"
        :injectionData="injectionData"
        :sample="sampleData"
        :sampleSocket="sampleSocket"
        :canEditChromatogram="!isCalibrationSequenceSample"
        :isVisible="isVisible"
      />

      <template v-if="injectionNew">
        <InjectionNewComponent
          v-if="sample.can_edit"
          :injection-name="(sample.injections_counter + 1).toString()"
          :sample-socket="sampleSocket"
          :default-vial="defaultVial"
          :default-device="
            sample.device_id ? { id: sample.device_id, name: sample.device_name } : undefined
          "
          :default-method="method"
          :default-column="column"
          @injectionCreate="handleInjectionCreation"
        />
        <not-available-component v-else class="pt-8">
          Finished or rejected samples can not run new injections.
        </not-available-component>
      </template>
    </div>
  </div>
  <error-component v-else-if="errorCode != null" :code="errorCode" class="mt-16" />
  <loading-component v-else />
</template>

<script>
  import 'assets/css/base/table.scss';
  import _ from 'lodash';

  import StateArchived from '@/uikitProject/states/StateArchived';
  import ErrorComponent from 'components/element/ErrorComponent';
  import ImportCsvModal from 'components/block/modal/ImportCsvModal';
  import InjectionNewComponent from 'components/block/InjectionNewComponent';
  import InjectionsNavBarComponent from 'components/block/InjectionsNavBarComponent';
  import LoadingComponent from 'components/element/LoadingComponent';
  import ModelTextareaComponent from 'components/element/ModelTextareaComponent';
  import MoreMenuComponent from 'components/element/MoreMenuComponent';
  import NotAvailableComponent from 'components/element/NotAvailableComponent';
  import SampleInjectionComponent from 'components/block/SampleInjectionComponent';
  import SampleInjectionsListComponent from 'components/block/SampleInjectionsListComponent';
  import SampleSocket, { SampleSocketEvents } from 'api/sockets/SampleSocket';
  import ShareableLinkComponent from 'components/element/ShareableLinkComponent';
  import InputTitle from '@/uikitProject/inputs/inputTitle/InputTitle';
  import { isSecondaryView } from '@/components/blocks/layouts/dual/SecondaryView';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import RouterHelper from 'utils/RouterHelper.ts';
  import { isSampleInProgress } from 'utils/sampleHelpers';
  import { apiSamples } from '@/api/graphql/cloud/samples';
  // import { getErrorMessage } from '@/utils/errorHelpers';
  import DataNotFoundError from '@/errors/DataNotFoundError';

  const EVENT_UPDATE_INJECTION_ID = 'update:injectionID';
  const EVENT_UPDATE_SAMPLE_DATA = 'update:sampleData';
  const EVENT_ARCHIVE = 'archive';

  export default {
    name: 'SampleComponent',

    components: {
      IconMaterial,
      InputTitle,
      ShareableLinkComponent,
      ImportCsvModal,
      ErrorComponent,
      NotAvailableComponent,
      SampleInjectionsListComponent,
      InjectionsNavBarComponent,
      LoadingComponent,
      ModelTextareaComponent,
      MoreMenuComponent,
      StateArchived,
      InjectionNewComponent,
      SampleInjectionComponent,
    },

    inject: {
      isSecondaryView,
    },

    props: {
      id: Number,
      injectionID: Number,
      sampleData: {
        type: Object,
        default: () => {
          //stub
        },
      },
      isPaused: {
        type: Boolean,
      },
      isVisible: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        sample: undefined,
        device: undefined,
        method: undefined,
        column: undefined,
        injections: {},
        injectionData: null,
        sampleSocket: this.createSocket(this.id),
        showArchivedInjections: false,
        errorCode: null,
        importCsvVisible: false,

        isEditMode: false,

        listenersGroupId: null,
      };
    },

    computed: {
      injectionsAll: {
        get() {
          return this.injectionID == null;
        },
        set(b) {
          if (b) this.showInjection(null);
        },
      },
      injectionNew: {
        get() {
          return this.injectionID === -1;
        },
        set(b) {
          if (b) this.showInjection(-1);
        },
      },
      defaultVial() {
        if (this.injections == null) return undefined;
        const ids = Object.keys(this.injections);
        if (ids.length <= 0) return undefined;
        return this.injections[ids[ids.length - 1]].injection.vial;
      },
      shareableLink() {
        if (this.sample.token_hex) {
          const token = this.sample.token_hex.replaceAll('-', '');

          let url = `samples/${this.id}`;
          if (this.injectionID && this.injectionID > 0) {
            url += `/injection/${this.injectionID}`;
          }
          return `${window.location.origin}/share/#/${url}/?t=${token}`;
        }
        return null;
      },

      linkToDevice() {
        const routePrimary = RouterHelper.getRoutePrimary(this.$route);
        if (routePrimary && routePrimary.name === 'dual.sequence') {
          const { id } = routePrimary.params;

          const pathSecondaryURL =
            `/sequences/${id}/${this.id}` + (this.injectionID ? `/${this.injectionID}` : '');

          return {
            name: 'dual.device',
            params: {
              id: this.device?.id,
            },
            query: {
              pathSecondary: decodeURIComponent(pathSecondaryURL),
              rawLocation: true,
            },
          };
        }

        const link = {
          name: 'device',
          params: { id: this.sample.device_id },
          query: { setAsPrimary: true },
        };

        if (!this.isSecondaryView) {
          link.query.makePrimaryAsSecondary = true;
        }

        return link;
      },
      // compoundsParsed() {
      //   if (this.sample?.compounds?.length) {
      //     return this.sample.compounds.split(';').map((compound) => ({ name: compound.trim() }));
      //   }
      //   return [];
      // },

      isInProgress() {
        return isSampleInProgress(this.sample);
      },

      // hasInjections() {
      //   return Object.values(this.injections).length > 0;
      // },

      isCalibrationSequenceSample() {
        return this.sample.sequence?.is_calibration_mode;
      },
    },

    watch: {
      id(id) {
        this.errorCode = null;
        this.sampleSocket = this.createSocket(id);
        this.initData(id);
      },
      injectionID() {
        this.updateInjectionData();
      },
      injections() {
        this.updateInjectionData();

        if (!Object.values(this.injections).length) {
          this.startEditing();
        } else {
          this.finishEditing();
        }
      },
      sampleData(newData) {
        if (newData == null) return;
        if (newData.id !== this.id) return;
        if (this.sample == null || this.sample.id !== newData.id) {
          this.sample = newData;
        }
      },
    },

    created() {
      this.initData(this.id);
    },

    beforeDestroy() {
      this.destroySocket();
    },

    methods: {
      async initData(sampleId) {
        try {
          const data = await apiSamples.getSampleFull(sampleId);

          if (data.sample.type === 'standard') {
            this.notifyError(
              'This is a standard. Go to the corresponding sequence page to see it!',
            );
            this.errorCode = 403;
            return;
          }

          if (_.has(data, 'sample')) {
            this.setSample(data.sample);
          }
          if (_.has(data, 'device')) {
            this.device = data.device;
          }
          if (_.has(data, 'method')) {
            this.method = data.method;
          }
          if (_.has(data, 'column')) {
            this.column = data.column;
          }
          if (_.has(data, 'injections')) {
            this.injections = data.injections;
          }
        } catch (e) {
          if (e instanceof DataNotFoundError) {
            this.resetData();
          }
          throw e;
        }
      },

      resetData() {
        this.setSample(undefined);
        this.device = undefined;
        this.method = undefined;
        this.column = undefined;
        this.injections = {};
      },

      setSample(s) {
        // eslint-disable-next-line vue/no-mutating-props
        this.sampleData = s;
        this.sample = s;
        this.$emit(EVENT_UPDATE_SAMPLE_DATA, s);
      },

      destroySocket() {
        if (this.sampleSocket) {
          this.sampleSocket.close(this.listenersGroupId);
          this.sampleSocket = null;
        }
      },
      createSocket(id) {
        this.destroySocket();

        const onSample = (s) => {
          console.warn('onSample');
          this.setSample(s);
        };
        const onDevice = (d) => {
          this.device = d;
        };
        const onMethod = (m) => {
          this.method = m;
        };
        const onColumn = (c) => {
          this.column = c;
        };
        const onInjections = (is) => {
          this.injections = is;
        };

        const sampleSocket = SampleSocket.start(id, null, (e) => {
          this.errorCode = e.code;
        });

        const listenersGroup = sampleSocket.createEventListenersGroup();
        this.listenersGroupId = listenersGroup.id;

        const onSampleRefresh = (sampleID) => {
          this.initData(sampleID);
          this.$refs.injection.resetZoom();
        };

        listenersGroup.addEventListener(SampleSocketEvents.SAMPLE_REFRESH, onSampleRefresh);
        listenersGroup.addEventListener(SampleSocketEvents.SAMPLE, onSample);
        listenersGroup.addEventListener(SampleSocketEvents.DEVICE, onDevice);
        listenersGroup.addEventListener(SampleSocketEvents.METHOD, onMethod);
        listenersGroup.addEventListener(SampleSocketEvents.COLUMN, onColumn);
        listenersGroup.addEventListener(SampleSocketEvents.INJECTIONS, onInjections);
        listenersGroup.addEventListener(SampleSocketEvents.SAMPLE_STATE, (sample, injections) => {
          if (this.sample) {
            this.sample.state = sample.state;
          }

          if (this.sampleData) {
            // eslint-disable-next-line vue/no-mutating-props
            this.sampleData.state = sample.state;
          }

          Object.values(injections).forEach((i) => {
            if (this.injections[i.id]?.injection) {
              this.injections[i.id].injection.state = i.state;
              this.injections[i.id].injection.archived = i.archived;
              this.injections[i.id].injection.date_synced = i.date_synced;
            }
          });
        });

        return sampleSocket;
      },

      updateInjectionData() {
        if (this.injections[this.injectionID] != null) {
          this.injectionData = this.injections[this.injectionID];
        }
      },

      saveName(name) {
        this.showNotificationIfRpcError(() => this.sampleSocket.update({ name }));
      },
      saveDescription() {
        this.showNotificationIfRpcError(() =>
          this.sampleSocket.update({ description: this.sample.description }),
        );
      },
      // saveCompounds(_compounds) {
      //   const compounds = _compounds.map((compound) => compound.name).join('; ');
      //   this.sample.compounds = compounds;
      //   this.showNotificationIfRpcError(() => this.sampleSocket.update({ compounds }));
      // },

      startEditing() {
        if (this.sample?.can_edit) {
          this.isEditMode = true;
        }
      },
      finishEditing() {
        if (this.isEditMode) {
          this.$refs.description.save();
          this.isEditMode = false;
        }
      },

      archive() {
        this.showNotificationIfRpcError(() => this.sampleSocket.archive());

        this.$emit(EVENT_ARCHIVE);
      },
      restore() {
        this.showNotificationIfRpcError(() => this.sampleSocket.restore());
      },

      // async convertToStandard() {
      //   try {
      //     const data = await this.sampleSocket.convertToStandard();
      //     this.$router.push({
      //       name: 'standard',
      //       // TODO fix
      //       params: { id: data.standard_ptr },
      //     });
      //   } catch (e) {
      //     this.notifyError(getErrorMessage(e.message));
      //     throw e;
      //   }
      // },

      share() {
        this.showNotificationIfRpcError(() => this.sampleSocket.share());
      },

      stopSharing() {
        this.showNotificationIfRpcError(() => this.sampleSocket.stopSharing());
      },

      showInjection(iid) {
        this.$emit(EVENT_UPDATE_INJECTION_ID, iid);
      },

      openImportCsv() {
        this.$modal.show('import-csv');
      },

      updateDMC({ device, method, column }) {
        const isDeviceChanged = device.id !== this.device?.id;
        const isMethodChanged = method.id !== this.method?.id;
        const isColumnChanged = column.id !== this.column?.id;

        if (isDeviceChanged || isMethodChanged || isColumnChanged) {
          this.showNotificationIfRpcError(() =>
            this.sampleSocket.update({
              device_id: device.id,
              method_id: method.id,
              column_id: column.id,
            }),
          );
        }
      },

      async handleInjectionCreation(data) {
        this.$set(this.injections, data.injection.id, {
          injection: data.injection,
          device: data.device,
          method: data.method,
          column: data.column,
        });

        await this.$nextTick();
        this.showInjection(data.injection.id);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sample-component {
    &__warning {
      color: $color-text-warning;
    }
  }
</style>
