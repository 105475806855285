<template>
  <div class="info-column" :style="styleGrid">
    <div class="info-column__group">
      <Property :bgColor="bgColor" property="Serial">
        <span v-html="column.serial"></span>
      </Property>
      <Property :bgColor="bgColor" property="Type">
        <span v-html="column.type"></span>
      </Property>
      <Property v-if="!isMini && column.lot" :bgColor="bgColor" property="Lot">
        <span v-html="column.lot"></span>
      </Property>
    </div>
    <div class="info-column__group">
      <Property :bgColor="bgColor" property="Length × ID">
        {{ column.length }} × {{ column.internal_diameter }} mm
      </Property>
      <Property :bgColor="bgColor" property="Particle, pore size">
        {{ column.particle_size }} μm, {{ column.pore_size }} Å
      </Property>
      <Property v-if="!isMini && column.max_pressure" :bgColor="bgColor" property="Max pressure">
        <span v-html="column.max_pressure + ' psi'"></span>
      </Property>
    </div>
  </div>
</template>

<script>
  import Property from '../decorators/DecoratorProperty';

  export default {
    name: 'InfoColumn',

    components: { Property },

    props: {
      column: {
        type: Object,
        required: true,
      },
      gridColumns: {
        type: Number,
        default: 2,
      },
      bgColor: {
        type: String,
        default: 'white',
      },
      isMini: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      styleGrid() {
        return {
          gridTemplateColumns: `repeat(${this.gridColumns}, 1fr)`,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .info-column {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 650px) {
      grid-template-columns: 1fr !important;
    }

    &__group {
      overflow: hidden;
    }
  }
</style>
