<template>
  <span class="device-type">
    <template v-if="model === 'cmt'">Cromite</template>
    <template v-else-if="model === 'slc'">Alltesta</template>
    <template v-else>Unknown type </template>
  </span>
</template>

<script>
  export default {
    name: 'DeviceType',

    props: {
      model: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .device-type {
    display: inline-block;
    white-space: nowrap;
    font-weight: bold;
  }
</style>
