<template>
  <modal
    v-if="isMounted"
    :ref="name"
    :name="name"
    :width="width"
    :height="height"
    :scrollable="scrollable"
    class="modal"
    :styles="{ maxWidth: '100%' }"
    :center="center"
    :hasCloseConfirmationPopup="hasCloseConfirmationPopup"
    :closeConfirmationPopupMessage="closeConfirmationPopupMessage"
    @before-open="beforeOpen"
    @opened="opened"
    @before-close="beforeClose"
    @closed="closed"
  >
    <div slot="top-right">
      <button class="close-button" :style="styleBtnClose" @mousedown="close">
        <i class="material-icons material-icon--18">close</i>
      </button>
    </div>
    <div id="modal-padding-wrapper" ref="modal" class="modal-padding-wrapper">
      <slot :close="close" />
    </div>
  </modal>
</template>

<script>
  import 'assets/css/component/modal.scss';
  import { KEY_ENTER, KEY_ESCAPE } from 'utils/key-codes';
  import NavigatorHelper, { BrowserNames } from 'utils/NavigatorHelper.ts';
  import { sendDebugEvent } from 'utils/sentryHelpers';

  const EVENT_SUBMIT = 'submit';

  const isArray = (a) => a && Array.isArray(a);

  const arrayLastElement = (a) => {
    if (isArray(a) && a.length > 0) return a[a.length - 1];
    return undefined;
  };

  export default {
    name: 'ModalComponent',

    props: {
      name: String,
      width: {
        type: [Number, String],
        default: 750 - 32,
      },
      height: {
        type: [Number, String],
        default: 'auto',
      },
      closeOnEscape: {
        type: Boolean,
        default: true,
      },
      doSubmitOnEnter: {
        type: Boolean,
        default: false,
      },
      scrollable: {
        type: Boolean,
        default: true,
      },
      center: {
        type: Boolean,
        default: false,
      },
      hasCloseConfirmationPopup: {
        type: Boolean,
      },
      closeConfirmationPopupMessage: {
        type: String,
      },
    },

    data() {
      return {
        handleKeyDownBound: this.handleKeyDownStop.bind(this),
        handleKeyUpBound: this.handleKeyUpStop.bind(this),

        styleBtnClose: {},

        resizeObserver: new ResizeObserver(() => {
          this.configureBtnCloseStyles();
        }),

        isMounted: false,

        isVisible: false,
      };
    },

    async mounted() {
      this.isMounted = true;
      await this.$nextTick();

      this.handleKeyDownBound = this.handleKeyDownStop.bind(this);
      this.handleKeyUpBound = this.handleKeyUpStop.bind(this);
      document.addEventListener('keydown', this.handleKeyDownBound);
      document.addEventListener('keyup', this.handleKeyUpBound);
    },

    beforeDestroy() {
      document.removeEventListener('keydown', this.handleKeyDownBound);
      document.removeEventListener('keyup', this.handleKeyUpBound);
    },

    methods: {
      beforeOpen() {
        if (!isArray(this.$modal.modalStack)) {
          this.$modal.modalStack = [this];
        } else {
          this.$modal.modalStack.push(this);
        }
        this.$emit('before-open');
      },
      async opened() {
        this.isVisible = true;
        this.$emit('opened');
        this.configurePaddingWrapper();
        await this.$nextTick();
        this.configureBtnCloseStyles();

        this.resizeObserver.observe(this.$refs[this.name].$el);

        // To fix a problem when a child modal opens from another modal with a scroll
        if (this.$modal.modalStack.length >= 2) {
          const currentModal = this.$el;
          const parentModal = this.$el.parentElement.closest('.modal.v--modal-overlay');

          // TODO Remove after catching an error
          if (!parentModal) {
            sendDebugEvent('There are at least two modals, but no parent one', [
              ['Modal stack', this.$modal.modalStack.map((modal) => modal.$el.outerHTML)],
            ]);
            return;
          }

          parentModal.style.transition = 'unset';
          parentModal.style.overflowY = 'hidden';

          if (
            NavigatorHelper.browserName === BrowserNames.SAFARI &&
            NavigatorHelper.isTouchDevice
          ) {
            return;
          }

          currentModal.style.top = `${parentModal.scrollTop}px`;
        }
      },

      beforeClose() {
        if (this.$modal.modalStack.length >= 2) {
          const parentModal = this.$el.parentElement.closest('.modal.v--modal-overlay');

          if (parentModal) {
            parentModal.style.transition = 'unset';
            parentModal.style.overflowY = 'auto';
          }
        }

        const index = this.$modal.modalStack.findIndex((o) => o === this);
        if (index >= 0) {
          this.$modal.modalStack.splice(index, 1);
        }
        this.$emit('before-close');
        this.resizeObserver.unobserve(this.$refs[this.name].$el);
      },
      closed() {
        this.isVisible = false;
        this.$emit('closed');
      },

      configurePaddingWrapper() {
        const modal = this.$refs[this.name].$refs.modal.firstChild;
        modal.addEventListener('mousedown', (e) => {
          if (e.target.id === 'modal-padding-wrapper') {
            this.close();
            e.stopImmediatePropagation();
          }
        });
      },
      configureBtnCloseStyles() {
        const BTN_SIZE_PX = 48;

        if (!this.$refs.modal) {
          return;
        }

        const { right, top, left } = this.$refs.modal.firstChild.getBoundingClientRect();
        const isEnoughSpace = window.innerWidth - 60 > right;

        this.styleBtnClose = {
          left: isEnoughSpace ? -left + 'px' : -left - BTN_SIZE_PX + 'px',
          top: isEnoughSpace ? top + 'px' : top - BTN_SIZE_PX + 'px',
        };
      },

      handleKeyDownStop(event) {
        if (!this.isVisible) {
          return;
        }

        if (event.defaultPrevented) return;
        const modal = arrayLastElement(this.$modal.modalStack);
        if (!modal) return;
        // eslint-disable-next-line no-underscore-dangle
        if (event.which === KEY_ESCAPE && modal._props.closeOnEscape) {
          event.stopImmediatePropagation();

          const canClose = this.hasCloseConfirmationPopup
            ? // eslint-disable-next-line no-alert
              confirm(this.closeConfirmationPopupMessage)
            : true;
          if (canClose) {
            this.$modal.modalStack.pop();
            this.$modal.hide(modal.name);
          }
          // eslint-disable-next-line no-underscore-dangle
        } else if (event.which === KEY_ENTER && modal._props.doSubmitOnEnter) {
          event.stopImmediatePropagation();
          modal.$emit(EVENT_SUBMIT);
        }
      },

      handleKeyUpStop(event) {
        if (!this.isVisible) {
          return;
        }

        event.stopImmediatePropagation();
      },

      close() {
        const canClose = this.hasCloseConfirmationPopup
          ? // eslint-disable-next-line no-alert
            confirm(this.closeConfirmationPopupMessage)
          : true;
        canClose && this.$modal.hide(this.name);
      },
    },
  };
</script>
