<template>
  <div
    ref="input"
    :contenteditable="!isDisabled"
    class="input-form-multiline"
    @input="onInput"
    @keydown.enter="onKeydownEnter"
    @paste.prevent="onPaste"
  >
    {{ valueDefault }}
  </div>
</template>

<script>
  const EVENT_MODEL = 'model';

  export default {
    name: 'InputFormMultiline',

    model: {
      prop: 'value',
      event: EVENT_MODEL,
    },

    props: {
      value: {
        type: String,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      valueDefault: '',
      previousSavedValue: '',
    }),

    computed: {
      localValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit(EVENT_MODEL, value);
        },
      },
    },

    // Hack to achieve two way binding with content editable div
    watch: {
      value(value) {
        if (value !== this.$refs.input.innerText) {
          this.$refs.input.innerText = value;
          this.previousSavedValue = this.value;
        }
      },
    },

    created() {
      this.initDefaultValue();
      this.initSavedValue();
    },

    methods: {
      initDefaultValue() {
        this.valueDefault = this.value;
      },
      initSavedValue() {
        this.previousSavedValue = this.value;
      },

      onInput(e) {
        this.localValue = e.target.innerText;
      },
      onKeydownEnter() {
        this.$refs.input.blur();
      },
      onPaste(e) {
        // To clean external styles
        const plainText = e.clipboardData.getData('text/plain');
        document.execCommand('insertText', false, plainText);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .input-form-multiline {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 33px;
    word-break: break-word;
    hyphens: auto;
    outline: none;
    line-height: 1.22;
    border: none;
    border-radius: 2px;
    padding: 10px 12px 8px;

    transition: background-color 0.1s;
    background-color: $pure-color__black--alp-06;

    &[contenteditable='true'] {
      cursor: text;

      &:focus {
        background-color: $pure-color__black--alp-04;
      }
    }
  }
</style>
