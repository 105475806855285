import _ from 'lodash';
import { Callback, Dict } from '@/types/utility';
import SocketCentrifugeBase, { SubscriptionError } from '@/api/sockets/SocketCentrifugeBase';

export enum StandardSocketEvents {
  STANDARD = 'standard',
  STANDARD_REFRESH = 'standard.refresh',
  // Internal app events. Not emitted by socket
  DEVICE = 'standard.device',
  METHOD = 'standard.method',
  COLUMN = 'standard.column',
  INJECTIONS = 'standard.injections',
}

enum StandardSocketRPC {
  GET = 'standard.get',
  ARCHIVE = 'standard.archive',
  RESTORE = 'standard.restore',
  UPDATE = 'standard.update',
  START_NEW_INJECTION = 'standard.startNewInjection',
}

export default class StandardSocket extends SocketCentrifugeBase<
  StandardSocketEvents,
  StandardSocketRPC
> {
  private static connections: Dict<StandardSocket> = {};

  protected listeners: Record<StandardSocketEvents, Callback[]> = {
    [StandardSocketEvents.STANDARD]: [],
    [StandardSocketEvents.STANDARD_REFRESH]: [],
    [StandardSocketEvents.DEVICE]: [],
    [StandardSocketEvents.METHOD]: [],
    [StandardSocketEvents.COLUMN]: [],
    [StandardSocketEvents.INJECTIONS]: [],
  };

  private constructor(
    public id: number,
    onConnect?: Callback<[SocketCentrifugeBase<StandardSocketEvents, StandardSocketRPC>]>,
    onError?: Callback<[SubscriptionError]>,
  ) {
    super(`personal:standard-${id}`, {
      handleEvents: ({ data }) => {
        switch (data.event) {
          case StandardSocketEvents.STANDARD: {
            if (_.has(data, 'standard')) {
              this.callListeners(StandardSocketEvents.STANDARD, data.standard);
            }
            if (_.has(data, 'device')) {
              this.callListeners(StandardSocketEvents.DEVICE, data.device);
            }
            if (_.has(data, 'method')) {
              this.callListeners(StandardSocketEvents.METHOD, data.method);
            }
            if (_.has(data, 'column')) {
              this.callListeners(StandardSocketEvents.COLUMN, data.column);
            }
            if (_.has(data, 'injections')) {
              this.callListeners(StandardSocketEvents.INJECTIONS, data.injections);
            }
            break;
          }
          case StandardSocketEvents.STANDARD_REFRESH: {
            this.callListeners(StandardSocketEvents.STANDARD_REFRESH, data.standard);
            break;
          }
        }
      },
      onConnect,
      onError,
    });

    this.dataAddedToEveryRequest = { standard_id: this.id };
  }

  public static start(
    id: number,
    onConnect?: Callback<[SocketCentrifugeBase<StandardSocketEvents, StandardSocketRPC>]>,
    onError?: Callback<[SubscriptionError]>,
  ) {
    const connection = StandardSocket.connections[id];
    if (connection) {
      connection.addSession();
      onConnect?.(connection);
      return connection;
    }

    StandardSocket.connections[id] = new StandardSocket(id, onConnect, onError);
    return StandardSocket.connections[id];
  }

  close(listenersGroupId: string): boolean {
    const isClosed = super.close(listenersGroupId);

    if (isClosed) {
      delete StandardSocket.connections[this.id];
    }

    return isClosed;
  }

  get() {
    return this.sendNamedRPC(StandardSocketRPC.GET);
  }

  archive() {
    return this.sendNamedRPC(StandardSocketRPC.ARCHIVE);
  }

  restore() {
    return this.sendNamedRPC(StandardSocketRPC.RESTORE);
  }

  update(standard) {
    return this.sendNamedRPC(StandardSocketRPC.UPDATE, { standard });
  }

  injectionNew(description, deviceId, vial, amount) {
    return this.sendNamedRPC(StandardSocketRPC.START_NEW_INJECTION, {
      description,
      device_id: deviceId,
      vial,
      amount,
    });
  }
}
