<template>
  <div>
    <div class="top-line" style="margin-bottom: 16px">
      <router-link
        v-if="isShowLinkToList"
        id="link-navigate-back"
        class="link-navigate-back"
        to="/samples/"
      >
        <i class="material-icons material-icon--16 no-text-decoration">keyboard_backspace</i>
        Samples
      </router-link>
    </div>

    <SampleComponent
      :id="id"
      :injectionID="iid"
      @update:injectionID="updatedInjectionID"
      @update:sampleData="updatedSampleData"
    />
  </div>
</template>

<script>
  import SampleComponent from 'components/block/SampleComponent.vue';
  import PageTitle from '@/mixins/page-title';
  import { isSecondaryView } from 'components/blocks/layouts/dual/SecondaryView';

  export default {
    name: 'SamplePage',

    inject: {
      isSecondaryView,
    },

    components: {
      SampleComponent,
    },

    mixins: [PageTitle],

    props: {
      id: Number,
      iid: Number,
    },

    data() {
      return {
        pageTitle: 'HPLC Cloud: Sample',
      };
    },

    computed: {
      isShowLinkToList() {
        return !this.isSecondaryView;
      },
    },

    methods: {
      updatedInjectionID(iid) {
        if (iid == null) {
          this.$router.push({
            name: 'sample',
            params: { id: this.id },
            query: { notOpenSecondaryTab: true },
          });
        } else if (iid === -1) {
          this.$router.push({
            name: 'sample injection new',
            params: { id: this.id },
            query: { notOpenSecondaryTab: true },
          });
        } else {
          this.$router.push({
            name: 'sample injection',
            params: { id: this.id, iid },
            query: { notOpenSecondaryTab: true },
          });
        }
      },
      updatedSampleData(sd) {
        this.pageTitle = `${sd?.name}: Sample HPLC.cloud`;
      },
    },
  };
</script>
