<template>
  <DeviceSelector
    :deviceSelected="deviceSelected"
    :devices="devices"
    v-bind="{ isLoading, isRequired, isReadonly }"
    @update:deviceSelected="setDevice"
  />
</template>

<script>
  import DeviceSelector from '@/uikitProject/devices/selector/DeviceSelector';
  import RouterHelper from 'utils/RouterHelper.ts';
  import { isSecondaryView } from '@/components/blocks/layouts/dual/SecondaryView';
  import { apiDevices } from '@/api/graphql/cloud/devices';
  import { consoleHelpers } from '@/utils/logHelpers';

  const EVENT_UPDATE_DEVICE_SELECTED = 'update:deviceSelected';

  export default {
    name: 'ApiDeviceSelector',

    inject: {
      isSecondaryView,
    },

    components: {
      DeviceSelector,
    },

    model: {
      prop: 'deviceSelected',
      event: EVENT_UPDATE_DEVICE_SELECTED,
    },

    props: {
      deviceSelected: {
        type: Object,
        default: undefined,
      },
      isRequired: {
        type: Boolean,
        default: false,
      },
      isReadonly: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      devices: [],
      isLoading: false,
      reloadTimeout: null,
    }),

    created() {
      this.initDevices();
    },

    beforeDestroy() {
      clearTimeout(this.reloadTimeout);
    },

    methods: {
      setDevice(device) {
        this.$emit(EVENT_UPDATE_DEVICE_SELECTED, device);
      },

      scheduleReload(timeout = 10000) {
        this.reloadTimeout = setTimeout(() => this.fetchDevices(), timeout);
      },

      async fetchDevices() {
        try {
          this.devices = await apiDevices.getDevicesWithMethodColumn();
          const deviceOnlineUnknown = this.devices.find((device) => device.isOnline == null);
          this.scheduleReload(deviceOnlineUnknown ? 1000 : 10000);
        } catch (e) {
          this.devices = [];
          this.notifyError(`Devices update error`);
          consoleHelpers.error(e);
          this.scheduleReload();
        }
      },

      initDefaultDevice() {
        const { device: deviceId } = RouterHelper.getQuery(this.isSecondaryView);
        RouterHelper.deleteQueryParams(['device']);
        if (!deviceId) return;

        const device = this.devices.find((device) => device.id === deviceId);

        if (device) {
          this.setDevice(device);
        }
      },

      async initDevices() {
        try {
          this.isLoading = true;

          await this.fetchDevices();
          this.initDefaultDevice();
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
</script>
