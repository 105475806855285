<template>
  <label class="input" tabindex="0" :class="[`input--height--${height}`]">
    <span v-if="$slots.iconLeft" class="input__wrapper-icon">
      <slot name="iconLeft" />
    </span>

    <!--Duplicate input because of the v-mask directive-->
    <input
      v-if="mask"
      ref="input"
      v-model="_value"
      v-mask="mask"
      class="input__field"
      :class="{ 'input__field--with-indent': !$slots.iconLeft }"
      v-bind="$attrs"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      v-on="$listeners"
    />
    <input
      v-else
      ref="input"
      v-model="_value"
      :class="{ 'input__field--with-indent': !$slots.iconLeft }"
      class="input__field"
      v-bind="$attrs"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      v-on="$listeners"
    />

    <BtnIcon
      v-if="hasClearBtn && _value"
      iconMaterial="close"
      class="input__btn-clear"
      :class="[`input__btn-clear--size--${height}`]"
      @click="clear"
    />
  </label>
</template>

<script>
  import BtnIcon from '@/uikitBase/btns/BtnIcon';
  import { mask } from 'vue-the-mask';

  const EVENT_MODEL = 'model';

  export default {
    name: 'Input',

    components: {
      BtnIcon,
    },

    directives: { mask },

    model: {
      props: 'value',
      event: EVENT_MODEL,
    },

    props: {
      value: {
        type: [String, Number],
        required: true,
        default: '',
      },
      hasClearBtn: {
        type: Boolean,
        required: false,
      },
      height: {
        type: String,
        default: 'sm',
        validator(value) {
          const availableValues = ['sm', 'md'];
          return availableValues.includes(value);
        },
      },
      mask: {
        type: Object,
      },
      isDarken: {
        type: Boolean,
      },
    },

    computed: {
      _value: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit(EVENT_MODEL, value);
        },
      },
    },

    methods: {
      focus() {
        this.$refs.input.focus();
      },

      clear() {
        this._value = '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .input {
    display: flex;
    align-items: center;
    width: 100%;
    color: $color-text-default;
    background: $color-bg-input;
    border-radius: $border-radius__sm;

    &:hover,
    &:focus-within {
      background-color: $color-bg-input--hover;
    }

    &--height {
      &--sm {
        height: 32px;
      }

      &--md {
        height: 38px;
      }
    }

    &__wrapper-icon {
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      padding-left: 4px;

      @include appleTouchDevice {
        padding-top: 1px;
      }
    }

    &__field {
      background: transparent;
      text-indent: 0;
      display: block;
      outline: none;
      font-size: 13px;
      box-sizing: border-box;
      appearance: none;
      padding: 0;

      &--with-indent {
        text-indent: 12px;
      }
    }

    &__btn-clear {
      flex: none;
      height: 100%;
      color: $color-text-primary;
      font-size: $size-sm;

      &--size {
        &--sm {
          width: 32px;
          height: 32px;
        }

        &--md {
          width: 38px;
          height: 38px;
        }
      }
    }
  }
</style>
