<template>
  <div v-if="configuration.cromite != null" class="hardware-tile cromite-operation">
    <div class="hardware-tile__wrapper-title">
      <div>
        <i class="hardware-tile__title-icon">settings_ethernet</i>
        <h4>Operation</h4>
      </div>
    </div>

    <template v-if="cromiteDetails">
      <div>
        <template v-if="state.ready">
          <hardware-state-component :state="3" />
          <img src="@/assets/img/cromite/ready.png" />
        </template>

        <template v-else-if="cromiteDetails.operation === 1 && valveDetails.position === 1">
          <div class="text-process cromite-operation__title">Column equilibration</div>
          <img src="@/assets/img/cromite/column-equilibration.png" />
        </template>

        <template v-else-if="cromiteDetails.operation === 5 && valveDetails.position === 1">
          <div class="text-process cromite-operation__title">Sample injection and run</div>
          <img src="@/assets/img/cromite/sample-injection.png" />
        </template>

        <template v-else-if="cromiteDetails.operation === 4 && valveDetails.position === 4">
          <div class="text-yellow cromite-operation__title">Needle cleaning</div>
          <img src="@/assets/img/cromite/needle-cleaning.png" />
        </template>

        <template v-else-if="cromiteDetails.operation === 3 && valveDetails.position === 3">
          <div class="text-yellow cromite-operation__title">Pump purge</div>
          <img src="@/assets/img/cromite/pump-purge.png" />
        </template>

        <template v-else-if="cromiteDetails.operation === 6 && valveDetails.position === 6">
          <div class="text-yellow cromite-operation__title">Pump refill</div>
          <img src="@/assets/img/cromite/pump-refill.png" />
        </template>

        <template v-else-if="cromiteDetails.operation === 2 && valveDetails.position === 4">
          <div class="text-process cromite-operation__title">Sample suction</div>
          <img src="@/assets/img/cromite/sample-suction.png" />
        </template>
      </div>
    </template>

    <template v-if="!injectionInProgress">
      <Btn
        height="s"
        type="primary"
        icon-left="play_arrow"
        :disabled="state.ready !== true"
        class="mt-8"
        @click="start"
      >
        Single injection
      </Btn>

      <Btn height="s" icon-left="gradient" class="mt-8" @click="cleanNeedle"> Clean needle </Btn>
    </template>
  </div>
</template>

<script>
  import Base from './Base.vue';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import { cromiteCommands } from '@/constants/hardware/commands';

  const EVENT_START = 'start';

  export default {
    name: 'CromiteOperation',

    components: { Btn },

    extends: Base,

    computed: {
      cromiteDetails() {
        return this.state?.cromite_details;
      },
      valveDetails() {
        return this.state?.valve_details;
      },
      injectionInProgress() {
        return this.state.injection_id != null;
      },
    },

    methods: {
      start() {
        this.$emit(EVENT_START, null);
      },
      cleanNeedle() {
        this.$modal.show('dialog', {
          title: 'Are you sure?',
          text: `As soon as the cleaning begins, flow will start coming out of the needle`,
          buttons: [
            {
              title: 'Yes',
              default: true,
              handler: () => {
                this.ws.command('cromite', cromiteCommands.CLEAN_NEEDLE);

                this.$modal.hide('dialog');
              },
              class: 'vue-dialog-button blue-text',
            },
            {
              title: 'No',
              handler: () => {
                this.$modal.hide('dialog');
              },
              class: 'vue-dialog-button red-text',
            },
          ],
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .cromite-operation {
    &__title {
      font-weight: $weight-bold;
      margin-top: 8px;
      margin-bottom: 12px;
    }
  }
</style>
