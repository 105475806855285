<template>
  <DecoratorState
    :hasTitleOnAdaptive="hasTitleOnAdaptive"
    title="Pending"
    :hasTitle="hasTitle"
    class="state-pending"
  >
    <template #icon>
      <div class="state-pending__icon" />
    </template>
  </DecoratorState>
</template>

<script>
  import DecoratorState from '@/uikitProject/decorators/DecoratorState';

  export default {
    name: 'StatePending',

    components: {
      DecoratorState,
    },

    props: {
      hasTitle: {
        type: Boolean,
        default: true,
      },
      hasTitleOnAdaptive: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .state-pending {
    color: $color-text-process;

    &__icon {
      display: inline-block;
      background-image: url('~assets/img/pulse.svg');
      background-size: contain;
      margin: 0 0 2px 0;
      vertical-align: bottom;
      text-decoration: none;
      width: 15px;
      height: 15px;
    }
  }
</style>
