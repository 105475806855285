<template>
  <div
    class="wifi-icon"
    :class="{
      'wifi-icon--connected': isConnected,
    }"
  >
    <LoaderSpinner v-if="isConnecting" />

    <template v-else-if="isConnected">
      <img
        v-if="signalLevel === 1"
        src="@/assets/img/icons/wifi_signal_1_selected.svg"
        class="wifi-icon__image"
      />
      <img
        v-else-if="signalLevel === 2"
        src="@/assets/img/icons/wifi_signal_2_selected.svg"
        class="wifi-icon__image"
      />
      <img
        v-else-if="signalLevel === 3"
        src="@/assets/img/icons/wifi_signal_3_selected.svg"
        class="wifi-icon__image"
      />
    </template>
    <template v-else>
      <img
        v-if="signalLevel === 1"
        src="@/assets/img/icons/wifi_signal_1.svg"
        class="wifi-icon__image"
      />
      <img
        v-else-if="signalLevel === 2"
        src="@/assets/img/icons/wifi_signal_2.svg"
        class="wifi-icon__image"
      />
      <img
        v-else-if="signalLevel === 3"
        src="@/assets/img/icons/wifi_signal_3.svg"
        class="wifi-icon__image"
      />
    </template>
  </div>
</template>

<script>
  import LoaderSpinner from '@/uikitProject/loaders/LoaderSpinner.vue';

  export default {
    name: 'WifiIcon',

    components: {
      LoaderSpinner,
    },

    props: {
      isConnected: {
        type: Boolean,
        required: true,
      },
      signal: {
        type: Number,
        required: true,
      },
      isConnecting: {
        type: Boolean,
      },
    },

    computed: {
      signalLevel() {
        if (this.signal < 35) {
          return 1;
        }
        if (this.signal < 75) {
          return 2;
        }
        return 3;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .wifi-icon {
    width: 28px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    padding: 5px;

    &--connected {
      color: white;
      background-color: $color-bg-primary;
    }

    &__image {
      height: 18px;
    }
  }
</style>
