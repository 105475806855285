<template>
  <modal :name="modalName" :width="modalWidth" doSubmitOnEnter @submit="submit" @closed="reset">
    <div class="modal-content modal-content--no-padding">
      <div class="modal-header"><h4>Settings</h4></div>
      <LoadingComponent v-if="isLoading" class="modal-content__loader" />
      <div v-if="error" class="modal-content__error">{{ error }}</div>

      <template v-if="!error">
        <AlltestaTowerSettings
          v-show="!isLoading"
          :id="deviceId"
          ref="block-settings"
          :isCromite="isCromite"
          @update="close"
          @ready="finishLoading"
          @error="setError"
        />
        <div v-show="!isLoading" class="modal-footer">
          <div class="modal-content__actions">
            <button class="button--accent" data-test-id="btn-save-settings" @click="submit">
              Save
            </button>
          </div>
        </div>
      </template>
    </div>
  </modal>
</template>

<script>
  import ModalComponent from 'components/element/ModalComponent.vue';
  import AlltestaTowerSettings from 'components/blocks/devices/settings/AlltestaTowerSettings';
  import LoadingComponent from 'components/element/LoadingComponent';

  const MODAL_NAME = 'settings-modal';

  export default {
    name: 'AlltestaTowerSettingsModal',

    components: {
      LoadingComponent,
      AlltestaTowerSettings,
      modal: ModalComponent,
    },

    props: {
      deviceId: String,
      isCromite: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      modalName: MODAL_NAME,
      modalWidth: 750 - 64 * 2, // 64px paddings

      isLoading: true,
      error: null,
    }),

    methods: {
      show() {
        this.$modal.show(this.modalName);
      },
      close() {
        this.$modal.hide(this.modalName);
      },
      submit() {
        this.$refs['block-settings'].save();
      },

      finishLoading() {
        this.isLoading = false;
      },
      setError(errorMessage) {
        this.finishLoading();
        this.error = errorMessage;
      },

      reset() {
        this.isLoading = true;
      },
    },
  };
</script>
