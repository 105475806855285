<template>
  <span class="decorator-state">
    <span v-if="$slots.icon" class="decorator-state__icon">
      <slot name="icon" />
    </span>

    <span v-if="hasTitle" class="decorator-state__title" :class="classAdaptive">{{ title }}</span>
  </span>
</template>

<script>
  export default {
    name: 'DecoratorState',

    props: {
      hasTitle: {
        type: Boolean,
        default: true,
      },
      hasTitleOnAdaptive: {
        type: Boolean,
        default: true,
      },
      title: {
        type: String,
      },
    },

    computed: {
      classAdaptive() {
        return !this.hasTitleOnAdaptive && 'decorator-state__title--adaptive--hide';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .decorator-state {
    white-space: nowrap;

    &__title {
      margin-left: 3px;
      font-weight: $weight-bold;

      &--adaptive--hide {
        @media (max-width: $screen-sm-max) {
          display: none;
        }
      }
    }

    &__icon {
      img {
        display: inline;
      }
    }
  }
</style>
