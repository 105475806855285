const transformArrayToObject = <T>(array: Array<T>, fieldAsKey: keyof T) =>
  array.reduce<Record<string, T>>((object, item) => {
    const key = item[fieldAsKey];
    if (typeof key === 'string' || typeof key === 'number') {
      // TODO ts bug
      // @ts-ignore
      object[key] = item;
      return object;
    }
    throw new Error('The key value type must be a string or a number.');
  }, {});

const convertNullValuesToUndefined = (object: object): object => {
  return Object.entries(object).reduce((acc, [key, value]) => {
    acc[key] = value ?? undefined;
    return acc;
  }, {});
};
export { transformArrayToObject, convertNullValuesToUndefined };
