<template>
  <div class="radio">
    <input
      v-show="!$scopedSlots.default"
      :id="id"
      v-model="_checked"
      v-test="{ id: 'inputCheck' }"
      :disabled="isDisabled"
      :value="value"
      :name="name"
      type="radio"
      class="radio__input-check"
    />

    <label
      v-if="$scopedSlots.default"
      v-test="{ id: 'labelView' }"
      :for="id"
      class="radio__view"
      @click.self="click"
    >
      <slot :isChecked="isChecked" :isDisabled="isDisabled" />
    </label>

    <label
      v-if="$slots.label"
      v-test="{ id: 'labelText' }"
      :for="id"
      class="radio__label"
      @click="click"
    >
      <slot name="label" />
    </label>
  </div>
</template>

<script>
  import { generateId } from '@/utils/generateId.ts';

  import testDirective from '@/directives/test';

  const EVENT_MODEL = 'model';

  export default {
    name: 'radio',

    directives: { test: testDirective },

    model: {
      prop: 'checked',
      event: EVENT_MODEL,
    },

    props: {
      id: {
        type: String,
        default: () => generateId(),
      },
      checked: {
        type: [Number, String],
      },
      value: {
        type: [Number, String],
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      _checked: {
        get() {
          return this.checked;
        },
        set(value) {
          this.$emit(EVENT_MODEL, value);
        },
      },

      isChecked() {
        return this.checked === this.value;
      },
    },

    methods: {
      click(e) {
        if (this.isDisabled) e.preventDefault();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .radio {
    display: inline-flex;
    align-items: baseline;
    cursor: pointer;
    outline: none;

    &__input-check {
      cursor: pointer;
      margin: 0;
    }

    &__label {
      cursor: pointer;
      padding-left: 8px;
      color: inherit;
      font-weight: inherit;
      font-size: inherit;
    }

    &__view {
      width: 100%;
      height: 100%;
      color: inherit;
    }
  }
</style>
