<template>
  <div v-if="device" class="device-connection">
    <span v-if="device.id != null" class="device-connection__serial">{{
      device.id != null ? deviceSerial : 'Not connected'
    }}</span>

    <template v-if="hasDetails && deviceSocket">
      <Ethernet
        :connectionDetails="device.state.ethernet_adapter_details"
        :deviceSocket="deviceSocket"
        :isDeviceOnline="device.is_online"
        class="device-connection__ethernet"
      />
      <Wifi
        :connectionDetails="device.state.wifi_adapter_details"
        :deviceSocket="deviceSocket"
        :isDeviceOnline="device.is_online"
        class="device-connection__wifi"
      />
      <Ping :deviceSocket="deviceSocket" :device="device" />
    </template>
  </div>
</template>

<script>
  import { formatDeviceSerial } from '@/utils/formatters.ts';
  import Ethernet from '@/uikitProject/devices/vueDeviceConnection/private/vueEthernet/Ethernet.vue';
  import Wifi from '@/uikitProject/devices/vueDeviceConnection/private/vueWifi/Wifi.vue';
  import { DeviceSocketEvents } from '@/api/sockets/DeviceSocket';
  import { getErrorMessage } from '@/utils/errorHelpers';
  import Ping from '@/uikitProject/devices/vueDeviceConnection/Ping.vue';

  export default {
    name: 'DeviceConnection',

    components: {
      Ping,
      Wifi,
      Ethernet,
    },

    props: {
      device: {
        type: Object,
        default: null,
      },
      deviceSocket: {
        type: Object,
      },
      hasDetails: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      deviceSerial() {
        return formatDeviceSerial(this.device.id);
      },
    },

    created() {
      if (this.hasDetails) {
        this.initSocketListeners();
      }
    },

    beforeDestroy() {
      if (this.hasDetails) {
        this.removeSocketListeners();
      }
    },

    methods: {
      initSocketListeners() {
        if (this.deviceSocket) {
          const listenersGroup = this.deviceSocket?.createEventListenersGroup();
          this.listenersGroupId = listenersGroup.id;

          listenersGroup.addEventListener(DeviceSocketEvents.NETWORK_DETAILS, (networkDetails) => {
            if (networkDetails.error) {
              console.warn('networkDetails.error', networkDetails.error);
              this.notifyError(getErrorMessage(networkDetails.error), {
                durationSeconds: 10,
              });
            }
          });
        }
      },
      removeSocketListeners() {
        this.deviceSocket?.removeEventListenersByGroupId(this.listenersGroupId);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .device-connection {
    display: inline-flex;
    white-space: nowrap;
    color: #00000088;

    &__serial {
      margin-right: 8px;
    }

    &__ethernet,
    &__wifi {
      margin-right: 8px;
    }
  }
</style>
