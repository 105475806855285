<template>
  <div class="not-available print-hidden">
    <div class="not-available__image-container">
      <img src="@/assets/img/cat-sad.gif" />
    </div>
    <div v-if="hasSlot" class="not-available__content">
      <slot></slot>
    </div>
    <span v-else class="not-available__content">Not available</span>
  </div>
</template>

<script>
  export default {
    name: 'NotAvailableComponent',
    computed: {
      hasSlot() {
        return !!this.$slots.default;
      },
    },
  };
</script>

<style lang="scss">
  .not-available {
    text-align: center;
    width: 100%;
    color: #00000099;
    align-content: center;

    &__image-container {
      width: 100%;
      clear: both;

      img {
        width: 32px;
        margin: 0 auto 16px;
        display: block;
        opacity: 0.5;
      }
    }

    &__content {
      margin: 0 32px;
    }
  }
</style>
