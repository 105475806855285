import { makeGraphqlRequestCloud } from 'api/graphql/graphQLClient';
import { Devices } from '@/graphql/cloud/devices/queries/Devices.graphql';
import { DevicesMethodColumn } from '@/graphql/cloud/devices/queries/DevicesMethodColumn.graphql';

export const apiDevices = {
  async getDevices() {
    const devices = (await makeGraphqlRequestCloud(Devices)).devices;
    return devices.map((device) => ({
      ...device,
      ...device.hardware,
    }));
  },
  async getDevicesWithMethodColumn() {
    const devices = (await makeGraphqlRequestCloud(DevicesMethodColumn)).devices;
    return devices.map((device) => ({
      ...device,
      ...device.hardware,
    }));
  },
};
