export const DEFAULT_DEVICE_CONFIGURATION = {
  device: 'ALLTESTA',
  pump: ['fake', 'fake'],
  autosampler: ['fake'],
  valve: ['fake'],
};

export const DEVICE_CONFIGURATION_FOR_IMPORT = {
  ...DEFAULT_DEVICE_CONFIGURATION,
  isForImport: true,
};
