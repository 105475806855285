import _ from 'lodash';
import { REST } from 'utils/rest';

const url = '/api/devices/';

export default class DeviceAPI {
  static urlFor(id) {
    return `${url + id.toString()}/`;
  }

  static get(id, onSuccess, onError) {
    return REST.get(DeviceAPI.urlFor(id), onSuccess, onError);
  }

  // static put(serial, onSuccess, onError) {
  //     let channel = $('#' + serial + '_device_channel').val();
  //     let name = $('#' + serial + '_device_name').val();
  //
  //     let url = '/api/boxes/' + serial + '/channels/' + channel + '/';
  //     let data = {
  //         'name': name,
  //     };
  //
  //     return REST.put(url, data, onSuccess, onError);
  // }

  // static delete(serial, channel, onSuccess, onError) {
  //     let url = '/api/boxes/' + serial + '/channels/' + channel + '/';
  //
  //     return REST.delete(url, onSuccess, onError);
  // }

  static patch(id, deviceData, onSuccess, onError) {
    const dataFields = ['archived', 'column_id', 'method_id'];

    const data = _.pick(deviceData, dataFields);

    const urlForId = DeviceAPI.urlFor(id);
    return REST.patch(urlForId, data, onSuccess, onError);
  }

  static postMethod(id, methodData, onSuccess, onError) {
    const urlForId = `${DeviceAPI.urlFor(id)}method/`;
    return REST.post(urlForId, methodData, onSuccess, onError);
  }

  static put(id, data, onSuccess, onError) {
    return REST.put(DeviceAPI.urlFor(id), data, onSuccess, onError);
  }

  static post(name, type, onSuccess, onError) {
    const data = { name, type };
    return REST.post(url, data, onSuccess, onError);
  }

  static archive(id, onSuccess, onError) {
    return REST.patch(DeviceAPI.urlFor(id), { archived: true }, onSuccess, onError);
  }

  static restore(id, onSuccess, onError) {
    return REST.patch(DeviceAPI.urlFor(id), { archived: false }, onSuccess, onError);
  }

  static run(id, { vial = 1 }, onSuccess, onError) {
    return REST.post(`/api/devices/${id.toString()}/run/`, { vial }, onSuccess, onError);
  }

  static update(id, onSuccess, onError) {
    return REST.post(`/api/devices/${id.toString()}/update/`, {}, onSuccess, onError);
  }

  static forceUpdate(id, targetVersion, onSuccess, onError) {
    return REST.post(
      `/api/devices/${id.toString()}:update/`,
      { targetVersion },
      onSuccess,
      onError,
    );
  }

  static submitUpdateResult(id, onSuccess, onError) {
    return REST.post(`/api/devices/${id.toString()}/update/:submit/`, {}, onSuccess, onError);
  }
}
