var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isInitialized)?_c('Select',{ref:"select",staticClass:"select-method-as-preset",attrs:{"itemSelected":_vm.privateMethod,"items":_vm.sortedMethods,"labelProp":"name","hasSearch":true,"padding":_vm.padding,"placeholderSearch":_vm.placeholder,"titleBottomSheet":_vm.placeholder,"canSearchById":true,"isTransparent":_vm.isTransparent,"isDisabled":_vm.isDisabled,"canCreate":_vm.isEditable,"messageCreate":"Create method"},on:{"update:itemSelected":_vm.selectedItemChanged,"search":_vm.getListItemsDebounced,"showPopup":_vm.handleShowPopup,"hidePopup":_vm.handleHidePopup,"create":function($event){return _vm.$emit('createMethod')}},scopedSlots:_vm._u([{key:"rightSideInsideItem",fn:function(ref){
var _method = ref.item;
var isBottomSheet = ref.isBottomSheet;
var isInDropdown = ref.isInDropdown;
var hide = ref.hide;
return [(_method.favorite)?_c('IconFavorite',{staticClass:"select-method-as-preset__favorite",class:{
        'select-method-as-preset__favorite--selected':
          _vm.privateMethod && _method.id === _vm.privateMethod.id && (isBottomSheet || isInDropdown),
      }}):_vm._e(),_c('span',{staticClass:"select-method-as-preset__id",class:{
        'select-method-as-preset__id--selected':
          _vm.privateMethod && _method.id === _vm.privateMethod.id && (isBottomSheet || isInDropdown),
      }},[_vm._v("#"+_vm._s(_method.id))]),(_vm.isEditable && (isBottomSheet || isInDropdown))?_c('BtnIcon',{staticClass:"select-method-as-preset__btn-edit",class:{
        'select-method-as-preset__btn-edit--selected':
          _vm.privateMethod && _method.id === _vm.privateMethod.id && (isBottomSheet || isInDropdown),
      },attrs:{"iconMaterial":"edit"},on:{"click":function($event){$event.stopPropagation();return _vm.editMethod(_method, hide)}}}):_vm._e()]}}],null,false,104581269)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }