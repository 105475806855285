<template>
  <div class="add-suggestion">
    <div v-if="isShowAlways || isShowContent" class="add-suggestion__content">
      <slot />
    </div>

    <Btn v-else @click="showContent">{{ message }}</Btn>
  </div>
</template>

<script>
  import Btn from '@/uikitBase/btns/Btn';

  const EVENT_SHOW = 'show';

  export default {
    name: 'AddSuggestion',

    components: { Btn },

    props: {
      message: {
        type: String,
        required: true,
      },
      isShowAlways: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      isShowContent: false,
    }),

    methods: {
      showContent() {
        this.$emit(EVENT_SHOW);
        this.isShowContent = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .add-suggestion {
    &__content {
      width: 100%;
    }
  }
</style>
