var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WithKeyboardSelection',{attrs:{"ids":_vm.itemIds,"onSelect":_vm.selectItemById,"isActive":_vm.isActive,"scrollContainer":_vm.scrollContainer},on:{"highlight":_vm.previewItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var selectedId = ref.selectedId;
return [_c('ul',{staticClass:"list"},[(_vm.canCreate || (_vm.canCreateIfNotFound && _vm.items.length === 0))?_c('li',{staticClass:"list__item list__item--create",class:[("list__item--padding--" + _vm.padding)],on:{"click":function($event){return _vm.$emit('create')}}},[_c('IconMaterial',{staticClass:"list__icon-add",attrs:{"title":"add"}}),_c('span',{staticClass:"list__label"},[_vm._v(_vm._s(_vm.messageCreate))])],1):_vm._e(),_vm._l((_vm.items),function(item,index){return _c('li',{key:item.id || index,staticClass:"list__item",class:[
        ("list__item--padding--" + _vm.padding),
        {
          'list__item--active': item.id === selectedId,
          'list__item--selected': _vm.itemSelected && item.id === _vm.itemSelected.id,
        } ],attrs:{"data-id":item.id},on:{"click":function($event){return _vm.selectItem(item)}}},[_c('span',{staticClass:"list__label",attrs:{"title":item[_vm.labelProp]}},[_vm._v(_vm._s(item[_vm.labelProp]))]),(_vm.$scopedSlots.info)?_c('span',{staticClass:"list__label-info"},[_vm._t("info",null,{"item":item})],2):_vm._e()])})],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }