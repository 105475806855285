<template>
  <PopupHelper v-if="isCompared" text="Go to compare page" placement="bottom">
    <Btn key="openComparison" padding="sm" type="transparent" @click="openComparison">
      <IconMaterial title="check" />
      <IconMaterial title="view_agenda" />
    </Btn>
  </PopupHelper>
  <PopupHelper v-else text="Add to compare" placement="bottom">
    <Btn key="compare" padding="sm" type="transparent" @click="compare">
      <IconMaterial title="view_agenda" />
    </Btn>
  </PopupHelper>
</template>

<script>
  import ChromatogramsCompare from '@/services/ChromatogramsCompare.ts';
  import Btn from '@/uikitBase/btns/Btn';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper';

  export default {
    name: 'BtnCompare',

    components: {
      PopupHelper,
      IconMaterial,
      Btn,
    },

    props: {
      id: {
        type: Number,
        required: true,
      },
    },

    data() {
      return {
        isCompared: ChromatogramsCompare.isCompared(this.id),
        onIDsUpdate: () => {
          this.updateIsCompared();
        },
      };
    },

    watch: {
      id() {
        this.updateIsCompared();
      },
    },

    mounted() {
      ChromatogramsCompare.subscribe(this.onIDsUpdate);
    },

    beforeDestroy() {
      ChromatogramsCompare.unsubscribe(this.onIDsUpdate);
    },

    methods: {
      updateIsCompared() {
        this.isCompared = ChromatogramsCompare.isCompared(this.id);
      },

      compare() {
        ChromatogramsCompare.add(this.id);

        this.notify('Added to comparison', 1);

        this.isCompared = true;
      },

      openComparison() {
        const url = this.$router.resolve({ name: 'chromatograms compare' });
        window.open(url.href, '_blank');
      },
    },
  };
</script>
