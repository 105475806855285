<template>
  <div class="standard-model-field">
    <model-field
      ref="input"
      :value="value"
      :type="type"
      :label="label"
      :is-title="isTitle"
      :error="isError"
      :disabled="disabled"
      :hints="hints"
      :canSelectOnlyFromHints="canSelectOnlyFromHints"
      :after-slot-visible="afterSlotVisible"
      :helper="helper"
      :isMultiline="isMultiline"
      @focus="selectIfDefaultValue"
      @update:valid="updateValid($event)"
      @update:value="updateValue($event)"
    >
      <template v-slot:after>
        <slot name="after"></slot>
      </template>
    </model-field>
    <div v-if="error" class="standard-model-field__error component-item">
      {{ error }}
    </div>
  </div>
</template>

<script>
  import ModelFieldComponent from 'components/element/ModelFieldComponent.vue';

  const ModelEvent = 'update:value';
  const ValidEvent = 'update:valid';

  export default {
    name: 'StandardModelFieldComponent',
    components: {
      'model-field': ModelFieldComponent,
    },
    model: {
      prop: 'value',
      event: ModelEvent,
    },
    props: {
      value: {},
      defaultValue: {
        type: [String, Number, Boolean],
      },
      type: String,
      label: String,
      disabled: {
        type: Boolean,
        default: false,
      },
      isTitle: {
        type: Boolean,
        default: false,
      },
      afterSlotVisible: {
        type: Boolean,
        default: false,
      },
      error: String,
      hints: Array,
      helper: String,

      isMultiline: {
        type: Boolean,
        default: false,
      },
      canSelectOnlyFromHints: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isError() {
        return !!this.error;
      },
    },
    methods: {
      updateValue(event) {
        this.$emit(ModelEvent, event);
      },
      updateValid(event) {
        this.$emit(ValidEvent, event);
      },
      selectIfDefaultValue() {
        if (this.value === this.defaultValue) {
          this.$refs.input.select();
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .standard-model-field {
    display: flex;
    flex-direction: column;

    .model-field {
      flex: 0 0;
    }

    &__error {
      flex: 0 0;
      color: $color-text-danger;
      margin-bottom: 10px;
    }
  }
</style>
