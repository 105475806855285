<template>
  <div class="column-form">
    <div class="column-form__wrapper-name">
      <StandardModelFieldComponent
        :value="privateColumnData.name"
        style="flex: 1 1"
        :type="'str'"
        :is-title="true"
        :label="'Column name'"
        :error="errors.name"
        :disabled="isArchived || !hasPermissionToEdit"
        @update:value="setName"
      />

      <template v-if="canArchive && hasPermissionToEdit">
        <button
          v-if="isArchived"
          class="button--square-image column-archive-button"
          @click="restore"
        >
          <i class="material-icons material-icon--18">restore</i>Restore
        </button>
        <button
          v-if="!isArchived"
          class="button--square-image column-archive-button"
          @click="archive"
        >
          <i class="material-icons material-icon--18">delete</i>Archive
        </button>
      </template>
    </div>

    <StateArchived v-if="isArchived" class="column-form__state-archived" />

    <ColumnEditComponent
      v-model="privateColumnData"
      :errors="errors"
      :disabled="isArchived || !hasPermissionToEdit"
      class="mt-3"
      @updateField="$emit('updateField', $event)"
    />
  </div>
</template>

<script>
  import ColumnEditComponent from 'components/block/ColumnEditComponent';
  import StateArchived from '@/uikitProject/states/StateArchived';
  import StandardModelFieldComponent from 'components/element/StandardModelFieldComponent';
  import { AuthService } from '@/services/auth.service';

  const EVENT_MODEL = 'update:columnData';
  const EVENT_ARCHIVE = 'update:isArchive';

  export default {
    name: 'ColumnForm',

    components: {
      StandardModelFieldComponent,
      StateArchived,
      ColumnEditComponent,
    },

    model: {
      prop: 'columnData',
      event: EVENT_MODEL,
    },

    props: {
      isArchived: {
        type: Boolean,
        required: true,
      },
      canArchive: {
        type: Boolean,
        required: true,
      },
      errors: {
        type: Object,
        required: true,
      },
      columnData: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      hasPermissionToEdit: AuthService.userData().role === 'admin',
    }),

    computed: {
      privateColumnData: {
        get() {
          return this.columnData;
        },
        set(data) {
          this.$emit(EVENT_MODEL, data);
        },
      },
    },

    methods: {
      archive() {
        this.$emit(EVENT_ARCHIVE, true);
      },
      restore() {
        this.$emit(EVENT_ARCHIVE, false);
      },
      setName(name) {
        this.privateColumnData = {
          ...this.privateColumnData,
          name,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .column-form {
    &__wrapper-name {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    &__state-archived {
      display: block;
      margin: 10px 0;
    }
  }
</style>
