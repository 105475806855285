<template>
  <div class="select" @click.stop>
    <MQ mq="desktop">
      <DesktopSelect
        ref="desktopSelect"
        v-bind="{ ...$props, ...$attrs }"
        class="select__desktop"
        v-on="$listeners"
      >
        <template #leftSideInsideItem="{ item, isInDropdown, hide }">
          <slot name="leftSideInsideItem" :item="item" :isInDropdown="isInDropdown" :hide="hide" />
        </template>
        <template #rightSideInsideItem="{ item, isInDropdown, hide }">
          <slot name="rightSideInsideItem" :item="item" :isInDropdown="isInDropdown" :hide="hide" />
        </template>
      </DesktopSelect>
    </MQ>
    <MQ mq="mobile">
      <MobileSelect ref="mobileSelect" v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
        <template #mobileTrigger>
          <slot name="mobileTrigger" />
        </template>
        <template #rightSideInsideItem="{ item, isBottomSheet, hide }">
          <slot
            name="rightSideInsideItem"
            :item="item"
            :isBottomSheet="isBottomSheet"
            :hide="hide"
          />
        </template>
      </MobileSelect>
    </MQ>
  </div>
</template>

<script>
  import { model, props } from './shared/config';
  import DesktopSelect from './desktop/Select.desktop';
  import MobileSelect from './mobile/Select.mobile';

  export default {
    name: 'Select',

    components: {
      DesktopSelect,
      MobileSelect,
    },

    model,

    props,

    methods: {
      focus() {
        this.$refs.desktopSelect?.focus();
        this.$refs.mobileSelect?.showPopup();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .select {
    width: 100%;

    &__desktop {
      width: 100%;
    }
  }
</style>
