<template>
  <PopupHelper text="Click to test the connection. The light on your device will blink">
    <Btn
      height="xxs"
      padding="xxs"
      :disabled="!canTestConnection"
      class="ping"
      @click="testConnection"
    >
      <template #iconLeft>
        <IconMaterial title="lightbulb" class="ping__icon" />
      </template>
      Ping
    </Btn>
  </PopupHelper>
</template>

<script>
  import Btn from '@/uikitBase/btns/Btn.vue';
  import { alltestaOrCromiteCommands } from '@/constants/hardware/commands';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper.vue';
  import IconMaterial from '@/uikitBase/icons/IconMaterial.vue';

  export default {
    name: 'Ping',

    components: { IconMaterial, PopupHelper, Btn },

    props: {
      deviceSocket: {
        type: Object,
        required: true,
      },
      device: {
        type: Object,
        required: true,
      },
    },

    computed: {
      commands() {
        return (
          (this.isCromite
            ? this.device.state.cromite_details?.commandDetails
            : this.device.state.alltesta_details?.commandDetails) ?? {}
        );
      },

      canTestConnection() {
        return this.commands[alltestaOrCromiteCommands.PING]?.status.available;
      },

      isCromite() {
        return this.device?.model === 'cmt';
      },
    },

    methods: {
      testConnection() {
        this.showNotificationIfRpcError(() =>
          this.deviceSocket.command(
            this.isCromite ? 'cromite' : 'alltesta',
            alltestaOrCromiteCommands.PING,
          ),
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .ping {
    margin-top: 2px;

    &__icon {
      color: $pure-color__yellow;
    }
  }
</style>
