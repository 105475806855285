<template>
  <div
    v-if="
      configuration.hasOwnProperty('fraction_collector_valve') &&
      configuration.fraction_collector_valve != null
    "
    class="hardware-tile"
  >
    <div class="hardware-tile__wrapper-title">
      <div>
        <i class="hardware-tile__title-icon">settings_applications</i>
        <h4>Fraction collector valve</h4>
      </div>
      <Information :data="popupInfoData" class="hardware-tile__information" />
    </div>

    <hardware-state-component :state="currentState" data-test-id="valve-loop-status" />

    <HardwareErrorComponent :errors="errors" :error-list="errorList" />

    <template v-if="stateDetails">
      <div style="max-width: 100px; margin: 16px auto 0">
        <template v-if="operation === 'MOVING_TO_POSITION'">
          <img src="@/assets/img/valve-12.svg" />
        </template>
        <template v-else>
          <img v-if="stateDetails.position === 2" src="@/assets/img/valve-16.svg" />
          <img v-else src="@/assets/img/valve-12.svg" />
          <span
            v-if="stateDetails.position === 2"
            class="text-green mb-2"
            style="font-weight: bold"
          >
            Bypass collection
          </span>
          <span v-else class="text-process mb-2" style="font-weight: bold">Allow collection</span>
        </template>
      </div>
    </template>

    <div v-if="operation === 'IN_POSITION_AND_CHARGING'" class="operation">
      <i class="hardware-tile__operation">rotate_left</i>
      <span class="text-green mb-2">Getting ready</span>
    </div>
  </div>
</template>

<script>
  import Base from './Base.vue';
  import HardwareErrorComponent from 'components/element/HardwareErrorComponent';
  import { GENERAL_ERRORS } from '@/constants/hardware/errors';
  import Information from 'components/block/hardware/private/Information';

  export default {
    components: { Information, HardwareErrorComponent },
    extends: Base,

    computed: {
      currentState() {
        return this.state?.fraction_collector_valve;
      },
      stateDetails() {
        return this.state?.fraction_collector_valve_details;
      },
      operation() {
        return this.stateDetails?.operation;
      },
      errors() {
        return this.stateDetails?.errors;
      },
      errorList() {
        return { ...GENERAL_ERRORS };
      },

      popupInfoData() {
        const { firmware_version, id, serial } = this.configuration.fraction_collector_valve;

        return [
          {
            name: 'Id',
            value: id,
          },
          {
            name: 'Serial',
            value: serial,
          },
          {
            name: 'Firmware Version',
            value: firmware_version
              ? `${firmware_version.major}.${firmware_version.minor}`
              : 'Not available',
          },
        ];
      },
    },
  };
</script>
<style lang="scss">
  .operation {
    text-align: center;
    align-content: center;
    margin: 32px auto auto;
  }
</style>
