<template>
  <div class="chromatograms">
    <div
      ref="divChromatogramsChart"
      v-resize:throttle="onResize"
      class="chromatograms-chart disable-selection"
    />
  </div>
</template>

<script>
  import resize from 'vue-resize-directive';
  import ChromatogramPainter from 'utils/chart/chromatogram.ts';
  import { LOST_VALUE, CALIBRATION_VALUE } from '@/utils/chart/chart-constants';

  const hasErrorValueToReplace = (value) => value === LOST_VALUE || value === CALIBRATION_VALUE;

  export default {
    name: 'ChromatogramsComponent',

    directives: { resize },

    props: {
      standards: Array,
      injectionHighlightedId: Number,
    },

    data: () => ({
      chart: null,
    }),

    computed: {
      injections() {
        return this.standards.flatMap((standard) => standard.injections);
      },
    },

    watch: {
      injections() {
        this.initChart();
      },
      injectionHighlightedId(id) {
        if (id) {
          const injection = this.injections.find((injection) => injection.id === id);
          this.chart.highlightMeasurement(injection.primary_measurement_id, {
            colorActive: '#f06',
            colorInactive: 'rgba(255,0,102, 0.15)',
          });
        } else {
          this.chart.disableMeasurementsHighlighting();
        }
      },
    },

    mounted() {
      this.initChart();
    },

    methods: {
      onResize() {
        this.initChart();
      },

      initChart() {
        const scaleConfig = {
          factor: 10,
          enableOverlay: true,
          indexMax: this.indexMax,
          padding: { top: 0, right: 0, bottom: 0, left: 0 },
          hasGrid: true,
        };

        this.chart = new ChromatogramPainter(
          this.$refs.divChromatogramsChart,
          scaleConfig,
          {
            background: 'rgb(247, 247, 247)',
            chartAxis: 'rgb(153, 153, 153)',
            scale: 'rgb(230, 230, 230)',
            timeLabels: 'rgb(168, 168, 168)',
            mauLabels: 'rgb(168, 168, 168)',
            peakNums: '#4c22ff',
            peakLines: '#000',
            text: '#00000088',
            line: '#f06',
            baseline: 'rgba(0,41,255,0.73)',
          },
          { canSelectPeak: false, hasAxisLabels: true, hasTimeScale: true, hasMauScale: true },
        );

        this.injections.forEach((injection) => {
          const measurement = injection.measurements.find(
            (measurement) => measurement.id === injection.primary_measurement_id,
          );
          const _data = measurement.processedData ?? measurement.rawData;

          if (_data == null) {
            return;
          }

          const hasErrorValues = _data.some(hasErrorValueToReplace);
          if (hasErrorValues) {
            for (let i = 0; _data.length > i; i++) {
              if (hasErrorValueToReplace(_data[i])) {
                _data[i] = 0;
              }
            }
          }

          this.chart.addMeasurement(_data, measurement.mps, measurement.id, {
            doRedraw: true,
            drawOnly: { polyline: true },
          });
        });

        this.chart.resetZoom();

        // TODO The problem is deeper. We need to recalculate z-indexes. It's quite difficult.
        // Now this should work. I hope we refactor the chart sooner than catching another error
        Object.values(this.injections).forEach((injection) => {
          this.chart.highlightMeasurement(injection.id, {
            colorActive: '#f06',
            colorInactive: 'rgba(255,0,102, 0.15)',
          });
        });
        this.chart.disableMeasurementsHighlighting();
      },
    },
  };
</script>

<style>
  .chromatograms {
    padding: 0;
    margin: 0;
    width: 300px;
    height: 300px;
  }

  .chromatograms-chart {
    height: 100%;
    width: 100%;
  }
</style>
