<template>
  <div class="chromatogram">
    <div
      v-show="isInitialized && hasData"
      v-resize:throttle="onResize"
      class="chromatogram__wrapper-chart"
    >
      <div
        ref="chart"
        class="chromatogram__chart"
        :style="styles"
        @click="chartMouseClick"
        @mouseleave="chartMouseLeave"
        @mouseenter="chartMouseEnter"
        @mousedown="chartMouseDown"
        @mousemove="chartMouseMove"
        @mouseup="chartMouseUp"
        @touchstart="chartTouchStart"
        @touchmove="chartTouchMove"
        @touchend="chartTouchEnd"
        @dblclick="chartDblClick"
      />
    </div>
    <nothing-there-component v-show="!hasData && isInitialized">
      Measurement is empty
    </nothing-there-component>
  </div>
</template>

<script>
  import _ from 'lodash';
  import resize from 'vue-resize-directive';

  import NothingThereComponent from 'components/element/NothingThereComponent';

  import {
    CHART_COLORS,
    MAGIC_VALUES_ARE_NOT_SUPPORTED_AFTER_UNIXTIME_MS,
    OUT_OF_DETECTION_VALUE,
  } from 'utils/chart/chart-constants.ts';

  import ChromatogramPainter, { DEFAULT_PADDING } from 'utils/chart/chromatogram.ts';
  import colorFromNm from 'utils/chart/chromatogram-colors.ts';
  import FPSService from '@/services/FPSService.ts';
  import ChromatogramHelper from 'components/blocks/charts/chromatogram/private/ChromatogramHelper.ts';

  const EVENT_ZOOM = 'zoom';
  const EVENT_CREATED = 'created';

  // key - level, value - throttle time ms
  const PERFORMANCE_LEVELS = {
    5: 500,
    4: 1000,
    3: 2000,
    2: 3000,
    1: 4000,
  };

  const DEFAULT_PERFORMANCE_LEVEL = 5;
  const MAX_PERFORMANCE_LEVEL = 5;
  const MIN_PERFORMANCE_LEVEL = 1;

  export default {
    name: 'PrChartChromatogramMultiple',

    components: {
      NothingThereComponent,
    },

    directives: { resize },

    props: {
      measurements: {
        type: Array,
        required: true,
      },
      colorScheme: { type: Object, default: () => CHART_COLORS },
      timeMin: {
        type: Number,
        default: 0,
      },
      // Draw chart on the plot not smaller than 'timeMax', value in minutes
      timeMax: {
        type: Number,
      },
      hasPreferences: {
        type: Boolean,
        default: true,
      },
      doPerformanceOptimisations: {
        type: Boolean,
        default: false,
      },
      isApplyBaselines: {
        type: Boolean,
        required: true,
      },
      hasPadding: { type: Boolean, default: true },
      heightPx: {
        type: Number,
        default: 300,
      },
      measurementColors: {
        type: Object,
      },
      hasOnlyHorizontalZoom: {
        type: Boolean,
      },
      doShowExactBounds: {
        type: Boolean,
      },
      hasMauScale: {
        type: Boolean,
      },

      hasSpacesIfOutOfDetectionTime: {
        type: Boolean,
      },
      isShowFullChart: {
        type: Boolean,
      },
      detectionTime: {
        type: Object,
      },

      creationDate: {
        type: Date,
      },
    },

    data: () => ({
      // chart instances
      svgOverlay: null,
      svgChart: null,

      chart: {
        mouseDownX: -1,
        mouseDownY: -1,
        touchAccepted: null,
        zoomRect: null,
      },

      mouse: {
        startMinutes: null,
        startMau: null,
        eventStart: null,
      },

      isInitialized: false,

      wasDrawn: false,
      willResetZoom: false,
      isZoomed: false,
      onUpdateMeasurementsThrottled: null,

      fpsService: null,
      optimizeChartUpdateThrottled: null,
      // performanceLevel must be from 1 to 5
      performanceLevel: DEFAULT_PERFORMANCE_LEVEL,
      averageFPS: 60,
    }),

    computed: {
      hasData() {
        return this.measurements.some((measurement) => measurement.data?.length);
      },
      minutesMin() {
        return this.privateTimeMin;
      },
      // Максимальное значение по оси X (время)
      minutesMax() {
        return this.privateTimeMax;
      },
      hasBaselineSwitcher() {
        return this.measurements.some(({ baseline }) => Boolean(baseline?.length));
      },
      styles() {
        return {
          height: `${this.heightPx}px`,
        };
      },

      privateTimeMin() {
        if (this.isShowFullChart) {
          return this.timeMin;
        }

        return this.detectionTime?.start ?? this.timeMin;
      },
      privateTimeMax() {
        if (this.isShowFullChart) {
          return this.timeMax;
        }

        if (this.detectionTime && this.detectionTime.end) {
          return this.timeMax
            ? Math.min(this.detectionTime.end, this.timeMax)
            : this.detectionTime.end;
        }
        return this.timeMax;
      },
    },

    watch: {
      hasData(value) {
        !value && this.svgChart.clearAllMeasurements();
      },
      minutesMin() {
        this.drawMainChart();
      },
      minutesMax() {
        this.drawMainChart();
      },
      hasMauScale() {
        this.svgChart.updateHasMauScale(this.hasMauScale);
        this.drawMainChart();
      },
      measurements() {
        this.onUpdateMeasurementsThrottled();

        if (this.doPerformanceOptimisations) {
          this.optimizeChartUpdateThrottled();
        }
      },
      isApplyBaselines() {
        this.drawMainChart({ doResetZoom: true });
      },
      doPerformanceOptimisations: {
        handler(value) {
          if (value) {
            this.fpsService = new FPSService({
              isStart: true,
              maxFramesForMeasurement: 10,
              maxSavedFrames: 10,
              preparationPeriodMs: 5000,
            });
          } else {
            this.fpsService?.stop();
          }
        },
        immediate: true,
      },
      measurementColors() {
        this.drawMainChart();
      },
    },

    created() {
      this.$emit(EVENT_CREATED);
    },

    mounted() {
      this.onUpdateMeasurementsThrottled = _.throttle(this.onUpdateMeasurements, 250, {
        trailing: true,
      });
      this.optimizeChartUpdateThrottled = _.throttle(this.optimizeChartUpdate, 15000, {
        trailing: true,
      });
      this.initChart();
      this.isInitialized = true;
    },

    beforeDestroy() {
      this.fpsService?.stop();
    },

    methods: {
      // Init
      initChart() {
        const { colorScheme } = this;
        const scaleConfig = {
          factor: 10,
          enableOverlay: true,
          minutesMin: this.minutesMin,
          minutesMax: this.minutesMax,
          padding: this.hasPadding ? DEFAULT_PADDING : { top: 0, bottom: 0, left: 0, right: 0 },
          hasAutoZoomMinBounds: true,
        };

        this.svgChart = new ChromatogramPainter(this.$refs.chart, scaleConfig, colorScheme, {
          canSelectPeak: false,
          hasAxisLabels: false,
          hasTimeScale: true,
          hasMauScale: this.hasMauScale,
          hasOnlyMinMaxMau: false,
          doShowExactBounds: this.doShowExactBounds,
          hasSpacesIfOutOfDetectionTime: this.hasSpacesIfOutOfDetectionTime,
        });
        this.svgOverlay = this.svgChart.overlayDraw;
        this.drawMainChart({ doResetZoom: true });

        this.initZoomRect();
      },
      initZoomRect() {
        this.chart.zoomRect = this.svgOverlay
          .rect(0, 0)
          .stroke({
            color: '#000',
            width: 2,
            dasharray: '10',
          })
          .fill('none')
          .hide();
      },

      // Main
      async drawMainChart({ doResetZoom = false } = {}) {
        const hasData = this.measurements.some(({ data }) => Boolean(data.length));
        if (!hasData) return;

        await this.$nextTick();
        if (this.minutesMin) this.svgChart.updateMinutesMin(this.minutesMin);
        if (this.minutesMax) this.svgChart.updateMinutesMax(this.minutesMax);

        this.svgChart.clearAllMeasurements({ doRedraw: false });

        this.measurements.forEach((measurement) => {
          const data = ChromatogramHelper.getModifiedData({
            data: measurement.data,
            baseline: measurement.baseline,
            isApplyBaseline: this.isApplyBaselines && measurement.baseline?.length,
            isSupportMagicValues:
              (this.creationDate ?? measurement.creationDate)?.getTime() <
              MAGIC_VALUES_ARE_NOT_SUPPORTED_AFTER_UNIXTIME_MS,
          });

          if (this.hasSpacesIfOutOfDetectionTime) {
            const startPosition = this.isShowFullChart
              ? null
              : this.detectionTime?.start * measurement.mps * 60;
            const endPosition = this.isShowFullChart
              ? null
              : this.detectionTime?.end * measurement.mps * 60;

            this.svgChart.addMeasurement(
              startPosition
                ? [
                    ...Array(Math.round(startPosition)).fill(OUT_OF_DETECTION_VALUE),
                    ...data.slice(startPosition, endPosition),
                    ...Array(
                      Math.round(data.length - endPosition > 0 ? data.length - endPosition : 0),
                    ).fill(OUT_OF_DETECTION_VALUE),
                  ]
                : data,
              measurement.mps,
              measurement.id,
              {
                color:
                  this.measurementColors?.[measurement?.id] ?? colorFromNm(measurement?.meta?.nm),
                drawOnly: { peaks: false, baseline: false, polyline: true },
                doRedraw: false,
              },
            );
          } else {
            this.svgChart.addMeasurement(data, measurement.mps, measurement.id, {
              color:
                this.measurementColors?.[measurement?.id] ?? colorFromNm(measurement?.meta?.nm),
              drawOnly: { peaks: false, baseline: false, polyline: true },
              doRedraw: false,
            });
          }
        });

        if (doResetZoom) {
          this.svgChart.resetZoom();
        } else {
          this.svgChart.paintAll({ peaks: false, baseline: false, polyline: true });
        }
      },

      // Zoom
      zoom({ pStart, pFinish }) {
        const coordsStart = this.svgChart.getMinutesMau(pStart);
        const coordsFinish = this.svgChart.getMinutesMau(pFinish);

        const startX = coordsStart.minutes;
        const startY = coordsStart.mau;
        const finishX = coordsFinish.minutes;
        const finishY = coordsFinish.mau;

        const minimumIntervalInMinutes = 0.00166; // 0.1 second

        const hasMovementX = Math.abs(finishX - startX) > minimumIntervalInMinutes;
        const hasMovementY = this.hasOnlyHorizontalZoom || Math.abs(finishY - startY) !== 0;

        if (hasMovementX && hasMovementY) {
          this.setZoomRange(
            coordsStart.minutes,
            coordsFinish.minutes,
            coordsStart.mau,
            coordsFinish.mau,
          );
          this.isZoomed = true;
        } else {
          this.svgChart.resetZoom();
          this.isZoomed = false;
        }

        this.chart.zoomRect.hide();
      },
      setZoomRange(minutesMin, minutesMax, min, max) {
        let [_minutesMin, _minutesMax, _min, _max] = [minutesMin, minutesMax, min, max];
        if (_minutesMin > _minutesMax) {
          [_minutesMin, _minutesMax] = [_minutesMax, _minutesMin];
        }

        if (_min > _max) {
          [_min, _max] = [_max, _min];
        }

        const zoomBounds = {
          minutesMin: _minutesMin,
          minutesMax: _minutesMax,
        };

        if (!this.hasOnlyHorizontalZoom) {
          zoomBounds.mauMin = _min;
          zoomBounds.mauMax = _max;
        }

        this.svgChart.setZoom(zoomBounds);
      },
      resetZoom() {
        this.svgChart.resetZoom();
      },

      // Events
      onUpdateMeasurements() {
        const doResetZoom = this.willResetZoom || !this.wasDrawn || !this.isZoomed;
        this.drawMainChart({ doResetZoom });
        this.willResetZoom = false;
        this.wasDrawn = true;
      },
      onResize() {
        this.svgChart.updateSize();
      },
      chartDblClick() {
        if (window.getSelection) window.getSelection().removeAllRanges();
        else if (document.selection) document.selection.empty();
        this.svgChart.resetZoom();
        this.isZoomed = false;
      },
      chartMouseLeave() {
        this.chart.zoomRect.hide();
      },
      chartMouseEnter(e) {
        if (e.buttons === 0) return;
        if (this.chart.mouseDownX === -1) return;

        this.chart.zoomRect.show();
      },
      chartMouseClick() {
        // this.svgChart.updateRect();
        // this.svgChart.resetZoom();
      },
      chartMouseDown(e) {
        this.svgChart.updateRect();

        const p = this.svgChart.getXY(e);
        this.chart.mouseDownX = p.x;
        this.chart.mouseDownY = p.y;

        const coords = this.svgChart.getMinutesMau(p);
        this.mouse.startMinutes = coords.minutes;
        this.mouse.startMau = coords.mau;
        this.mouse.eventStart = e;

        this.chart.zoomRect.move(p.x, p.y).size(0, 0).show();
      },
      chartMouseMove(e) {
        if (e.buttons === 0) return;

        const p = this.svgChart.getXY(e);

        this.hasOnlyHorizontalZoom
          ? this.chart.zoomRect
              .move(Math.min(p.x, this.chart.mouseDownX), 24)
              .size(
                Math.abs(p.x - this.chart.mouseDownX),
                this.svgOverlay?.node.getBoundingClientRect().height - 48,
              )
          : this.chart.zoomRect
              .move(Math.min(p.x, this.chart.mouseDownX), Math.min(p.y, this.chart.mouseDownY))
              .size(Math.abs(p.x - this.chart.mouseDownX), Math.abs(p.y - this.chart.mouseDownY));
      },
      chartMouseUp(e) {
        const { eventStart } = this.mouse;
        if (this.chart.mouseDownX === -1) return;

        const pFinish = this.svgChart.getXY(e);
        const pStart = this.svgChart.getXY(eventStart);

        this.$emit(EVENT_ZOOM, { pStart, pFinish });
        this.zoom({ pStart, pFinish });

        this.chart.mouseDownX = -1;
      },
      // todo: merge with @chartMouseDown
      chartTouchStart(e) {
        this.svgChart.updateRect();

        this.touchAccepted = null;
        const p = this.svgChart.getXY(e.changedTouches[0]);
        this.chart.mouseDownX = p.x;
        this.chart.mouseDownY = p.y;

        const coords = this.svgChart.getMinutesMau(p);
        this.mouse.startMinutes = coords.minutes;
        this.mouse.startMau = coords.mau;
        this.mouse.eventStart = e.changedTouches[0];

        this.chart.zoomRect.move(p.x, p.y).size(0, 0).show();
      },
      // todo: merge with @chartMouseMove
      chartTouchMove(e) {
        const p = this.svgChart.getXY(e.changedTouches[0]);
        const dx = Math.abs(p.x - this.chart.mouseDownX);
        const dy = Math.abs(p.y - this.chart.mouseDownY);

        if (this.touchAccepted == null) {
          if (dx * dx + dy * dy > 400) {
            if (dx < dy) {
              this.touchAccepted = false;
              this.chartMouseLeave();
              this.sendTouchEvent(
                e.changedTouches[0].pageX,
                e.changedTouches[0].pageY,
                document.body,
                'touchstart',
              );
              return;
            }
            this.touchAccepted = true;
          }
        } else if (this.touchAccepted === false) {
          return;
        }

        e.returnValue = false;

        this.hasOnlyHorizontalZoom
          ? this.chart.zoomRect
              .move(Math.min(p.x, this.chart.mouseDownX), 24)
              .size(dx, this.svgOverlay?.node.getBoundingClientRect().height - 48)
          : this.chart.zoomRect
              .move(Math.min(p.x, this.chart.mouseDownX), Math.min(p.y, this.chart.mouseDownY))
              .size(dx, dy);
      },
      // todo: merge with @chartMouseUp
      chartTouchEnd(e) {
        if (this.touchAccepted === false) {
          return;
        }

        e.returnValue = true;

        if (this.chart.mouseDownX === -1) return;

        const { eventStart } = this.mouse;
        const pStart = this.svgChart.getXY(eventStart);
        const pFinish = this.svgChart.getXY(e.changedTouches[0]);

        this.$emit(EVENT_ZOOM, { pStart, pFinish });
        this.zoom({ pStart, pFinish });

        this.chart.mouseDownX = -1;
      },
      sendTouchEvent(x, y, element, eventType) {
        const touchObj = new Touch({
          identifier: Date.now(),
          target: element,
          clientX: x,
          clientY: y,
          radiusX: 2.5,
          radiusY: 2.5,
          rotationAngle: 10,
          force: 0.5,
        });

        const touchEvent = new TouchEvent(eventType, {
          cancelable: true,
          bubbles: true,
          touches: [touchObj],
          targetTouches: [],
          changedTouches: [touchObj],
          shiftKey: true,
        });

        element.dispatchEvent(touchEvent);
      },

      optimizeChartUpdate() {
        if (!this.fpsService) {
          return;
        }

        const { averageFPS } = this.fpsService;

        if (averageFPS < 30 && this.performanceLevel > 1) {
          this.performanceLevel -= 1;

          this.onUpdateMeasurementsThrottled = _.throttle(
            this.onUpdateMeasurements,
            PERFORMANCE_LEVELS[this.performanceLevel],
            { trailing: true },
          );
        }

        const FPSDifference = averageFPS - this.averageFPS;
        if (Math.abs(FPSDifference) > 7) {
          const oldPerformanceLevel = this.performanceLevel;
          if (FPSDifference < 0 && this.performanceLevel > MIN_PERFORMANCE_LEVEL) {
            this.performanceLevel -= 1;
          } else if (this.performanceLevel < MAX_PERFORMANCE_LEVEL) {
            this.performanceLevel += 1;
          }

          if (oldPerformanceLevel !== this.performanceLevel) {
            this.averageFPS = averageFPS;

            this.onUpdateMeasurementsThrottled = _.throttle(
              this.onUpdateMeasurements,
              PERFORMANCE_LEVELS[this.performanceLevel],
              { trailing: true },
            );
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chromatogram {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &__preferences {
      padding: 0 32px;
    }

    &__wrapper-chart {
      position: relative;
      width: 100%;
    }

    &__chart {
      width: 100%;
      margin: 0;
    }
  }
</style>
