export const EVENT_MODEL = 'model';

export const model = {
  prop: 'vial',
  event: EVENT_MODEL,
};

export const props = {
  vial: {
    type: [String, Number],
  },
  placement: {
    type: String,
    default: 'bottom-start',
  },
  // Config with number of rows and columns.
  // Format: 96_8x12
  trayConfig: {
    required: true,
    validator: (value) => typeof value === 'string' || value === null,
  },
  title: {
    type: String,
  },
  isLazy: {
    type: Boolean,
    default: false,
  },
  usedVials: {
    type: Array,
    default: () => [],
  },
};
