<template>
  <Tippy
    trigger="mouseenter"
    :placement="placement"
    :duration="[67, 0]"
    arrow
    :popperOptions="popperOptions"
    theme="lightyellow"
    class="popup-helper"
    :class="{
      'popup-helper--fullscreen': isFullscreen,
    }"
    @click.native="$emit('click')"
  >
    <template #trigger>
      <slot />
    </template>
    <template #default>
      <div class="popup-helper__popup">
        {{ text }}
      </div>
    </template>
  </Tippy>
</template>

<script>
  import Tippy from '@/uikitBase/popups/vueTippy/Tippy';

  export default {
    name: 'PopupHelper',

    components: {
      Tippy,
    },

    props: {
      text: {
        type: String,
        required: true,
      },
      placement: {
        type: String,
        required: false,
        default: 'top-start',
      },
      isFullscreen: {
        type: Boolean,
      },
    },

    computed: {
      popperOptions() {
        return {
          modifiers: {
            preventOverflow: {
              boundariesElement: 'window',
              enabled: true,
            },
          },
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .popup-helper {
    display: flex;
    align-items: center;

    &__popup {
      max-width: 300px;
      alignment: left;
      text-align: left;
      padding: 16px;
    }
  }
</style>

<style lang="scss">
  .popup-helper {
    &--fullscreen {
      > div {
        width: 100%;
      }
    }
  }
</style>
