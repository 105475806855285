<template>
  <div class="device-method-column">
    <template>
      <template v-if="(device && device.id) || method || column">
        <template v-if="device && device.id">
          <div class="device-method-column__device">
            <div
              class="device-method-column__label"
              :class="{
                'device-method-column__label--wide': postprocessings,
              }"
            >
              Device
            </div>
            <template v-if="disableClickAway">{{ device.name }}</template>
            <div v-else class="device-method-column__wrapper-link-device">
              <router-link :to="linkToDevice" style="display: inline">{{
                device.name
              }}</router-link>
            </div>
            <span v-if="vial" class="ml-2" style="flex: none">Vial {{ vial }}</span>
          </div>
        </template>
        <template v-if="method">
          <MethodPreviewModal
            :name="`method-preview-${id}}`"
            :method="method"
            :isInSharedMode="isInSharedMode"
          />

          <div style="display: flex; width: fit-content; max-width: 100%; align-items: center">
            <div
              class="device-method-column__label"
              :class="{
                'device-method-column__label--wide': postprocessings,
              }"
              @click="showPreviewModal"
            >
              Method
            </div>
            <PopupInfoMethodColumn
              :method="method"
              :isDisabled="methodExpanded"
              class="device-method-column__link"
              @click="showPreviewModal"
            >
              <a @click="methodExpanded = !methodExpanded"> #{{ method.id }} {{ method.name }} </a>
            </PopupInfoMethodColumn>
            <BtnIcon
              type="transparent"
              iconMaterial="my_library_books"
              class="device-method-column__btn-details"
              @click="showPreviewModal"
            />
            <BtnIcon
              v-if="!isInSharedMode"
              title="Open in new page"
              iconMaterial="open_in_new"
              class="device-method-column__btn-open-in-new"
              @click="openMethodPreviewPage"
            />
          </div>
          <InfoMethod
            v-if="methodExpanded"
            :method="method"
            bgColor="#ecefff"
            style="margin: 8px 0"
            @click="showPreviewModal"
          />
        </template>
        <template v-if="column">
          <div class="device-method-column__column-title">
            <div
              class="device-method-column__label"
              :class="{
                'device-method-column__label--wide': postprocessings,
              }"
            >
              Column
            </div>
            <PopupInfoMethodColumn
              :column="column"
              :isDisabled="columnExpanded"
              class="device-method-column__link"
            >
              <a @click="columnExpanded = !columnExpanded">
                {{ column.name }}
              </a>
            </PopupInfoMethodColumn>
            <BtnIcon
              v-if="!isInSharedMode"
              title="Open in new page"
              iconMaterial="open_in_new"
              class="device-method-column__btn-open-in-new"
              @click="openColumnPage"
            />
          </div>
          <InfoColumn
            v-if="columnExpanded"
            :column="column"
            bgColor="#ecefff"
            style="margin: 8px 0"
          />
        </template>
        <template v-if="isShowSequenceInfo">
          <div class="device-method-column__column-title">
            <div
              class="device-method-column__label"
              :class="{
                'device-method-column__label--wide': postprocessings,
              }"
            >
              Sequence
            </div>
            <RouterLink :to="linkToSequence">
              {{ sample.sequence.name }}
            </RouterLink>
          </div>
        </template>
      </template>
    </template>

    <template v-if="!device && vial">
      <div class="device-method-column__column-title">
        <div class="device-method-column__label device-method-column__label--wide">Vial</div>
        {{ vial }}
      </div>
    </template>

    <template v-if="postprocessings">
      <div class="device-method-column__column-title">
        <div class="device-method-column__label device-method-column__label--wide">
          Postprocessing
        </div>
        <span
          v-for="(postprocessing, index) of postprocessings"
          :key="index"
          class="device-method-column__measurement"
        >
          <span v-if="index !== 0">, </span>
          <a style="display: inline" @click="togglePostprocessing(postprocessing.measurement.id)">
            {{ postprocessing.measurement.waveLength }}nm
          </a>
        </span>
      </div>
      <AppliedPostprocessing
        v-for="postprocessing of postprocessingsToShow"
        :key="postprocessing.name"
        :postprocessing="postprocessing"
        class="device-method-column__postprocessing-details"
      />
    </template>

    <template v-if="postprocessingsToApply">
      <div class="device-method-column__column-title">
        <div class="device-method-column__label device-method-column__label--wide">
          Postprocessing
        </div>
        <span
          v-for="([channel], index) of postprocessingsToApply"
          :key="index"
          class="device-method-column__measurement"
        >
          <span v-if="index !== 0">, </span>
          <a style="display: inline" @click="togglePostprocessing(channel)">
            {{ CHANNEL_WAVELENGTH_MAP[channel] }}
          </a>
        </span>
      </div>

      <PostprocessingToApply
        v-for="[channel, postprocessing] of postprocessingsToApplyToShow"
        :key="channel"
        :postprocessing="postprocessing"
        :channel="channel"
        class="device-method-column__postprocessing-details"
      />
    </template>
  </div>
</template>

<script>
  import BtnIcon from '@/uikitBase/btns/BtnIcon';
  import PopupInfoMethodColumn from '@/uikitProject/popups/info/PopupInfoMethodColumn';
  import MethodPreviewModal from 'components/block/modal/MethodPreviewModal';
  import { isSecondaryView } from '@/components/blocks/layouts/dual/SecondaryView';
  import RouterHelper from 'utils/RouterHelper.ts';
  import InfoColumn from '@/uikitProject/info/InfoColumn';
  import InfoMethod from '@/uikitProject/info/InfoMethod';
  import { openInNewTab } from 'utils/browserHelpers.ts';
  import { generateId } from '@/utils/generateId';
  import AppliedPostprocessing from '@/components/blocks/measurements/AppliedPostprocessing.vue';
  import PostprocessingToApply from '@/components/blocks/postprocessings/PostprocessingToApply.vue';
  import { CHANNEL_WAVELENGTH_MAP } from '../../utils/measurementHelpers';

  export default {
    name: 'DeviceMethodColumnComponent',

    components: {
      PostprocessingToApply,
      AppliedPostprocessing,
      MethodPreviewModal,
      InfoMethod,
      InfoColumn,
      PopupInfoMethodColumn,
      BtnIcon,
    },

    inject: {
      isSecondaryView,
    },

    props: {
      disableClickAway: {
        type: Boolean,
        default: false,
      },
      device: Object,
      method: Object,
      column: Object,
      postprocessings: Array,
      postprocessingsToApply: Array,
      vial: String,
      sample: {
        type: Object,
      },
      injectionId: {
        type: Number,
      },
      isInSharedMode: {
        type: Boolean,
      },
    },

    data: () => ({
      methodExpanded: false,
      columnExpanded: false,

      id: generateId(),

      postprocessingIdsToShow: {
        // id: true
      },

      CHANNEL_WAVELENGTH_MAP,
    }),

    computed: {
      linkToDevice() {
        const routePrimary = RouterHelper.getRoutePrimary(this.$route);
        if (routePrimary && routePrimary.name === 'dual.sequence') {
          const routeSecondary = RouterHelper.getRouteSecondary(this.$route);

          const { id: sequenceId } = routePrimary.params;
          const { id, iid } = routeSecondary.params;

          const pathSecondaryURL = `/sequences/${sequenceId}/${id}` + (iid ? `/${iid}` : '');

          return {
            name: 'dual.device',
            params: {
              id: this.device?.id,
            },
            query: {
              pathSecondary: decodeURIComponent(pathSecondaryURL),
              rawLocation: true,
            },
          };
        }

        const link = {
          name: 'device',
          params: { id: this.device.id },
          query: { setAsPrimary: true },
        };

        if (!this.isSecondaryView) {
          link.query.makePrimaryAsSecondary = true;
        }

        return link;
      },
      linkToSequence() {
        if (!this.sample?.sequence) {
          return;
        }

        if (RouterHelper.isDualMode) {
          const link = {
            name: 'sequence',
            params: {
              id: this.sample.sequence.id,
            },
            query: { setAsPrimary: true, makePrimaryAsSecondary: true },
          };

          if (this.isSecondaryView) {
            link.query.makePrimaryAsSecondary = false;
          }

          return link;
        }

        const link = {
          name: 'sequence child injection',
          params: {
            id: this.sample.sequence.id,
            sid: this.sample.id,
            iid: this.injectionId,
          },
          query: { setAsPrimary: true, makePrimaryAsSecondary: true },
        };

        if (this.isSecondaryView) {
          link.query.makePrimaryAsSecondary = false;
        }

        return link;
      },

      isShowSequenceInfo() {
        if (!this.sample?.sequence) {
          return;
        }

        if (RouterHelper.isDualMode) {
          const primaryRouteName = RouterHelper.getRoutePrimary(this.$route)?.name;
          const secondaryRouteName = RouterHelper.getRouteSecondary(this.$route)?.name;

          const hasSequenceOnPrimaryView =
            primaryRouteName === 'dual.sequence' ||
            primaryRouteName === 'dual.sequence child injection';

          const hasSequenceOnSecondaryView = secondaryRouteName === 'sequence child injection';

          return !hasSequenceOnPrimaryView && !hasSequenceOnSecondaryView;
        }

        return this.$route.name !== 'sequence child injection';
      },

      postprocessingsToShow() {
        return this.postprocessings?.filter(
          (postprocessing) => this.postprocessingIdsToShow[postprocessing.measurement.id],
        );
      },
      postprocessingsToApplyToShow() {
        return this.postprocessingsToApply?.filter(
          ([channel]) => this.postprocessingIdsToShow[channel],
        );
      },
    },

    methods: {
      openColumnPage() {
        openInNewTab(`/app/columns/${this.column.id}`);
      },
      openMethodPreviewPage() {
        openInNewTab(`/app/methods/preview/${this.method.id}`);
      },

      showPreviewModal() {
        this.$modal.show(`method-preview-${this.id}}`);
      },

      togglePostprocessing(measurementIDorChannel) {
        this.postprocessingIdsToShow = {
          ...this.postprocessingIdsToShow,
          [measurementIDorChannel]: !this.postprocessingIdsToShow[measurementIDorChannel],
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .device-method-column {
    position: relative;
    background-color: $color-bg-light-selection;
    padding: 8px 22px 8px 16px;
    width: max-content;
    max-width: 100%;
    box-sizing: border-box;

    &__device {
      display: flex;
      max-width: 100%;
      @include textOverflow;
    }

    &__wrapper-link-device {
      flex: 1;
      @include textOverflow;
    }

    &__label {
      color: $color-text-second;
      width: 60px;
      display: inline-block;
      flex-shrink: 0;

      &--wide {
        width: 90px;
      }
    }

    a,
    &__link {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 400px;
      cursor: pointer;
    }

    &__column-title {
      display: flex;
      align-items: center;
      width: fit-content;
      max-width: 100%;
    }

    &__btn-details {
      margin-bottom: 2px;
      margin-left: 4px;
      color: $color-text-primary;
      font-size: 14px;

      &:hover {
        color: $color-text-primary--hover;
      }
    }

    &__btn-open-in-new {
      margin-bottom: 2px;
      margin-left: 4px;
      color: $color-text-primary;
      font-size: 14px;

      &:hover {
        color: $color-text-primary--hover;
      }
    }

    &__measurement {
      white-space: nowrap;
    }

    &__postprocessing-details {
      margin: 12px 0;
    }
  }
</style>
