import { REST } from 'utils/rest';

const url = '/api/samples/';

export class SampleAPI {
  static getList(onSuccess, onError) {
    return REST.get(url, onSuccess, onError);
  }

  static post(data, onSuccess, onError) {
    return REST.post(url, data, onSuccess, onError);
  }

  static postInjectionCsv(id, body, onSuccess, onError) {
    return REST.postMultipart(
      `${url + id.toString()}/injections/:import/`,
      body,
      onSuccess,
      onError,
    );
  }

  static getShared(id, token, onSuccess, onError) {
    return REST.get(`/api/shared/samples/${id}/${token}/`, onSuccess, onError);
  }
}
