import SocketCentrifugeBase from '@/api/sockets/SocketCentrifugeBase';
import { Callback, Dict } from '@/types/utility';
import { MeasurementSocketEvents } from '@/api/sockets/MeasurementSocket';

export enum SharedMeasurementSocketEvents {
  PEAKS = 'measurement.peaks',
  NEW_VALUES = 'measurement.newValues',
}

enum SharedMeasurementSocketRPC {
  GET = 'shared_measurement.get',
}

export default class SharedMeasurementSocket extends SocketCentrifugeBase<
  SharedMeasurementSocketEvents,
  SharedMeasurementSocketRPC
> {
  private static connections: Dict<SharedMeasurementSocket> = {};

  protected listeners = {
    [MeasurementSocketEvents.PEAKS]: [] as Callback<[data: object]>[],
    [SharedMeasurementSocketEvents.NEW_VALUES]: [] as Callback<[from: number, values: number[]]>[],
  };

  private constructor(
    public id: number,
    onConnect?: Callback<
      [SocketCentrifugeBase<SharedMeasurementSocketEvents, SharedMeasurementSocketRPC>]
    >,
    options?: { shareToken?: string },
  ) {
    super(`personal:measurement-${id}`, {
      handleEvents: ({ data }) => {
        switch (data.event) {
          case MeasurementSocketEvents.PEAKS: {
            this.callListeners(SharedMeasurementSocketEvents.PEAKS, data);
            break;
          }
          case SharedMeasurementSocketEvents.NEW_VALUES:
            this.callListeners(SharedMeasurementSocketEvents.NEW_VALUES, data.from, data.values);
            break;
        }
      },
      onConnect,
      shareToken: options?.shareToken,
    });

    this.dataAddedToEveryRequest = {
      measurement_id: this.id,
      share_token: options?.shareToken ?? '',
    };
  }

  public static start(
    id: number,
    onConnect?: Callback<
      [SocketCentrifugeBase<SharedMeasurementSocketEvents, SharedMeasurementSocketRPC>]
    >,
    options?: { shareToken?: string },
  ) {
    const connection = SharedMeasurementSocket.connections[id];
    if (connection) {
      connection.addSession();
      onConnect?.(connection);
      return connection;
    }

    SharedMeasurementSocket.connections[id] = new SharedMeasurementSocket(id, onConnect, options);
    return SharedMeasurementSocket.connections[id];
  }

  public close(listenersGroupId: string): boolean {
    const isClosed = super.close(listenersGroupId);

    if (isClosed) {
      delete SharedMeasurementSocket.connections[this.id];
    }

    return isClosed;
  }
}
