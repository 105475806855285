export const autosamplerCommands = {
  RETURN_PLUNGER_TO_HOME_POSITION: 'retractPlunger',
  RAISE_ARM: 'needleMaxPosition',
  MOVE_ARM_FROM_INJECTION_PORT: 'ejectArm',
  RESET_NEEDLE: 'recalibrateArmNeedle',
  RESET_VALVE: 'recalibrateValve',
  WASH: 'wash',
  SHAKE: 'shake',
  GET_READY: 'getReady',
} as const;

export const detectorCommands = {
  CALIBRATE: 'calibrate',
  TURN_ON_LEDS: 'turnOnLeds',
  TURN_OFF_LEDS: 'turnOffLeds',
} as const;

export const pumpCommands = {
  START: 'start',
  STOP: 'stop',
  STOP_A: 'stopA',
  STOP_B: 'stopB',
  PURGE: 'purge',
  PURGE_A: 'purgeA',
  PURGE_B: 'purgeB',
  BLINK: 'blink',
} as const;

export const ethernetAdapterCommands = {
  GET_DETAILS: 'getDetails',
} as const;

export const wifiAdapterCommands = {
  GET_DETAILS: 'getDetails',
  CONNECT: 'connect',
} as const;

export const valveCommands = {
  SWITCH_POSITION: 'switchPosition',
} as const;

export const alltestaOrCromiteCommands = {
  PING: 'ping',
} as const;

export const cromiteCommands = {
  CLEAN_NEEDLE: 'cleanNeedle',
} as const;
