<template>
  <div v-if="hasAnyPhaseContainer || hasWasteContainer" class="hardware-tile phase">
    <i class="hardware-tile__title-icon">science</i>

    <h4>Phases</h4>

    <template v-if="state && hasAnyPhaseContainer">
      <Container
        v-if="configuration.phase.phaseContainers.a"
        :volume="configuration.phase.phaseContainers.a.volume"
        :amount="amountA"
        :name="nameA"
        label="A"
        :runs="runARemain"
        mode="in"
        class="phase__container"
        @updateAmount="updateAmount('a', $event)"
      />
      <Container
        v-if="configuration.phase.phaseContainers.b"
        :volume="configuration.phase.phaseContainers.b.volume"
        :amount="amountB"
        :name="nameB"
        label="B"
        :runs="runBRemain"
        mode="in"
        class="phase__container"
        @updateAmount="updateAmount('b', $event)"
      />
    </template>
    <template v-if="state && hasWasteContainer">
      <Container
        :volume="configuration.waste.volume"
        :amount="wasteAmount"
        label="W"
        mode="out"
        class="waste__container"
        @updateAmount="updateWasteAmount($event)"
      />
    </template>
  </div>
</template>

<script>
  import Base from '../Base.vue';
  import Container from './private/Container';

  export default {
    name: 'Phase',

    components: { Container },

    extends: Base,

    computed: {
      hasWasteContainer() {
        return (
          Object.prototype.hasOwnProperty.call(this.configuration, 'waste') &&
          this.configuration.waste != null
        );
      },
      hasAnyPhaseContainer() {
        return this.configuration.phase && this.configuration.phase.phaseContainers;
      },
      runARemain() {
        const runTimeMin = this.method?.run_time_min ?? 0;
        const flowRateMlPerMin = this.method?.flow_rate_ml_per_min ?? 0;
        if (this.configuration.pump?.type === 'TWO SYRINGE GRADIENT') {
          return Math.floor(
            this.amountA /
              this.calcGradientRunConsumption(
                this.method?.gradient,
                0,
                runTimeMin * flowRateMlPerMin,
              ),
          );
        }
        let consumptionPerRun = flowRateMlPerMin * runTimeMin;
        if (consumptionPerRun > 5800) consumptionPerRun = 5800;
        return Math.floor(this.amountA / consumptionPerRun);
      },
      runBRemain() {
        const runTimeMin = this.method?.run_time_min ?? 0;
        const flowRateMlPerMin = this.method?.flow_rate_ml_per_min ?? 0;

        if (this.configuration.pump?.type === 'TWO SYRINGE GRADIENT') {
          return Math.floor(
            this.amountB /
              this.calcGradientRunConsumption(
                this.method?.gradient,
                1,
                runTimeMin * flowRateMlPerMin,
              ),
          );
        }
        let consumptionPerRun = flowRateMlPerMin * runTimeMin;
        if (consumptionPerRun > 5800) consumptionPerRun = 5800;
        return Math.floor(this.amountA / consumptionPerRun);
      },
      amountA() {
        return this.state.phase && this.state.phase.a ? Number(this.state.phase.a.toFixed(1)) : 0.0;
      },
      amountB() {
        return this.state.phase && this.state.phase.b ? Number(this.state.phase.b.toFixed(1)) : 0.0;
      },
      wasteAmount() {
        return this.state.waste ? Number(this.state.waste.toFixed(1)) : 0.0;
      },
      nameA() {
        return this.method?.solvents?.a?.name ? this.method.solvents.a.name : 'Unknown';
      },
      nameB() {
        return this.method?.solvents?.b?.name ? this.method.solvents.b.name : 'Unknown';
      },
    },

    methods: {
      updateWasteAmount(event) {
        this.ws.command('waste', 'updatePhaseAmount', { waste: event });
      },
      updateAmount(index, event) {
        this.ws.command('phase', 'updatePhaseAmount', { [index]: event });
      },
      calcGradientRunConsumption(gradient, index, totalRunConsumption) {
        // TODO remove null check after CLD-1306 done
        if (!gradient) {
          // eslint-disable-next-line no-param-reassign
          gradient = [{ fractions: [0.5, 0.5] }];
        }
        const totalFractions = gradient
          .map((step) => step.fractions)
          .reduce(function (r, a) {
            a.forEach((b, i) => {
              r[i] = (r[i] || 0) + b;
            });
            return r;
          }, []);
        const consumptionPart =
          totalFractions[index] / totalFractions.reduce((partialSum, a) => partialSum + a, 0);

        return totalRunConsumption * consumptionPart;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .phase {
    &__container {
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }
  .waste {
    &__container {
      margin-top: 20px;
    }
  }
</style>
