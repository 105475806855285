import { render, staticRenderFns } from "./StandardResults.vue?vue&type=template&id=69086cc5&scoped=true&"
import script from "./StandardResults.vue?vue&type=script&lang=js&"
export * from "./StandardResults.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69086cc5",
  null
  
)

export default component.exports