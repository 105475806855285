<template>
  <span class="datetime">
    <Time :date="date">
      <template #default="{ time }">
        <slot :datetime="`${date} ${time}`"> {{ dateFormatted }} {{ time }} </slot>
      </template>
    </Time>
  </span>
</template>

<script>
  import Time from '@/uikitProject/dates/Time';

  export default {
    name: 'Datetime',

    components: {
      Time,
    },

    props: {
      date: {
        type: [String, Number, Date],
        required: true,
      },
      placeholder: {
        type: String,
        default: 'No date',
      },
    },

    computed: {
      dateFormatted() {
        if (this.date) {
          return new Date(this.date).toLocaleDateString();
        }
        return this.placeholder;
      },
    },
  };
</script>
