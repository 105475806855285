<template>
  <router-link :to="to">
    <template v-if="sequence">
      <IconSequence v-if="firstIcon" />
      <span class="overflow-hidden mr-4">{{ sequence.name }}</span>

      <IconStandard v-if="injection.type === 'standard'" />
      <IconSample v-else />
      <span class="overflow-hidden mr-4">{{ sample.name }}</span>

      <IconInjection />
      {{ pInjection.name }}
    </template>
    <template v-else>
      <IconSample v-if="firstIcon" :type="pInjection.type" />
      <span class="overflow-hidden mr-4">{{ sample.name }}</span>

      <IconInjection />
      {{ pInjection.name }}
    </template>
  </router-link>
</template>

<script>
  import IconSequence from '@/uikitProject/icons/IconSequence.vue';
  import IconSample from '@/uikitProject/icons/IconSample.vue';
  import IconInjection from '@/uikitProject/icons/IconInjection.vue';
  import IconStandard from '@/uikitProject/icons/IconStandard';

  export default {
    name: 'InjectionLinkComponent',

    components: { IconSequence, IconSample, IconInjection, IconStandard },

    props: {
      injection: {
        type: Object,
        default: () => ({ name: '---', type: 'S' }),
      },
      firstIcon: {
        type: Boolean,
        default: true,
      },
      isOpenEntityAsSecondary: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      pInjection() {
        return this.injection || { id: null, name: '---' };
      },
      sample() {
        return this.injection?.sample || { id: null, name: '---' };
      },
      sequence() {
        return this.sample.sequence;
      },
      to() {
        const i = this.pInjection;
        const s = this.sample;

        const query = !this.isOpenEntityAsSecondary && {
          makeSecondaryAsPrimary: true,
          primaryMustBe: 'device',
        };

        if (s.sequence) {
          return {
            name: 'sequence child injection',
            params: { id: s.sequence.id, sid: s.id, iid: i.id },
            query,
          };
        }
        if (i.type === 'sample') {
          return {
            name: 'sample injection',
            params: { id: s.id, iid: i.id },
            query,
          };
        }
        if (i.type === 'standard') {
          return {
            name: 'standard injection',
            params: { id: s.id, iid: i.id },
            query,
          };
        }
        return {};
      },
    },
  };
</script>
