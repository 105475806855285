<template>
  <Select
    v-if="isInitialized"
    ref="select"
    :itemSelected="privatePostprocessingTemplate"
    :items="postprocessingTemplatesToShow"
    labelProp="name"
    :hasSearch="true"
    :padding="padding"
    :placeholderSearch="placeholder"
    :titleBottomSheet="placeholder"
    class="select-postprocessing-template-as-preset"
    :canSearchById="true"
    :isTransparent="isTransparent"
    :isDisabled="isDisabled"
    :canCreate="isEditable"
    messageCreate="Create postprocessingTemplate"
    @update:itemSelected="selectedItemChanged"
    @search="getListItemsDebounced"
    @showPopup="handleShowPopup"
    @hidePopup="handleHidePopup"
    @create="$emit('createPostprocessingTemplate')"
  >
    <template
      #rightSideInsideItem="{ item: _postprocessingTemplate, isBottomSheet, isInDropdown, hide }"
    >
      <IconFavorite
        v-if="_postprocessingTemplate.favorite"
        class="select-postprocessing-template-as-preset__favorite"
        :class="{
          'select-postprocessing-template-as-preset__favorite--selected':
            privatePostprocessingTemplate &&
            _postprocessingTemplate.id === privatePostprocessingTemplate.id &&
            (isBottomSheet || isInDropdown),
        }"
      />

      <span
        class="select-postprocessing-template-as-preset__id"
        :class="{
          'select-postprocessing-template-as-preset__id--selected':
            privatePostprocessingTemplate &&
            _postprocessingTemplate.id === privatePostprocessingTemplate.id &&
            (isBottomSheet || isInDropdown),
        }"
        >#{{ _postprocessingTemplate.id }}</span
      >
      <BtnIcon
        v-if="isEditable && (isBottomSheet || isInDropdown)"
        iconMaterial="edit"
        class="select-postprocessing-template-as-preset__btn-edit"
        @click.stop="editPostprocessingTemplate(_postprocessingTemplate, hide)"
      />
    </template>
  </Select>
</template>

<script>
  import { debounce } from 'lodash';
  import Select from '@/uikitBase/selects/vueSelect/Select';
  import IconFavorite from '@/uikitProject/icons/IconFavorite.vue';
  import hash from 'object-hash';
  import BtnIcon from '@/uikitBase/btns/BtnIcon.vue';

  const SEARCH_LIMIT = 25;
  const SEARCH_DELAY = 200;

  const ModelEvent = 'change';
  const CloseEvent = 'close';

  export default {
    name: 'SelectPostprocessingTemplateAsPreset',

    components: {
      BtnIcon,
      IconFavorite,
      Select,
    },

    model: {
      prop: 'postprocessingTemplate',
      event: ModelEvent,
    },

    props: {
      postprocessingTemplate: {
        type: Object,
        default: null,
      },
      postprocessingTemplateId: {
        type: Number,
        default: null,
      },
      placeholder: {
        type: String,
        default: 'Search postprocessing template',
      },
      postprocessingTemplateIdsToHide: {
        type: Array,
      },
      isTransparent: {
        type: Boolean,
        default: false,
      },
      padding: {
        type: String,
        default: 'md',
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      isEditable: {
        type: Boolean,
        default: false,
      },
      preloadedPostprocessingTemplate: {
        type: Object,
      },
      configuration: {
        type: Object,
      },
    },

    data: () => ({
      isSearchActive: false,
      searchQuery: null,
      items: [],

      getListItemsDebounced: null,

      isSelectOpened: false,

      isInitialized: false,
    }),

    computed: {
      privatePostprocessingTemplate() {
        return (
          this.postprocessingTemplatesToShow.find(
            (item) =>
              item.id === (this.postprocessingTemplateId ?? this.postprocessingTemplate?.id),
          ) ??
          this.postprocessingTemplate ??
          null
        );
      },

      postprocessingTemplatesFromStore() {
        return Object.values(this.$store.state.postprocessingTemplates);
      },

      postprocessingTemplatesToShow() {
        if (this.preloadedPostprocessingTemplate) {
          const isPreloadedPostprocessingTemplateAlreadyInList = this.items.some(
            (postprocessingTemplate) =>
              postprocessingTemplate.id === this.preloadedPostprocessingTemplate.id,
          );
          if (!isPreloadedPostprocessingTemplateAlreadyInList) {
            return [...this.items, this.preloadedPostprocessingTemplate];
          }
        }

        return this.items;
      },
    },

    watch: {
      postprocessingTemplate(itemNew) {
        if (itemNew) {
          this.addItem(itemNew);
        }
      },
      postprocessingTemplatesFromStore() {
        if (this.postprocessingTemplatesFromStore.length === 0) {
          this.search(null);
        }
      },
      configuration() {
        this.refreshPostprocessingTemplates();
      },
    },

    created() {
      // / NOTE: using hash instead of searching in array
      this.itemsHash = {};

      this.getListItemsDebounced = debounce(this.search, SEARCH_DELAY);

      this.search(null);
    },

    methods: {
      activate() {
        this.$refs.select.focus();
      },

      deactivate() {
        // this.$refs.select.deactivate();
      },

      onClose() {
        this.$emit(CloseEvent);
      },

      selectedItemChanged(item) {
        this.$emit(ModelEvent, item);
      },

      async search(query) {
        const searchingFirstTime =
          query == null && this.searchQuery == null && this.items.length === 0;

        this.isSearchActive = !searchingFirstTime;
        this.searchQuery = query;

        const variables = {
          search: this.searchQuery,
          isArchived: false,
          limit: SEARCH_LIMIT,
          hasOnlyFavorites: false,
          hasSortingByFavorites: 'desc',
          postprocessingTemplateIdsToExclude: this.postprocessingTemplateIdsToHide,
          hardwareConfiguration: this.configuration,
        };

        const variablesHash = hash(variables);
        try {
          if (!this.$store.state.postprocessingTemplates[variablesHash]) {
            this.$store.dispatch('initPostprocessingTemplates', variables);
          }

          const { postprocessingTemplates } = await this.$store.state.postprocessingTemplates[
            variablesHash
          ];

          if (this.searchQuery !== query) return;
          this.isSearchActive = false;

          this.replaceItems(postprocessingTemplates);
          this.isInitialized = true;
        } catch {
          if (this.searchQuery !== query) return;
          this.isSearchActive = false;
          this.clearItems();
        }
      },

      addItem(itemNew) {
        if (!(itemNew.id in this.itemsHash)) {
          this.itemsHash[itemNew.id] = true;
          this.items.unshift(itemNew);
        }
      },

      replaceItems(items) {
        this.itemsHash = {};
        items.forEach((item) => {
          this.itemsHash[item.id] = true;
        });
        this.items = items;
      },

      clearItems() {
        this.itemsHash = {};
        this.items = [];
      },

      handleShowPopup() {
        this.isSelectOpened = true;
        this.$emit('showPopup');
      },
      handleHidePopup() {
        this.isSelectOpened = false;
        this.$emit('hidePopup');
      },

      refreshPostprocessingTemplates() {
        this.search(null);
      },

      editPostprocessingTemplate(postprocessingTemplate, hide) {
        this.$emit('editPostprocessingTemplate', postprocessingTemplate);
        hide();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .select-postprocessing-template-as-preset {
    width: 100%;

    &__id {
      color: $color-text-third;

      &--selected {
        color: white;
      }
    }

    &__favorite {
      margin-right: 10px;

      &--selected {
        color: white;
      }
    }

    &__btn-edit {
      margin-left: 5px;
    }
  }
</style>
