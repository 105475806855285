<template>
  <modal ref="modal" :width="750 - 128" name="changelog-modal">
    <div class="modal-content modal-content--no-padding modal-changelog">
      <div class="modal-header">
        <h4>New version!</h4>
      </div>

      <div class="modal-body modal-body--big-padding modal-changelog__body">
        <div v-if="latestVersion" class="modal-changelog__version-latest">
          <div class="modal-changelog__title-version-latest">
            #{{ latestVersion.title }} (latest)
          </div>

          <Changes
            :added="latestVersion.parsed.Added"
            :changed="latestVersion.parsed.Changed"
            :removed="latestVersion.parsed.Removed"
            :fixed="latestVersion.parsed.Fixed"
            class="modal-changelog__changes"
          />
        </div>

        <div
          v-if="previousVersions && previousVersions.length"
          class="modal-changelog__previous-versions"
        >
          <div class="modal-changelog__title-version-previous">Previous versions</div>

          <PreviousVersion
            v-for="version of previousVersions"
            :key="version.version"
            :version="version"
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import ModalComponent from 'components/element/ModalComponent.vue';
  import Changes from 'components/block/modal/vueChangelogModal/shared/Changes';
  import PreviousVersion from 'components/block/modal/vueChangelogModal/private/PreviousVersion';

  const MODAL_NAME = 'changelog-modal';

  export default {
    name: 'ChangelogModal',

    components: {
      PreviousVersion,
      Changes,
      modal: ModalComponent,
    },

    props: {
      versions: {
        type: Array,
        required: true,
      },
    },

    computed: {
      latestVersion() {
        return this.versions?.[0];
      },
      previousVersions() {
        return this.versions?.slice(1);
      },
    },

    methods: {
      show() {
        this.$modal.show(MODAL_NAME);
      },
      close() {
        this.$modal.hide(MODAL_NAME);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-changelog {
    &__body {
      @media (max-width: $screen-sm-max) {
        padding: 16px;
      }
    }

    &__title-version-latest {
      font-size: $size-lg;
      font-weight: $weight-bold;
      margin-bottom: 20px;
    }

    &__title-version-previous {
      margin-top: 40px;
      font-size: $size-md;
      margin-bottom: 10px;
      color: $color-text-second;
    }
  }
</style>
