<template>
  <Label
    v-tippy="{ trigger: tooltip ? 'mouseenter' : 'manual' }"
    class="checkbox"
    :isSelected="checked"
    :isDisabled="isDisabled"
    :content="tooltip"
    :selectedColor="selectedColor"
  >
    <input v-model="_checked" :disabled="isDisabled" type="checkbox" class="checkbox__input" />
    <slot />
  </Label>
</template>

<script>
  import Label from './private/Label';

  const EVENT_MODEL = 'model';

  export default {
    name: 'Checkbox',

    components: {
      Label,
    },

    model: {
      prop: 'checked',
      event: EVENT_MODEL,
    },

    props: {
      checked: {
        type: Boolean,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      tooltip: {
        type: String,
      },
      selectedColor: {
        type: String,
      },
    },

    computed: {
      _checked: {
        get() {
          return this.checked;
        },
        set(value) {
          this.$emit(EVENT_MODEL, value);
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .checkbox {
    &__input {
      display: none;
    }
  }
</style>
