export default class ApproximationHelper {
  public static approximateChartBounds(min: number, max: number): [number, number] {
    const maxAbsoluteValue = Math.max(Math.abs(min), Math.abs(max));

    if (maxAbsoluteValue < 10) {
      return [Math.floor(min), Math.ceil(max)];
    }

    if (maxAbsoluteValue < 100) {
      return [Math.floor(min / 25) * 25, Math.ceil(max / 25) * 25];
    }

    if (maxAbsoluteValue < 1000) {
      return [Math.floor(min / 50) * 50, Math.ceil(max / 50) * 50];
    }

    return [Math.floor(min / 100) * 100, Math.ceil(max / 100) * 100];
  }
}
