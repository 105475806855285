<template>
  <div></div>
</template>

<script>
  import HardwareStateComponent from 'components/element/HardwareStateComponent.vue';
  import 'assets/css/component/hardware.scss';

  export default {
    components: {
      // eslint-disable-next-line vue/no-unused-components
      HardwareStateComponent, // Used by children
    },
    props: {
      configuration: Object,
      state: Object,
      method: Object,
      ws: Object,
    },

    computed: {
      trayConfig() {
        const trayHardware =
          this.configuration.autosampler ??
          this.configuration.autosampler_fraction_collector ??
          null;
        if (trayHardware && trayHardware?.supported_trays && trayHardware?.tray != null) {
          const trayId = trayHardware?.tray;
          return Object.entries(trayHardware.supported_trays).find(([, id]) => id === trayId)[0];
        }
        return null;
      },
    },
  };
</script>
