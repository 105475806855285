<template>
  <Btn
    borderRadius="sm"
    type="secondary"
    height="s"
    padding="xl"
    :disabled="wasSent || isLoading || isPreparingReportData"
    :isLoading="isLoading || isPreparingReportData"
    class="btn-send"
    @click="send"
  >
    {{ wasSent ? 'Sent' : 'Send' }}
  </Btn>
</template>

<script>
  import Btn from '@/uikitBase/btns/Btn.vue';
  import InjectionAPI from '@/api/injection';
  import { SequenceAPI } from '@/api/sequence';

  const EVENT_SEND = 'send';

  export default {
    name: 'BtnSend',

    components: { Btn },

    props: {
      contact: {
        type: Object,
        required: true,
      },
      message: {
        type: String,
      },
      reportType: {
        type: String,
        required: true,
        validator(value) {
          const availableValues = ['pdf', 'csv'];
          return availableValues.includes(value);
        },
      },
      report: {
        type: String,
        required: true,
        validator(value) {
          const availableValues = ['sequence', 'injection'];
          return availableValues.includes(value);
        },
      },
      wasSent: {
        type: Boolean,
        required: true,
      },

      sequenceId: {
        type: Number,
      },
      sampleId: {
        type: Number,
      },
      injectionId: {
        type: Number,
      },

      reportData: {
        type: Object,
      },

      isPreparingReportData: {
        type: Boolean,
      },
    },

    data: () => ({
      isLoading: false,
    }),

    watch: {
      reportType() {
        // TODO use abort controller here
        this.isLoading = false;
      },
    },

    methods: {
      send() {
        const savedReportType = this.reportType;
        this.isLoading = true;

        const { sampleId, injectionId, sequenceId } = this;

        const data = {
          sequenceId,
          sampleId,
          injectionId,
          email: this.contact.email,
          message: this.message,
          report_data: this.reportData,
          report_type: this.reportType,
        };

        switch (this.report) {
          case 'injection': {
            InjectionAPI.sendReport(
              data,
              () => {
                this.$emit(EVENT_SEND, {
                  contactId: this.contact.id,
                  reportType: savedReportType,
                });

                this.isLoading = false;
              },
              () => {
                this.notifyError('Unable to send a report!');

                this.isLoading = false;
              },
            );
            break;
          }
          case 'sequence': {
            SequenceAPI.sendReport(
              data,
              () => {
                this.$emit(EVENT_SEND, {
                  contactId: this.contact.id,
                  reportType: savedReportType,
                });

                this.isLoading = false;
              },
              () => {
                this.notifyError('Unable to send a report!');

                this.isLoading = false;
              },
            );
            break;
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .btn-send {
    @media (max-width: $screen-xs-max) {
      padding: 0 10px;
    }
  }
</style>
