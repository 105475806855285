import _ from 'lodash';
import { Callback, Dict } from '@/types/utility';
import SocketCentrifugeBase, { SubscriptionError } from '@/api/sockets/SocketCentrifugeBase';

export enum SequenceSocketEvents {
  SEQUENCE = 'sequence',
  SEQUENCE_RUN = 'sequence.run',
  SEQUENCE_CHILD = 'sequence.child',
  SEQUENCE_SELECT = 'sequence.select',
  SEQUENCE_TABLE = 'sequence.table',
  SEQUENCE_DRAFT = 'sequence.draft',
  SEQUENCE_PROGRESS = 'sequence.progress',
  SEQUENCE_ERROR = 'sequence.error',
  SEQUENCE_CLONED = 'sequence.cloned',
  REPLACE_SAMPLE = 'sequence.replaceSample',
  // Internal app events. Not emitted by socket
  DEVICE = 'sequence.device',
  METHOD = 'sequence.method',
  COLUMN = 'sequence.column',
  CHILDREN = 'sequence.children',
  TABLE = 'sequence.table',
  METHODS = 'sequence.methods',
}

enum SequenceSocketRPC {
  GET = 'sequence.get',
  GET_FULL = 'sequence.getFull',
  RUN = 'sequence.run',
  STOP = 'sequence.stop',
  FINISH = 'sequence.finish',
  REJECT = 'sequence.reject',
  ARCHIVE = 'sequence.archive',
  RESTORE = 'sequence.restore',
  CLONE = 'sequence.clone',
  ADD_SAMPLE = 'sequence.addSample',
  ADD_STANDARD = 'sequence.addStandard',
  UPDATE = 'sequence.update',
  TABLE_UPDATE = 'sequence.table.update',
  TABLE_UPDATE_DRAFT = 'sequence.table.updateDraft',
  TABLE_START = 'sequence.table.start',
  TABLE_PAUSE = 'sequence.table.pause',
  TABLE_RESUME = 'sequence.table.resume',
  TABLE_STOP = 'sequence.table.stop',
}

export default class SequenceSocket extends SocketCentrifugeBase<
  SequenceSocketEvents,
  SequenceSocketRPC
> {
  private static connections: Dict<SequenceSocket> = {};

  public id: number;

  protected listeners: Record<SequenceSocketEvents, Callback[]> = {
    [SequenceSocketEvents.SEQUENCE]: [],
    [SequenceSocketEvents.SEQUENCE_RUN]: [],
    [SequenceSocketEvents.SEQUENCE_CHILD]: [],
    [SequenceSocketEvents.SEQUENCE_SELECT]: [],
    [SequenceSocketEvents.SEQUENCE_TABLE]: [],
    [SequenceSocketEvents.SEQUENCE_DRAFT]: [],
    [SequenceSocketEvents.SEQUENCE_PROGRESS]: [],
    [SequenceSocketEvents.SEQUENCE_ERROR]: [],
    [SequenceSocketEvents.SEQUENCE_CLONED]: [],
    [SequenceSocketEvents.REPLACE_SAMPLE]: [],
    [SequenceSocketEvents.DEVICE]: [],
    [SequenceSocketEvents.METHOD]: [],
    [SequenceSocketEvents.COLUMN]: [],
    [SequenceSocketEvents.CHILDREN]: [],
    // [SequenceSocketEvents.TABLE]: [],
    [SequenceSocketEvents.METHODS]: [],
  };

  private constructor(
    id: number,
    onConnect?: Callback<[SocketCentrifugeBase<SequenceSocketEvents, SequenceSocketRPC>]>,
    onError?: Callback<[SubscriptionError]>,
  ) {
    super(
      `personal:sequence-${id}`,

      {
        handleEvents: ({ data }) => {
          switch (data.event) {
            case SequenceSocketEvents.SEQUENCE: {
              this.callListeners(SequenceSocketEvents.SEQUENCE, data.sequence);

              if (_.has(data, 'device')) {
                this.callListeners(SequenceSocketEvents.DEVICE, data.device);
              }
              if (_.has(data, 'method')) {
                this.callListeners(SequenceSocketEvents.METHOD, data.method);
              }
              if (_.has(data, 'column')) {
                this.callListeners(SequenceSocketEvents.COLUMN, data.column);
              }
              if (_.has(data, 'children')) {
                this.callListeners(SequenceSocketEvents.CHILDREN, data.children);
              }
              if (_.has(data, 'table')) {
                this.callListeners(SequenceSocketEvents.TABLE, data.table);
              }
              if (_.has(data, 'methods')) {
                this.callListeners(SequenceSocketEvents.METHODS, data.methods);
              }
              break;
            }
            case SequenceSocketEvents.SEQUENCE_RUN: {
              this.callListeners(SequenceSocketEvents.SEQUENCE_RUN, data.child, data.run);
              break;
            }
            case SequenceSocketEvents.SEQUENCE_CHILD: {
              this.callListeners(SequenceSocketEvents.SEQUENCE_CHILD, data.child);
              break;
            }
            case SequenceSocketEvents.SEQUENCE_SELECT: {
              this.callListeners(SequenceSocketEvents.SEQUENCE_SELECT, data.child_id, data.run_id);
              break;
            }
            case SequenceSocketEvents.SEQUENCE_TABLE: {
              this.callListeners(SequenceSocketEvents.SEQUENCE_TABLE, data.table);
              break;
            }
            case SequenceSocketEvents.SEQUENCE_DRAFT: {
              this.callListeners(SequenceSocketEvents.SEQUENCE_DRAFT, data.draft);
              break;
            }
            case SequenceSocketEvents.SEQUENCE_PROGRESS: {
              this.callListeners(
                SequenceSocketEvents.SEQUENCE_PROGRESS,
                data.progress,
                data.progressStr,
                data.draft,
              );
              break;
            }
            case SequenceSocketEvents.SEQUENCE_ERROR: {
              this.callListeners(SequenceSocketEvents.SEQUENCE_ERROR, data.msg);
              break;
            }
            case SequenceSocketEvents.SEQUENCE_CLONED: {
              this.callListeners(SequenceSocketEvents.SEQUENCE_CLONED, data.sequence_id);
              break;
            }
            case SequenceSocketEvents.REPLACE_SAMPLE: {
              this.callListeners(SequenceSocketEvents.REPLACE_SAMPLE, data.sample_id);
              break;
            }
          }
        },
        onConnect,
        onError,
      },
    );

    this.id = id;

    this.dataAddedToEveryRequest = { sequence_id: this.id };
  }

  public static start(
    id: number,
    onConnect?: Callback<[SocketCentrifugeBase<SequenceSocketEvents, SequenceSocketRPC>]>,
    onError?: Callback<[SubscriptionError]>,
  ): SequenceSocket {
    const connection = SequenceSocket.connections[id];
    if (connection) {
      connection.addSession();
      onConnect?.(connection);
      return connection;
    }

    SequenceSocket.connections[id] = new SequenceSocket(id, onConnect, onError);
    return SequenceSocket.connections[id];
  }

  close(listenersGroupId: string): boolean {
    const isClosed = super.close(listenersGroupId);

    if (isClosed) {
      delete SequenceSocket.connections[this.id];
    }

    return isClosed;
  }

  get() {
    return this.sendNamedRPC(SequenceSocketRPC.GET);
  }

  getFull() {
    return this.sendNamedRPC(SequenceSocketRPC.GET_FULL);
  }

  run() {
    return this.sendNamedRPC(SequenceSocketRPC.RUN);
  }

  stop() {
    return this.sendNamedRPC(SequenceSocketRPC.STOP);
  }

  finish() {
    return this.sendNamedRPC(SequenceSocketRPC.FINISH);
  }

  reject() {
    return this.sendNamedRPC(SequenceSocketRPC.REJECT);
  }

  archive() {
    return this.sendNamedRPC(SequenceSocketRPC.ARCHIVE);
  }

  restore() {
    return this.sendNamedRPC(SequenceSocketRPC.RESTORE);
  }

  clone() {
    return this.sendNamedRPC(SequenceSocketRPC.CLONE);
  }

  addSample(name) {
    return this.sendNamedRPC(SequenceSocketRPC.ADD_SAMPLE, { name });
  }

  addStandard(standardData) {
    return this.sendNamedRPC(SequenceSocketRPC.ADD_STANDARD, standardData);
  }

  sequenceTableUpdate(table) {
    return this.sendNamedRPC(SequenceSocketRPC.TABLE_UPDATE, { drafts: table });
  }

  sequenceTableUpdateDraft(draft) {
    return this.sendNamedRPC(SequenceSocketRPC.TABLE_UPDATE_DRAFT, { draft });
  }

  update(sequence) {
    return this.sendNamedRPC(SequenceSocketRPC.UPDATE, { sequence });
  }

  sequenceTableStart() {
    return this.sendNamedRPC(SequenceSocketRPC.TABLE_START);
  }

  sequenceTablePause() {
    return this.sendNamedRPC(SequenceSocketRPC.TABLE_PAUSE);
  }

  sequenceTableResume() {
    return this.sendNamedRPC(SequenceSocketRPC.TABLE_RESUME);
  }

  sequenceTableStop() {
    return this.sendNamedRPC(SequenceSocketRPC.TABLE_STOP);
  }
}
