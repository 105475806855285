<template>
  <div class="method-form">
    <div class="method-form__wrapper-name">
      <StandardModelFieldComponent
        ref="name"
        v-model="privateMethodData.name"
        style="flex: 1 1"
        :type="'str'"
        :is-title="true"
        :label="'Method name'"
        :error="errors.name"
        :disabled="isArchived"
        :isMultiline="true"
        @update:value="$emit('updateField', 'name')"
      />

      <div class="method-form__actions">
        <button v-if="isArchived" class="button--square-image archive-button" @click="restore">
          <i class="material-icons material-icon--18">restore</i>Restore
        </button>
        <button v-else class="button--square-image archive-button" @click="archive">
          <i class="material-icons material-icon--18">delete</i>Archive
        </button>

        <button class="button--square-image favorites-button" @click="toggleFavorite">
          <i class="material-icons material-icon--18">{{
            methodData.favorite ? 'star' : 'star_border'
          }}</i>
        </button>
      </div>
    </div>

    <StateArchived v-if="isArchived" class="method-form__state-archived" />

    <MethodEditComponent
      ref="editForm"
      v-model="privateMethodData"
      :errors="errors"
      :disabled="isArchived"
      :configuration="configurationForMethod"
      padding="sm"
      class="mt-3 method-form__edit"
      @validation="setValidation"
      @updateField="$emit('updateField', $event)"
    />
  </div>
</template>

<script>
  import StateArchived from '@/uikitProject/states/StateArchived.vue';
  import MethodEditComponent from '@/components/block/MethodEditComponent.vue';
  import StandardModelFieldComponent from '@/components/element/StandardModelFieldComponent.vue';
  import { apiMethods } from '@/api/graphql/cloud/methods';

  const EVENT_MODEL = 'update:methodData';
  const EVENT_ARCHIVE = 'update:isArchive';
  const EVENT_VALIDATION = 'update:isValid';
  const EVENT_FAVORITE = 'favorite';

  export default {
    name: 'MethodForm',

    components: {
      StandardModelFieldComponent,
      MethodEditComponent,
      StateArchived,
    },

    model: {
      prop: 'methodData',
      event: EVENT_MODEL,
    },

    props: {
      isArchived: {
        type: Boolean,
        required: true,
      },
      canArchive: {
        type: Boolean,
        required: true,
      },
      isValid: {
        type: Boolean,
        required: true,
      },
      errors: {
        type: Object,
        required: true,
      },
      methodData: {
        type: Object,
        required: true,
      },
      configurationForMethod: {
        type: Object,
        required: true,
      },
    },

    computed: {
      privateMethodData: {
        get() {
          return this.methodData;
        },
        set(data) {
          this.$emit(EVENT_MODEL, data);
        },
      },
    },

    methods: {
      archive() {
        this.$emit(EVENT_ARCHIVE, true);
      },
      restore() {
        this.$emit(EVENT_ARCHIVE, false);
      },
      setName(name) {
        this.privateMethodData = {
          ...this.privateMethodData,
          name,
        };
      },
      setValidation(value) {
        this.$emit(EVENT_VALIDATION, value);
      },

      scrollToField(fieldName) {
        if (fieldName === 'name') {
          this.$refs.name.$el.scrollIntoView({
            behavior: 'smooth',
          });
        } else if (fieldName) {
          this.$refs.editForm.scrollToField(fieldName);
        }
      },

      async toggleFavorite() {
        this.methodData.favorite
          ? await apiMethods.removeFromFavorites(this.methodData.id)
          : await apiMethods.addToFavorites(this.methodData.id);

        this.$emit(EVENT_FAVORITE, !this.methodData.favorite);
        this.privateMethodData = {
          ...this.privateMethodData,
          favorite: !this.methodData.favorite,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .method-form {
    &__wrapper-name {
      display: flex;
      justify-content: space-between;
    }

    &__actions {
      display: flex;
    }

    .favorites-button,
    .archive-button {
      margin-top: 10px;
    }

    &__state-archived {
      display: block;
      margin: 10px 0;
    }

    &__edit {
      margin-left: -10px;
    }

    &__btn-restore,
    &__btn-archive {
      margin-top: 12px;
    }
  }
</style>
