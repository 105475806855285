<template>
  <ValidationProvider
    ref="ValidationProvider"
    #default="{ errors, dirty, touched }"
    :rules="rules"
    :name="name"
    class="input-transparent"
  >
    <input
      ref="input"
      v-model="_value"
      v-bind="$attrs"
      class="input-transparent__field"
      :class="{ 'input-transparent__field--invalid': errors.length && dirty && touched }"
      v-on="$listeners"
    />

    <div v-if="errors.length && dirty && touched" class="input-transparent__error">
      {{ errors[0] }}
    </div>
  </ValidationProvider>
</template>

<script>
  const EVENT_MODEL = 'update:value';

  export default {
    name: 'InputTransparent',

    inheritAttrs: false,

    model: {
      props: 'value',
      event: EVENT_MODEL,
    },

    props: {
      value: {
        type: [Number, String],
        required: true,
      },
      rules: {
        type: [String, Object],
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
    },

    computed: {
      _value: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit(EVENT_MODEL, value);
        },
      },
    },

    methods: {
      focus() {
        this.$refs.input.focus();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .input-transparent {
    position: relative;
    display: inline-block;

    &__field {
      // TODO Styles were copied from global css to avoid conflicts in the future
      // sometimes global styles will be deleted, afterwards we will need to check this scoped styles
      border: none;
      padding: 0 5px;
      text-indent: 0;
      width: 100%;
      height: 22px;
      outline: none;
      font-size: inherit;
      box-sizing: border-box;
      appearance: none;
      border-bottom: 2px solid transparent;
      background-color: transparent;
      border-radius: 0;

      &:focus {
        border-bottom-color: $color-bg-primary;
      }

      &--invalid {
        &:focus {
          border-bottom-color: $color-bg-danger;
        }
      }
    }

    &__error {
      position: absolute;
      top: 100%;
      color: $color-text-danger;
      font-size: 9px;
      padding: 0 5px;
    }
  }
</style>
