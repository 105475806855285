const withoutRender = [
  // example
  // {
  //   path: '/add-product/',
  //   name: 'AddProduct',
  //   beforeEnter(to, from, next) {
  //     const { type, ...query } = to.query;
  //
  //     switch (type) {
  //       case 'hs':
  //         next({
  //           name: 'devices configure',
  //           query,
  //         });
  //         break;
  //       default:
  //         consoleHelpers.error('UNKNOWN PRODUCT');
  //         next('/');
  //     }
  //   },
  // },
];

export default withoutRender;
