<template>
  <DecoratorPaperBlock>
    <form class="form-add-device" @submit.stop.prevent="addDevice">
      <div class="form-add-device__inputs">
        <InputForm
          v-model="formData.id"
          title="Serial"
          placeholder="XXXX-XXXX"
          :mask="maskId"
          class="form-add-device__input"
          data-test-id="input-device-serial"
        />
        <InputForm
          v-model="formData.key"
          title="Key"
          placeholder="**"
          :mask="maskKey"
          class="form-add-device__input"
          data-test-id="input-device-key"
        />
      </div>

      <div class="form-add-device__btns">
        <Btn
          type="accent"
          :disabled="!formValid"
          :isSubmit="true"
          class="form-add-device__btn"
          data-test-id="btn-add-device"
        >
          Add device
        </Btn>
        <Btn class="form-add-device__btn" @click="scan">Scan</Btn>
        <Btn class="form-add-device__btn" @click="cancel">Cancel</Btn>
      </div>
    </form>
  </DecoratorPaperBlock>
</template>

<script>
  import Btn from '@/uikitBase/btns/Btn';
  import DecoratorPaperBlock from '@/uikitProject/decorators/DecoratorPaperBlock';
  import InputForm from '@/uikitProject/inputs/InputForm';

  const EVENT_SUBMIT = 'submit';
  const EVENT_SCAN = 'scan';
  const EVENT_CANCEL = 'cancel';

  export default {
    name: 'PrFormAddDevice',

    components: {
      InputForm,
      DecoratorPaperBlock,
      Btn,
    },

    props: {
      initialData: {
        type: Object,
        default: () => ({}),
      },
    },

    data: () => ({
      formData: {
        id: '',
        key: '',
      },

      maskId: {
        mask: 'xxxx-xxxx',
        tokens: {
          x: {
            pattern: /[0-9a-zA-Z]/,
            transform(v) {
              return v.toLocaleUpperCase();
            },
          },
        },
      },

      maskKey: {
        mask: 'xx',
        tokens: {
          x: {
            pattern: /[0-9a-zA-Z]/,
            transform(v) {
              return v.toLocaleUpperCase();
            },
          },
        },
      },
    }),

    computed: {
      formValid() {
        return (
          this.formData.id != null &&
          this.formData.id.length === 9 &&
          this.formData.key != null &&
          this.formData.key.length === 2
        );
      },
    },

    watch: {
      initialData() {
        const { id = '', key = '' } = this.initialData;

        this.formData = {
          id: id.replace(/(.{4})(.{4})/, '$1-$2'),
          key,
        };
      },
    },

    created() {
      const { id = '', key = '' } = this.initialData;

      this.formData = {
        id: id.replace(/(.{4})(.{4})/, '$1-$2'),
        key,
      };

      if (this.formValid) {
        this.addDevice();
      }
    },

    methods: {
      addDevice() {
        this.$emit(EVENT_SUBMIT, this.formData);
      },
      scan() {
        this.$emit(EVENT_SCAN);
      },
      cancel() {
        this.$emit(EVENT_CANCEL);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .form-add-device {
    display: flex;
    align-items: flex-end;

    @media (max-width: $screen-xs-max) {
      align-items: flex-start;
      flex-direction: column;
    }

    &__inputs {
      display: inline-block;
      margin-right: 8px;
    }

    &__input {
      @media (max-width: $screen-xs-max) {
        margin-bottom: 16px;
      }
    }

    &__btns {
      display: flex;
      align-items: center;
    }

    &__btn {
      margin-right: 8px;
    }
  }
</style>
