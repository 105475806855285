export const COLUMNS = [
  {
    id: 1,
    title: 'Time',
    dataType: 'min',
    hint: 'Sets the time (from the start) at which the gradient table entry will be enabled.',
  },
  {
    id: 2,
    title: 'A',
    dataType: '%',
    hint: 'Sets the portion of Pump A solvent for that interval. ',
  },
  {
    id: 3,
    title: 'B',
    dataType: '%',
    hint: 'Sets the portion of Pump B solvent for that interval.',
  },
  {
    id: 4,
    title: 'C',
    dataType: '%',
    hint: 'Sets the share of Pump C for that interval.',
  },
  {
    id: 5,
    title: 'D',
    dataType: '%',
    hint: 'Sets the share of Pump D for that interval.',
  },
];
