<template>
  <div class="ethernet">
    <Tippy
      :isInteractive="true"
      trigger="click"
      placement="bottom-start"
      :duration="[200, 67]"
      :popperOptions="popperOptions"
      :isHideOnClickInside="false"
      :isDisabled="!hasEthernet || !isDeviceOnline"
    >
      <template #trigger>
        <PopupHelper
          v-if="!hasEthernet || !isDeviceOnline"
          text="Plug in an Ethernet cable to access the Internet or continue to use Wi-Fi."
        >
          <div class="ethernet__btn ethernet__btn--disabled">
            <img
              src="../../../../../assets/img/icons/ethernet.svg"
              class="ethernet__icon ethernet__icon--disabled"
            />
            <DisconnectedStatus class="ethernet__disconnected-status" />
          </div>
        </PopupHelper>
        <BtnIcon v-else class="ethernet__btn">
          <img
            src="../../../../../assets/img/icons/ethernet.svg"
            class="ethernet__icon"
            :class="{
              'ethernet__icon--no-connection': !hasConnection,
            }"
          />
          <InUseStatus v-if="isInUse" class="ethernet__in-use-status" />
        </BtnIcon>
      </template>

      <template #default="{ isShow }">
        <Details v-if="isShow" :deviceSocket="deviceSocket" />
      </template>
    </Tippy>
  </div>
</template>

<script>
  import BtnIcon from '@/uikitBase/btns/BtnIcon.vue';
  import Tippy from '@/uikitBase/popups/vueTippy/Tippy.vue';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper.vue';
  import Details from '@/uikitProject/devices/vueDeviceConnection/private/vueEthernet/private/Details.vue';
  import InUseStatus from '@/uikitProject/devices/vueDeviceConnection/private/common/InUseStatus.vue';
  import DisconnectedStatus from '@/uikitProject/devices/vueDeviceConnection/private/common/DisconnectedStatus.vue';

  export default {
    name: 'Ethernet',

    components: {
      DisconnectedStatus,
      InUseStatus,
      Details,
      PopupHelper,
      Tippy,
      BtnIcon,
    },

    props: {
      connectionDetails: {
        type: Object,
      },
      deviceSocket: {
        type: Object,
        required: true,
      },
      isDeviceOnline: {
        type: Boolean,
        required: true,
      },
    },

    computed: {
      popperOptions() {
        const pageContentContainer = document.querySelector('.main-wrapper');

        return {
          modifiers: {
            preventOverflow: {
              boundariesElement: pageContentContainer,
              enabled: true,
            },
          },
        };
      },
      hasEthernet() {
        return this.connectionDetails != null;
      },
      hasConnection() {
        return this.connectionDetails.connection?.connection ?? false;
      },
      isInUse() {
        return this.connectionDetails.connection?.in_use ?? false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .ethernet {
    display: flex;
    align-items: center;
    color: #000;
    white-space: normal;
    height: 21px;

    &__icon {
      width: 18px;

      &--disabled,
      &--no-connection {
        opacity: 0.4;
      }
    }

    &__btn {
      position: relative;

      &--disabled {
        cursor: not-allowed;
      }
    }

    &__disconnected-status {
      position: absolute;
      bottom: 0;
      right: -2px;
    }

    &__in-use-status {
      position: absolute;
      top: -1px;
      right: -1px;
    }
  }
</style>
