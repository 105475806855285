<template>
  <div class="injections-navbar">
    <div id="injections-navbar__print-break-up" class="break-up" />
    <div
      id="injections-navbar__content"
      v-resize:throttle="onResize"
      class="injections-navbar__content block--gray block--shadow-in"
    >
      <button
        class="rect-button injections-navbar__btn"
        v-bind:class="{ 'rect-button--selected': injectionsAll }"
        @click="injectionsAll = true"
      >
        Injections
      </button>

      <div v-if="!usePager" class="injections-navbar__wrapper-injections">
        <button
          v-if="hasNavigationButtons"
          :disabled="isDisabledNavigationButtonLeft"
          class="rect-button injections-navbar__btn-navigation"
          @click="scrollNavigationToStart"
        >
          <IconMaterial title="first_page" />
        </button>
        <button
          v-if="hasNavigationButtons"
          :disabled="isDisabledNavigationButtonLeft"
          class="rect-button injections-navbar__btn-navigation"
          @click="scrollNavigationLeft"
        >
          <IconMaterial title="chevron_left" />
        </button>
        <div
          ref="injections"
          class="injections-navbar__injections hide-scrollbar"
          @scroll="initNavigationButtons"
        >
          <button
            v-for="i in injectionsFiltered"
            :key="i.injection.id"
            :ref="`injection-${i.injection.id}`"
            class="rect-button"
            :class="{
              'rect-button--selected': i.injection.id === injectionID,
              'rect-button--gray': i.injection.archived,
            }"
            @click="selectID(i.injection.id)"
          >
            {{ i.injection.name }}
            <StatesForEntity :hasTitle="false" :state="i.injection.state" class="inline" />
          </button>
        </div>
        <template v-if="hasNavigationButtons">
          <button
            :disabled="isDisabledNavigationButtonRight"
            class="rect-button injections-navbar__btn-navigation"
            @click="scrollNavigationRight"
          >
            <IconMaterial title="chevron_right" />
          </button>
          <button
            :disabled="isDisabledNavigationButtonRight"
            class="rect-button injections-navbar__btn-navigation"
            @click="scrollNavigationToEnd"
          >
            <IconMaterial title="last_page" />
          </button>
        </template>
      </div>
      <PagerComponent
        v-else
        :prev-enabled="prevID"
        :next-enabled="nextID"
        :current="pagerCurrent"
        :total="Object.keys(injections).length"
        @prev="prev"
        @next="next"
        @prevToStart="goNavigationToStart"
        @nextToEnd="goNavigationToEnd"
      />

      <template v-if="allowNew">
        <PopupHelper
          v-if="isInProgress"
          text="You can not create a new injection until the current one is finished"
        >
          <button disabled class="rect-button injections-navbar__btn">
            <i class="material-icons material-icon--14">add</i>
            New Injection
          </button>
        </PopupHelper>
        <button
          v-else
          class="rect-button injections-navbar__btn"
          v-bind:class="{ 'rect-button--selected': injectionNew }"
          @click="injectionNew = true"
        >
          <i class="material-icons material-icon--14">add</i>
          New Injection
        </button>
      </template>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import StatesForEntity from '@/uikitProject/states/packs/StatesForEntity.vue';
  import resize from 'vue-resize-directive';
  import PagerComponent from 'components/block/PagerComponent';
  import DomHelper from 'utils/DomHelper';
  import IconMaterial from '@/uikitBase/icons/IconMaterial.vue';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper.vue';

  export default {
    name: 'InjectionsNavBarComponent',

    components: { PopupHelper, IconMaterial, PagerComponent, StatesForEntity },

    directives: { resize },

    model: {
      prop: 'injectionID',
      event: 'update:injectionID',
    },

    props: {
      allowNew: {
        type: Boolean,
        default: true,
      },
      injectionID: Number,
      injections: Object,
      archived: Boolean,
      isInProgress: {
        type: Boolean,
      },
    },

    data: () => ({
      componentWidth: 0,
      hasNavigationButtons: false,
      isDisabledNavigationButtonLeft: false,
      isDisabledNavigationButtonRight: false,
    }),

    computed: {
      injectionsFiltered() {
        if (this.archived) {
          return this.injections;
        }
        return _.pickBy(this.injections, (i) => !i.injection.archived);
      },

      injectionsAll: {
        get() {
          return this.injectionID == null;
        },
        set(b) {
          if (b) this.selectID(null);
        },
      },

      injectionNew: {
        get() {
          return this.injectionID === -1;
        },
        set(b) {
          if (b) this.selectID(-1);
        },
      },

      pagerCurrent() {
        return this.injections[this.injectionID]?.injection?.name || '-';
      },
      prevID() {
        return _.last(_.filter(Object.keys(this.injectionsFiltered), (i) => i < this.injectionID));
      },
      nextID() {
        return _.first(_.filter(Object.keys(this.injectionsFiltered), (i) => i > this.injectionID));
      },

      usePager() {
        return (
          this.componentWidth < 480 &&
          Object.keys(this.injectionsFiltered).length > 3 &&
          this.injectionID &&
          this.injectionID >= 0
        );
      },
    },

    watch: {
      injectionID: {
        async handler(id) {
          await this.$nextTick();
          const element = this.$refs[`injection-${id}`]?.[0];
          if (element) DomHelper.scrollToHorizontally(element);
        },
        immediate: true,
      },
      injections() {
        this.initNavigationButtons();
      },
    },

    updated() {
      this.initNavigationButtons();
    },

    methods: {
      selectID(id) {
        this.$emit('update:injectionID', id);
      },

      prev() {
        this.$emit('update:injectionID', this.prevID);
      },

      next() {
        this.$emit('update:injectionID', this.nextID);
      },

      onResize(div) {
        this.componentWidth = div.getBoundingClientRect().width;
      },

      async initNavigationButtons() {
        await this.$nextTick();
        const { injections } = this.$refs;
        if (injections) {
          this.hasNavigationButtons = injections.clientWidth < injections.scrollWidth;
          this.isDisabledNavigationButtonLeft = injections.scrollLeft === 0;
          this.isDisabledNavigationButtonRight =
            Math.abs(injections.scrollLeft + injections.clientWidth - injections.scrollWidth) < 2;
        }
      },
      scrollNavigationLeft() {
        const { injections } = this.$refs;
        if (injections) {
          const { scrollLeft, clientWidth } = injections;
          injections.scrollTo({
            left: scrollLeft - clientWidth,
            behavior: 'smooth',
          });
        }
      },
      scrollNavigationRight() {
        const { injections } = this.$refs;
        if (injections) {
          const { scrollLeft, clientWidth } = injections;
          injections.scrollTo({
            left: scrollLeft + clientWidth,
            behavior: 'smooth',
          });
        }
      },
      scrollNavigationToStart() {
        const { injections } = this.$refs;
        if (injections) {
          injections.scrollTo({
            left: 0,
            behavior: 'smooth',
          });
        }
      },
      scrollNavigationToEnd() {
        const { injections } = this.$refs;
        if (injections) {
          const { scrollWidth } = injections;
          injections.scrollTo({
            left: scrollWidth,
            behavior: 'smooth',
          });
        }
      },
      goNavigationToStart() {
        this.selectID(Object.keys(this.injectionsFiltered)[0]);
      },
      goNavigationToEnd() {
        const injectionIds = Object.keys(this.injectionsFiltered);
        this.selectID(injectionIds[injectionIds.length - 1]);
      },
    },
  };
</script>

<style lang="scss">
  .injections-navbar__content {
    display: flex;
    width: 100%;
  }

  .injections-navbar {
    &__btn {
      @media (max-width: $screen-sm-max) {
        padding-left: 4px;
        padding-right: 4px;
      }
    }

    &__wrapper-injections {
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-right: auto;
    }

    &__injections {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      flex-grow: 1;
      flex-wrap: nowrap;

      :first-child {
        margin-left: auto;
      }
    }

    &__btn-navigation {
      display: flex;
      font-size: 22px;
      padding: 15px 2px;
    }
  }

  #injections-navbar__print-break-up {
    display: none;
  }

  .break-up {
    height: 0.5px;
    background: #888888 !important;
    width: calc(100% - 64px);
    border: solid 1px #888888;
    box-sizing: border-box;
    margin: 0 32px;
  }

  @media print {
    #injections-navbar__print-break-up {
      display: block;
    }

    #injections-navbar__content {
      display: none;
    }
  }

  .hide-scrollbar::-webkit-scrollbar {
    height: 0;
    background: transparent;
  }
  .hide-scrollbar::-webkit-scrollbar-thumb {
    background: #ff0000;
  }
</style>
