<template>
  <div class="domp-browser">
    <Btn
      v-if="serialDeviceId"
      :isLoading="isLoadingDompBrowser"
      type="primary"
      class="domp-browser__btn-show-browser"
      @click="getDompObjects"
    >
      Get objects
    </Btn>

    <div v-if="serialDeviceId && data && data.length && tabs" class="domp-browser__content">
      <div class="domp-browser__wrapper-tabs">
        <Tabs :tabs="tabs" :tabSelected.sync="tabSelected" class="domp-browser__tabs" />
      </div>

      <div class="domp-browser__wrapper-fields">
        <Fields
          :key="tabSelected.code"
          :fields="fieldsInSelectedTable"
          class="domp-browser__fields"
          @update="updateField"
          @get="getField"
        />
      </div>
    </div>

    <div v-else-if="serialDeviceId && data && !data.length">Data is not valid!</div>

    <div v-if="loggedCommands.length" class="domp-browser__log">
      <div class="domp-browser__label-log">Log</div>
      <div
        v-for="({ command, type }, index) of loggedCommands"
        :key="index"
        class="domp-browser__executed-command"
        :class="`domp-browser__executed-command--${type}`"
      >
        {{ command }}
      </div>
    </div>

    <InputForm
      v-if="serialDeviceId"
      v-model="inputs.command"
      title="Command"
      name="command"
      class="domp-browser__input-command"
      @keypress.enter="submitDompCommand"
    />
  </div>
</template>

<script>
  import Tabs from '@/uikitProject/selectors/Tabs';
  import Fields from './private/Fields';
  import Btn from '@/uikitBase/btns/Btn';
  import DeviceSocket, { DeviceSocketEvents } from 'api/sockets/DeviceSocket';
  import InputForm from '@/uikitProject/inputs/InputForm';

  const LOG_TYPE_INPUT = 'in';
  const LOG_TYPE_OUTPUT = 'out';
  const LOG_TYPE_ERROR = 'error';

  export default {
    name: 'DompBrowser',

    components: {
      InputForm,
      Btn,
      Fields,
      Tabs,
    },

    props: {
      deviceSocket: {
        type: DeviceSocket,
        required: true,
      },
      serialDeviceId: {
        type: [Number, String],
        required: true,
      },
    },

    data: () => ({
      tabSelected: null,

      loggedCommands: [],

      isLoadingDompBrowser: false,

      data: null,

      inputs: {
        command: '',
      },
    }),

    computed: {
      tabs() {
        return this.data?.map(({ name: title, id: code }) => ({ title, code }));
      },
      fieldsInSelectedTable() {
        return this.data?.find(({ id }) => id === this.tabSelected.code)?.fields;
      },
    },

    watch: {
      deviceSocket: {
        handler(deviceSocket) {
          deviceSocket.addEventListener(
            DeviceSocketEvents.DOMP_DESCRIPTION,
            (serialDeviceId, data) => {
              if (this.serialDeviceId === serialDeviceId) {
                this.data = data;
                this.isLoadingDompBrowser = false;
              }
            },
          );

          deviceSocket.addEventListener(
            DeviceSocketEvents.COMMAND_RESPONSE,
            (from, rawResponse, varStr) => {
              if (from === `serialdeviceid:${this.serialDeviceId}`) {
                const { name, value } = varStr;

                if (value) {
                  const tabId = name[0];
                  const fieldId = name.slice(1);
                  this.updateLocalField({ tabId, fieldId, value });

                  this.loggedCommands.unshift({
                    command: rawResponse,
                    type: LOG_TYPE_INPUT,
                  });
                }
              }
            },
          );

          deviceSocket.addEventListener(DeviceSocketEvents.ERROR, this.handleSocketError);
        },
        immediate: true,
      },
      tabs: {
        handler(tabs) {
          this.initSelectedTab(tabs);
        },
        immediate: true,
      },
    },

    beforeDestroy() {
      this.deviceSocket.removeEventListener(DeviceSocketEvents.ERROR, this.handleSocketError);
    },

    methods: {
      handleSocketError(e) {
        this.loggedCommands.unshift({
          command: e,
          type: LOG_TYPE_ERROR,
        });
      },

      initSelectedTab(tabs) {
        if (tabs) {
          const hasSelectedTabInUpdatedTabs =
            this.tabSelected && this.tabs.find((tab) => tab.code === this.tabSelected.code);

          if (!hasSelectedTabInUpdatedTabs) {
            this.tabSelected = this.tabs[0];
          }
        } else {
          this.tabSelected = null;
        }
      },

      getDompObjects() {
        this.isLoadingDompBrowser = true;
        this.showNotificationIfRpcError(() =>
          this.deviceSocket.getDompDescription(this.serialDeviceId),
        );
      },

      updateField({ value, index, type }) {
        // eslint-disable-next-line no-useless-escape
        const valueFormatted = type === 'INT' ? value : `\"${value}\"`;
        const assignSign = type === 'INT' ? '=' : '/';
        const commandUpdate = String.raw`>1 ${this.tabSelected.code}${index}${assignSign}${valueFormatted}`;

        this.showNotificationIfRpcError(() =>
          this.deviceSocket.command('serialdeviceid:' + this.serialDeviceId, commandUpdate),
        );

        this.loggedCommands.unshift({
          command: `${commandUpdate}`,
          type: LOG_TYPE_OUTPUT,
        });
      },
      getField({ index }) {
        const commandGet = `>1 ${this.tabSelected.code}${index}?`;

        this.showNotificationIfRpcError(() =>
          this.deviceSocket.command('serialdeviceid:' + this.serialDeviceId, commandGet),
        );

        this.loggedCommands.unshift({
          command: commandGet,
          type: LOG_TYPE_OUTPUT,
        });
      },

      updateLocalField({ tabId, fieldId, value }) {
        if (this.data) {
          this.data = this.data.map((tabData) => {
            if (String(tabData.id).toLowerCase() === String(tabId).toLowerCase()) {
              return {
                ...tabData,
                fields: tabData.fields.map((field) => {
                  if (String(field.id) === String(fieldId)) {
                    return {
                      ...field,
                      value,
                    };
                  }
                  return field;
                }),
              };
            }

            return tabData;
          });
        }
      },

      submitDompCommand() {
        this.showNotificationIfRpcError(() =>
          this.deviceSocket.command('serialdeviceid:' + this.serialDeviceId, this.inputs.command),
        );

        this.loggedCommands.unshift({
          command: this.inputs.command,
          type: LOG_TYPE_OUTPUT,
        });

        this.inputs.command = '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .domp-browser {
    &__btn-show-browser {
      margin-bottom: 20px;
    }

    &__wrapper-tabs {
      max-width: 100%;
      overflow-y: auto;
      margin-bottom: 20px;
    }

    &__log {
      margin-top: 20px;
      margin-bottom: 20px;
      line-height: 1.7;
    }

    &__label-log {
      color: $color-text-third;
      margin-bottom: 5px;
    }

    &__executed-command {
      padding: 3px 9px;
      margin-bottom: 3px;
      border-radius: $border-radius__sm;

      &--in {
        background-color: rgb(219, 233, 212);
      }

      &--out {
        background-color: rgb(253, 242, 209);
      }

      &--error {
        background-color: lighten($color-bg-danger, 30%);
      }
    }

    &__tabs {
      width: max-content;
    }

    &__wrapper-fields {
      max-width: 100%;
      overflow-y: auto;
    }

    &__fields {
      min-width: 550px;
    }

    &__input-command {
      display: block;
      margin-top: 20px;
    }
  }
</style>
