<template>
  <modal name="editChromatogramAppearance" :pivot-y="0" :width="485">
    <div class="modal-content modal-content--no-padding">
      <div class="modal-header">
        <h4>Edit chromatogram appearance</h4>
      </div>

      <div class="modal-body modal-body--big-padding edit-chromatogram-appearance">
        <div class="edit-chromatogram-appearance__content">
          <div class="edit-chromatogram-appearance__field">
            <Checkbox v-model="appearance.hasGridLines">Show grid lines</Checkbox>
          </div>
          <div class="edit-chromatogram-appearance__field">
            <Checkbox v-model="appearance.hasTooltipOnHover">Show coordinates on hover</Checkbox>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="modal-content__actions-panel">
          <div class="actions">
            <Btn type="primary" @click="save">Save</Btn>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import ModalComponent from '@/components/element/ModalComponent.vue';
  import Checkbox from '@/uikitBase/switchers/Checkbox.vue';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import { persistentStorage, PersistentStorageKeys } from '@/utils/persistentStorage';

  const getDefaultAppearance = () => ({
    hasGridLines: false,
    hasTooltipOnHover: false,
  });

  const EVENT_UPDATE_APPEARANCE = 'updateAppearance';

  export default {
    name: 'EditChromatogramAppearanceModal',

    components: {
      Btn,
      Checkbox,
      modal: ModalComponent,
    },

    data: () => ({
      appearance: getDefaultAppearance(),
    }),

    created() {
      this.init();
    },

    methods: {
      init() {
        this.appearance =
          persistentStorage.get(PersistentStorageKeys.CHROMATOGRAM_APPEARANCE) ??
          getDefaultAppearance();
      },

      close() {
        this.$modal.hide('editChromatogramAppearance');
      },

      save() {
        this.$emit(EVENT_UPDATE_APPEARANCE, this.appearance);
        persistentStorage.set(PersistentStorageKeys.CHROMATOGRAM_APPEARANCE, this.appearance);
        this.close();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .edit-chromatogram-appearance {
    &__field:not(:last-child) {
      margin-bottom: 10px;
    }
  }
</style>
