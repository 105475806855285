<template>
  <div class="add-contact">
    <div class="add-contact__undo" @click="$emit('cancel')">
      <IconMaterial title="undo" class="add-contact__icon-undo" />
    </div>

    <ValidationObserver
      #default="{ invalid }"
      tag="form"
      class="add-contact__form"
      @submit.prevent="add"
    >
      <div class="add-contact__inputs">
        <InputTransparent
          ref="inputName"
          v-model="inputs.name"
          placeholder="Name"
          rules="required"
          class="add-contact__input"
        />
        <InputTransparent
          v-model="inputs.email"
          placeholder="Email"
          rules="required|email"
          class="add-contact__input"
        />
      </div>

      <Btn
        borderRadius="sm"
        type="primary"
        height="s"
        padding="none"
        class="add-contact__btn-save"
        :disabled="invalid || isLoading"
        :isSubmit="true"
      >
        Add
      </Btn>
    </ValidationObserver>
  </div>
</template>

<script>
  import Btn from '@/uikitBase/btns/Btn';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import InputTransparent from '@/uikitProject/inputs/InputTransparent';
  import ContactsAPI from 'api/contacts';

  const EVENT_CREATE = 'create';

  export default {
    name: 'AddContact',

    components: { InputTransparent, IconMaterial, Btn },

    data: () => ({
      inputs: {
        name: '',
        email: '',
      },

      isLoading: false,
    }),

    mounted() {
      this.$refs.inputName.focus();
    },

    methods: {
      resetForm() {
        this.inputs = {
          name: '',
          email: '',
        };
      },
      add() {
        this.isLoading = true;

        ContactsAPI.create(
          {
            name: this.inputs.name,
            email: this.inputs.email,
          },
          (user) => {
            this.$emit(EVENT_CREATE, user);
            this.isLoading = false;
          },
          () => {
            this.notifyError(`Unable to create a new contact!`);
            this.isLoading = false;
          },
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .add-contact {
    min-height: 44px;
    display: flex;
    align-items: center;
    padding-left: 17px;
    padding-right: 14px;
    background-color: #f6f6f6;

    @media (max-width: $screen-xs-max) {
      padding: 0 9px;
      overflow-x: auto;
    }

    &__undo {
      flex: none;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: background-color 0.3s;
      margin-right: 13px;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }

      &:active {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    &__icon-undo {
      width: auto;
      height: auto;
    }

    &__form {
      flex: 1;
      display: flex;
      align-items: center;

      @media (max-width: $screen-xs-max) {
        flex: 1 0 auto;
      }
    }

    &__inputs {
      flex: 1;
      display: flex;
      margin-left: -5px;
      margin-right: 5px;

      @media (max-width: $screen-xs-max) {
        flex-direction: column;
        padding: 10px 0;
      }
    }

    &__input {
      flex: 1;

      @media (max-width: $screen-xs-max) {
        margin-bottom: 3px;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    &__btn-save {
      width: 75px;
      flex: none;
    }
  }
</style>
