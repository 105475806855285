import DateHelper from 'utils/DateHelper.ts';
import { isThisYear } from 'date-fns';

const options = { timeStyle: 'short' };
const { language } = navigator;

function formatTime(strDate) {
  const date = new Date(strDate);
  return date.toLocaleTimeString(language, options);
}

export default {
  methods: {
    formatTime(strDate) {
      if (strDate == null) return '--:--';
      return formatTime(strDate);
    },
    formatDate(strDate) {
      if (strDate == null) return '##/##';

      const date = new Date(strDate);
      return DateHelper.formatDate(date, isThisYear(date) ? 'LL/dd' : 'P');
    },
  },
};
