<template>
  <div ref="keyboardSelector">
    <GlobalEvents
      v-if="isActive"
      @keydown.up="moveUpSelection"
      @keydown.down="moveDownSelection"
      @keydown.enter.capture="select"
    />
    <slot :selectedId="selectedId" />
  </div>
</template>

<script>
  import GlobalEvents from 'vue-global-events';

  const EVENT_HIGHLIGHT = 'highlight';

  const OFFSET = 50;
  const INDEX_NOT_SELECTED = -1;

  export default {
    name: 'WithKeyboardSelection',

    components: {
      GlobalEvents,
    },

    props: {
      isActive: {
        type: Boolean,
        default: true,
      },
      ids: {
        type: Array,
        required: true,
      },
      onSelect: {
        type: Function,
        required: true,
      },
      isScrollToElement: {
        type: Boolean,
        default: true,
      },
      // This HOC will use scrollIntoView if no element is provided
      scrollContainer: {
        type: typeof window === 'undefined' ? Object : window.HTMLElement,
      },
    },

    data: () => ({
      selectedIndex: INDEX_NOT_SELECTED,
    }),

    computed: {
      selectedId() {
        return this.ids[this.selectedIndex];
      },
    },

    watch: {
      ids() {
        this.selectedIndex = INDEX_NOT_SELECTED;
      },
      selectedId(id) {
        if (this.selectedId && this.selectedId !== INDEX_NOT_SELECTED) {
          this.$emit(EVENT_HIGHLIGHT, this.selectedId);
        }

        const elem = this.$el.querySelector(`[data-id="${id}"]`);
        elem && this.scrollToElement(elem);
      },
    },

    methods: {
      moveUpSelection(e) {
        if (!this.hasFocus()) {
          return;
        }

        e.preventDefault();

        if (this.selectedIndex <= 0) {
          this.selectedIndex = this.ids.length - 1;
        } else {
          this.selectedIndex -= 1;
        }
      },
      moveDownSelection(e) {
        if (!this.hasFocus()) {
          return;
        }

        e.preventDefault();

        const indexNew = (this.selectedIndex + 1) % this.ids.length;
        this.selectedIndex = isNaN(indexNew) ? INDEX_NOT_SELECTED : indexNew;
      },
      select(e) {
        if (!this.hasFocus()) {
          return;
        }

        e.preventDefault();

        if (this.selectedId != null) {
          this.onSelect(this.selectedId);
        }
      },

      scrollToElement(elem) {
        if (this.scrollContainer) {
          // eslint-disable-next-line vue/no-mutating-props
          this.scrollContainer.scrollTop = elem.offsetTop - OFFSET;
          return;
        }

        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      },

      hasFocus() {
        if (document.activeElement) {
          return document.activeElement.contains(this.$refs.keyboardSelector);
        }
        return false;
      },
    },
  };
</script>
