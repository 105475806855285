import { makeGraphqlRequestCloud } from 'api/graphql/graphQLClient';
import { Organization } from '@/graphql/cloud/organizations/queries/Organization.graphql';
import { SetOrganizationName } from '@/graphql/cloud/organizations/mutations/SetOrganizationName.graphql';
import { SetMemberRole } from '@/graphql/cloud/organizations/mutations/SetMemberRole.graphql';

export const apiOrganizations = {
  async getOrganization(organizationId) {
    const { organization } = await makeGraphqlRequestCloud(Organization, { organizationId });

    return organization;
  },
  async setOrganizationName(organizationId, name) {
    const { organization } = await makeGraphqlRequestCloud(SetOrganizationName, {
      organizationId,
      name,
    });

    return organization;
  },
  setMemberRole(memberId, role) {
    return makeGraphqlRequestCloud(SetMemberRole, {
      memberId,
      role,
    });
  },
};
