<template>
  <div v-tippy class="reference" :content="message">?</div>
</template>

<script>
  export default {
    name: 'Reference',

    props: {
      message: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .reference {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;

    color: $color-text-third;
    font-weight: $weight-bold;
    font-size: 9px;

    border: 1px solid darken($color-bg-third, 20%);
    border-radius: 50%;
    cursor: default;
  }
</style>
