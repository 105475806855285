// eslint-disable-next-line no-unused-vars
import { G, Pattern, Polyline, Rect as SvgRect, SVG, Svg, Text } from '@svgdotjs/svg.js';
import * as _ from 'lodash';
import { DELTAS_MAU, DELTAS_TIME, OUT_OF_DETECTION_VALUE } from './chart-constants';
// eslint-disable-next-line no-unused-vars
import { Dictionary, Rect } from './types';
import isPointInside from '@/utils/chart/isPointInside';
import NavigatorHelper, { BrowserNames } from '@/utils/NavigatorHelper';
import ApproximationHelper from '@/utils/chart/ApproximationHelper';
import { consoleHelpers } from '@/utils/logHelpers';
import { Nullable, Optional } from '@/types/utility';

type ListenerPeakHover = (peakId: number, isHighlighted: boolean) => void;

interface Peak {
  apex: number;
  apex_time_str: string;
  area: number;
  end: number;
  end_mau?: number;
  id: number;
  start: number;
  start_mau?: number;
  is_manual?: boolean;
}

interface Padding {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

interface ChartBounds {
  minutesMin: number;
  minutesMax: number;
  mauMin: number;
  mauMax: number;
}

interface Measurement {
  data: number[];
  mps: number;
  startPosition?: number;

  color?: string;

  peaks?: Peak[];

  baseline: {
    data?: number[];
    visible: boolean;
  };

  drawings: {
    baseline?: Polyline;
    polyline?: Polyline;
    peaks: G;
    peaksHighlight: G;
  };
}

interface ScaleConfig {
  hasGrid: boolean;
  factor: number;
  minutesMin?: number;
  minutesMax?: number;
  mauMax?: Nullable<number>;
  mauMin?: Nullable<number>;
  padding: Padding;
  enableOverlay?: boolean;
  hasAutoZoomMinBounds?: boolean;
}

interface ColorScheme {
  background: string;
  scale: string;
  text: string;
  line: string;
  baseline: string;
  timeLabels: string;
  timeLabelsBg: string;
  mauLabels: string;
  peakNums: string;
  peakLineManual: string;
  peakLineAuto: string;
  chartAxis: string;
}

interface DrawOnly {
  polyline?: boolean;
  baseline?: boolean;
  peaks?: boolean;
}

const DrawOnlyFull: DrawOnly = {
  polyline: true,
  baseline: true,
  peaks: true,
};

interface Options {
  hasOnlyMinMaxMau: boolean;
  canSelectPeak: boolean;
  hasAxisLabels: boolean;
  hasTimeScale: boolean;
  hasMauScale: boolean;
  yAxisName?: string;
  isRoundYValues?: boolean;
  // If this value is false, we add an extra space
  doShowExactBounds?: boolean;
  /**
   * Use this flag to support detection time on the comparison page
   */
  hasSpacesIfOutOfDetectionTime?: boolean;
}

export const DEFAULT_PADDING: Padding = { left: 32, top: 24, right: 32, bottom: 24 };

function toShort(bounds: ChartBounds) {
  const { minutesMin: xs, minutesMax: xe, mauMin: ys, mauMax: ye } = bounds;
  return { xs, xe, ys, ye };
}

const DEFAULT_OPTIONS: Options = {
  canSelectPeak: false,
  hasAxisLabels: false,
  hasTimeScale: true,
  hasMauScale: true,
  hasOnlyMinMaxMau: false,
};

class ChromatogramPainter {
  get isZoomed(): boolean {
    const bounds = this.getChartBounds();
    const range = bounds.mauMax - bounds.mauMin;

    const extraSpace = this.options.doShowExactBounds ? 0 : range * 0.1;

    const noZoom = {
      minutesMin: this.scaleConfig.minutesMin ?? bounds.minutesMin,
      minutesMax: this.scaleConfig.minutesMax ?? bounds.minutesMax,
      mauMin: this.scaleConfig.mauMin ?? bounds.mauMin - extraSpace,
      mauMax: this.scaleConfig.mauMax ?? bounds.mauMax + extraSpace,
    };

    return Object.entries(this.zoom).some(([key, value]) => value !== noZoom[key]);
  }

  public div: HTMLElement;

  public svgContainer: HTMLElement;

  public draw: Svg;

  public overlayDraw?: Svg;

  public scaleDrawTime?: G;
  public scaleDrawMau?: G;

  public chartRect!: Rect;

  public measurements: Dictionary<Measurement>;

  public zoom: ChartBounds;
  private oldZoom?: ChartBounds & { wasZoomed: boolean };

  public scaleConfig: ScaleConfig;

  public colorConfig: ColorScheme;

  public highlightedPeakIds: Set<string>;

  public peaksHighlight: Polyline[];

  public peakTitles: Text[];

  public peaksForDelete: Array<{ id: number; figure: Polyline }>;

  public options: Options;

  public backgroundHatching: Pattern;

  public background!: SvgRect;

  private mps!: number;

  private listenerPeakHover: Nullable<ListenerPeakHover>;

  private xAxisLabels: Nullable<HTMLElement>;

  constructor(
    div: HTMLElement,
    scaleConfig: ScaleConfig,
    colorScheme: ColorScheme,
    options: Options = DEFAULT_OPTIONS,
  ) {
    this.div = div;
    this.scaleConfig = scaleConfig;
    this.scaleConfig.padding = this.scaleConfig.padding ?? DEFAULT_PADDING;
    this.colorConfig = colorScheme;
    this.highlightedPeakIds = new Set();
    this.peaksHighlight = [];
    this.peakTitles = [];
    this.peaksForDelete = [];
    this.options = options;

    this.measurements = {};
    this.zoom = {
      minutesMin: 0,
      minutesMax: 0,
      mauMin: 0,
      mauMax: 0,
    };

    this.div.innerHTML = '';

    const { top, right, bottom, left } = this.scaleConfig.padding;

    const container = document.createElement('DIV');
    container.style.width = '100%';
    container.style.height = '100%';
    container.style.padding = `${top}px ${right}px ${bottom}px ${left}px`;
    div.appendChild(container);
    this.svgContainer = container;

    this.draw = SVG().addTo(container).size('100%', '100%');

    this.draw.attr({
      fontFamily: 'Helvetica',
    });

    this.backgroundHatching = this.draw
      .pattern(10, 6, (add) => {
        add.rect(10, 6).fill('#eaf0f9');
        add.line(0, 0, 10, 0).stroke({ color: '#e2e8f0', width: 5 });
      })
      .rotate(-45)
      .id('backgroundHatching');

    this.updateRect();

    if (this.scaleConfig.enableOverlay) {
      const overlay = document.createElement('DIV');
      overlay.style['box-sizing'] = 'border-box';
      overlay.style.width = '100%';
      overlay.style.height = '100%';
      overlay.style.position = 'absolute';
      overlay.style.top = '0';
      overlay.style['pointer-events'] = 'none';
      div.appendChild(overlay);

      this.overlayDraw = SVG().addTo(overlay).size('100%', '100%');
    }
  }

  public indexMin() {
    return 0;
  }

  public indexMax() {
    const eachLength = Object.values(this.measurements).map((o) => o.data.length);
    return Math.max(...eachLength);
  }

  public minutesMin() {
    return this.scaleConfig.minutesMin ?? 0;
  }

  public minutesMax() {
    if (this.scaleConfig.minutesMax) {
      return this.scaleConfig.minutesMax;
    }

    const eachLength = Object.values(this.measurements).map(
      (measurement) => (measurement.data.length - 1) / 60 / measurement.mps,
    );
    return Math.max(...eachLength);
  }

  public mauMin() {
    const eachMauMin = Object.values(this.measurements).map((o) => _.min(o.data));
    return _.min(eachMauMin) ?? Infinity;
  }

  public mauMax() {
    const eachMauMax = Object.values(this.measurements).map((o) =>
      _.max(
        this.options.hasSpacesIfOutOfDetectionTime
          ? o.data.filter((point) => point !== OUT_OF_DETECTION_VALUE)
          : o.data,
      ),
    );
    return _.max(eachMauMax) ?? -Infinity;
  }

  public getChartBounds(): ChartBounds {
    const mauMin = this.mauMin();
    return {
      minutesMin: this.minutesMin(),
      minutesMax: this.minutesMax(),
      mauMin,
      // TODO why?
      mauMax: Math.max(mauMin + 0.1, this.mauMax()),
    };
  }

  public getHW() {
    const { height: h, width: w } = this.chartRect;
    return { h, w };
  }

  public getXY(mouseEvent: MouseEvent) {
    const rect = this.chartRect;

    const x = mouseEvent.clientX - rect.x;
    const y = mouseEvent.clientY - rect.y;
    return { x, y };
  }

  public getMinutesMau(screenXY) {
    const r = this.chartRect;
    const { top, left } = this.scaleConfig.padding;
    let x = screenXY.x - left;
    x = x < 0 ? 0 : x;
    const y = screenXY.y - top;

    const minutesDelta = this.zoom.minutesMax - this.zoom.minutesMin;
    const mauDelta = this.zoom.mauMax - this.zoom.mauMin;
    const minutes = this.zoom.minutesMin + (x * minutesDelta) / r.width;
    const mau = this.zoom.mauMin + ((r.height - y) * mauDelta) / r.height;
    return { minutes, mau };
  }

  // time in minutes
  public valueChartXY(data: number[], mps: number, time: number, mau?: number): [number, number] {
    let aTime = time;

    const { h, w } = this.getHW();

    const chartBounds = toShort(this.getZoom());
    let { xs, xe } = chartBounds;
    const { ys, ye } = chartBounds;
    xs = xs * 60 * mps;
    xe = xe * 60 * mps;

    aTime = Math.round(aTime * 60 * mps);

    const x = ((aTime - xs) * w) / (xe - xs);

    const yMau = mau ?? data[aTime];
    const y = h - ((yMau - ys) * h) / (ye - ys);

    return [x, y];
  }

  public updateRect() {
    const el = this.svgContainer;
    const computedStyle = getComputedStyle(el);

    let height = el.clientHeight; // height with padding
    let width = el.clientWidth; // width with padding

    height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
    width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
    const { x, y } = this.svgContainer.getBoundingClientRect();
    this.chartRect = { height, width, x, y };
  }

  public paintMauScale(scaleGroup: G, bounds: ChartBounds, scaleFactor: number) {
    const {
      scale: scaleColor,
      mauLabels: mauLabelsColor,
      chartAxis: chartAxisColor,
    } = this.colorConfig;
    const { ys, ye } = toShort(bounds);

    if (isNaN(ye) && isNaN(ys)) {
      return;
    }

    const { h, w } = this.getHW() ?? { h: null };

    if (h == null) {
      return;
    }

    // this.options.hasAxisLabels && this.draw.text('mAU').move(40, 2);

    scaleGroup.line(0, 0, 0, h).stroke({ width: 2, color: chartAxisColor }).front();

    if (!scaleColor && !mauLabelsColor) return;

    scaleGroup.fill(mauLabelsColor);

    if (this.options.hasOnlyMinMaxMau && !isNaN(ye) && !isNaN(ys)) {
      const unitGroupHighest = scaleGroup.group().front();
      const numberHighest = unitGroupHighest
        .plain(`${ye}${this.options.yAxisName ?? ''}`)
        .attr({ x: 3, y: 10 });
      const numberBoxHighest = numberHighest.bbox();
      unitGroupHighest
        .rect(numberBoxHighest.width, numberBoxHighest.height)
        .radius(3, 3)
        .fill('white')
        .attr({ x: 3, y: 13 - numberBoxHighest.height })
        .back();

      const unitGroupLowest = scaleGroup.group().front();
      const numberLowest = unitGroupLowest
        .plain(`${ys}${this.options.yAxisName ?? ''}`)
        .attr({ x: 3, y: h - 5 });
      const numberBoxLowest = numberLowest.bbox();
      unitGroupLowest
        .rect(numberBoxLowest.width, numberBoxLowest.height)
        .radius(3, 3)
        .fill('white')
        .attr({ x: 3, y: h - 2 - numberBoxLowest.height })
        .back();
    } else {
      let d = (ye - ys) / scaleFactor;
      for (let i = 0; i < DELTAS_MAU.length; i++) {
        if (d <= DELTAS_MAU[i]) {
          d = DELTAS_MAU[i];
          break;
        }
      }

      const min2 = Math.round(ys / d) * d;

      const units: { value: string; y: number }[] = [];
      for (let mau = min2; mau <= ye; mau += d) {
        const y = h - ((mau - ys) * h) / (ye - ys);

        units.push({
          value: (Math.round(mau * 100) / 100).toString(),
          y,
        });
      }

      const areUnitsCorrect =
        units.length > 1 && units.filter((unit) => unit.y > 0 && unit.y < h - 30).length > 1;

      const isZoomed = this.isZoomed;

      if (areUnitsCorrect) {
        let isLabelAdded = false;

        units.reverse().forEach(({ value, y }) => {
          const unitGroup = scaleGroup.group().front();

          const doAddLabel = !isLabelAdded && y > 20 && !isZoomed;

          const number = unitGroup
            .plain(doAddLabel ? `${value} mAU` : value)
            .attr({ x: 3, y: y - 3 });

          if (doAddLabel) {
            isLabelAdded = true;
          }

          const numberBox = number.bbox();
          unitGroup
            .rect(numberBox.width, numberBox.height)
            .radius(3, 3)
            .fill('white')
            .attr({ x: 3, y: y - numberBox.height })
            .back();

          if (scaleColor) {
            const lineConfig = this.scaleConfig.hasGrid ? [0, y, w, y] : [0, y, 4, y];
            scaleGroup.line(lineConfig).stroke({ width: 1, color: scaleColor }).back();
          }
        });
      } else {
        const unitGroupHighest = scaleGroup.group().front();
        const numberHighest = unitGroupHighest
          .plain(`${ye.toFixed(3)}${isZoomed ? '' : ' mAU'}`)
          .attr({ x: 3, y: 10 });
        const numberBoxHighest = numberHighest.bbox();
        unitGroupHighest
          .rect(numberBoxHighest.width, numberBoxHighest.height)
          .radius(3, 3)
          .fill('white')
          .attr({ x: 3, y: 13 - numberBoxHighest.height })
          .back();

        if (scaleColor) {
          const lineConfig = this.scaleConfig.hasGrid ? [0, 10, w, 10] : [0, 0, 4, 0];
          scaleGroup.line(lineConfig).stroke({ width: 1, color: scaleColor }).back();
        }

        const unitGroupMiddle = scaleGroup.group().front();
        const numberMiddle = unitGroupMiddle
          .plain(`${((ye + ys) / 2).toFixed(3)}`)
          .attr({ x: 3, y: h / 2 - 3 });
        const numberBoxMiddle = numberMiddle.bbox();
        unitGroupMiddle
          .rect(numberBoxMiddle.width, numberBoxMiddle.height)
          .radius(3, 3)
          .fill('white')
          .attr({ x: 3, y: h / 2 + 5 - 3 - numberBoxMiddle.height })
          .back();

        if (scaleColor) {
          const y = h / 2;
          const lineConfig = this.scaleConfig.hasGrid ? [0, y, w, y] : [0, y, 4, y];
          scaleGroup.line(lineConfig).stroke({ width: 1, color: scaleColor }).back();
        }
      }
    }
  }

  removeMauScale() {
    this.scaleDrawMau?.clear();
    this.scaleDrawMau = undefined;
  }

  public paintTimeScale(scaleGroup: G, bounds: ChartBounds, mps: number) {
    const {
      scale: colorScale,
      timeLabels: timeLabelsColor,
      timeLabelsBg = 'white',
      chartAxis: chartAxisColor,
    } = this.colorConfig;
    let { xs, xe } = toShort(bounds);

    const { h, w } = this.getHW() ?? { h: null, w: null };
    if (h == null || w == null) {
      return;
    }

    // this.options.hasAxisLabels && this.draw.text('Time, min').move(w - 60, h - 20);

    scaleGroup.line(0, h, w, h).stroke({ width: 2, color: chartAxisColor }).front();

    if (!colorScale && !timeLabelsColor) return;

    scaleGroup.fill(timeLabelsColor);

    const duration = xe - xs;
    let dW = 1;
    if (duration / 15 > 1 || duration / 8 < 1) {
      dW = duration / 12;
      for (let i = 0; i < DELTAS_TIME.length; i++) {
        if (dW < DELTAS_TIME[i]) {
          dW = DELTAS_TIME[i];
          break;
        }
      }
    }

    dW = dW * mps * 60;
    xs = xs * 60 * mps;
    xe = xe * 60 * mps;

    const x2 = Math.round(xs / dW) * dW;

    const units: { value: string; x: number }[] = [];
    for (let i = x2; i < xe; i += dW) {
      const x = ((i - xs) * w) / (xe - xs);

      units.push({
        value: (Math.round((i / mps / 60) * 100) / 100).toString(),
        x,
      });
    }

    const isZoomed = this.isZoomed;

    const areUnitsCorrect =
      units.length > 1 && units.filter((unit) => unit.x > 0 && unit.x < w - 30).length > 1;

    if (areUnitsCorrect) {
      this.xAxisLabels?.remove();
      this.xAxisLabels = document.createElement('div');
      this.xAxisLabels.style.position = 'relative';
      this.xAxisLabels.style.width = '100%';
      this.svgContainer.append(this.xAxisLabels);

      const labelsFragment = document.createDocumentFragment();

      units.forEach(({ value, x }, index) => {
        const isHighestUnit = units.length - 1 === index;

        if (x > 0) {
          const label = document.createElement('div');
          label.innerHTML = !isZoomed && isHighestUnit ? `${value} min` : value;
          label.style.position = 'absolute';
          label.style.left = `${x}px`;
          label.style.color = timeLabelsColor;
          labelsFragment.append(label);
        }

        this.xAxisLabels && this.xAxisLabels.append(labelsFragment);

        if (colorScale) {
          const lineConfig = this.scaleConfig.hasGrid ? [x, 0, x, h] : [x, h - 4, x, h];
          scaleGroup.line(lineConfig).stroke({ width: 1, color: colorScale }).back();
        }
      });
    } else {
      const unitGroupHighest = scaleGroup.group().front();
      const numberHighest = unitGroupHighest
        .plain(`${(Math.round((xe / mps / 60) * 100) / 100).toString()}${isZoomed ? '' : ' min'}`)
        .attr({ x: w, y: h - 7 });
      const numberBoxHighest = numberHighest.bbox();
      numberHighest.move(numberBoxHighest.x - numberBoxHighest.width, numberBoxHighest.y);
      const numberBoxTopUpdated = numberHighest.bbox();
      unitGroupHighest
        .rect(numberBoxTopUpdated.width, numberBoxTopUpdated.height)
        .radius(3, 3)
        .fill(timeLabelsBg)
        .attr({ x: numberBoxTopUpdated.x, y: h - 3 - numberBoxTopUpdated.height })
        .back();

      if (colorScale) {
        const lineConfig = this.scaleConfig.hasGrid
          ? [w - 1, 0, w - 1, h]
          : [w - 1, h - 4, w - 1, h];
        scaleGroup.line(lineConfig).stroke({ width: 1, color: colorScale }).back();
      }

      const unitGroupMiddle = scaleGroup.group().front();
      const numberMiddle = unitGroupMiddle
        .plain(`${(Math.round(((xe + xs) / 2 / mps / 60) * 100) / 100).toString()}`)
        .attr({ x: w / 2, y: h - 7 });
      const numberBoxMiddle = numberMiddle.bbox();
      numberMiddle.move(numberBoxMiddle.x - numberBoxMiddle.width, numberBoxMiddle.y);
      const numberBoxMiddleUpdated = numberMiddle.bbox();
      unitGroupMiddle
        .rect(numberBoxMiddleUpdated.width, numberBoxMiddleUpdated.height)
        .radius(3, 3)
        .fill(timeLabelsBg)
        .attr({ x: numberBoxMiddleUpdated.x, y: h - 3 - numberBoxMiddleUpdated.height })
        .back();

      if (colorScale) {
        const lineConfig = this.scaleConfig.hasGrid
          ? [w / 2, 0, w / 2, h]
          : [w / 2, h - 4, w / 2, h];
        scaleGroup.line(lineConfig).stroke({ width: 1, color: colorScale }).back();
      }
    }
  }

  public drawBackground() {
    const { w, h } = this.getHW();
    if (!this.background) {
      this.background = this.draw.rect(w, h).fill(this.colorConfig.background).back();
    }
  }

  public drawLayout(mps: number, isForceUpdate = false) {
    const bounds = this.getZoom();
    const scaleFactor = this.scaleConfig.factor;

    if (
      this.options.hasMauScale &&
      (this.oldZoom?.mauMin !== bounds.mauMin ||
        this.oldZoom?.mauMax !== bounds.mauMax ||
        this.oldZoom.wasZoomed !== this.isZoomed ||
        !this.scaleDrawMau)
    ) {
      if (!this.scaleDrawMau) {
        this.scaleDrawMau = this.draw.group();
      } else {
        this.scaleDrawMau.clear();
      }

      this.paintMauScale(this.scaleDrawMau, bounds, scaleFactor);
    } else if (!this.options.hasMauScale) {
      this.removeMauScale();
    }

    if (
      this.options.hasTimeScale &&
      (isForceUpdate ||
        this.oldZoom?.minutesMin !== bounds.minutesMin ||
        this.oldZoom?.minutesMax !== bounds.minutesMax ||
        this.oldZoom.wasZoomed !== this.isZoomed)
    ) {
      if (!this.scaleDrawTime) {
        this.scaleDrawTime = this.draw.group();
      } else {
        this.scaleDrawTime.clear();
      }
      mps && this.paintTimeScale(this.scaleDrawTime, bounds, mps);
    }

    this.drawBackground();

    if (mps) {
      this.oldZoom = { ...bounds, wasZoomed: this.isZoomed };
    }
  }

  public paintAll(options: { drawOnly?: DrawOnly; isForceUpdate?: boolean } = {}) {
    this.updateRect();

    if (this.chartRect.width <= 0 || this.chartRect.height <= 0) {
      // eslint-disable-next-line no-console
      consoleHelpers.error('chartRect has negative dimensions, aborting paintAll()');
      return;
    }

    const measurements = Object.values(this.measurements);
    this.mps = Math.max(...measurements.map((m) => m.mps));

    this.drawLayout(this.mps, options.isForceUpdate);
    measurements.forEach((m) => {
      this.drawMeasurement(m, options.drawOnly);
    });
  }

  public dataToPoints(
    data: number[],
    mps: number,
    options: { isParsedForDrawing: true; startPosition?: number },
  ): Nullable<string>;
  public dataToPoints(
    data: number[],
    mps: number,
    options: { isParsedForDrawing: false; startPosition?: number },
  ): Nullable<number[]>;
  public dataToPoints(
    data: number[],
    mps: number,
    {
      isParsedForDrawing = true,
      startPosition = 0,
    }: { isParsedForDrawing: boolean; startPosition?: number },
  ): Nullable<number[] | string> {
    const { h, w } = this.getHW() ?? { h: undefined, w: undefined };
    if (h == null || w == null) {
      return;
    }

    const chartBounds = toShort(this.getZoom());
    let { xs, xe } = chartBounds;
    const { ys, ye } = chartBounds;
    xs = xs * 60 * mps;
    xe = xe * 60 * mps;

    const pointsForDrawing: string[] = [];
    const pointsForMeasurement: number[] = [];

    const addPoint = (x: number, y: number) => {
      const isValidX = x != null && !isNaN(x) && isFinite(x);
      const isValidY = y != null && !isNaN(y) && isFinite(y);
      if (isValidX && isValidY) {
        if (isParsedForDrawing) {
          pointsForDrawing.push(`,${x} ${y}`);
        } else {
          pointsForMeasurement.push(x, y);
        }
      }
    };

    const start = Math.floor(xs);
    const end = xe;

    const diffX = xe - xs;
    const diffY = ye - ys;

    // end + 1 add one extra point to display charts with different mps
    for (let i = start; i <= end + 1; i += 1) {
      const mau = data[i];

      if (typeof mau !== 'undefined') {
        if (this.options.hasSpacesIfOutOfDetectionTime ? mau !== OUT_OF_DETECTION_VALUE : true) {
          const x = ((i + startPosition - xs) * w) / diffX;

          const y = h - ((mau - ys) * h) / diffY;
          addPoint(x, y);
        }
      }
    }

    if (isParsedForDrawing) {
      // The most performant way to concatenate strings
      if (NavigatorHelper.browserName === BrowserNames.CHROME) {
        return ''.concat(...pointsForDrawing).slice(1);
      }
      return pointsForDrawing.join('').slice(1);
    }

    return pointsForMeasurement;
  }

  public setZoom(zoom: Optional<ChartBounds, 'mauMin' | 'mauMax'>) {
    const { mauMax = this.zoom.mauMax, mauMin = this.zoom.mauMin, minutesMax, minutesMin } = zoom;
    const { minutesMax: minutesMaxBound, minutesMin: minutesMinBound } = this.getChartBounds();

    this._setZoom({
      minutesMax: minutesMax < minutesMaxBound ? minutesMax : minutesMaxBound,
      minutesMin: minutesMin > minutesMinBound ? minutesMin : minutesMinBound,
      mauMax,
      mauMin,
    });
    this.paintAll();
  }

  public getZoom(): ChartBounds {
    return this.zoom;
  }

  public resetZoom() {
    this._resetZoom();
    this.paintAll();
  }

  public updateSize() {
    this.paintAll({ isForceUpdate: true });
  }

  public updateMinutesMin(minutesMin) {
    this.scaleConfig.minutesMin = minutesMin;
  }

  public updateMinutesMax(minutesMax) {
    this.scaleConfig.minutesMax = minutesMax;
  }

  public updateMauMax(mauMax) {
    this.scaleConfig.mauMax = mauMax;
  }

  public updateMauMin(mauMin) {
    this.scaleConfig.mauMin = mauMin;
  }

  public updateHasMauScale(hasMauScale: boolean) {
    this.options.hasMauScale = hasMauScale;
  }

  public updateDoShowExactBounds(value: boolean) {
    this.options.doShowExactBounds = value;
  }

  public isFullscreen() {
    const bounds = this.getChartBounds();
    if (this.zoom.minutesMin === 0 && this.zoom.minutesMax === 0) {
      return true;
    }
    return this.zoom.minutesMin === 0 && this.zoom.minutesMax === bounds.minutesMax;
  }

  public getPeakIdByPoint(p: { x: number; y: number }) {
    const peakTitle = this.peakTitles.find((peak) => peak.inside(p.x, p.y));
    if (peakTitle) {
      return peakTitle.data('id');
    }

    const peakHighlight = this.peaksHighlight.find((peak) =>
      // @ts-ignore
      isPointInside([p.x, p.y], peak.plot()),
    );
    if (peakHighlight) {
      return peakHighlight.data('id-raw');
    }
  }

  ///

  public addMeasurement(
    data: number[],
    mps: number,
    key = 'main',
    options: { color?: string; drawOnly?: DrawOnly; doRedraw: boolean; startPosition?: number } = {
      doRedraw: true,
    },
  ) {
    if (!data) {
      return false;
    }

    if (key in this.measurements) {
      if (!data.length) {
        return false;
      }

      const measurement = this.measurements[key];

      this.measurements[key] = {
        ...measurement,
        // TODO without spread?
        data: [...data],
        mps,
        startPosition: options.startPosition,
        color: options.color,
      };
    } else {
      this.measurements[key] = {
        data: [...data],
        mps,
        color: options.color,
        startPosition: options.startPosition,
        baseline: {
          visible: false,
        },
        drawings: {
          peaks: this.draw.group().addClass('peaks'),
          peaksHighlight: this.draw.group().addClass('peaks-highlight'),
        },
      };
    }

    if (options.doRedraw) {
      this.paintAll({ drawOnly: options.drawOnly });
    }

    return true;
  }

  // TODO combine two clear methods
  public clearMeasurement(key = 'main') {
    this.measurements[key].drawings.polyline?.remove();
    this.measurements[key].drawings.baseline?.remove();
    this.measurements[key].drawings.peaks?.remove();
    this.measurements[key].drawings.peaksHighlight?.remove();

    this.highlightedPeakIds = new Set();
    this.peaksHighlight = [];

    delete this.measurements[key];
    this.paintAll();
  }
  public clearAllMeasurements(options: { doRedraw: boolean } = { doRedraw: true }) {
    Object.values(this.measurements).forEach((measurement) => {
      measurement.drawings.polyline?.remove();
      measurement.drawings.baseline?.remove();
      measurement.drawings.peaks?.remove();
      measurement.drawings.peaksHighlight?.remove();
    });

    this.highlightedPeakIds = new Set();
    this.peaksHighlight = [];

    this.measurements = {};

    if (options.doRedraw) {
      this.paintAll();
    }
  }

  public setBaselineToMeasurement(baseline: number[], key = 'main') {
    if (key in this.measurements) {
      const measurement = this.measurements[key];
      if (_.isEqual(measurement.baseline.data, baseline)) {
        return false;
      }

      measurement.baseline.data = baseline;

      this.drawMeasurement(measurement, { baseline: true });
      return true;
    }
    return false;
  }

  public removeBaselineFromMeasurement(key = 'main') {
    if (key in this.measurements) {
      const measurement = this.measurements[key];

      measurement.baseline.data = undefined;
      measurement.drawings.baseline?.remove();

      return true;
    }
    return false;
  }

  public setPeaksToMeasurement(peaks: Peak[], key = 'main') {
    if (key in this.measurements) {
      const measurement = this.measurements[key];
      if (_.isEqual(measurement.peaks, peaks)) {
        return false;
      }

      this.measurements[key].peaks = _.clone(peaks);

      this.drawMeasurement(measurement, { peaks: true });
      return true;
    }
    return false;
  }

  public setPeakHighlighting(peakId: string, isHighlighted: boolean) {
    const id = `peak-highlight-${peakId}`;
    isHighlighted ? this.highlightedPeakIds.add(id) : this.highlightedPeakIds.delete(id);

    const group = this.measurements.main?.drawings.peaksHighlight;
    if (!group) {
      return;
    }

    const peakHighlight = group.node.querySelector(`#${id}`);

    if (peakHighlight) {
      const wrapperPeakHighlight = SVG(peakHighlight);

      if (isHighlighted) {
        wrapperPeakHighlight.fill(this.backgroundHatching);
      } else {
        wrapperPeakHighlight.fill('transparent');
      }
    }
  }

  public highlightForDeleteInside(timeMin: number, timeMax: number) {
    this.peaksForDelete = [];

    this.peaksHighlight.forEach((peak) => {
      const timeStart = peak.x();
      const timeEnd = timeStart + peak.width();

      const isTimeStartInside = timeStart > timeMin && timeStart < timeMax;
      const isTimeEndInside = timeEnd > timeMin && timeEnd < timeMax;
      const isTimeBothInside = timeStart < timeMin && timeEnd > timeMax;

      if (isTimeStartInside || isTimeEndInside || isTimeBothInside) {
        peak.fill('rgba(255,0,102,0.5)');
        this.peaksForDelete.push({ id: peak.data('id-raw'), figure: peak });
      } else {
        peak.fill('transparent');
      }
    });
  }

  public resetHighlightForDelete() {
    this.peaksForDelete.forEach(({ figure }) => figure.fill('transparent'));
  }

  public setMeasurementColor(measurementId: string, color) {
    this.measurements[measurementId]?.drawings.polyline?.stroke(color);
  }

  public highlightMeasurement(
    measurementId: string | number,
    options: { colorActive: string; colorInactive: string },
  ) {
    const { colorActive, colorInactive } = options;
    Object.entries(this.measurements).forEach(([id, measurement]) => {
      const isHighlight = id === String(measurementId);
      measurement.drawings.polyline?.stroke(isHighlight ? colorActive : colorInactive);
      isHighlight && measurement.drawings.polyline?.front();
    });
  }

  public disableMeasurementsHighlighting() {
    Object.values(this.measurements).forEach((measurement) => {
      measurement.drawings.polyline?.stroke(this.colorConfig.line);
    });
  }

  ///

  public drawPolyline(measurement: Measurement, options: { color?: string } = {}) {
    const { line: colorLine } = this.colorConfig;

    const points = this.dataToPoints(measurement.data, measurement.mps, {
      isParsedForDrawing: true,
      startPosition: measurement.startPosition,
    });
    if (!points?.length) {
      return;
    }

    return this.draw
      .polyline(points)
      .fill('none')
      .stroke({
        color: options?.color ?? colorLine,
        width: 2,
        linecap: 'round',
        linejoin: 'round',
      });
  }

  public drawBaseline(baseline: number[], mps: number) {
    const { baseline: baselineColor } = this.colorConfig;
    const baselinePoints = this.dataToPoints(baseline, mps, { isParsedForDrawing: true });
    if (baselinePoints == null) {
      return;
    }

    return this.draw.polyline(baselinePoints).fill('none').stroke({
      color: baselineColor,
      width: 2,
      linecap: 'round',
      linejoin: 'round',
      dasharray: '8,8',
    });
  }

  public drawPeaks(drawPeaks: G, data: number[], peaks: Peak[], mps: number) {
    const {
      peakNums: peakNumsColor,
      peakLineManual: peakLineManualColor,
      peakLineAuto: peakLineAutoColor,
    } = this.colorConfig;
    const { h, w } = this.getHW();

    const chartBounds = toShort(this.getZoom());
    let { xs, xe } = chartBounds;
    const { ys, ye } = chartBounds;
    xs = xs * 60 * mps;
    xe = xe * 60 * mps;

    if (peaks) {
      this.peakTitles = [];

      for (let i = 0; i < peaks.length; i++) {
        const peak = peaks[i];
        const time = Math.round(peak.apex * 60 * mps);
        const x = ((time - xs) * w) / (xe - xs);
        const y = h - ((data[time] - ys) * h) / (ye - ys);

        const peakTitle = this.draw
          .text((i + 1).toString())
          .move(x, y - 17)
          .fill(peakNumsColor)
          .font({
            family: 'Helvetica',
            weight: 'bold',
            size: 13,
            anchor: 'middle',
            leading: '1.5em',
          })
          .addClass('peak-title')
          .data('id', peak.id);

        if (this.options.canSelectPeak && !this.isZoomed) {
          peakTitle.css('cursor', 'pointer');
        }

        this.peakTitles.push(peakTitle);
        drawPeaks.add(peakTitle);

        drawPeaks
          .line([
            ...this.valueChartXY(data, mps, peak.start, peak?.start_mau ?? 0),
            ...this.valueChartXY(data, mps, peak.end, peak?.end_mau ?? 0),
          ])
          .stroke({
            color: peak.is_manual ? peakLineManualColor : peakLineAutoColor,
            width: 2,
          });

        // draw 2 perpendiculars
        if (peak.start_mau !== undefined && peak.end_mau !== undefined) {
          const strokeStyle = {
            color: '#000',
            width: 2,
            dasharray: '2,2',
          };

          const lineCoordsA = [
            ...this.valueChartXY(data, mps, peak.start, peak.start_mau),
            ...this.valueChartXY(data, mps, peak.start),
          ];

          const lineCoordsB = [
            ...this.valueChartXY(data, mps, peak.end, peak.end_mau),
            ...this.valueChartXY(data, mps, peak.end),
          ];

          if (lineCoordsA.every(isFinite) && lineCoordsB.every(isFinite)) {
            drawPeaks.line(lineCoordsA).stroke(strokeStyle);
            drawPeaks.line(lineCoordsB).stroke(strokeStyle);
          }
        }
      }
    }
  }

  public drawPeaksHighlight(
    groupPeaksHighlight: G,
    measurementData: number[],
    peaks: Peak[],
    mps: number,
  ) {
    groupPeaksHighlight.clear();

    const points = this.dataToPoints(measurementData, mps, { isParsedForDrawing: false });
    if (!points) {
      return;
    }

    if (peaks) {
      this.peaksHighlight = [];

      for (let i = 0; i < peaks.length; i++) {
        const peak = peaks[i];

        const [minX, minY] = this.valueChartXY(
          measurementData,
          mps,
          peak.start,
          peak.start_mau ?? 0,
        );
        const [maxX, maxY] = this.valueChartXY(measurementData, mps, peak.end, peak.end_mau ?? 0);

        if (!isFinite(minX) || !isFinite(minY) || !isFinite(maxX) || !isFinite(maxY)) {
          return;
        }

        const measurementPart: number[] = [];
        for (let j = 0; j < points.length; j = j + 2) {
          const x = points[j];

          if (x > maxX) {
            break;
          }

          if (x >= minX) {
            const y = points[j + 1];
            measurementPart.push(x, y);
          }
        }

        const id = `peak-highlight-${peak.id}`;

        const area = this.draw
          .polyline([
            minX,
            minY,
            ...this.valueChartXY(measurementData, mps, peak.start),
            ...measurementPart,
            ...this.valueChartXY(measurementData, mps, peak.end),
            maxX,
            maxY,
          ])
          .id(id)
          .addClass('peak-highlight')
          .data('id-raw', peak.id)
          .fill('transparent');

        if (this.options.canSelectPeak && !this.isZoomed) {
          area.css('cursor', 'pointer');
        }

        const that = this;
        area.on('mouseenter', function () {
          that.listenerPeakHover?.(peak.id, true);
        });
        area.on('mouseleave', function () {
          that.listenerPeakHover?.(peak.id, false);
        });

        this.peaksHighlight.push(area);
        groupPeaksHighlight.add(area);

        this.highlightedPeakIds.has(id) && area.fill(this.backgroundHatching);
      }
    }
  }

  public drawMeasurement(measurement: Measurement, only: DrawOnly = DrawOnlyFull) {
    measurement.drawings.peaks.clear();
    measurement.drawings.peaksHighlight.clear();

    if (only.polyline) {
      measurement.drawings.polyline?.remove();

      measurement.drawings.polyline = this.drawPolyline(measurement, {
        color: measurement.color,
      })?.front();
    }

    if (only.baseline) {
      measurement.drawings.baseline?.remove();
      if (measurement.baseline.data) {
        measurement.drawings.baseline = this.drawBaseline(
          measurement.baseline.data,
          measurement.mps,
        )?.back();
        this.toggleBaseline({ show: measurement.baseline.visible });
      }
    }

    if (only.peaks) {
      if (measurement.peaks) {
        this.drawPeaks(
          measurement.drawings.peaks,
          measurement.data,
          measurement.peaks,
          measurement.mps,
        );

        this.drawPeaksHighlight(
          measurement.drawings.peaksHighlight,
          measurement.data,
          measurement.peaks,
          measurement.mps,
        );
        measurement.drawings.peaks.back();
        measurement.drawings.polyline?.back();
      }
    }
  }

  ///

  public toggleBaseline({ key = 'main', show = true }) {
    if (show) {
      this.measurements[key].drawings.baseline?.show();
    } else {
      this.measurements[key].drawings.baseline?.hide();
    }

    this.measurements[key].baseline.visible = show;
  }

  private _setZoom(zoom: ChartBounds) {
    this.zoom = zoom;
  }

  private _resetZoom() {
    const { isRoundYValues } = this.options;
    const bounds = this.getChartBounds();
    const range = bounds.mauMax - bounds.mauMin;

    const peaksMau = Object.values(this.measurements).reduce((data, measurement) => {
      if (measurement.peaks) {
        const peaks = measurement.peaks?.reduce((data, peak) => {
          if (peak.start_mau !== undefined) data.push(peak.start_mau);
          if (peak.end_mau !== undefined) data.push(peak.end_mau);
          return data;
        }, [] as number[]);

        data.push(...peaks);
      }

      return data;
    }, [] as number[]);

    const peakMauValueMax = Math.max(...peaksMau);
    const peakMauValueMin = Math.min(...peaksMau);

    const extraSpace = this.options.doShowExactBounds ? 0 : range * 0.1;

    const _mauMax =
      Math.max(this.scaleConfig.mauMax ?? bounds.mauMax, peakMauValueMax) + extraSpace;
    const _mauMin =
      Math.min(this.scaleConfig.mauMin ?? bounds.mauMin, peakMauValueMin) - extraSpace;

    if (isRoundYValues) {
      const [mauMin, mauMax] = ApproximationHelper.approximateChartBounds(_mauMin, _mauMax);
      this._setZoom({
        minutesMin: this.scaleConfig.minutesMin ?? bounds.minutesMin,
        minutesMax: this.scaleConfig.minutesMax ?? bounds.minutesMax,
        mauMin: this.scaleConfig.hasAutoZoomMinBounds ? Math.min(-1, mauMin) : mauMin,
        mauMax: this.scaleConfig.hasAutoZoomMinBounds ? Math.max(1, mauMax) : mauMax,
      });
    } else {
      this._setZoom({
        minutesMin: this.scaleConfig.minutesMin ?? bounds.minutesMin,
        minutesMax: this.scaleConfig.minutesMax ?? bounds.minutesMax,
        mauMin: this.scaleConfig.hasAutoZoomMinBounds ? Math.min(-1, _mauMin) : _mauMin,
        mauMax: this.scaleConfig.hasAutoZoomMinBounds ? Math.max(1, _mauMax) : _mauMax,
      });
    }
  }

  public addPeakHoverListener(listener: ListenerPeakHover) {
    this.listenerPeakHover = listener;
  }
}

export default ChromatogramPainter;
