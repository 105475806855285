import _ from 'lodash';
import { STUB_FUNCTION } from '@/utils/stubHelpers';
import Hammer from 'hammerjs';
import { delay } from '@/utils/asyncHelpers';

const CLASS_ZOOM_ACTIVATION_OVERLAY = 'chromatogram-zoom-activation-overlay';
const CLASS_ZOOM_ACTIVATION_OVERLAY_VISIBLE = 'chromatogram-zoom-activation-overlay--visible';

const addStyleSheetForTouchDeviceOverlay = _.once(() => {
  const css = window.document.styleSheets[0];
  css.insertRule(
    `
    .${CLASS_ZOOM_ACTIVATION_OVERLAY} {
      position: absolute;
      top: 0;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.55) 28%, rgba(0,0,0,0.52) 50%, rgba(0,0,0,0.55) 66%, rgba(0,0,0,0.65) 100%);
      font-size: 22px;
      color: white;
      transition: opacity .3s;

      @media (hover: hover) and (pointer: fine) {
        display: none
      }
    }
    `,
    css.cssRules.length,
  );

  css.insertRule(
    `
    .${CLASS_ZOOM_ACTIVATION_OVERLAY_VISIBLE} {
      opacity: 1;
    }
    `,
    css.cssRules.length,
  );
});

export const createZoomActivationOverlay = () => {
  const zoomActivationOverlay = {
    element: document.createElement('div'),
    onActive: STUB_FUNCTION,
    onDisable: STUB_FUNCTION,
    disable() {
      this.element.remove();
      this.onDisable();
    },
    show() {
      zoomActivationOverlay.element.classList.add(CLASS_ZOOM_ACTIVATION_OVERLAY_VISIBLE);
    },
    hide() {
      zoomActivationOverlay.element.classList.remove(CLASS_ZOOM_ACTIVATION_OVERLAY_VISIBLE);
    },
    get isVisible() {
      return zoomActivationOverlay.element.classList.contains(
        CLASS_ZOOM_ACTIVATION_OVERLAY_VISIBLE,
      );
    },
  };

  addStyleSheetForTouchDeviceOverlay();
  zoomActivationOverlay.element.classList.add(CLASS_ZOOM_ACTIVATION_OVERLAY);
  zoomActivationOverlay.element.innerText = 'Tap to activate zooming!';

  const hammerManager = new Hammer(zoomActivationOverlay.element);
  hammerManager.get('tap').recognizeWith('pan');
  hammerManager.get('tap').set({
    time: 1000,
    posThreshold: 50,
  });

  const activateZoom = () => {
    zoomActivationOverlay.element.remove();
    zoomActivationOverlay.onActive();
  };

  const showOverlay: HammerListener = async () => {
    hammerManager.stop(true);

    if (!zoomActivationOverlay.isVisible) {
      zoomActivationOverlay.show();
      hammerManager.off('pan tap', showOverlay);
      hammerManager.on('tap', activateZoom);
      await delay(5000);
      zoomActivationOverlay.hide();
      hammerManager.off('tap', activateZoom);
      hammerManager.on('pan tap', showOverlay);
    }
  };

  hammerManager.on('pan tap', showOverlay);

  return zoomActivationOverlay;
};
