<template>
  <ul class="unordered-list">
    <template v-if="itemsType === 'object'">
      <li v-for="(item, index) of items" :key="item[keyProp] || index" class="unordered-list__item">
        {{ item[labelProp] }}
      </li>
    </template>
    <template v-else>
      <li v-for="(item, index) of items" :key="index" class="unordered-list__item">{{ item }}</li>
    </template>
  </ul>
</template>

<script>
  export default {
    name: 'UnorderedList',

    props: {
      items: {
        type: Array,
        required: true,
      },
      keyProp: {
        type: String,
      },
      labelProp: {
        type: String,
        default: 'title',
      },
    },

    computed: {
      itemsType() {
        return typeof this.items[0];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .unordered-list {
    list-style-type: disc;
    padding-left: 20px;
    line-height: 1.5;
  }
</style>
