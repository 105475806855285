<template>
  <div class="bottle">
    <div
      class="bottle__label"
      :class="{ 'bottle__label--disabled': isDisabled }"
      @click="$emit('click')"
    >
      {{ label }}
    </div>
    <svg
      width="54"
      height="86"
      viewBox="0 0 54 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="bottle__visualization"
      :class="{ 'bottle__visualization--disabled': isDisabled }"
      @click="$emit('click')"
    >
      <defs>
        <mask id="mask" x="0" y="0">
          <path
            d="M0 38.1724C0 34.5442 1.23312 31.0238 3.49706 28.1886L13.2986 15.914C15.5625 13.0788 16.7956 9.55833 16.7956 5.93014V2C16.7956 0.895431 17.6911 0 18.7956 0H36.1537C37.2583 0 38.1537 0.895431 38.1537 2V6.18197C38.1537 9.65572 39.2842 13.0351 41.3745 15.8096L50.7792 28.293C52.8695 31.0674 54 34.4468 54 37.9206V78C54 82.4183 50.4183 86 46 86H8C3.58172 86 0 82.4183 0 78V38.1724Z"
            fill="#fff"
            mask="url(#mask)"
          />
        </mask>
      </defs>

      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill="#ddd"
        mask="url(#mask)"
        class="bottle__full"
      />
      <rect
        x="0"
        :y="filledBottleYCoord"
        width="100%"
        height="100%"
        :fill="isInPourInMode ? '#2a4cff' : '#ff08d6'"
        mask="url(#mask)"
        class="bottle__filled"
      />
    </svg>
  </div>
</template>

<script>
  const X1 = 0;
  const X2 = 54;
  const Y1 = 0;
  const Y2 = 86;

  export default {
    name: 'Bottle',

    props: {
      volume: {
        type: Number,
        required: true,
      },
      amount: {
        type: Number,
        required: true,
      },
      label: {
        type: String,
      },

      isInPourInMode: {
        type: Boolean,
        default: false,
      },

      isDisabled: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      viewBox: `${X1} ${Y1} ${X2} ${Y2}`,
    }),

    computed: {
      filledBottleYCoord() {
        const percentInCoord = Y2 / 100;
        const filledPercents = this.amount > this.volume ? 100 : (this.amount / this.volume) * 100;

        return Y2 - filledPercents * percentInCoord;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bottle {
    position: relative;
    width: 54px;
    height: 86px;

    &__label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      height: 20px;
      width: 24px;
      background: white;
      text-align: center;
      font-weight: $weight-bold;
      font-size: $size-xs;
      border-radius: 5px;

      &:not(&--disabled) {
        cursor: pointer;
      }

      &:hover:not(&--disabled) + .bottle__visualization {
        .bottle__full,
        .bottle__filled {
          filter: brightness(0.9);
        }
      }
    }

    &__visualization {
      cursor: pointer;

      &--disabled {
        cursor: default;
      }

      &:hover:not(&--disabled) {
        .bottle__full,
        .bottle__filled {
          filter: brightness(0.9);
        }
      }

      &:active:not(&--disabled) {
        .bottle__full,
        .bottle__filled {
          filter: brightness(0.85);
        }
      }
    }

    &__full,
    &__filled {
      transition: filter 0.3s;
    }
  }
</style>
