exports.KEY_CANCEL = 3;
exports.KEY_HELP = 6;
exports.KEY_BACK_SPACE = 8;
exports.KEY_TAB = 9;
exports.KEY_CLEAR = 12;
exports.KEY_RETURN = 13;
exports.KEY_ENTER = 13;
exports.KEY_SHIFT = 16;
exports.KEY_CONTROL = 17;
exports.KEY_ALT = 18;
exports.KEY_PAUSE = 19;
exports.KEY_CAPS_LOCK = 20;
exports.KEY_ESCAPE = 27;
exports.KEY_SPACE = 32;
exports.KEY_PAGE_UP = 33;
exports.KEY_PAGE_DOWN = 34;
exports.KEY_END = 35;
exports.KEY_HOME = 36;
exports.KEY_LEFT = 37;
exports.KEY_UP = 38;
exports.KEY_RIGHT = 39;
exports.KEY_DOWN = 40;
exports.KEY_PRINTSCREEN = 44;
exports.KEY_INSERT = 45;
exports.KEY_DELETE = 46;
exports.KEY_0 = 48;
exports.KEY_1 = 49;
exports.KEY_2 = 50;
exports.KEY_3 = 51;
exports.KEY_4 = 52;
exports.KEY_5 = 53;
exports.KEY_6 = 54;
exports.KEY_7 = 55;
exports.KEY_8 = 56;
exports.KEY_9 = 57;
exports.KEY_SEMICOLON = 59;
exports.KEY_EQUALS = 61;
exports.KEY_A = 65;
exports.KEY_B = 66;
exports.KEY_C = 67;
exports.KEY_D = 68;
exports.KEY_E = 69;
exports.KEY_F = 70;
exports.KEY_G = 71;
exports.KEY_H = 72;
exports.KEY_I = 73;
exports.KEY_J = 74;
exports.KEY_K = 75;
exports.KEY_L = 76;
exports.KEY_M = 77;
exports.KEY_N = 78;
exports.KEY_O = 79;
exports.KEY_P = 80;
exports.KEY_Q = 81;
exports.KEY_R = 82;
exports.KEY_S = 83;
exports.KEY_T = 84;
exports.KEY_U = 85;
exports.KEY_V = 86;
exports.KEY_W = 87;
exports.KEY_X = 88;
exports.KEY_Y = 89;
exports.KEY_Z = 90;
exports.KEY_LEFT_CMD = 91;
exports.KEY_RIGHT_CMD = 93;
exports.KEY_CONTEXT_MENU = 93;
exports.KEY_NUMPAD0 = 96;
exports.KEY_NUMPAD1 = 97;
exports.KEY_NUMPAD2 = 98;
exports.KEY_NUMPAD3 = 99;
exports.KEY_NUMPAD4 = 100;
exports.KEY_NUMPAD5 = 101;
exports.KEY_NUMPAD6 = 102;
exports.KEY_NUMPAD7 = 103;
exports.KEY_NUMPAD8 = 104;
exports.KEY_NUMPAD9 = 105;
exports.KEY_MULTIPLY = 106;
exports.KEY_ADD = 107;
exports.KEY_SEPARATOR = 108;
exports.KEY_SUBTRACT = 109;
exports.KEY_DECIMAL = 110;
exports.KEY_DIVIDE = 111;
exports.KEY_F1 = 112;
exports.KEY_F2 = 113;
exports.KEY_F3 = 114;
exports.KEY_F4 = 115;
exports.KEY_F5 = 116;
exports.KEY_F6 = 117;
exports.KEY_F7 = 118;
exports.KEY_F8 = 119;
exports.KEY_F9 = 120;
exports.KEY_F10 = 121;
exports.KEY_F11 = 122;
exports.KEY_F12 = 123;
exports.KEY_F13 = 124;
exports.KEY_F14 = 125;
exports.KEY_F15 = 126;
exports.KEY_F16 = 127;
exports.KEY_F17 = 128;
exports.KEY_F18 = 129;
exports.KEY_F19 = 130;
exports.KEY_F20 = 131;
exports.KEY_F21 = 132;
exports.KEY_F22 = 133;
exports.KEY_F23 = 134;
exports.KEY_F24 = 135;
exports.KEY_NUM_LOCK = 144;
exports.KEY_SCROLL_LOCK = 145;
exports.KEY_COMMA = 188;
exports.KEY_PERIOD = 190;
exports.KEY_SLASH = 191;
exports.KEY_BACK_QUOTE = 192;
exports.KEY_OPEN_BRACKET = 219;
exports.KEY_BACK_SLASH = 220;
exports.KEY_CLOSE_BRACKET = 221;
exports.KEY_QUOTE = 222;
exports.KEY_META = 224;
