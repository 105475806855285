<template>
  <div class="chromatogram-live">
    <div
      ref="divChart"
      v-resize:throttle="initChart"
      class="chromatogram-live__chart disable-selection"
    />
  </div>
</template>

<script>
  import resize from 'vue-resize-directive';
  import { CHART_COLORS, LOST_VALUE, CALIBRATION_VALUE } from 'utils/chart/chart-constants.ts';
  import ChromatogramPainter from 'utils/chart/chromatogram.ts';

  export default {
    name: 'ChromatogramLive',

    directives: { resize },

    props: {
      initialData: { type: Array, default: null },
      mps: { type: Number, required: true }, // Measurements per second
      prune: { type: Number, default: 6 }, // Remove values older than {prune} seconds

      chartBackground: { type: String, default: CHART_COLORS.background },
      chartAxisColor: { type: String, default: CHART_COLORS.chartAxis },
      chartTimeLabelsColor: { type: String, default: CHART_COLORS.timeLabels },
      chartTimeLabelsBg: { type: String, default: CHART_COLORS.timeLabels },
      chartMauLabelsColor: { type: String, default: CHART_COLORS.mauLabels },
      chartLineColor: { type: String, default: CHART_COLORS.line },
      hasOnlyMinMaxMau: { type: Boolean, default: false },
      isRoundYValues: { type: Boolean, default: false },
    },

    data() {
      return {
        chart: null,
        data: this.initialData || [],
      };
    },

    computed: {
      maxNumberOfValues() {
        return this.mps * this.prune;
      },
    },

    mounted() {
      this.initChart();
    },

    methods: {
      append(newValues) {
        const dataAppended = this.data;
        Array.prototype.push.apply(dataAppended, newValues);

        if (dataAppended.length > this.maxNumberOfValues) {
          dataAppended.splice(0, dataAppended.length - this.maxNumberOfValues);
        }

        const polylines = dataAppended.reduce(
          (polygons, point, index) => {
            if (point === LOST_VALUE || point === CALIBRATION_VALUE) {
              if (
                index > 0 &&
                dataAppended[index - 1] !== LOST_VALUE &&
                dataAppended[index - 1] !== CALIBRATION_VALUE
              ) {
                polygons.push({
                  data: [],
                  startPosition: index,
                });
              }
              polygons[polygons.length - 1].startPosition++;
            } else {
              polygons[polygons.length - 1].data.push(point);
            }

            return polygons;
          },
          [{ data: [], startPosition: 0 }],
        );

        this.chart.clearAllMeasurements({ doRedraw: false });

        polylines.forEach(({ data, startPosition }, index) => {
          this.chart.addMeasurement(data, this.mps, `chart-${index}-${startPosition}`, {
            doRedraw: index === polylines.length - 1,
            startPosition,
          });
        });

        this.chart.resetZoom();
      },

      initChart() {
        const scaleConfig = {
          factor: 4,
          enableOverlay: true,
          padding: { top: 0, right: 0, bottom: 0, left: 0 },
          hasGrid: false,
          minutesMax: this.maxNumberOfValues / this.mps / 60,
        };

        this.chart = new ChromatogramPainter(
          this.$refs.divChart,
          scaleConfig,
          {
            background: this.chartBackground,
            chartAxis: this.chartAxisColor,
            timeLabels: this.chartTimeLabelsColor,
            timeLabelsBg: this.chartTimeLabelsBg,
            mauLabels: this.chartMauLabelsColor,
            line: this.chartLineColor,
          },
          {
            canSelectPeak: false,
            hasAxisLabels: false,
            hasTimeScale: true,
            hasMauScale: true,
            hasOnlyMinMaxMau: this.hasOnlyMinMaxMau,
            isRoundYValues: true,
          },
        );

        this.chart.addMeasurement(this.data, this.mps);

        this.chart.resetZoom();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chromatogram-live {
    padding: 0;
    margin: 0;
    height: 260px;

    &__chart {
      height: 100%;
    }
  }
</style>
