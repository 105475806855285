<template>
  <div class="contact">
    <div v-if="isEditMode" class="contact__undo" @click="finishEditing">
      <IconMaterial title="undo" class="contact__icon-undo" />
    </div>
    <div v-else class="contact__wrapper-avatar">
      <Avatar :name="contact.name" class="contact__avatar" />
      <div v-if="!isReadonly" class="contact__edit" @click="startEditing">
        <IconMaterial title="edit" />
      </div>
    </div>

    <ValidationObserver
      v-if="isEditMode"
      #default="{ invalid }"
      tag="form"
      class="contact__form"
      @submit.prevent="save"
    >
      <div class="contact__inputs">
        <InputTransparent
          ref="inputName"
          v-model="inputs.name"
          placeholder="Name"
          class="contact__input"
          rules="required"
        />
        <InputTransparent
          v-model="inputs.email"
          placeholder="Email"
          class="contact__input"
          rules="required|email"
        />
      </div>

      <div class="contact__actions">
        <Btn
          borderRadius="sm"
          type="primary"
          height="s"
          padding="none"
          class="contact__btn-save"
          :disabled="invalid || isLoading"
          :isSubmit="true"
        >
          Save
        </Btn>
        <Btn
          borderRadius="sm"
          type="danger-inverse"
          height="s"
          padding="none"
          class="contact__btn-remove"
          :disabled="invalid || isLoading"
          @click="remove"
        >
          <IconMaterial title="delete_outline" />
        </Btn>
      </div>
    </ValidationObserver>
    <div v-else class="contact__info">
      <div class="contact__name">{{ contact.name }}</div>
      <div class="contact__email" :title="contact.email" :class="{ 'contact__email--me': isMe }">
        {{ contact.email }}
        <template v-if="isMe">(me)</template>
      </div>
    </div>
    <slot v-if="!isEditMode" name="action" :contact="contact" />
  </div>
</template>

<script>
  import Avatar from '@/uikitProject/users/Avatar';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import InputTransparent from '@/uikitProject/inputs/InputTransparent';
  import Btn from '@/uikitBase/btns/Btn';
  import ContactsAPI from 'api/contacts';

  const EVENT_EDIT = 'edit';
  const EVENT_REMOVE = 'remove';

  export default {
    name: 'Contact',

    components: { Btn, InputTransparent, IconMaterial, Avatar },

    props: {
      contact: {
        type: Object,
        required: true,
      },
      isMe: {
        type: Boolean,
        default: false,
      },
      isReadonly: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      isEditMode: false,

      inputs: {
        name: '',
        email: '',
      },

      isLoading: false,
    }),

    methods: {
      async startEditing() {
        this.inputs = {
          name: this.contact.name,
          email: this.contact.email,
        };

        this.isEditMode = true;

        await this.$nextTick();

        this.$refs.inputName.focus();
      },
      finishEditing() {
        this.isEditMode = false;
      },
      startLoading() {
        this.isLoading = true;
      },
      finishLoading() {
        this.isLoading = false;
      },

      save() {
        this.startLoading();

        ContactsAPI.edit(
          this.contact.id,
          {
            name: this.inputs.name,
            email: this.inputs.email,
          },
          (contact) => {
            this.$emit(EVENT_EDIT, contact);
            this.finishLoading();
            this.finishEditing();
          },
          () => {
            this.notifyError('Unable to edit a contact!');
            this.finishLoading();
            this.finishEditing();
          },
        );
      },
      remove() {
        this.startLoading();

        ContactsAPI.remove(
          this.contact.id,
          () => {
            this.$emit(EVENT_REMOVE, this.contact);
            this.finishLoading();
            this.finishEditing();
          },
          () => {
            this.notifyError('Unable to edit a contact!');
            this.finishLoading();
            this.finishEditing();
          },
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .contact {
    min-height: 44px;
    display: flex;
    align-items: center;
    padding-left: 17px;
    padding-right: 14px;
    background-color: #f0f0f0;

    @media (max-width: $screen-xs-max) {
      padding: 0 9px;
      overflow-x: auto;
    }

    &:nth-child(even) {
      background-color: #f6f6f6;
    }

    &__wrapper-avatar {
      position: relative;
      margin-right: 13px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      flex: none;
    }

    &__avatar {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &__edit {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 16px;
      opacity: 0;
      transition: opacity 0.3s;
      cursor: pointer;
      color: white;

      &:hover {
        opacity: 1;
      }
    }

    &__info {
      flex: 1;
      display: flex;
      align-items: center;
      overflow: hidden;

      @media (max-width: $screen-xs-max) {
        flex-direction: column;
        padding: 10px 0;
        margin-right: 10px;
      }
    }

    &__email,
    &__name {
      width: 50%;
      margin-right: 10px;

      &--me {
        color: $color-text-primary;
      }

      @media (max-width: $screen-xs-max) {
        width: auto;
        margin-right: 0;
        text-align: center;
      }
    }

    &__name {
      padding: 5px 0;

      @media (max-width: $screen-xs-max) {
        margin-bottom: 5px;
      }
    }

    &__email {
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @media (max-width: $screen-xs-max) {
        width: 100%;
        text-align: center;
      }
    }

    &__undo {
      flex: none;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: background-color 0.3s;
      margin-right: 13px;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }

      &:active {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    &__icon-undo {
      width: auto;
      height: auto;
    }

    &__form {
      flex: 1;
      display: flex;
      align-items: center;

      @media (max-width: $screen-xs-max) {
        flex: 1 0 auto;
      }
    }

    &__inputs {
      flex: 1;
      display: flex;
      margin-left: -5px;
      margin-right: 5px;

      @media (max-width: $screen-xs-max) {
        flex-direction: column;
        padding: 10px 0;
      }
    }

    &__input {
      flex: 1;

      @media (max-width: $screen-xs-max) {
        margin-bottom: 3px;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    &__actions {
      flex: none;
      width: 76px;
      display: flex;
    }

    &__btn-save {
      flex: 1;
      margin-right: 2px;
    }

    &__btn-remove {
      flex: none;
      width: 24px;
      font-size: 17px;
    }
  }
</style>
