<template>
  <div class="applied-postprocessing">
    <div class="applied-postprocessing__title">
      {{ channelCapitalized }} - {{ CHANNEL_WAVELENGTH_MAP[channel] }}
    </div>

    <div class="applied-postprocessing__details">
      <div v-if="postprocessing.algorithm_pdet" class="applied-postprocessing__section">
        <div class="applied-postprocessing__title">Peak detection</div>
        <DecoratorProperty
          bgColor="#ecefff"
          property="Algorithm"
          class="applied-postprocessing__property"
        >
          {{ postprocessing.algorithm_pdet }}
        </DecoratorProperty>
        <DecoratorProperty
          v-if="postprocessing.params_pdet.peak_detection_min_height != null"
          bgColor="#ecefff"
          :property="`Min height ${
            postprocessing.params_pdet.peak_detection_min_height !== -1 ? ', mAU' : ''
          }`"
          class="applied-postprocessing__property"
        >
          {{
            postprocessing.params_pdet.peak_detection_min_height !== -1
              ? postprocessing.params_pdet.peak_detection_min_height
              : '10 * Noise level'
          }}
        </DecoratorProperty>
        <DecoratorProperty
          v-if="postprocessing.params_pdet.peak_detection_min_prominence != null"
          bgColor="#ecefff"
          property="Min Prominence (relative height), mAU"
          class="applied-postprocessing__property"
        >
          {{ postprocessing.params_pdet.peak_detection_min_prominence }}
        </DecoratorProperty>
        <DecoratorProperty
          v-if="postprocessing.params_pdet.peak_detection_min_width != null"
          bgColor="#ecefff"
          property="Min Width, sec"
          class="applied-postprocessing__property"
        >
          {{ postprocessing.params_pdet.peak_detection_min_width }}
        </DecoratorProperty>
        <DecoratorProperty
          v-if="postprocessing.params_pdet.peak_detection_min_distance != null"
          bgColor="#ecefff"
          property="Min Distance between peaks, min"
          class="applied-postprocessing__property"
        >
          {{ postprocessing.params_pdet.peak_detection_min_distance }}
        </DecoratorProperty>
      </div>

      <div class="applied-postprocessing__section">
        <DecoratorProperty
          v-if="postprocessing.params_dtim.detection_time_start_sec != null"
          bgColor="#ecefff"
          property="Start time, min"
          class="applied-postprocessing__property"
        >
          {{ postprocessing.params_dtim.detection_time_start_sec / 60 }}
        </DecoratorProperty>
        <DecoratorProperty
          v-if="postprocessing.params_dtim.detection_time_end_sec != null"
          bgColor="#ecefff"
          property="End time, min"
          class="applied-postprocessing__property"
        >
          {{ postprocessing.params_dtim.detection_time_end_sec / 60 }}
        </DecoratorProperty>
      </div>

      <div
        v-if="postprocessing.algorithm_peak && postprocessing.algorithm_peak !== 'none'"
        class="applied-postprocessing__section"
      >
        <div class="applied-postprocessing__title">Peak integration</div>
        <DecoratorProperty
          bgColor="#ecefff"
          property="Algorithm"
          class="applied-postprocessing__property"
        >
          {{ postprocessing.algorithm_peak }}
        </DecoratorProperty>
        <DecoratorProperty
          bgColor="#ecefff"
          property="Quality threshold"
          class="applied-postprocessing__property"
        >
          {{ postprocessing.params_peak.peak_integration_quality_threshold }}
        </DecoratorProperty>
      </div>

      <div
        v-if="postprocessing.algorithm_base && postprocessing.algorithm !== 'none'"
        class="applied-postprocessing__section"
      >
        <div class="applied-postprocessing__title">Baseline correction</div>
        <DecoratorProperty
          bgColor="#ecefff"
          property="Algorithm"
          class="applied-postprocessing__property"
        >
          {{ postprocessing.algorithm_base }}
        </DecoratorProperty>
        <DecoratorProperty
          v-if="postprocessing.algorithm_base === 'horizontal_correction'"
          bgColor="#ecefff"
          property="Anchor point"
          class="applied-postprocessing__property"
        >
          {{ postprocessing.params_base.baseline_correction_anchor_point }}
        </DecoratorProperty>
      </div>

      <div
        v-if="postprocessing.algorithm_binj && postprocessing.algorithm_binj !== 'none'"
        class="applied-postprocessing__section"
      >
        <div class="applied-postprocessing__title">Blank injection</div>
        <DecoratorProperty
          bgColor="#ecefff"
          property="Algorithm"
          class="applied-postprocessing__property"
        >
          {{ postprocessing.algorithm_binj }}
        </DecoratorProperty>
        <DecoratorProperty
          v-if="postprocessing.algorithm_binj === 'simple_subtraction'"
          bgColor="#ecefff"
          property="Measurement"
          class="applied-postprocessing__property"
        >
          <LoaderSpinner v-if="isLoadingMeasurement" />
          <RouterLink
            v-else-if="blankInjectionMeasurement"
            :to="linkToInjection"
            class="select-measurement__link-to-measurement"
          >
            <IconStandard v-if="blankInjectionMeasurement.injection.sample.isStandard" />
            <IconSample v-else />
            {{ blankInjectionMeasurement.injection.sample.name }} #{{
              blankInjectionMeasurement.injection.name
            }}
            : {{ blankInjectionMeasurement.name }}
          </RouterLink>
        </DecoratorProperty>
      </div>

      <div
        v-if="postprocessing.algorithm_nois && postprocessing.algorithm_nois !== 'none'"
        class="applied-postprocessing__section"
      >
        <div class="applied-postprocessing__title">Noise reduction</div>
        <DecoratorProperty
          bgColor="#ecefff"
          property="Algorithm"
          class="applied-postprocessing__property"
        >
          {{ postprocessing.algorithm_nois }}
        </DecoratorProperty>
      </div>
    </div>
  </div>
</template>

<script>
  import DecoratorProperty from '@/uikitProject/decorators/DecoratorProperty.vue';
  import { POSTPROCESSING_TEMPLATE_MAP } from '@/utils/postprocessingTemplateHelpers';
  import { apiMeasurements } from '@/api/graphql/cloud/measurements';
  import IconStandard from '@/uikitProject/icons/IconStandard.vue';
  import IconSample from '@/uikitProject/icons/IconSample.vue';
  import LoaderSpinner from '@/uikitProject/loaders/LoaderSpinner.vue';
  import { capitalize } from '@/utils/stringHelpers';
  import { CHANNEL_WAVELENGTH_MAP } from '@/utils/measurementHelpers';

  export default {
    name: 'PostprocessingToApply',

    components: {
      LoaderSpinner,
      IconSample,
      IconStandard,
      DecoratorProperty,
    },

    props: {
      postprocessing: {
        type: Object,
        required: true,
      },
      channel: {
        type: String,
        required: true,
      },
    },

    data: () => ({
      POSTPROCESSING_TEMPLATE_MAP,

      blankInjectionMeasurement: null,

      isLoadingMeasurement: true,

      CHANNEL_WAVELENGTH_MAP,
    }),

    computed: {
      linkToInjection() {
        return `/${
          this.blankInjectionMeasurement.injection.sample.isStandard ? 'standards' : 'samples'
        }/${this.blankInjectionMeasurement.injection.sample.id}/injection/${
          this.blankInjectionMeasurement.injection.id
        }`;
      },

      channelCapitalized() {
        return capitalize(this.channel);
      },
    },

    created() {
      this.initBlankInjectionMeasurement();
    },

    methods: {
      async initBlankInjectionMeasurement() {
        if (
          !this.blankInjectionMeasurement &&
          this.postprocessing?.params_binj.blank_injection_measurement_id
        ) {
          try {
            this.isLoadingMeasurement = true;
            this.blankInjectionMeasurement = await apiMeasurements.getMeasurementShort(
              this.postprocessing.params_binj.blank_injection_measurement_id,
            );
          } finally {
            this.isLoadingMeasurement = false;
          }
        }
        this.isLoadingMeasurement = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .applied-postprocessing {
    &__title {
      width: 100%;
      display: flex;
      align-items: center;
      font-weight: $weight-bold;
    }

    &__section {
      margin-bottom: 5px;
    }

    &__details {
      margin-top: 5px;
    }

    &__title {
      font-weight: $weight-bold;
    }
  }
</style>
