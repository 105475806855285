<template>
  <div class="chart-chromatogram">
    <template v-if="doPerformanceOptimisations">
      <GlobalEvents @visibilitychange="onPageVisibilityChange" />
      <GlobalEvents target="window" @storage="onLocalStorageChange" />
    </template>

    <template v-if="isShowChart">
      <KeepAlive>
        <NothingThereComponent v-if="!hasDataForPressure" style="margin-top: 32px">
          No recorded data for pressure
        </NothingThereComponent>

        <PrChartPressure
          v-else-if="measurementPressure"
          ref="chartPressure"
          :measurement="measurementPressure"
          :timeMin="(detectionTime && detectionTime.start) || 0"
          :timeMax="timeMax"
          class="chart-chromatogram__pressure"
        />
      </KeepAlive>

      <NothingThereComponent v-if="!hasDataForAllMeasurements" style="margin-top: 32px">
        <template v-if="measurements.length > 1">
          No recorded data for one of the selected measurements
        </template>
        <template v-else>No recorded data</template>
      </NothingThereComponent>

      <template v-else>
        <PrChartChromatogramSingle
          v-if="measurements.length === 1 && runningChromatogram !== 'multiple'"
          ref="chartSingle"
          :measurement="measurements[0]"
          v-bind="$attrs"
          :sampleToken="sampleToken"
          :doPerformanceOptimisations="doPerformanceOptimisations"
          :applyBaseline="applyBaseline"
          class="chart-chromatogram__chart"
          :toolbar="hasToolbar"
          :hasPadding="hasPadding"
          :hasLiveUpdate="hasLiveUpdate"
          :heightPx="heightPx"
          :timeMax="timeMax"
          :hasOnlyHorizontalZoom="hasOnlyHorizontalZoom"
          :hasSpacesIfOutOfDetectionTime="hasSpacesIfOutOfDetectionTime"
          :doShowExactBounds="doShowExactBounds"
          :detectionTime="detectionTime"
          :isShowFullChart="isShowFullChart"
          :creationDate="creationDate"
          @update:applyBaseline="$emit('update:applyBaseline', $event)"
          @zoom="syncZoom"
          @update="$emit('update')"
          @selectPeak="$emit('selectPeak', $event)"
          @updateMeasurement="$emit('updateMeasurement', $event)"
          @mounted="resetZoomPressure"
          @created="setRunningChromatogram('single')"
          @destroy="setRunningChromatogram(null)"
          @showFullChart="showFullChart"
          @showDetectionTime="showDetectionTime"
        >
          <template #peaks="{ peaks, measurementSocket, setPeakHighlighting, highlightedPeakId }">
            <slot
              name="peaks"
              v-bind="{
                peaks,
                measurementSocket,
                setPeakHighlighting,
                highlightedPeakId,
              }"
            />
          </template>
        </PrChartChromatogramSingle>
        <PrDataAdapter
          v-if="
            measurements.length && measurements.length !== 1 && runningChromatogram !== 'single'
          "
          :measurements="measurements"
          :hasLiveUpdate="hasLiveUpdate"
          :sampleToken="sampleToken"
          :doSkipDataFetching="doSkipDataFetching"
          @updateMeasurement="$emit('updateMeasurement', $event)"
          #default="{ data }"
          @create="setRunningChromatogram('multiple')"
          @destroy="setRunningChromatogram(null)"
        >
          <PrChartChromatogramMultiple
            ref="chartMultiple"
            :measurements="data"
            v-bind="$attrs"
            :doPerformanceOptimisations="doPerformanceOptimisations"
            :isApplyBaselines="applyBaseline"
            :hasPadding="hasPadding"
            :heightPx="heightPx"
            :timeMax="timeMax"
            :measurementColors="measurementColors"
            :hasOnlyHorizontalZoom="hasOnlyHorizontalZoom"
            :doShowExactBounds="doShowExactBounds"
            :hasMauScale="hasMauScale"
            :detectionTime="detectionTime"
            :hasSpacesIfOutOfDetectionTime="hasSpacesIfOutOfDetectionTime"
            :isShowFullChart="isShowFullChart"
            :creationDate="creationDate"
            class="chart-chromatogram__chart"
            @updateMeasurement="$emit('updateMeasurement', $event)"
            @zoom="syncZoom"
            @created="resetZoomPressure"
          />
        </PrDataAdapter>
      </template>
    </template>
  </div>
</template>

<script>
  import PrChartChromatogramMultiple from './private/PrChartChromatogramMultiple';
  import PrChartChromatogramSingle from './private/PrChartChromatogramSingle';
  import PrChartPressure from './private/PrChartPressure';
  import PrDataAdapter from './private/PrDataAdapter';
  import { generateId } from 'utils/generateId.ts';
  import { persistentStorage, PersistentStorageKeys } from 'utils/persistentStorage';
  import NothingThereComponent from '@/components/element/NothingThereComponent.vue';

  const URL_MEASUREMENT_NOT_FOUND = 'https://cdn.hplc.cloud/measurement/measurement_not_found.bin';

  export default {
    name: 'ChartChromatogram',

    components: {
      NothingThereComponent,
      PrChartPressure,
      PrDataAdapter,
      PrChartChromatogramSingle,
      PrChartChromatogramMultiple,
    },

    props: {
      measurements: {
        type: Array,
        required: true,
      },
      hasOnlyHorizontalZoom: {
        type: Boolean,
      },
      doPerformanceOptimisations: {
        type: Boolean,
        default: false,
      },
      measurementPressure: {
        type: Object,
        default: null,
      },
      applyBaseline: {
        type: Boolean,
        required: true,
      },
      hasToolbar: {
        type: Boolean,
        default: true,
      },
      hasPadding: {
        type: Boolean,
        default: true,
      },
      hasMauScale: {
        type: Boolean,
        default: true,
      },
      hasLiveUpdate: {
        type: Boolean,
        default: true,
      },
      heightPx: {
        type: Number,
        default: 300,
      },
      timeMax: {
        type: Number,
      },
      detectionTime: {
        type: Object,
      },
      sampleToken: {
        type: String,
        default: null,
      },
      doShowExactBounds: {
        type: Boolean,
      },
      doSkipDataFetching: {
        type: Boolean,
      },

      /**
       * I wanted to add a color prop to each measurement from outside the current component, but I couldn't.
       * DataAdapter gets events from the measurementSocket and overrides an initial prop.
       * All solutions are not good(
       */
      measurementColors: {
        type: Object,
      },

      hasSpacesIfOutOfDetectionTime: {
        type: Boolean,
      },

      creationDate: {
        type: Date,
      },
    },

    data: () => ({
      id: generateId(),
      isShowChart: true,

      sharedZoom: null,

      // Single or multiple
      runningChromatogram: null,

      isShowFullChart: false,
    }),

    computed: {
      hasDataForAllMeasurements() {
        return this.measurements.every(
          (measurement) => measurement.data_url !== URL_MEASUREMENT_NOT_FOUND,
        );
      },
      hasDataForPressure() {
        return this.measurementPressure?.data_url !== URL_MEASUREMENT_NOT_FOUND;
      },
    },

    watch: {
      doPerformanceOptimisations(value) {
        if (!value) {
          this.isShowChart = true;
        }
      },
    },

    created() {
      persistentStorage.set(PersistentStorageKeys.ACTIVE_CHROMATOGRAM_ID, this.id);
    },

    methods: {
      zoom({ pStart, pFinish }) {
        this.$refs.chartSingle.zoom({ pStart, pFinish });
      },
      syncZoom(zoomData = this.sharedZoom) {
        if (zoomData) {
          const { pStart, pFinish } = zoomData;
          this.$refs.chartPressure?.zoom({ pStart, pFinish });

          this.sharedZoom = { pStart, pFinish };
          this.$emit('zoom', zoomData);
        }
      },
      resetZoomPressure() {
        this.$refs.chartPressure?.resetZoom();
      },
      resetZoom() {
        this.$refs.chartPressure?.resetZoom();
        this.$refs.chartSingle?.resetZoom();
        this.$refs.chartMultiple?.resetZoom();
      },

      onPageVisibilityChange() {
        if (document.visibilityState === 'visible') {
          persistentStorage.set(PersistentStorageKeys.ACTIVE_CHROMATOGRAM_ID, this.id);
          this.isShowChart = true;
        }
      },
      onLocalStorageChange(e) {
        if (e.key === PersistentStorageKeys.ACTIVE_CHROMATOGRAM_ID) {
          this.isShowChart = e.newValue === this.id;
        }
      },

      setRunningChromatogram(value) {
        this.runningChromatogram = value;
      },

      showFullChart() {
        this.isShowFullChart = true;
      },
      showDetectionTime() {
        this.isShowFullChart = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chart-chromatogram {
    &__edit-panel {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }

    &__pressure {
      margin-bottom: 20px;
    }

    &__chart {
      margin-top: -20px;
    }
  }
</style>
