<template>
  <img src="@/assets/img/spinner.gif" alt="loading..." class="loader-spinner" :class="classSize" />
</template>

<script>
  // TODO story
  export default {
    name: 'LoaderSpinner',

    props: {
      size: {
        type: String,
        default: 's',
        validator(value) {
          const availableValues = ['s', 'm', 'l'];
          return availableValues.includes(value);
        },
      },
    },

    computed: {
      classSize() {
        return `loader-spinner--size--${this.size}`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .loader-spinner {
    display: block;

    &--size {
      &--s {
        width: 15px;
        height: 15px;
      }

      &--m {
        width: 20px;
        height: 20px;
      }

      &--l {
        width: 25px;
        height: 25px;
      }
    }
  }
</style>
