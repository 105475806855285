<template>
  <div class="tabs">
    <div
      v-for="tab of tabs"
      :key="tab.code"
      class="tabs__tab"
      :class="classTabSelected(tab)"
      @click="setTab(tab)"
    >
      {{ tab.title }}
    </div>
  </div>
</template>

<script>
  const EVENT_UPDATE_TAB_SELECTED = 'update:tabSelected';

  export default {
    name: 'Tabs',

    model: {
      event: EVENT_UPDATE_TAB_SELECTED,
      prop: 'tabSelected',
    },

    props: {
      tabs: {
        type: Array,
        default: () => [],
      },
      // Sync
      tabSelected: {
        type: Object,
        required: true,
      },
    },

    computed: {
      classTabSelected() {
        return (tab) => tab.code === this.tabSelected.code && 'tabs__tab--selected';
      },
    },

    methods: {
      setTab(tab) {
        this.$emit(EVENT_UPDATE_TAB_SELECTED, tab);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 32px;
    background: #f0f0f0;
    color: $color-text-primary;
    font-weight: $weight-bold;
    font-size: 13px;
    padding: 0 9px;
    border-radius: $border-radius__sm;

    &__tab {
      display: flex;
      align-items: stretch;
      height: 100%;
      padding: 0 14px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      line-height: 32px;
      transition: color 0.3s, border-bottom-color 0.3s;

      &--selected {
        color: $color-text-primary;
        border-bottom-color: $color-text-primary;
      }
    }
  }
</style>
