<template>
  <div
    v-if="configuration.hasOwnProperty('pump') && configuration.pump != null"
    class="hardware-tile"
  >
    <div class="hardware-tile__wrapper-title">
      <div>
        <i class="hardware-tile__title-icon" style="font-weight: bold">arrow_forward</i>
        <h4>
          <!--      <template v-if="configuration.pump.type === 'SYRINGE'">Syringe</template>-->
          <template v-if="configuration.pump.type === 'TWO SYRINGE GRADIENT'"
            >Gradient Pump</template
          >
          <template v-else>Pump</template>
        </h4>
      </div>
      <Information :data="popupInfoData" class="hardware-tile__information" />
    </div>

    <hardware-state-component :state="currentState" />

    <template v-if="configuration.pump.type === 'TWO SYRINGE GRADIENT'">
      <div class="text-red">
        <template v-if="errors.a != null">
          A: <HardwareErrorComponent :errors="errors.a" :error-list="errorList" />
        </template>
        <template v-if="errors.b != null">
          B: <HardwareErrorComponent :errors="errors.b" :error-list="errorList" />
        </template>
      </div>
    </template>
    <template v-else>
      <HardwareErrorComponent :errors="errors" :error-list="errorList" />
    </template>

    <template v-if="configuration.pump.type === 'SYRINGE' && state.pump_details">
      <div class="mt-4">
        <i
          :class="{ blink: stateDetails.draw || stateDetails.refill }"
          class="hardware-tile__direction"
        >
          <template v-if="stateDetails.draw">
            <div class="hardware-tile__running"></div>
          </template>
          <template v-else-if="stateDetails.refill">
            <div class="hardware-tile__refilling"></div>
          </template>
          <template v-else>stop</template>
        </i>
        <template v-if="stateDetails.amount">
          <span class="text-xl" v-html="stateDetails.amount.toFixed(2)" /> mL
        </template>
        <span v-if="stateDetails.draw" class="text-gray" style="float: right; margin-top: 7px">
          Pumping
        </span>
        <span
          v-else-if="stateDetails.refill"
          class="text-gray"
          style="float: right; margin-top: 7px"
        >
          Refilling
        </span>
      </div>

      <div class="progress-bar__bg mb-4">
        <div
          :class="progressBarColor(stateDetails)"
          :style="{ width: Math.min(1, stateDetails.position) * 100 + '%' }"
          class="progress-bar__bar"
        />
      </div>
      <Btn v-if="canPurge" height="s" @click="purge(configuration.pump.id)">
        <template #iconLeft>
          <IconMaterial title="play_arrow" class="hardware-tile__icon-play" />
        </template>
        Purge
      </Btn>
    </template>

    <template v-if="configuration.pump.type === 'TWO SYRINGE GRADIENT' && state.pump_details">
      <template v-if="stateDetails.pumps && stateDetails.pumps.a">
        <div class="mt-4">
          <button
            class="text-xl link-button--normal-transparent"
            @click="blink(configuration.pump.pumps.a.id)"
          >
            A
          </button>
          <i
            :class="{ blink: stateDetails.pumps.a.draw || stateDetails.pumps.a.refill }"
            class="hardware-tile__direction"
          >
            <template v-if="stateDetails.pumps.a.draw">
              <div class="hardware-tile__running"></div>
            </template>
            <template v-else-if="stateDetails.pumps.a.refill">
              <div class="hardware-tile__refilling"></div>
            </template>
            <template v-else>stop</template>
          </i>
          <template v-if="stateDetails.pumps.a && stateDetails.pumps.a.amount">
            <span class="text-xl" v-html="stateDetails.pumps.a.amount.toFixed(2)" /> mL
          </template>
          <span
            v-if="stateDetails.pumps.a.draw"
            class="text-gray"
            style="float: right; margin-top: 7px"
          >
            Running
          </span>
          <span
            v-else-if="stateDetails.pumps.a.refill"
            class="text-gray"
            style="float: right; margin-top: 7px"
          >
            Refilling
          </span>
        </div>

        <div class="progress-bar__bg mb-4">
          <div
            :class="progressBarColor(stateDetails.pumps.a)"
            :style="{ width: Math.min(1, stateDetails.pumps.a.position) * 100 + '%' }"
            class="progress-bar__bar"
          />
        </div>

        <Btn v-if="canPurgeA" height="s" @click="purge(configuration.pump.pumps.a.id)">
          <template #iconLeft>
            <IconMaterial title="play_arrow" class="hardware-tile__icon-play" />
          </template>
          Purge A
        </Btn>
        <Btn v-else-if="canStopA" height="s" @click="stop(configuration.pump.pumps.a.id)">
          <template #iconLeft>
            <IconMaterial title="stop" class="hardware-tile__icon-play" />
          </template>
          Stop A
        </Btn>
      </template>
      <template v-if="stateDetails.pumps && stateDetails.pumps.b">
        <div class="mt-4">
          <button
            class="text-xl link-button--normal-transparent"
            @click="blink(configuration.pump.pumps.b.id)"
          >
            B
          </button>
          <i
            :class="{ blink: stateDetails.pumps.b.draw || stateDetails.pumps.b.refill }"
            class="hardware-tile__direction"
          >
            <template v-if="stateDetails.pumps.b.draw">
              <div class="hardware-tile__running"></div>
            </template>
            <template v-else-if="stateDetails.pumps.b.refill">
              <div class="hardware-tile__refilling"></div>
            </template>
            <template v-else>stop</template>
          </i>
          <template v-if="stateDetails.pumps.b && stateDetails.pumps.b.amount">
            <span class="text-xl" v-html="stateDetails.pumps.b.amount.toFixed(2)" /> mL
          </template>
          <span
            v-if="stateDetails.pumps.b.draw"
            class="text-gray"
            style="float: right; margin-top: 7px"
          >
            Running
          </span>
          <span
            v-else-if="stateDetails.pumps.b.refill"
            class="text-gray"
            style="float: right; margin-top: 7px"
          >
            Refilling
          </span>
        </div>

        <div class="progress-bar__bg mb-4">
          <div
            :class="progressBarColor(this.stateDetails.pumps.b)"
            :style="{ width: Math.min(1, stateDetails.pumps.b.position) * 100 + '%' }"
            class="progress-bar__bar"
          />
        </div>

        <Btn v-if="canPurgeB" height="s" @click="purge(configuration.pump.pumps.b.id)">
          <template #iconLeft>
            <IconMaterial title="play_arrow" class="hardware-tile__icon-play" />
          </template>
          Purge B
        </Btn>
        <Btn v-else-if="canStopB" height="s" @click="stop(configuration.pump.pumps.b.id)">
          <template #iconLeft>
            <IconMaterial title="stop" class="hardware-tile__icon-play" />
          </template>
          Stop B
        </Btn>
      </template>
    </template>

    <div v-if="stateDetails" class="mt-4" style="flex-wrap: wrap">
      <div
        class="hardware-tile__pressure"
        style="display: flex; align-items: baseline; flex-grow: 1"
      >
        <div style="white-space: nowrap">
          <!-- JUST FOR TESTING ON STAGE-->
          <span class="text-xl">{{ stateDetails.pressure }}</span>

          psi
        </div>
        <div class="font-black text-gray">Pressure</div>
      </div>
    </div>

    <div v-if="stateDetails && stateDetails.flow" class="hardware-tile__flow-rate">
      <div style="white-space: nowrap">
        <span class="font-black inline-block text-2xl mt-2">{{
          stateDetails.flow.toFixed(2)
        }}</span>
        mL/min
      </div>
      <div class="font-black text-gray">Flow rate</div>
    </div>

    <div v-if="canStart || canStop" class="mt-4 mb-2">
      <PopupHelper v-if="canStart" text="Run time will be ignored">
        <Btn height="s" class="mr-2" @click="start">
          <template #iconLeft>
            <IconMaterial title="play_arrow" class="hardware-tile__icon-play" />
          </template>
          Start
        </Btn>
      </PopupHelper>
      <Btn v-else-if="canStop" height="s" @click="stop()">
        <template #iconLeft>
          <IconMaterial title="stop" class="hardware-tile__icon-play" />
        </template>
        Stop
      </Btn>
    </div>
  </div>
</template>

<script>
  import Base from './Base.vue';
  import Btn from '@/uikitBase/btns/Btn';
  import HardwareErrorComponent from 'components/element/HardwareErrorComponent';
  import { GENERAL_ERRORS, PUMP_ERRORS } from '@/constants/hardware/errors';
  import Information from 'components/block/hardware/private/Information';
  import PopupHelper from '@/uikitProject/popups/info/PopupHelper';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import { pumpCommands } from '@/constants/hardware/commands';

  export default {
    components: {
      IconMaterial,
      PopupHelper,
      Information,
      HardwareErrorComponent,
      Btn,
    },

    extends: Base,

    computed: {
      currentState() {
        return this.state?.pump;
      },
      stateDetails() {
        return this.state?.pump_details;
      },
      errors() {
        if (this.configuration.pump.type === 'TWO SYRINGE GRADIENT') {
          // TODO Think how to show pumps.a and pumps.b separately
          return {
            a: this.stateDetails?.pumps?.a?.errors,
            b: this.stateDetails?.pumps?.b?.errors,
          };
        }
        return this.stateDetails?.errors;
      },
      errorList() {
        return { ...GENERAL_ERRORS, ...PUMP_ERRORS };
      },

      popupInfoData() {
        const {
          firmware_version,
          id,
          pressure_sensor_version,
          pressure_sensor,
          serial,
          type,
          amount,
          pumps: { a: pumpA, b: pumpB } = {},
        } = this.configuration.pump;

        const data = [];

        if (!pumpA && !pumpB) {
          data.push(
            {
              name: 'Id',
              value: id,
            },
            {
              name: 'Serial',
              value: serial,
            },
            {
              name: 'Type',
              value: type,
            },
            {
              name: 'Firmware Version',
              value: firmware_version
                ? `${firmware_version.major}.${firmware_version.minor}`
                : 'Not available',
            },
            {
              name: 'Total amount',
              value: amount,
            },
            {
              name: 'Current amount',
              value: this.state?.pump_details?.amount * 1000,
            },
            {
              name: 'Pressure sensor',
              value: pressure_sensor_version || pressure_sensor,
              isBoolean: !pressure_sensor_version,
            },
            {
              name: 'Temperature',
              value: this.state?.pump_details?.temperature,
            },
            {
              name: 'Flow rate, mL/min',
              value: this.state?.pump_details?.flow,
            },
          );
        }

        if (pumpA) {
          data.push(
            {
              type: 'title',
              value: 'Pump A',
            },
            {
              name: 'Id',
              value: pumpA.id,
            },
            {
              name: 'Serial',
              value: pumpA.serial,
            },
            {
              name: 'Type',
              value: pumpA.type,
            },
            {
              name: 'Firmware Version',
              value: pumpA.firmware_version
                ? `${pumpA.firmware_version.major}.${pumpA.firmware_version.minor}`
                : 'Not available',
            },
            {
              name: 'Total amount',
              value: pumpA.amount,
            },
            {
              name: 'Current amount',
              value: this.state?.pump_details?.pumps?.a?.amount * 1000,
            },
            {
              name: 'Pressure sensor',
              value: pumpA.pressure_sensor_version || pumpA.pressure_sensor,
              isBoolean: !pumpA.pressure_sensor_version,
            },
            {
              name: 'Pressure, psi',
              value: this.state?.pump_details?.pumps?.a?.pressure,
            },
            {
              name: 'Temperature',
              value: this.state?.pump_details?.pumps?.a?.temperature,
            },
            {
              name: 'Flow rate, mL/min',
              value: this.state?.pump_details?.pumps?.a?.flow,
            },
          );
        }

        if (pumpB) {
          data.push(
            {
              type: 'title',
              value: 'Pump B',
            },
            {
              name: 'Id',
              value: pumpB.id,
            },
            {
              name: 'Serial',
              value: pumpB.serial,
            },
            {
              name: 'Type',
              value: pumpB.type,
            },
            {
              name: 'Firmware Version',
              value: pumpB.firmware_version
                ? `${pumpB.firmware_version.major}.${pumpB.firmware_version.minor}`
                : 'Not available',
            },
            {
              name: 'Total amount',
              value: pumpB.amount,
            },
            {
              name: 'Current amount',
              value: this.state?.pump_details?.pumps?.b?.amount * 1000,
            },
            {
              name: 'Pressure sensor',
              value: pumpB.pressure_sensor_version || pumpB.pressure_sensor,
              isBoolean: !pumpB.pressure_sensor_version,
            },
            {
              name: 'Pressure, psi',
              value: this.state?.pump_details?.pumps?.b?.pressure,
            },
            {
              name: 'Temperature',
              value: this.state?.pump_details?.pumps?.b?.temperature,
            },
            {
              name: 'Flow rate, mL/min',
              value: this.state?.pump_details?.pumps?.b?.flow,
            },
          );
        }

        return data;
      },

      commands() {
        return this.stateDetails?.commandDetails ?? {};
      },

      canStart() {
        return this.commands[pumpCommands.START]?.status.available;
      },
      canStop() {
        return this.configuration.pump.type === 'TWO SYRINGE GRADIENT'
          ? this.canStopA && this.canStopB
          : this.commands[pumpCommands.STOP]?.status.available;
      },
      canPurge() {
        return this.commands[pumpCommands.PURGE]?.status.available;
      },

      canStopA() {
        return this.commands[pumpCommands.STOP_A]?.status.available;
      },
      canPurgeA() {
        return this.commands[pumpCommands.PURGE_A]?.status.available;
      },

      canStopB() {
        return this.commands[pumpCommands.STOP_B]?.status.available;
      },
      canPurgeB() {
        return this.commands[pumpCommands.PURGE_B]?.status.available;
      },
    },

    methods: {
      progressBarColor(pump) {
        if (pump.draw) {
          return 'bg-process';
        } else if (pump.refill) {
          return 'bg-preparing';
        } else if (!pump.refill && !pump.draw && pump.errors == null) {
          return 'bg-ready';
        }
        return 'bg-preparing';
      },
      start() {
        this.ws.command('pump', pumpCommands.START);
      },
      stop(pumpId) {
        this.ws.command(pumpId ? `pump:${pumpId}` : 'pump', pumpCommands.STOP);
      },
      blink(pumpId) {
        this.ws.command(`pump:${pumpId}`, pumpCommands.BLINK);
      },
      purge(pumpId) {
        this.ws.command(`pump:${pumpId}`, pumpCommands.PURGE);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .hardware-tile {
    &__running,
    &__refilling {
      display: inline-block;
      vertical-align: bottom;
      text-decoration: none;
      width: 20px;
      height: 20px;
      background-size: 15px;
      background-position: center;
      background-repeat: no-repeat;
    }

    &__running {
      background-image: url('~assets/img/running.svg');
    }

    &__refilling {
      background-image: url('~assets/img/getting-ready.svg');
    }

    &__pressure,
    &__flow-rate {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      max-width: 218px;
    }

    &__icon-play {
      font-size: 18px;
    }
  }
</style>
