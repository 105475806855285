export enum CellTypes {
  TIME = 'time',
  PERCENT = 'percent',
}

export type CellFraction = {
  value: number;
  type: CellTypes.PERCENT;
};

export type CellTime = {
  value: number;
  type: CellTypes.TIME;
  isUnique?: boolean;
};

export type Cell = CellFraction | CellTime;

export type Row = {
  id: number | string;
  cells: Cell[];
  isValidPercent: boolean;
};

export type Fraction = number;

export type ServerFormatItem = {
  id: number | string;
  time: number;
  fractions: Fraction[];
};

export type FractionsCount = 2 | 3 | 4;

export type ValidationStatus = {
  isValid: boolean;
  error?: string;
};

export enum ValidationErrorMessage {
  MAX_ALLOWED_TIME = 'Time exceed maximum allowed value',
  INVALID_TIME_STEP = 'Time values have an invalid step',
  DUPLICATE_TIME = 'Cells with time have the same values',
  PERCENTS_NOT_100 = 'Sum of percents in one row must be 100%',
}
