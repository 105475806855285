<template>
  <DecoratorInput
    iconSize="sm"
    iconTitle="query_stats"
    label="Postprocessings"
    class="setup-postprocessing"
  >
    <div v-if="preselectedPostprocessings.length" class="setup-postprocessing__postprocessings">
      <div
        v-for="[channel] of preselectedPostprocessings"
        :key="channel"
        class="setup-postprocessing__postprocessing"
      >
        <div class="setup-postprocessing__postprocessing-info">
          {{ CHANNEL_NAMES[channel] }}

          <Btn padding="sm" height="xs" @click="setupPostprocessing(channel)"> Edit </Btn>
          <BtnIcon iconMaterial="close" @click="removePostprocessing(channel)" />
        </div>
        <!--        <div-->
        <!--          v-if="channel === 'primary' && preselectedPostprocessings.length > 1"-->
        <!--          class="setup-postprocessing__warning"-->
        <!--        >-->
        <!--          Postprocessing for the primary channel is prioritised over others in case of conflict-->
        <!--        </div>-->
      </div>
    </div>

    <div class="setup-postprocessing__channels">
      <template v-if="unsettedChannels.length">
        <Btn
          v-for="channel of unsettedChannels"
          :key="channel"
          padding="sm"
          iconRight="add"
          @click="setupPostprocessing(channel)"
        >
          {{ CHANNEL_NAMES[channel] }}
        </Btn>
      </template>
      <slot v-else-if="availableChannels.length === 0" name="noAvailableChannelsError">
        No channels to select
      </slot>
    </div>

    <div
      v-if="isRequired && availableChannels.length > 0 && preselectedPostprocessings.length === 0"
      class="setup-postprocessing__error"
    >
      You must configure a postprocessing
    </div>

    <Portal to="modal">
      <PostprocessingTemplateEditModal
        :postprocessingTemplate="postprocessingForEditing"
        :selectedChannel="channelForEditing"
        :isPreselectMode="false"
        :isCalibrationSequenceMode="true"
        @update:sequencePostprocessing="save"
      />
    </Portal>
  </DecoratorInput>
</template>

<script>
  import DecoratorInput from '@/uikitProject/decorators/DecoratorInput.vue';
  import Btn from '@/uikitBase/btns/Btn.vue';
  import PostprocessingTemplateEditModal from '@/components/block/modal/PostprocessingTemplateEditModal.vue';
  import BtnIcon from '@/uikitBase/btns/BtnIcon.vue';

  const CHANNELS = ['red', 'green', 'blue', 'uv'];

  const CHANNEL_NAMES = {
    red: '630nm (Red)',
    green: '520nm (Green)',
    blue: '460nm (Blue)',
    uv: '275nm (UV)',
  };

  const EVENT_MODEL = 'model';

  export default {
    name: 'SetupPostprocessings',

    components: {
      BtnIcon,
      PostprocessingTemplateEditModal,
      Btn,
      DecoratorInput,
    },

    model: {
      prop: 'postprocessings',
      event: EVENT_MODEL,
    },

    props: {
      /**
       * Format: {
       *         red: null,
       *         green: null,
       *         blue: null,
       *         uv: null,
       *       }
       */
      postprocessings: {
        type: Object,
        required: true,
      },
      canSelectOnlyOne: {
        type: Object,
      },
      availableChannels: {
        type: Array,
        default: () => [...CHANNELS],
      },

      isRequired: {
        type: Boolean,
      },
    },

    data: () => ({
      CHANNELS,
      CHANNEL_NAMES,

      channelForEditing: '',
      postprocessingForEditing: undefined,
    }),

    computed: {
      preselectedPostprocessings() {
        return Object.entries(this.postprocessings).filter(
          ([, postprocessing]) => postprocessing != null,
        );
      },
      unsettedChannels() {
        if (this.canSelectOnlyOne) {
          return this.availableChannels.some((channel) => this.postprocessings[channel] != null)
            ? []
            : this.availableChannels;
        }

        return this.availableChannels.filter((channel) => this.postprocessings[channel] == null);
      },
    },

    methods: {
      setupPostprocessing(channel) {
        this.channelForEditing = channel;
        this.postprocessingForEditing = this.postprocessings[channel] ?? undefined;
        this.$modal.show('postprocessing-template-edit');
      },

      removePostprocessing(channel) {
        this.$emit(EVENT_MODEL, {
          ...this.postprocessings,
          [channel]: null,
        });
      },
      save(postprocessing) {
        this.$emit(EVENT_MODEL, {
          ...this.postprocessings,
          [this.channelForEditing]: postprocessing,
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .setup-postprocessing {
    &__postprocessings {
      margin-top: 5px;
    }

    &__postprocessing {
      margin-bottom: 10px;
    }

    &__postprocessing-info {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &__warning {
      margin-top: 2px;
      margin-bottom: 5px;
      color: $color-text-warning;
    }

    &__channels {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
      min-height: 32px;
      align-items: center;
    }

    &__error {
      margin-top: 2px;
      color: $color-text-danger;
    }
  }
</style>
