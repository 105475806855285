<template>
  <Btn v-if="hasNewVersion" height="xs" class="firmware-status-mini" @click="update">
    <span
      class="firmware-status-mini__has-update"
      :class="{ 'firmware-status-mini__has-update--deprecated': data.deprecated }"
    >
      <LoadingComponent v-if="isLoading" :label="null" class="firmware-status-mini__loader" />
      fv{{ formatVersion(this.versionCurrent) }}
    </span>
    <IconMaterial title="info" class="firmware-status-mini__icon" />
  </Btn>
  <span
    v-else
    class="firmware-status-mini"
    :class="{ 'firmware-status-mini--clickable': isSuperuser }"
    @click="update"
    >fv{{ formatVersion(this.versionCurrent) }}</span
  >
</template>

<script>
  import VersionHelper from 'utils/VersionHelper.ts';
  import LoadingComponent from 'components/element/LoadingComponent';
  import IconMaterial from '@/uikitBase/icons/IconMaterial';
  import Btn from '@/uikitBase/btns/Btn';
  import { persistentStorage, PersistentStorageKeys } from 'utils/persistentStorage';
  import { differenceInCalendarDays } from 'date-fns';
  import { AuthService } from '@/services/auth.service';

  const DAYS_IN_TWO_WEEKS = 14;

  const EVENT_UPDATE = 'update';

  const UPDATE_PENDING = 'UPDATE_PENDING';
  const UPDATING = 'UPDATING';

  export default {
    name: 'FirmwareStatusMini',

    components: { Btn, IconMaterial, LoadingComponent },

    props: {
      deviceId: {
        type: String,
        required: true,
      },
      data: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      isSuperuser: AuthService.userData()?.is_superuser,
    }),

    computed: {
      hasNewVersion() {
        return (
          this.state != null || VersionHelper.greaterThan(this.versionLatest, this.versionCurrent)
        );
      },

      state() {
        return this.data?.state;
      },
      versionCurrent() {
        return this.data?.current_version;
      },
      versionLatest() {
        return this.data?.latest_version;
      },
      versionTarget() {
        return this.data?.target_version;
      },

      isLoading() {
        return this.state === UPDATE_PENDING || this.state === UPDATING;
      },
    },

    watch: {
      hasNewVersion(value) {
        if (value) {
          this.$emit(EVENT_UPDATE);
          persistentStorage.remove(PersistentStorageKeys.DEVICE_VERSION_UPDATE_LATER, {
            params: [this.deviceId],
          });
        }
      },
    },

    created() {
      this.init();
    },

    methods: {
      formatVersion(version) {
        return version.join('.');
      },
      update() {
        if (this.hasNewVersion || this.isSuperuser) {
          this.$emit(EVENT_UPDATE);
        }
      },

      init() {
        if (this.hasNewVersion) {
          const skippedUpdate = persistentStorage.get(
            PersistentStorageKeys.DEVICE_VERSION_UPDATE_LATER,
            { params: [this.deviceId] },
          );

          if (skippedUpdate) {
            if (skippedUpdate.version.toString() === this.versionLatest.toString()) {
              const daysFromLastSkip = differenceInCalendarDays(
                new Date(),
                skippedUpdate.timestamp,
              );

              if (daysFromLastSkip > DAYS_IN_TWO_WEEKS) {
                this.$emit(EVENT_UPDATE);
                persistentStorage.remove(PersistentStorageKeys.DEVICE_VERSION_UPDATE_LATER, {
                  params: [this.deviceId],
                });
              }
            }
          } else {
            this.$emit(EVENT_UPDATE);
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  a.firmware-status-mini {
    cursor: pointer;
  }

  .firmware-status-mini {
    display: inline-flex;
    align-items: center;

    &--clickable {
      border-bottom: 2px solid transparent;
      transition: border-bottom-color 0.3s;
      cursor: pointer;

      &:hover {
        border-bottom-color: black;
      }
    }

    &__has-update {
      font-weight: $weight-bold;

      &--deprecated {
        display: inline-block;
        background-color: $color-bg-danger;
        color: white;
        padding: 1px 4px 1px;
        border-radius: 2px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__icon {
      margin-left: 5px;
      color: $color-text-danger;
    }

    &__loader {
      vertical-align: bottom;
      display: inline-block;
      width: 16px;
      margin: 0 4px 3px 0;
    }
  }
</style>
