<template>
  <div class="error-hidden">
    <label @click="show = !show">Error</label>
    <div @click="show = !show">
      <slot name="brief" />
      <span style="width: 3px; display: inline-block" />
      <button v-if="!show" class="error-hidden__more-link">more...</button>
    </div>
    <div v-if="show" class="error-hidden__main">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ErrorBlock',
    data() {
      return {
        show: false,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .error-hidden {
    &__main {
      box-sizing: border-box;
      margin-top: 8px;
      padding: 16px;
      background: #ffe6ec;
      max-width: 420px;

      div {
        margin-top: 8px;
        margin-bottom: 8px;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__more-link {
      width: fit-content;
      padding: 0;
      border-radius: 4px;
      font-size: $font-size;
      color: $color-text-primary;
      display: inline-block;
      cursor: pointer;
      border: none;
      outline: none !important;
      background-color: $color-bg-transparent;
      white-space: nowrap;

      &:enabled:hover {
        color: $color-text-primary--hover;
        background-color: $color-bg-transparent;
        text-decoration: underline;
      }

      &:enabled:active:focus {
        color: $color-text-primary--active;
        background-color: $color-bg-transparent;
      }

      &:disabled {
        color: $color-text-default--disabled;
        background-color: $color-bg-transparent;
      }
    }
  }
</style>
