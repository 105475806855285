<template>
  <TippyComponent
    ref="tippy"
    v-bind="$attrs"
    :onShow="showHandler"
    :onHide="hideHandler"
    maxWidth="auto"
    :theme="theme"
    :interactive="isInteractive"
    :hideOnClick="isHideOnClickOutside"
    :trigger="trigger"
    class="tippy"
    :animateFill="false"
    :animation="$attrs.animation || 'fade'"
    :isOverflowEllipsis="isOverflowEllipsis"
    :isFullwidth="isFullwidth"
  >
    <template #trigger>
      <slot name="trigger" :isShow="localIsShow" />
    </template>
    <div class="content" @click="clickInsideHandler">
      <slot :hide="hide" :isShow="localIsShow" :triggerWidth="triggerWidth" />
    </div>
  </TippyComponent>
</template>

<script>
  import { TippyComponent } from '@/assets/libraries/vueTippy/src';

  export default {
    name: 'Tippy',

    components: {
      TippyComponent,
    },

    inheritAttrs: false,

    props: {
      theme: {
        type: String,
        default: 'light',
      },
      trigger: {
        type: String,
        default: 'hover',
      },
      timeout: {
        type: Number,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      isInteractive: {
        type: Boolean,
        default: false,
      },
      isHideOnClickInside: {
        type: Boolean,
        default: true,
      },
      // This prop is used only if trigger mode is manual
      isShow: {
        type: Boolean,
      },
      isHideOnClickOutside: {
        type: Boolean,
        default: true,
      },
      isOverflowEllipsis: {
        type: Boolean,
      },
      isFullwidth: {
        type: Boolean,
        default: true,
      },
    },

    data: () => ({
      localIsShow: false,
      triggerWidth: null,
    }),

    watch: {
      isDisabled() {
        if (this.isDisabled) {
          this.hide();
          this.$refs.tippy?.tip.disable();
        } else {
          this.$refs.tippy?.tip.enable();
        }
      },
      isShow: {
        handler(value) {
          const isManualMode = this.trigger === 'manual';
          if (isManualMode) {
            value ? this.show() : this.hide();
          }
        },
        immediate: true,
      },
    },

    mounted() {
      this.init();
    },

    methods: {
      show() {
        this.$refs.tippy?.tip.show();
      },
      hide() {
        this.$refs.tippy?.tip.hide();
      },

      hideAfterTimeout() {
        if (this.timeout) {
          setTimeout(this.hide, this.timeout);
        }
      },

      showHandler() {
        this.$emit('show');
        this.localIsShow = true;
        this.initTriggerWidth();

        this.hideAfterTimeout();
      },

      hideHandler() {
        this.$emit('hide');
        this.localIsShow = false;
      },

      clickInsideHandler() {
        if (this.isHideOnClickInside) {
          this.hide();
        }
      },

      fixPlugin() {
        // The plugin adds a wrapper with tabindex attribute, it prevents focus after pressing on the tab button
        const focusableWrapper = this.$refs.tippy.$el.firstElementChild;
        focusableWrapper?.removeAttribute('tabindex');
      },

      initTriggerWidth() {
        const { clientWidth } = this.$refs.tippy?.$refs?.trigger ?? {};

        if (clientWidth !== this.triggerWidth) {
          this.triggerWidth = this.$refs.tippy?.$refs.trigger.clientWidth;
        }
      },

      async init() {
        if (this.isDisabled) {
          this.$refs.tippy.tip.disable();
        }

        this.fixPlugin();

        await this.$nextTick();

        this.initTriggerWidth();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .tippy {
    display: inline-block;
    outline: none;
  }
</style>
