import float16 from '../assets/libraries/float16';
import { Nullable } from '@/types/utility';

enum BinaryFormat {
  F16,
  F32,
}

const convertArrayBufferToFloat32 = (
  format: Nullable<BinaryFormat>,
  buffer: ArrayBuffer,
): Float32Array => {
  if (format === BinaryFormat.F32) {
    return new Float32Array(buffer);
  } else if (format === BinaryFormat.F16) {
    // @ts-ignore
    const float16Array = new float16.Float16Array(buffer);
    return Float32Array.from(float16Array);
  }

  return new Float32Array(new ArrayBuffer(0));
};

export const convertDataToMau = (base64: string = '') => {
  const binaryString = window.atob(base64);

  const int8Array = new Int8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    int8Array[i] = binaryString.charCodeAt(i);
  }

  const binaryFormat = BinaryFormat.F32;
  const float32Array = convertArrayBufferToFloat32(binaryFormat, int8Array.buffer);

  return [...float32Array];
};

const getFilenameFromURL = (url: string) => {
  const urlObj = new URL(url);
  const pathname = urlObj.pathname;
  return pathname.split('/').pop();
};

const getFullExtension = (filename: Nullable<string>) => {
  // This regex captures from the first dot in a sequence of two or more file extensions to the end
  const result = filename?.match(/(\.[0-9a-z]+)+$/i);

  if (result) {
    return result[0];
  }
  return ''; // Return an empty string if no extension is found
};

const getBinaryFormatFromExtension = (extension: string) => {
  if (extension === '.bin') {
    return BinaryFormat.F16;
  } else if (extension === '.f32.bin') {
    return BinaryFormat.F32;
  }
  return null;
};

// const dataMeasurementCache: Dict = {};
// window.www = dataMeasurementCache;

export const getDataMeasurementFromCDN = async (url: string) => {
  // if (dataMeasurementCache[url]) {
  //   return dataMeasurementCache[url];
  // }

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const arrayBuffer = await response.arrayBuffer();

    const filename = getFilenameFromURL(url);
    const extension = getFullExtension(filename);
    const binaryFormat = getBinaryFormatFromExtension(extension);
    const float32Array = convertArrayBufferToFloat32(binaryFormat, arrayBuffer);

    return [...float32Array];
  } catch (error) {
    console.error('Error fetching binary file:', error);
  }
};
