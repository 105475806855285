<template>
  <div ref="sample-peaks" v-resize:throttle="onResize">
    <template v-if="peaks && peaks.length > 0">
      <div style="overflow-x: auto">
        <table class="table-peaks">
          <thead class="table-peaks__thead">
            <tr>
              <th class="number">#</th>
              <th style="text-align: left">Time</th>
              <th style="text-align: right; white-space: nowrap">Area</th>
              <th />
              <th style="text-align: left; padding-left: 8px">Compound</th>
              <th style="text-align: right; padding-right: 0">Amount</th>
              <th />
            </tr>
          </thead>
          <tbody class="table-peaks__tbody">
            <!-- todo: create key  -->
            <tr
              v-for="(peak, index) in peaksAndEstimations"
              :key="index"
              :class="{ 'table-peaks__row--selected': peak.id === highlightedPeakId }"
              @mouseenter.self="onMouseEnter(peak.id)"
              @mouseleave="onMouseLeave(peak.id)"
            >
              <td class="number">
                <span class="font-monospace font-40">{{ index + 1 }}</span>
              </td>
              <td class="font-monospace" v-html="Math.round(peak.apex * 100) / 100" />
              <td class="font-monospace area">
                {{ peak.area }}
              </td>
              <td class="arrow">→</td>
              <td class="compound">
                <span v-if="peak.estimation.standard_id == null">{{ peak.estimation.name }}</span>
                <a v-else v-bind:href="'/app/standards/' + peak.estimation.standard_id.toString()">
                  {{ peak.estimation.name }}
                </a>
              </td>
              <td style="padding: 0; text-align: right">
                <span v-if="peak.estimation.standard_id == null" class="font-monospace amount">
                  {{ peak.estimation.amount }}
                </span>
                <a
                  v-else
                  class="font-monospace amount"
                  style="vertical-align: bottom; overflow: hidden"
                  v-bind:href="'/app/standards/' + peak.estimation.standard_id.toString()"
                >
                  {{ peak.estimation.amount }}
                </a>
              </td>
              <td>
                <span v-if="peak.estimation.standard_id == null" class="unit">
                  {{ peak.estimation.unit }}
                </span>
                <span v-else class="unit">
                  {{ peak.estimation.unit }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
  import 'assets/css/component/modal.scss';

  import resize from 'vue-resize-directive';

  export default {
    name: 'PrChartSamplePeaks',

    directives: { resize },

    props: {
      ppeaks: Array,
      measurementSocket: Object,
      highlightedPeakId: Number,
    },

    data() {
      return {
        peaks: this.modifyPeaks(this.ppeaks),
        small: false,
        focusedPeakId: null,
      };
    },

    computed: {
      peaksAndEstimations: {
        get() {
          return this.peaks;
        },
        set() {
          //stub
        },
      },
    },

    watch: {
      ppeaks(ppeaks) {
        if (ppeaks) this.setPeaks(ppeaks);
      },
    },

    methods: {
      onResize() {
        const w = this.$refs['sample-peaks'].getBoundingClientRect().width;
        this.small = w < 600;
      },

      modifyPeaks(peaks) {
        if (peaks == null) return peaks;
        for (let i = 0; i < peaks.length; i++) {
          // eslint-disable-next-line no-param-reassign
          peaks[i].apex = Math.round(peaks[i].apex * 1e3) / 1e3;
          // eslint-disable-next-line no-param-reassign
          peaks[i].area = Math.round(peaks[i].area * 1e3) / 1e3;
        }
        return peaks;
      },

      setPeaks(peaks) {
        this.peaks = this.modifyPeaks(peaks);
      },

      emitSelectionEvent(peakId, isSelected) {
        this.$emit('selection', { peakId, isSelected });
      },

      onMouseEnter(peakId) {
        this.emitSelectionEvent(peakId, true);
      },
      onMouseLeave(peakId) {
        if (this.focusedPeakId !== peakId) {
          this.emitSelectionEvent(peakId, false);
        }
      },
    },
  };
</script>

<style lang="scss">
  .table-peaks-scrollable-container {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .table-peaks {
    height: 1px;
  }

  .table-peaks button {
    display: block;
    min-width: 16px;
    padding: 4px 8px;
    margin: 0;
    background: transparent;
    color: $color-text-primary;

    @include isHoverDevice {
      visibility: hidden;
    }
  }

  .table-peaks button:hover {
    background: #00000022;
  }

  .table-peaks button:active {
    background: #00000044;
  }

  .table-peaks__thead th {
    font-size: 13px;
    font-weight: bold;
    color: #00000066;
    padding: 2px 8px 6px 8px;
  }

  .table-peaks__thead th:first-child {
    padding-left: 32px;
  }

  .table-peaks__thead th:last-child {
    padding-right: 32px;
  }

  .table-peaks__tbody td {
    font-size: 13px;
    white-space: nowrap;
    padding: 4px 8px 3px 8px;
    width: 1px;
  }

  .table-peaks__tbody td:first-child {
    padding-left: 32px;
  }

  .table-peaks__tbody td:last-child {
    padding-right: 32px;
  }

  .table-peaks__tbody tr:hover button {
    visibility: visible;
  }

  .table-peaks__tbody tr:nth-child(2n-1) {
    background-color: #00000008;
  }

  .table-peaks__tbody tr:hover,
  .table-peaks__tbody tr.table-peaks__row--selected {
    background-color: #00000011;
  }

  .input-compound--focus-underline {
    input[type='text'].autosuggest__input,
    input:not([type]).autosuggest__input {
      border: 0 solid transparent;
      border-bottom-width: 2px;
      background: transparent;
      border-radius: 3px;
      padding: 6px 6px 4px 0;
      font-size: 13px;
      outline: none !important;

      &:focus {
        border-width: 0 0 2px 0;
        border-color: $color-bg-primary;
        outline: none !important;
      }
    }
  }

  input[type='text'].input--focus-underline,
  input:not([type]).input--focus-underline,
  input[type='number'].input--focus-underline {
    border: 0 solid transparent;
    border-bottom-width: 2px;
    background: transparent;
    border-radius: 3px;
    padding: 6px 6px 4px 0;
    font-size: 13px;

    &:focus {
      border-width: 0 0 2px 0;
      border-color: $color-bg-primary;
      outline: none !important;
    }
  }

  .table-peaks__tbody {
    .number {
      text-align: left;
    }

    .area {
      font-weight: bold;
      color: $color-text-info;
      text-align: right;
      padding-left: 12px;
    }

    .compound {
      width: auto;
      padding-right: 0;
      padding-left: 8px;
    }

    .unit {
      display: inline-block;
      width: 64px;
    }

    .amount {
      display: inline-block;
      text-align: right;
      width: 64px;
    }
  }

  @media only screen and (max-width: 700px) {
    .table-peaks__tbody {
      .number {
        padding-right: 0;
      }

      .area {
        padding-left: 8px;
      }

      .amount {
        width: auto;
      }

      .arrow {
        padding: 0 0 0 2px;
      }

      .compound {
        padding-left: 8px;
      }

      .unit {
        width: auto;
      }
    }

    .table-peaks__thead {
      .number {
        padding-right: 0;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .table-peaks {
    &__input {
      flex: 1;
      width: 100%;
      outline: none;
      color: inherit;
      padding: 0 5px;
      transition: border-color 67ms;
      border: none;
      background-color: transparent;
      text-indent: 6px;
      height: 34px;
      border-bottom: 2px solid transparent;
      -webkit-appearance: none;

      &:focus {
        border-bottom-color: $color-text-primary;
        border-radius: 4px;
      }
    }
  }
</style>

<style scoped>
  .table-peaks__tbody tr >>> input::placeholder {
    color: #00000000;
  }

  .table-peaks__tbody tr:hover >>> input::placeholder {
    color: #00000066;
  }
</style>
