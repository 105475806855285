<template>
  <WithKeyboardSelection
    v-if="items.length"
    #default="{ selectedId }"
    :ids="itemIds"
    :onSelect="selectItemById"
    :isActive="isActive"
    :scrollContainer="scrollContainer"
  >
    <ul class="popup-base">
      <li v-if="canCreate" class="popup-base__item-create" @click="$emit('create')">
        <IconMaterial title="add" class="popup-base__icon-add" />
        {{ messageCreate }}
      </li>
      <li
        v-for="(item, index) of items"
        :key="item.id || index"
        class="popup-base__item"
        :class="{
          'popup-base__item--selected': item === itemSelected,
          'popup-base__item--active': item.id === selectedId,
        }"
        :data-id="item.id"
        @click="selectItem(item)"
      >
        <span v-if="$scopedSlots.leftSideInsideItem" class="popup-base__left-side-inside-item">
          <slot name="leftSideInsideItem" :item="item" />
        </span>
        <span class="popup-base__label" :title="item[labelProp]">{{ item[labelProp] }}</span>
        <span v-if="$scopedSlots.rightSideInsideItem" class="popup-base__right-side-inside-item">
          <slot name="rightSideInsideItem" :item="item" />
        </span>
      </li>
    </ul>
  </WithKeyboardSelection>
  <div v-else class="popup-base">
    <li
      v-if="canCreate || (canCreateIfNotFound && items.length === 0)"
      class="popup-base__item-create"
      @click="$emit('create')"
    >
      <IconMaterial title="add" class="popup-base__popup-base__icon-add" />
      {{ messageCreate }}
    </li>
    <div class="popup-base__no-items">{{ messageNoItems }}</div>
  </div>
</template>

<script>
  import WithKeyboardSelection from '@/hoc/WithKeyboardSelection.vue';
  import LayoutHelper from '@/utils/LayoutHelper';
  import IconMaterial from '@/uikitBase/icons/IconMaterial.vue';

  export default {
    name: 'PopupBase',

    components: {
      IconMaterial,
      WithKeyboardSelection,
    },

    props: {
      items: {
        type: Array,
        required: true,
      },
      itemSelected: {
        type: Object,
      },
      isActive: {
        type: Boolean,
        default: false,
      },
      labelProp: {
        type: String,
        required: true,
      },
      scrollContainer: {
        type: HTMLElement,
      },
      messageNoItems: {
        type: String,
        default: 'No items',
      },
      messageCreate: {
        type: String,
        default: 'Create',
      },
      canCreate: {
        type: Boolean,
        default: false,
      },
      canCreateIfNotFound: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      itemIds() {
        return this.items.map((item) => item.id);
      },
    },

    updated() {
      // Tippy library has a complicated bug: Popup position changes if content updates.
      // Tippy can fix position if the viewport is a bit changed
      LayoutHelper.scrollableViewRoots.forEach((node) => {
        node.scrollTop--;
        node.scrollTop++;
      });
    },

    methods: {
      selectItem(item) {
        this.$emit('select', item);
      },
      selectItemById(itemId) {
        const item = this.items.find((_item) => _item.id === Number(itemId));
        this.$emit('select', item);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .popup-base {
    min-width: 100%;
    overflow: hidden;
    max-height: 200px;
    overflow-y: auto;

    background-color: $color-bg-second;

    &__item {
      position: relative;

      display: flex;
      align-items: center;
      height: 28px;
      padding: 0 12px;

      font-size: 13px;
      text-align: left;

      cursor: pointer;

      &:hover,
      &--active {
        background-color: white;
      }

      &--selected {
        color: $color-text-on-primary;
        background: $color-bg-primary;
        font-weight: $weight-bold;

        &:hover {
          background-color: $color-bg-primary--hover;
        }
      }
    }

    &__item-create {
      display: flex;
      align-items: center;
      height: 28px;
      padding: 0 12px;
      font-size: 13px;
      cursor: pointer;
      font-weight: $weight-bold;
      border-bottom: 1px solid $color-bg-third;

      &:hover,
      &--active {
        background-color: white;
      }
    }

    &__icon-add {
      margin-right: 5px;
    }

    &__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: auto;
    }

    &__left-side-inside-item,
    &__right-side-inside-item {
      flex: none;
      display: flex;
      align-items: center;
    }

    &__left-side-inside-item {
      margin-right: 5px;
    }

    &__right-side-inside-item {
      margin-left: 5px;
    }

    &__no-items {
      padding: 5px;
      color: $color-text-third;
    }
  }
</style>
