<template>
  <textarea
    ref="textarea"
    v-model="_value"
    class="textarea"
    :class="{
      'textarea--darken': isDarken,
    }"
    v-bind="$attrs"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
  const EVENT_MODEL = 'model';

  export default {
    name: 'Textarea',

    model: {
      props: 'value',
      event: EVENT_MODEL,
    },

    props: {
      value: {
        type: [Number, String],
        required: true,
      },
      isDarken: {
        type: Boolean,
      },
    },

    computed: {
      _value: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit(EVENT_MODEL, value);
        },
      },
    },

    methods: {
      focus() {
        this.$refs.textarea.focus();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .textarea {
    // TODO Styles were copied from global css to avoid conflicts in the future
    // sometimes global styles will be deleted, afterwards we will need to check this scoped styles
    min-height: 42px;
    color: $color-text-default;
    background: $color-bg-input;
    border: none;
    border-radius: 2px;
    padding: 12px;
    text-indent: 0;
    width: 100%;
    display: block;
    outline: none;
    font-size: inherit;
    box-sizing: border-box;
    appearance: none;

    &--darken {
      background: darken($color-bg-input, 5%);
    }

    &:focus {
      background-color: $color-bg-input--hover;
    }
  }
</style>
