<template>
  <InputTypeNumber
    v-bind="$attrs"
    :value="valueStringified"
    step="0.000001"
    :isFloat="true"
    :isDisabled="isDisabled"
    class="input-response"
    :isChangeOnBlur="true"
    @change="change"
  />
</template>

<script>
  import InputTypeNumber from '@/uikitProject/tables/shared/vueTableCell/private/inputs/private/InputTypeNumber';

  export default {
    name: 'InputResponse',

    components: {
      InputTypeNumber,
    },

    props: {
      value: {
        type: Number,
        required: true,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      valueStringified() {
        return this.value.toString();
      },
    },

    methods: {
      change(value) {
        this.$emit('change', value);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .input-response {
    text-align: right;
  }
</style>
