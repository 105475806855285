<template>
  <div>
    <div class="devices-page top-line--flex">
      <h1>Devices</h1>

      <template v-if="devices && !isEditMode">
        <Btn v-if="devices.length > 0" height="s" type="transparent" @click="startEditing">
          <template #iconLeft>
            <i class="material-icons icon_no_decoration material-icon--18">settings</i>
          </template>
          Add/Remove/Move devices
        </Btn>
        <Btn
          v-else
          height="s"
          type="primary"
          data-test-id="btn-device-settings"
          @click="startEditing"
        >
          <template #iconLeft>
            <i class="material-icons icon_no_decoration material-icon--18">add</i>
          </template>
          Add device
        </Btn>
      </template>
      <Btn v-else-if="isEditMode" height="s" type="transparent" @click="stopEditing">
        <template #iconLeft>
          <i class="material-icons material-icon--16 no-text-decoration">done</i>
        </template>
        Finish editing
      </Btn>
    </div>

    <AddDevice
      v-if="isEditMode"
      :hasDevices="devices ? devices.length > 0 : false"
      @add="onDeviceAdd"
    />

    <template v-if="devices">
      <template v-if="devices.length">
        <Draggable
          v-model="devices"
          :animation="350"
          :disabled="!isEditMode"
          :fallbackOnBody="false"
          :fallbackTolerance="1"
          :forceFallback="true"
          :scroll="true"
          :scrollSensitivity="100"
          :scrollSpeed="5"
          easing="cubic-bezier(1, 0, 0, 1)"
          group="devices"
          handle=".devices-page__device"
          @start="startDrag"
          @end="finishDrag"
        >
          <DeviceCard
            v-for="device in devices"
            :key="device.id"
            :device="device"
            :isEditable="isEditMode"
            class="devices-page__device"
            data-test-id="device-card"
            @remove="removeDevice"
          />
        </Draggable>
      </template>

      <nothing-there-component v-if="!devices.length" style="margin-top: 32px">
        <h3 style="color: #00000066" data-test-id="message-no-devices">No devices</h3>
        <div v-if="isEditMode" style="padding: 32px; text-align: left; color: #0f0f0f">
          To add your device - <b>find serial and key at the bottom</b> of your Alltesta or Cromite.
          Enter both below.
          <br />
          <br />
          If you don't have <a href="/hardware"> Alltesta - order it here.</a>
        </div>
        <span v-else style="padding: 16px; display: block">
          Device is a <b>Alltesta</b> data acquisition or <b>Cromite</b>.<br />
          To add your device click the button above.<br /><br />
          <a href="/hardware"><b>To order</b> equipment compatible with HPLC.Cloud go here.</a>
        </span>
      </nothing-there-component>
    </template>
    <template v-else>
      <error-component v-if="errorCode" :code="errorCode" class="mt-64" />
      <loading-component v-else />
    </template>
  </div>
</template>

<script>
  import ErrorComponent from 'components/element/ErrorComponent.vue';
  import NothingThereComponent from 'components/element/NothingThereComponent.vue';
  import LoadingComponent from 'components/element/LoadingComponent.vue';
  import PageTitle from '@/mixins/page-title';
  import Btn from '@/uikitBase/btns/Btn';
  import DeviceCard from '@/uikitProject/devices/DeviceCard';
  import HardwareAPI from 'api/hardware';
  import AddDevice from 'components/blocks/devices/add/AddDevice';
  import { AuthService } from '@/services/auth.service';
  import { apiDevices } from '@/api/graphql/cloud/devices';
  import { consoleHelpers } from '@/utils/logHelpers';
  import { persistentStorage, PersistentStorageKeys } from '@/utils/persistentStorage';
  import Draggable from 'vuedraggable';

  export default {
    name: 'DevicesPage',

    components: {
      AddDevice,
      DeviceCard,
      Btn,
      ErrorComponent,
      LoadingComponent,
      NothingThereComponent,
      Draggable,
    },

    mixins: [PageTitle],

    data: () => ({
      pageTitle: 'HPLC Cloud: Devices',
      devices: null,
      errorCode: undefined,
      updateTimeout: null,
      getDeviceListCall: null,

      isEditMode: false,

      isSuperuser: AuthService.userData()?.is_superuser,

      isDragging: false,
    }),

    created() {
      this.initQuery();

      this.loadDevices();
    },

    beforeDestroy() {
      clearTimeout(this.updateTimeout);
      this.getDeviceListCall = null;
    },

    methods: {
      startEditing() {
        this.isEditMode = true;
      },
      stopEditing() {
        this.isEditMode = false;
      },

      initQuery() {
        const { serial, key } = this.$route.query;
        if (serial && key) {
          this.isEditMode = true;
        }
      },
      initDevices(devices) {
        if (this.isDragging) {
          return;
        }

        const devicesOrder = persistentStorage.get(PersistentStorageKeys.DEVICES_ORDER) ?? [];

        let hasUnknownDevices = false;

        this.devices = [...devices].sort((deviceA, deviceB) => {
          const deviceAIndex = devicesOrder.indexOf(deviceA.id);
          const deviceBIndex = devicesOrder.indexOf(deviceB.id);

          if (deviceAIndex === -1 || deviceBIndex === -1) {
            hasUnknownDevices = true;
          }

          if (deviceAIndex === -1 && deviceBIndex === -1) {
            if (deviceA.hardware.isOnline === null || deviceB.hardware.isOnline === null) {
              if (deviceA.hardware.isOnline === null && deviceB.hardware.isOnline === null) {
                return 0;
              } else if (deviceA.hardware.isOnline === null) {
                return 1;
              }
              return -1;
            }

            if (deviceA.hardware.isOnline && !deviceB.hardware.isOnline) {
              return -1;
            } else if (!deviceA.hardware.isOnline && deviceB.hardware.isOnline) {
              return 1;
            }
            return 0;
          } else if (deviceAIndex === -1) {
            return -1;
          } else if (deviceBIndex === -1) {
            return 1;
          }

          return deviceAIndex > deviceBIndex ? 1 : -1;
        });

        if (hasUnknownDevices) {
          this.updateOrder();
        }
      },

      async loadDevices() {
        try {
          this.errorCode = undefined;

          this.getDeviceListCall = apiDevices.getDevices();

          const devices = await this.getDeviceListCall;

          if (this.getDeviceListCall == null) return;

          if (this.updateTimeout != null) {
            clearTimeout(this.updateTimeout);
            this.updateTimeout = null;
          }

          this.initDevices(devices);

          const hasDeviceWithUnknownStatus = devices.some((device) => device.isOnline == null);
          this.updateTimeout = setTimeout(
            () => this.loadDevices(),
            hasDeviceWithUnknownStatus ? 1000 : 5000,
          );
        } catch (e) {
          if (this.getDeviceListCall == null) return;

          this.devices = undefined;
          this.notifyError(`Devices update error`);
          consoleHelpers.warn(e);
          this.updateTimeout = setTimeout(() => this.loadDevices(), 5000);
        }
      },

      onDeviceAdd() {
        this.isEditMode = false;
        this.loadDevices();
      },

      async removeDevice(deviceId) {
        await HardwareAPI.delete(deviceId);
        this.loadDevices();
      },

      updateOrder() {
        persistentStorage.set(
          PersistentStorageKeys.DEVICES_ORDER,
          this.devices.map((device) => device.id),
        );
      },

      startDrag() {
        this.isDragging = true;
      },
      finishDrag() {
        this.updateOrder();
        this.isDragging = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .devices-page {
    &__device {
      margin: 8px 0;
    }

    &__label-offline {
      margin: 10px 0;
      color: $color-text-third;

      @media (max-width: $screen-sm-max) {
        margin: 10px 1rem;
      }
    }
  }
</style>
