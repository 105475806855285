import _ from 'lodash';
import { BroadcastChannel } from 'broadcast-channel';
import { persistentStorage, PersistentStorageKeys } from '@/utils/persistentStorage';

const BROADCAST_CHANNEL = 'chromatograms-compare';

export default class ChromatogramsCompare {
  static getChannel() {
    return new BroadcastChannel(BROADCAST_CHANNEL);
  }

  static isCompared(id) {
    return ChromatogramsCompare.getIDs()?.includes(id);
  }

  static add(id) {
    const ids = _.union(ChromatogramsCompare.getIDs() || [], [id]);
    ChromatogramsCompare.save(ids);
  }

  static remove(id) {
    let ids = ChromatogramsCompare.getIDs();
    ids = ids?.splice(ids.indexOf(id), 1);
    ChromatogramsCompare.save(ids);
  }

  static subscribe(onchange) {
    ChromatogramsCompare.getChannel().addEventListener('message', onchange);
  }

  static unsubscribe(onchange) {
    ChromatogramsCompare.getChannel().removeEventListener('message', onchange);
  }

  static getIDs() {
    return persistentStorage.get(PersistentStorageKeys.CHROMATOGRAMS_COMPARE_IDS);
  }

  static save(ids) {
    persistentStorage.set(PersistentStorageKeys.CHROMATOGRAMS_COMPARE_IDS, ids);
    ChromatogramsCompare.getChannel().postMessage(ids);
  }
}
