<template>
  <Radio
    v-model="_checkedVial"
    v-tippy="{ touch: false }"
    :content="value"
    name="vials"
    :value="value"
    :isDisabled="isDisabled"
  >
    <template #default="{ isChecked }">
      <div class="vial" :class="{ 'vial--disabled': isDisabled }">
        <div
          class="vial__circle"
          :class="{
            'vial__circle--selected': isChecked,
            'vial__circle--disabled': isDisabled,
            'vial__circle--used': isUsed && !isChecked,
            'vial__circle--processing': isProcessing,
          }"
        ></div>
      </div>
    </template>
  </Radio>
</template>

<script>
  import Radio from '@/uikitBase/switchers/Radio';

  const EVENT_MODEL = 'model';

  export default {
    name: 'Vial',

    components: {
      Radio,
    },

    model: {
      prop: 'checkedVial',
      event: EVENT_MODEL,
    },

    props: {
      checkedVial: {
        type: String,
      },
      value: {
        type: String,
        required: true,
      },
      isDisabled: {
        type: Boolean,
      },
      isUsed: {
        type: Boolean,
      },
      isProcessing: {
        type: Boolean,
      },
    },

    computed: {
      _checkedVial: {
        get() {
          return this.checkedVial && this.checkedVial.toUpperCase();
        },
        set(value) {
          this.$emit(EVENT_MODEL, value);
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .vial {
    margin: auto;
    padding: 5px;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &--disabled {
      cursor: default;
    }

    &:hover:not(&--disabled) &__circle {
      background-color: $color-bg-primary--hover;
      border-color: $pure-color__black--alp-60;
    }

    &:active:not(&--disabled) &__circle {
      background-color: $color-bg-primary--active;
      border-color: $pure-color__black--alp-60;
    }

    &__circle {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      border: 1px solid $color-bg-primary--active;
      transition: background-color 67ms, border-color 67ms;
      min-width: 14px;
      min-height: 14px;
      cursor: pointer;

      &--disabled {
        cursor: not-allowed;
      }

      &--selected {
        background-color: $color-bg-primary;
        border-color: $pure-color__black--alp-60;
      }

      &--selected:not(&--disabled) {
        &:hover {
          background-color: $color-bg-primary--hover;
        }

        &:active {
          background-color: $color-bg-primary--active;
        }
      }

      &--selected.vial__circle--processing {
        animation-name: processing;
        animation-iteration-count: infinite;
        animation-duration: 0.7s;
        animation-direction: alternate;
      }

      &--used {
        background-color: #ffbcbc;
        border-color: $pure-color__black--alp-60;
      }
    }
  }

  @keyframes processing {
    0% {
      background-color: darken($color-bg-primary, 10%);
    }

    100% {
      background-color: lighten($color-bg-primary, 5%);
    }
  }
</style>
